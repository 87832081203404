import { useEffect, useState, useCallback, useContext, useMemo, useRef } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { TabbedPane } from 'oemiq-common';

import FlagTable from './FlagTable/FlagTable';
import TagTable from './TagTable/TagTable';
import LinksTable from './LinksTable/LinksTable';

import SearchFilter from 'components/SearchFilter/SearchFilter';
import { TaggingWorkFlowStatusEnum } from 'helpers/FlagTagHelper';
import {
    filterInReviewTags,
    filterCompleteTags,
    filterUncheckedLinkTags,
    filterCheckedLinkTags,
    filterInProgressTags,
    filterNeedHelpTags,
} from 'helpers/FlagTagHelper';

import useTagsAndFlags from './useTagsAndFlags';

import useOneTimeUseFlagDispositions from 'hooks/useOneTimeUseFlagDispositions';
import ViewProcedureModal from 'components/Modals/ViewProcedureModal/ViewProcedureModal';
import useTagsAndFlagsSearch from './useTagsAndFlagsSearch';
import useTagsAndFlagsFilter from './useTagsAndFlagsFilter';
import { TaggerContext } from 'contexts/TaggerContextProvider';

const TaggerList = () => {
    const { bookId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const { setDisplayProcedureIds, setDisplayTagIds } = useContext(TaggerContext);

    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [modalProcedureId, setModalProcedureId] = useState(null);
    const handleViewProcedureClick = useCallback((e, procedureId) => {
        e.stopPropagation();
        setModalProcedureId(procedureId);
    }, []);

    const { flags, tags, selectedTags, handleTagsSelection, handleTagStatusChange, flagAndTagPropertiesList } =
        useTagsAndFlags(bookId);

    const { searchedFlags, searchedTags, handleSearch, searchOptions } = useTagsAndFlagsSearch(flags, tags);
    const isInitialUrlFiltersCheck = useRef(false);

    const {
        filteredFlags,
        filteredTags,
        appliedFilters,
        setAppliedFilters,
        filterByNoGroups,
        setFilterByNoGroups,
        filterByNoType,
        setFilterByNoType,
        filterRefreshed,
        setFilterRefreshed,
    } = useTagsAndFlagsFilter(searchedFlags, searchedTags);

    const handleUpdateApliedFilters = state => {
        setSearchParams(searchParams => {
            searchParams.set('appliedFilters', JSON.stringify(state));
            return searchParams;
        });
    };

    const handleUpdateFilterByNoType = updateFn => {
        setSearchParams(searchParams => {
            searchParams.set('filterByNoType', updateFn(filterByNoType));
            return searchParams;
        });
    };

    const handleUpdateFilterByNoGroups = updateFn => {
        setSearchParams(searchParams => {
            searchParams.set('filterByNoGroups', updateFn(filterByNoGroups));
            return searchParams;
        });
    };

    const handleUpdateFilterRefreshed = updateFn => {
        setSearchParams(searchParams => {
            searchParams.set('filterRefreshed', updateFn(filterRefreshed));
            return searchParams;
        });
    };

    useEffect(() => {
        setAppliedFilters(searchParams.get('appliedFilters') ? JSON.parse(searchParams.get('appliedFilters')) : []);

        setFilterRefreshed(
            searchParams.get('filterRefreshed') ? JSON.parse(searchParams.get('filterRefreshed')) : false
        );

        setFilterByNoGroups(
            searchParams.get('filterByNoGroups') ? JSON.parse(searchParams.get('filterByNoGroups')) : false
        );

        setFilterByNoType(searchParams.get('filterByNoType') ? JSON.parse(searchParams.get('filterByNoType')) : false);
        isInitialUrlFiltersCheck.current = true;
    }, [searchParams, setAppliedFilters, setFilterRefreshed, setFilterByNoType, setFilterByNoGroups]);

    const tabs = useMemo(() => {
        return {
            inReviewTags: filterInReviewTags(filteredTags),
            completedTags: filterCompleteTags(filteredTags),
            checkedLinkTags: filterCheckedLinkTags(filteredTags),
            uncheckedLinkTags: filterUncheckedLinkTags(filteredTags),
            needHelpTags: filterNeedHelpTags(filteredTags),
            inProgressTags: filterInProgressTags(filteredTags),
        };
    }, [filteredTags]);

    const [statusModalOpen, setStatusModalOpen] = useState(false);
    const [statusOptions, setStatusOptions] = useState([]);

    const { options: dispositions } = useOneTimeUseFlagDispositions();

    useEffect(() => {
        const options = [];
        for (const prop in TaggingWorkFlowStatusEnum) {
            const { Id, Name, Updatable } = TaggingWorkFlowStatusEnum[prop];
            if (Updatable) {
                options.push({
                    key: Id,
                    value: Id,
                    text: Name,
                });
            }
        }
        setStatusOptions(options);
    }, []);

    const flagsProcedureIds = useMemo(() => [...new Set(filteredFlags.map(f => f.procedureId))], [filteredFlags]);
    const inProgressProcedureIds = useMemo(() => [...new Set(tabs.inProgressTags.map(t => t.procedureId))], [tabs]);
    const inReviewProcedureIds = useMemo(() => [...new Set(tabs.inReviewTags.map(t => t.procedureId))], [tabs]);
    const needHelpProcedureIds = useMemo(() => [...new Set(tabs.needHelpTags.map(t => t.procedureId))], [tabs]);
    const completedProcedureIds = useMemo(() => [...new Set(tabs.completedTags.map(t => t.procedureId))], [tabs]);
    const uncheckedLinkIds = useMemo(
        () => [...new Set(tabs.uncheckedLinkTags.map(t => t.stagedOneTimeUseTagId))],
        [tabs]
    );
    const checkedLinkIds = useMemo(() => [...new Set(tabs.checkedLinkTags.map(t => t.stagedOneTimeUseTagId))], [tabs]);

    const handleLinkRowClick = tIds => () => setDisplayTagIds(tIds);
    const searchParamsObject = useMemo(() => Object.fromEntries(searchParams), [searchParams]);

    return (
        <div className="container-fluid mt-3">
            <div className="row">
                <div className="col-6 mb-3">
                    <SearchFilter
                        handleSearch={handleSearch}
                        searchOptions={searchOptions}
                        bookId={bookId}
                        filterColumnOptions={flagAndTagPropertiesList}
                        appliedFilters={appliedFilters}
                        filterByNoGroups={filterByNoGroups}
                        filterByNoType={filterByNoType}
                        filterRefreshed={filterRefreshed}
                        handleUpdateApliedFilters={handleUpdateApliedFilters}
                        handleUpdateFilterByNoType={handleUpdateFilterByNoType}
                        handleUpdateFilterByNoGroups={handleUpdateFilterByNoGroups}
                        handleUpdateFilterRefreshed={handleUpdateFilterRefreshed}
                        isInitialUrlFiltersCheck={isInitialUrlFiltersCheck.current}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <TabbedPane defaultSelectedTabIndex={selectedTabIndex}>
                        <>
                            <div className="text-warning" onClick={() => setSelectedTabIndex(0)}>
                                Flags ({filteredFlags.length})
                            </div>
                            <div className="col mt-3">
                                <FlagTable
                                    flags={filteredFlags}
                                    searchParamsObject={searchParamsObject}
                                    flagsProcedureIds={flagsProcedureIds}
                                    setDisplayProcedureIds={setDisplayProcedureIds}
                                    dispositions={dispositions}
                                    handleViewProcedureClick={handleViewProcedureClick}
                                />
                            </div>
                        </>
                        <>
                            <div className="text-primary" onClick={() => setSelectedTabIndex(1)}>
                                Tags In Progress ({tabs.inProgressTags.length})
                            </div>
                            <div className="col mt-3">
                                <TagTable
                                    tags={tabs.inProgressTags}
                                    searchParamsObject={searchParamsObject}
                                    tagProcedureIds={inProgressProcedureIds}
                                    setDisplayProcedureIds={setDisplayProcedureIds}
                                    handleViewProcedureClick={handleViewProcedureClick}
                                    onCheckboxClick={handleTagsSelection}
                                    selectedTags={selectedTags}
                                    statusModalOpen={statusModalOpen}
                                    toggleStatusModal={() => setStatusModalOpen(isOpen => !isOpen)}
                                    tabStatus={TaggingWorkFlowStatusEnum.IN_PROGRESS.Id}
                                    statusOptions={statusOptions}
                                    onStatusModalSave={handleTagStatusChange}
                                />
                            </div>
                        </>
                        <>
                            <div className="text-primary" onClick={() => setSelectedTabIndex(2)}>
                                Tags Need Help ({tabs.needHelpTags.length})
                            </div>
                            <div className="col mt-3">
                                <TagTable
                                    tags={tabs.needHelpTags}
                                    tagProcedureIds={needHelpProcedureIds}
                                    setDisplayProcedureIds={setDisplayProcedureIds}
                                    searchParamsObject={searchParamsObject}
                                    handleViewProcedureClick={handleViewProcedureClick}
                                    onCheckboxClick={handleTagsSelection}
                                    selectedTags={selectedTags}
                                    statusModalOpen={statusModalOpen}
                                    toggleStatusModal={() => setStatusModalOpen(isOpen => !isOpen)}
                                    tabStatus={TaggingWorkFlowStatusEnum.NEED_HELP.Id}
                                    statusOptions={statusOptions}
                                    onStatusModalSave={handleTagStatusChange}
                                />
                            </div>
                        </>
                        <>
                            <div className="text-primary" onClick={() => setSelectedTabIndex(3)}>
                                Tags In Review ({tabs.inReviewTags.length})
                            </div>
                            <div className="col mt-3">
                                <TagTable
                                    tags={tabs.inReviewTags}
                                    tagProcedureIds={inReviewProcedureIds}
                                    setDisplayProcedureIds={setDisplayProcedureIds}
                                    searchParamsObject={searchParamsObject}
                                    handleViewProcedureClick={handleViewProcedureClick}
                                    onCheckboxClick={handleTagsSelection}
                                    selectedTags={selectedTags}
                                    statusModalOpen={statusModalOpen}
                                    toggleStatusModal={() => setStatusModalOpen(isOpen => !isOpen)}
                                    tabStatus={TaggingWorkFlowStatusEnum.IN_REVIEW.Id}
                                    statusOptions={statusOptions}
                                    onStatusModalSave={handleTagStatusChange}
                                />
                            </div>
                        </>
                        <>
                            <div className="text-success" onClick={() => setSelectedTabIndex(4)}>
                                Tags Complete ({tabs.completedTags.length})
                            </div>
                            <div className="col mt-3">
                                <TagTable
                                    tags={tabs.completedTags}
                                    tagProcedureIds={completedProcedureIds}
                                    setDisplayProcedureIds={setDisplayProcedureIds}
                                    searchParamsObject={searchParamsObject}
                                    handleViewProcedureClick={handleViewProcedureClick}
                                    onCheckboxClick={handleTagsSelection}
                                    selectedTags={selectedTags}
                                    statusModalOpen={statusModalOpen}
                                    toggleStatusModal={() => setStatusModalOpen(isOpen => !isOpen)}
                                    tabStatus={TaggingWorkFlowStatusEnum.COMPLETE.Id}
                                    statusOptions={statusOptions}
                                    onStatusModalSave={handleTagStatusChange}
                                />
                            </div>
                        </>
                        <>
                            <div className="text-danger" onClick={() => setSelectedTabIndex(5)}>
                                Links Unchecked ({tabs.uncheckedLinkTags.length})
                            </div>
                            <div className="col mt-3">
                                <LinksTable
                                    tags={tabs.uncheckedLinkTags}
                                    handleViewProcedureClick={handleViewProcedureClick}
                                    handleRowClick={handleLinkRowClick(uncheckedLinkIds)}
                                />
                            </div>
                        </>
                        <>
                            <div className="text-success" onClick={() => setSelectedTabIndex(6)}>
                                Links Checked ({tabs.checkedLinkTags.length})
                            </div>
                            <div className="col mt-3">
                                <LinksTable
                                    tags={tabs.checkedLinkTags}
                                    handleViewProcedureClick={handleViewProcedureClick}
                                    handleRowClick={handleLinkRowClick(checkedLinkIds)}
                                />
                            </div>
                        </>
                    </TabbedPane>
                </div>
            </div>
            <ViewProcedureModal
                procedureId={modalProcedureId}
                resetProcedureId={() => setModalProcedureId(null)}
                defaultCompareVersions={true}
            />
        </div>
    );
};

export default TaggerList;
