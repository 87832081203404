import React, { FC, useState, useEffect } from 'react';
import { CellProps, CategoryDropdownCellOption, IOption } from './types';
import calculateClass from 'components/Shared/Table/Cells/Helpers/calculateClass';
import { VehiclePublishExceptionTrackerFieldEnum } from 'helpers/VehicleProcessHelper';
import ReactSelect, { StylesConfig } from 'react-select';

const PublishExceptionCategory: (options?: CategoryDropdownCellOption) => FC<CellProps> = options => {
    const PublishExceptionCategoryComponent = ({ value, header, item, index }: CellProps) => {
        const publishExceptionCategoryId =
            (item?.publishException as { publishExceptionCategoryId?: string })?.publishExceptionCategoryId || null;
        const focusColor = '#84abc9';
        const blurColor = '#ced4da';
        const customStyles: StylesConfig = {
            control: (provided, state) => ({
                ...provided,
                borderColor: state.isFocused ? focusColor : blurColor,
                boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(9, 87, 146, 0.25)' : 'none',
                '&:hover': {
                    borderColor: state.isFocused ? focusColor : blurColor,
                },
            }),
        };
        const [category, setCategory] = useState(null);
        const { dropdownOptions } = options;
        const handleBlur = () => {
            if (category || publishExceptionCategoryId) {
                options.dropdownOptions.onBlur(
                    item ? item : {},
                    VehiclePublishExceptionTrackerFieldEnum.PUBLISH_EXCEPTION_CATEGORY.Id,
                    category?.value ? category.value : null
                );
            }
        };
        const handleOnChange = (selected: IOption | null) => {
            setCategory(selected);
        };
        useEffect(() => {
            const ddlData = dropdownOptions.options.find(x => x.value === value);
            setCategory(ddlData ? ddlData : null);
        }, [value, dropdownOptions.options]);
        return (
            <td
                headers={header.id}
                className={calculateClass(options, item)}
                id={`${header.id}${index}`}
                title={header.title}
                key={`${item.vehicleId}${header}`}>
                {!item.isVehicle ? (
                    <></>
                ) : (
                    <ReactSelect
                        id={'form-control-input-category' + item.vehicleId}
                        className={'custom-ddl-single-value'}
                        styles={customStyles}
                        value={category}
                        options={dropdownOptions.options}
                        onChange={handleOnChange}
                        onBlur={handleBlur}
                        placeholder={'Select Category'}
                        isDisabled={options.dropdownOptions.disabled}
                        isMulti={false}
                        isClearable={true}
                    />
                )}
            </td>
        );
    };
    return PublishExceptionCategoryComponent;
};
export default PublishExceptionCategory;
