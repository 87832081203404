import { useState, useEffect, useContext } from 'react';
import { requestVehiclePublishHistory } from 'api/vehicleInfo';
import { requestOrganizationUsers } from 'api/SecurityApi';
import { AccessControlContext } from 'components/Shared/AccessControl/AccessControl';

const useViewVehiclePublishHistoryContent = vehicleId => {
    const [vehicleHistory, setVehicleHistory] = useState([]);
    const [orgUsers, setOrgUsers] = useState([]);
    const { userInfo } = useContext(AccessControlContext);

    useEffect(() => {
        const getOrgUsers = async () => {
            if (userInfo) setOrgUsers(await requestOrganizationUsers(userInfo.organization.organizationId));
        };
        getOrgUsers();
    }, [userInfo]);

    useEffect(() => {
        const getVehiclePublishHistory = async () => {
            if (orgUsers.length > 0 && vehicleId) {
                let history = await requestVehiclePublishHistory(vehicleId);

                history.forEach(h => {
                    h.userName = orgUsers.find(u => u.userId === h.userId)?.userName;
                });
                history = history.sort((a, b) => new Date(b.actionDate) - new Date(a.actionDate));
                setVehicleHistory(history);
            }
        };
        getVehiclePublishHistory();
    }, [vehicleId, orgUsers]);

    return { vehicleHistory };
};

export default useViewVehiclePublishHistoryContent;
