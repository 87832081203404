import React from 'react';
import { useParams } from 'react-router-dom';
import Table from 'components/Shared/Table/Table';
import useTableActions from './useTableActions';
import useTableConfiguration from './tableConfiguration';
import './RefreshPublisher.scss';
import ProgressBar from 'components/Shared/ProgressBar/ProgressBar';
import SmallSpinner from 'components/SmallSpinner';
import RefreshPublisherHeader from './RefreshPublisherHeader';
import RefreshPublisherFooter from './RefreshPublisherFooter';
import { BookPublishState } from 'types/RefreshPublisherBookStatisticsType';
import { RefreshPublisherDataRowType } from './types';
import { Options } from 'components/Shared/Table/types';
import useRefreshPublisher from './useRefreshPublisher';

const disableCheckboxFn = (rd: RefreshPublisherDataRowType) => rd.bookPublishState !== BookPublishState.ReadyToPublish;

const tableOptions: Options = {
    checkboxes: true,
    disableCheckboxFn,
    stickyHeader: true,
};

const RefreshPublisher = () => {
    const { oemId } = useParams();

    const {
        bookLoading,
        statsLoading,
        isPublishing,
        dataToShow,
        totalBooks,
        searchValue,
        pagination,
        totalPage,
        loadBookStatsPercentage,
        bulkPublishStatus,
        selected,
        setSelected,
        publishRpBooks,
        reloadStatsForBooks,
        handleSearchChange,
        handleCurrentPageNumberChange,
        handleItemsPerPageChange,
        handleBulkPublishButtonClick,
    } = useRefreshPublisher(parseInt(oemId));

    const actions = useTableActions(publishRpBooks, reloadStatsForBooks);
    const headers = useTableConfiguration();

    const isProcessing = bookLoading || statsLoading || isPublishing;
    const shouldShowFooter = selected.length > 0 || !!bulkPublishStatus;

    return (
        <>
            <RefreshPublisherHeader
                searchValue={searchValue}
                onSearchValueChange={handleSearchChange}
                pagination={pagination}
                onPageNumberChange={handleCurrentPageNumberChange}
                totalPage={totalPage}
                onItemsPerPageChange={handleItemsPerPageChange}
                totalItems={totalBooks}
                disabled={bookLoading || isPublishing}
            />
            <ProgressBar percentage={loadBookStatsPercentage} />

            {isProcessing ? (
                <SmallSpinner
                    id="refresh-publisher-table-spinner"
                    data-testid="refresh-publisher-table-spinner"
                    className="my-3"
                />
            ) : (
                <Table
                    className="mb-5"
                    headers={headers}
                    data={dataToShow}
                    idKey="bookId"
                    cellEventCallbacks={actions}
                    selected={selected}
                    setSelected={setSelected}
                    options={tableOptions}
                />
            )}
            <RefreshPublisherFooter
                show={shouldShowFooter}
                onPublish={handleBulkPublishButtonClick}
                status={bulkPublishStatus}
            />
        </>
    );
};

export default RefreshPublisher;
