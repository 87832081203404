import { fetchWithAuthHeader } from './AuthUtils';

export const requestNetworks = async networkLevelIdFilter => {
    let url = '';
    if (networkLevelIdFilter) url = `api/Security/Network/${networkLevelIdFilter}`;
    else url = 'api/Security/Network';

    const response = await fetchWithAuthHeader(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
    });

    return await response.json();
};

export const requestNetworkTypes = async () => {
    const url = 'api/Security/NetworkTypes';
    const response = await fetchWithAuthHeader(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
    });

    return await response.json();
};

export const requestNetworkLevels = async () => {
    const url = 'api/Security/NetworkLevels';
    const response = await fetchWithAuthHeader(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
    });

    if (!response.ok) throw new Error('Failed to get networks');

    return await response.json();
};

export const createNetwork = async (name, networkTypeId, networkLevelId, note) => {
    const url = 'api/Security/Network';
    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            name,
            networkTypeId,
            networkLevelId,
            note,
        }),
    });

    if (!response.ok) throw new Error('Failed to create network');

    return await response.json();
};

export const editNetwork = async (networkId, name, networkTypeId, networkLevelId, note) => {
    const url = `api/Security/Network/${networkId}`;
    const response = await fetchWithAuthHeader(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            name,
            networkTypeId,
            networkLevelId,
            note,
        }),
    });

    if (!response.ok) throw new Error('Failed to edit network');

    return await response.json();
};

export const deleteNetwork = async networkId => {
    const url = `api/Security/Network/${networkId}`;
    const response = await fetchWithAuthHeader(url, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
        },
    });

    if (!response.ok) throw new Error('Failed to delete network');

    return true;
};

export const removeOrganizationFromNetwork = async (networkId, organizationId) => {
    const url = 'api/Security/Network/RemoveOrganizationFromNetwork';
    const response = await fetchWithAuthHeader(url, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            networkId,
            organizationId,
        }),
    });

    if (!response.ok) throw new Error('Failed to delete network from organzation');

    return true;
};

export const addOrganizationToNetwork = async (networkId, organizationId) => {
    const url = 'api/Security/Network/AddOrganizationToNetwork';
    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            networkId,
            organizationId,
        }),
    });

    if (!response.ok) throw new Error('Failed to add network to organization');

    return await response.json();
};

export const removeCompanyFromNetwork = async (networkId, companyId) => {
    const url = 'api/Security/Network/RemoveCompanyFromNetwork';
    const response = await fetchWithAuthHeader(url, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            networkId,
            companyId,
        }),
    });

    if (!response.ok) throw new Error('Failed to delete network from company');

    return true;
};

export const addCompanyToNetwork = async (networkId, companyId) => {
    const url = 'api/Security/Network/AddCompanyToNetwork';
    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            networkId,
            companyId,
        }),
    });

    if (!response.ok) throw new Error('Failed to add network to company');

    return await response.json();
};
