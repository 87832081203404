import React, { useCallback } from 'react';
import { FILTER_INPUT_TYPE_ENUM } from 'helpers/BulkEditTableHelper';
import { Select, Input } from 'oemiq-common';

const FilterInput = ({ id, inputTypeId, value, onChange, onBlur, ...props }) => {
    const handleKeyPress = useCallback(e => {
        if (e.key === 'Enter' || e.key === 'Tab') {
            e.target.blur();
        }
    }, []);

    switch (inputTypeId) {
        case FILTER_INPUT_TYPE_ENUM.INPUT:
            return (
                <Input
                    id={id}
                    value={value}
                    onChange={e => onChange(e.currentTarget.value)}
                    onBlur={e => onBlur(e.currentTarget.value)}
                    formGroupClassName="mb-0 align-self-center"
                    onKeyPress={handleKeyPress}
                    {...props}
                />
            );
        case FILTER_INPUT_TYPE_ENUM.DROPDOWN:
            return (
                <Select
                    id={id}
                    className="form-select"
                    value={value}
                    onChange={e => onBlur(e.currentTarget.value)}
                    formGroupClassName="mb-0 align-self-center"
                    {...props}
                />
            );
        case FILTER_INPUT_TYPE_ENUM.DATE_PICKER:
            return (
                <input
                    id={id}
                    value={value}
                    onChange={e => onChange(e.currentTarget.value)}
                    onBlur={e => onBlur(e.currentTarget.value)}
                    className="form-control"
                    type="date"
                    {...props}
                />
            );
        default:
            throw `Unsupported Filter Input Type. Value provided ${inputTypeId}`;
    }
};

export default FilterInput;
