import React from 'react';
import useMapperSearch from './useMapperSearch';
import ReactSelect from 'react-select';
import mapToOptions from 'helpers/MapToOptions';

const MapperSearch = ({ searchProcedures }) => {
    const {
        searchOptions,
        searchValueSelections,
        handleOptionsSelectChange,
        localSearchValue,
        setLocalSearchValue,
        handleSearch,
        handleSearchKeyPress,
    } = useMapperSearch(searchProcedures);

    return (
        <div className="mb-2 d-flex align-items-center justify-content-between">
            <div className="w-75">
                <input
                    id="procedure-search"
                    className="form-control"
                    value={localSearchValue}
                    onChange={e => setLocalSearchValue(e.currentTarget.value)}
                    onBlur={() => handleSearch()}
                    onKeyPress={e => handleSearchKeyPress(e)}
                    placeholder="Search"
                />
            </div>
            <div className="ms-2 flex-fill">
                <ReactSelect
                    isMulti
                    placeholder="Search on"
                    value={searchOptions.filter(o => searchValueSelections.includes(o.value)).map(mapToOptions)}
                    options={searchOptions.map(mapToOptions)}
                    onChange={handleOptionsSelectChange}
                />
            </div>
        </div>
    );
};

export default MapperSearch;
