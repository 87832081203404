import { useCallback } from 'react';
import { ReactModal } from 'oemiq-common';

const FlagDispositionModal = ({ open, toggle, value, options, onChange, onSave, title = '' }) => {
    const handleChange = useCallback(
        e => {
            onChange(e.currentTarget.value);
        },
        [onChange]
    );

    const handleSave = useCallback(() => {
        onSave(parseInt(value));
    }, [value, onSave]);

    return (
        <ReactModal
            id="disposition-flag-modal"
            isOpen={open}
            toggle={toggle}
            headerComponent={<div>{`Flag disposition${title ? `for: ${title}` : ''}`}</div>}
            bodyComponent={
                <div>
                    <select
                        id="flag-disposition-select"
                        className="form-control form-control-sm mt-2"
                        value={value}
                        onChange={handleChange}>
                        <option value="" disabled="disabled">
                            Select Flag Disposition
                        </option>
                        {options.map(({ key, value, text }) => (
                            <option key={key} value={value}>
                                {text}
                            </option>
                        ))}
                    </select>
                </div>
            }
            footerComponent={
                <div>
                    <button type="button" className="btn btn-sm btn-primary" disabled={!value} onClick={handleSave}>
                        Save
                    </button>
                </div>
            }
        />
    );
};

export default FlagDispositionModal;
