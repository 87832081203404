import React from 'react';
import { ProcedureType } from 'hooks/OemModels/MetaModel/ProcedureType';
import ReactSelect from 'react-select';

const selectOptions = [ProcedureType.Procedure, ProcedureType.Bulletin, ProcedureType.PositionStatement].map(v => ({
    value: v,
    label: v,
}));

export const ProcedureTypeInput = ({ filter, setFilterValue }: { filter; setFilterValue }) => {
    return (
        <ReactSelect
            className="mb-2"
            options={selectOptions}
            isDisabled={filter.isAppOnly}
            value={filter.value ? selectOptions.find(f => f.value === filter.value) : null}
            onChange={v => setFilterValue(v)}
        />
    );
};

const operatorsList = {
    is: {
        value: 'procedureType.is',
        label: 'is',
    },
};
export default {
    component: ProcedureTypeInput,
    validator: v => v,
    operators: [operatorsList.is],
    defaultValue: '',
    allowFalse: false,
    allowInstances: 1,
};
