import currency from 'currency.js';

/**
 * Helper functions related to currencies on the platform
 * ---------------------------------
 */

/**
 * Function to format and truncate currency values. Avoids float multiplication/division issues
 * @param {Currency value to format, can be string, number, etc.} value
 * @param {Decimal places to format to, default 2} decimalPlaces
 */

export function FormatCurrency(value, decimalPlaces = 2) {
    if (isNaN(value)) {
        return currency(0, { precision: decimalPlaces });
    }
    const baseTen = Math.pow(10, decimalPlaces);
    // use extra precision to avoid currency library rounding to nearest precision
    let formattedPrice = currency(value, { precision: decimalPlaces + 1 }).multiply(baseTen);
    // truncate down to the decimal specified without rounding
    formattedPrice = Math.trunc(formattedPrice);
    // convert back to currency
    formattedPrice = currency(formattedPrice).divide(baseTen);
    // return currency truncated to correct precision
    return currency(formattedPrice, { precision: decimalPlaces });
}
