import { createContext, useContext } from 'react';
import { NewDataColumnSettings } from './types';

export const RowContext = createContext(null);
export const ColumnContext = createContext<string>(null);
export const TableContext = createContext<{
    orderedColumns: string[];
    columnSettings: Record<string, NewDataColumnSettings>;
}>({
    orderedColumns: [],
    columnSettings: null,
});

export const useRow = <T>(): T => {
    return useContext(RowContext);
};
