import { requestGetFeatures } from 'api/SecurityApi';
import { ToastContext } from 'components/ToastProvider';
import { useContext, useEffect, useState } from 'react';

const useFeatures = () => {
    const [features, setFeatures] = useState([]);
    const [loading, setLoading] = useState(false);
    const { showToast } = useContext(ToastContext);

    useEffect(() => {
        let isUnmounted = false;

        (async () => {
            try {
                setLoading(true);
                const features = await requestGetFeatures();

                if (!isUnmounted) {
                    setFeatures(features);
                }
            } catch (error) {
                showToast(error);
            } finally {
                if (!isUnmounted) {
                    setLoading(false);
                }
            }
        })();

        return () => (isUnmounted = true);
    }, [showToast]);

    return { loading, features };
};

export default useFeatures;
