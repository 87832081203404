import { fetchWithAuthHeader } from './AuthUtils';

export const requestImports = async () => {
    const url = 'api/NvdInfo/odata/ImportHistory?$count=true&$orderby=ImportDate desc&$top=100';
    let response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error('Could not retrieve Import History', { status: response.status });
    const data = await response.json();
    return data.value;
};

export const requestImportRecord = async importHistoryId => {
    const url = `api/NvdInfo/odata/ImportHistory?$top=1&$count=false&$filter=ImportHistoryId eq ${importHistoryId}`;
    let response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error('Could not retrieve Import History', { status: response.status });
    const data = await response.json();
    return data.value[0];
};
