import { PrimaryButton } from 'oemiq-common';
import SmallSpinner from 'components/SmallSpinner';
import ReactSelect from 'react-select';

const unassignButtonId = 'unassign-oem-model-btn';
const unassignButtonExtendedProps = {
    id: unassignButtonId,
    buttonProps: {
        'data-testid': unassignButtonId,
    },
};

const OEMModelSearch = ({
    id,
    disabled,
    loading,
    oemModelOptions,
    oemModelValue,
    onOemModelChange,
    onUnassignButtonClick,
    className,
    style,
}) => {
    const resultsCount = loading ? 0 : oemModelOptions.length;
    return (
        <div id={id} data-testid={id} className={className} style={style}>
            <div className="d-flex flex-row align-items-center">
                <ReactSelect
                    isMulti
                    className="col mt-2"
                    tabIndex={-1}
                    placeholder={
                        loading ? (
                            <SmallSpinner />
                        ) : resultsCount == 0 ? (
                            <span className="badge text-bg-secondary">No available options</span>
                        ) : null
                    }
                    options={oemModelOptions}
                    value={oemModelValue}
                    onChange={onOemModelChange}
                    isDisabled={disabled}
                />
                {!!onUnassignButtonClick && (
                    <PrimaryButton
                        type="button"
                        buttonProps={{ style: { minWidth: '120px' } }}
                        disabled={loading || disabled}
                        onClick={onUnassignButtonClick}
                        className="mx-2"
                        {...unassignButtonExtendedProps}>
                        Unassign
                    </PrimaryButton>
                )}
            </div>

            {!disabled && (
                <div className="text-left" style={{ minWidth: '55px', borderRadius: '1.3rem' }}>
                    <span
                        id={`${id}-count`}
                        data-testid={`${id}-count`}
                        style={{
                            borderRadius: '2px',
                            minWidth: '25px',
                            fontSize: '75%',
                            fontWeight: 400,
                            padding: '3px 6px',
                            marginLeft: '15px',
                        }}>
                        {resultsCount > 0 ? `${resultsCount} result${resultsCount !== 1 ? 's' : ''}` : ''}
                    </span>
                </div>
            )}
        </div>
    );
};

export default OEMModelSearch;
