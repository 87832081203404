import { Labels, Number, Text } from 'components/Shared/Table/Cells';
import { LabelWithPills } from 'components/Shared/Table/types';
import { FC } from 'react';

export interface TableConfigurationEntity {
    label: string;
    id: string;
    thClass?: string;
    component?: FC;
}

const TableConfiguration: Array<TableConfigurationEntity> = [
    {
        label: 'History ID',
        id: 'procedureMappingHistoryId',
        thClass: 'text-center',
        component: Number(),
    },
    {
        label: 'Groups',
        id: 'groupsNames',
        thClass: 'text-center',
        component: Labels({
            labelClass: 'bg-primary',
            class: 'text-center',
            labels: i =>
                (i.groupsNames as string[]).map(gn => {
                    return { labelText: gn };
                }) as LabelWithPills[],
        }),
    },
    {
        label: 'Type',
        id: 'oemIqSectionName',
        thClass: 'text-center',
        component: Labels({
            labelClass: 'bg-primary',
            class: 'text-center',
            labels: i => [{ labelText: i.oemIqSectionName as string }] as LabelWithPills[],
        }),
    },
    {
        label: 'Action',
        id: 'mappingActionName',
        thClass: 'text-center',
        component: Text({ class: 'text-center' }),
    },
    {
        label: 'Rule ID',
        id: 'mappingRuleId',
        thClass: 'text-center',
        component: Number(),
    },
    {
        label: 'User',
        id: 'userName',
        thClass: 'text-center',
        component: Text({ class: 'text-center' }),
    },
    {
        label: 'Status',
        id: 'mappingWorkFlowStatusName',
        thClass: 'text-center',
        component: Text({ class: 'text-center' }),
    },
    {
        label: 'Create Date',
        id: 'createDateGMT',
        thClass: 'text-center',
        component: Number(),
    },
    {
        label: 'Update Date',
        id: 'updateDateGMT',
        thClass: 'text-center',
        component: Number(),
    },
];

export default TableConfiguration;
