import React, { useCallback } from 'react';
import TagTableItem from './TagTableItem/TagTableItem';
import { LargeCheckbox, PrimaryButton } from 'oemiq-common';
import SortableTableHeader from '../SortableTableHeader/SortableTableHeader';
import TagStatusUpdateModal from './TagStatusUpdateModal';
import useTagSorting from './useTagSorting';
import { useNavigate } from 'react-router-dom';

const TagTable = ({
    tags,
    setDisplayProcedureIds,
    tagProcedureIds,
    searchParamsObject,
    handleViewProcedureClick,
    onCheckboxClick,
    selectedTags,
    statusModalOpen,
    toggleStatusModal,
    tabStatus,
    statusOptions,
    onStatusModalSave,
}) => {
    const navigate = useNavigate();

    const {
        sortedTags,
        sortTagColumn: sortColumn,
        handleTagSortColumnClick: handleSortColumnClick,
    } = useTagSorting(tags);

    const handleTagRowClick = procedureId => {
        setDisplayProcedureIds(tagProcedureIds);
        navigate(`${location.pathname}/tag/${procedureId}`, { state: searchParamsObject });
    };

    const handleMasterCheckboxClick = useCallback(() => {
        if (tags) {
            if (selectedTags.size === 0 || selectedTags.size === tags.length) {
                onCheckboxClick(tags);
            } else {
                onCheckboxClick(tags.filter(t => !selectedTags.has(t.stagedOneTimeUseTagId)));
            }
        }
    }, [onCheckboxClick, selectedTags, tags]);

    return (
        <>
            <PrimaryButton
                id="bulk-tag-status-open-modal-button"
                onClick={toggleStatusModal}
                disabled={!selectedTags.size}>
                Bulk Move
            </PrimaryButton>
            <table className="table table-striped table-hover table-responsive">
                <thead id="tag-table-header">
                    <tr>
                        <th>
                            <LargeCheckbox
                                id={'bulk-select-all'}
                                label=""
                                className="large-checkbox-check mb-0"
                                checked={tags.length > 0 && tags.length === selectedTags.size}
                                onChange={handleMasterCheckboxClick}
                                readOnly={false}
                                // style={{ top: '-23px' }}
                            />
                        </th>
                        <th>Preview Icon</th>
                        <SortableTableHeader
                            title={'Staged Tag ID'}
                            propertyKey={'stagedOneTimeUseTagId'}
                            sortColumn={sortColumn}
                            handleSortColumnClick={handleSortColumnClick}
                        />
                        <SortableTableHeader
                            title={'Tag ID'}
                            propertyKey={'oneTimeUseTagId'}
                            sortColumn={sortColumn}
                            handleSortColumnClick={handleSortColumnClick}
                        />
                        <SortableTableHeader
                            title={'Tag Name'}
                            propertyKey={'title'}
                            sortColumn={sortColumn}
                            handleSortColumnClick={handleSortColumnClick}
                        />
                        <SortableTableHeader
                            title={'Tag Text'}
                            propertyKey={'text'}
                            sortColumn={sortColumn}
                            handleSortColumnClick={handleSortColumnClick}
                        />
                        <SortableTableHeader
                            title={'Quantity'}
                            propertyKey={'quantity'}
                            sortColumn={sortColumn}
                            handleSortColumnClick={handleSortColumnClick}
                        />
                        <SortableTableHeader
                            title={'Quantity Condition'}
                            propertyKey={'quantityCondition'}
                            sortColumn={sortColumn}
                            handleSortColumnClick={handleSortColumnClick}
                        />
                        <SortableTableHeader
                            title={'Part Number'}
                            propertyKey={'partNumber'}
                            sortColumn={sortColumn}
                            handleSortColumnClick={handleSortColumnClick}
                        />
                        <SortableTableHeader
                            title={'MSRP'}
                            propertyKey={'price'}
                            sortColumn={sortColumn}
                            handleSortColumnClick={handleSortColumnClick}
                        />
                        <SortableTableHeader
                            title={'1xID Type'}
                            propertyKey={'oneTimeUsePartType'}
                            sortColumn={sortColumn}
                            handleSortColumnClick={handleSortColumnClick}
                        />
                        <SortableTableHeader
                            title={'Is Active'}
                            propertyKey={'isActive'}
                            sortColumn={sortColumn}
                            handleSortColumnClick={handleSortColumnClick}
                        />
                        <SortableTableHeader
                            title={'Procedure ID'}
                            propertyKey={'procedureId'}
                            sortColumn={sortColumn}
                            handleSortColumnClick={handleSortColumnClick}
                        />
                        <SortableTableHeader
                            title={'Procedure Title'}
                            propertyKey={'procedureTitle'}
                            sortColumn={sortColumn}
                            handleSortColumnClick={handleSortColumnClick}
                        />
                        <SortableTableHeader
                            title={'Groups'}
                            propertyKey={'groups'}
                            sortColumn={sortColumn}
                            handleSortColumnClick={handleSortColumnClick}
                        />
                        <SortableTableHeader
                            title={'Type'}
                            propertyKey={'type'}
                            sortColumn={sortColumn}
                            handleSortColumnClick={handleSortColumnClick}
                        />
                    </tr>
                </thead>
                <tbody>
                    {sortedTags.map(tag => (
                        <TagTableItem
                            key={tag.stagedOneTimeUseTagId}
                            tag={tag}
                            handleTagRowClick={handleTagRowClick}
                            handleViewProcedureClick={handleViewProcedureClick}
                            onCheckboxClick={onCheckboxClick}
                            checked={selectedTags.has(tag.stagedOneTimeUseTagId)}
                        />
                    ))}
                </tbody>
            </table>
            <TagStatusUpdateModal
                open={statusModalOpen}
                toggle={toggleStatusModal}
                tabStatus={tabStatus}
                options={statusOptions}
                onSave={onStatusModalSave}
            />
        </>
    );
};

export default TagTable;
