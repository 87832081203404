import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CellOperationStatus = ({ status }) => {
    switch (status) {
        case 'Finished':
            return (
                <>
                    <span className="badge bg-success text-white pt-1" title="Operation was completed">
                        {status}
                    </span>
                </>
            );
        case 'Aborted':
            return (
                <span
                    className="badge bg-warning text-dark pt-1"
                    title="Undo operation was aborted because some (or all) of mappigs are in Complete status. Removing completed mappings would remove the procedure from Repair Logic">
                    {status} <FontAwesomeIcon icon="eye" />
                </span>
            );
        case 'Failed':
            return (
                <span className="badge bg-danger text-white pt-1" title="Some unexpected error broke the operation">
                    {status}
                </span>
            );
        default:
            return <span>{status}</span>;
    }
};

export default CellOperationStatus;
