const apiPrefix = 'api/';
export const ApiEndpoints = {
    organization: {
        fetch: `${apiPrefix}Security/Organization`,
        fetchById: (orgId: number) => `${apiPrefix}Security/Organization/${orgId}`,
        updateActiveStatus: (orgId: number, isActive: boolean) =>
            `${apiPrefix}Security/Organization/${orgId}/UpdateActiveStatus/${isActive}`,
        globalSearch: `${apiPrefix}Security/Organization/g-search`,
    },
    company: {
        fetch: (orgId: number) => `${apiPrefix}Security/Organization/${orgId}/companies`,
        fetchById: (companyId: number) => `${apiPrefix}Security/Company/${companyId}`,
    },
    user: {
        fetch: (companyId: number, queryActiveUsers: boolean) =>
            `${apiPrefix}Security/Company/${companyId}/users?queryActiveUsers=${queryActiveUsers}`,
        fetchById: (userId: number) => `${apiPrefix}Security/User/${userId}`,
    },
    location: {
        updateStatus: (companyId: number, isActive: boolean) =>
            `${apiPrefix}Security/Company/${companyId}/UpdateActiveStatus/${isActive}`,
    },
};
