import { useState, useEffect, useContext } from 'react';
import {
    requestMapProceduresToType,
    requestMapProcedureGroupApi,
    requestRemoveMappingApi,
    requestUpdateRegionStatusApi,
    requestUnmapType,
} from 'api/RepairProcedureApi';
import useMappingWorkflowStatus from 'hooks/useMappingWorkflowStatus';
import { LoadingContext } from 'components/Layout';
import { OlsMapperListContext } from 'contexts/MapperListContext';
import { MappingDefinitionsContext } from 'contexts/MappingDefinitionsContext';
import { AccessControlContext } from 'components/Shared/AccessControl/AccessControl';
import { roles } from 'components/Shared/AccessControl/privilegesMap';
import { ESProcedure } from 'components/locations/MappingProcess/Mapper/ESProcedure';
import { isNil } from 'lodash';

const useGroupTypeModal = (
    groupTypeModalProcedureIds: number[],
    resetProcedureIds: () => void,
    setNewGroupListToProcedureByProcedureId: (newGroupList, procedureId) => void,
    procedures: ESProcedure[],
    resetBulkSelection: () => void,
    groupTypeModalBulkType: string | null,
    setGroupTypeModalBulkType,
    oemId: number,
    oDataFilter: string
) => {
    const [selectedGroupIdsWithStatusId, setSelectedGroupIdsWithStatusId] = useState([]);
    const [selectedTypeIdWithStatusId, setSelectedTypeIdWithStatusId] = useState(null);
    const [typeSelectionOptions, setTypeSelectionOptions] = useState([]);
    const { getMappingWorkFlowStatusById } = useMappingWorkflowStatus();
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const { types } = useContext(MappingDefinitionsContext);
    const { refreshProcedures, updateProcedures, updateOemIqTypeForProcedureIds } = useContext(OlsMapperListContext);
    const { hasRole } = useContext(AccessControlContext);

    useEffect(() => {
        if (groupTypeModalProcedureIds.length === 1) {
            const procedure = procedures.find(p => p.procedureId === groupTypeModalProcedureIds[0]);
            if (!procedure) return;

            const procGroupIdsWithStatusIds = procedure.stageArea.groups.map(group => ({
                groupId: group.groupId,
                statusId: group.mappingStatusId,
            }));
            const procedureType = procedure.stageArea.type;
            const procTypeIdWithStatusId = isNil(procedureType.typeId)
                ? null
                : { typeId: procedureType.typeId, statusId: procedureType.mappingStatusId };
            setSelectedGroupIdsWithStatusId(procGroupIdsWithStatusIds);
            setSelectedTypeIdWithStatusId(procTypeIdWithStatusId);
        }
    }, [procedures, groupTypeModalProcedureIds]);

    useEffect(() => {
        const typeOptions = [];
        types.map(o => {
            const option = { value: o.oemIqSectionId, text: o.oemIqSectionName };
            return typeOptions.push(option);
        });
        setTypeSelectionOptions(typeOptions);
    }, [types]);

    const handleModalToggle = () => {
        resetProcedureIds();
        setSelectedGroupIdsWithStatusId([]);
        setSelectedTypeIdWithStatusId(null);
        setGroupTypeModalBulkType(null);
    };

    const handleRegionClick = async groupId => {
        setSelectedGroupIdsWithStatusId(currState => {
            let newState = [...currState];
            const index = newState.findIndex(n => n.groupId === groupId);

            if (groupTypeModalBulkType && groupTypeModalBulkType === 'Remove') {
                if (index < 0) newState.push({ groupId: groupId, statusId: 100 });
                else newState = newState.filter(n => n.groupId !== groupId);
            } else {
                if (index < 0) newState.push({ groupId: groupId, statusId: 1 });
                else if (newState[index].statusId === 1) newState[index].statusId = 2;
                else if (newState[index].statusId === 2) newState[index].statusId = 3;
                else newState = newState.filter(n => n.groupId !== groupId);
            }

            return newState;
        });
    };

    const handleTypeStatusSelection = typeId => {
        if (groupTypeModalBulkType && groupTypeModalBulkType === 'Remove') {
            if (selectedTypeIdWithStatusId && selectedTypeIdWithStatusId.typeId === typeId) {
                if (selectedTypeIdWithStatusId.statusId === 100) setSelectedTypeIdWithStatusId(null);
            } else setSelectedTypeIdWithStatusId({ typeId: typeId, statusId: 100 });
        } else if (selectedTypeIdWithStatusId && selectedTypeIdWithStatusId.typeId === typeId) {
            if (selectedTypeIdWithStatusId.statusId === 1 && hasRole(roles.admin, roles.siteAdmin, roles.dataSME))
                setSelectedTypeIdWithStatusId({ typeId: typeId, statusId: 2 });
            else if (selectedTypeIdWithStatusId.statusId === 1)
                setSelectedTypeIdWithStatusId({ typeId: typeId, statusId: 3 });
            else if (selectedTypeIdWithStatusId.statusId === 2)
                setSelectedTypeIdWithStatusId({ typeId: typeId, statusId: 3 });
            else if (selectedTypeIdWithStatusId.statusId === 3) setSelectedTypeIdWithStatusId(null);
        } else setSelectedTypeIdWithStatusId({ typeId: typeId, statusId: 1 });
    };

    const handleBulkMap = async () => {
        const bulkMapGroups = async procs => {
            if (selectedGroupIdsWithStatusId.length <= 0) {
                return procs;
            }

            const selectedGroupIds = selectedGroupIdsWithStatusId.map(n => n.groupId);
            const completeGroupIds = selectedGroupIdsWithStatusId.filter(n => n.statusId === 2).map(n => n.groupId);
            const needsHelpGroupIds = selectedGroupIdsWithStatusId.filter(n => n.statusId === 3).map(n => n.groupId);

            const { procedureIds } = await requestMapProcedureGroupApi(
                groupTypeModalProcedureIds,
                selectedGroupIds,
                oemId,
                oDataFilter
            );

            if (completeGroupIds.length > 0)
                await requestUpdateRegionStatusApi(groupTypeModalProcedureIds, completeGroupIds, 2, oemId, oDataFilter);
            if (needsHelpGroupIds.length > 0)
                await requestUpdateRegionStatusApi(
                    groupTypeModalProcedureIds,
                    needsHelpGroupIds,
                    3,
                    oemId,
                    oDataFilter
                );

            const proceduresToUpdate = procs.filter(procedure => procedureIds.includes(procedure.procedureId));
            proceduresToUpdate.forEach(procedure => {
                const newGroups = [...procedure.stageArea.groups];
                selectedGroupIdsWithStatusId.forEach(selectedGroup => {
                    if (!newGroups.find(group => group.groupId === selectedGroup.groupId)) {
                        newGroups.push({
                            groupId: selectedGroup.groupId,
                            mappingStatusId: selectedGroup.statusId,
                            mappingRuleId: null,
                        });
                    }
                });
                procedure.stageArea.groups = newGroups;
            });

            return proceduresToUpdate;
        };

        const bulkMapType = async procs => {
            if (!selectedTypeIdWithStatusId) {
                return procs;
            }

            const { procedureIds } = await requestMapProceduresToType(
                parseInt(selectedTypeIdWithStatusId.typeId),
                groupTypeModalProcedureIds,
                selectedTypeIdWithStatusId.statusId,
                oemId,
                oDataFilter
            );

            const proceduresToUpdate = procs.filter(procedure => procedureIds.includes(procedure.procedureId));
            proceduresToUpdate.forEach(
                procedure =>
                    (procedure.stageArea.type = {
                        typeId: selectedTypeIdWithStatusId.typeId,
                        mappingStatusId: selectedTypeIdWithStatusId.statusId,
                        mappingRuleId: null,
                    })
            );

            return proceduresToUpdate;
        };

        const updatedProcedures = [...procedures];
        const groupMappedProcedures = await bulkMapGroups(updatedProcedures);
        const typeMappedProcedures = await bulkMapType(groupMappedProcedures);

        updateProcedures(typeMappedProcedures); // merged
    };

    const handleBulkRemove = async () => {
        const bulkRemoveGroups = async (procs, removeGroupIdList) => {
            if (removeGroupIdList.length <= 0) {
                return procs;
            }

            const { procedureIds } = await requestRemoveMappingApi(
                groupTypeModalProcedureIds,
                removeGroupIdList,
                oemId,
                oDataFilter
            );

            const proceduresToUpdate = procs.filter(procedure => procedureIds.includes(procedure.procedureId));
            proceduresToUpdate.forEach(procedure => {
                procedure.stageArea.groups = procedure.stageArea.groups.filter(
                    group => !removeGroupIdList.includes(group.groupId)
                );
            });

            return proceduresToUpdate;
        };

        const bulkRemoveType = async procs => {
            if (!selectedTypeIdWithStatusId) {
                return procs;
            }

            const { typeId } = selectedTypeIdWithStatusId;

            if (!typeId) {
                return procs;
            }
            const { procedureIds } = await requestUnmapType(groupTypeModalProcedureIds, typeId);

            const proceduresToUpdate = procs.filter(procedure => procedureIds.includes(procedure.procedureId));
            proceduresToUpdate.forEach(
                procedure =>
                    (procedure.stageArea.type = {
                        typeId: null,
                        mappingStatusId: null,
                        mappingRuleId: null,
                    })
            );
            return proceduresToUpdate;
        };

        const removeGroupIdList = selectedGroupIdsWithStatusId.filter(n => n.statusId === 100).map(n => n.groupId);
        const updatedProcedures = [...procedures];
        const groupMappedProcedures = await bulkRemoveGroups(updatedProcedures, removeGroupIdList);
        const typeMappedProcedures = await bulkRemoveType(groupMappedProcedures);

        updateProcedures(typeMappedProcedures);
    };

    const handleMapProcedures = async () => {
        incrementLoading();

        if (groupTypeModalBulkType && groupTypeModalBulkType === 'Remove') {
            await handleBulkRemove();
        } else if (groupTypeModalBulkType && groupTypeModalBulkType === 'Assign') {
            await handleBulkMap();
        } else {
            await handleMapProcedureSingle();
            await handleMapTypeSingle();
        }

        resetProcedureIds();
        setSelectedGroupIdsWithStatusId([]);
        resetBulkSelection();
        setSelectedTypeIdWithStatusId(null);
        setGroupTypeModalBulkType(null);
        refreshProcedures();
        decrementLoading();
    };

    const handleMapTypeSingle = async () => {
        if (selectedTypeIdWithStatusId !== null) {
            await requestMapProceduresToType(
                parseInt(selectedTypeIdWithStatusId.typeId),
                groupTypeModalProcedureIds,
                selectedTypeIdWithStatusId.statusId,
                oemId,
                oDataFilter
            );
            updateOemIqTypeForProcedureIds(
                types.filter(o => o.oemIqSectionId === parseInt(selectedTypeIdWithStatusId.typeId))[0],
                groupTypeModalProcedureIds,
                selectedTypeIdWithStatusId.statusId
            );
        } else {
            await requestUnmapType(groupTypeModalProcedureIds);
            updateOemIqTypeForProcedureIds(null, groupTypeModalProcedureIds, null);
        }
    };

    const handleMapProcedureSingle = async () => {
        const procId = groupTypeModalProcedureIds[0];
        const procGroupIds = procedures
            .find(pr => pr.procedureId === procId)
            .stageArea.groups.map(group => group.groupId);
        let removeGroupsList = [...procGroupIds];
        const addGroupIdsList = selectedGroupIdsWithStatusId
            .filter(selected => !procGroupIds.includes(selected.groupId))
            .map(group => group.groupId);
        const inReviewGroupIds = selectedGroupIdsWithStatusId.filter(n => n.statusId === 1).map(n => n.groupId);
        const completeGroupIds = selectedGroupIdsWithStatusId.filter(n => n.statusId === 2).map(n => n.groupId);
        const needsHelpGroupIds = selectedGroupIdsWithStatusId.filter(n => n.statusId === 3).map(n => n.groupId);
        const newGroupList = [];

        if (selectedGroupIdsWithStatusId.length > 0) {
            selectedGroupIdsWithStatusId.forEach(g => {
                removeGroupsList = removeGroupsList.filter(r => r !== g.groupId);
                newGroupList.push({
                    procedureId: procId,
                    mappingWorkFlowStatus: getMappingWorkFlowStatusById(g.statusId),
                    regionId: g.groupId,
                });
            });

            //Map the new group ids first, then set the new status accordingly
            if (addGroupIdsList.length > 0)
                await requestMapProcedureGroupApi(groupTypeModalProcedureIds, addGroupIdsList, oemId, oDataFilter);
            if (inReviewGroupIds.length > 0)
                await requestUpdateRegionStatusApi(groupTypeModalProcedureIds, inReviewGroupIds, 1, oemId, oDataFilter);
            if (completeGroupIds.length > 0)
                await requestUpdateRegionStatusApi(groupTypeModalProcedureIds, completeGroupIds, 2, oemId, oDataFilter);
            if (needsHelpGroupIds.length > 0)
                await requestUpdateRegionStatusApi(
                    groupTypeModalProcedureIds,
                    needsHelpGroupIds,
                    3,
                    oemId,
                    oDataFilter
                );
            if (removeGroupsList.length > 0)
                await requestRemoveMappingApi(groupTypeModalProcedureIds, removeGroupsList, oemId, oDataFilter);

            setNewGroupListToProcedureByProcedureId(newGroupList, procId);
        } else {
            if (removeGroupsList.length > 0)
                await requestRemoveMappingApi(groupTypeModalProcedureIds, removeGroupsList, oemId, oDataFilter);

            setNewGroupListToProcedureByProcedureId(newGroupList, procId);
        }
    };

    return {
        handleRegionClick,
        handleMapProcedures,
        handleModalToggle,
        selectedTypeIdWithStatusId,
        handleTypeStatusSelection,
        typeSelectionOptions,
        selectedGroupIdsWithStatusId,
    };
};

export default useGroupTypeModal;
