import { fetchWithAuthHeader } from './AuthUtils';

export const requestGetUserInfoApi = async () => {
    const url = 'api/Security/UserAccount';
    const response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error('Could not get user info');
    return await response.json();
};

export const requestGetInternalUsersApi = async (queryActiveUsers = false) => {
    const url = `api/Security/GetOemIqInternalUsers?queryActiveUsers=${queryActiveUsers}`;
    const response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error('Could not get internal users');
    return await response.json();
};
