import { useContext, useEffect, useCallback, useState, useMemo } from 'react';
import { MappingDefinitionsContext } from 'contexts/MappingDefinitionsContext';
import { requestGetFlagsApi, requestGetTagsApi, requestUpdateOneTimeUseTag } from 'api/RepairProcedureApi';
import { LoadingContext } from 'components/Layout';
import { ToastContext } from 'components/ToastProvider';
import { filterUntaggedFlags } from 'helpers/FlagTagHelper';
import { INPUT_TYPE_ENUM } from 'enums/InputTypeEnum';

const defaultOperators = Object.freeze(['include', 'exclude']);

const flagAndTagPropertiesListInitialState = [
    {
        propertyName: 'oneTimeUseFlagId',
        displayName: 'Flag ID',
        filterOperators: defaultOperators,
    },
    {
        propertyName: 'stagedOneTimeUseTagId',
        displayName: 'Staged Tag ID',
        filterOperators: defaultOperators,
    },
    {
        propertyName: 'oneTimeUseTagId',
        displayName: 'Tag ID',
        filterOperators: defaultOperators,
    },
    {
        propertyName: 'title',
        displayName: 'Flag/Tag Name',
        filterOperators: defaultOperators,
    },
    {
        propertyName: 'text',
        displayName: 'Flag/Tag Text',
        filterOperators: defaultOperators,
    },
    {
        propertyName: 'quantity',
        displayName: 'Quantity',
        filterOperators: defaultOperators,
    },
    {
        propertyName: 'quantityCondition',
        displayName: 'Quantity Condition',
        filterOperators: defaultOperators,
    },
    {
        propertyName: 'procedureId',
        displayName: 'Procedure ID',
        filterOperators: defaultOperators,
    },
    {
        propertyName: 'partNumber',
        displayName: 'Part Number',
        filterOperators: defaultOperators,
    },
    {
        propertyName: 'price',
        displayName: 'MSRP',
        filterOperators: defaultOperators,
    },
    {
        propertyName: 'groups',
        displayName: 'Groups',
        filterOperators: [...defaultOperators, 'equal'],
        filterInput: {
            type: INPUT_TYPE_ENUM.DROPDOWN,
            defaultOption: { value: '', text: 'Select group' },
            options: [],
        },
    },
    {
        propertyName: 'type',
        displayName: 'Type',
        filterOperators: defaultOperators,
    },
];

const useTagsAndFlags = bookId => {
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);

    const { groups: regions } = useContext(MappingDefinitionsContext);

    const showToast = useContext(ToastContext);

    const [selectedTags, setSelectedTags] = useState(new Map());

    const [flags, setFlags] = useState([]);
    const [tags, setTags] = useState([]);

    const handleTagsSelection = useCallback(
        tags => {
            const newSelectedTags = new Map(selectedTags);
            tags.forEach(tag => {
                const key = tag.stagedOneTimeUseTagId;
                if (newSelectedTags.has(key)) {
                    newSelectedTags.delete(key);
                } else {
                    newSelectedTags.set(key, tag);
                }
            });
            setSelectedTags(newSelectedTags);
        },
        [selectedTags]
    );

    const updateTags = useCallback(
        (updatedTags, newStatusId) => {
            return tags.map(tag => {
                if (updatedTags.find(ut => ut.stagedOneTimeUseTagId === tag.stagedOneTimeUseTagId)) {
                    return { ...tag, workFlowStatusId: newStatusId };
                }
                return tag;
            });
        },
        [tags]
    );

    const handleTagStatusChange = useCallback(
        async newStatusId => {
            try {
                incrementLoading();

                const updatedTags = [...selectedTags.values()].map(t => {
                    const newTag = { ...t, workFlowStatusId: newStatusId };
                    delete newTag.workFlowStatus; // remove the obsolete field after updating the status
                    return newTag;
                });
                await requestUpdateOneTimeUseTag(updatedTags);

                setTags(updateTags(updatedTags, newStatusId));

                setSelectedTags(new Map());
            } catch (error) {
                showToast(error);
            } finally {
                decrementLoading();
            }
        },
        [incrementLoading, decrementLoading, selectedTags, showToast, updateTags, setTags]
    );

    const flagAndTagPropertiesList = useMemo(
        () =>
            flagAndTagPropertiesListInitialState.map(p => {
                if (p.propertyName === 'groups') {
                    p.filterInput.options = regions.map(r => ({
                        text: r.regionFriendlyName,
                        value: r.regionFriendlyName,
                    }));
                    return p;
                }
                return p;
            }),
        [regions]
    );

    useEffect(() => {
        const getFlagsAndTags = async bookId => {
            incrementLoading();

            const flags = await requestGetFlagsApi(bookId);
            const tags = await requestGetTagsApi(bookId);

            setFlags(filterUntaggedFlags(flags, tags));
            setTags(tags);

            decrementLoading();
        };

        getFlagsAndTags(bookId);
    }, [bookId, incrementLoading, decrementLoading]);

    return { flags, tags, selectedTags, handleTagStatusChange, handleTagsSelection, flagAndTagPropertiesList };
};

export default useTagsAndFlags;
