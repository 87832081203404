import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Select, Input } from 'oemiq-common';
import { v4 as uuidv4 } from 'uuid';

const FilterSearchItem = ({ id, selectedColumn, selectedOperator, term, addFilter, filterColumns, removeFilter }) => {
    return (
        <div className="mb-2 d-flex align-items-center justify-content-between">
            <div>
                <button
                    id={`apply-update-filter-${id}`}
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={() => addFilter({ id: uuidv4(), selectedColumn: -1, selectedOperator: -1, term: '' })}>
                    <FontAwesomeIcon icon={faPlus} />
                </button>
            </div>
            <div>
                <button
                    id={`apply-update-filter-${id}`}
                    type="button"
                    className="btn btn-danger btn-sm"
                    onClick={() => removeFilter(id)}>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
            </div>
            <div>
                <Select
                    id={`column-${id}`}
                    formGroupClassName="mb-0"
                    className="form-select"
                    value={selectedColumn}
                    options={filterColumns}
                    defaultOption={{ value: -1, text: 'Column' }}
                    onChange={e =>
                        addFilter({
                            id: id,
                            selectedColumn: e.currentTarget.value,
                            selectedOperator: selectedOperator,
                            term: term,
                        })
                    }
                />
            </div>
            <div>
                <Select
                    id={`operator-${id}`}
                    formGroupClassName="mb-0"
                    className="form-select"
                    value={selectedOperator}
                    options={[
                        { text: 'include', value: 'include' },
                        { text: 'exclude', value: 'exclude' },
                    ]}
                    defaultOption={{ value: -1, text: 'operator' }}
                    onChange={e =>
                        addFilter({
                            id: id,
                            selectedColumn: selectedColumn,
                            selectedOperator: e.currentTarget.value,
                            term: term,
                        })
                    }
                />
            </div>
            <div>
                <Input
                    type="text"
                    id={`term-${id}`}
                    formGroupClassName="mb-0"
                    placeholder="Filter Term"
                    value={term}
                    onChange={e =>
                        addFilter(
                            {
                                id: id,
                                selectedColumn: selectedColumn,
                                selectedOperator: selectedOperator,
                                term: e.currentTarget.value,
                            },
                            'term'
                        )
                    }
                />
            </div>
        </div>
    );
};

export default FilterSearchItem;
