export const readyForPublish = i => {
    return !i.published && i.isLoaded && i.isCompleted;
};

export const readyForUnpublish = i => {
    return i.published && i.isLoaded;
};

export const notReady = i => {
    return !readyForPublish(i) && !readyForUnpublish(i);
};
