import React from 'react';
import { Link } from 'react-router-dom';
import useGetOemInfo from 'components/Oem/useGetOemInfo';
import { Dropdown } from 'react-bootstrap';
import { useOemIdParams } from 'components/locations/MappingProcess/RulesRunner/RulesRunnerTool';
import classNames from 'classnames';

const buildTitle = oemName => (oemName ? `${oemName} Book list` : '');
const mode = { procedureTools: true };

// get info from above about oems
export const OemBookNameBreadcrumb = () => {
    const oemId = useOemIdParams();
    const { oemInfo } = useGetOemInfo(mode);

    const currentOem = oemInfo.find(x => x.oemId === oemId);
    return (
        <Dropdown>
            <Link
                to={`/mapping-process/mapper/${currentOem?.oemId}`}
                className={classNames('text-decoration-none', 'text-white', 'text-nowrap')}>
                {buildTitle(currentOem?.oemName)}
            </Link>
            <Dropdown.Toggle split as={'span'} className="clickable text-white me-2" />
            <Dropdown.Menu>
                {oemInfo
                    .filter(x => x.isActive)
                    .map(x => (
                        <Dropdown.Item
                            key={x.oemId}
                            to={`/mapping-process/mapper/${x.oemId}`}
                            as={Link}
                            active={x.oemId === oemId}>
                            {buildTitle(x.oemName)}
                        </Dropdown.Item>
                    ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};
