import { cloneDeep } from 'lodash';
import { useState } from 'react';
import { DynamicProcedureKey } from './ColumnSettings';

const useColumnSettings = (dynamicProcedureKeys: DynamicProcedureKey[], hideDynamicKeyList: string[]) => {
    const [localDynamicProcedureKeys, setLocalDynamicProcedureKeys] = useState<DynamicProcedureKey[]>(() =>
        cloneDeep(dynamicProcedureKeys.filter(k => !hideDynamicKeyList.includes(k.text)))
    );

    const [draggingColumnId, setDraggingColumnId] = useState<string | null>(null);
    const [dropColumnId, setDropColumnId] = useState<string | null>(null);

    const handleHiddenColumnChange = (columnId: string) => {
        setLocalDynamicProcedureKeys((currDynKeys: DynamicProcedureKey[]) => {
            const index = currDynKeys.map(e => e.id).indexOf(columnId);
            const isCurrentlyHidden = currDynKeys[index].isHidden;
            const currDynCopy = [...currDynKeys];
            currDynCopy[index].isHidden = !isCurrentlyHidden;
            return currDynCopy;
        });
    };

    const columnDragStart = (event, draggingColumnId: string) => {
        event.stopPropagation();
        setTimeout(() => {
            setDraggingColumnId(draggingColumnId);
        });
    };

    const columnDragOver = event => {
        event.preventDefault();
        event.stopPropagation();
    };

    const columnDragEnd = event => {
        event.preventDefault();
        event.stopPropagation();
        setDraggingColumnId(null);
        setDropColumnId(null);
    };

    const columnDrop = event => {
        event.preventDefault();
        event.stopPropagation();

        const newLocalDynamicProcedureKeys = [...localDynamicProcedureKeys];
        const draggedObject = newLocalDynamicProcedureKeys.find(e => e.id === draggingColumnId);
        newLocalDynamicProcedureKeys.splice(newLocalDynamicProcedureKeys.map(e => e.id).indexOf(draggingColumnId), 1);
        newLocalDynamicProcedureKeys.splice(
            newLocalDynamicProcedureKeys.map(e => e.id).indexOf(dropColumnId),
            0,
            draggedObject
        );

        setLocalDynamicProcedureKeys(newLocalDynamicProcedureKeys);
    };

    const columnDragEnter = (event, enteredColumnId: string) => {
        event.preventDefault();
        event.stopPropagation();

        event.dataTransfer.setDragImage(new Image(), 0, 0);
        setDropColumnId(enteredColumnId);
    };

    return {
        localDynamicProcedureKeys,
        handleHiddenColumnChange,
        columnDragStart,
        columnDragOver,
        columnDragEnd,
        columnDrop,
        columnDragEnter,
        dropColumnId,
    };
};

export default useColumnSettings;
