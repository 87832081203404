import React from 'react';
import PositionStatementModal from './AddPositionStatementModal/AddPositionStatementModal';
import usePositionStatementList from './usePositionStatementList';
import { faEdit, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ConfirmDeletePositionStatementModal from './ConfirmDeletePositionStatementModal/ConfirmDeletePositionStatementModal';
import EditPositionStatementModal from './EditPositionStatementModal/EditPositionStatementModal';
import ViewProcedureModal from 'components/Modals/ViewProcedureModal/ViewProcedureModal';

const PositionStatementList = () => {
    const {
        showModal,
        setShowModal,
        oemId,
        onSave,
        positionStatements,
        groups,
        handleEditClick,
        handleViewClick,
        handleDeleteClick,
        deleteProcedureId,
        clearDeleteProcedureId,
        deletePositionStatement,
        editingProcedure,
        clearEditProcedure,
        updateProcedureInList,
        setViewingProcedure,
        viewingProcedure,
    } = usePositionStatementList();

    return (
        <div className="container-fluid">
            <PositionStatementModal isOpen={showModal} toggle={setShowModal} oemId={oemId} onSave={onSave} />
            <ConfirmDeletePositionStatementModal
                isOpen={deleteProcedureId > 0 ? true : false}
                toggle={clearDeleteProcedureId}
                procedureId={deleteProcedureId}
                deletePositionStatement={deletePositionStatement}
            />
            {editingProcedure !== null ? (
                <EditPositionStatementModal
                    isOpen={editingProcedure !== null ? true : false}
                    oemId={oemId}
                    toggle={clearEditProcedure}
                    editingProcedure={editingProcedure}
                    updateProcedureInList={updateProcedureInList}
                />
            ) : (
                <></>
            )}

            {viewingProcedure !== null ? (
                <ViewProcedureModal
                    procedureId={viewingProcedure.procedureId}
                    resetProcedureId={() => setViewingProcedure(null)}
                    defaultCompareVersions={false}
                />
            ) : (
                <></>
            )}

            <div className="my-3">
                <button
                    id="position-statement-new"
                    type="button"
                    className="btn btn-sm btn-primary h-100"
                    onClick={() => setShowModal(true)}>
                    Upload New Position Statement
                </button>
            </div>
            <div>
                {positionStatements && (
                    <table id="position-statements" className="table">
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Title</th>
                                <th>Group(s)</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {positionStatements
                                .sort((a, b) => (a.procedureId < b.procedureId ? -1 : 1))
                                .map(p => {
                                    const psGroups = groups.filter(r =>
                                        p.groups.some(rr => rr.regionId === r.regionId && rr.regionId !== 40)
                                    );
                                    const groupNames = psGroups.map(r => r.regionFriendlyName).join(', ');
                                    if (p.isDeleted) return null;
                                    return (
                                        <tr
                                            id={`position-statement-${p.procedureId}`}
                                            data-positionstatementid={p.procedureId}
                                            key={p.procedureId}>
                                            <td>{p.procedureId}</td>
                                            <td>{p.procedureTitle}</td>
                                            <td>{groupNames}</td>
                                            <td>
                                                <FontAwesomeIcon
                                                    id={`procedure-${p.procedureId}-edit`}
                                                    className="text-primary clickable m-1"
                                                    icon={faEdit}
                                                    onClick={e => handleEditClick(e, p)}
                                                />
                                                <FontAwesomeIcon
                                                    id={`procedure-${p.procedureId}-view`}
                                                    className="text-primary clickable m-1"
                                                    icon={faEye}
                                                    onClick={e => handleViewClick(e, p)}
                                                />
                                                <FontAwesomeIcon
                                                    id={`procedure-${p.procedureId}-delete`}
                                                    className="text-primary clickable m-1"
                                                    icon={faTrash}
                                                    onClick={e => handleDeleteClick(e, p)}
                                                />
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
};

export default PositionStatementList;
