import { useState, useEffect } from 'react';

const useViewVehiclePublishHistory = vehicle => {
    const [vehicleDetails, setVehicleDetails] = useState(null);

    useEffect(() => {
        const getVehicleDetails = async () => {
            const vehicleDetails = `${vehicle.year.yearId} ${vehicle.oem.oemName} ${vehicle.model.modelName} ${vehicle.trim.trimName}`;
            setVehicleDetails(vehicleDetails);
        };
        getVehicleDetails();
    }, [vehicle]);

    return { vehicleDetails };
};

export default useViewVehiclePublishHistory;
