import { requestUpdateVehicles, requestFindVehicleOemModel } from 'api/vehicleInfo';
import { useEffect, useState, useContext, useCallback } from 'react';
import { ToastContext } from 'components/ToastProvider';

const useAssignOemModelToVehicle = (
    vehicle,
    isShowOemModelDropdown,
    setShowOemModelDropdown,
    onToggle,
    updateVehicles
) => {
    const { showToast } = useContext(ToastContext);
    const [isFindLoading, setIsFindLoading] = useState(false);
    const [isSaveLoading, setIsSaveLoading] = useState(false);
    const [oemIdSearchValue, setOemIdSearchValue] = useState('');
    const [selectedOemModels, setSelectedOemModels] = useState([]);
    const [oemlModelSearchResults, setOemModelSearchResults] = useState([]);
    const [oemModelOptions, setOemModelOptions] = useState([]);
    const [resultsCount, setResultsCount] = useState(0);

    useEffect(() => {
        setOemModelOptions(
            oemlModelSearchResults.map(vm => ({
                label: `${vm.oemModelName}`,
                value: vm.oemModelId,
            }))
        );
    }, [oemlModelSearchResults]);

    const onShowOemModelDropDown = useCallback(async () => {
        setOemIdSearchValue(vehicle.oem.oemId);
        await findOemModelForVehicle(vehicle.oem.oemId);
    }, [vehicle, findOemModelForVehicle]);

    const handleFindClick = async () => {
        if (isFindLoading) return;
        setSelectedOemModels([]);
        await findOemModelForVehicle(oemIdSearchValue);
    };

    const handleCancelClick = useCallback(() => {
        setSelectedOemModels([]);
        setOemModelSearchResults([]);
        setResultsCount(0);
        setOemModelOptions([]);
        if (isShowOemModelDropdown) {
            setShowOemModelDropdown(false);
            onToggle(vehicle.vehicleId);
        }
    }, [isShowOemModelDropdown, onToggle, setShowOemModelDropdown, vehicle.vehicleId]);

    const handleOemModelChange = useCallback(
        e => {
            setSelectedOemModels(e);
        },
        [setSelectedOemModels]
    );

    const handleSaveVehicleOemModel = async () => {
        await reqUpdateVehicleOemModel(selectedOemModels);
    };

    const findOemModelForVehicle = useCallback(
        async oemId => {
            try {
                setIsFindLoading(true);
                let response = await requestFindVehicleOemModel(oemId);

                setOemModelSearchResults(response);
                setResultsCount(response.length);
                if (response.length > 0) {
                    response.sort((a, b) => {
                        return a.oemModelName.localeCompare(b.oemModelName);
                    });
                    let selected = vehicle.oemModels
                        ?.map(m => response.find(x => x.oemModelId === m.oemModelId))
                        .filter(x => !!x) ?? [response[0]];

                    setSelectedOemModels(() =>
                        selected.map(x => ({
                            label: `${x.oemModelName}`,
                            value: x.oemModelId,
                        }))
                    );
                }
            } catch (error) {
                showToast();
            } finally {
                setIsFindLoading(false);
            }
        },
        [showToast, vehicle]
    );

    const reqUnassignVehicleOemModel = async () => {
        try {
            setIsSaveLoading(true);
            let success = await requestUpdateVehicles([vehicle.vehicleId], vehicle.oemId, { oemModelIds: [] });
            updateVehicles(success);
            // TODO: fix whatever the code below does
            if (success) vehicle.oemModel = null;
            if (isShowOemModelDropdown) {
                setShowOemModelDropdown(false);
                onToggle(vehicle.vehicleId);
            }
        } catch (error) {
            showToast();
        } finally {
            setIsSaveLoading(false);
        }
    };

    const reqUpdateVehicleOemModel = async oemModels => {
        try {
            // let assignedOemModel = { oemModelId: oemModel.value, oemModelName: oemModel.label };
            let assignedOemModel = oemModels.map(x => ({
                oemModelId: x.value,
                oemModelName: x.label,
            }));
            setIsSaveLoading(true);
            let success = await requestUpdateVehicles([vehicle.vehicleId], vehicle.oemId, {
                oemModelIds: oemModels.map(x => x.value),
            });
            updateVehicles(success);
            // TODO: fix whatever the code below does (and the assignedOemModels above as well)
            if (success) vehicle.oemModel = assignedOemModel;
            if (isShowOemModelDropdown) {
                setShowOemModelDropdown(false);
                onToggle(vehicle.vehicleId);
            }
        } catch (error) {
            showToast();
        } finally {
            setIsSaveLoading(false);
        }
    };

    const hasOemModels = vehicle.oemModels?.length > 0;

    return {
        isFindLoading,
        isSaveLoading,
        selectedOemModels,
        oemModelOptions,
        resultsCount,
        hasOemModels,
        onShowOemModelDropDown,
        handleFindClick,
        handleCancelClick,
        handleOemModelChange,
        handleSaveVehicleOemModel,
        reqUnassignVehicleOemModel,
        reqUpdateVehicleOemModel,
    };
};

export default useAssignOemModelToVehicle;
