const CellUser = ({ users, userId }) => {
    const userName = users.length && users.find(user => userId === user.userId)?.userName;
    return userName ? (
        <>
            {`${userName}`} <br /> {`Id:${userId}`}
        </>
    ) : (
        <>{`User Id:${userId}`}</>
    );
};

export default CellUser;
