import { ReactModal } from 'oemiq-common';
import ReactSelect from 'react-select';
import SmallSpinner from 'components/SmallSpinner';
import useUserModal from './useUserModal';
import { USER_CANCEL_BUTTON_ID } from '../ManageCustomers/ManageCustomersConstants';

const UserModal = ({ orgId, companyId, user, isOpen, toggle, onSaveOrEdit }) => {
    const {
        inEditState,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        userName,
        setUserName,
        email,
        setEmail,
        phone,
        setPhone,
        handleSetPrimaryLocation,
        primaryLocation,
        roles,
        userRole,
        setUserRole,
        multiSelectOptions,
        locations,
        handleLocationsChange,
        handleSave,
        saveEnabled,
        awaitingResponse,
        primaryLocationOptions,
    } = useUserModal(
        user,
        toggle,
        user?.company?.organizationId ?? orgId,
        user?.company?.companyId ?? companyId,
        onSaveOrEdit
    );

    return (
        <ReactModal
            id="create-or-edit-user"
            headerComponent={<div>{user && user.userId ? 'Edit User' : 'Create New User'}</div>}
            isOpen={isOpen}
            toggle={toggle}
            backdrop={inEditState ? 'static' : true}
            bodyComponent={
                <div>
                    <input
                        id="user-first-name"
                        className="form form-control mt-2"
                        type="text"
                        placeholder="First Name"
                        defaultValue={firstName}
                        onChange={e => setFirstName(e.target.value)}
                    />
                    <input
                        id="user-last-name"
                        className="form form-control mt-2"
                        type="text"
                        placeholder="Last Name"
                        defaultValue={lastName}
                        onChange={e => setLastName(e.currentTarget.value)}
                    />
                    <input
                        id="user-username"
                        className="form form-control mt-2"
                        type="text"
                        placeholder="Username"
                        defaultValue={userName}
                        disabled
                        onChange={e => setUserName(e.currentTarget.value)}
                    />
                    <input
                        id="user-email"
                        className="form form-control mt-2"
                        type="email"
                        placeholder="Email"
                        defaultValue={email}
                        onChange={e => setEmail(e.currentTarget.value)}
                    />
                    <input
                        id="user-phone"
                        className="form form-control mt-2"
                        type="text"
                        placeholder="Phone"
                        defaultValue={phone}
                        onChange={e => setPhone(e.currentTarget.value)}
                    />
                    {primaryLocationOptions && (
                        <select
                            id="user-primary-location"
                            className="form-control mt-2"
                            value={primaryLocation}
                            onChange={e => handleSetPrimaryLocation(e)}>
                            <option value={0} disabled>
                                Choose Primary Location (Required)
                            </option>
                            {primaryLocationOptions.map(l => {
                                return (
                                    <option key={l.companyId} value={l.companyId}>
                                        {l.companyName}
                                    </option>
                                );
                            })}
                        </select>
                    )}
                    {roles && (
                        <select
                            id="user-roles"
                            className="form-control mt-2"
                            value={userRole}
                            onChange={e => setUserRole(e.currentTarget.value)}>
                            <option value={''} disabled>
                                Choose Role (Required)
                            </option>
                            {roles.map(r => {
                                return (
                                    <option key={r.id} value={r.name}>
                                        {r.name}
                                    </option>
                                );
                            })}
                        </select>
                    )}
                    {multiSelectOptions && (
                        <ReactSelect
                            className="mt-2"
                            placeholder="Select Available Locations"
                            isMulti={true}
                            options={multiSelectOptions}
                            value={locations}
                            onChange={e => handleLocationsChange(e)}
                        />
                    )}
                </div>
            }
            footerComponent={
                <div>
                    {inEditState && (
                        <div>
                            <button
                                type="button"
                                className="btn btn-danger h-100"
                                id={USER_CANCEL_BUTTON_ID}
                                onClick={toggle}>
                                Cancel
                            </button>
                            <button
                                id="user-modal-save"
                                type="button"
                                className="btn btn-primary ms-3 h-100"
                                onClick={handleSave}
                                disabled={!saveEnabled || awaitingResponse}>
                                <div className="d-flex">
                                    Save
                                    {awaitingResponse && <SmallSpinner className="align-self-center ms-2" />}
                                </div>
                            </button>
                        </div>
                    )}
                </div>
            }
        />
    );
};

export default UserModal;
