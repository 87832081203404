import React from 'react';
import { TagStatus } from 'helpers/TaggerHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TagRowDetails = ({ isExpanded, toggleTag, tagId, handleUpdateTagClick, data, handleSplitTagClick }) => {
    const {
        title,
        isActive,
        oneTimeUseTagLink,
        isHidden,
        workFlowStatus: { taggingWorkFlowStatusId: workflowStatusId },
        procedureDetail,
    } = data;
    const isLiveUpdate = procedureDetail?.isLatest && procedureDetail.isPublished;

    return (
        <>
            <div className="d-flex align-items-center me-2">
                {isExpanded ? (
                    <FontAwesomeIcon role="chevronUp" icon="chevron-up" />
                ) : (
                    <FontAwesomeIcon role="chevronDown" icon="chevron-down" />
                )}
                <h6 role="title" className="mb-0 ms-2 me-2">
                    {title}
                </h6>
                <div className="d-flex">
                    <span role="workflowStatus" className={`badge rounded-pill ${TagStatus[workflowStatusId].color}`}>
                        {TagStatus[workflowStatusId].text}
                    </span>
                    <span
                        role="activeStatus"
                        className={`badge rounded-pill ms-2 ${isActive ? 'text-bg-success' : 'text-bg-danger'}`}>
                        {isActive ? 'Active' : 'Not Active'}
                    </span>
                    {isActive && isLiveUpdate && (
                        <span role="liveUpdateStatus" className="badge rounded-pill ms-2 text-bg-success">
                            Live Update
                        </span>
                    )}
                </div>
            </div>
            <div className="d-flex align-items-center">
                {isActive && (
                    <button
                        role="splitTag"
                        type="button"
                        className="btn btn-sm btn-warning me-2"
                        onClick={handleSplitTagClick}>
                        Split Tag
                    </button>
                )}
                {oneTimeUseTagLink && (
                    <button
                        role="linkTag"
                        type="button"
                        className="btn btn-sm btn-light me-2"
                        // todo: onClick is not implemented yet, wait on bulk edit task
                        disabled={true}>
                        <FontAwesomeIcon icon="link" />
                    </button>
                )}
                <button
                    role="toggleTag"
                    type="button"
                    className="btn btn-sm btn-light me-2"
                    onClick={e => toggleTag(e, tagId)}>
                    {isHidden ? <FontAwesomeIcon icon="eye-slash" /> : <FontAwesomeIcon icon="eye" />}
                </button>
                {workflowStatusId !== 2 && isActive && (
                    <button
                        role="completeTag"
                        type="button"
                        className="btn btn-sm btn-success me-2"
                        onClick={e => handleUpdateTagClick(e, 'setComplete')}>
                        <FontAwesomeIcon icon="thumbs-up" />
                    </button>
                )}
                {isActive ? (
                    <button
                        role="inactivateTag"
                        type="button"
                        className="btn btn-sm btn-danger"
                        onClick={e => handleUpdateTagClick(e, 'setInactive')}>
                        <FontAwesomeIcon icon="trash" />
                    </button>
                ) : (
                    <button
                        role="activateTag"
                        type="button"
                        className="btn btn-sm btn-success"
                        onClick={e => handleUpdateTagClick(e, 'setActive')}>
                        <FontAwesomeIcon icon="trash-restore" />
                    </button>
                )}
            </div>
        </>
    );
};

export default TagRowDetails;
