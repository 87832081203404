import { VehicleFilter, YearOperator } from '../VehicleFilter';

export const validateVehicleFilterValue = (v: VehicleFilter) => {
    const yearEqNeqHasValue =
        (v.yearOperator?.value === YearOperator.eq || v.yearOperator?.value === YearOperator.neq) && v.yearNumber;
    const yearRangeHasValue = v.yearOperator?.value === YearOperator.range && (v.fromYear || v.toYear);
    const atLeastOneValue = v.make?.value || v.model?.value || yearEqNeqHasValue || yearRangeHasValue;
    if (!atLeastOneValue) return false;

    if ((v.yearOperator?.value === YearOperator.eq || v.yearOperator?.value === YearOperator.neq) && v.yearNumber) {
        if (isNaN(parseInt(v.yearNumber))) return false;
        if (parseInt(v.yearNumber) <= 0) return false;
    }

    if (v.yearOperator?.value === YearOperator.range && (v.fromYear || v.toYear)) {
        if (v.fromYear && isNaN(parseInt(v.fromYear))) return false;
        if (v.toYear && isNaN(parseInt(v.toYear))) return false;
        if (v.fromYear && parseInt(v.fromYear) <= 0) return false;
        if (v.toYear && parseInt(v.toYear) <= 0) return false;
        if (v.fromYear && v.toYear && parseInt(v.fromYear) > parseInt(v.toYear)) return false;
    }

    return true;
};
