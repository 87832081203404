import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { appInsights } from 'api/config/AppInsights';
import { useState, useCallback, useMemo, useRef } from 'react';
const notificationTypes = {
    success: 'bg-success border-success text-white',
    info: 'bg-info border-info text-white',
    warning: 'bg-warning border-warning text-dark',
    danger: 'bg-danger border-danger text-white',
};

const useNotifications = () => {
    const [notifications, setNotifications] = useState([]);
    const notificationCount = useRef(0);

    const pushNotification = useCallback((notification, type) => {
        notificationCount.current = notificationCount.current + 1;
        setNotifications(currentNotifications => {
            return [
                ...currentNotifications,
                {
                    id: currentNotifications.length,
                    show: true,
                    pinned: false,
                    type,
                    title: '',
                    body: '',
                    ...notification,
                },
            ];
        });
    }, []);

    const hideNotification = useCallback(notificationId => {
        setNotifications(currentNotifications => {
            if (currentNotifications.find(notification => notification.id === notificationId))
                currentNotifications.find(notification => notification.id === notificationId).show = false;

            return [...currentNotifications];
        });
    }, []);

    const notificationInterface = useMemo(
        () => ({
            notifications: {
                pushSuccess: (body, options) => pushNotification({ body, ...options }, notificationTypes.success),
                pushInfo: (body, options) => pushNotification({ body, ...options }, notificationTypes.info),
                pushWarning: (body, options) => pushNotification({ body, ...options }, notificationTypes.warning),
                pushDanger: (body, options) => pushNotification({ body, ...options }, notificationTypes.danger),
                pushExceptionDanger: (error, options) => (
                    pushNotification({ body: error.message, pinned: true, ...options }, notificationTypes.danger),
                    appInsights.trackException({ error, severityLevel: SeverityLevel.Error })
                ),
                getNextId: () => notificationCount.current,
                hideNotification: id => hideNotification(id),
            },
        }),
        [pushNotification, notificationCount, hideNotification]
    );

    return {
        notifications,
        notificationInterface,
        hideNotification,
    };
};

export default useNotifications;
