import { useState, useEffect } from 'react';
import { Input, PrimaryButton } from 'oemiq-common';
import Table from 'components/Shared/Table/Table';
import tableConfiguration from './tableConfiguration';
import { createNetwork, editNetwork, deleteNetwork } from 'api/NetworkApi';
import AddNetworkModal from './AddNetworkModal';
import useTableActions from './useTableActions';
import useNetwork from 'hooks/useNetwork';

const Networks = () => {
    const { networks, networkTypes, networkLevels, setNetworks } = useNetwork();
    const [displayNetworks, setDisplayNetworks] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [isAddNetworkModalOpen, setIsAddNetworkModalOpen] = useState(false);
    const [selectedNetworkToEdit, setSelectedNetworkToEdit] = useState(null);

    useEffect(() => {
        if (!searchValue || searchValue === '') setDisplayNetworks(networks);
        else {
            let disNetworks = networks.filter(n => n.name.toLowerCase().includes(searchValue.toLowerCase()));
            setDisplayNetworks(disNetworks);
        }
    }, [networks, searchValue]);

    const onModalToggle = () => {
        setIsAddNetworkModalOpen(prevState => !prevState);
        setSelectedNetworkToEdit(null);
    };

    const onEditClick = networkToEdit => {
        setSelectedNetworkToEdit(networkToEdit);
        setIsAddNetworkModalOpen(true);
    };

    const onDeleteClick = async networkToDelete => {
        const networkId = networkToDelete.networkId;
        await deleteNetwork(networkId);
        setNetworks(prevState => {
            let newState = prevState.filter(n => n.networkId !== networkId);
            return newState;
        });
    };

    const headers = tableConfiguration;
    const actions = useTableActions(onEditClick, onDeleteClick);

    return (
        <div>
            <div className="d-flex align-items-center justify-content-lg-between px-3">
                <div className="w-50">
                    <Input
                        id="network-search"
                        className="w-100"
                        type="text"
                        value={searchValue}
                        formGroupClassName={'m-3'}
                        onChange={e => setSearchValue(e.currentTarget.value)}
                        placeholder="Search Name"
                    />
                </div>

                <div>
                    <PrimaryButton type="button" onClick={() => setIsAddNetworkModalOpen(true)}>
                        Create New Network
                    </PrimaryButton>
                </div>
            </div>

            <Table idKey="networkId" headers={headers} data={displayNetworks} cellEventCallbacks={actions} />
            <AddNetworkModal
                isOpen={isAddNetworkModalOpen}
                onToggle={() => onModalToggle()}
                networkTypes={networkTypes}
                networkLevels={networkLevels}
                createNetwork={createNetwork}
                setNetworks={setNetworks}
                editNetwork={editNetwork}
                networkToEdit={selectedNetworkToEdit}
            />
        </div>
    );
};

export default Networks;
