export const PAGE_SIZE = 100;

export const TABLE_COLUMN_ENUM = Object.freeze({
    TITLE: 1,
    TEXT: 2,
    QUANTITY: 3,
    QUANTITY_CONDITION: 4,
    PART_NUMBER: 5,
    PRICE: 6,
    PART_TYPE_ID: 7,
    BOOK_NAME: 8,
    PROCEDURE_ID: 9,
    PROCEDURE_TITLE: 10,
    FLAG_TRIGGER: 11,
    GROUPS: 12,
    TYPE: 13,
    CREATE_DATE: 14,
    UPDATE_DATE: 15,
    STATUS: 16,
    NEEDS_ATTENTION: 17,
    LINK: 18,
    ONE_TIME_USE_FLAG_TERM_ID: 19,
    ONE_TIME_USE_FLAG_TERM: 20,
    IMAGE: 21,
});

export const TABLE_COLUMNS = Object.freeze([
    {
        propertyName: 'bookName',
        getSortOrderKey: () => {
            return 'bookName';
        },
        displayName: 'Book Name',
        showInFlagMode: null,
        filterOption: Object.freeze({
            key: TABLE_COLUMN_ENUM.BOOK_NAME,
            value: TABLE_COLUMN_ENUM.BOOK_NAME,
            text: 'Book Name',
        }),
    },
    {
        propertyName: 'OneTimeUseTagImages/any()',
        getSortOrderKey: () => {
            return 'OneTimeUseTagImages/$count';
        },
        displayName: 'Image',
        showInFlagMode: false,
        filterOption: Object.freeze({
            key: TABLE_COLUMN_ENUM.IMAGE,
            value: TABLE_COLUMN_ENUM.IMAGE,
            text: 'Image (for tags only)',
        }),
    },
    {
        propertyName: 'workFlowStatus/taggingWorkFlowStatusName',
        getSortOrderKey: () => {
            return 'workFlowStatus/taggingWorkFlowStatusName';
        },
        displayName: 'Status',
        showInFlagMode: false,
        filterOption: Object.freeze({
            key: TABLE_COLUMN_ENUM.STATUS,
            value: TABLE_COLUMN_ENUM.STATUS,
            text: 'Status (for tags only)',
        }),
    },
    {
        propertyName: 'needsAttention',
        getSortOrderKey: () => {
            return 'needsAttention';
        },
        displayName: 'Needs Attention',
        showInFlagMode: true,
        filterOption: Object.freeze({
            key: TABLE_COLUMN_ENUM.NEEDS_ATTENTION,
            value: TABLE_COLUMN_ENUM.NEEDS_ATTENTION,
            text: 'Needs Attention (for flags only)',
        }),
    },
    {
        propertyName: 'procedure/procedureId',
        getSortOrderKey: () => {
            return 'procedure/procedureId';
        },
        displayName: 'Procedure ID',
        showInFlagMode: null,
        filterOption: {
            key: TABLE_COLUMN_ENUM.PROCEDURE_ID,
            value: TABLE_COLUMN_ENUM.PROCEDURE_ID,
            text: 'Procedure ID',
        },
    },
    {
        propertyName: 'procedure/procedureTitle',
        getSortOrderKey: () => {
            return 'procedure/procedureTitle';
        },
        displayName: 'Procedure Title',
        showInFlagMode: null,
        filterOption: {
            key: TABLE_COLUMN_ENUM.PROCEDURE_TITLE,
            value: TABLE_COLUMN_ENUM.PROCEDURE_TITLE,
            text: 'Procedure Title',
        },
    },
    {
        propertyName: 'flagLocation',
        getSortOrderKey: () => {
            return 'flagLocation';
        },
        displayName: 'Flag Location',
        showInFlagMode: true,
        filterOption: Object.freeze({
            key: TABLE_COLUMN_ENUM.FLAG_TRIGGER,
            value: TABLE_COLUMN_ENUM.FLAG_TRIGGER,
            text: 'Flag Location',
        }),
    },
    {
        propertyName: 'title',
        getSortOrderKey: () => {
            return 'title';
        },
        displayName: 'Title',
        showInFlagMode: null,
        filterOption: Object.freeze({
            key: TABLE_COLUMN_ENUM.TITLE,
            value: TABLE_COLUMN_ENUM.TITLE,
            text: 'Title',
        }),
    },
    {
        propertyName: 'oneTimeUseTagLink',
        getSortOrderKey: () => {
            return 'oneTimeUseTagLink';
        },
        displayName: 'Link',
        showInFlagMode: false,
        filterOption: Object.freeze({
            key: TABLE_COLUMN_ENUM.LINK,
            value: TABLE_COLUMN_ENUM.LINK,
            text: 'Link',
        }),
    },
    {
        propertyName: 'text',
        getSortOrderKey: () => {
            return 'text';
        },
        displayName: 'Flagged Text',
        showInFlagMode: null,
        filterOption: Object.freeze({
            key: TABLE_COLUMN_ENUM.TEXT,
            value: TABLE_COLUMN_ENUM.TEXT,
            text: 'Text',
        }),
    },
    {
        propertyName: 'oneTimeUseFlagTerm/id',
        getSortOrderKey: () => {
            return 'oneTimeUseFlagTerm/id';
        },
        displayName: '1xUse Term Id',
        showInFlagMode: true,
        filterOption: Object.freeze({
            key: TABLE_COLUMN_ENUM.ONE_TIME_USE_FLAG_TERM_ID,
            value: TABLE_COLUMN_ENUM.ONE_TIME_USE_FLAG_TERM_ID,
            text: '1xUse Term Id',
        }),
    },
    {
        propertyName: 'oneTimeUseFlagTerm/term',
        getSortOrderKey: () => {
            return 'oneTimeUseFlagTerm/term';
        },
        displayName: '1xUse Term',
        showInFlagMode: true,
        filterOption: Object.freeze({
            key: TABLE_COLUMN_ENUM.ONE_TIME_USE_FLAG_TERM,
            value: TABLE_COLUMN_ENUM.ONE_TIME_USE_FLAG_TERM,
            text: '1xUse Term',
        }),
    },
    {
        propertyName: 'quantity',
        getSortOrderKey: () => {
            return 'quantity';
        },
        displayName: 'Quantity',
        showInFlagMode: null,
        filterOption: Object.freeze({
            key: TABLE_COLUMN_ENUM.QUANTITY,
            value: TABLE_COLUMN_ENUM.QUANTITY,
            text: 'Quantity',
        }),
    },
    {
        propertyName: 'quantityCondition',
        getSortOrderKey: () => {
            return 'quantityCondition';
        },
        displayName: 'Quantity Condition',
        showInFlagMode: null,
        filterOption: Object.freeze({
            key: TABLE_COLUMN_ENUM.QUANTITY_CONDITION,
            value: TABLE_COLUMN_ENUM.QUANTITY_CONDITION,
            text: 'Quantity Condition',
        }),
    },
    {
        propertyName: 'partNumber',
        getSortOrderKey: () => {
            return 'partNumber';
        },
        displayName: 'Part Number',
        showInFlagMode: null,
        filterOption: Object.freeze({
            key: TABLE_COLUMN_ENUM.PART_NUMBER,
            value: TABLE_COLUMN_ENUM.PART_NUMBER,
            text: 'Part Number',
        }),
    },
    {
        propertyName: 'price',
        getSortOrderKey: () => {
            return 'price';
        },
        displayName: 'Price',
        showInFlagMode: null,
        filterOption: Object.freeze({
            key: TABLE_COLUMN_ENUM.PRICE,
            value: TABLE_COLUMN_ENUM.PRICE,
            text: 'Price',
        }),
    },
    {
        //[OneTimeUse]PartType/oneTimeUsePartTypeName
        propertyName: 'oneTimeUsePartTypeName',
        getSortOrderKey: flagModeOn => {
            return `${flagModeOn ? 'partType' : 'oneTimeUsePartType'}/oneTimeUsePartTypeName`;
        },
        displayName: 'Part Type',
        showInFlagMode: null,
        filterOption: Object.freeze({
            key: TABLE_COLUMN_ENUM.PART_TYPE_ID,
            value: TABLE_COLUMN_ENUM.PART_TYPE_ID,
            text: 'Part Type',
        }),
    },
    {
        propertyName: 'regionName',
        getSortOrderKey: () => {
            return 'regionName';
        },
        displayName: 'Groups',
        showInFlagMode: null,
        filterOption: Object.freeze({
            key: TABLE_COLUMN_ENUM.GROUPS,
            value: TABLE_COLUMN_ENUM.GROUPS,
            text: 'Groups',
        }),
    },
    {
        propertyName: 'procedure/stageArea/type/type/oemIqSectionName',
        getSortOrderKey: () => {
            return 'procedure/stageArea/type/type/oemIqSectionName';
        },
        displayName: 'Type',
        showInFlagMode: null,
        filterOption: Object.freeze({
            key: TABLE_COLUMN_ENUM.TYPE,
            value: TABLE_COLUMN_ENUM.TYPE,
            text: 'Type',
        }),
    },
    {
        propertyName: 'updateDate',
        getSortOrderKey: () => {
            return 'updateDate';
        },
        displayName: 'Update Date',
        showInFlagMode: null,
        filterOption: Object.freeze({
            key: TABLE_COLUMN_ENUM.UPDATE_DATE,
            value: TABLE_COLUMN_ENUM.UPDATE_DATE,
            text: 'Update Date',
        }),
    },
    {
        propertyName: 'createDate',
        getSortOrderKey: () => {
            return 'createDate';
        },
        displayName: 'Create Date',
        showInFlagMode: null,
        filterOption: Object.freeze({
            key: TABLE_COLUMN_ENUM.CREATE_DATE,
            value: TABLE_COLUMN_ENUM.CREATE_DATE,
            text: 'Create Date',
        }),
    },
]);

export const SEARCH_ACTION_TYPE_ENUM = Object.freeze({
    ADD_FILTER: 1,
    DELETE_FILTER: 2,
    UPDATE_FILTER: 3,
    TOGGLE_CHECKBOX: 4,
    RESET: 5,
});

export const FILTER_INPUT_TYPE_ENUM = Object.freeze({
    INPUT: 1,
    DROPDOWN: 2,
    DATE_PICKER: 3,
});

export const OPERATOR_TYPE_ENUM = Object.freeze({
    CONTAINS: Object.freeze({
        id: 1,
        text: 'Contains',
    }),
    NOT_CONTAINS: Object.freeze({
        id: 2,
        text: 'Not contains',
    }),
    EQUAL: Object.freeze({
        id: 3,
        text: 'Equal',
    }),
    NOT_EQUAL: Object.freeze({
        id: 4,
        text: 'Not equal',
    }),
    GREATER_THAN: Object.freeze({
        id: 5,
        text: 'Greater than',
    }),
    LESS_THAN: Object.freeze({
        id: 6,
        text: 'Less than',
    }),
});

export const ACTIONS = Object.freeze({
    [TABLE_COLUMN_ENUM.BOOK_NAME]: {
        operators: [
            {
                key: OPERATOR_TYPE_ENUM.CONTAINS.id,
                value: OPERATOR_TYPE_ENUM.CONTAINS.id,
                text: OPERATOR_TYPE_ENUM.CONTAINS.text,
            },
            {
                key: OPERATOR_TYPE_ENUM.NOT_CONTAINS.id,
                value: OPERATOR_TYPE_ENUM.NOT_CONTAINS.id,
                text: OPERATOR_TYPE_ENUM.NOT_CONTAINS.text,
            },
        ],
        inputType: {
            id: FILTER_INPUT_TYPE_ENUM.INPUT,
            props: {
                type: 'text',
            },
        },
        generateFilterTerm: (operatorId, inputValue) => {
            let term = '';
            switch (operatorId) {
                case OPERATOR_TYPE_ENUM.CONTAINS.id:
                    term = `and procedure/booksForProcedure/any(bp: contains(bp/book/bookName , '${inputValue}'))`;
                    break;
                case OPERATOR_TYPE_ENUM.NOT_CONTAINS.id:
                    term = `and not procedure/booksForProcedure/any(bp: contains(bp/book/bookName , '${inputValue}'))`;
                    break;
                default:
                    break;
            }
            return term;
        },
    },
    [TABLE_COLUMN_ENUM.TITLE]: {
        operators: [
            {
                key: OPERATOR_TYPE_ENUM.EQUAL.id,
                value: OPERATOR_TYPE_ENUM.EQUAL.id,
                text: OPERATOR_TYPE_ENUM.EQUAL.text,
            },
            {
                key: OPERATOR_TYPE_ENUM.NOT_EQUAL.id,
                value: OPERATOR_TYPE_ENUM.NOT_EQUAL.id,
                text: OPERATOR_TYPE_ENUM.NOT_EQUAL.text,
            },
            {
                key: OPERATOR_TYPE_ENUM.CONTAINS.id,
                value: OPERATOR_TYPE_ENUM.CONTAINS.id,
                text: OPERATOR_TYPE_ENUM.CONTAINS.text,
            },
            {
                key: OPERATOR_TYPE_ENUM.NOT_CONTAINS.id,
                value: OPERATOR_TYPE_ENUM.NOT_CONTAINS.id,
                text: OPERATOR_TYPE_ENUM.NOT_CONTAINS.text,
            },
        ],
        inputType: {
            id: FILTER_INPUT_TYPE_ENUM.INPUT,
            props: {
                type: 'text',
            },
        },
        generateFilterTerm: (operatorId, inputValue) => {
            let term = '';
            switch (operatorId) {
                case OPERATOR_TYPE_ENUM.EQUAL.id:
                    term = `and title eq '${inputValue}'`;
                    break;
                case OPERATOR_TYPE_ENUM.NOT_EQUAL.id:
                    term = `and title ne '${inputValue}'`;
                    break;
                case OPERATOR_TYPE_ENUM.CONTAINS.id:
                    term = `and contains(title , '${inputValue}')`;
                    break;
                case OPERATOR_TYPE_ENUM.NOT_CONTAINS.id:
                    term = `and not contains(title , '${inputValue}')`;
                    break;
                default:
                    break;
            }
            return term;
        },
    },
    [TABLE_COLUMN_ENUM.PROCEDURE_ID]: {
        operators: [
            {
                key: OPERATOR_TYPE_ENUM.EQUAL.id,
                value: OPERATOR_TYPE_ENUM.EQUAL.id,
                text: OPERATOR_TYPE_ENUM.EQUAL.text,
            },
            {
                key: OPERATOR_TYPE_ENUM.NOT_EQUAL.id,
                value: OPERATOR_TYPE_ENUM.NOT_EQUAL.id,
                text: OPERATOR_TYPE_ENUM.NOT_EQUAL.text,
            },
        ],
        inputType: {
            id: FILTER_INPUT_TYPE_ENUM.INPUT,
            props: {
                type: 'number',
                inputProps: { min: 0.0, step: 1 },
            },
        },
        generateFilterTerm: (operatorId, inputValue) => {
            let term = '';
            switch (operatorId) {
                case OPERATOR_TYPE_ENUM.EQUAL.id:
                    term = `and procedureId eq ${inputValue}`;
                    break;
                case OPERATOR_TYPE_ENUM.NOT_EQUAL.id:
                    term = `and procedureId ne ${inputValue}`;
                    break;
            }
            return term;
        },
    },
    [TABLE_COLUMN_ENUM.PROCEDURE_TITLE]: {
        operators: [
            {
                key: OPERATOR_TYPE_ENUM.CONTAINS.id,
                value: OPERATOR_TYPE_ENUM.CONTAINS.id,
                text: OPERATOR_TYPE_ENUM.CONTAINS.text,
            },
            {
                key: OPERATOR_TYPE_ENUM.NOT_CONTAINS.id,
                value: OPERATOR_TYPE_ENUM.NOT_CONTAINS.id,
                text: OPERATOR_TYPE_ENUM.NOT_CONTAINS.text,
            },
        ],
        inputType: {
            id: FILTER_INPUT_TYPE_ENUM.INPUT,
            props: {
                type: 'text',
            },
        },
        generateFilterTerm: (operatorId, inputValue) => {
            let term = '';
            switch (operatorId) {
                case OPERATOR_TYPE_ENUM.CONTAINS.id:
                    term = ` and contains(procedure/procedureTitle, '${inputValue}')`;
                    break;
                case OPERATOR_TYPE_ENUM.NOT_CONTAINS.id:
                    term = ` and not contains(procedure/procedureTitle, '${inputValue}')`;
                    break;
                default:
                    break;
            }
            return term;
        },
    },
    [TABLE_COLUMN_ENUM.FLAG_TRIGGER]: {
        operators: [
            {
                key: OPERATOR_TYPE_ENUM.CONTAINS.id,
                value: OPERATOR_TYPE_ENUM.CONTAINS.id,
                text: OPERATOR_TYPE_ENUM.CONTAINS.text,
            },
            {
                key: OPERATOR_TYPE_ENUM.NOT_CONTAINS.id,
                value: OPERATOR_TYPE_ENUM.NOT_CONTAINS.id,
                text: OPERATOR_TYPE_ENUM.NOT_CONTAINS.text,
            },
        ],
        inputType: {
            id: FILTER_INPUT_TYPE_ENUM.INPUT,
            props: {
                type: 'text',
            },
        },
        generateFilterTerm: (operatorId, inputValue, flagModeOn) => {
            let term = '';

            if (flagModeOn) {
                switch (operatorId) {
                    case OPERATOR_TYPE_ENUM.CONTAINS.id:
                        term = `and contains(flagLocation , '${inputValue}')`;
                        break;
                    case OPERATOR_TYPE_ENUM.NOT_CONTAINS.id:
                        term = `and not contains(flagLocation , '${inputValue}')`;
                        break;
                    default:
                        break;
                }
            }
            return term;
        },
    },
    [TABLE_COLUMN_ENUM.TEXT]: {
        operators: [
            {
                key: OPERATOR_TYPE_ENUM.CONTAINS.id,
                value: OPERATOR_TYPE_ENUM.CONTAINS.id,
                text: OPERATOR_TYPE_ENUM.CONTAINS.text,
            },
            {
                key: OPERATOR_TYPE_ENUM.NOT_CONTAINS.id,
                value: OPERATOR_TYPE_ENUM.NOT_CONTAINS.id,
                text: OPERATOR_TYPE_ENUM.NOT_CONTAINS.text,
            },
        ],
        inputType: {
            id: FILTER_INPUT_TYPE_ENUM.INPUT,
            props: {
                type: 'text',
            },
        },
        generateFilterTerm: (operatorId, inputValue) => {
            let term = '';
            switch (operatorId) {
                case OPERATOR_TYPE_ENUM.CONTAINS.id:
                    term = `and contains(text , '${inputValue}')`;
                    break;
                case OPERATOR_TYPE_ENUM.NOT_CONTAINS.id:
                    term = `and not contains(text , '${inputValue}')`;
                    break;
                default:
                    break;
            }
            return term;
        },
    },
    [TABLE_COLUMN_ENUM.QUANTITY]: {
        operators: [
            {
                key: OPERATOR_TYPE_ENUM.EQUAL.id,
                value: OPERATOR_TYPE_ENUM.EQUAL.id,
                text: OPERATOR_TYPE_ENUM.EQUAL.text,
            },
            {
                key: OPERATOR_TYPE_ENUM.NOT_EQUAL.id,
                value: OPERATOR_TYPE_ENUM.NOT_EQUAL.id,
                text: OPERATOR_TYPE_ENUM.NOT_EQUAL.text,
            },
            {
                key: OPERATOR_TYPE_ENUM.GREATER_THAN.id,
                value: OPERATOR_TYPE_ENUM.GREATER_THAN.id,
                text: OPERATOR_TYPE_ENUM.GREATER_THAN.text,
            },
            {
                key: OPERATOR_TYPE_ENUM.LESS_THAN.id,
                value: OPERATOR_TYPE_ENUM.LESS_THAN.id,
                text: OPERATOR_TYPE_ENUM.LESS_THAN.text,
            },
        ],
        inputType: {
            id: FILTER_INPUT_TYPE_ENUM.INPUT,
            props: {
                type: 'number',
                inputProps: { min: 0.0, step: 1 },
            },
        },
        generateFilterTerm: (operatorId, inputValue) => {
            let term = '';
            switch (operatorId) {
                case OPERATOR_TYPE_ENUM.EQUAL.id:
                    term = `and quantity eq ${inputValue}`;
                    break;
                case OPERATOR_TYPE_ENUM.NOT_EQUAL.id:
                    term = `and quantity ne ${inputValue}`;
                    break;
                case OPERATOR_TYPE_ENUM.GREATER_THAN.id:
                    term = `and quantity gt ${inputValue}`;
                    break;
                case OPERATOR_TYPE_ENUM.LESS_THAN.id:
                    term = `and quantity lt ${inputValue}`;
                    break;
                default:
                    break;
            }
            return term;
        },
    },
    [TABLE_COLUMN_ENUM.QUANTITY_CONDITION]: {
        operators: [
            {
                key: OPERATOR_TYPE_ENUM.CONTAINS.id,
                value: OPERATOR_TYPE_ENUM.CONTAINS.id,
                text: OPERATOR_TYPE_ENUM.CONTAINS.text,
            },
            {
                key: OPERATOR_TYPE_ENUM.NOT_CONTAINS.id,
                value: OPERATOR_TYPE_ENUM.NOT_CONTAINS.id,
                text: OPERATOR_TYPE_ENUM.NOT_CONTAINS.text,
            },
        ],
        inputType: {
            id: FILTER_INPUT_TYPE_ENUM.DROPDOWN,
            props: {
                defaultOption: { value: '', text: 'Select quantity condition' },
                options: [],
            },
        },
        generateFilterTerm: (operatorId, inputValue) => {
            let term = '';
            switch (operatorId) {
                case OPERATOR_TYPE_ENUM.CONTAINS.id:
                    term = `and contains(quantityCondition , '${inputValue}')`;
                    break;
                case OPERATOR_TYPE_ENUM.NOT_CONTAINS.id:
                    term = `and not contains(quantityCondition , '${inputValue}')`;
                    break;
                default:
                    break;
            }
            return term;
        },
    },
    [TABLE_COLUMN_ENUM.PART_NUMBER]: {
        operators: [
            {
                key: OPERATOR_TYPE_ENUM.CONTAINS.id,
                value: OPERATOR_TYPE_ENUM.CONTAINS.id,
                text: OPERATOR_TYPE_ENUM.CONTAINS.text,
            },
            {
                key: OPERATOR_TYPE_ENUM.NOT_CONTAINS.id,
                value: OPERATOR_TYPE_ENUM.NOT_CONTAINS.id,
                text: OPERATOR_TYPE_ENUM.NOT_CONTAINS.text,
            },
        ],
        inputType: {
            id: FILTER_INPUT_TYPE_ENUM.INPUT,
            props: {
                type: 'text',
            },
        },
        generateFilterTerm: (operatorId, inputValue) => {
            let term = '';
            switch (operatorId) {
                case OPERATOR_TYPE_ENUM.CONTAINS.id:
                    term = `and contains(partNumber , '${inputValue}')`;
                    break;
                case OPERATOR_TYPE_ENUM.NOT_CONTAINS.id:
                    term = `and not contains(partNumber , '${inputValue}')`;
                    break;
                default:
                    break;
            }
            return term;
        },
    },
    [TABLE_COLUMN_ENUM.PRICE]: {
        operators: [
            {
                key: OPERATOR_TYPE_ENUM.GREATER_THAN.id,
                value: OPERATOR_TYPE_ENUM.GREATER_THAN.id,
                text: OPERATOR_TYPE_ENUM.GREATER_THAN.text,
            },
            {
                key: OPERATOR_TYPE_ENUM.LESS_THAN.id,
                value: OPERATOR_TYPE_ENUM.LESS_THAN.id,
                text: OPERATOR_TYPE_ENUM.LESS_THAN.text,
            },
        ],
        inputType: {
            id: FILTER_INPUT_TYPE_ENUM.INPUT,
            props: {
                type: 'number',
                inputProps: { min: 0.0, step: 0.25 },
            },
        },
        generateFilterTerm: (operatorId, inputValue) => {
            let term = '';
            switch (operatorId) {
                case OPERATOR_TYPE_ENUM.GREATER_THAN.id:
                    term = `and price gt ${inputValue}`;
                    break;
                case OPERATOR_TYPE_ENUM.LESS_THAN.id:
                    term = `and price lt ${inputValue}`;
                    break;
                default:
                    break;
            }
            return term;
        },
    },
    [TABLE_COLUMN_ENUM.PART_TYPE_ID]: {
        operators: [
            {
                key: OPERATOR_TYPE_ENUM.EQUAL.id,
                value: OPERATOR_TYPE_ENUM.EQUAL.id,
                text: OPERATOR_TYPE_ENUM.EQUAL.text,
            },
            {
                key: OPERATOR_TYPE_ENUM.NOT_EQUAL.id,
                value: OPERATOR_TYPE_ENUM.NOT_EQUAL.id,
                text: OPERATOR_TYPE_ENUM.NOT_EQUAL.text,
            },
        ],
        inputType: {
            id: FILTER_INPUT_TYPE_ENUM.DROPDOWN,
            props: {
                defaultOption: { value: '', text: 'Select part type' },
                options: [],
            },
        },
        generateFilterTerm: (operatorId, inputValue, flagModeOn) => {
            let term = '';
            switch (operatorId) {
                case OPERATOR_TYPE_ENUM.EQUAL.id:
                    term = `and ${
                        flagModeOn ? 'partTypeId' : 'oneTimeUsePartType/oneTimeUsePartTypeId'
                    } eq ${inputValue}`;
                    break;
                case OPERATOR_TYPE_ENUM.NOT_EQUAL.id:
                    term = `and ${
                        flagModeOn ? 'partTypeId' : 'oneTimeUsePartType/oneTimeUsePartTypeId'
                    } ne ${inputValue}`;
                    break;
                default:
                    break;
            }
            return term;
        },
    },
    [TABLE_COLUMN_ENUM.GROUPS]: {
        operators: [
            {
                key: OPERATOR_TYPE_ENUM.CONTAINS.id,
                value: OPERATOR_TYPE_ENUM.CONTAINS.id,
                text: OPERATOR_TYPE_ENUM.CONTAINS.text,
            },
            {
                key: OPERATOR_TYPE_ENUM.NOT_CONTAINS.id,
                value: OPERATOR_TYPE_ENUM.NOT_CONTAINS.id,
                text: OPERATOR_TYPE_ENUM.NOT_CONTAINS.text,
            },
            {
                key: OPERATOR_TYPE_ENUM.EQUAL.id,
                value: OPERATOR_TYPE_ENUM.EQUAL.id,
                text: OPERATOR_TYPE_ENUM.EQUAL.text,
            },
        ],
        inputType: {
            id: FILTER_INPUT_TYPE_ENUM.DROPDOWN,
            props: {
                defaultOption: { value: '', text: 'Select group' },
                options: [],
            },
        },
        generateFilterTerm: (operatorId, inputValue) => {
            let term = '';
            switch (operatorId) {
                case OPERATOR_TYPE_ENUM.CONTAINS.id:
                    term = `and procedure/stageArea/groups/any(g: g/groupId eq ${inputValue})`;
                    break;
                case OPERATOR_TYPE_ENUM.NOT_CONTAINS.id:
                    term = `and not procedure/stageArea/groups/any(g: g/groupId eq ${inputValue})`;
                    break;
                case OPERATOR_TYPE_ENUM.EQUAL.id:
                    term = `and procedure/stageArea/groups/any(g: g/groupId eq ${inputValue}) and procedure/stageArea/groups/all(g: g/groupId eq ${inputValue})`;
                    break;
                default:
                    break;
            }
            return term;
        },
    },
    [TABLE_COLUMN_ENUM.TYPE]: {
        operators: [
            {
                key: OPERATOR_TYPE_ENUM.CONTAINS.id,
                value: OPERATOR_TYPE_ENUM.CONTAINS.id,
                text: OPERATOR_TYPE_ENUM.CONTAINS.text,
            },
            {
                key: OPERATOR_TYPE_ENUM.NOT_CONTAINS.id,
                value: OPERATOR_TYPE_ENUM.NOT_CONTAINS.id,
                text: OPERATOR_TYPE_ENUM.NOT_CONTAINS.text,
            },
        ],
        inputType: {
            id: FILTER_INPUT_TYPE_ENUM.INPUT,
            props: {
                type: 'text',
            },
        },
        generateFilterTerm: (operatorId, inputValue) => {
            let term = '';
            switch (operatorId) {
                case OPERATOR_TYPE_ENUM.CONTAINS.id:
                    term = `and contains(procedure/stageArea/type/type/oemIqSectionName , '${inputValue}')`;
                    break;
                case OPERATOR_TYPE_ENUM.NOT_CONTAINS.id:
                    term = `and not contains(procedure/stageArea/type/type/oemIqSectionName , '${inputValue}')`;
                    break;
                default:
                    break;
            }
            return term;
        },
    },
    [TABLE_COLUMN_ENUM.CREATE_DATE]: {
        operators: [
            {
                key: OPERATOR_TYPE_ENUM.EQUAL.id,
                value: OPERATOR_TYPE_ENUM.EQUAL.id,
                text: OPERATOR_TYPE_ENUM.EQUAL.text,
            },
            {
                key: OPERATOR_TYPE_ENUM.NOT_EQUAL.id,
                value: OPERATOR_TYPE_ENUM.NOT_EQUAL.id,
                text: OPERATOR_TYPE_ENUM.NOT_EQUAL.text,
            },
            {
                key: OPERATOR_TYPE_ENUM.GREATER_THAN.id,
                value: OPERATOR_TYPE_ENUM.GREATER_THAN.id,
                text: OPERATOR_TYPE_ENUM.GREATER_THAN.text,
            },
            {
                key: OPERATOR_TYPE_ENUM.LESS_THAN.id,
                value: OPERATOR_TYPE_ENUM.LESS_THAN.id,
                text: OPERATOR_TYPE_ENUM.LESS_THAN.text,
            },
        ],
        inputType: {
            id: FILTER_INPUT_TYPE_ENUM.DATE_PICKER,
            props: {},
        },
        generateFilterTerm: (operatorId, inputValue) => {
            let term = '';
            switch (operatorId) {
                case OPERATOR_TYPE_ENUM.EQUAL.id:
                    term = `and date(createDate) eq ${inputValue}`;
                    break;
                case OPERATOR_TYPE_ENUM.NOT_EQUAL.id:
                    term = `and date(createDate) ne ${inputValue}`;
                    break;
                case OPERATOR_TYPE_ENUM.GREATER_THAN.id:
                    term = `and date(createDate) gt ${inputValue}`;
                    break;
                case OPERATOR_TYPE_ENUM.LESS_THAN.id:
                    term = `and date(createDate) lt ${inputValue}`;
                    break;
                default:
                    break;
            }
            return term;
        },
    },
    [TABLE_COLUMN_ENUM.UPDATE_DATE]: {
        operators: [
            {
                key: OPERATOR_TYPE_ENUM.EQUAL.id,
                value: OPERATOR_TYPE_ENUM.EQUAL.id,
                text: OPERATOR_TYPE_ENUM.EQUAL.text,
            },
            {
                key: OPERATOR_TYPE_ENUM.NOT_EQUAL.id,
                value: OPERATOR_TYPE_ENUM.NOT_EQUAL.id,
                text: OPERATOR_TYPE_ENUM.NOT_EQUAL.text,
            },
            {
                key: OPERATOR_TYPE_ENUM.GREATER_THAN.id,
                value: OPERATOR_TYPE_ENUM.GREATER_THAN.id,
                text: OPERATOR_TYPE_ENUM.GREATER_THAN.text,
            },
            {
                key: OPERATOR_TYPE_ENUM.LESS_THAN.id,
                value: OPERATOR_TYPE_ENUM.LESS_THAN.id,
                text: OPERATOR_TYPE_ENUM.LESS_THAN.text,
            },
        ],
        inputType: {
            id: FILTER_INPUT_TYPE_ENUM.DATE_PICKER,
            props: {},
        },
        generateFilterTerm: (operatorId, inputValue) => {
            let term = '';
            switch (operatorId) {
                case OPERATOR_TYPE_ENUM.EQUAL.id:
                    term = `and date(updateDate) eq ${inputValue}`;
                    break;
                case OPERATOR_TYPE_ENUM.NOT_EQUAL.id:
                    term = `and date(updateDate) ne ${inputValue}`;
                    break;
                case OPERATOR_TYPE_ENUM.GREATER_THAN.id:
                    term = `and date(updateDate) gt ${inputValue}`;
                    break;
                case OPERATOR_TYPE_ENUM.LESS_THAN.id:
                    term = `and date(updateDate) lt ${inputValue}`;
                    break;
                default:
                    break;
            }
            return term;
        },
    },
    [TABLE_COLUMN_ENUM.STATUS]: {
        operators: [
            {
                key: OPERATOR_TYPE_ENUM.EQUAL.id,
                value: OPERATOR_TYPE_ENUM.EQUAL.id,
                text: OPERATOR_TYPE_ENUM.EQUAL.text,
            },
            {
                key: OPERATOR_TYPE_ENUM.NOT_EQUAL.id,
                value: OPERATOR_TYPE_ENUM.NOT_EQUAL.id,
                text: OPERATOR_TYPE_ENUM.NOT_EQUAL.text,
            },
        ],
        inputType: {
            id: FILTER_INPUT_TYPE_ENUM.DROPDOWN,
            props: {
                defaultOption: { value: '', text: 'Select status' },
                options: [],
            },
        },
        generateFilterTerm: (operatorId, inputValue, flagModeOn) => {
            let term = '';
            if (!flagModeOn) {
                switch (operatorId) {
                    case OPERATOR_TYPE_ENUM.EQUAL.id:
                        term = `and workFlowStatusId eq ${inputValue}`;
                        break;
                    case OPERATOR_TYPE_ENUM.NOT_EQUAL.id:
                        term = `and workFlowStatusId ne ${inputValue}`;
                        break;
                    default:
                        break;
                }
            }
            return term;
        },
    },
    [TABLE_COLUMN_ENUM.NEEDS_ATTENTION]: {
        operators: [
            {
                key: OPERATOR_TYPE_ENUM.EQUAL.id,
                value: OPERATOR_TYPE_ENUM.EQUAL.id,
                text: OPERATOR_TYPE_ENUM.EQUAL.text,
            },
        ],
        inputType: {
            id: FILTER_INPUT_TYPE_ENUM.DROPDOWN,
            props: {
                defaultOption: { value: '', text: 'Select value' },
                options: [],
            },
        },
        generateFilterTerm: (operatorId, inputValue, flagModeOn) => {
            let term = '';
            if (flagModeOn) {
                switch (operatorId) {
                    case OPERATOR_TYPE_ENUM.EQUAL.id:
                        term = `and needsAttention eq ${inputValue}`;
                        break;
                    case OPERATOR_TYPE_ENUM.NOT_EQUAL.id:
                        term = `and needsAttention ne ${inputValue}`;
                        break;
                    default:
                        break;
                }
            }
            return term;
        },
    },
    [TABLE_COLUMN_ENUM.LINK]: {
        operators: [
            {
                key: OPERATOR_TYPE_ENUM.CONTAINS.id,
                value: OPERATOR_TYPE_ENUM.CONTAINS.id,
                text: OPERATOR_TYPE_ENUM.CONTAINS.text,
            },
            {
                key: OPERATOR_TYPE_ENUM.NOT_CONTAINS.id,
                value: OPERATOR_TYPE_ENUM.NOT_CONTAINS.id,
                text: OPERATOR_TYPE_ENUM.NOT_CONTAINS.text,
            },
        ],
        inputType: {
            id: FILTER_INPUT_TYPE_ENUM.INPUT,
            props: {
                type: 'text',
            },
        },
        generateFilterTerm: (operatorId, inputValue, flagModeOn) => {
            let term = '';
            if (!flagModeOn) {
                switch (operatorId) {
                    case OPERATOR_TYPE_ENUM.CONTAINS.id:
                        term = `and contains(cast(oneTimeUseTagLink, 'Edm.String'), '${inputValue}')`;
                        break;
                    case OPERATOR_TYPE_ENUM.NOT_CONTAINS.id:
                        term = `and not contains(cast(oneTimeUseTagLink, 'Edm.String'), '${inputValue}')`;
                        break;
                    default:
                        break;
                }
            }
            return term;
        },
    },
    [TABLE_COLUMN_ENUM.ONE_TIME_USE_FLAG_TERM_ID]: {
        operators: [
            {
                key: OPERATOR_TYPE_ENUM.EQUAL.id,
                value: OPERATOR_TYPE_ENUM.EQUAL.id,
                text: OPERATOR_TYPE_ENUM.EQUAL.text,
            },
            {
                key: OPERATOR_TYPE_ENUM.NOT_EQUAL.id,
                value: OPERATOR_TYPE_ENUM.NOT_EQUAL.id,
                text: OPERATOR_TYPE_ENUM.NOT_EQUAL.text,
            },
        ],
        inputType: {
            id: FILTER_INPUT_TYPE_ENUM.INPUT,
            props: {
                type: 'number',
            },
        },
        generateFilterTerm: (operatorId, inputValue, flagModeOn) => {
            let term = '';
            if (flagModeOn) {
                switch (operatorId) {
                    case OPERATOR_TYPE_ENUM.EQUAL.id:
                        term = `and oneTimeUseFlagTerm/id eq ${inputValue}`;
                        break;
                    case OPERATOR_TYPE_ENUM.NOT_EQUAL.id:
                        term = `and oneTimeUseFlagTerm/id ne ${inputValue}`;
                        break;
                    default:
                        break;
                }
            }
            return term;
        },
    },
    [TABLE_COLUMN_ENUM.ONE_TIME_USE_FLAG_TERM]: {
        operators: [
            {
                key: OPERATOR_TYPE_ENUM.CONTAINS.id,
                value: OPERATOR_TYPE_ENUM.CONTAINS.id,
                text: OPERATOR_TYPE_ENUM.CONTAINS.text,
            },
            {
                key: OPERATOR_TYPE_ENUM.NOT_CONTAINS.id,
                value: OPERATOR_TYPE_ENUM.NOT_CONTAINS.id,
                text: OPERATOR_TYPE_ENUM.NOT_CONTAINS.text,
            },
        ],
        inputType: {
            id: FILTER_INPUT_TYPE_ENUM.INPUT,
            props: {
                type: 'text',
            },
        },
        generateFilterTerm: (operatorId, inputValue, flagModeOn) => {
            let term = '';
            if (flagModeOn) {
                switch (operatorId) {
                    case OPERATOR_TYPE_ENUM.CONTAINS.id:
                        term = `and contains(oneTimeUseFlagTerm/term , '${inputValue}')`;
                        break;
                    case OPERATOR_TYPE_ENUM.NOT_CONTAINS.id:
                        term = `and not contains(oneTimeUseFlagTerm/term , '${inputValue}')`;
                        break;
                    default:
                        break;
                }
            }
            return term;
        },
    },
    [TABLE_COLUMN_ENUM.IMAGE]: {
        operators: [
            {
                key: OPERATOR_TYPE_ENUM.EQUAL.id,
                value: OPERATOR_TYPE_ENUM.EQUAL.id,
                text: OPERATOR_TYPE_ENUM.EQUAL.text,
            },
        ],
        inputType: {
            id: FILTER_INPUT_TYPE_ENUM.DROPDOWN,
            props: {
                defaultOption: { value: '', text: 'Select value' },
                options: [],
            },
        },
        generateFilterTerm: (operatorId, inputValue, flagModeOn) => {
            if (!flagModeOn && operatorId === OPERATOR_TYPE_ENUM.EQUAL.id) {
                return `and ${inputValue === 'false' ? 'not' : ''} OneTimeUseTagImages/any()`;
            }
            return '';
        },
    },
});
