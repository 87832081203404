import React, { useEffect, useState, useRef } from 'react';
import { Checkbox, PrimaryButton } from 'oemiq-common';
import ReactModal from 'components/Shared/ReactModal';
import Confirmation from 'components/Shared/Confirmation/Confirmation';
import { escapeRegExp } from 'lodash';

const ModalRun = ({ books, ruleIds, isOpen, closeRunModalCallback, runRulesCallback }) => {
    const [selected, setSelected] = useState([]);
    const [bookToFilter, setBookToFilter] = useState('');

    const toggleRowCheckbox = (checked, ruleId) => {
        checked && setSelected(currentSelected => [...currentSelected, ruleId]);
        !checked && setSelected(currentSelected => currentSelected.filter(selectedRule => ruleId !== selectedRule));
    };

    useEffect(() => {
        !isOpen && setSelected([]);
    }, [isOpen]);

    const confirmRef = useRef();

    const isAllRules = ruleIds.length < 1;

    const handleRunAllRules = () => {
        isAllRules
            ? openConfirmationModalForAllBooks(() => runRulesCallback(ruleIds, [], closeRunModalCallback))
            : runRulesCallback(ruleIds, [], closeRunModalCallback);
    };

    const handleRunSelectedRules = () => {
        isAllRules
            ? openConfirmationModalForSelectedBooks(() => runRulesCallback(ruleIds, selected, closeRunModalCallback))
            : runRulesCallback(ruleIds, selected, closeRunModalCallback);
    };

    const openConfirmationModalForAllBooks = runRulesCallback => {
        const modalBody = (
            <>
                You are going to run <b>all rules</b> on all books. <br /> Are you sure you want to continue?
            </>
        );

        openRunRulesConfirmationModal(runRulesCallback, modalBody);
    };

    const openConfirmationModalForSelectedBooks = runRulesCallback => {
        const modalBody = (
            <>
                You are going to run <b>all rules</b> on {selected.length} {selected.length == 1 ? 'book' : 'books'}.{' '}
                <br /> Are you sure you want to continue?
            </>
        );

        openRunRulesConfirmationModal(runRulesCallback, modalBody);
    };

    const openRunRulesConfirmationModal = (runRulesCallback, modalBody) => {
        confirmRef.current.open(runRulesCallback, {
            body: modalBody,
            btnText: <>OK</>,
            center: true,
        });
    };

    return (
        <>
            <ReactModal
                id="modal-run"
                container={undefined}
                isOpen={isOpen}
                toggle={closeRunModalCallback}
                headerComponent={<>Running {isAllRules ? 'all rules' : `${ruleIds.length} rules`}</>}
                bodyComponent={
                    <>
                        <input
                            id="search"
                            name="search"
                            className="form form-control mt-2"
                            type="text"
                            placeholder="Search"
                            value={bookToFilter}
                            onChange={event => setBookToFilter(event.target.value)}
                        />
                        <table className="table mb-0">
                            <thead>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col">Book Name</th>
                                    <th scope="col">Book Id</th>
                                </tr>
                            </thead>
                            <tbody>
                                {books
                                    .filter(b => b.bookName.match(buildCaseInsensitiveRegex(bookToFilter)))
                                    .map(book => (
                                        <tr key={book.bookId}>
                                            <td style={{ width: '2.7rem' }}>
                                                <Checkbox
                                                    id={`row_book_checkbox_${book.bookId}`}
                                                    checked={selected.includes(book.bookId)}
                                                    formCheckClassName={'mt-1'}
                                                    checkboxProps={{ style: { transform: 'scale(2)' } }}
                                                    onChange={e => toggleRowCheckbox(e.target.checked, book.bookId)}
                                                />
                                            </td>
                                            <td className="text-break">{book.bookName}</td>
                                            <td>{book.bookId}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </>
                }
                footerComponent={
                    <div style={{ position: 'sticky', bottom: 0 }}>
                        <PrimaryButton
                            id="modal-run-close"
                            className="me-3"
                            type="button"
                            onClick={closeRunModalCallback}>
                            Close
                        </PrimaryButton>
                        <PrimaryButton
                            id="modal-run-run-all"
                            className="me-3"
                            type="button"
                            disabled={selected.length > 0}
                            onClick={handleRunAllRules}>
                            Run All
                        </PrimaryButton>
                        <PrimaryButton
                            id="modal-run-run"
                            type="button"
                            disabled={selected.length < 1}
                            onClick={handleRunSelectedRules}>
                            Run {selected.length} Selected
                        </PrimaryButton>
                    </div>
                }
                className={'modal-lg sticky-footer'}
            />
            <Confirmation ref={confirmRef} />
        </>
    );
};

const buildCaseInsensitiveRegex = phrase => {
    return new RegExp(escapeRegExp(phrase), 'i');
};

export default ModalRun;
