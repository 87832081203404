import { INPUT_TYPE_ENUM } from 'enums/InputTypeEnum';
import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

const useFilter = (appliedFilters, handleUpdateAppliedFilters, isInitialUrlFiltersCheck) => {
    const [displayFilters, setDisplayFilters] = useState([]);
    const [validationError, setValidationError] = useState(false);
    const [unappliedFilters, setUnappliedFilters] = useState([]);

    useEffect(() => {
        let filterHash = {};

        appliedFilters.forEach(filter => {
            filterHash[filter.id] = filter;
        });

        unappliedFilters.forEach(filter => {
            filterHash[filter.id] = filter;
        });

        let newDisplayFilters = [];
        Object.keys(filterHash).forEach(function (id) {
            newDisplayFilters.push(filterHash[id]);
        });

        setDisplayFilters(newDisplayFilters);
    }, [unappliedFilters, appliedFilters, displayFilters.length]);

    useEffect(() => {
        if (
            unappliedFilters.length < 1 &&
            appliedFilters.length < 1 &&
            displayFilters.length < 1 &&
            isInitialUrlFiltersCheck
        ) {
            let filter = {
                id: uuidv4(),
                selectedColumn: -1,
                selectedOperator: -1,
                term: '',
                selectedColumnInputType: INPUT_TYPE_ENUM.TEXT,
            };
            setUnappliedFilters(arr => [...arr, filter]);
        }
    }, [appliedFilters.length, displayFilters.length, isInitialUrlFiltersCheck, unappliedFilters.length]);

    const addFilter = (filter, changingField) => {
        let index = unappliedFilters.findIndex(f => f.id === filter.id);
        let newArray = [...unappliedFilters];

        // if an unapplied filter's field is changed
        if (index > -1 && newArray[index].selectedColumnInputType !== filter.selectedColumnInputType) {
            filter.term = '';
            filter.selectedOperator = -1;
        } else if (index == -1) {
            let prevAppliedFilter = appliedFilters.find(f => f.Id == filter.Id);
            if (prevAppliedFilter && prevAppliedFilter.selectedColumnInputType !== filter.selectedColumnInputType) {
                filter.term = '';
                filter.selectedOperator = -1;
            }
        }
        if (index > -1) {
            if (changingField && changingField === 'term') newArray[index].term = filter.term;
            else newArray[index] = filter;
        } else {
            newArray.push(filter);
        }

        setUnappliedFilters(newArray);
    };

    const removeFilter = filterId => {
        setValidationError(false);
        let newFilters = appliedFilters.filter(f => f.id !== filterId);

        if (newFilters.length !== appliedFilters.length) handleUpdateAppliedFilters(newFilters);

        let newUnappliedFilters = unappliedFilters.filter(f => f.id !== filterId);

        if (newUnappliedFilters.length !== unappliedFilters.length) setUnappliedFilters(newUnappliedFilters);
    };

    const applyUnappliedFilters = () => {
        let valid = displayFilters.every(filter => {
            return (
                filter.selectedColumn !== -1 && filter.selectedOperator !== -1 && filter.term && filter.term.length > 0
            );
        });

        if (!valid) {
            setValidationError(true);
        } else {
            setValidationError(false);
            setUnappliedFilters([]);
            handleUpdateAppliedFilters(displayFilters);
        }
    };

    const handleApplyFilter = () => {
        let noColumnFilters =
            displayFilters.length === 1 &&
            displayFilters.every(filter => {
                return filter.selectedColumn === -1 && filter.selectedOperator === -1 && filter.term === '';
            });

        if (!noColumnFilters) applyUnappliedFilters();
    };

    return {
        displayFilters,
        addFilter,
        removeFilter,
        handleApplyFilter,
        validationError,
    };
};

export default useFilter;
