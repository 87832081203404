import React, { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { requestGetFirstProcedureVehiclesByProcedureId } from 'api/RepairProcedureApi';
import { requestVehicleByYMM } from 'api/vehicleInfo';
import { createInternalPlan, queuePdfGeneration, downloadPdf } from 'api/RepairPlanApi';
import { AccessControlContext } from 'components/Shared/AccessControl/AccessControl';
import checkPdfForDownload from './CheckPdfForDownload';
import { NotificationsContext } from 'components/Shared/Notifications/Notifications';

type SavePdfProps = {
    procedure;
};

const SavePdf = ({ procedure }: SavePdfProps) => {
    const { userInfo } = useContext(AccessControlContext);
    const [isBusy, setIsBusy] = useState(false);

    const { notifications } = useContext(NotificationsContext);

    async function handleDownloadPDF(procedure) {
        try {
            setIsBusy(true);
            if (userInfo) {
                const procedureVehicle = await requestGetFirstProcedureVehiclesByProcedureId(procedure.procedureId);
                if (!procedureVehicle) {
                    throw new Error(
                        'Generating a PDF file is not possible without assigning the procedure/book to the vehicle. Contact the development team.'
                    );
                }
                const vehicle = await requestVehicleByYMM(
                    procedureVehicle.yearId,
                    procedureVehicle.oemId,
                    procedureVehicle.modelId
                );

                const plan = await createInternalPlan({
                    procedureId: procedure.procedureId,
                    vehicleId: vehicle.vehicleId,
                });

                const printRecordResponse = await queuePdfGeneration({
                    planId: plan.planId,
                    procedureIds: [procedure.procedureId],
                    printRecordType: 1,
                    version: 1,
                });

                await checkPdfForDownload(printRecordResponse.printRecordId);

                await handleDownload(printRecordResponse.printRecordId);
            }
        } catch (error) {
            notifications.pushExceptionDanger(error);
        } finally {
            setIsBusy(false);
        }
    }

    return (
        <button
            type="button"
            style={{ width: '10rem', borderRadius: '20px' }}
            className="btn btn-outline-primary btn"
            disabled={isBusy}
            onClick={() => {
                handleDownloadPDF(procedure);
            }}>
            {!isBusy && <FontAwesomeIcon icon="file-arrow-down" className="me-2" />}
            {isBusy ? 'Generating' : 'Save PDF'}
            {isBusy && <span className="ms-2 spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
        </button>
    );
};

async function handleDownload(printRecordId) {
    const response = await downloadPdf(printRecordId);

    const disposition = response.headers.get('Content-Disposition');
    let filename = 'downloaded.pdf';

    if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
        }
    }

    const blob = await response.blob();

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
}

export default SavePdf;
