import { useContext, useEffect, useState, useCallback } from 'react';
import { requestBulkTaskHistory } from 'api/RepairProcedureApi';
import { useParams } from 'react-router-dom';
import { ToastContext } from 'components/ToastProvider';

const useBulkTaskHistoryModal = (isOpen, onToggle, top) => {
    const { showToast } = useContext(ToastContext);

    const [historyEntries, setHistoryEntries] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    let { oemId } = useParams();

    useEffect(() => {
        let isUnmounted = false;
        (async () => {
            if (isOpen) {
                try {
                    setIsLoading(true);
                    const entries = await requestBulkTaskHistory(oemId, top);
                    setHistoryEntries(entries);
                } catch (error) {
                    showToast(error);
                } finally {
                    if (!isUnmounted) {
                        setIsLoading(false);
                    }
                }
            }
        })();

        return () => (isUnmounted = true);
    }, [isOpen, oemId, showToast, top]);

    const handleToggle = () => {
        onToggle();
    };

    const handleCloseButtonClick = () => {
        onToggle();
    };

    const handleRefreshButtonClick = useCallback(async () => {
        try {
            setIsLoading(true);
            setHistoryEntries([]);
            const entries = await requestBulkTaskHistory(oemId, top);
            setHistoryEntries(entries);
        } catch (error) {
            showToast(error);
        } finally {
            setIsLoading(false);
        }
    }, [oemId, showToast, top]);

    return {
        isLoading,
        historyEntries,
        handleToggle,
        handleCloseButtonClick,
        handleRefreshButtonClick,
    };
};

export default useBulkTaskHistoryModal;
