import { useEffect, useMemo, useState } from 'react';

const useDisplayRowsLimited = flags => {
    const [rowCount, setRowCount] = useState(100);

    const flagsLimited = useMemo(() => {
        return flags.slice(0, rowCount);
    }, [flags, rowCount]);

    const isUserScrolledToBottom = () => {
        if (window.innerHeight + window.pageYOffset + 500 >= document.body.offsetHeight) {
            setRowCount(currState => {
                return currState + 50;
            });
        }
    };

    useEffect(() => {
        const scrollListener = () => {
            isUserScrolledToBottom();
        };
        window.addEventListener('scroll', scrollListener);
        return () => {
            window.removeEventListener('scroll', scrollListener);
        };
    }, []);

    return { flagsLimited };
};

export default useDisplayRowsLimited;
