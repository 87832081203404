import React from 'react';
import FlagTagItem from './FlagTagItem/FlagTagItem';
import NavigationButtons from './NavigationButtons/NavigationButtons';
import useTagsAndFlagsDisplay from './useTagsAndFlagsDisplay';
import FlagDispositionModal from 'components/Modals/FlagDispositionModal';

const TagsAndFlagsDisplay = ({ tags, flags, highlightStyles, toggleFlag, toggleTag, isScrollToContentEnabled }) => {
    const {
        handleFlagDispositionClick,
        dispositions,
        flagDispositionSelection,
        flagAndTagListItems,
        dispositionSelection,
        handleDispositionValueChange,
        handleDispositionModalToggle,
        handleSaveFlagDispositionClick,
        setFlags,
        setTags,
    } = useTagsAndFlagsDisplay(tags, flags, highlightStyles);

    return (
        <div>
            <div className="mt-3">
                {flagAndTagListItems.map(ft => (
                    <FlagTagItem
                        key={ft.key}
                        flagTagItem={ft}
                        toggleTag={toggleTag}
                        toggleFlag={toggleFlag}
                        handleFlagDispositionClick={handleFlagDispositionClick}
                        isScrollToContentEnabled={isScrollToContentEnabled}
                        setFlags={setFlags}
                        setTags={setTags}
                    />
                ))}
            </div>

            <NavigationButtons />

            <FlagDispositionModal
                open={flagDispositionSelection}
                toggle={handleDispositionModalToggle}
                options={dispositions}
                value={dispositionSelection}
                onChange={handleDispositionValueChange}
                onSave={handleSaveFlagDispositionClick}
                title={flagDispositionSelection ? flagDispositionSelection.title : ''}
            />
        </div>
    );
};

export default TagsAndFlagsDisplay;
