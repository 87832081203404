export const QueryParameterNames = {
    ReturnUrl: 'returnUrl',
    Message: 'message',
} as const;

export const LogoutActions = {
    LogoutCallback: 'logout-callback',
    Logout: 'logout',
    LoggedOut: 'logged-out',
} as const;

export const LoginActions = {
    Login: 'login',
    LoginCallback: 'signin-oidc',
    LoginFailed: 'login-failed',
} as const;

export const ApplicationPaths = {
    DefaultLoginRedirectPath: '/',
    Login: `/auth/${LoginActions.Login}`,
    LoginFailed: `/auth/${LoginActions.LoginFailed}`,
    LoginCallback: `/auth/${LoginActions.LoginCallback}`,
    LogOut: `/auth/${LogoutActions.Logout}`,
    LoggedOut: `/auth/${LogoutActions.LoggedOut}`,
    LogOutCallback: `/auth/${LogoutActions.LogoutCallback}`,
} as const;
