import { fetchWithAuthHeader } from './AuthUtils';

export const downloadPlansInCSVFormat = async repairPlanIds => {
    const url = 'api/RepairPlan/DownloadPlansInCSVFormat';
    await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(repairPlanIds),
    }).then(async res => {
        if (!res.ok) throw new Error('Failed to download');

        let a = document.createElement('a');
        a.href = 'data:text/csv;charset=UTF-8,' + encodeURIComponent(await res.json());
        a.download = 'plan.csv';
        document.body.appendChild(a);
        a.click();
        a.remove();
    });
};

export const getRepairPlansByCompanyId = async companyId => {
    const url = `api/RepairPlan/GetRepairPlansByCompanyId/${companyId}`;
    let response = await fetchWithAuthHeader(url);

    if (!response.ok) throw new Error('Failed to get repair plans');

    let res = await response.json();

    return res.value;
};

export const movePlansToOrganizations = async (originalOrgId, proposedOrgId) => {
    const url = `api/RepairPlan/${originalOrgId}/${proposedOrgId}/MovePlansToOrganization`;
    const response = await fetchWithAuthHeader(url, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
        },
    });
    if (!response.ok) throw new Error('Failed to Move Plans to Proposed Organization!');
};

export const requestAssignedUserCleanup = async userId => {
    const url = `api/RepairPlan/Plan/${userId}/AssignedUserCleanup`;
    const response = await fetchWithAuthHeader(url, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
        },
    });

    if (!response.ok) throw new Error('Unable to move users. Pls try again!');
    return response;
};

export const createInternalPlan = async planBody => {
    const url = 'api/RepairPlan/CreateInternalPlan';
    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(planBody),
    });

    if (!response.ok) throw new Error('Failed to create internal plan for RepairDeck');

    return await response.json();
};

export const queuePdfGeneration = async body => {
    const url = 'api/RepairPlan/QueuePdfGeneration';
    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });

    if (!response.ok) throw new Error('Failed to queue pdf');

    return await response.json();
};

export const downloadPdf = async printRecordId => {
    const url = `api/RepairPlan/DownloadPdf/${printRecordId}`;
    const response = await fetchWithAuthHeader(url);

    if (!response.ok) throw new Error('Failed to download pdf');

    return response;
};

export const getPrintRecord = async printRecordId => {
    const url = `api/RepairPlan/GetPrintRecord/${printRecordId}`;
    const response = await fetchWithAuthHeader(url);

    if (!response.ok) throw new Error('Failed to get print record');

    return await response.json();
};

export const movedLocationCleanup = async (organizationId, companyId, companyUsers) => {
    const url = `api/RepairPlan/MovedLocationCleanup`;
    const response = await fetchWithAuthHeader(url, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            OrgId: organizationId,
            CompanyId: companyId,
            PrimaryLocationUserIds: companyUsers,
        }),
    });
    if (!response.ok) throw new Error('Location movement unsuccessful.');
};
