import GroupListNamesComponent from './Helpers/Components/GroupListNamesComponent';
import GroupListIdsComponent from './Helpers/Components/GroupListIdsComponent';

export const proceduresOperatorsList = {
    containsAny: {
        value: 'procedures.containsAny',
        label: 'contains any',
    },
    notContainsAny: {
        value: 'procedures.notContainsAny',
        label: 'not contains',
    },
    containsAll: {
        value: 'procedures.containsAll',
        label: 'contains all',
    },
    eqwo: {
        value: 'procedures.eqwo',
        label: 'equal without order',
    },
    nameContains: {
        value: 'procedures.nameContains',
        label: 'name contains',
    },
};

const proceduresGroupOperatorsIds = [
    proceduresOperatorsList.containsAny,
    proceduresOperatorsList.containsAll,
    proceduresOperatorsList.eqwo,
];

const proceduresGroupOperatorsNames = [
    proceduresOperatorsList.nameContains,
    proceduresOperatorsList.containsAny,
    proceduresOperatorsList.containsAll,
    proceduresOperatorsList.notContainsAny,
    proceduresOperatorsList.eqwo,
];

export const mappingRulesOperatorsList = {
    containsAny: {
        value: 'rulesRunner.containsAny',
        label: 'contains any',
    },
    notContainsAny: {
        value: 'rulesRunner.notContainsAny',
        label: 'not contains',
    },
    containsAll: {
        value: 'rulesRunner.containsAll',
        label: 'contains all',
    },
    eqwo: {
        value: 'rulesRunner.eqwo',
        label: 'equal without order',
    },
    nameContains: {
        value: 'rulesRunner.nameContains',
        label: 'name contains',
    },
};

const mappingRulesGroupOperatorsNames = [
    mappingRulesOperatorsList.nameContains,
    mappingRulesOperatorsList.containsAny,
    mappingRulesOperatorsList.containsAll,
    mappingRulesOperatorsList.notContainsAny,
    mappingRulesOperatorsList.eqwo,
];

export default (isIds: boolean, isProcedures: boolean) => ({
    component: isIds ? GroupListIdsComponent : GroupListNamesComponent,
    operators: isProcedures
        ? isIds
            ? proceduresGroupOperatorsIds
            : proceduresGroupOperatorsNames
        : mappingRulesGroupOperatorsNames,
    defaultValue: '',
    allowFalse: false,
    allowInstances: 1,
    validator: v => v.length > 0,
});
