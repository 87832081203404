import React, { useContext, useReducer, useMemo, useState } from 'react';
import { ReactModal, PrimaryButton, Input } from 'oemiq-common';
import ReactSelect from 'react-select';

import { OemId } from 'helpers/OemId';
import { MappingDefinitionsContext } from 'contexts/MappingDefinitionsContext';
import useOneTimeUseFlagDispositions from 'hooks/useOneTimeUseFlagDispositions';

import { FilterViewModel, InputValue, RuleCondition } from './FilterViewModel';
import RuleConditionPill from './RuleConditionPill';

import { createRule } from 'api/FlaggerApi';

interface FilterState {
    selectedFilter: InputValue | null;
    selectedOperator: InputValue | null;
    selectedValues: InputValue[] | null;
}

type FilterAction =
    | { type: 'SET_FILTER'; payload: InputValue | null }
    | { type: 'SET_OPERATOR'; payload: InputValue | null }
    | { type: 'SET_VALUES'; payload: InputValue[] | null };

const filterReducer = (state: FilterState, action: FilterAction): FilterState => {
    switch (action.type) {
        case 'SET_FILTER':
            return { ...state, selectedFilter: action.payload, selectedOperator: null, selectedValues: null };
        case 'SET_OPERATOR':
            return { ...state, selectedOperator: action.payload };
        case 'SET_VALUES':
            return { ...state, selectedValues: action.payload };
        default:
            throw new Error(`Unhandled action type: ${action}`);
    }
};

interface ModalProps {
    oemId: OemId;
    isOpen: boolean;
    toggle: () => void;
    addNewDispositionRuleCallback: (dispositionRule) => void;
    oneTimeUseFlagTerms;
}

const ModalAddNewRule: React.FC<ModalProps> = ({
    oemId,
    isOpen,
    oneTimeUseFlagTerms,
    toggle,
    addNewDispositionRuleCallback,
}) => {
    const { groups, types } = useContext(MappingDefinitionsContext);

    const viewModel = useMemo(
        () => new FilterViewModel(groups, types, oneTimeUseFlagTerms),
        [groups, types, oneTimeUseFlagTerms]
    );

    const oneTimeUseFlagDispositionTypes = useOneTimeUseFlagDispositions();

    const [ruleConditions, setRuleConditions] = useState<RuleCondition[]>([]);
    const [ruleDisposition, setRuleDisposition] = useState<InputValue | null>(null);

    const [filterState, dispatch] = useReducer(filterReducer, {
        selectedFilter: null,
        selectedOperator: null,
        selectedValues: null,
    });

    const handleOperatorChange = (operator: InputValue | null) => dispatch({ type: 'SET_OPERATOR', payload: operator });
    const handleFilterChange = (filter: InputValue | null) => dispatch({ type: 'SET_FILTER', payload: filter });
    const handleValuesChange = (values: InputValue | InputValue[]) => {
        const processedValues = Array.isArray(values)
            ? values.map(v => ({ label: v.label, value: v.value }))
            : [{ label: values.label, value: values.value }];

        dispatch({
            type: 'SET_VALUES',
            payload: processedValues,
        });
    };

    const handleAddCondition = () => {
        const { selectedFilter, selectedOperator, selectedValues } = filterState;
        if (!selectedFilter || !selectedOperator || !selectedValues) return;

        setRuleConditions(prev => [
            ...prev,
            {
                field: { label: selectedFilter.label, value: selectedFilter.value },
                operator: { label: selectedOperator.label, value: selectedOperator.value },
                values: selectedValues,
            },
        ]);

        dispatch({ type: 'SET_FILTER', payload: null });
        dispatch({ type: 'SET_OPERATOR', payload: null });
        dispatch({ type: 'SET_VALUES', payload: null });
    };

    const handleAddNewRule = async () => {
        if (ruleConditions.length === 0 || !ruleDisposition) return;

        const payloadRuleConditions = ruleConditions.map(condition => ({
            field: condition.field.value,
            operator: condition.operator.value,
            value:
                condition.values.length > 1
                    ? JSON.stringify(condition.values.map(value => value.value))
                    : condition.values[0].value.toString(),
        }));

        const payload = {
            oemId,
            ruleConditions: payloadRuleConditions,
            oneTimeUseFlagDispositionId: ruleDisposition.value,
        };

        setRuleConditions([]);
        setRuleDisposition(null);

        const dispositionRule = await createRule(payload);
        const mappedDispositionRule = {
            ...dispositionRule,
            isActive: dispositionRule.isActive ?? null,
            flagDispositionRuleDefinitions: dispositionRule.flagDispositionRuleDefinitions.map(rd => {
                return {
                    ...rd,
                    operator: rd.ruleOperator,
                };
            }),
        };

        addNewDispositionRuleCallback(mappedDispositionRule);
        toggle();
    };

    const isAddConditionDisabled = !(
        filterState.selectedFilter &&
        filterState.selectedOperator &&
        filterState.selectedValues &&
        !ruleConditions.some(
            c =>
                c.field === filterState.selectedFilter &&
                c.operator === filterState.selectedOperator &&
                c.values.length === filterState.selectedValues.length &&
                c.values.every(v => filterState.selectedValues.some(sv => sv.value === v.value))
        )
    );

    return (
        <ReactModal
            isOpen={isOpen}
            toggle={toggle}
            headerComponent={
                <div className="d-flex justify-content-between">
                    <span>Add new rule</span>
                </div>
            }
            bodyComponent={
                <>
                    <div data-testid="select-condition-field">
                        <ReactSelect
                            className="mb-2"
                            placeholder="Filter"
                            isSearchable={false}
                            options={viewModel.getFilterTypes(ruleConditions)}
                            onChange={handleFilterChange}
                            value={filterState.selectedFilter ?? null}
                        />
                    </div>
                    <div data-testid="select-condition-operator">
                        <ReactSelect
                            className="mb-2"
                            placeholder="Operator"
                            isSearchable={false}
                            options={viewModel.getOperatorTypes(filterState.selectedFilter)}
                            onChange={handleOperatorChange}
                            value={filterState.selectedOperator ?? null}
                        />
                    </div>

                    <div data-testid="select-condition-value">
                        {filterState.selectedFilter &&
                        ['ProcedureTitle', 'Text'].includes(filterState.selectedFilter.value) ? (
                            <Input
                                className="mb-2"
                                type="text"
                                placeholder="Value"
                                value={filterState.selectedValues?.[0]?.label ?? ''}
                                onChange={e => handleValuesChange({ label: e.target.value, value: e.target.value })}
                            />
                        ) : (
                            <ReactSelect
                                isMulti={filterState.selectedFilter?.value === 'Group'}
                                className="mb-2"
                                placeholder="Value"
                                isSearchable={false}
                                options={viewModel.getValueTypes(filterState.selectedFilter)}
                                onChange={handleValuesChange}
                                value={filterState.selectedValues}
                            />
                        )}
                    </div>

                    <div className="d-flex justify-content-end">
                        <PrimaryButton
                            className="me-3"
                            type="button"
                            disabled={isAddConditionDisabled}
                            onClick={handleAddCondition}>
                            Add condition
                        </PrimaryButton>
                    </div>
                    <div className="mb-2">
                        {ruleConditions.map(rc => (
                            <RuleConditionPill
                                key={`${rc.field}-${rc.operator}-${rc.values.map(v => v.value).join('-')}`}
                                ruleCondition={rc}
                            />
                        ))}
                    </div>

                    <div data-testid="select-disposition">
                        <ReactSelect
                            className="mb-2"
                            placeholder="Disposition"
                            isSearchable={false}
                            options={oneTimeUseFlagDispositionTypes?.options.map(o => ({ ...o, label: o.text })) ?? []}
                            onChange={setRuleDisposition}
                            value={ruleDisposition ?? null}
                        />
                    </div>
                </>
            }
            footerComponent={
                <>
                    <PrimaryButton className="me-3" type="button" onClick={toggle}>
                        Cancel
                    </PrimaryButton>
                    <PrimaryButton
                        disabled={ruleConditions.length === 0 || !ruleDisposition}
                        className="me-3"
                        type="button"
                        onClick={handleAddNewRule}>
                        Add New Rule
                    </PrimaryButton>
                </>
            }
        />
    );
};

export default ModalAddNewRule;
