import React, { useCallback, useState } from 'react';
import { ReactModal, PrimaryButton, LightButton, Select } from 'oemiq-common';
import { useEffect } from 'react';

const TagStatusUpdateModal = ({ open, toggle, tabStatus, options, onSave }) => {
    const [selectedOption, setSelectedOption] = useState(-1);

    // reset the selected option after changing the tab
    useEffect(() => {
        setSelectedOption(-1);
    }, [tabStatus]);

    const handleSaveClick = useCallback(() => {
        toggle();
        onSave(selectedOption);
    }, [onSave, selectedOption, toggle]);

    return (
        <ReactModal
            id="bulk-tag-status-update-modal"
            isOpen={open}
            toggle={toggle}
            headerComponent={<div>Update Tag Status</div>}
            bodyComponent={
                <Select
                    id="bulk-tag-status-update-modal-dropdown"
                    label="Status"
                    value={selectedOption}
                    className="form-select"
                    defaultOption={{ key: -1, value: -1, text: 'Select Status' }}
                    options={options}
                    onChange={e => setSelectedOption(parseInt(e.currentTarget.value))}
                />
            }
            footerComponent={
                <div className="d-flex justify-content-between w-100">
                    <LightButton
                        id="bulk-tag-status-update-modal-cancel-button"
                        // formGroupClassName="align-self-start"
                        onClick={toggle}>
                        Cancel
                    </LightButton>
                    <PrimaryButton
                        id="bulk-tag-status-update-modal-save-button"
                        // formGroupClassName="align-self-end"
                        disabled={selectedOption === -1 || selectedOption === tabStatus}
                        onClick={handleSaveClick}>
                        Change Status
                    </PrimaryButton>
                </div>
            }
        />
    );
};

export default TagStatusUpdateModal;
