import React from 'react';
import InfiniteScroll from 'components/InfiniteScroll';

import Table from 'components/Shared/Table/Table';

const disableCheckboxFn = rule => !rule.isActive;

const tableOptions = {
    checkboxes: true,
    disableCheckboxFn,
};

const MappingRulesTable = ({
    dataModel,
    rules,
    selected,
    setSelected,
    headers,
    actions,
    isLoading,
    loadMoreCallback,
}) => {
    return (
        <>
            <Table
                className="mb-5"
                headers={headers}
                data={rules}
                idKey={dataModel.keyField}
                selected={selected}
                setSelected={setSelected}
                cellEventCallbacks={actions}
                options={tableOptions}
            />
            {rules.length > 0 && <InfiniteScroll isLoading={isLoading} loadMoreCallback={loadMoreCallback} />}
        </>
    );
};
export default React.memo(MappingRulesTable);
