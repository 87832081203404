import { useContext } from 'react';
import NavigationTile from 'components/Shared/Components/NavigationTile';
import { AccessControlContext } from 'components/Shared/AccessControl/AccessControl';
import { CUST_TOOLS } from 'components/locations/ManageCustomers/ManageCustomersConstants';

const HomePage = () => {
    const { hasAccess } = useContext(AccessControlContext);

    return (
        <div className="container mt-4">
            <div className="row">
                <NavigationTile
                    path="/mapping-process"
                    text="Mapping Process"
                    id="mapping-process"
                    icon="tags"
                    tileDescription={
                        'Tools for mapping process = assigning groups and a types to car repair procedures'
                    }
                    display={hasAccess('tool.mappingProcess')}
                />
                <NavigationTile
                    path="/tagging-process"
                    text="Tagging Process"
                    id="tagging-process"
                    icon="flag"
                    tileDescription={'Tool for converting flags to tags or adding a new tag to procedures'}
                    display={hasAccess('tool.taggingProcess')}
                />
                <NavigationTile
                    path="/vehicle-process"
                    text="Vehicle Process"
                    icon="car"
                    tileDescription={'Tool for publishing vehicles'}
                    display={hasAccess('tool.vehicleProcess')}
                />
                <NavigationTile
                    path="/refreshmanager"
                    text="Refresh Manager"
                    id="refresh-manager"
                    icon="sink"
                    tileDescription={'Tool for managing refreshed procedure publishing in our system'}
                    display={hasAccess('tool.refreshManager')}
                />
                <NavigationTile
                    path={`/${CUST_TOOLS.baseTool.route}`}
                    text={`${CUST_TOOLS.baseTool.name}`}
                    id="customers"
                    icon="user-friends"
                    tileDescription={'Includes all controls related to managing Customers and Networks'}
                    display={hasAccess('tool.manageCustomers')}
                />
                <NavigationTile
                    path="/other"
                    text="Other"
                    id="other"
                    icon="bars"
                    tileDescription={'Other tools and option.'}
                    display={hasAccess('tool.other')}
                />
            </div>
        </div>
    );
};

export default HomePage;
