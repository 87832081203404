import { requestRegions } from 'api/vehicleInfo';
import { requestGetOemiqSections } from 'api/RepairProcedureApi';

const maxRetryAttempts = 4;
const calcBackOfTimeMs = iteration => 19 + (iteration + 1) ** 5;
const delay = retryCount => new Promise(resolve => setTimeout(resolve, calcBackOfTimeMs(retryCount)));

export const getGroupsWithRetry = async (retryCount = 0, lastError = null) => {
    if (retryCount > maxRetryAttempts) throw new Error(lastError);
    try {
        const groups = await requestRegions();
        return groups.map(r => {
            return { ...r, label: r.regionFriendlyName, value: r.regionId };
        });
    } catch (error) {
        await delay(retryCount);
        return await getGroupsWithRetry(retryCount + 1, error);
    }
};

export const getTypesWithRetry = async (retryCount = 0, lastError = null) => {
    if (retryCount > maxRetryAttempts) throw new Error(lastError);
    try {
        const types = await requestGetOemiqSections();
        const convertedTypes = types.map(t => ({
            ...t,
            text: t.oemIqSectionName,
            value: parseInt(t.oemIqSectionId, 10),
        }));
        return convertedTypes;
    } catch (error) {
        await delay(retryCount);
        return await getTypesWithRetry(retryCount + 1, error);
    }
};
