import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ImportJobChangeReportBodyItem = ({ bookId, bookTitle, handleBookClick, procedures, groups }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="mb-3">
            <h5 className="clickable">
                <span className="pe-3 text-primary" onClick={() => handleBookClick(bookId)}>
                    {bookTitle}
                </span>
                {isOpen ? (
                    <FontAwesomeIcon icon={'chevron-up'} onClick={() => setIsOpen(false)} />
                ) : (
                    <FontAwesomeIcon icon={'chevron-down'} onClick={() => setIsOpen(true)} />
                )}
            </h5>
            {isOpen && (
                <div className="ps-3">
                    <ul>
                        {procedures
                            .sort((a, b) => (a.procedureId < b.procedureId ? 1 : -1))
                            .map(p => {
                                let groupFriendlyNames = [];
                                p.groupIds.forEach(g => {
                                    let group = groups.find(r => r.regionId === g);
                                    groupFriendlyNames.push(group.regionFriendlyName);
                                });
                                return (
                                    <li className="mb-2" key={p.procedureId}>
                                        <div>
                                            {p.procedureId} ~ {p.procedureTitle} ~ <i>{getStatusDisplayName(p)}</i>
                                        </div>
                                        <div>
                                            <b>Groups:</b> {groupFriendlyNames.join()} <b>Type:</b> {p.type}
                                        </div>
                                    </li>
                                );
                            })}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default ImportJobChangeReportBodyItem;
function getStatusDisplayName(p) {
    switch (p.status) {
        case 1:
            return 'New';
        case 2:
            return 'Updated';
        case 3:
            return 'Pending Removal';
        case 4:
            return 'Removed';
        default:
            return 'Unknown';
    }
}
