import { getPrintRecord } from 'api/RepairPlanApi';

const operationCyclicCheckTime = 500;

const checkPdfForDownload = (printRecordId: number): Promise<string> => {
    return new Promise<string>(resolve => {
        const repeatGetPrintRecord = async () => {
            const response = await getPrintRecord(printRecordId);
            if (response.location) {
                resolve(response.location);
            } else {
                setTimeout(repeatGetPrintRecord, operationCyclicCheckTime);
            }
        };

        repeatGetPrintRecord();
    });
};

export default checkPdfForDownload;
