/* eslint-disable react/react-in-jsx-scope */
import { ColumnChangeAction, OPERATORS, RuleableOperatorBackendEnumValue } from './types';
import ReactSelect from 'react-select';
import './OperatorDropdown.scss';

interface SelectOperatorProps {
    operator: RuleableOperatorBackendEnumValue;
    containsText: string;
    onChange: (e: { action: string; operator: RuleableOperatorBackendEnumValue; value: null | string }) => void;
}

const OperatorDropdown = ({ operator, containsText, onChange }: SelectOperatorProps) => {
    return (
        <div className="operator-component-wrapper">
            <ReactSelect
                className="operator-component-select"
                options={Object.values(OPERATORS)}
                value={OPERATORS[operator]}
                onChange={e =>
                    onChange({
                        action: ColumnChangeAction.SetOperator,
                        operator: e.value,
                        value: containsText,
                    })
                }
                styles={{
                    dropdownIndicator: styles => ({ ...styles, padding: '8px 0px' }),
                    valueContainer: styles => ({ ...styles, padding: '2px 2px' }),
                    menu: styles => ({ ...styles, zIndex: 100 }),
                }}
            />
        </div>
    );
};

export default OperatorDropdown;
