import { useContext } from 'react';
import { ReactModal } from 'oemiq-common';
import { Select } from 'oemiq-common';
import { CustomerContext } from 'components/locations/ManageCustomers/ManageCustomersRoutes';
import ManageNetworksForOrgCompany from '../../ManageNetworksForOrgCompany/ManageNetworksForOrgCompany';
import useCompanyModal from './useCompanyModal';
import { COMPANY_CANCEL_BUTTON_ID } from '../../../ManageCustomers/ManageCustomersConstants';
import { LOCATION_NAME_EXISTS_WARNING_ID } from './useCompanyModal';

const FeatureInput = ({ id, feature, checked, onChange, className }) => (
    <div className={`d-flex align-items-center clickable${className ? ` ${className}` : ''}`}>
        <input className="me-2" type="checkbox" id={id} checked={checked} onChange={() => onChange(feature)} />
        <label className="mb-0 clickable" htmlFor={id}>
            {feature.friendlyName}
        </label>
    </div>
);

const CompanyModal = ({ orgId, companyId, isOpen, toggle, onSaveOrEdit, targetOrgId }) => {
    const { billingTypeOptions, features, countries } = useContext(CustomerContext);
    const {
        networks,
        saveEnabled,
        companyName,
        setCompanyName,
        address,
        setAddress,
        city,
        setCity,
        zip,
        setZip,
        state,
        setState,
        countryId,
        setCountryId,
        businessPhone,
        setBusinessPhone,
        website,
        setWebsite,
        inEditState,
        oecId,
        setOecId,
        billingOption,
        setBillingOption,
        companyBillingPrice,
        setCompanyBillingPrice,
        salesAgreementId,
        setSalesAgreementId,
        pricingId,
        setPricingId,
        isInAppSurveyEnabled,
        setIsInAppSurveyEnabled,
        companyFeatures,
        selectedNetwork,
        setSelectedNetwork,
        handleSave,
        handleToggle,
        handleFeatureToggle,
        onDeleteClick,
        onAddNetwork,
        destOrgs,
        destOrgId,
        setDestOrgId,
        handleOrgTransfer,
        stagedOrg,
        destOrgSelected,
        setSearchTerm,
        nameMatchesExisting,
        getCompExistsMessage,
    } = useCompanyModal(orgId, companyId, toggle, onSaveOrEdit, targetOrgId);

    return (
        <ReactModal
            id="create-or-edit-company"
            headerComponent={
                <div>
                    {stagedOrg
                        ? 'Add Location to Existing Organization'
                        : companyId
                        ? 'Edit Company'
                        : 'Create new Company'}
                </div>
            }
            isOpen={isOpen}
            toggle={handleToggle}
            backdrop={inEditState ? 'static' : true}
            bodyComponent={
                <div>
                    {stagedOrg && (
                        <>
                            <h6 className="mt-2 pb-1 font-weight-bold border-bottom border-dark">
                                Parent Organization
                            </h6>
                            <Select
                                id="company-dest-org"
                                formGroupClassName="mt-2 mb-0"
                                className="form-select"
                                value={destOrgId}
                                options={destOrgs}
                                defaultOption={{ value: -1, text: 'Select Destination' }}
                                onChange={e => setDestOrgId(e.currentTarget.value)}
                            />
                            <h6 className="mt-2 mb-1 pb-1 font-weight-bold border-bottom border-dark">
                                Location Information
                            </h6>
                        </>
                    )}

                    <input
                        id="company-name"
                        className="form form-control mt-2"
                        type="text"
                        placeholder="Company Name (Required)"
                        value={companyName}
                        onChange={e => {
                            const entry = e.target.value;
                            setCompanyName(entry);
                            setSearchTerm(entry);
                        }}
                    />
                    {nameMatchesExisting && (
                        <h6 className="danger-text" id={`${LOCATION_NAME_EXISTS_WARNING_ID}`}>
                            {getCompExistsMessage()}
                        </h6>
                    )}
                    <input
                        id="company-address"
                        className="form form-control mt-2"
                        type="text"
                        placeholder="Address (Required)"
                        value={address}
                        onChange={e => setAddress(e.currentTarget.value)}
                    />
                    <input
                        id="company-city"
                        className="form form-control mt-2"
                        type="text"
                        placeholder="City (Required)"
                        value={city}
                        onChange={e => setCity(e.currentTarget.value)}
                    />
                    <input
                        id="company-zip"
                        className="form form-control mt-2"
                        type="text"
                        placeholder="ZIP (Required)"
                        value={zip}
                        onChange={e => setZip(e.currentTarget.value)}
                    />
                    <input
                        id="company-state"
                        className="form form-control mt-2"
                        type="text"
                        placeholder="State (Required)"
                        value={state}
                        onChange={e => setState(e.currentTarget.value)}
                    />
                    {countries && (
                        <Select
                            id="company-country"
                            formGroupClassName="mt-2 mb-0"
                            className="form-select"
                            value={countryId}
                            options={countries}
                            defaultOption={{ value: -1, text: 'Select Country' }}
                            onChange={e => setCountryId(parseInt(e.currentTarget.value))}
                        />
                    )}
                    <input
                        id="company-phone"
                        className="form form-control mt-2"
                        type="text"
                        placeholder="Phone"
                        value={businessPhone}
                        onChange={e => setBusinessPhone(e.currentTarget.value)}
                    />
                    <input
                        id="company-website"
                        className="form form-control mt-2"
                        type="text"
                        placeholder="Website"
                        value={website}
                        onChange={e => setWebsite(e.currentTarget.value)}
                    />
                    {billingTypeOptions && (
                        <Select
                            id="company-billing-type"
                            formGroupClassName="mt-2 mb-0"
                            className="form-select"
                            value={billingOption}
                            options={billingTypeOptions}
                            defaultOption={{ value: -1, text: 'Select Billing Option (required)' }}
                            onChange={e => setBillingOption(e.currentTarget.value)}
                        />
                    )}

                    {parseInt(billingOption) === 3 && (
                        <input
                            id="billing-price-id"
                            type="number"
                            placeholder="Billing Price (Required)"
                            className="form-control form-control-sm mt-2"
                            value={companyBillingPrice}
                            step="0.01"
                            onChange={e => setCompanyBillingPrice(e.currentTarget.value)}
                            onBlur={e => setCompanyBillingPrice(e.currentTarget.value)}
                        />
                    )}

                    <input
                        id="company-sales-agreement-id"
                        className="form form-control mt-2"
                        type="text"
                        placeholder={`Sales Agreement ID ${
                            parseInt(billingOption) === 2 || parseInt(billingOption) === 3 ? '(Required)' : ''
                        }`}
                        value={salesAgreementId}
                        onChange={e => setSalesAgreementId(e.currentTarget.value)}
                    />
                    {(parseInt(billingOption) === 2 || parseInt(billingOption) === 3) && (
                        <input
                            id="pricing-id"
                            className="form form-control mt-2"
                            type="text"
                            placeholder={`Pricing ID ${
                                parseInt(billingOption) === 2 || parseInt(billingOption) === 3 ? '(Required)' : ''
                            }`}
                            value={pricingId}
                            onChange={e => setPricingId(e.currentTarget.value)}
                        />
                    )}
                    <input
                        id="company-oec-id"
                        className="form form-control mt-2"
                        type="text"
                        placeholder="OEC ID"
                        value={oecId}
                        onChange={e => setOecId(e.currentTarget.value)}
                    />
                    <fieldset className="fieldset my-2">
                        <legend className="legend">Features</legend>
                        <div className="d-flex align-items-center">
                            <div className="d-flex align-items-center clickable">
                                <input
                                    className="me-2"
                                    type="checkbox"
                                    id="in-app-survey-checkbox-company-modal"
                                    checked={isInAppSurveyEnabled}
                                    onChange={() => setIsInAppSurveyEnabled(!isInAppSurveyEnabled)}
                                />
                                <label className="mb-0 clickable" htmlFor="in-app-survey-checkbox-company-modal">
                                    In-App Surveys
                                </label>
                            </div>
                            {!!features &&
                                features.map(f => (
                                    <FeatureInput
                                        key={f.id}
                                        id={`feature-${f.id}-checkbox-company-modal`}
                                        feature={f}
                                        className="mx-4"
                                        onChange={handleFeatureToggle}
                                        checked={!!companyFeatures.find(cf => cf.id === f.id)}
                                    />
                                ))}
                        </div>
                    </fieldset>
                    {companyId && (
                        <ManageNetworksForOrgCompany
                            assignedNetworks={networks}
                            onDeleteClick={onDeleteClick}
                            onAddNetwork={onAddNetwork}
                            selectedNetwork={selectedNetwork}
                            setSelectedNetwork={setSelectedNetwork}
                            networkLevelIdFilter={2}
                        />
                    )}
                </div>
            }
            footerComponent={
                <div className="d-flex justify-content-end" id="comp-modal-actions">
                    {(inEditState || destOrgSelected) && (
                        <div>
                            <button
                                type="button"
                                className="btn btn-danger ms-2 h-100"
                                id={COMPANY_CANCEL_BUTTON_ID}
                                onClick={handleToggle}>
                                Cancel
                            </button>
                            <button
                                id="company-modal-save"
                                type="button"
                                className={`btn btn-primary h-100 ms-2 ${destOrgSelected && `d-none`}`}
                                onClick={handleSave}
                                disabled={!saveEnabled}>
                                Save
                            </button>
                            {destOrgSelected && (
                                <button
                                    id="company-modal-transfer-organization"
                                    type="button"
                                    className={`btn btn-primary h-100 ms-2`}
                                    /* if in edit state, combines save & transfer, 
                                        so re-use save validation */
                                    onClick={handleOrgTransfer}
                                    disabled={!saveEnabled}>
                                    Add to Organization
                                </button>
                            )}
                        </div>
                    )}
                </div>
            }
        />
    );
};

export default CompanyModal;
