import { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { requestModelsForOem } from 'api/vehicleInfo';
import { LoadingContext } from 'components/Layout';
import { ToastContext } from 'components/ToastProvider';
import { useNavigate, useLocation } from 'react-router-dom';

const useVehicleModelList = () => {
    const [models, setModels] = useState([]);
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const { showToast } = useContext(ToastContext);
    const { oemId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const tableColumnDetails = [{ title: 'Model Name', columnProperty: 'modelName', sortable: false }];

    useEffect(() => {
        (async () => {
            try {
                incrementLoading();
                const models = await requestModelsForOem(parseInt(oemId));
                models.sort((m1, m2) => (m1.modelName < m2.modelName ? -1 : 1));
                setModels(models);
            } catch (error) {
                showToast(error);
            } finally {
                decrementLoading();
            }
        })();
    }, [oemId, showToast, incrementLoading, decrementLoading]);

    const handleRowClick = clickedObject => {
        navigate(`${location.pathname}/${clickedObject.modelId}`);
    };

    return { models, tableColumnDetails, handleRowClick };
};

export default useVehicleModelList;
