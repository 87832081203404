import React, { FC, useCallback } from 'react';
import calculateClass from 'components/Shared/Table/Cells/Helpers/calculateClass';
import { BasicCellOptions, CellProps } from 'components/Shared/Table/types';

const MappingStatus = (options?: BasicCellOptions): FC<CellProps> => {
    const MappingStatusComponent = useCallback(
        ({ header, item }: CellProps) => {
            const totalMapped = item.totalMapped as number;
            const totalProcedures = item.totalProcedures as number;
            const percentage = (totalMapped / totalProcedures) * 100;

            const value = !isNaN(percentage) && (
                <>
                    {totalMapped}/{totalProcedures} ({percentage.toFixed(2)}%)
                </>
            );
            return (
                <td
                    headers={header.id}
                    className={`${calculateClass(options, item)} text-center text-nowrap`}
                    title={header.title}>
                    {percentage < 100 ? <strong>{value}</strong> : value}
                </td>
            );
        },
        [options]
    );
    return MappingStatusComponent;
};

export default MappingStatus;
