import React from 'react';
import { ReactModal, Input, PrimaryButton, Select } from 'oemiq-common';
import useCreateMappingTrainingModal from './useCreateMappingTrainingModal';

const CreateMappingTrainingModal = ({ isOpen, handleToggle, createTrainingMappingBook }) => {
    const {
        trainingManualName,
        setTrainingManualName,
        oem,
        displayOems,
        setOem,
        book,
        books,
        setBook,
        handleModalClose,
    } = useCreateMappingTrainingModal(handleToggle);

    return (
        <ReactModal
            id="create-mapping-training-modal"
            headerComponent={<div>Create Mapping Training</div>}
            isOpen={isOpen}
            toggle={handleModalClose}
            bodyComponent={
                <div>
                    <Input
                        type="text"
                        id="training-name"
                        placeholder="Type Training Manual Name"
                        value={trainingManualName}
                        onChange={e => setTrainingManualName(e.currentTarget.value)}
                        formGroupClassName="mb-0"
                    />
                    <Select
                        id="oems"
                        value={oem}
                        options={displayOems.map(o => {
                            return { text: o.oemName, value: o.oemId };
                        })}
                        className="form-select"
                        defaultOption={{ value: -1, text: 'Select Oem' }}
                        onChange={e => setOem(e.currentTarget.value)}
                        fullWidth={true}
                        formGroupClassName="mb-0 mt-2"
                    />
                    <Select
                        id="books"
                        value={book}
                        options={books.map(o => {
                            return { text: o.bookName, value: o.bookId };
                        })}
                        className="form-select"
                        defaultOption={{ value: -1, text: 'Select Book' }}
                        onChange={e => setBook(e.currentTarget.value)}
                        fullWidth={true}
                        disabled={oem === -1}
                        formGroupClassName="mb-0 mt-2"
                    />
                </div>
            }
            footerComponent={
                <div>
                    <PrimaryButton
                        type="button"
                        id="save-training-manual"
                        disabled={trainingManualName === '' || book === -1}
                        onClick={() => createTrainingMappingBook(parseInt(book), trainingManualName)}>
                        Save
                    </PrimaryButton>
                </div>
            }
        />
    );
};

export default CreateMappingTrainingModal;
