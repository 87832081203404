export interface ColumnData {
    id: string;
    label: string;
    rulePropertyName: string;
    operator: RuleableOperatorBackendEnumValue;
    hasActiveFilter: boolean;
    value: string;
}

export interface ColumnValues {
    id: string;
    values: string[];
    count: number;
    isLoading: boolean;
    isLoadAll: boolean;
}

export interface ColumnChangeCommand {
    action: ColumnChangeAction;
    propertyName: string;
    id: string;
    operator: RuleableOperatorBackendEnumValue;
    value: string;
}

export enum RuleableOperatorBackendEnumValue {
    Equal = 'Equal',
    Contains = 'Contains',
    NotContains = 'NotContains',
}

export enum ColumnChangeAction {
    SetOperator = 'set-operator',
    SetContainsText = 'set-contains-text',
    SetValue = 'set-value',
}

export const OPERATORS: { [operatorId: string]: { label: string; value: RuleableOperatorBackendEnumValue } } = {
    [RuleableOperatorBackendEnumValue.Equal]: { label: 'equal', value: RuleableOperatorBackendEnumValue.Equal },
    [RuleableOperatorBackendEnumValue.Contains]: {
        label: 'contains',
        value: RuleableOperatorBackendEnumValue.Contains,
    },
    [RuleableOperatorBackendEnumValue.NotContains]: {
        label: 'not contains',
        value: RuleableOperatorBackendEnumValue.NotContains,
    },
};
