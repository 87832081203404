import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import ViewProcedureModal from 'components/Modals/ViewProcedureModal/ViewProcedureModal';
import ViewBulkEditTaggerModal from 'components/Modals/ViewBulkEditTaggerModal';
import BulkEditTable from './BulkEditTable/BulkEditTable';
import SearchTool from './SearchTool';
import ViewWarningModal from './ViewWarningModal';
import useBulkEdit, { LIFECYCLE_FILTER, getFlagTagMode } from './useBulkEdit';
import useQuantityConditions from 'hooks/useQuantityConditions';
import BulkEditFooter from './BulkEditFooter/BulkEditFooter';
import FlagDispositionModal from 'components/Modals/FlagDispositionModal';
import useOneTimeUseFlagDispositions from 'hooks/useOneTimeUseFlagDispositions';
import FilterCategory from 'components/Shared/FilterCategory/FilterCategory';
import { partTypes } from 'helpers/PartTypes';
import './BulkEdit.scss';
import { useMemo } from 'react';

const MAX_MODEL_FILTERS = 10;

const BulkEdit = () => {
    const {
        loading,
        hasMore,
        deletedItemIds,
        restoredItemIds,
        fetchMoreAsync,
        modalProcedureId,
        setModalProcedureId,
        selectedItems,
        handleItemCheckboxClick,
        isBulkEditModalOpen,
        handleModalCloseButtonClick,
        handleModalSaveButtonClick,
        handleModalDeleteButtonClick,
        handleModalRestoreButtonClick,
        handleModalUndoButtonClick,
        handleOnDisposeItem,
        handleRestoreItem,
        items,
        editedItems,
        updateItem,
        flagModeOn,
        groups,
        types,
        handleSearchButtonClick,
        handleSearchActionChange,
        noGroupChecked,
        noTypeChecked,
        refreshedPendingChecked,
        selectAllChecked,
        handleMasterCheckboxClick,
        isWarningModalOpen,
        warningModalType,
        handleApplyButtonClick,
        handleWarningModalCancelButtonClick,
        handleWarningModalConfirmButtonClick,
        handleFlagModeSwitchClick,
        handleBulkEditButtonClick,
        sortedColumns,
        handleSortColumnClick,
        total,
        onNeedsAttentionClick,
        handleShiftButtonKeyDown,
        handleShiftButtonKeyUp,
        dispositionModalOpen,
        dispositionSelection,
        handleDispositionModalToggle,
        handleDispositionModalValueChange,
        handleDispositionSaveButtonClick,
        submitModelFilterCallback,
        modelsOptions,
        filterModelIds,
        lifecycleFilter,
        handleSetLifecycleFilter,
        isLifecycleChange,
        handleResetSortAndFilter,
    } = useBulkEdit();

    const { quantityConditions, addNewQuantityCondition } = useQuantityConditions();
    const oneTimeUseFlagDispositionTypes = useOneTimeUseFlagDispositions();

    const anythingToUndo = useMemo(
        () => [...selectedItems.values()].some(i => getFlagTagMode(flagModeOn, i, isLifecycleChange(i)).canBeUndo),
        [flagModeOn, isLifecycleChange, selectedItems]
    );
    const anythingToEdit = useMemo(
        () => [...selectedItems.values()].some(i => getFlagTagMode(flagModeOn, i, isLifecycleChange(i)).canBeEdited),
        [flagModeOn, isLifecycleChange, selectedItems]
    );
    const anythingToRestore = useMemo(
        () => [...selectedItems.values()].some(i => getFlagTagMode(flagModeOn, i, isLifecycleChange(i)).canBeRestored),
        [flagModeOn, isLifecycleChange, selectedItems]
    );
    const anythingToDispose = useMemo(
        () => [...selectedItems.values()].some(i => getFlagTagMode(flagModeOn, i, isLifecycleChange(i)).canBeDisposed),
        [flagModeOn, isLifecycleChange, selectedItems]
    );

    return (
        <>
            <div className="mt-3" style={{ height: `${items.length === 0 ? 'calc(100vh - 115px)' : ''}` }}>
                <div className="mb-3">
                    <div className="d-flex justify-content-between align-items-baseline">
                        <div className="col-8 ms-2">
                            <SearchTool
                                quantityConditions={quantityConditions}
                                partTypes={partTypes}
                                groups={groups}
                                onSearchActionChange={handleSearchActionChange}
                                onSearch={handleSearchButtonClick}
                                loading={loading}
                                noGroupChecked={noGroupChecked}
                                noTypeChecked={noTypeChecked}
                                refreshedPendingChecked={refreshedPendingChecked}
                                onResetAllFiltersAndSorts={handleResetSortAndFilter}
                            />
                        </div>
                        <FilterCategory
                            options={modelsOptions}
                            filteredValues={filterModelIds.current}
                            submitCallback={submitModelFilterCallback}
                            maxFilters={MAX_MODEL_FILTERS}
                            maxFilterMessage={`Number of model filters is limited to ${MAX_MODEL_FILTERS} due to performance reasons`}
                            title="Apply filter by model"
                            buttonText="Filter models"
                            allText="All models"
                        />
                    </div>
                </div>
                <div
                    id="toggle-flag-tag-info"
                    className="switch switch-sm ps-3"
                    title={flagModeOn ? 'Showing flags only' : 'Showing tags only'}>
                    <span className="ms-2">{flagModeOn ? 'Mode:Flags' : 'Mode:Tags'}</span>
                    <div className="switch switch-sm" title={flagModeOn ? 'Showing flags only' : 'Showing tags only'}>
                        <input
                            disabled={loading}
                            type="checkbox"
                            className="switch"
                            id={'toggle-flag-tag'}
                            checked={flagModeOn}
                            onChange={handleFlagModeSwitchClick}
                        />
                        <label className="text-darkblue mt-2 ms-2" htmlFor={'toggle-flag-tag'}></label>
                    </div>
                </div>
                <div className="d-inline">
                    <ToggleButtonGroup
                        type="radio"
                        disabled={loading}
                        value={lifecycleFilter}
                        onChange={handleSetLifecycleFilter}
                        name="stateFilter">
                        <ToggleButton
                            value={LIFECYCLE_FILTER.All}
                            id={LIFECYCLE_FILTER.All}
                            disabled={loading}
                            variant={lifecycleFilter === LIFECYCLE_FILTER.All ? 'primary' : 'outline-primary'}>
                            All
                        </ToggleButton>
                        <ToggleButton
                            value={LIFECYCLE_FILTER.ActiveOnly}
                            id={LIFECYCLE_FILTER.ActiveOnly}
                            disabled={loading}
                            variant={lifecycleFilter === LIFECYCLE_FILTER.ActiveOnly ? 'primary' : 'outline-primary'}>
                            Active
                        </ToggleButton>
                        <ToggleButton
                            value={LIFECYCLE_FILTER.DisposedOnly}
                            id={LIFECYCLE_FILTER.DisposedOnly}
                            disabled={loading}
                            variant={lifecycleFilter === LIFECYCLE_FILTER.DisposedOnly ? 'primary' : 'outline-primary'}>
                            {flagModeOn ? 'Disposed' : 'Inactive'}
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>
                <div className="row">
                    <div className="col mt-3">
                        <BulkEditTable
                            handleOpenProcedureModal={setModalProcedureId}
                            handleSortColumnClick={handleSortColumnClick}
                            sortedColumns={sortedColumns}
                            loading={loading}
                            hasMore={hasMore}
                            items={items}
                            updateItem={updateItem}
                            fetchMore={fetchMoreAsync}
                            quantityConditions={quantityConditions}
                            addNewQuantityCondition={addNewQuantityCondition}
                            partTypes={partTypes}
                            selectedItems={selectedItems}
                            onItemCheckboxClick={handleItemCheckboxClick}
                            flagModeOn={flagModeOn}
                            groups={groups}
                            types={types}
                            selectAllChecked={selectAllChecked}
                            handleMasterCheckboxClick={handleMasterCheckboxClick}
                            onNeedsAttentionClick={onNeedsAttentionClick}
                            onItemCheckboxShiftButtonKeyDown={handleShiftButtonKeyDown}
                            onItemCheckboxShiftButtonKeyUp={handleShiftButtonKeyUp}
                            onDisposeItem={handleOnDisposeItem}
                            onRestoreItem={handleRestoreItem}
                            isLifecycleChange={isLifecycleChange}
                        />
                    </div>
                </div>
            </div>
            <BulkEditFooter
                selectedItems={selectedItems}
                handleBulkEditButtonClick={handleBulkEditButtonClick}
                editedItems={editedItems}
                deletedItemIds={deletedItemIds}
                restoredItemIds={restoredItemIds}
                handleApplyButtonClick={handleApplyButtonClick}
                items={items}
                total={total}
                loading={loading}
            />

            <ViewBulkEditTaggerModal
                flagModeOn={flagModeOn}
                open={isBulkEditModalOpen}
                quantityConditions={quantityConditions}
                addNewQuantityCondition={addNewQuantityCondition}
                partTypes={partTypes}
                onSave={handleModalSaveButtonClick}
                onClose={handleModalCloseButtonClick}
                onDelete={handleModalDeleteButtonClick}
                onRestore={handleModalRestoreButtonClick}
                onUndo={handleModalUndoButtonClick}
                anythingToUndo={anythingToUndo}
                anythingToDispose={anythingToDispose}
                anythingToEdit={anythingToEdit}
                anythingToRestore={anythingToRestore}
            />
            <ViewProcedureModal procedureId={modalProcedureId} resetProcedureId={() => setModalProcedureId(null)} />
            <ViewWarningModal
                open={isWarningModalOpen}
                onCancel={handleWarningModalCancelButtonClick}
                onConfirm={handleWarningModalConfirmButtonClick}
                type={warningModalType}
                flagModeOn={flagModeOn}
                newItemCount={editedItems.size}
                deletedItemCount={deletedItemIds.size}
                restoredItemCount={restoredItemIds.size}
            />
            <FlagDispositionModal
                open={dispositionModalOpen}
                toggle={handleDispositionModalToggle}
                value={dispositionSelection}
                options={oneTimeUseFlagDispositionTypes.options}
                onChange={handleDispositionModalValueChange}
                onSave={handleDispositionSaveButtonClick}
            />
        </>
    );
};

export default BulkEdit;
