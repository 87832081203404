import React, { useRef, useCallback, useContext } from 'react';
import Icon from 'components/Shared/Elements/Icon';
import Confirmation from 'components/Shared/Confirmation/Confirmation';
import { AccessControlContext } from 'components/Shared/AccessControl/AccessControl';
import Form from 'react-bootstrap/Form';
import { FlaggerToolNumberOfFlags } from './FlaggerToolNumberOfFlags';

const MappingRulesToolRow = ({
    oemId,
    term,
    getPartTypeLabel,
    navigateToTagger,
    runFlagTerm,
    undoFlagTerm,
    deleteFlagTerm,
    activateFlagTerm,
    getNumberOfFlags,
}) => {
    const confirmRef = useRef();
    const handleNavigateToTaggerClick = useCallback(() => {
        navigateToTagger(oemId, term);
    }, [navigateToTagger, oemId, term]);
    const { hasAccess } = useContext(AccessControlContext);

    return (
        <>
            <tr>
                <th scope="row">{term.id}</th>
                <td>{term.term}</td>
                <td>
                    <FlaggerToolNumberOfFlags
                        id={term.id}
                        getNumberOfFlags={getNumberOfFlags}
                        handleNavigateToTaggerClick={handleNavigateToTaggerClick}
                    />
                </td>
                <td>{getPartTypeLabel(term.oneTimeUsePartType)}</td>
                <td
                    style={{ width: '8rem', whiteSpace: 'nowrap' }}
                    className="d-flex align-items-center justify-content-start">
                    <button
                        title="Run flag term"
                        id={`run-flag-term-${term.id}`}
                        className="btn btn-sm btn-primary me-2"
                        disabled={!hasAccess('flagTerm.run') || !term.isActive}
                        onClick={() =>
                            confirmRef.current.open(() => runFlagTerm(term.id), {
                                body: (
                                    <>
                                        Are you sure you want to proceed with{' '}
                                        <strong className="text-primary">running</strong> &ldquo;{term.term}
                                        &rdquo; flag term?
                                    </>
                                ),
                                btnText: (
                                    <>
                                        Run flag term ...
                                        <Icon icon="person-running" />
                                    </>
                                ),
                            })
                        }>
                        ...
                        <Icon icon="person-running" />
                    </button>
                    <button
                        title="Undo"
                        id={`undo-flag-term-${term.id}`}
                        className="btn btn-sm btn-warning me-2"
                        disabled={!hasAccess('flagTerm.undo') || !term.isActive}
                        onClick={() =>
                            confirmRef.current.open(() => undoFlagTerm(term.id), {
                                body: (
                                    <>
                                        Are you sure you want to proceed with{' '}
                                        <strong className="text-warning">undoing</strong> &ldquo;{term.term}
                                        &rdquo; Flag term?
                                    </>
                                ),
                                btnClass: 'btn-warning',
                                btnText: (
                                    <>
                                        Undo flag term <Icon icon="clock-rotate-left" />
                                    </>
                                ),
                            })
                        }>
                        <Icon icon="clock-rotate-left" />
                    </button>
                    <button
                        title="Delete"
                        id={`delete-flag-term-${term.id}`}
                        className={'btn btn-sm btn-danger me-3'}
                        disabled={
                            (!hasAccess('flagTerm.delete') || term['oneTimeUseFlags@odata.count'] ? true : false) ||
                            !term.isActive
                        }
                        onClick={() =>
                            confirmRef.current.open(() => deleteFlagTerm(term.id), {
                                body: (
                                    <>
                                        Are you sure you want to proceed with{' '}
                                        <strong className="text-danger">deleting</strong> &ldquo;{term.term}
                                        &rdquo; Flag term?
                                    </>
                                ),
                                btnClass: 'btn-danger',
                                btnText: (
                                    <>
                                        Delete flag term <Icon icon="trash" />
                                    </>
                                ),
                            })
                        }>
                        <Icon icon="trash" />
                    </button>
                    <Form.Check
                        type="switch"
                        disabled={!hasAccess('flagTerm.toggleActive')}
                        title={'enabled'}
                        className={`default-slider`}
                        checked={term.isActive}
                        onChange={() => activateFlagTerm({ termId: term.id, isActive: !term.isActive })}
                    />
                </td>
            </tr>

            <Confirmation ref={confirmRef} />
        </>
    );
};

export default MappingRulesToolRow;
