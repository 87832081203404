import { useState, useEffect } from 'react';

const searchOptions = [
    { key: 'type', text: 'Type', value: 'OemiqSectionName' },
    { key: 'title', text: 'Procedure Title', value: 'ProcedureTitle' },
    { key: 'body', text: 'Procedure Body', value: 'HTML' },
];

const useMapperSearch = searchProcedures => {
    const [searchValueSelections, setSearchValueSelections] = useState([]);
    const [localSearchValue, setLocalSearchValue] = useState('');
    useEffect(() => {
        setSearchValueSelections(searchOptions.map(o => o.value));
    }, []);

    const handleOptionsSelectChange = e => {
        setSearchValueSelections(e.map(v => v.value));
    };

    const handleSearch = () => {
        searchProcedures(localSearchValue, searchValueSelections);
    };

    const handleSearchKeyPress = event => {
        if (event.key === 'Enter') event.currentTarget.blur();
    };

    return {
        searchOptions,
        searchValueSelections,
        handleOptionsSelectChange,
        localSearchValue,
        setLocalSearchValue,
        handleSearch,
        handleSearchKeyPress,
    };
};

export default useMapperSearch;
