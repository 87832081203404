import { useState } from 'react';
import { LargeCheckbox, Input, PrimaryButton } from 'oemiq-common';
import VehicleRecord from './VehicleRecord';
import { SORT_ORDER } from 'enums/SortOrderEnum';
import SmallSpinner from 'components/SmallSpinner';
import useVehicleManagementTool from './useVehicleManagementTool';
import BulkAssignVehicleModal from './BulkAssignVehicleModal';
import ViewThreeDModelModal from './ViewThreeDModelModal/ViewThreeDModelModal';
import Confirmation from 'components/Shared/Confirmation/Confirmation';
import { VM_TABLE_FILTER_COLUMNS } from 'components/locations/VehicleProcess/VehicleManagement/VehicleManagementConfig';
import FilterItem from 'components/locations/TaggingProcess/BulkEdit/SearchTool/FilterItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';

const VehicleManagementTool = () => {
    const {
        searchValue,
        setSearchValue,
        isLoading,
        searchedVehicles,
        updateTrimFiltering,
        handleBulkEditButtonClick,
        selectedVehicleIds,
        isBulkCheckboxChecked,
        handleCheckboxChange,
        handleBulkCheckboxChange,
        handleCheckboxKeyDown,
        handleCheckboxKeyUp,
        isBulkAssignModalOpen,
        handleModalClose,
        handleModalSave,
        oemId,
        visualModelViewerModel,
        setVisualModalViewerModel,
        handleVehicleDisplayingTagsYesButtonClick,
        confirmationModelRef,
        vehicles,
        filters,
        handleAddFilterButtonClick,
        handleRemoveFilterButtonClick,
        handleSearchButtonClick,
        handleUpdate,
        sortedVehicles,
        handleColumnSort,
        sortedColumn,
        sortOrder,
        updateVehicles,
        handleIsReviewedChange,
    } = useVehicleManagementTool();

    //keep track of active 3d model edit view
    const [activeAssign3dModel, setActiveAssign3dModel] = useState(null);
    const toggleAssign3dModel = vehicleId => {
        setActiveAssign3dModel(vehicleId === activeAssign3dModel ? null : vehicleId);
    };
    const [activeAssignOemModel, setActiveAssignOemModel] = useState(null);
    const toggleAssignOemModel = vehicleId => {
        setActiveAssignOemModel(vehicleId === activeAssignOemModel ? null : vehicleId);
    };

    const sortIndicator = column => {
        if (sortedColumn.includes(column)) {
            if (sortOrder[column] === SORT_ORDER.asc) return <FontAwesomeIcon icon={faSortUp} data-testid={'sortup'} />;
            else if (sortOrder[column] === SORT_ORDER.desc)
                return <FontAwesomeIcon icon={faSortDown} data-testid={'sortdown'} />;
        }
        return null;
    };

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col p-0">
                        <Input
                            id="searchfilter-search"
                            type="text"
                            value={searchValue}
                            formGroupClassName={'m-3'}
                            onChange={e => setSearchValue(e.currentTarget.value)}
                            placeholder="Search"
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    {filters &&
                        filters.map(filter => (
                            <FilterItem
                                key={filter.id}
                                id={filter.id}
                                columnId={filter.columnId}
                                operatorId={filter.operatorId}
                                inputValue={filter.inputValue}
                                options={VM_TABLE_FILTER_COLUMNS}
                                action={filter.action}
                                handleAddFilter={handleAddFilterButtonClick}
                                handleRemoveFilter={handleRemoveFilterButtonClick}
                                handleUpdate={handleUpdate}
                            />
                        ))}
                </div>
                <div className="row mb-3">
                    <div className="flex mt-1">
                        <button
                            disabled={isLoading}
                            title="Apply search and filters"
                            id="oem-search-filter-button"
                            data-testid="oem-search-filter-button"
                            type="button"
                            onClick={handleSearchButtonClick}
                            className="btn btn-primary btn me-3">
                            Apply search/filters
                        </button>
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="col p-0">
                        {isLoading ? (
                            <SmallSpinner />
                        ) : (
                            <table className="table ">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <LargeCheckbox
                                                checked={isBulkCheckboxChecked}
                                                onChange={handleBulkCheckboxChange}
                                                disabled={!searchedVehicles.length}
                                            />
                                        </th>
                                        <th
                                            className="text-nowrap clickable"
                                            scope="col"
                                            data-testid="headerVehicleID"
                                            onClick={() => handleColumnSort('vehicleId')}>
                                            VehicleID {sortIndicator('vehicleId')}
                                        </th>
                                        <th
                                            className="text-nowrap clickable"
                                            scope="col"
                                            data-testid="headerVehicleName"
                                            onClick={() => handleColumnSort('vehicle')}>
                                            Vehicle {sortIndicator('vehicle')}
                                        </th>
                                        <th
                                            className="text-nowrap clickable"
                                            scope="col"
                                            data-testid="headerLocales"
                                            onClick={() => handleColumnSort('locales')}>
                                            Locales {sortIndicator('locales')}
                                        </th>
                                        <th
                                            className="text-nowrap clickable"
                                            scope="col"
                                            data-testid="headerTrimFiltering"
                                            onClick={() => handleColumnSort('trim')}>
                                            Trim Filtering {sortIndicator('trim')}
                                        </th>
                                        <th
                                            className="text-nowrap clickable"
                                            scope="col"
                                            data-testid="headerDisplayTags"
                                            onClick={() => handleColumnSort('tags')}>
                                            Display Tags {sortIndicator('tags')}
                                        </th>
                                        <th
                                            className="text-nowrap clickable"
                                            scope="col"
                                            data-testid="header3DModel"
                                            onClick={() => handleColumnSort('3dModel')}>
                                            3D Model {sortIndicator('3dModel')}
                                        </th>
                                        <th
                                            className="text-nowrap clickable"
                                            scope="col"
                                            data-testid="headerOemModelName"
                                            onClick={() => handleColumnSort('oemModel')}>
                                            OEM Model Name {sortIndicator('oemModel')}
                                        </th>
                                        <th
                                            className="text-nowrap clickable"
                                            scope="col"
                                            data-testid="headerCreateDate"
                                            onClick={() => handleColumnSort('createDate')}>
                                            Date Created {sortIndicator('createDate')}
                                        </th>
                                        <th
                                            className="text-nowrap clickable"
                                            scope="col"
                                            data-testid="headerIsReviewed"
                                            onClick={() => handleColumnSort('isReviewed')}>
                                            Is Reviewed {sortIndicator('isReviewed')}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sortedVehicles.map(v => {
                                        return (
                                            <VehicleRecord
                                                key={v.vehicleId}
                                                vehicleRecord={v}
                                                updateTrimFiltering={updateTrimFiltering}
                                                isActiveAssign3dModel={v.vehicleId === activeAssign3dModel}
                                                onToggleAssign3dModel={toggleAssign3dModel}
                                                onToggleAssignOemModel={toggleAssignOemModel}
                                                isActiveAssignOemModel={v.vehicleId === activeAssignOemModel}
                                                onCheckboxChange={handleCheckboxChange}
                                                selectedVehicleIds={selectedVehicleIds}
                                                onCheckboxKeyDown={handleCheckboxKeyDown}
                                                onCheckboxKeyUp={handleCheckboxKeyUp}
                                                setVisualModalViewerModel={setVisualModalViewerModel}
                                                onUpdateVehicleDisplayingTags={
                                                    handleVehicleDisplayingTagsYesButtonClick
                                                }
                                                updateVehicles={updateVehicles}
                                                onUpdateIsReviewed={handleIsReviewedChange}
                                            />
                                        );
                                    })}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
                {selectedVehicleIds?.length > 0 && (
                    <div className="position-fixed" style={{ bottom: '20px', right: '50px' }}>
                        <PrimaryButton onClick={handleBulkEditButtonClick}>Bulk Edit</PrimaryButton>
                    </div>
                )}
            </div>
            <BulkAssignVehicleModal
                isOpen={isBulkAssignModalOpen}
                onClose={handleModalClose}
                onSave={handleModalSave}
                oemId={oemId}
                selectedVehicleIds={selectedVehicleIds}
                vehicles={vehicles}
            />
            {visualModelViewerModel && (
                <ViewThreeDModelModal
                    isOpen={visualModelViewerModel !== null}
                    groups={[]}
                    handleToggle={() => setVisualModalViewerModel(null)}
                    visualModel={visualModelViewerModel}
                />
            )}
            <Confirmation ref={confirmationModelRef} />
        </>
    );
};

export default VehicleManagementTool;
