import { ReactModal, ModelView } from 'oemiq-common';

const ViewThreeDModelModal = ({ isOpen, groups, handleToggle, visualModel }) => {
    return (
        <ReactModal
            id="bulk-assign-vehicle-modal"
            isOpen={isOpen}
            toggle={handleToggle}
            headerComponent={<div>Viewing {visualModel.visualModelName}</div>}
            bodyComponent={
                <ModelView
                    pause3D={false}
                    regionNames={groups}
                    hiddenRegions={[]}
                    regionClick={() => {
                        return;
                    }}
                    modelPath={visualModel.path}
                    modelFileName={visualModel.fileName}
                    showZoomControls={false}
                    materialReplacements={[]}
                    hideLoading={false}
                />
            }
            footerComponent={null}
        />
    );
};

export default ViewThreeDModelModal;
