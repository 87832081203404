import React, { useEffect, useMemo, useState } from 'react';
import Nav from 'components/Navigation/Nav';
import { LoadingIndicator } from 'oemiq-common';
import useLayout from 'hooks/useLayout';
import Notifications from './Shared/Notifications/Notifications';
import { getGroupsWithRetry, getTypesWithRetry } from './fetchMappingDefinitions';
import { MappingDefinitionsContext } from 'contexts/MappingDefinitionsContext';

export const LoadingContext = React.createContext();

const Layout = ({ children }) => {
    const [mappingDefinitions, setMappingDefinitions] = useState();
    useEffect(() => {
        Promise.all([getGroupsWithRetry(), getTypesWithRetry()]).then(values =>
            setMappingDefinitions({ groups: values[0], types: values[1] })
        );
    }, []);

    const { incrementLoading, decrementLoading, isLoading } = useLayout();
    const contextValue = useMemo(() => {
        return {
            incrementLoading,
            decrementLoading,
        };
    }, [decrementLoading, incrementLoading]);

    return (
        <>
            <LoadingContext.Provider value={contextValue}>
                {mappingDefinitions && (
                    <MappingDefinitionsContext.Provider value={mappingDefinitions}>
                        <Nav />
                        <Notifications>{children}</Notifications>
                    </MappingDefinitionsContext.Provider>
                )}
            </LoadingContext.Provider>
            {isLoading && <LoadingIndicator />}
        </>
    );
};

export default Layout;
