import { useState, useEffect, useContext } from 'react';
import { requestProcedureDetailsApi } from 'api/RepairProcedureApi';
import { ToastContext } from 'components/ToastProvider';

const useProcedureTitle = procedureId => {
    const [procedureTitle, setProcedureTitle] = useState('');
    const { showToast } = useContext(ToastContext);

    useEffect(() => {
        procedureId &&
            requestProcedureDetailsApi(parseInt(procedureId))
                .then(proc => setProcedureTitle(proc.procedureTitle))
                .catch(showToast);
    }, [procedureId, showToast]);

    return {
        procedureTitle,
    };
};

export default useProcedureTitle;
