import React, { createContext, useState, useEffect } from 'react';
import { fetchWithAuthHeader } from 'api/AuthUtils';

const FeatureFlagsContext = createContext();

export const FeatureFlagsProvider = ({ children }) => {
    const [flags, setFlags] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        const product = 'RepairDeck';
        const label = getEnvironmentLabel();
        const url = `api/RepairProcedure/Features/GetFeatureFlagsByProductLabel?product=${product}&label=${label}&api-version=5.0`;
        const fetchFlags = async () => {
            try {
                const response = await fetchWithAuthHeader(url, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                });
                if (!response.ok) throw new Error(`Failed to get feature flags`);

                const data = await response.json();
                setFlags(data);
            } catch (error) {
                // TODO: console.error('Failed to fetch feature flags:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchFlags();
    }, []);

    if (loading) {
        return <div>Loading feature flags...</div>;
    }

    return <FeatureFlagsContext.Provider value={flags}>{children}</FeatureFlagsContext.Provider>;
};

export const getEnvironmentLabel = () => {
    const url = window.location.href;
    const isLocal = url.includes('localhost');
    const isPrEnv = url.includes('pr.dev.adminportal-site.repairlogic.com');
    const isDev = url.includes('dev.adminportal-site.repairlogic.com') && !isPrEnv;
    const isQA =
        url.includes('adminportal-site-qa.repairlogic.com') ||
        url.includes('adminportal-site-qa-cc.repairlogic.com') ||
        url.includes('adminportal-site-qa-ce.repairlogic.com');
    const isProd =
        url.includes('adminportal-site-prod.repairlogic.com') ||
        url.includes('adminportal-site-prod-cc.repairlogic.com') ||
        url.includes('adminportal-site-prod-ce.repairlogic.com');
    const label = isProd ? 'prod' : isQA ? 'qa' : isDev || isPrEnv || isLocal ? 'dev' : '';
    return label;
};

export const useFeatureFlag = flagName => {
    const flags = React.useContext(FeatureFlagsContext);
    const flag = flags.find(ff => ff.flagName === flagName);
    // if (!flag) throw `Feature Flag with name='${flagName}' not found.`;
    if (!flag) return false;
    return flag.isActive;
};

export const FeatureFlagName = {
    HalloweenMode: 'Special.HalloweenMode',
};
