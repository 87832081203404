import { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
import { requestGraphDataNodes, requestGraphDataLinks } from 'api/RepairProcedureApi';
import { useParams } from 'react-router-dom';

const useGraphExplorer = () => {
    const svgNode = useRef();
    const [graphData, setGraphData] = useState(undefined);
    const [init, setIsInit] = useState(false);
    const { bookId } = useParams();

    useEffect(() => {
        const getGraphData = async () => {
            const graphDataNodes = await requestGraphDataNodes(bookId);
            const graphDataLinks = await requestGraphDataLinks(bookId);
            const nodes = graphDataNodes.value.map(n => {
                return {
                    id: n.procedureId,
                    title: n.procedureTitle,
                    group: 1,
                };
            });
            const links = graphDataLinks.value
                .filter(
                    l =>
                        !!nodes.find(n => n.id === l.parentProcedureId) &&
                        !!nodes.find(n => n.id === l.childProcedureId)
                )
                .map(l => {
                    return {
                        source: l.parentProcedureId,
                        target: l.childProcedureId,
                        value: 1,
                    };
                });

            const graphDataMap = {
                nodes,
                links,
            };
            setGraphData(graphDataMap);
        };
        getGraphData();
    }, [bookId]);

    useEffect(() => {
        if (!graphData || init) return;
        setIsInit(true);
        const drag = simulation => {
            function dragstarted(event, d) {
                if (!event.active) simulation.alphaTarget(0.3).restart();
                d.fx = d.x;
                d.fy = d.y;
            }

            function dragged(event, d) {
                d.fx = event.x;
                d.fy = event.y;
            }

            function dragended(event, d) {
                if (!event.active) simulation.alphaTarget(0);
                d.fx = null;
                d.fy = null;
            }

            return d3.drag().on('start', dragstarted).on('drag', dragged).on('end', dragended);
        };

        const color = () => {
            const scale = d3.scaleOrdinal(d3.schemeCategory10);
            return d => scale(d.group);
        };

        const links = graphData.links.map(d => Object.create(d));
        const nodes = graphData.nodes.map(d => Object.create(d));

        const simulation = d3
            .forceSimulation(nodes)
            .force(
                'link',
                d3.forceLink(links).id(d => d.id)
            )
            .force('charge', d3.forceManyBody())
            .force('center', d3.forceCenter());

        const svg = d3.select(svgNode.current);

        const g = svg.append('g').attr('class', 'cont');

        const link = g
            .append('g')
            .attr('stroke', '#999')
            .attr('stroke-opacity', 0.6)
            .selectAll('line')
            .data(links)
            .join('line')
            .attr('stroke-width', d => Math.sqrt(d.value));

        const node = g
            .append('g')
            .attr('stroke', '#fff')
            .attr('stroke-width', 1.5)
            .selectAll('circle')
            .data(nodes)
            .join('circle')
            .attr('r', 5)
            .attr('fill', color)
            .call(drag(simulation));

        node.append('title').text(d => d.title);

        simulation.on('tick', () => {
            link.attr('x1', d => d.source.x)
                .attr('y1', d => d.source.y)
                .attr('x2', d => d.target.x)
                .attr('y2', d => d.target.y);

            node.attr('cx', d => d.x).attr('cy', d => d.y);
        });

        svg.call(
            d3.zoom().on('zoom', function (event) {
                g.attr('transform', event.transform);
            })
        );
        return () => {
            simulation.stop();
        };
    }, [graphData, init]);

    return { svgNode };
};

export default useGraphExplorer;
