import { useState } from 'react';
import Table from 'components/Shared/Table/Table';
import { Select } from 'oemiq-common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import useNetwork from 'hooks/useNetwork';
import tableConfiguration from './tableConfiguration';
import useTableActions from './useTableActions';

const ManageNetworksForOrgCompany = ({
    assignedNetworks,
    onDeleteClick,
    onAddNetwork,
    selectedNetwork,
    setSelectedNetwork,
    networkLevelIdFilter = null,
}) => {
    const { networks } = useNetwork(networkLevelIdFilter);
    const [showAddNetwork, setShowAddNetwork] = useState(false);

    const onAddNetworkClick = () => {
        onAddNetwork();
        onAddNetworkToggle();
    };

    const onAddNetworkToggle = () => {
        setSelectedNetwork(-1);
        setShowAddNetwork(prevState => !prevState);
    };

    const headers = tableConfiguration;
    const actions = useTableActions(onDeleteClick);

    return (
        <div className="mt-4">
            <h6>Networks</h6>
            {assignedNetworks && assignedNetworks.length > 0 && (
                <Table
                    idKey="networkId"
                    headers={headers}
                    data={assignedNetworks ? assignedNetworks : []}
                    className="p-0"
                    cellEventCallbacks={actions}
                />
            )}
            <hr />
            <div className=" d-flex align-items-baseline justify-content-between">
                <div
                    className="w-75"
                    style={{
                        visibility: !showAddNetwork ? 'hidden' : 'visible',
                        opacity: !showAddNetwork ? '0' : '1',
                        transition: 'all .2s',
                    }}>
                    <Select
                        id="network-levels"
                        className="form-select"
                        value={selectedNetwork}
                        options={networks.map(o => {
                            return { text: o.name, value: o.networkId };
                        })}
                        defaultOption={{ value: -1, text: 'Select Level' }}
                        onChange={e => setSelectedNetwork(e.currentTarget.value)}
                        fullWidth={true}
                    />
                </div>

                {showAddNetwork ? (
                    <div
                        style={{
                            visibility: !showAddNetwork ? 'hidden' : 'visible',
                            opacity: !showAddNetwork ? '0' : '1',
                            transition: 'all .2s',
                        }}>
                        <button
                            type="button"
                            id="add-network-to-org"
                            className="btn btn-sm btn-success h-100 me-3"
                            onClick={() => onAddNetworkClick()}>
                            <FontAwesomeIcon icon={faCheck} />
                        </button>
                        <button
                            type="button"
                            id="cancel-add-network"
                            className="btn btn-sm btn-secondary h-100"
                            onClick={() => onAddNetworkToggle()}>
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    </div>
                ) : (
                    <div
                        style={{
                            visibility: !showAddNetwork ? 'visible' : 'hidden',
                            opacity: !showAddNetwork ? '1' : '0',
                            transition: 'all .2s',
                        }}>
                        <button
                            type="button"
                            id="show-add-network"
                            className="btn btn-sm btn-primary h-100"
                            onClick={() => onAddNetworkToggle()}>
                            <FontAwesomeIcon icon={faPlus} />
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ManageNetworksForOrgCompany;
