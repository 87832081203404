import { MappingDefinitionsContext } from 'contexts/MappingDefinitionsContext';
import { useState, useContext, useMemo } from 'react';

const useTagsAndFlagsFilter = (flags, tags) => {
    const { groups, types } = useContext(MappingDefinitionsContext);

    const [appliedFilters, setAppliedFilters] = useState([]);
    const [filterByNoGroups, setFilterByNoGroups] = useState(false);
    const [filterByNoType, setFilterByNoType] = useState(false);
    const [filterRefreshed, setFilterRefreshed] = useState(false);

    const filterIncluded = (elements, filter) =>
        elements.filter(p => {
            const propertyData = String(p[filter.selectedColumn]);
            return propertyData.toLowerCase().includes(filter.term.toLowerCase());
        });

    const filterExcluded = (elements, filter) =>
        elements.filter(p => {
            const propertyData = String(p[filter.selectedColumn]);
            return propertyData.toLowerCase().includes(filter.term.toLowerCase()) === false;
        });

    const filterEquals = (elements, filter) =>
        elements.filter(p => {
            const propertyData = String(p[filter.selectedColumn]);
            return propertyData.toLowerCase() === filter.term.toLowerCase();
        });

    const { filteredFlags, filteredTags } = useMemo(() => {
        const filter = (elements, filterInclucedFn, filterExcludedFn, filterEqualsFn) => {
            return appliedFilters.reduce((acc, current) => {
                if (current.selectedOperator === 'include') {
                    const filteredCurrent = filterInclucedFn(elements, current);
                    return acc.filter(value => filteredCurrent.includes(value));
                } else if (current.selectedOperator === 'exclude') {
                    const filteredCurrent = filterExcludedFn(elements, current);
                    return acc.filter(value => filteredCurrent.includes(value));
                } else {
                    const filteredCurrent = filterEqualsFn(elements, current);
                    return acc.filter(value => filteredCurrent.includes(value));
                }
            }, elements);
        };

        const enritchedTags = tags.map(tag => {
            tag.groups = tag.procedure.stageArea.groups
                ? tag.procedure.stageArea.groups
                      .map(tagGroup => groups.find(g => g.regionId === tagGroup.groupId).regionFriendlyName)
                      .sort()
                : [];
            tag.type = tag.procedure.stageArea.type.typeId
                ? types.find(tagType => tag.procedure.stageArea.type.typeId === tagType.oemIqSectionId).oemIqSectionName
                : '';
            return tag;
        });
        const enritchedFlags = flags.map(flag => {
            flag.groups = flag.procedure.stageArea.groups
                ? flag.procedure.stageArea.groups
                      .map(flagGroup => groups.find(g => g.regionId === flagGroup.groupId).regionFriendlyName)
                      .sort()
                : [];
            flag.type = flag.procedure.stageArea.type.typeId
                ? types.find(flagType => flag.procedure.stageArea.type.typeId === flagType.oemIqSectionId)
                      .oemIqSectionName
                : '';
            return flag;
        });

        let filteredTags = filter(enritchedTags, filterIncluded, filterExcluded, filterEquals);
        let filteredFlags = filter(enritchedFlags, filterIncluded, filterExcluded, filterEquals);

        if (filterByNoGroups) {
            filteredFlags = filteredFlags.filter(f => f.procedure.stageArea.groups?.length === 0);
            filteredTags = filteredTags.filter(f => f.procedure.stageArea.groups?.length === 0);
        }
        if (filterByNoType) {
            filteredFlags = filteredFlags.filter(f => f.procedure.stageArea.type?.typeId === null);
            filteredTags = filteredTags.filter(f => f.procedure.stageArea.type?.typeId === null);
        }

        const isLatestUnpublishedSignificant = d =>
            d.isLatest === true &&
            d.isPublished === false &&
            (!d.versionSignificance /* Unevaluated */ ||
                d.versionSignificance.toLowerCase() === 'MajorRevision'.toLowerCase());

        if (filterRefreshed) {
            filteredFlags = filteredFlags.filter(f =>
                f.procedure.procedureDetails?.some(isLatestUnpublishedSignificant)
            );
            filteredTags = filteredTags.filter(f => f.procedure.procedureDetails?.some(isLatestUnpublishedSignificant));
        }

        return { filteredFlags, filteredTags };
    }, [flags, tags, groups, types, filterByNoGroups, filterByNoType, filterRefreshed, appliedFilters]);

    return {
        filteredFlags,
        filteredTags,
        appliedFilters,
        setAppliedFilters,
        filterByNoGroups,
        setFilterByNoGroups,
        filterByNoType,
        setFilterByNoType,
        filterRefreshed,
        setFilterRefreshed,
    };
};

export default useTagsAndFlagsFilter;
