import { FilteredVehicle } from 'hooks/OemModels/MetaModel/types/FilteredVehicle';
import { FilterClause } from '../MapperTool';
import { VehicleFilter, YearOperator } from 'components/Shared/TableFilters/Types/VehicleFilter';
import { FilterPropertyEnum } from 'hooks/OemModels/MetaModel/types/FilterPropertiesEnum';
import { YearBoundaries } from 'hooks/OemModels/MetaModel/types/YearBoundaries';

export function getFilteredVehicles(filters: FilterClause[]): FilteredVehicle[] {
    const filteredVehicles = filters
        .filter(condition => condition.property === FilterPropertyEnum.Vehicle)
        .map(filter => {
            const vehicleFilter = filter.value as VehicleFilter;

            return {
                ...(vehicleFilter.make ? { oemId: vehicleFilter.make.value } : {}),
                ...(vehicleFilter.model ? { modelId: vehicleFilter.model.value } : {}),
                ...(vehicleFilter.yearOperator?.value === YearOperator.range
                    ? {
                          fromYear: parseInt(vehicleFilter.fromYear) || YearBoundaries.MinValue,
                          toYear: parseInt(vehicleFilter.toYear) || YearBoundaries.MaxValue,
                      }
                    : vehicleFilter.yearNumber
                    ? {
                          fromYear: parseInt(vehicleFilter.yearNumber),
                          toYear: parseInt(vehicleFilter.yearNumber),
                      }
                    : {}),
            };
        });
    return filteredVehicles;
}
