import React from 'react';
import BookFilterTools from './BookFilterTools/BookFilterTools';
import BookItem from './BookItem/BookItem';
import useBooks from './useBooks';

const BooksListMapper = ({ procedureTools = false }) => {
    const isTaggerBooksComponent = false;

    const {
        filteredBooks,
        bookStats,
        bookSearchValue,
        setBookSearchValue,
        isNotCompletedFilter,
        setIsNotCompletedFilter,
        isRefreshedPendingFilter,
        setRefreshedPendingFilter,
        statsLoadingPercentage,
        isOnlyHotSheetFilter,
        setIsOnlyHotSheetFilter,
    } = useBooks(isTaggerBooksComponent);

    return (
        <div>
            <BookFilterTools
                bookSearchValue={bookSearchValue}
                setBookSearchValue={setBookSearchValue}
                displayTaggerDetails={isTaggerBooksComponent}
                isNotCompletedFilter={isNotCompletedFilter}
                setIsNotCompletedFilter={setIsNotCompletedFilter}
                isRefreshedPendingFilter={isRefreshedPendingFilter}
                setRefreshedPendingFilter={setRefreshedPendingFilter}
                statsLoadingPercentage={statsLoadingPercentage()}
                isOnlyHotSheetFilter={isOnlyHotSheetFilter}
                setOnlyHotSheetFilter={setIsOnlyHotSheetFilter}
                procedureTools={procedureTools}
            />
            <table id="tagger-book-table" className="table table-hover">
                <thead>
                    <tr>
                        <th>Book of Procedures</th>
                        <th>Removal Pending</th>
                        <th>Only HotSheet Mapped</th>
                        <th>Groups In Review</th>
                        <th>Types In Review</th>
                        <th>Groups In Complete</th>
                        <th>Types In Complete</th>
                        <th>Needs Help</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredBooks.map(b => (
                        <BookItem
                            key={b.bookId}
                            book={b}
                            bookDetails={bookStats.get(b.bookId)}
                            displayTaggerDetails={isTaggerBooksComponent}
                            isNotCompletedFilter={isNotCompletedFilter}
                            isRefreshedPendingFilter={isRefreshedPendingFilter}
                            isOnlyHotSheetFilter={isOnlyHotSheetFilter}
                            setIsOnlyHotSheetFilter={setIsOnlyHotSheetFilter}
                            procedureTools={procedureTools}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default BooksListMapper;
