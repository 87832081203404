import React, { FC, useMemo } from 'react';
import { CellProps } from 'components/Shared/Table/types';
import calculateClass from 'components/Shared/Table/Cells/Helpers/calculateClass';
import { IngestionButton, IngestionProcess } from '../types';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import { match, P } from 'ts-pattern';
import '../IngestionManager.scss';

const IngestionProcessList: (options?: IngestionProcess) => FC<CellProps> = options => {
    const IngestionProcessComponent = ({ idKey, item, header, index, cellEventCallback }: CellProps) => {
        const radioValue = useMemo(() => {
            return match(item)
                .with(
                    { hasBeenReviewed: false, shouldVehicleBeRun: P.boolean },
                    { hasBeenReviewed: false, shouldBeRun: P.boolean },
                    { hasBeenReviewed: false, shouldBeRun: P.boolean },
                    { hasBeenReviewed: false, shouldBeRun: P.boolean },
                    () => IngestionButton.NewButton
                )
                .with(
                    { hasBeenReviewed: true, shouldVehicleBeRun: false },
                    { hasBeenReviewed: true, shouldBeRun: false },
                    { hasBeenReviewed: true, shouldBeRun: false },
                    { hasBeenReviewed: true, shouldBeRun: false },
                    () => IngestionButton.OffButton
                )
                .with(
                    { hasBeenReviewed: true, shouldVehicleBeRun: true },
                    { hasBeenReviewed: true, shouldBeRun: true },
                    { hasBeenReviewed: true, shouldBeRun: true },
                    { hasBeenReviewed: true, shouldBeRun: true },
                    () => IngestionButton.OnButton
                )
                .run();
        }, [item]);

        return (
            <td headers={header.id} className={`${calculateClass(options, item)} ingestion-process`}>
                <ButtonGroup className="ingestion-process-btngroup">
                    <ToggleButton
                        value={'newButton'}
                        id={`${options.newButton.id}${index}`}
                        className={options.newButton.class}
                        title={options.newButton.title}
                        type="radio"
                        name="radio"
                        disabled={true}
                        variant={radioValue === IngestionButton.NewButton ? 'secondary' : 'outline-secondary'}
                        checked={radioValue === IngestionButton.NewButton}>
                        {'New (off)'}
                    </ToggleButton>
                    <ToggleButton
                        value={'onButton'}
                        id={`${options.onButton.id}${index}`}
                        className={options.onButton.class}
                        title={options.onButton.title}
                        type="radio"
                        name="radio"
                        variant={radioValue === IngestionButton.OnButton ? 'success' : 'outline-secondary'}
                        checked={radioValue === IngestionButton.OnButton}
                        onChange={() =>
                            cellEventCallback({
                                id: item[idKey] as string,
                                actionId: options.onButton.id,
                                index,
                                item,
                            })
                        }>
                        {'On'}
                    </ToggleButton>
                    <ToggleButton
                        value={'offButton'}
                        id={`${options.offButton.id}${index}`}
                        className={options.offButton.class}
                        title={options.offButton.title}
                        type="radio"
                        name="radio"
                        variant={radioValue === IngestionButton.OffButton ? 'danger' : 'outline-secondary'}
                        checked={radioValue === IngestionButton.OffButton}
                        onChange={() =>
                            cellEventCallback({
                                id: item[idKey] as string,
                                actionId: options.offButton.id,
                                index,
                                item,
                            })
                        }>
                        {'Off'}
                    </ToggleButton>
                </ButtonGroup>
            </td>
        );
    };
    return IngestionProcessComponent;
};

export default IngestionProcessList;
