import { useCallback, useEffect, useMemo, useState } from 'react';
import { CheckAllStates, CheckRowCallback, DataItem, Options, ItemReturnEvent } from './types';

const buildSelected = ({ id, index, item }): ItemReturnEvent => ({ id, index, item });

const useCheckboxes = (
    idKey: string,
    data: DataItem[],
    selected: ItemReturnEvent[],
    setSelected: CheckRowCallback,
    settings: Options
) => {
    const [checkAllState, setCheckAllState] = useState(CheckAllStates.unchecked);
    const dataEnabled: ItemReturnEvent[] = useMemo(() => {
        return data
            .map((rd, i) => ({
                id: rd[idKey] as string,
                index: i,
                item: rd,
            }))
            .filter(rd => !settings.disableCheckboxFn(rd.item));
    }, [data, idKey, settings]);

    const toggleCheck = useCallback(
        (e: ItemReturnEvent) => {
            setSelected((curr: ItemReturnEvent[]) => {
                const selectedRowIndex = curr.findIndex(s => s.id === e.id);
                if (selectedRowIndex > -1 && !e.checked) return curr.filter(s => s.id !== e.id);
                if (selectedRowIndex === -1 && e.checked) return [...curr, buildSelected(e)];
            });
        },
        [setSelected]
    );

    const toggleCheckAll = useCallback(
        state => {
            setCheckAllState(state);
            state === CheckAllStates.checked && setSelected(() => [...dataEnabled]);
            state === CheckAllStates.unchecked && setSelected(() => []);
        },
        [dataEnabled, setSelected]
    );

    useEffect(() => {
        selected &&
            dataEnabled.length !== selected.length &&
            setCheckAllState((curr: CheckAllStates) =>
                curr === CheckAllStates.checked ? CheckAllStates.undetermined : curr
            );
    }, [dataEnabled.length, selected]);

    return {
        checkAllState,
        toggleCheck,
        toggleCheckAll,
    };
};

export default useCheckboxes;
