import { useEffect, useContext, useState } from 'react';
import { requestImportJobDelta } from 'api/RepairProcedures/RepairProceduresImportJobApi';
import { LoadingContext } from 'components/Layout';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContext } from 'components/ToastProvider';

const useImportJobChangeReportModal = (importJobId, toggle, toggleHistory) => {
    const [proceduresByBook, setProceduresByBook] = useState(null);
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const { showToast } = useContext(ToastContext);
    let navigate = useNavigate();
    const { oemId } = useParams();

    useEffect(() => {
        (async () => {
            try {
                if (importJobId !== null) {
                    incrementLoading();

                    //Get procedure deltas by importJobId
                    let res = await requestImportJobDelta(importJobId);
                    res = res.books.sort((a, b) => (a.bookName < b.bookName ? 1 : -1));
                    setProceduresByBook(res);
                }
            } catch (error) {
                showToast(error);
            } finally {
                decrementLoading();
            }
        })();
    }, [importJobId, incrementLoading, decrementLoading, showToast]);

    const handleBookClick = bookId => {
        //Navigate to books mapper-old page
        navigate(`/mapper-old/${oemId}/${bookId}`);

        //Toggle both modals closed
        toggle();
        toggleHistory();
    };

    return { proceduresByBook, handleBookClick };
};

export default useImportJobChangeReportModal;
