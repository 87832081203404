import React, { FC, useState, useRef, useEffect, ReactNode } from 'react';
import './FloatingMenu.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { IFloatingMenuProps } from '../types';

const ellipsisIcon = <FontAwesomeIcon icon={faEllipsis as IconProp} color="#195283" size="sm" />;

const FloatingMenu: FC<IFloatingMenuProps> = ({
    floatingMenuId,
    floatingMenuClass = '',
    labelId,
    items,
    label = ellipsisIcon,
}) => {
    const [isHovered, setIsHovered] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ left: 0, bottom: 0 });
    const triggerRef = useRef<HTMLDivElement>(null);
    const optionsRef = useRef<HTMLUListElement>(null);

    useEffect(() => {
        if (isHovered && triggerRef.current && optionsRef.current) {
            const TriggerRect = triggerRef.current.getBoundingClientRect();
            const optionsRect = optionsRef.current.getBoundingClientRect();
            const screenWidth = window.innerWidth;
            const screenHeight = window.innerHeight;
            const TriggerWidth = TriggerRect.right - TriggerRect.left;

            let newLeft: number;
            let newBottom: number;

            // Horizontal positioning
            if (TriggerRect.left + optionsRect.width > screenWidth) {
                newLeft = -optionsRect.width + TriggerWidth;
            } else {
                newLeft = 0;
            }

            // Vertical positioning
            if (TriggerRect.bottom + optionsRect.height > screenHeight) {
                newBottom = optionsRect.height + TriggerRect.height / 2;
            } else {
                newBottom = -(TriggerRect.height / 2);
            }

            setMenuPosition({ left: newLeft, bottom: newBottom });
        }
    }, [isHovered]);

    if (!items || items.length === 0) {
        return null;
    }

    const wrapItemsInListItems = (items: ReactNode[]) => {
        return React.Children.map(items, (option, index) => {
            if (React.isValidElement(option) && option.type === 'li') {
                return option;
            }
            return <li key={index}>{option}</li>;
        });
    };

    return (
        <div
            className={`floating-menu${floatingMenuClass ? ` ${floatingMenuClass}` : ''}`}
            onClick={e => {
                e.stopPropagation();
            }}
            ref={triggerRef}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            data-testid={floatingMenuId}>
            <button className="floating-menu-button" data-testid={labelId}>
                {label}
            </button>
            {isHovered && (
                <ul
                    className="floating-menu-items popover"
                    style={{
                        left: `${menuPosition.left}px`,
                        bottom: `${menuPosition.bottom}px`,
                    }}
                    ref={optionsRef}>
                    {wrapItemsInListItems(items)}
                </ul>
            )}
        </div>
    );
};

export default FloatingMenu;
