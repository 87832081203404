import React from 'react';
import { Route, Routes, Outlet } from 'react-router-dom';
import ToolSelection from 'components/locations/Home/ToolSelection';
import AuthorizedElement from 'routes/AuthorizedElement';
import Networks from './Networks/Networks';
import Organizations from './Organizations/Organizations';
import useBillingTypes from 'hooks/useBillingTypes';
import useFeatures from 'hooks/useFeatures';
import useCountries from 'hooks/useCountries';
import CompanyUsers from './CompanyUsers/CompanyUsers';
import OrganizationUsers from './OrganizationUsers/OrganizationUsers';
import OrganizationCompanies from './OrganizationCompanies/OrganizationCompanies';
import 'components/locations/ManageCustomers/CustomerToolsStyles.scss';
import { CUST_TOOLS } from './ManageCustomersConstants';
import './ManageCustomers.scss';
import ManageCustomersNavigation from 'components/Navigation/ManageCustomersNavigation';

export const CustomerContext = React.createContext();

const ManageCustomersRoutes = () => {
    const { billingTypes, billingTypeOptions } = useBillingTypes();
    const { countries } = useCountries();
    const { features } = useFeatures();

    return (
        <CustomerContext.Provider value={{ billingTypes, billingTypeOptions, features, countries }}>
            <Routes>
                {/* customer tool selection */}
                <Route
                    index
                    element={
                        <ToolSelection
                            tools={[
                                {
                                    path: `${CUST_TOOLS.orgTool.route}`,
                                    text: `${CUST_TOOLS.orgTool.name}`,
                                    icon: 'user-friends',
                                    tileDescription:
                                        'Tool for managing the organizations, locations within an organization and users within locations.',
                                    access: 'tool.manageCustomers.organizations',
                                },
                                {
                                    path: `${CUST_TOOLS.networkTool.route}`,
                                    text: `Manage ${CUST_TOOLS.networkTool.name}`,
                                    icon: 'sitemap',
                                    tileDescription: 'Tool for managing networks available in the system',
                                    access: 'tool.manageCustomers.networks',
                                },
                            ]}
                        />
                    }
                />
                {/* org > company > users || org > users flow */}
                <Route
                    path={`${CUST_TOOLS.orgTool.route}`}
                    element={
                        <AuthorizedElement resource="tool.manageCustomers.organizations">
                            <Outlet />
                        </AuthorizedElement>
                    }>
                    <Route index element={<Organizations />} />
                    <Route path=":organizationId" element={<Outlet />}>
                        <Route index element={<OrganizationCompanies />} />
                        <Route path={`${CUST_TOOLS.orgUserTool.route}`} element={<OrganizationUsers />} />
                        <Route path={`${CUST_TOOLS.compTool.route}`} element={<Outlet />}>
                            <Route path=":locationId" element={<Outlet />}>
                                <Route index element={<CompanyUsers />} />
                                <Route path={`${CUST_TOOLS.compUserTool.route}`} element={<CompanyUsers />} />
                            </Route>
                        </Route>
                    </Route>
                    <Route element={<Outlet />}>
                        <Route path={`${CUST_TOOLS.compTool.route}`} index element={<OrganizationCompanies />} />
                        <Route path={`${CUST_TOOLS.orgUserTool.route}`} element={<OrganizationUsers />} />
                    </Route>
                </Route>
                {/* network flow */}
                <Route
                    path={CUST_TOOLS.networkTool.route}
                    element={
                        <AuthorizedElement resource="tool.manageCustomers.networks">
                            <Networks />
                        </AuthorizedElement>
                    }
                />
            </Routes>
        </CustomerContext.Provider>
    );
};

export const ManageCustomersRoute = {
    path: CUST_TOOLS.baseTool.route + '/*',
    element: <ManageCustomersRoutes />,
    handle: {
        resource: 'tool.manageCustomers',
        crumb: () => (
            <Routes>
                <Route path={`${CUST_TOOLS.baseTool.route}`} element={<ManageCustomersNavigation />}>
                    <Route path=":customerTool" element={<ManageCustomersNavigation />}>
                        <Route path=":organizationId" element={<ManageCustomersNavigation />}>
                            <Route path=":orgTool" element={<ManageCustomersNavigation />}>
                                <Route path=":locationId" element={<ManageCustomersNavigation />}>
                                    <Route path=":locTool" element={<ManageCustomersNavigation />} />
                                </Route>
                            </Route>
                        </Route>
                    </Route>
                </Route>
            </Routes>
        ),
    },
};

export default ManageCustomersRoutes;
