import React, { useEffect, useRef } from 'react';

const MAGIC_PIXEL_CORRECTION = 4;

const TextareaAutoHeight = ({ value, disabled, onChange, onBlur, onKeyPress, ...props }) => {
    const ref = useRef();

    const setHeight = () => {
        ref.current.style.height = `${ref.current.scrollHeight + MAGIC_PIXEL_CORRECTION}px`;
    };

    const onChangeHandler = () => {
        if (ref.current.scrollHeight > ref.current.offsetHeight) {
            setHeight();
        }
    };

    useEffect(() => {
        setHeight();
    }, []);

    return (
        <textarea
            ref={ref}
            value={value}
            style={{ width: '100%', height: '29px' }}
            onChange={e => {
                onChangeHandler();
                onChange(e);
            }}
            onBlur={onBlur}
            onKeyPress={onKeyPress}
            disabled={disabled}
            {...props}
        />
    );
};

export default TextareaAutoHeight;
