import React, { useMemo } from 'react';
import useToast from './useToast';
import Toast from './Toast';

export const ToastContext = new React.createContext();

const ToastProvider = ({ children }) => {
    const { isOpen, header, message, type, showToast, showToastMessage, setToastType, hideToast } = useToast();
    const contextValue = useMemo(() => {
        return { showToast, showToastMessage, setToastType };
    }, [setToastType, showToast, showToastMessage]);

    return (
        <ToastContext.Provider value={contextValue}>
            {children}
            <Toast isOpen={isOpen} header={header} type={type} message={message} toggle={hideToast} />
        </ToastContext.Provider>
    );
};

export default ToastProvider;
