const mappingActionsIdsMap = [
    { mappingActionId: 0, mappingActionName: 'Historical' },
    { mappingActionId: 1, mappingActionName: 'Manually added' },
    { mappingActionId: 2, mappingActionName: 'Status changed manually' },
    { mappingActionId: 3, mappingActionName: 'Manually removed' },
    { mappingActionId: 4, mappingActionName: 'Automatically added' },
    { mappingActionId: 5, mappingActionName: 'Automatically changed' },
    { mappingActionId: 6, mappingActionName: 'Automatically removed' },
    { mappingActionId: 7, mappingActionName: 'Automatically added' },
    { mappingActionId: 8, mappingActionName: 'Added by a rule' },
    { mappingActionId: 9, mappingActionName: 'Removed by Undo operation' },
];

export const getMappingActionById = (mappingActionId: number) => {
    return mappingActionsIdsMap.find(m => m.mappingActionId === mappingActionId);
};
