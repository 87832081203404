import React from 'react';
import { CellProps } from 'components/Shared/Table/types';
import { RefreshDashboardRowDataType } from '../types';

const OemCell: React.FC<CellProps> = ({ item }) => {
    const rowData = item as RefreshDashboardRowDataType;
    const id = `import-manager-item-${item.oemId}-oem`;

    return (
        <td style={{ verticalAlign: 'middle' }} id={id}>
            <div className="d-flex flex-row justify-content-begin align-items-center">
                <div style={{ width: '24px' }}>
                    {rowData.isLoading ? (
                        <span
                            className="spinner-grow spinner-grow-sm text-primary"
                            role="status"
                            aria-hidden="true"
                            title="Loading data"></span>
                    ) : (
                        <span
                            className={`d-inline-block rounded-circle ${
                                rowData.lastImportJobSummary ? 'bg-success' : 'bg-danger'
                            }`}
                            style={{ width: '1rem', height: '1rem' }}
                            title={`Data loaded ${
                                rowData.lastImportJobSummary ? 'successfully' : 'unsuccessfully'
                            }`}></span>
                    )}
                </div>
                <div>
                    <img
                        width="56px"
                        src={rowData.logo}
                        alt={rowData.oemName}
                        title={`${rowData.oemId} - ${rowData.oemName}`}
                    />
                </div>
            </div>
        </td>
    );
};

export default OemCell;
