import apiConfig from 'api/config/ApiConfig';

let loggableError = false;

// get config from appsettings.{env}.json file
(async () => {
    const _apiConfig = await apiConfig.get();
    loggableError = _apiConfig.errorLog === 'development';
})();

/**
 * Determine if the current working environment is allowed to log errors to console
 * @returns true if the working env is dev, false otherwise
 */
export const isAllowedToLogErrors = () => {
    return loggableError;
};
