import React, { useState } from 'react';
import { ReactModal } from 'oemiq-common';
import { TABLE_COLUMN_ENUM } from 'helpers/BulkEditTableHelper';

import Body from './Body';
import Footer from './Footer';

const Modal = ({
    open,
    quantityConditions,
    addNewQuantityCondition,
    partTypes,
    onSave,
    onClose,
    onDelete,
    onRestore,
    onUndo,
    flagModeOn,
    anythingToUndo,
    anythingToDispose,
    anythingToEdit,
    anythingToRestore,
}) => {
    const [title, setTitle] = useState({ value: '', edited: false });
    const [quantity, setQuantity] = useState({ value: '', edited: false });
    const [partNumber, setPartNumber] = useState({ value: '', edited: false });
    const [price, setPrice] = useState({ value: '', edited: false });
    const [text, setText] = useState({ value: '', edited: false });
    const [quantityCondition, setQuantityCondition] = useState({ value: '', edited: false });
    const [partType, setPartType] = useState({ value: '', edited: false });
    const [status, setStatus] = useState({ value: '', edited: false });

    const handleClose = () => {
        resetStates();
        onClose();
    };

    const handleSave = () => {
        const data = {
            editedFields: new Set(),
        };
        if (title.edited) {
            data.editedFields.add(TABLE_COLUMN_ENUM.TITLE);
            data.title = title.value;
        }
        if (quantity.edited) {
            data.editedFields.add(TABLE_COLUMN_ENUM.QUANTITY);
            data.quantity = quantity.value;
        }
        if (partNumber.edited) {
            data.editedFields.add(TABLE_COLUMN_ENUM.PART_NUMBER);
            data.partNumber = partNumber.value;
        }
        if (price.edited) {
            data.editedFields.add(TABLE_COLUMN_ENUM.PRICE);
            data.price = price.value;
        }
        if (text.edited) {
            data.editedFields.add(TABLE_COLUMN_ENUM.TEXT);
            data.text = text.value;
        }
        if (quantityCondition.edited) {
            data.editedFields.add(TABLE_COLUMN_ENUM.QUANTITY_CONDITION);
            data.quantityCondition = quantityCondition.value;
        }
        if (partType.edited) {
            data.editedFields.add(TABLE_COLUMN_ENUM.PART_TYPE_ID);
            data.oneTimeUsePartTypeId = partType.value;
        }
        if (status.edited) {
            data.editedFields.add(TABLE_COLUMN_ENUM.STATUS);
            data.workFlowStatusId = status.value;
        }
        resetStates();
        onSave(data);
    };

    const handleDelete = () => {
        onDelete();
    };

    const handleRestore = () => {
        resetStates();
        onRestore();
    };

    const handleUndo = () => {
        resetStates();
        onUndo();
    };

    // reset the states of this modal to default values
    const resetStates = () => {
        setTitle({ value: '', edited: false });
        setQuantity({ value: '', edited: false });
        setPartNumber({ value: '', edited: false });
        setPrice({ value: '', edited: false });
        setText({ value: '', edited: false });
        setQuantityCondition({ value: '', edited: false });
        setPartType({ value: '', edited: false });
        setStatus({ value: '', edited: false });
    };

    return (
        <ReactModal
            id="View-bulk-edit-tagger-modal"
            className={anythingToEdit ? 'modal-xl' : ''}
            isOpen={open}
            headerComponent={<div>Bulk Edit</div>}
            bodyComponent={
                <Body
                    title={title}
                    setTitle={setTitle}
                    quantity={quantity}
                    setQuantity={setQuantity}
                    partNumber={partNumber}
                    setPartNumber={setPartNumber}
                    price={price}
                    setPrice={setPrice}
                    text={text}
                    setText={setText}
                    quantityCondition={quantityCondition}
                    quantityConditions={quantityConditions}
                    setQuantityCondition={setQuantityCondition}
                    addNewQuantityCondition={addNewQuantityCondition}
                    partType={partType}
                    partTypes={partTypes}
                    setPartType={setPartType}
                    status={status}
                    setStatus={setStatus}
                    flagModeOn={flagModeOn}
                    anythingToEdit={anythingToEdit}
                />
            }
            footerComponent={
                <Footer
                    onClose={handleClose}
                    onSave={handleSave}
                    onDelete={handleDelete}
                    onRestore={handleRestore}
                    onUndo={handleUndo}
                    anythingToUndo={anythingToUndo}
                    anythingToDispose={anythingToDispose}
                    anythingToEdit={anythingToEdit}
                    anythingToRestore={anythingToRestore}
                />
            }
        />
    );
};

export default Modal;
