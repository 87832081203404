import { PrimaryButton, LightButton } from 'oemiq-common';
import { useEffect, useState, useRef } from 'react';
import useAssignOemModelToVehicle from './useAssignOemModelToVehicle';
import Icon from 'components/Shared/Elements/Icon';
import Confirmation from 'components/Shared/Confirmation/Confirmation';
import OEMModelSearch from './OEMModelSearch';

const AssignOemModelToVehicle = ({ vehicle, active, onToggle, updateVehicles }) => {
    const [isShowOemModelDropdown, setShowOemModelDropdown] = useState(true);
    const confirmRef = useRef();
    const {
        isFindLoading,
        isSaveLoading,
        oemModelOptions,
        onShowOemModelDropDown,
        resultsCount,
        handleCancelClick,
        handleOemModelChange,
        selectedOemModels,
        handleSaveVehicleOemModel,
        reqUnassignVehicleOemModel,
    } = useAssignOemModelToVehicle(vehicle, isShowOemModelDropdown, setShowOemModelDropdown, onToggle, updateVehicles);

    useEffect(() => {
        if (!active) {
            setShowOemModelDropdown(false);
        } else {
            onShowOemModelDropDown();
        }
    }, [active, setShowOemModelDropdown, onShowOemModelDropDown]);

    const vehicleOemModels = vehicle.oemModels?.map(m => m.oemModelName).join(', ');

    return (
        <>
            <div
                id={`assign-oem-model-edit-${vehicle.vehicleId}`}
                data-testid={`assign-oem-model-edit-${vehicle.vehicleId}`}>
                <div className={`dropdown me-3 ${isShowOemModelDropdown ? 'show' : ''}`}>
                    <PrimaryButton
                        id={`assign-oem-model-toggle-${vehicle.vehicleId}`}
                        data-testid={`assign-oem-model-toggle-${vehicle.vehicleId}`}
                        className={`dropdown-toggle ${vehicle.oemModels?.length > 0 ? 'btn-primary' : 'btn-secondary'}`}
                        type="button"
                        onClick={() => (onToggle(vehicle.vehicleId), setShowOemModelDropdown(show => !show))}>
                        {vehicleOemModels ? `${vehicleOemModels}` : 'Assign OemModels'}
                    </PrimaryButton>

                    {vehicleOemModels && (
                        <button
                            title="Unassign"
                            type="button"
                            id={`assign-oem-model-remove-from-${vehicle.vehicleId}`}
                            data-testid={`assign-oem-model-remove-from-${vehicle.vehicleId}`}
                            style={{ marginLeft: '5px' }}
                            className="btn btn-secondary btn-sm me-2"
                            onClick={() =>
                                confirmRef.current.open(
                                    () => reqUnassignVehicleOemModel(vehicle.vehicleId, null, vehicle.oem.oemId),
                                    {
                                        title: '',
                                        body: (
                                            <>
                                                Are you sure you want to{' '}
                                                <strong className="text-secondary">
                                                    unassign <i>{vehicle.oemModels?.length}</i> OEM Model(s){' '}
                                                </strong>
                                                from the{' '}
                                                <strong className="text-secondary">
                                                    {vehicle.year.yearId} {vehicle.oem.oemName}{' '}
                                                    {vehicle.model.modelName}
                                                </strong>
                                                ?
                                            </>
                                        ),
                                        btnClass: 'btn-warning',
                                        btnText: <>Confirm</>,
                                    }
                                )
                            }>
                            <Icon icon="times" />
                        </button>
                    )}

                    {active && (
                        <div
                            className={`dropdown-menu dropdown-menu-left bg-light pt-2 pb-3 pe-3 ps-3 ${
                                isShowOemModelDropdown ? 'show' : ''
                            }`}
                            aria-labelledby="dropdownMenuButton"
                            style={{ minWidth: '450px', borderRadius: '1.3rem' }}>
                            <OEMModelSearch
                                id={`assign-oem-model-search-${vehicle.vehicleId}`}
                                loading={isFindLoading}
                                oemModelOptions={oemModelOptions}
                                oemModelValue={selectedOemModels}
                                onOemModelChange={handleOemModelChange}
                            />
                            <div className="mb-2" style={{ marginRight: '10px' }}>
                                <div className="d-flex justify-content-end">
                                    <LightButton
                                        type="button"
                                        className="mx-2"
                                        onClick={() => (setShowOemModelDropdown(false), handleCancelClick())}>
                                        Cancel
                                    </LightButton>

                                    <PrimaryButton
                                        id={`apply-to-${vehicle.vehicleId}`}
                                        type="button"
                                        className="mx-2"
                                        disabled={resultsCount === 0 || isSaveLoading}
                                        onClick={handleSaveVehicleOemModel}>
                                        Apply
                                    </PrimaryButton>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <Confirmation ref={confirmRef} />
        </>
    );
};

export default AssignOemModelToVehicle;
