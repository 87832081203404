import React from 'react';
import ViewVehiclePublishHistory from './ViewVehiclePublishHistory/ViewVehiclePublishHistory';
import { useState } from 'react';

const VehicleHistory = ({ value }) => {
    const [isPublishHistoryOpen, setIsPublishHistoryOpen] = useState(false);

    return (
        <td>
            {value && value.vehicleHistory ? (
                <div>
                    <div>
                        <div className="w-100 text-center">
                            <div className="border p-1 rounded w-100" id="latest-publish-date">
                                {new Date(value.vehicleHistory.actionDate)
                                    .toISOString()
                                    .slice(0, 19)
                                    .replace(/-/g, '/')
                                    .replace('T', ' ')
                                    .substring(0, 10)}
                            </div>

                            {value.vehicleHistory && (
                                <div className="clickable text-info mt-1" onClick={() => setIsPublishHistoryOpen(true)}>
                                    View History
                                </div>
                            )}
                        </div>
                    </div>

                    <ViewVehiclePublishHistory
                        isOpen={isPublishHistoryOpen}
                        toggleModal={() => setIsPublishHistoryOpen(false)}
                        vehicle={value.vehicle}
                    />
                </div>
            ) : value ? (
                <div>N/A</div>
            ) : (
                ''
            )}
        </td>
    );
};

export default VehicleHistory;
