import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState, useEffect } from 'react';
import { LightButton } from 'oemiq-common';
import ReactModal from '../ReactModal';

const defaultSettings = {
    title: 'Please confirm operation',
    body: '',
    btnClass: 'btn-primary',
    btnText: 'Confirm',
    center: false,
};

const Confirmation = forwardRef(({ title, body, btnClass, btnText, center }, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const [settings, setSettings] = useState(defaultSettings);
    const confirmCallback = useRef();
    const initSettings = useRef();

    const close = useCallback(() => {
        setIsOpen(false);
        confirmCallback.current = undefined;
    }, []);

    const open = useCallback((callback, newSettings) => {
        setIsOpen(true);
        newSettings && setSettings({ ...initSettings.current, ...newSettings });
        confirmCallback.current = callback;
    }, []);

    useEffect(() => {
        const calcSettings = {
            title: title || defaultSettings.title,
            body: body || defaultSettings.body,
            btnClass: btnClass || defaultSettings.btnClass,
            btnText: btnText || defaultSettings.btnText,
            center: center || defaultSettings.center,
        };
        setSettings(calcSettings);
        initSettings.current = calcSettings;
    }, [title, body, btnClass, btnText, center]);

    useImperativeHandle(ref, () => ({
        close,
        open,
    }));

    return (
        <ReactModal
            id="create-update-mapping-modal"
            centered={settings.center}
            container={undefined}
            isOpen={isOpen}
            toggle={close}
            headerComponent={<>{settings.title}</>}
            bodyComponent={<>{settings.body}</>}
            footerComponent={
                <div className="d-flex justify-content-end">
                    <LightButton id="confirmation-cancel" type="button" className="me-3" onClick={close}>
                        Cancel
                    </LightButton>
                    <button
                        id="confirmation-confirm"
                        type="button"
                        className={`btn btn-border-radius card-shadow py-2 px-4 ${settings.btnClass}`}
                        onClick={() => (confirmCallback.current(), close())}>
                        {settings.btnText}
                    </button>
                </div>
            }
        />
    );
});

export default Confirmation;
