import React, { useMemo, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Select, Input } from 'oemiq-common';
import { v4 as uuidv4 } from 'uuid';
import { INPUT_TYPE_ENUM } from 'enums/InputTypeEnum';

const FilterItem = ({
    id,
    selectedColumn,
    selectedOperator,
    selectedColumnInputType,
    term,
    addFilter,
    columns,
    removeFilter,
}) => {
    const selectedColumnObject = useMemo(() => {
        return columns.find(col => col.propertyName == selectedColumn);
    }, [selectedColumn, columns]);

    const selectedColumnOperators = useMemo(() => {
        let operators = selectedColumnObject?.filterOperators;
        return operators?.map(op => ({ text: op, value: op })) || null;
    }, [selectedColumnObject]);

    const getInputTypeByProperty = useCallback(
        value => columns.find(col => col.propertyName == value)?.filterInput?.type ?? INPUT_TYPE_ENUM.TEXT,
        [columns]
    );

    return (
        <div className="mb-2 d-flex align-items-center justify-content-between">
            <div>
                <button
                    id={`apply-update-filter-${id}`}
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={() =>
                        addFilter({
                            id: uuidv4(),
                            selectedColumn: -1,
                            selectedOperator: -1,
                            term: '',
                            selectedColumnInputType: INPUT_TYPE_ENUM.TEXT,
                        })
                    }>
                    <FontAwesomeIcon icon={faPlus} />
                </button>
            </div>
            <div>
                <button
                    id={`apply-update-filter-${id}`}
                    type="button"
                    className="btn btn-danger btn-sm"
                    onClick={() => removeFilter(id)}>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
            </div>
            <div>
                <Select
                    id={`column-${id}`}
                    formGroupClassName="mb-0"
                    className="form-select"
                    value={selectedColumn}
                    options={columns.map(col => {
                        return { text: col.displayName, value: col.propertyName };
                    })}
                    defaultOption={{ value: -1, text: 'Column' }}
                    onChange={e =>
                        addFilter({
                            id: id,
                            selectedColumn: e.currentTarget.value,
                            selectedOperator: selectedOperator,
                            selectedColumnInputType: getInputTypeByProperty(e.currentTarget.value),
                            term: term,
                        })
                    }
                />
            </div>
            <div>
                <Select
                    id={`operator-${id}`}
                    formGroupClassName="mb-0"
                    className="form-select"
                    value={selectedOperator}
                    options={
                        selectedColumnOperators ?? [
                            { text: 'include', value: 'include' },
                            { text: 'exclude', value: 'exclude' },
                        ]
                    }
                    defaultOption={{ value: -1, text: 'operator' }}
                    onChange={e =>
                        addFilter({
                            id: id,
                            selectedColumn: selectedColumn,
                            selectedOperator: e.currentTarget.value,
                            selectedColumnInputType: selectedColumnInputType,
                            term: term,
                        })
                    }
                />
            </div>
            <div>
                {selectedColumnInputType == INPUT_TYPE_ENUM.DROPDOWN ? (
                    <Select
                        id={id}
                        className="form-select"
                        value={term}
                        onChange={e =>
                            addFilter(
                                {
                                    id: id,
                                    selectedColumn: selectedColumn,
                                    selectedOperator: selectedOperator,
                                    selectedColumnInputType: selectedColumnInputType,
                                    term: e.currentTarget.value,
                                },
                                'term'
                            )
                        }
                        formGroupClassName="mb-0 align-self-center"
                        options={selectedColumnObject?.filterInput?.options ?? []}
                        defaultOption={selectedColumnObject?.filterInput?.defaultOption}
                    />
                ) : (
                    <Input
                        type="text"
                        id={`term-${id}`}
                        formGroupClassName="mb-0"
                        placeholder="Filter Term"
                        value={term}
                        onChange={e =>
                            addFilter(
                                {
                                    id: id,
                                    selectedColumn: selectedColumn,
                                    selectedOperator: selectedOperator,
                                    selectedColumnInputType: selectedColumnInputType,
                                    term: e.currentTarget.value,
                                },
                                'term'
                            )
                        }
                    />
                )}
            </div>
        </div>
    );
};

export default FilterItem;
