import React from 'react';
import { LargeCheckbox } from 'oemiq-common';
import useFilter from './useFilter';
import FilterItem from './FilterItem/FilterItem';

const Filter = ({
    filterColumnOptions,
    appliedFilters,
    filterByNoGroups,
    filterByNoType,
    filterRefreshed,
    handleUpdateApliedFilters,
    handleUpdateFilterByNoGroups,
    handleUpdateFilterByNoType,
    handleUpdateFilterRefreshed,
    isInitialUrlFiltersCheck,
}) => {
    const { displayFilters, addFilter, removeFilter, handleApplyFilter, validationError } = useFilter(
        appliedFilters,
        handleUpdateApliedFilters,
        isInitialUrlFiltersCheck
    );
    return (
        <div>
            {displayFilters.map(filter => {
                return (
                    <FilterItem
                        columns={filterColumnOptions}
                        addFilter={addFilter}
                        removeFilter={removeFilter}
                        id={filter.id}
                        key={filter.id}
                        selectedColumn={filter.selectedColumn}
                        selectedOperator={filter.selectedOperator}
                        selectedColumnInputType={filter.selectedColumnInputType}
                        term={filter.term}
                    />
                );
            })}
            {validationError && (
                <div className="text-danger pb-2">All filters must contain a column, operator, and term</div>
            )}

            <div className="d-flex align-items-center my-2">
                <div className="d-flex align-items-center me-2">
                    <div style={{ height: '21px' }}>
                        <LargeCheckbox
                            id="filter-no-groups"
                            label="Filter no group"
                            className="large-checkbox-check mb-0"
                            checked={filterByNoGroups}
                            onChange={() => handleUpdateFilterByNoGroups(state => !state)}
                            readOnly={false}
                        />
                    </div>
                </div>
                <div className="d-flex align-items-center me-2">
                    <div style={{ height: '21px' }}>
                        <LargeCheckbox
                            id="filter-no-type"
                            label="Filter no type"
                            className="large-checkbox-check mb-0"
                            checked={filterByNoType}
                            onChange={() => handleUpdateFilterByNoType(state => !state)}
                            readOnly={false}
                        />
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    <div style={{ height: '21px' }}>
                        <LargeCheckbox
                            id="filter-refreshed-pending"
                            label="Refreshed pending"
                            className="large-checkbox-check mb-0"
                            checked={filterRefreshed}
                            onChange={() => handleUpdateFilterRefreshed(state => !state)}
                            readOnly={false}
                        />
                    </div>
                </div>
            </div>

            <button
                id={'apply-filters-button'}
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => handleApplyFilter()}>
                Apply Filters
            </button>
        </div>
    );
};

export default Filter;
