import React, { useCallback, useMemo } from 'react';
import InfiniteScrollTableRow from 'components/InfiniteScrollTableRow';
import { OemMetadataView } from 'hooks/OemModels/MetaModel/BuildOemMetadata';
import { HistoryModalProcedure } from 'components/Modals/ViewProcedureMappingHistoryModal/ViewProcedureMappingHistoryModal';
import { ESProcedure } from 'components/locations/MappingProcess/Mapper/ESProcedure';
import {
    NewDataTable,
    NewDataTableFooter,
    NewDataTableHeader,
    NewDataTableHeaderCell,
    NewDataTableRow,
    NewDataTableRowCell,
} from 'components/Shared/NewDataTable';
import { CommonPropertiesId } from 'hooks/OemModels/MetaModel/BaseProcedureMetadata';
import {
    BasicViewValueComponent,
    GmtDateDisplayViewValueComponent,
    GroupDisplayViewValueComponent,
    GroupIdDisplayViewValueComponent,
    ProcedureDisplayViewValueComponent,
    ProcedureIdAndHistoryComponent,
    TypeIdViewValueComponent,
    TypeNameViewValueComponent,
    VehicleComponent,
} from 'hooks/OemModels/MetaModel/PropertyType.Components.NewDataTable';
import { OemProceduresHeaderCell } from './items/OemProceduresHeaderCell';
import { SelectAllHeaderCell } from './items/SelectAllHeaderCell';
import { SelectRowCell } from './items/SelectRowCell';
import { FilteredVehicle } from 'hooks/OemModels/MetaModel/types/FilteredVehicle';

type OemProceduresTableProps = BaseProps & (Editable | NoEditable);

type BaseProps = {
    data: ESProcedure[];
    hasMoreData: boolean;
    oemMetadataView: OemMetadataView;
    style?;
    columnSettings: {
        id: string;
        isHidden: boolean;
        text: string;
        width: string;
    }[];
    isLoading: boolean;
    filteredVehicles?: FilteredVehicle[];
    onSorting: (columnName: string, order: string) => void;
    loadMoreCallback: () => void;
    onClickModalProcedure;
    onclickHistoryModalProcedure: (proc: HistoryModalProcedure | null) => void;
    expandedProcedures?: number[];
    onExpandProcedureClick?: (procedureId: number) => void;
};

type Editable = {
    isEditable: true;
    selected;
    isAll: boolean;
    onSelectAll: (data) => void;
    onSelectOne: (procedureId) => void;
    refreshProcedures;
    setNewGroupListToProcedureByProcedureId;
};

type NoEditable = {
    isEditable: false;
    onSelectAll?: null;
    onSelectOne?: null;
};

const getRowClassNameForProcedure = (row: ESProcedure) => {
    const isRemovalPending = row?.stageArea.isDeleted && !row.isDeleted;
    const isRemoved = row?.stageArea.isDeleted && row.isDeleted;

    return isRemovalPending ? 'bg-red-200' : isRemoved ? 'bg-gray-500' : row?.isStale ? 'bg-yellow-500' : '';
};

const OemProceduresTable = (props: OemProceduresTableProps) => {
    const {
        data,
        hasMoreData,
        oemMetadataView,
        style,
        columnSettings,
        isLoading,
        filteredVehicles,
        onSorting,
        loadMoreCallback,
        onClickModalProcedure,
        onclickHistoryModalProcedure,
        isEditable,
        onSelectOne,
        onSelectAll,
        expandedProcedures,
        onExpandProcedureClick,
    } = props;

    const visibleColumns = useMemo(
        () =>
            columnSettings
                .filter(c => !c.isHidden)
                .map(cs => oemMetadataView.metadata.properties[cs.id])
                .filter(c => c),
        [columnSettings, oemMetadataView.metadata.properties]
    );
    const orderedColumns = useMemo(
        () => (isEditable ? ['bulk_select', ...visibleColumns.map(c => c.id)] : visibleColumns.map(c => c.id)),
        [isEditable, visibleColumns]
    );
    const newColumnSettings = useMemo(() => {
        const ncs = visibleColumns
            .map(c => {
                return { id: c.id, displayName: c.displayName };
            })
            .reduce((acc, c) => {
                acc[c.id] = c;
                return acc;
            }, {});

        if (isEditable) {
            ncs['bulk_select'] = { id: 'bulk_select' };
        }

        return ncs;
    }, [isEditable, visibleColumns]);

    const getCellValueFromRow = useCallback(
        (row: ESProcedure, id: string) =>
            oemMetadataView.metadata.properties[id].name.split('.').reduce((o, i) => (o ? o[i] : null), row),
        [oemMetadataView.metadata.properties]
    );

    const handleSelectAll = useCallback(() => isEditable && onSelectAll(data), [data, isEditable, onSelectAll]);
    const getDisplayName = useCallback(
        (id: string): string => oemMetadataView.metadata.properties[id].displayName,
        [oemMetadataView.metadata.properties]
    );
    const getIsSortable = useCallback(
        (id: string): boolean => oemMetadataView.metadata.properties[id].sortable,
        [oemMetadataView.metadata.properties]
    );

    const handleSelectOne = useCallback(
        (row: ESProcedure) => isEditable && onSelectOne(row.procedureId),
        [isEditable, onSelectOne]
    );
    const getIsMultiple = useCallback(
        (id: string): boolean => oemMetadataView.metadata.properties[id].multiple,
        [oemMetadataView.metadata.properties]
    );
    const getKey = useCallback((i: ESProcedure): number => i.procedureId, []);
    const getHeaderCellClassName = useCallback(
        (columnId: string): string => `text-nowrap ${getIsSortable(columnId) ? 'clickable' : ''}`,
        [getIsSortable]
    );
    return (
        <div id="procedures-table" className="overflow-auto flex-grow-1" style={style}>
            <NewDataTable
                tableClassName={'table mt-3'}
                data={data}
                getKey={getKey}
                orderedColumns={orderedColumns}
                columnSettings={newColumnSettings}>
                <NewDataTableHeader headerClassName={'sticky-top bg-white'}>
                    {props.isEditable && (
                        <NewDataTableHeaderCell id="bulk_select" headerCellClassName="text-nowrap">
                            <SelectAllHeaderCell isAll={props.isAll} onSelectAll={handleSelectAll} />
                        </NewDataTableHeaderCell>
                    )}
                    <NewDataTableHeaderCell default headerCellClassName={getHeaderCellClassName}>
                        <OemProceduresHeaderCell
                            onSorting={onSorting}
                            getDisplayName={getDisplayName}
                            isSortable={getIsSortable}
                        />
                    </NewDataTableHeaderCell>
                </NewDataTableHeader>
                <NewDataTableRow rowClassName={getRowClassNameForProcedure}>
                    {props.isEditable && (
                        <NewDataTableRowCell id="bulk_select">
                            <SelectRowCell
                                selected={props.selected}
                                isAll={props.isAll}
                                onSelectOne={handleSelectOne}
                            />
                        </NewDataTableRowCell>
                    )}
                    <NewDataTableRowCell id={CommonPropertiesId.ProcedureId}>
                        <ProcedureIdAndHistoryComponent onHistoryClick={onclickHistoryModalProcedure} />
                    </NewDataTableRowCell>
                    <NewDataTableRowCell id={CommonPropertiesId.ProcedureTitle}>
                        <ProcedureDisplayViewValueComponent onClick={onClickModalProcedure} />
                    </NewDataTableRowCell>
                    <NewDataTableRowCell id={CommonPropertiesId.StageAreaGroups}>
                        <GroupIdDisplayViewValueComponent
                            enabled={props.isEditable}
                            setNewGroupListToProcedureByProcedureId={
                                props.isEditable && props.setNewGroupListToProcedureByProcedureId
                            }
                        />
                    </NewDataTableRowCell>
                    <NewDataTableRowCell id={CommonPropertiesId.GroupsView}>
                        <GroupDisplayViewValueComponent
                            enabled={props.isEditable}
                            setNewGroupListToProcedureByProcedureId={
                                props.isEditable && props.setNewGroupListToProcedureByProcedureId
                            }
                        />
                    </NewDataTableRowCell>
                    <NewDataTableRowCell id={CommonPropertiesId.TypeId}>
                        <TypeIdViewValueComponent
                            enabled={props.isEditable}
                            setNewGroupListToProcedureByProcedureId={
                                props.isEditable && props.setNewGroupListToProcedureByProcedureId
                            }
                        />
                    </NewDataTableRowCell>
                    <NewDataTableRowCell id={CommonPropertiesId.TypeName}>
                        <TypeNameViewValueComponent
                            enabled={props.isEditable}
                            setNewGroupListToProcedureByProcedureId={
                                props.isEditable && props.setNewGroupListToProcedureByProcedureId
                            }
                        />
                    </NewDataTableRowCell>
                    <NewDataTableRowCell
                        id={[
                            CommonPropertiesId.SqlUpdateDate,
                            CommonPropertiesId.UpdateDate,
                            CommonPropertiesId.Timestamp,
                        ]}>
                        <GmtDateDisplayViewValueComponent getValue={getCellValueFromRow} />
                    </NewDataTableRowCell>
                    <NewDataTableRowCell id={CommonPropertiesId.Vehicles}>
                        <VehicleComponent
                            filteredVehicles={filteredVehicles}
                            expandedProcedures={expandedProcedures}
                            onExpandProcedureClick={onExpandProcedureClick}
                        />
                    </NewDataTableRowCell>
                    <NewDataTableRowCell default>
                        <BasicViewValueComponent getValue={getCellValueFromRow} isMultipleProvider={getIsMultiple} />
                    </NewDataTableRowCell>
                </NewDataTableRow>
                <NewDataTableFooter>
                    {hasMoreData && (
                        <InfiniteScrollTableRow
                            columnsCount={columnSettings.length}
                            isLoading={isLoading}
                            loadMoreCallback={loadMoreCallback}
                        />
                    )}
                </NewDataTableFooter>
            </NewDataTable>
        </div>
    );
};

export default OemProceduresTable;
