import { useState, useEffect, useRef, useCallback } from 'react';

export default function useClickOutside(defaultVisibility: boolean) {
    const [isComponentVisible, setIsComponentVisible] = useState(defaultVisibility);
    const compRef = useRef(null);

    const handleClickOutside = useCallback(
        (event: MouseEvent) => {
            if (compRef.current && !compRef.current.contains(event.target)) setIsComponentVisible(false);
        },
        [setIsComponentVisible]
    );

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [handleClickOutside]);

    return { compRef, isComponentVisible, setIsComponentVisible, handleClickOutside };
}
