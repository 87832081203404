import React, { createRef, useMemo } from 'react';
import ReactSelect from 'react-select';
import Creatable from 'react-select/creatable';
import mapToOptions from 'helpers/MapToOptions';
import { TaggingWorkFlowStatusOptions } from 'helpers/FlagTagHelper';

const ButtonLabel = ({ label, onClick, isActive, ...props }) => {
    return (
        <div style={{ width: '15rem' }}>
            {isActive ? (
                <button className="w-100 btn btn-success" onClick={onClick} color="green" tabIndex={-1} {...props}>
                    {label}
                </button>
            ) : (
                <button className="w-100 btn" onClick={onClick} tabIndex={-1} {...props}>
                    {label}
                </button>
            )}
        </div>
    );
};

const LabeledInput = ({ value, edited, label, tag, clearState, ...props }) => {
    const inputRef = createRef();
    const handleClick = () => {
        if (!edited) {
            inputRef.current.focus();
        } else {
            clearState();
        }
    };

    return (
        <div className="d-flex flex-row mb-2">
            <ButtonLabel label={label} onClick={handleClick} isActive={edited} />
            <div style={{ flexGrow: 1 }} className="ms-1">
                {!tag && <input className="form-control" value={value} ref={inputRef} {...props} />}
                {!!tag && (
                    <input
                        className="form-control"
                        ref={inputRef}
                        label={{ tag: true, content: `${tag}` }}
                        value={value}
                        {...props}
                    />
                )}
            </div>
        </div>
    );
};

const LabeledTextArea = ({ label, value, edited, clearState, ...props }) => {
    const inputRef = createRef();
    const handleClick = () => {
        if (!edited) {
            inputRef.current.focus();
        } else {
            clearState();
        }
    };

    return (
        <div className="d-flex flex-row mb-2">
            <ButtonLabel label={label} onClick={handleClick} isActive={edited} />
            <div style={{ flexGrow: 1 }} className="ms-1">
                <textarea ref={inputRef} className="form-control" value={value} {...props} />
            </div>
        </div>
    );
};

const LabeledDropdown = ({ value, options, label, edited, clearState, ...props }) => {
    const formattedOptions = useMemo(() => options.map(mapToOptions), [options]);
    const handleClick = () => {
        if (edited) {
            clearState();
        }
    };
    return (
        <div className="d-flex flex-row mb-2">
            <ButtonLabel label={label} onClick={handleClick} isActive={edited} />
            <div style={{ flexGrow: 1 }} className="ms-1">
                {!props.allowAdditions ? (
                    <ReactSelect
                        value={formattedOptions.find(o => o.value === value)}
                        options={formattedOptions}
                        {...props}
                    />
                ) : (
                    <Creatable
                        value={formattedOptions.find(o => o.value === value)}
                        options={formattedOptions}
                        {...props}
                    />
                )}
            </div>
        </div>
    );
};

const Body = ({
    title,
    setTitle,
    quantity,
    setQuantity,
    partNumber,
    setPartNumber,
    price,
    setPrice,
    text,
    setText,
    quantityCondition,
    quantityConditions,
    setQuantityCondition,
    partType,
    partTypes,
    setPartType,
    status,
    setStatus,
    flagModeOn,
    anythingToEdit,
}) => {
    return (
        <>
            {anythingToEdit && (
                <div style={{ width: '100%' }}>
                    <LabeledInput
                        label="Title"
                        placeholder="Enter tag title"
                        type="text"
                        value={title.value}
                        edited={title.edited}
                        onChange={e => setTitle({ value: e.target.value, edited: true })}
                        clearState={() => setTitle({ value: '', edited: false })}
                    />
                    {!flagModeOn && (
                        <LabeledDropdown
                            value={status.value}
                            options={TaggingWorkFlowStatusOptions}
                            edited={status.edited}
                            clearState={() => setStatus({ value: '', edited: false })}
                            label="Status"
                            placeholder="Select status"
                            fluid
                            selection
                            onChange={({ value }) => setStatus({ value, edited: true })}
                        />
                    )}
                    <LabeledInput
                        label="Quantity"
                        placeholder="Enter quantity"
                        type="number"
                        min="0"
                        value={quantity.value}
                        edited={quantity.edited}
                        onChange={e => setQuantity({ value: e.target.value, edited: true })}
                        clearState={() => setQuantity({ value: '', edited: false })}
                    />
                    <LabeledDropdown
                        value={quantityCondition.value}
                        edited={quantityCondition.edited}
                        clearState={() => setQuantityCondition({ value: '', edited: false })}
                        options={quantityConditions}
                        onChange={({ value }) => setQuantityCondition({ value, edited: true })}
                        label="Quantity Condition"
                        placeholder="Select quantity condition"
                        fluid
                        search
                        selection
                        additionLabel="Custom condition: "
                    />
                    <LabeledInput
                        label="Part Number"
                        placeholder="Enter part number"
                        type="text"
                        value={partNumber.value}
                        edited={partNumber.edited}
                        onChange={e => setPartNumber({ value: e.target.value, edited: true })}
                        clearState={() => setPartNumber({ value: '', edited: false })}
                    />
                    <LabeledInput
                        label="Part Price"
                        placeholder="Enter part price"
                        tag="$"
                        type="number"
                        min="0.0"
                        step="0.25"
                        value={price.value}
                        edited={price.edited}
                        onChange={e => setPrice({ value: e.target.value, edited: true })}
                        clearState={() => setPrice({ value: '', edited: false })}
                    />
                    <LabeledDropdown
                        value={partType.value}
                        options={partTypes}
                        edited={partType.edited}
                        clearState={() => setPartType({ value: '', edited: false })}
                        label="Part Type"
                        placeholder="Select part type"
                        fluid
                        selection
                        onChange={({ value }) => setPartType({ value, edited: true })}
                    />
                    <LabeledTextArea
                        label="Text"
                        placeholder="Enter tag text"
                        value={text.value}
                        edited={text.edited}
                        onChange={e => setText({ value: e.target.value, edited: true })}
                        clearState={() => setText({ value: '', edited: false })}
                        rows="4"
                        type="text"
                    />
                </div>
            )}
        </>
    );
};

export default Body;
