import React from 'react';
import { Text } from './Cells';
import { CellValue, TableCellProps } from './types';

const TableCell = ({ idKey, header, rowData, index, cellEventCallback }: TableCellProps) => {
    const TextComponent = Text();
    const value = header.id ? header.id.split('.').reduce((o, i) => (o ? o[i] : null), rowData) : null;
    return header.component ? (
        <header.component
            idKey={idKey}
            value={value as CellValue}
            header={header}
            item={rowData}
            index={index}
            cellEventCallback={cellEventCallback}
        />
    ) : (
        <TextComponent value={value as CellValue} header={header} index={index} />
    );
};

export default React.memo(TableCell);
