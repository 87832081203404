import { fetchWithAuthHeader } from 'api/AuthUtils';
import { LoadingContext } from 'components/Layout';
import { NotificationsContext } from 'components/Shared/Notifications/Notifications';
import { useState, useEffect, useContext, useCallback } from 'react';

interface Oem {
    oemId: number;
    oemName: string;
}

interface Model {
    modelId: number;
    modelName: string;
}

interface Trim {
    trimId: number;
    trimName: string;
}

interface VinPattern {
    vinPatternId: number;
    displayName: string;
}

export interface VehicleInfo {
    oems: Oem[];
    models: Model[];
    trims: Trim[];
    vinPatterns: VinPattern[];
}

export const useFetchVehicleInfo = (): VehicleInfo => {
    const [oems, setOems] = useState<Oem[]>([]);
    const [models, setModels] = useState<Model[]>([]);
    const [trims, setTrims] = useState<Trim[]>([]);
    const [vinPatterns, setVinPatterns] = useState<VinPattern[]>([]);

    const { notifications } = useContext(NotificationsContext);
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);

    const fetchOems = useCallback(async () => {
        try {
            const response = await fetchWithAuthHeader(
                'api/VehicleDetails/odata/Oem?api-version=3&$select=oemId,oemName&$count=true'
            );
            const data = await response.json();
            setOems(data.value || []);
        } catch (err) {
            notifications.pushExceptionDanger('Failed to fetch OEMs');
        }
    }, [notifications]);

    const fetchModels = useCallback(async () => {
        try {
            const response = await fetchWithAuthHeader(
                'api/VehicleDetails/odata/Model?api-version=3&$select=modelId,modelName&$count=false'
            );
            const data = await response.json();
            setModels(data.value || []);
        } catch (err) {
            notifications.pushExceptionDanger('Failed to fetch Models');
        }
    }, [notifications]);

    const fetchTrims = useCallback(async () => {
        try {
            const response = await fetchWithAuthHeader(
                'api/VehicleDetails/odata/Trim?api-version=3&$select=trimId,trimName&$count=false'
            );
            const data = await response.json();
            setTrims(data.value || []);
        } catch (err) {
            notifications.pushExceptionDanger('Failed to fetch Trims');
        }
    }, [notifications]);

    const fetchVinPatterns = useCallback(async () => {
        try {
            const response = await fetchWithAuthHeader(
                'api/VehicleDetails/odata/VinPattern?api-version=3&$select=vinPatternId,displayName&$count=false'
            );
            const data = await response.json();
            setVinPatterns(data.value || []);
        } catch (err) {
            notifications.pushExceptionDanger('Failed to fetch VIN Patterns');
        }
    }, [notifications]);

    useEffect(() => {
        const fetchData = async () => {
            await Promise.all([fetchOems(), fetchModels(), fetchTrims(), fetchVinPatterns()]);
        };

        fetchData();
    }, [decrementLoading, fetchModels, fetchOems, fetchTrims, fetchVinPatterns, incrementLoading]);

    return { oems, models, trims, vinPatterns };
};
