import { useState, useEffect } from 'react';
import { requestNetworks, requestNetworkTypes, requestNetworkLevels } from 'api/NetworkApi';

const useNetwork = networkLevelIdFilter => {
    const [networks, setNetworks] = useState([]);
    const [networkTypes, setNetworkTypes] = useState([]);
    const [networkLevels, setNetworkLevels] = useState([]);

    useEffect(() => {
        (async () => {
            const networks = await requestNetworks(networkLevelIdFilter);
            setNetworks(networks);
        })();
    }, [networkLevelIdFilter]);

    useEffect(() => {
        (async () => {
            const networkTypes = await requestNetworkTypes();
            const networkLevels = await requestNetworkLevels();
            setNetworkTypes(networkTypes);
            setNetworkLevels(networkLevels);
        })();
    }, []);

    return { networks, networkTypes, networkLevels, setNetworks };
};

export default useNetwork;
