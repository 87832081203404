import SmallSpinner from 'components/SmallSpinner';
import { FormatDateToGMT } from 'helpers/DateHelper';

const BulkTaskHistoryModalBody = ({ isLoading, items }) => {
    if (isLoading) {
        return <SmallSpinner id="bulk-action-history-modal-loading-spinner" />;
    }
    if (!items.length) {
        return <div>No history</div>;
    }
    return (
        <table className="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Type</th>
                    <th scope="col">User</th>
                    <th scope="col"># of procedures</th>
                    <th scope="col">Status</th>
                    <th scope="col">Create Date</th>
                </tr>
            </thead>
            <tbody>
                {items.map(item => (
                    <tr key={item.procedureMappingTaskId}>
                        <td scope="row">{item.taskType.friendlyName}</td>
                        <td>{item.userId}</td>
                        <td>{item['procedureMappingTaskProcedures@odata.count']}</td>
                        <td>{item.taskStatus.friendlyName}</td>
                        <td>{FormatDateToGMT(item.createDate)}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default BulkTaskHistoryModalBody;
