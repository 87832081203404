import { useContext } from 'react';
import { AccessControlContext } from 'components/Shared/AccessControl/AccessControl';
import Unauthorized from './Unauthorized';

const AuthorizedElement = ({ resource, children }) => {
    const { hasAccess, userInfo } = useContext(AccessControlContext);

    return hasAccess(resource) ? children : userInfo.roles && <Unauthorized />;
};

export default AuthorizedElement;
