import React from 'react';

const OneTimeUseTagHighlight = ({ highlightStyles, tag }) => {
    if (!highlightStyles) return null;
    const tagId = tag.stagedOneTimeUseTagId;
    let style = highlightStyles.filter(s => s.id === tagId);
    if (style.length === 0) return null;
    if (tag.isActive) return <div id={tagId} style={style[0].style}></div>;
    else return null;
};

export default OneTimeUseTagHighlight;
