import React from 'react';

const IconButton = ({ children, id, onClick, btnColorClassName }) => {
    return (
        <button
            id={id}
            type="button"
            className={`btn ${btnColorClassName}`}
            onClick={onClick}
            style={{ padding: '0.25rem', fontSize: '0.6rem' }}>
            {children}
        </button>
    );
};

export default IconButton;
