import { requestGetBillingTypes } from 'api/SecurityApi';
import { ToastContext } from 'components/ToastProvider';
import { useContext, useEffect, useState } from 'react';

const useBillingTypes = () => {
    const [billingTypes, setBillingTypes] = useState([]);
    const [billingTypeOptions, setBillingTypeOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const { showToast } = useContext(ToastContext);

    useEffect(() => {
        let isUnmounted = false;

        (async () => {
            try {
                setLoading(true);
                const values = await requestGetBillingTypes();

                const options = values
                    .map(({ billingTypeId, billingTypeName }) => ({
                        key: billingTypeId,
                        value: billingTypeId,
                        text: billingTypeName,
                    }))
                    .map(option =>
                        option.text.toLowerCase() === 'other'
                            ? { ...option, key: Math.max(...values.map(v => v.billingTypeId)) + 1 }
                            : option
                    )
                    .sort((a, b) => a.key - b.key);
                if (!isUnmounted) {
                    setBillingTypes(values);
                    setBillingTypeOptions(options);
                }
            } catch (error) {
                showToast(error);
            } finally {
                if (!isUnmounted) {
                    setLoading(false);
                }
            }
        })();

        return () => (isUnmounted = true);
    }, [showToast]);

    return { loading, billingTypes, billingTypeOptions };
};

export default useBillingTypes;
