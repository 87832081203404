import type { DataItem } from 'components/Shared/Table/types';
import type { OemRefreshSchedule } from '../types';

export interface ImportJobBookSummary {
    new: number;
    updated: number;
    success: number;
    error: number;
    inProgress: number;
}

export interface ImportJobProcedureSummary {
    new: number;
    updated: number;
}

export interface ImportJobSummary {
    importJobId: number;
    success: boolean | null;
    numberOfBooks: number | null;
    createdAt: string;
    updatedAt: string | null;
    procedureSummary?: ImportJobProcedureSummary | null;
    bookSummary?: ImportJobBookSummary | null;
}

export interface RefreshDashboardRowDataType extends DataItem {
    readonly oemId: number;
    readonly oemName: string;
    readonly logo: string;
    isLoading: boolean;
    lastImportJobSummary: ImportJobSummary | null;
    oemRefreshSchedules: OemRefreshSchedule[] | null;
}

export enum RefreshDashboardActionTypes {
    EditNextScheduledRun = '1',
    ReloadOemStatistics = '2',
    NavigateToDetailsPage = '3',
    NavigateToHistoryPage = '4',
    RunRefresh = '5',
}
