import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const useTagSorting = tags => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [sortTagColumn, setSortTagColumn] = useState(
        searchParams.get('tsort') ? JSON.parse(searchParams.get('tsort')) : null
    );

    const sortedTags = useMemo(() => {
        const sortedTags = [...tags];
        if (sortTagColumn !== null) {
            if (sortTagColumn.key === 'oneTimeUsePartType') {
                sortedTags.sort((a, b) => {
                    if (a.oneTimeUsePartType === b.oneTimeUsePartType) return 0;
                    else if (a.oneTimeUsePartType === null) return 1;
                    else if (b.oneTimeUsePartType === null) return -1;
                    else if (sortTagColumn.direction === 'A')
                        return a.oneTimeUsePartType['oneTimeUsePartTypeName'] <
                            b.oneTimeUsePartType['oneTimeUsePartTypeName']
                            ? -1
                            : 1;
                    else
                        return a.oneTimeUsePartType['oneTimeUsePartTypeName'] <
                            b.oneTimeUsePartType['oneTimeUsePartTypeName']
                            ? 1
                            : -1;
                });
            } else if (sortTagColumn.key === 'procedureTitle') {
                if (sortTagColumn.direction === 'A')
                    sortedTags.sort((a, b) => (a.procedure['procedureTitle'] < b.procedure['procedureTitle'] ? -1 : 1));
                else
                    sortedTags.sort((a, b) => (a.procedure['procedureTitle'] < b.procedure['procedureTitle'] ? 1 : -1));
            } else if (sortTagColumn.key === 'procedureId') {
                if (sortTagColumn.direction === 'A')
                    sortedTags.sort((a, b) => (a.procedure['procedureId'] < b.procedure['procedureId'] ? -1 : 1));
                else sortedTags.sort((a, b) => (a.procedure['procedureId'] < b.procedure['procedureId'] ? 1 : -1));
            } else {
                sortedTags.sort((a, b) => {
                    if (a[sortTagColumn.key] === b[sortTagColumn.key]) return 0;
                    else if (a[sortTagColumn.key] === null) return 1;
                    else if (b[sortTagColumn.key] === null) return -1;
                    else if (sortTagColumn.direction === 'A')
                        return a[sortTagColumn.key] < b[sortTagColumn.key] ? -1 : 1;
                    else return a[sortTagColumn.key] < b[sortTagColumn.key] ? 1 : -1;
                });
            }
        }

        return sortedTags;
    }, [tags, sortTagColumn]);

    const handleTagSortColumnClick = useCallback(
        key => {
            let sorting = null;
            if (sortTagColumn?.key !== key) sorting = { key: key, direction: 'A' };
            else if (sortTagColumn.key === key && sortTagColumn.direction === 'A')
                sorting = { key: key, direction: 'D' };
            setSortTagColumn(sorting);
            setSearchParams(searchParams => {
                searchParams.set('tsort', JSON.stringify(sorting));
                return searchParams;
            });
        },
        [setSearchParams, sortTagColumn]
    );

    return { sortedTags, sortTagColumn, handleTagSortColumnClick };
};

export default useTagSorting;
