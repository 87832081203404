import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { requestGetTagById, requestGetTagLinks } from 'api/RepairProcedureApi';
import LinkTagsControls from './LinkTagsControls';
import TagDetails from './TagDetails';
import LinksTagsCheckboxItem from 'components/Shared/Components/LinkTagsCheckboxItem';
import LinkTagMatches from './LinkTagMatches';
import ViewProcedureModal from 'components/Modals/ViewProcedureModal/ViewProcedureModal';
import { TaggerContext } from 'contexts/TaggerContextProvider';
import { ToastContext } from 'components/ToastProvider';
import { LoadingContext } from 'components/Layout';

const TaggerLink = () => {
    const [tag, setTag] = useState(null);
    const [linkedTags, setLinkedTags] = useState([]);
    const [modalProcedureId, setModalProcedureId] = useState(null);
    const { bookId, tagId } = useParams();
    const { displayTagIds } = useContext(TaggerContext);
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const { showToast } = useContext(ToastContext);

    useEffect(() => {
        const getTagDetails = async () => {
            try {
                incrementLoading();

                if (tagId) {
                    setTag(await requestGetTagById(tagId));
                }
            } catch (error) {
                showToast(error);
            } finally {
                decrementLoading();
            }
        };
        getTagDetails();
    }, [tagId, showToast, incrementLoading, decrementLoading]);

    useEffect(() => {
        const getLinkedTags = async () => {
            try {
                incrementLoading();

                if (tag && tag.oneTimeUseTagLink !== null) {
                    let linkedTags = await requestGetTagLinks(tag.oneTimeUseTagLink);
                    linkedTags = linkedTags.filter(t => t.stagedOneTimeUseTagId !== tag.stagedOneTimeUseTagId);
                    setLinkedTags(linkedTags);
                } else {
                    setLinkedTags([]);
                }
            } catch (error) {
                showToast(error);
            } finally {
                decrementLoading();
            }
        };
        getLinkedTags();
    }, [tag, showToast, incrementLoading, decrementLoading]);

    const viewModalOnClick = (e, procedureId) => {
        e.stopPropagation();
        setModalProcedureId(procedureId);
    };

    return (
        <>
            <div className="container-fluid mt-3">
                <LinkTagsControls tag={tag} tagIds={displayTagIds} />
                <div className="row mt-3">
                    <div className="col border rounded p-4 mx-2">
                        <div>
                            {tag && <TagDetails tag={tag} viewModalOnClick={viewModalOnClick} />}
                            <div className="mt-3">
                                <h6 className="font-weight-bold text-primary border p-2 m-0">
                                    Linked Tags ({linkedTags ? linkedTags.length : 0}):
                                </h6>
                                {linkedTags.map((m, index) => {
                                    return (
                                        <LinksTagsCheckboxItem
                                            key={index}
                                            text="Tag Name"
                                            value={m.title}
                                            tag={m}
                                            modelId={'viewprocedure-modal'}
                                            viewModalOnClick={e => viewModalOnClick(e, m.procedureId)}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="col border rounded p-4 mx-2">
                        <LinkTagMatches
                            tag={tag}
                            linkedTags={linkedTags}
                            viewModalOnClick={viewModalOnClick}
                            bookId={bookId}
                        />
                    </div>
                </div>
            </div>
            <ViewProcedureModal procedureId={modalProcedureId} resetProcedureId={() => setModalProcedureId(null)} />
        </>
    );
};

export default TaggerLink;
