import {
    requestGMBulkSetShouldVehicleBeRun,
    requestGMSetShouldVehicleBeRun,
    requestGMCImportPublicationOdata,
    requestGMCImportPublicationsWithCountOdata,
} from 'api/RepairProcedureApi';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { GMVehicle, IngestionButton, VehicleCountByStatus } from '../types';
import { ToastContext } from 'components/ToastProvider';
import { LoadingContext } from 'components/Layout';

const useGMVehicles = notifications => {
    const [vehicles, setVehicles] = useState<GMVehicle[]>([]);
    const [vehiclesCountByStatus, setVehiclesCountByStatus] = useState<VehicleCountByStatus>();
    const [filters, setFilters] = useState<string>('');
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const [loading, setLoading] = useState<boolean>(false);
    const { showToast } = useContext<{ showToast }>(ToastContext);
    const page = useRef(0);
    const pageSize = 100;
    const [hasMore, setHasMore] = useState(true);

    const loadVehicles = useCallback(
        async (reset = false) => {
            try {
                setLoading(true);
                incrementLoading();
                if (reset) {
                    page.current = 0;
                    setVehicles([]);
                    setHasMore(true);
                    const response = await getVehiclesWithCount(page.current, pageSize, filters);
                    console.log(response);
                    const vehicles = response.data.vehicles;
                    page.current++;
                    setVehiclesCountByStatus({
                        new: response.data.newVehiclesCount,
                        off: response.data.offVehiclesCount,
                        on: response.data.onVehiclesCount,
                    });
                    if (vehicles.length > 0) {
                        setVehicles(vehicles);
                    } else {
                        setHasMore(false);
                    }
                    if (response.error) {
                        showToast(response.error);
                    }
                } else {
                    const response = await getVehicles(page.current, pageSize, filters);
                    page.current++;

                    if (response.data.length > 0) {
                        setVehicles(v => (reset ? response.data : [...v, ...response.data]));
                    } else {
                        setHasMore(false); // No more data to load
                    }
                    if (response.error) {
                        showToast(response.error);
                    }
                }
            } catch (e) {
                notifications.pushExceptionDanger(e);
            } finally {
                setLoading(false);
                decrementLoading();
            }
        },
        [filters, incrementLoading, notifications, decrementLoading, showToast]
    );

    const loadVehicleCount = useCallback(async () => {
        const response = await getVehiclesWithCount(0, 0, filters);
        setVehiclesCountByStatus({
            new: response.data.newVehiclesCount,
            off: response.data.offVehiclesCount,
            on: response.data.onVehiclesCount,
        });
    }, [filters]);

    useEffect(() => {
        loadVehicles(true);
    }, [filters, loadVehicles]);

    const turnGMVehiclesOn = useCallback(
        async (gmVehicleId: number) => {
            try {
                await requestGMSetShouldVehicleBeRun(gmVehicleId, true, true);
                setVehicles(vehicles =>
                    vehicles.map(v =>
                        v.importPublicationId === gmVehicleId
                            ? {
                                  ...v,
                                  shouldBeRun: true,
                                  hasBeenReviewed: true,
                              }
                            : v
                    )
                );
                loadVehicleCount();
            } catch (e) {
                notifications.pushExceptionDanger(e);
            }
        },
        [loadVehicleCount, notifications]
    );

    const turnGMVehiclesOff = useCallback(
        async (gmVehicleId: number) => {
            try {
                await requestGMSetShouldVehicleBeRun(gmVehicleId, false, true);
                setVehicles(vehicles =>
                    vehicles.map(v =>
                        v.importPublicationId === gmVehicleId
                            ? {
                                  ...v,
                                  shouldBeRun: false,
                                  hasBeenReviewed: true,
                              }
                            : v
                    )
                );
                loadVehicleCount();
            } catch (e) {
                notifications.pushExceptionDanger(e);
            }
        },
        [loadVehicleCount, notifications]
    );

    const bulkTurnGMVehiclesOn = useCallback(
        async (ids: number[]) => {
            try {
                await requestGMBulkSetShouldVehicleBeRun(ids, true, true);
                setVehicles(vehicles =>
                    vehicles.map(v =>
                        ids.includes(v.importPublicationId)
                            ? {
                                  ...v,
                                  shouldBeRun: true,
                                  hasBeenReviewed: true,
                              }
                            : v
                    )
                );
                loadVehicleCount();
            } catch (e) {
                notifications.pushExceptionDanger(e);
            }
        },
        [loadVehicleCount, notifications]
    );

    const bulkTurnGmVehiclesOff = useCallback(
        async (ids: number[]) => {
            try {
                await requestGMBulkSetShouldVehicleBeRun(ids, false, true);
                setVehicles(vehicles =>
                    vehicles.map(v =>
                        ids.includes(v.importPublicationId)
                            ? {
                                  ...v,
                                  shouldBeRun: false,
                                  hasBeenReviewed: true,
                              }
                            : v
                    )
                );
                loadVehicleCount();
            } catch (e) {
                notifications.pushExceptionDanger(e);
            }
        },
        [loadVehicleCount, notifications]
    );

    const onSearch = (filter: string, radioValue: IngestionButton) => {
        const stringColumns = ['pubName', 'vehicleList', 'notes'];
        const intColumns = ['pubsyskey'];

        const stringFilters = stringColumns.map(column => `contains(${column},'${filter}')`);

        const filterNumber = Number(filter);
        const intFilters = !isNaN(filterNumber) ? intColumns.map(column => `${column} eq ${filterNumber}`) : [];
        const parsedFilters = [...stringFilters, ...intFilters].join(' or ');

        const processFilter =
            radioValue === IngestionButton.NewButton
                ? 'and (hasBeenReviewed eq false)'
                : radioValue === IngestionButton.OnButton
                ? 'and (hasBeenReviewed eq true and shouldBeRun eq true)'
                : radioValue === IngestionButton.OffButton
                ? 'and (hasBeenReviewed eq true and shouldBeRun eq false)'
                : '';

        setFilters(`(${parsedFilters}) ${processFilter}`);
    };

    const loadMoreCallback = useCallback(() => {
        if (hasMore && !loading) {
            loadVehicles();
        }
    }, [hasMore, loading, loadVehicles]);

    return {
        vehicles,
        isLoading: loading,
        loadMoreCallback,
        onSearch,
        vehiclesCountByStatus,
        turnGMVehiclesOn,
        turnGMVehiclesOff,
        bulkTurnGMVehiclesOn,
        bulkTurnGmVehiclesOff,
    };
};

const getVehicles = async (page: number, pageSize: number, filter: string) => {
    try {
        const vehicles = await requestGMCImportPublicationOdata({
            top: pageSize,
            skip: pageSize * page,
            filter: filter ? filter : undefined,
        });
        return { data: vehicles.value, error: '' };
    } catch (e) {
        return { data: [], error: e };
    }
};

const getVehiclesWithCount = async (page: number, pageSize: number, filter: string) => {
    try {
        const response = await requestGMCImportPublicationsWithCountOdata({
            top: pageSize,
            skip: pageSize * page,
            filter: filter ? filter : undefined,
        });
        return { data: response, error: '' };
    } catch (e) {
        return { data: [], error: e };
    }
};

export default useGMVehicles;
