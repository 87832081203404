import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { requestPositionStatements } from 'api/RepairProcedureApi';
import { requestRegions } from 'api/vehicleInfo';
import { LoadingContext } from 'components/Layout';
import { ToastContext } from 'components/ToastProvider';

const usePositionStatementList = () => {
    const { oemId } = useParams();
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const { showToast } = useContext(ToastContext);
    const [groups, setGroups] = useState([]);
    const [positionStatements, setPositionStatements] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [deleteProcedureId, setDeleteProcedureId] = useState(0);
    const [editingProcedure, setEditingProcedure] = useState(null);
    const [viewingProcedure, setViewingProcedure] = useState(null);

    useEffect(() => {
        const getPositionStatements = async () => {
            try {
                incrementLoading();
                setPositionStatements(await requestPositionStatements(oemId));
                setGroups(await requestRegions());
            } catch (error) {
                showToast(error);
            } finally {
                decrementLoading();
            }
        };
        getPositionStatements();
    }, [oemId, showToast, incrementLoading, decrementLoading]);

    const onSave = async () => {
        try {
            incrementLoading();
            setPositionStatements(await requestPositionStatements(oemId));
        } catch (error) {
            showToast(error);
        } finally {
            setShowModal(false);
            decrementLoading();
        }
    };

    const handleEditClick = async (e, p) => {
        setEditingProcedure(p);
    };

    const clearEditProcedure = async () => {
        setEditingProcedure(null);
    };

    const handleViewClick = async (e, p) => {
        setViewingProcedure(p);
    };

    const handleDeleteClick = async (e, p) => {
        setDeleteProcedureId(p.procedureId);
    };

    const clearDeleteProcedureId = async () => {
        setDeleteProcedureId(0);
    };

    const deletePositionStatement = async () => {
        let newList = [...positionStatements];

        newList = newList.filter(ps => ps.procedureId !== deleteProcedureId);

        setPositionStatements(newList);
    };

    const updateProcedureInList = async procedure => {
        let newList = [...positionStatements];
        newList = newList.filter(ps => ps.procedureId !== procedure.procedureId);
        newList.push(procedure);

        setPositionStatements(newList);
        setEditingProcedure(null);
    };

    return {
        showModal,
        setShowModal,
        oemId,
        onSave,
        positionStatements,
        groups,
        handleEditClick,
        handleViewClick,
        handleDeleteClick,
        deleteProcedureId,
        clearDeleteProcedureId,
        deletePositionStatement,
        editingProcedure,
        clearEditProcedure,
        setEditingProcedure,
        updateProcedureInList,
        setViewingProcedure,
        viewingProcedure,
    };
};

export default usePositionStatementList;
