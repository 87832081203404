import { VehicleYearRange } from 'components/locations/MappingProcess/utils/groupModelsByYears';
import { VehicleInfo } from 'contexts/useFetchVehicleInfo';
import { FilteredVehicle } from '../types/FilteredVehicle';

const minimalVisible = 5;

export enum Expand {
    Expanded = 'Expanded',
    Collapsed = 'Collapsed',
}

export interface VehiclesCellState {
    vehicles: string[];
    buttonLabel: string | null; // null => button should not be visible
}

export const getVehiclesCellState = ({
    vehicleYearRanges,
    vehicleInfo,
    filteredVehicles,
    cellExpand,
}: {
    vehicleYearRanges: VehicleYearRange[];
    vehicleInfo: VehicleInfo;
    filteredVehicles: FilteredVehicle[] | undefined;
    cellExpand: Expand;
}) => {
    const total = vehicleYearRanges.length;
    const tooManyVehicles = total > minimalVisible;

    let visible = total;
    if (tooManyVehicles && cellExpand === Expand.Collapsed) {
        visible = minimalVisible - 1;
    }

    const vehicleNames = vehicleYearRanges.map(v => {
        const oemName = vehicleInfo.oems.find(oem => v.oemId === oem.oemId)?.oemName;
        const modelName = vehicleInfo.models.find(model => v.modelId === model.modelId)?.modelName;
        const isHighlighted =
            filteredVehicles &&
            filteredVehicles.length > 0 &&
            filteredVehicles.every(
                fv =>
                    (!fv.oemId || fv.oemId === v.oemId) &&
                    (!fv.modelId || fv.modelId === v.modelId) &&
                    (!fv.fromYear || isIntersect([fv.fromYear, fv.toYear], [v.startYear, v.endYear]))
            );
        return {
            isHighlighted,
            makeModel: `${oemName} ${modelName}`,
            years: v.startYear === v.endYear ? `${v.startYear}` : `${v.startYear} - ${v.endYear}`,
        };
    });

    const sorted = vehicleNames.sort(
        (a, b) =>
            Number(b.isHighlighted) - Number(a.isHighlighted) ||
            a.makeModel.localeCompare(b.makeModel) ||
            a.years.localeCompare(b.years)
    );

    const vehicles = sorted.slice(0, visible).map(vn => `${vn.isHighlighted ? '*' : ''}${vn.years} ${vn.makeModel}`);

    const buttonLabel = !tooManyVehicles
        ? null
        : visible !== total
        ? `Show all (+${total - minimalVisible + 1})`
        : 'Collapse';

    return {
        vehicles,
        buttonLabel,
    } as VehiclesCellState;
};

function isIntersect(range1: [number, number], range2: [number, number]) {
    return range1[0] <= range2[1] && range2[0] <= range1[1];
}
