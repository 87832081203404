import { OemId } from './OemId';

class _OemsMap {
    private forwardMap = new Map<OemId, { bookOems: OemId[]; vehicleOems: OemId[] }>();
    private reverseBooksMap = new Map<OemId, OemId>();
    private reverseVehicleMap = new Map<OemId, OemId>();

    constructor(entries: { majorOem: OemId; bookOems: OemId[]; vehicleOems: OemId[] }[]) {
        entries.forEach(({ majorOem, bookOems, vehicleOems }) => {
            this.forwardMap.set(majorOem, { bookOems, vehicleOems });

            bookOems.forEach(value => this.reverseBooksMap.set(value, majorOem));
            vehicleOems.forEach(value => this.reverseVehicleMap.set(value, majorOem));
        });
    }

    getBookOemsForMajorOemId(majorOemId: OemId): OemId[] | undefined {
        return this.forwardMap.get(majorOemId)?.bookOems;
    }

    getVehicleOemsForMajorOemId(majorOemId: OemId): OemId[] | undefined {
        return this.forwardMap.get(majorOemId)?.vehicleOems;
    }

    getMajorBooksOemId(subOem: OemId): OemId | undefined {
        return this.reverseBooksMap.get(subOem);
    }

    getMajorVehiclesOemId(subOem: OemId): OemId | undefined {
        return this.reverseVehicleMap.get(subOem);
    }
}

export const OemNameMap = {
    [OemId.Ford]: 'Ford',
    [OemId.Chrysler]: 'Chrysler',
    [OemId.Toyota]: 'Toyota',
    [OemId.Nissan]: 'Nissan',
    [OemId.BMW]: 'BMW',
    [OemId.Lexus]: 'Lexus',
    [OemId.Scion]: 'Scion',
    [OemId.Chevrolet]: 'Chevrolet',
    [OemId.Cadillac]: 'Cadillac',
    [OemId.Buick]: 'Buick',
    [OemId.GMC]: 'GMC',
    [OemId.Hummer]: 'Hummer',
    [OemId.Oldsmobile]: 'Oldsmobile',
    [OemId.Pontiac]: 'Pontiac',
    [OemId.Saturn]: 'Saturn',
    [OemId.Infiniti]: 'Infiniti',
    [OemId.Honda]: 'Honda',
    [OemId.Acura]: 'Acura',
    [OemId.Hyundai]: 'Hyundai',
    [OemId.Kia]: 'Kia',
    [OemId.MINI]: 'MINI',
    [OemId.MercedesBenz]: 'Mercedes-Benz',
    [OemId.Subaru]: 'Subaru',
    [OemId.Mazda]: 'Mazda',
    [OemId.Audi]: 'Audi',
    [OemId.Volkswagen]: 'Volkswagen',
    [OemId.Volvo]: 'Volvo',
    [OemId.RAM]: 'RAM',
    [OemId.Fiat]: 'Fiat',
    [OemId.Dodge]: 'Dodge',
    [OemId.Jeep]: 'Jeep',
    [OemId.Jaguar]: 'Jaguar',
    [OemId.LandRover]: 'Land Rover',
    [OemId.Mitsubishi]: 'Mitsubishi',
    [OemId.Lincoln]: 'Lincoln',
    [OemId.AlfaRomeo]: 'Alfa Romeo',
    [OemId.Genesis]: 'Genesis',
    [OemId.Tesla]: 'Tesla',
    [OemId.Porsche]: 'Porsche',
    [OemId.OEMiQ]: 'OEMiQ',
};

export const OemIdMap = [
    { majorOem: OemId.Ford, bookOems: [OemId.Ford], vehicleOems: [OemId.Ford, OemId.Lincoln] },
    {
        majorOem: OemId.Toyota,
        bookOems: [OemId.Toyota, OemId.Lexus],
        vehicleOems: [OemId.Toyota, OemId.Lexus, OemId.Scion],
    },
    { majorOem: OemId.Nissan, bookOems: [OemId.Nissan, OemId.Infiniti], vehicleOems: [OemId.Nissan, OemId.Infiniti] },
    { majorOem: OemId.Honda, bookOems: [OemId.Honda, OemId.Acura], vehicleOems: [OemId.Honda, OemId.Acura] },
    {
        majorOem: OemId.GMC,
        bookOems: [OemId.GMC],
        vehicleOems: [
            OemId.Chevrolet,
            OemId.Cadillac,
            OemId.Buick,
            OemId.GMC,
            OemId.Hummer,
            OemId.Oldsmobile,
            OemId.Pontiac,
            OemId.Saturn,
        ],
    },
    {
        majorOem: OemId.Chrysler,
        bookOems: [OemId.Chrysler, OemId.RAM, OemId.Fiat, OemId.Dodge, OemId.Jeep, OemId.AlfaRomeo],
        vehicleOems: [OemId.Chrysler, OemId.RAM, OemId.Fiat, OemId.Dodge, OemId.Jeep, OemId.AlfaRomeo],
    },
    {
        majorOem: OemId.Volkswagen,
        bookOems: [OemId.Volkswagen, OemId.Audi],
        vehicleOems: [OemId.Volkswagen, OemId.Audi],
    },
    { majorOem: OemId.Subaru, bookOems: [OemId.Subaru], vehicleOems: [OemId.Subaru] },
    { majorOem: OemId.Hyundai, bookOems: [OemId.Hyundai, OemId.Genesis], vehicleOems: [OemId.Hyundai, OemId.Genesis] },
    { majorOem: OemId.OEMiQ, bookOems: [OemId.OEMiQ], vehicleOems: [OemId.Ford, OemId.Lincoln, OemId.OEMiQ] },
];

export const OemsMap = new _OemsMap(OemIdMap);
