import { useCallback, useEffect, useState } from 'react';

const useModal = toggleCallback => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = useCallback(() => setIsModalOpen(true), []);
    const closeModal = useCallback(() => setIsModalOpen(false), []);

    useEffect(() => toggleCallback && toggleCallback(isModalOpen), [isModalOpen, toggleCallback]);

    return {
        isModalOpen,
        openModal,
        closeModal,
    };
};

export default useModal;
