import React, { useContext, useRef } from 'react';
import Table from 'components/Shared/Table/Table';
import Confirmation from 'components/Shared/Confirmation/Confirmation';
import { NotificationsContext } from 'components/Shared/Notifications/Notifications';

import useTableActions from './useTableActions';
import useVehicles from './useVehicles';
import VehiclePublishTableConfiguration from './VehiclePublishTableConfiguration';

const VehicleList = ({ oemId, modelId, showBooks = true }) => {
    const confirmRef = useRef();

    const { notifications } = useContext(NotificationsContext);
    const { data, setData, categories, manageVehiclePublisherExceptionTracking, editMode, clearPublishExceptionData } =
        useVehicles(oemId, modelId, showBooks, notifications);

    const headers = VehiclePublishTableConfiguration(categories, manageVehiclePublisherExceptionTracking, editMode);
    const actions = useTableActions(setData, notifications, confirmRef, clearPublishExceptionData);

    return (
        <div>
            <Confirmation ref={confirmRef} />
            <Table idKey="id" headers={headers} data={data} cellEventCallbacks={actions} />
        </div>
    );
};

export default VehicleList;
