import React, { useCallback, useContext, useState } from 'react';
import ReactModal from 'components/Shared/ReactModal';
import BulkMoveBody from './BulkMoveBody';
import BulkMoveFooter from './BulkMoveFooter';
import { useParams } from 'react-router-dom';
import { LoadingContext } from 'components/Layout';
import { OlsMapperListContext } from 'contexts/MapperListContext';
import { requestUpdateProcedureMappingStatus } from 'api/RepairProcedureApi';

type BulkMoveModalProps = {
    isOpen: boolean;
    statusModalProcedureIds: number[];
    selectedProceduresCount: number;
    resetProcedureIds: () => void;
    procedures;
    resetBulkSelection: () => void;
    oDataFilter: string;
};

const BulkMoveModal = ({
    isOpen,
    statusModalProcedureIds,
    selectedProceduresCount,
    resetProcedureIds,
    procedures,
    resetBulkSelection,
    oDataFilter,
}: BulkMoveModalProps) => {
    const [selectedStatusId, setSelectedStatusId] = useState<number>(-1);
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const { updateProcedures, refreshProcedures, toggleBulkTaskHistoryModal } = useContext(OlsMapperListContext);
    const { oemId } = useParams();

    const handleModalToggle = useCallback(() => {
        resetProcedureIds();
        setSelectedStatusId(-1);
    }, [resetProcedureIds]);

    const handleBulkChangeStatusProcedures = useCallback(async () => {
        incrementLoading();
        const uniqueProcedureIds = statusModalProcedureIds ? [...new Set(statusModalProcedureIds)] : [];

        const { isQueued, procedureIds } = await requestUpdateProcedureMappingStatus(
            uniqueProcedureIds,
            selectedStatusId,
            parseInt(oemId),
            oDataFilter
        );

        const proceduresToUpdate = procedures.filter(procedure => procedureIds.includes(procedure.procedureId));
        proceduresToUpdate.forEach(procedure => {
            procedure.stageArea.type.mappingStatusId = selectedStatusId;
            procedure.stageArea.groups.forEach(group => {
                group.mappingStatusId = selectedStatusId;
            });
        });

        updateProcedures(proceduresToUpdate);

        handleModalToggle();
        resetBulkSelection();
        decrementLoading();
        await refreshProcedures();

        if (isQueued) {
            toggleBulkTaskHistoryModal();
        }
    }, [
        incrementLoading,
        selectedStatusId,
        statusModalProcedureIds,
        procedures,
        oemId,
        oDataFilter,
        updateProcedures,
        handleModalToggle,
        resetBulkSelection,
        decrementLoading,
        refreshProcedures,
        toggleBulkTaskHistoryModal,
    ]);

    return (
        <ReactModal
            id="bulk-move-modal"
            headerComponent={<div style={{ fontSize: '1rem', lineHeight: 'normal' }}>Bulk Move</div>}
            isOpen={isOpen}
            toggle={handleModalToggle}
            bodyComponent={
                <BulkMoveBody
                    selectedProceduresCount={selectedProceduresCount}
                    selectedStatusId={selectedStatusId}
                    setSelectedStatusId={setSelectedStatusId}
                />
            }
            footerComponent={
                <BulkMoveFooter
                    handleModalToggle={handleModalToggle}
                    handleBulkChangeStatusProcedures={handleBulkChangeStatusProcedures}
                />
            }
            container={undefined}
        />
    );
};

export default BulkMoveModal;
