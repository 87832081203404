import { useNavigate, useLocation } from 'react-router-dom';

const useLinksTable = handleRowClick => {
    let navigate = useNavigate();
    let location = useLocation();

    const handleLinksRowClick = tagId => {
        handleRowClick();
        navigate(`${location.pathname}/link/${tagId}`);
    };

    return { handleLinksRowClick };
};

export default useLinksTable;
