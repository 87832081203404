import React from 'react';

import { ReactModal } from 'oemiq-common';

export const WARNING_MODAL_TYPE = Object.freeze({
    APPLYING: 1,
    SWITCHING: 2,
    SEARCHING: 3,
    SORTING: 4,
    LIFECYCLE_FILTER: 5,
    APPLY_RESET_FILTER_SORT: 6,
});

const Body = ({ type, flagModeOn, newItemCount, deletedItemCount, restoredItemCount }) => {
    switch (type) {
        case WARNING_MODAL_TYPE.APPLYING:
            return (
                <div>
                    <div>You are:</div>
                    <ul>
                        {!!newItemCount && (
                            <li>{`${flagModeOn ? 'Creating' : 'Updating'} ${newItemCount} tag${
                                newItemCount > 1 ? 's' : ''
                            }`}</li>
                        )}
                        {!!deletedItemCount && (
                            <li>{`${flagModeOn ? 'Disposing' : 'Inactivating'} ${deletedItemCount} ${
                                flagModeOn ? 'flag' : 'tag'
                            }${deletedItemCount > 1 ? 's' : ''}`}</li>
                        )}
                        {!!restoredItemCount && (
                            <li>{`Restoring ${restoredItemCount} ${flagModeOn ? 'flag' : 'tag'}${
                                restoredItemCount > 1 ? 's' : ''
                            }`}</li>
                        )}
                    </ul>
                </div>
            );
        case WARNING_MODAL_TYPE.SWITCHING: // same as below
        case WARNING_MODAL_TYPE.LIFECYCLE_FILTER: // same as below
        case WARNING_MODAL_TYPE.SEARCHING:
        case WARNING_MODAL_TYPE.APPLY_RESET_FILTER_SORT:
            return (
                <div>
                    <div>You are leaving without:</div>
                    <ul>
                        {!!newItemCount && (
                            <li>{`${flagModeOn ? 'Creating' : 'Updating'} ${newItemCount} tag${
                                newItemCount > 1 ? 's' : ''
                            }`}</li>
                        )}
                        {!!deletedItemCount && (
                            <li>{`${flagModeOn ? 'Disposing' : 'Inactivating'} ${deletedItemCount} ${
                                flagModeOn ? 'flag' : 'tag'
                            }${deletedItemCount > 1 ? 's' : ''}`}</li>
                        )}
                        {!!restoredItemCount && (
                            <li>{`Restoring ${restoredItemCount} ${flagModeOn ? 'flag' : 'tag'}${
                                restoredItemCount > 1 ? 's' : ''
                            }`}</li>
                        )}
                    </ul>
                    <div>Unsaved work will be lost.</div>
                </div>
            );
        case WARNING_MODAL_TYPE.SORTING:
            return (
                <div>
                    <div>Sorting will discard the following:</div>
                    <ul>
                        {!!newItemCount && (
                            <li>{`${flagModeOn ? 'Creating' : 'Updating'} ${newItemCount} tag${
                                newItemCount > 1 ? 's' : ''
                            }`}</li>
                        )}
                        {!!deletedItemCount && (
                            <li>{`${flagModeOn ? 'Disposing' : 'Inactivating'} ${deletedItemCount} ${
                                flagModeOn ? 'flag' : 'tag'
                            }${deletedItemCount > 1 ? 's' : ''}`}</li>
                        )}
                        {!!restoredItemCount && (
                            <li>{`Restoring ${restoredItemCount} ${flagModeOn ? 'flag' : 'tag'}${
                                restoredItemCount > 1 ? 's' : ''
                            }`}</li>
                        )}
                    </ul>
                    <div>Unsaved work will be lost.</div>
                </div>
            );
        default:
            throw new Error('Warning modal type not found');
    }
};

const ViewWarningModal = ({
    open,
    onCancel,
    onConfirm,
    type,
    flagModeOn,
    newItemCount,
    deletedItemCount,
    restoredItemCount,
}) => {
    return (
        <ReactModal
            id="confirm-apply-action"
            isOpen={open}
            headerComponent={<div>{'Are you sure?'}</div>}
            bodyComponent={
                <Body
                    type={type}
                    flagModeOn={flagModeOn}
                    newItemCount={newItemCount}
                    deletedItemCount={deletedItemCount}
                    restoredItemCount={restoredItemCount}
                />
            }
            footerComponent={
                <div>
                    <button
                        type="button"
                        className={`btn btn-sm h-100 ${
                            type === WARNING_MODAL_TYPE.APPLYING ? 'btn-danger' : 'btn-primary'
                        }`}
                        onClick={onCancel}>
                        Cancel
                    </button>
                    <button
                        type="button"
                        className={`btn btn-sm ms-3 h-100 ${
                            type === WARNING_MODAL_TYPE.APPLYING ? 'btn-primary' : 'btn-danger'
                        }`}
                        onClick={onConfirm}>
                        Confirm
                    </button>
                </div>
            }
        />
    );
};

export default ViewWarningModal;
