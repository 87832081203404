import React, { useCallback } from 'react';
import { LargeCheckbox } from 'oemiq-common';
import BulkEditTableItem from './BulkEditTableItem';
import SortableTableHeader from 'components/locations/TaggingProcess/TaggerList/SortableTableHeader/SortableTableHeader';
import InfiniteScrollTableRow from 'components/InfiniteScrollTableRow';

import { TABLE_COLUMNS } from 'helpers/BulkEditTableHelper';

const BulkEditTable = ({
    loading,
    hasMore,
    items,
    updateItem,
    fetchMore,
    handleOpenProcedureModal,
    handleSortColumnClick,
    sortedColumns,
    partTypes,
    quantityConditions,
    addNewQuantityCondition,
    selectedItems,
    onItemCheckboxClick,
    flagModeOn,
    groups,
    types,
    selectAllChecked,
    handleMasterCheckboxClick,
    onNeedsAttentionClick,
    onItemCheckboxShiftButtonKeyDown,
    onItemCheckboxShiftButtonKeyUp,
    onDisposeItem,
    onRestoreItem,
    isLifecycleChange,
}) => {
    const handleCheckboxClick = useCallback(item => onItemCheckboxClick(item), [onItemCheckboxClick]);
    const tableColumns = TABLE_COLUMNS.filter(t => t.showInFlagMode === null || t.showInFlagMode === flagModeOn);
    const cssNavbarHeight = '48px'; // the header is sticky

    return (
        <table id="bulk-edit-tag-table" className="table table-striped table-hover">
            <thead
                id="bulk-edit-tag-table-header"
                style={{ position: 'sticky', top: cssNavbarHeight, backgroundColor: 'white', zIndex: '100' }}>
                <tr>
                    <th>
                        <LargeCheckbox
                            id={'select-all-checkbox'}
                            label=""
                            className="large-checkbox-check mb-0"
                            checked={selectAllChecked}
                            onChange={() => handleMasterCheckboxClick()}
                            readOnly={false}
                        />
                    </th>
                    {tableColumns.map(c => (
                        <SortableTableHeader
                            key={c.propertyName}
                            title={c.displayName}
                            propertyKey={c.propertyName}
                            sortColumn={sortedColumns.has(c.propertyName) ? sortedColumns.get(c.propertyName) : null}
                            handleSortColumnClick={() => handleSortColumnClick(c)}
                        />
                    ))}
                </tr>
            </thead>
            <tbody>
                {items.map((item, index) => {
                    return (
                        <BulkEditTableItem
                            key={item.id}
                            item={item}
                            rowLinkIndex={index}
                            handleOpenProcedureModal={handleOpenProcedureModal}
                            updateItem={updateItem}
                            quantityConditions={quantityConditions}
                            addNewQuantityCondition={addNewQuantityCondition}
                            partTypes={partTypes}
                            groups={groups}
                            types={types}
                            checked={selectedItems.has(item.id)}
                            onCheckboxClick={handleCheckboxClick}
                            flagModeOn={flagModeOn}
                            onNeedsAttentionClick={onNeedsAttentionClick}
                            onCheckboxShiftButtonKeyDown={onItemCheckboxShiftButtonKeyDown}
                            onCheckboxShiftButtonKeyUp={onItemCheckboxShiftButtonKeyUp}
                            onDisposeItem={onDisposeItem}
                            onRestoreItem={onRestoreItem}
                            isLifecycleChange={isLifecycleChange}
                        />
                    );
                })}
            </tbody>
            <tfoot>
                {hasMore && (
                    <InfiniteScrollTableRow
                        columnsCount={tableColumns.length + 1}
                        isLoading={loading}
                        loadMoreCallback={fetchMore}
                    />
                )}
            </tfoot>
        </table>
    );
};

export default BulkEditTable;
