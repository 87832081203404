import { useState, useContext, useEffect } from 'react';
import { LoadingContext } from 'components/Layout';
import { ToastContext } from 'components/ToastProvider';
import { requestYears, requestModelsForOem, requestRegions } from 'api/vehicleInfo';
import { requestUpdatePositionStatement } from 'api/RepairProcedureApi';

const useEditPositionStatementModal = (toggle, oemId, editingProcedure, updateProcedureInList) => {
    const [title, setTitle] = useState(editingProcedure.procedureTitle);

    const [isOem, setIsOem] = useState(true);
    const [saveEnabled, setIsSaveEnabled] = useState(false);

    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const { showToast } = useContext(ToastContext);

    const [regionOptions, setRegionOptions] = useState([]);
    const [regions, setRegions] = useState([]);
    const [years, setYears] = useState([]);
    const [models, setModels] = useState([]);
    const [yearOptions, setYearOptions] = useState({});
    const [modelOptions, setModelOptions] = useState({});
    const [fileData, setFileData] = useState(null);

    useEffect(() => {
        const getVehicleData = async () => {
            try {
                incrementLoading();
                const yearData = await requestYears();
                const modelData = await requestModelsForOem(oemId);
                let regionData = await requestRegions();
                regionData = regionData.filter(rd => rd.regionId !== 40); //Filter out position statement group as this is auto mapped in the command handler
                setYearOptions(
                    yearData.map(y => {
                        return { label: y.yearValue, value: y.yearId };
                    })
                );
                setModelOptions(
                    modelData.map(m => {
                        return { label: m.modelName, value: m.modelId };
                    })
                );

                let region = {};

                setRegionOptions(
                    regionData.map(r => {
                        region[r.regionId] = { label: r.regionFriendlyName, value: r.regionId };
                        return { label: r.regionFriendlyName, value: r.regionId };
                    })
                );

                decrementLoading();
            } catch (error) {
                showToast(error);
            } finally {
                decrementLoading();
            }
        };
        getVehicleData();
    }, [showToast, incrementLoading, decrementLoading, oemId]);

    useEffect(() => {
        if (regionOptions) {
            let options = {};
            regionOptions.forEach(ro => (options[ro.value] = ro));

            let selectedRegions = [];
            editingProcedure.groups.forEach(g => {
                if (options[g.regionId]) {
                    selectedRegions.push(options[g.regionId]);
                }
            });
            setRegions(selectedRegions);
        }

        if (modelOptions && modelOptions.length) {
            let options = {};
            modelOptions.forEach(mo => (options[mo.value] = mo));

            let selectedModels = new Set();
            editingProcedure.vehicles.forEach(v => {
                if (v.modelId > 0) {
                    if (options[v.modelId]) {
                        selectedModels.add(options[v.modelId]);
                    }
                }
            });
            setModels(Array.from(selectedModels));
        }
        if (yearOptions && yearOptions.length) {
            let options = {};
            yearOptions.forEach(yo => (options[yo.value] = yo));

            let selectedYears = new Set();
            editingProcedure.vehicles.forEach(v => {
                if (v.yearId > 0) {
                    if (options[v.yearId]) {
                        selectedYears.add(options[v.yearId]);
                    }
                }
            });
            setYears(Array.from(selectedYears));
        }

        // Check to see if it applies to the entire OEM
        let allOem = true;
        if (
            editingProcedure.vehicles &&
            editingProcedure.vehicles.length > 0 &&
            (editingProcedure.vehicles[0].yearId !== 0 || editingProcedure.vehicles[0].modelId !== 0)
        ) {
            allOem = false;
        }

        setIsOem(allOem);
    }, [regionOptions, yearOptions, modelOptions, editingProcedure.groups, editingProcedure.vehicles]);

    //Handle Enable save
    useEffect(() => {
        if (!oemId || !regions || !title) {
            setIsSaveEnabled(false);
        } else if (!isOem && (!years || years.length < 1) && (!models || models.length < 1)) setIsSaveEnabled(false);
        else setIsSaveEnabled(true);
    }, [oemId, isOem, title, years, models, regions]);

    const handleToggle = () => {
        clear();
        toggle();
    };

    const handleSave = async () => {
        let yearIds = [];
        let modelIds = [];
        let regionIds = [];
        let newProcedure = { ...editingProcedure };
        newProcedure.vehicles = [];

        if (!isOem) {
            for (let i = 0; i < years.length; i++) {
                yearIds.push(years[i].value);
                newProcedure.vehicles.push({
                    bodyStyleId: null,
                    boxLengthId: null,
                    modelId: 0,
                    oemId: oemId,
                    procedureId: editingProcedure.procedureId,
                    yearId: years[i].value,
                });
            }

            for (let i = 0; i < models.length; i++) {
                modelIds.push(models[i].value);

                newProcedure.vehicles.push({
                    bodyStyleId: null,
                    boxLengthId: null,
                    modelId: models[i].value,
                    oemId: oemId,
                    procedureId: editingProcedure.procedureId,
                    yearId: 0,
                });
            }
        } else {
            newProcedure.vehicles.push({
                modelId: 0,
                oemId: oemId,
                procedureId: editingProcedure.procedureId,
                yearId: 0,
            });
        }

        newProcedure.groups = [];
        newProcedure.procedureTitle = title;

        for (let i = 0; i < regions.length; i++) {
            regionIds.push(regions[i].value);
            newProcedure.groups.push({
                procedureId: editingProcedure.procedureId,
                regionId: regions[i].value,
                workflowStatusId: 2,
            });
        }

        try {
            incrementLoading();
            const form = new FormData();
            if (fileData !== null) form.append('File', fileData, fileData.name);
            form.append('Title', `${title}`);
            form.append('OemId', `${oemId}`);
            form.append('ProcedureId', `${editingProcedure.procedureId}`);

            if (!isOem) {
                for (let i = 0; i < years.length; i++) form.append('YearIds', `${years[i].value}`);
                for (let i = 0; i < models.length; i++) form.append('ModelIds', `${models[i].value}`);
            }
            for (let i = 0; i < regions.length; i++) form.append('Regions', `${regions[i].value}`);
            await requestUpdatePositionStatement(parseInt(oemId), form);

            // Create an updated procedure
            updateProcedureInList(newProcedure);
        } catch (error) {
            showToast(error);
        } finally {
            handleToggle();
            decrementLoading();
        }
    };

    const clear = () => {
        setTitle('');
        setRegions([]);
        setYears([]);
        setModels([]);
        setIsOem(false);
    };

    const handleIsOemToggle = () => {
        setYears([]);
        setModels([]);
        setIsOem(!isOem);
    };

    const handleYearChange = e => {
        setYears(e);
    };

    const handleModelChange = e => {
        setModels(e);
    };

    const handleRegionChange = e => {
        setRegions(e);
    };

    return {
        handleToggle,
        title,
        setTitle,
        isOem,
        handleIsOemToggle,
        setIsOem,
        yearOptions,
        handleYearChange,
        years,
        modelOptions,
        handleModelChange,
        models,
        regionOptions,
        handleRegionChange,
        regions,
        handleSave,
        saveEnabled,
        setFileData,
    };
};

export default useEditPositionStatementModal;
