import { useState, useEffect, useContext, useCallback } from 'react';
import { LoadingContext } from 'components/Layout';
import { ToastContext } from 'components/ToastProvider';
import { requestProceduresByBookApi } from 'api/RepairProcedureApi';
import { appInsights } from '../../src/api/config/AppInsights';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

const useProcedures = bookId => {
    const { showToast } = useContext(ToastContext);
    const [procedures, setProcedures] = useState([]);
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);

    const getProcedures = useCallback(async () => {
        try {
            incrementLoading();
            setProcedures(await requestProceduresByBookApi(bookId));
        } catch (error) {
            showToast(error);
            appInsights.trackException({ error: error, severityLevel: SeverityLevel.Error });
        } finally {
            decrementLoading();
        }
    }, [bookId, showToast, incrementLoading, decrementLoading]);

    const updateOemIqTypeByProcedureId = useCallback((newOemIqType, procedureId) => {
        setProcedures(currProcedures => {
            return currProcedures.map(p => {
                if (p.procedureId === procedureId) {
                    return { ...p, type: newOemIqType };
                }
                return p;
            });
        });
    }, []);

    const updateOemIqTypeForProcedureIds = useCallback((newOemIqType, procedureIds, statusId) => {
        setProcedures(currProcedures => {
            return currProcedures.map(p => {
                if (procedureIds.includes(p.procedureId)) {
                    return { ...p, type: newOemIqType, typeMappingWorkFlowStatusId: statusId };
                }
                return p;
            });
        });
    }, []);

    const setNewGroupListToProcedureByProcedureId = useCallback((newGroupList, procedureId) => {
        setProcedures(currProcedures => {
            return currProcedures.map(p => {
                if (p.procedureId === procedureId) {
                    return { ...p, groups: newGroupList };
                }
                return p;
            });
        });
    }, []);

    const updateProcedures = useCallback(updatedProcedures => {
        setProcedures(currProcedures => {
            const newProcedures = [...currProcedures];
            updatedProcedures.forEach(up => {
                const index = newProcedures.findIndex(np => np.procedureId === up.procedureId);
                newProcedures[index] = up;
            });

            return newProcedures;
        });
    }, []);

    useEffect(() => {
        getProcedures();
    }, [getProcedures]);

    return {
        procedures,
        updateOemIqTypeByProcedureId,
        setNewGroupListToProcedureByProcedureId,
        updateOemIqTypeForProcedureIds,
        updateProcedures,
        getProcedures,
    };
};

export default useProcedures;
