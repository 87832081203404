import React from 'react';
import { LargeCheckbox } from 'oemiq-common';
import useFilterSearch from './useFilterSearch';
import FilterSearchItem from './FilterSearchItem';
import { useMemo } from 'react';

const FilterSearch = ({ columns, setNoGroupFilter, setNoTypeFilter, setRefreshedFilter, setOnlyHotSheetFilter }) => {
    const {
        addFilter,
        removeFilter,
        handleApplyFilter,
        validationError,
        displayFilters,
        filterWithoutGroup,
        setFilterWithoutGroup,
        filterWithoutType,
        setFilterWithoutType,
        filterRefreshedPending,
        setFilterRefreshedPending,
        filterOnlyHotSheet,
        setFilterOnlyHotSheet,
    } = useFilterSearch(setNoGroupFilter, setNoTypeFilter, setRefreshedFilter, setOnlyHotSheetFilter);

    const filterColumns = useMemo(() => {
        const dataColumns = columns.filter(c => !c.isHidden).map(c => ({ text: c.text, value: c.text }));
        const filters = [
            { text: 'Id', value: 'procedureId' },
            { text: 'Groups', value: 'ColumnGroups' },
            { text: 'Type', value: 'ColumnType' },
            ...dataColumns,
        ];
        return filters;
    }, [columns]);

    return (
        <div>
            {displayFilters.map(filter => {
                return (
                    <FilterSearchItem
                        filterColumns={filterColumns}
                        addFilter={addFilter}
                        removeFilter={removeFilter}
                        id={filter.id}
                        key={filter.id}
                        selectedColumn={filter.selectedColumn}
                        selectedOperator={filter.selectedOperator}
                        term={filter.term}
                    />
                );
            })}
            {validationError ? (
                <div data-testid="warning-message" className="text-danger pb-2">
                    All filters must contain a column, operator, and term
                </div>
            ) : (
                false
            )}

            <div className="d-flex align-items-center my-2">
                <div className="d-flex align-items-center  me-4">
                    <div style={{ height: '21px' }}>
                        <LargeCheckbox
                            id="filter-no-groups"
                            label="Filter no group"
                            className="large-checkbox-check mb-0"
                            checked={filterWithoutGroup}
                            onChange={() => setFilterWithoutGroup(currState => !currState)}
                            readOnly={false}
                        />
                    </div>
                </div>
                <div className="d-flex align-items-center me-4">
                    <div style={{ height: '21px' }}>
                        <LargeCheckbox
                            id="filter-no-type"
                            label="Filter no type"
                            className="large-checkbox-check mb-0"
                            checked={filterWithoutType}
                            onChange={() => setFilterWithoutType(currState => !currState)}
                            readOnly={false}
                        />
                    </div>
                </div>
                <div className="d-flex align-items-center me-4">
                    <div style={{ height: '21px' }}>
                        <LargeCheckbox
                            id="filter-refreshed-pending"
                            label="Refreshed pending"
                            className="large-checkbox-check mb-0"
                            checked={filterRefreshedPending}
                            onChange={() => setFilterRefreshedPending(currState => !currState)}
                            readOnly={false}
                        />
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    <div style={{ height: '21px' }}>
                        <LargeCheckbox
                            id="filter-only-hotsheet"
                            label="Filter HotSheet group only"
                            className="large-checkbox-check mb-0"
                            checked={filterOnlyHotSheet}
                            onChange={() => setFilterOnlyHotSheet(currState => !currState)}
                            readOnly={false}
                        />
                    </div>
                </div>
            </div>

            <button
                id="apply-filters-button"
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => handleApplyFilter()}>
                Apply Filters
            </button>
        </div>
    );
};

export default FilterSearch;
