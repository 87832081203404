import React from 'react';
import { Select } from 'oemiq-common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';
import ReactSelect from 'react-select';

const BuildMappingRuleModalBody = ({
    conditions,
    oemMetadata,
    mappingDefinitions,
    selectedGroups,
    typeId,
    onGroupsChange,
    onTypeIdChange,
}) => {
    return (
        <>
            <div className="row mb-3">
                <div className="col-8">
                    <h5>Rule Conditions</h5>
                    {oemMetadata.ruleable
                        .filter(p => !isEmpty(conditions[p.rulePropertyName]))
                        .map(p => (
                            <div key={p.name}>
                                {p.displayName}{' '}
                                <span className="badge bg-primary text-white">
                                    {conditions[p.rulePropertyName].operator.label}
                                </span>{' '}
                                {conditions[p.rulePropertyName].value}
                            </div>
                        ))}
                </div>
                <div className="col-4">
                    <h5>Empty Meta Fields</h5>
                    {oemMetadata.ruleable
                        .filter(p => isEmpty(conditions[p.rulePropertyName]))
                        .map(p => (
                            <div key={p.name}>{p.displayName}</div>
                        ))}
                </div>
            </div>
            <div>
                <h5>Assign</h5>
                <ReactSelect
                    className="mt-2"
                    placeholder="Select Available Groups"
                    isMulti={true}
                    options={mappingDefinitions.groups}
                    value={selectedGroups}
                    onChange={onGroupsChange}
                />
                <div className="d-flex justify-content-between align-items-center mt-2">
                    <div className="flex-fill">
                        <Select
                            formGroupClassName="mb-0"
                            className="form-select"
                            value={typeId ?? -1}
                            onChange={e => onTypeIdChange(e.currentTarget.value !== -1 ? e.currentTarget.value : null)}
                            options={mappingDefinitions.types}
                            defaultOption={{ text: 'Select Type', value: -1 }}
                        />
                    </div>
                    {typeId && (
                        <div className="ms-4">
                            <button
                                type="button"
                                className="btn btn-sm btn-danger"
                                onClick={() => onTypeIdChange(null)}>
                                <FontAwesomeIcon icon="times" />
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default BuildMappingRuleModalBody;
