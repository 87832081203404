import React from 'react';
import useViewVehiclePublishHistoryContent from './useViewVehiclePublishHistoryContent';

const ViewVehiclePublishHistoryContent = ({ vehicleId }) => {
    const { vehicleHistory } = useViewVehiclePublishHistoryContent(vehicleId);

    if (vehicleHistory.length === 0) return <h5>No records found</h5>;

    return (
        <div>
            {vehicleHistory.map((vh, index) => {
                //Control styling based on the first and last element
                const isLast = index === vehicleHistory.length - 1;
                const isFirst = index === 0;
                return (
                    <div key={index} className={`${isLast ? '' : 'border-bottom'} ${isFirst ? 'pb-3' : 'py-3'}`}>
                        <div>
                            {vh.action}{' '}
                            {new Date(vh.actionDate).toISOString().slice(0, 19).replace(/-/g, '/').replace('T', ' ')} by{' '}
                            {vh.userName}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default ViewVehiclePublishHistoryContent;
