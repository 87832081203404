import * as React from 'react';

const ProgressBar = ({
    percentage,
    className,
    height,
}: {
    percentage: number;
    className?: string;
    height?: string;
}) => {
    return (
        <div
            className={`progress${percentage === 100 ? ' fade-progress' : ''}${className ? ` ${className}` : ''}`}
            style={{ height: height ? height : '0.15rem' }}>
            <div className="progress-bar" role="progressbar" style={{ width: `${percentage}%` }}></div>
        </div>
    );
};

export default ProgressBar;
