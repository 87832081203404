import { useCallback, useState, useMemo } from 'react';
import { SORT_ORDER, SortOrderClause } from 'enums/SortOrderEnum';

const useSorting = () => {
    const [sortRules, setSortRules] = useState<SortOrderClause[]>([]);

    const handleSorting = useCallback((elementId: string, order: SORT_ORDER) => {
        setSortRules(prev => {
            const elementIndex = prev.findIndex(e => e.elementId === elementId);
            if (elementIndex === -1) {
                return [...prev, { elementId, order }];
            } else {
                return prev
                    .map(rule => (rule.elementId === elementId ? { ...rule, order } : rule))
                    .filter(rule => rule.order != SORT_ORDER.none);
            }
        });
    }, []);

    return useMemo(() => ({ orderBy: sortRules, handleSorting }), [sortRules, handleSorting]);
};

export default useSorting;
