import React, { useEffect, useRef, useState } from 'react';
import { isEqual } from 'lodash';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { escapeRegExp } from 'lodash';

const defaultItemComponent = ({ option, active, setShow, locationSearch }) => {
    return (
        <Link
            className={`dropdown-item${active ? ' active' : ''}`}
            to={option.link + locationSearch}
            onClick={() => {
                setShow(false);
            }}>
            {option.text}
        </Link>
    );
};

const defaultTriggerComponent = ({ text, triggerClass, setShow, ...rest }) => (
    <button
        className={`btn dropdown-toggle${triggerClass ? ` ${triggerClass}` : ''}`}
        onClick={() => setShow(s => !s)}
        {...rest}>
        {text}
    </button>
);

const Dropdown = ({
    text,
    items,
    active,
    triggerComponent,
    itemComponent,
    menuClass,
    dropdownClass,
    triggerClass,
    triggerProps,
}) => {
    const location = useLocation();
    const [show, setShow] = useState(false);
    const [filter, setFilter] = useState('');
    const Item = itemComponent ? itemComponent : defaultItemComponent;
    const Trigger = triggerComponent ? triggerComponent : defaultTriggerComponent;
    const inputRef = useRef();

    useEffect(() => {
        show && inputRef.current.focus();
    }, [show]);
    useEffect(() => {
        !show && setFilter('');
    }, [show]);

    return (
        <>
            <div className={`dropdown${dropdownClass ? ` ${dropdownClass}` : ''}`} style={{ zIndex: show ? 1000 : 0 }}>
                <Trigger text={text} triggerClass={triggerClass} setShow={setShow} {...triggerProps} />
                <div
                    className={`dropdown-menu${show ? ' show' : ''}${menuClass ? ` ${menuClass}` : ''}`}
                    style={{ maxHeight: '300px', overflowY: 'auto' }}
                    aria-labelledby="dropdownMenuButton">
                    <div className="ps-2 pe-2 pb-2">
                        <input
                            ref={inputRef}
                            className="form-control"
                            type="text"
                            placeholder="Search..."
                            value={filter}
                            onChange={e => setFilter(e.target.value)}
                        />
                    </div>
                    {items
                        .filter(o => (filter ? o.text.match(new RegExp(escapeRegExp(filter), 'i')) : true))
                        .map((o, i) => (
                            <Item
                                key={i}
                                option={o}
                                active={isEqual(o, active)}
                                setShow={setShow}
                                locationSearch={location.search}
                            />
                        ))}
                </div>
            </div>
            <div
                style={{
                    display: show ? 'block' : 'none',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: 999,
                }}
                onClick={() => {
                    setShow(false);
                }}></div>
        </>
    );
};

Dropdown.propTypes = {
    text: PropTypes.string,
    options: PropTypes.array,
    active: PropTypes.object,
    triggerComponent: PropTypes.func,
    itemComponent: PropTypes.func,
    menuClass: PropTypes.string,
    dropdownClass: PropTypes.string,
    triggerClass: PropTypes.string,
};

export default React.memo(Dropdown);
