import React, { useContext } from 'react';
import { ReactModal } from 'oemiq-common';
import useImportJobChangeReportModal from './useImportJobChangeReportModal';
import ImportJobChangeReportBodyItem from './ImportJobChangeReportBody/ImportJobChangeReportBodyItem';
import { MappingDefinitionsContext } from 'contexts/MappingDefinitionsContext';

const ImportJobChangeReportModal = ({ isOpen, toggle, importJobId, toggleHistory }) => {
    const { proceduresByBook, handleBookClick } = useImportJobChangeReportModal(importJobId, toggle, toggleHistory);

    const { groups, _ } = useContext(MappingDefinitionsContext);

    return (
        <ReactModal
            id="import-job-change-report-modal"
            className="modal-xl"
            headerComponent={
                <div style={{ fontSize: '1rem', lineHeight: 'normal' }}>
                    Import Job Change Report for: <b>{importJobId}</b>
                </div>
            }
            isOpen={isOpen}
            toggle={() => toggle()}
            bodyComponent={
                proceduresByBook && (
                    <div>
                        {proceduresByBook.map(book => {
                            return (
                                <ImportJobChangeReportBodyItem
                                    key={book.bookId}
                                    bookId={book.bookId}
                                    bookTitle={book.bookName}
                                    handleBookClick={handleBookClick}
                                    procedures={book.procedureDetails}
                                    groups={groups}
                                />
                            );
                        })}
                    </div>
                )
            }
        />
    );
};

export default ImportJobChangeReportModal;
