import React, { useContext, useMemo } from 'react';
import { ColumnContext, TableContext } from './Contextes';
import { isHeaderCell, isReactFragment } from './utils';
import { NewDataTableHeaderCellProps, NewDataTableHeaderProps } from './types';

export const NewDataTableHeader = ({ children, headerClassName }: NewDataTableHeaderProps) => {
    const { orderedColumns, columnSettings } = useContext(TableContext);
    const { headers } = useMemo(() => {
        const headers: {
            headerClassName: null | string;
            templates: Record<string, { children: React.ReactNode; headerCellClassName: (columnId: string) => string }>;
            default: { children: React.ReactNode; headerCellClassName: (columnId: string) => string };
        } = {
            headerClassName: null,
            templates: {},
            default: null,
        };

        const core = (children: React.ReactNode) => {
            React.Children.forEach(children, child => {
                if (isReactFragment(child)) {
                    core(child.props.children);
                }

                if (isHeaderCell(child)) {
                    if (child.props.default === true) {
                        headers.default = {
                            children: child.props.children,
                            headerCellClassName: child.props.headerCellClassName,
                        };
                    } else {
                        const headerCellClassName = child.props.headerCellClassName;
                        if (Array.isArray(child.props.id)) {
                            for (const id of child.props.id) {
                                headers.templates[id] = {
                                    children: child.props.children,
                                    headerCellClassName: () => headerCellClassName,
                                };
                            }
                        } else {
                            headers.templates[child.props.id] = {
                                children: child.props.children,
                                headerCellClassName: () => headerCellClassName,
                            };
                        }
                    }
                }
            });
        };

        core(children);
        headers.headerClassName = headerClassName;

        return { headers };
    }, [children, headerClassName]);

    return (
        <thead className={headers.headerClassName}>
            <tr>
                {orderedColumns.map(column => {
                    const template = headers.templates[column] ?? headers.default;
                    return (
                        <th key={column} className={template?.headerCellClassName?.(column)} id={column}>
                            {template ? (
                                <ColumnContext.Provider value={column}>{template.children}</ColumnContext.Provider>
                            ) : (
                                columnSettings[column].displayName
                            )}
                        </th>
                    );
                })}
            </tr>
        </thead>
    );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const NewDataTableHeaderCell = (_props: NewDataTableHeaderCellProps) => null;
