import React, { useEffect, useCallback, useContext, useState } from 'react';

import { PrimaryButton } from 'oemiq-common';
import SmallSpinner from 'components/SmallSpinner';
import ReactModal from 'components/Shared/ReactModal';
import Table from 'components/Shared/Table/Table';
import TableConfiguration from './TableConfiguration';
import { MappingDefinitionsContext } from 'contexts/MappingDefinitionsContext';

import { requestGetProcedureMappingHistory } from 'api/RepairProcedureApi';
import { FormatDateToGMT } from 'helpers/DateHelper';
import useMappingWorkflowStatus from 'hooks/useMappingWorkflowStatus';
import { getMappingActionById } from 'hooks/mappingActions';
import useGetUserName from './useGetUserName';
import { NotificationsContext } from 'components/Shared/Notifications/Notifications';
import './ViewProcedureMappingHistoryModal.scss';

export interface HistoryModalProcedure {
    procedureId: number;
    procedureTitle: string;
    stageArea?: { isDeleted: boolean };
}

export interface ProcedureMappingHistoryModalProps {
    procedure: { procedureId: number; procedureTitle: string; stageArea?: { isDeleted: boolean } };
    resetProcedureId: () => void;
}

export type ProcedureMappingHistoryModalParams = ProcedureMappingHistoryModalProps;

type HistoryGroupItem = {
    groupId: number;
};

type HistoryItem = {
    userId: number;
    groupsIds: HistoryGroupItem[];
    oemIqSectionId: number;
    mappingWorkFlowStatusId: number;
    procedureMappingHistoryActionId: number;

    updateDate(updateDate: string): Date;
    createDate(createDate: string): Date;
};

const ViewProcedureMappingHistoryModal = ({ procedure, resetProcedureId }: ProcedureMappingHistoryModalParams) => {
    const [mappingHistory, setMappingHistory] = useState(null);
    const [rawMappingHistory, setRawMappingHistory] = useState(null);
    const [loading, setLoading] = useState(false);
    const { groups, types } = useContext(MappingDefinitionsContext);
    const { notifications } = useContext(NotificationsContext);
    const { getMappingWorkFlowStatusById } = useMappingWorkflowStatus();

    const { getUserNameById } = useGetUserName();

    const complementHistory = useCallback(
        (history: Array<HistoryItem>) => {
            const enrichedHistory = history.map(h => ({
                ...h,
                oemIqSectionName: types.find((t: { oemIqSectionId: number }) => t.oemIqSectionId == h.oemIqSectionId)
                    ?.text,
                groupsNames: h.groupsIds.map(
                    hg => groups.find((g: { regionId: number }) => g.regionId == hg.groupId)?.regionFriendlyName
                ),
                createDateGMT: FormatDateToGMT(h.createDate),
                updateDateGMT: FormatDateToGMT(h.updateDate),
                mappingWorkFlowStatusName: getMappingWorkFlowStatusById(h.mappingWorkFlowStatusId)
                    ?.mappingWorkFlowStatusName,
                mappingActionName: getMappingActionById(h.procedureMappingHistoryActionId)?.mappingActionName,
                userName: getUserNameById(h.userId),
            }));

            return enrichedHistory;
        },
        [groups, types, getMappingWorkFlowStatusById, getUserNameById]
    );

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                if (procedure != null) {
                    const history = await requestGetProcedureMappingHistory(
                        procedure.procedureId,
                        procedure.stageArea?.isDeleted
                    );
                    setRawMappingHistory(history);
                }
            } catch (error) {
                notifications.pushExceptionDanger(error);
            } finally {
                setLoading(false);
            }
        })();
    }, [procedure, notifications]);

    useEffect(() => {
        rawMappingHistory && setMappingHistory(complementHistory(rawMappingHistory));
    }, [rawMappingHistory, complementHistory]);

    const close = () => {
        resetProcedureId();
    };

    const headers = TableConfiguration;

    return (
        <>
            <ReactModal
                id="procedure-mapping-history-modal"
                className="modal-max-height mapping-history-modal"
                container={undefined}
                isOpen={procedure != null}
                toggle={close}
                headerComponent={
                    <div className="d-flex justify-content-between">
                        <span>Mapping History Table</span>{' '}
                        <span>
                            RPprocedureID: <strong>{procedure?.procedureId}</strong>
                        </span>
                    </div>
                }
                bodyComponent={
                    loading ? (
                        <SmallSpinner />
                    ) : (
                        mappingHistory !== null &&
                        procedure && (
                            <div>
                                <h5 className="mb-3">{procedure.procedureTitle}</h5>
                                <Table
                                    idKey="procedureMappingHistoryId"
                                    headers={headers}
                                    data={mappingHistory}
                                    className="p-0"
                                />
                            </div>
                        )
                    )
                }
                footerComponent={
                    <div className="text-right">
                        <PrimaryButton id="build-mapping-rule-modal-submit" className="me-3" onClick={close}>
                            Close
                        </PrimaryButton>
                    </div>
                }
            />
        </>
    );
};

export default React.memo(ViewProcedureMappingHistoryModal);
