import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { requestGetUserInfoApi } from 'api/UserApi';
import privilegesMap from './privilegesMap';
import apiConfig from 'api/config/ApiConfig';

export const AccessControlContext = React.createContext();

const AccessControl = ({ children }) => {
    const [userInfo, setUserInfo] = useState({});
    const userPrivileges = useRef({});
    const [config, setConfig] = useState(null);
    const [usePrototype, setUsePrototype] = useState(false);

    useEffect(() => {
        const init = async () => {
            setConfig(await apiConfig.get());
        };

        init();
    }, [setConfig]);

    const getUserInfo = useCallback(async () => {
        const user = await requestGetUserInfoApi();
        setUserInfo(user);
    }, []);

    useEffect(() => {
        getUserInfo();
    }, [getUserInfo]);

    const getCategoryPrivileges = (role, category) =>
        privilegesMap[role][category] ? privilegesMap[role][category] : [];

    const getCombinedCategoryPrivileges = useCallback(
        category => {
            const privileges = userPrivileges.current[category] || [
                ...new Set([].concat(...userInfo.roles.map(role => getCategoryPrivileges(role, category)))),
            ];
            !userPrivileges.current[category] &&
                (userPrivileges.current = { ...userPrivileges.current, [category]: privileges });
            return privileges;
        },
        [userInfo]
    );

    const getAccess = useCallback(
        resource => {
            if (userInfo.roles) {
                const res = resource.split('.');
                const category = res.shift();
                const action = res.join('.');
                const categoryAccess = getCombinedCategoryPrivileges(category);
                return categoryAccess.includes(action);
            }
            return false;
        },
        [userInfo, getCombinedCategoryPrivileges]
    );

    const hasAccess = useCallback((...resources) => resources.some(resource => getAccess(resource)), [getAccess]);

    const hasRole = useCallback(
        (...roles) => roles.some(role => userInfo.roles && userInfo.roles.includes(role)),
        [userInfo]
    );

    const enablePrototype = useCallback(
        setting => {
            if (typeof setting !== 'boolean') {
                return;
            } else {
                setUsePrototype(setting);
                return `Prototype features ${setting ? 'enabled' : 'disabled'}`;
            }
        },
        [setUsePrototype]
    );

    useEffect(() => {
        if (hasAccess('prototypes.togglePrototype') && config && config.environment?.includes('dev')) {
            window.usePrototype = usePrototype;
            window.enablePrototype = enablePrototype;
        }
    }, [usePrototype, config, hasAccess, enablePrototype]);

    const contextValue = useMemo(() => {
        return {
            userInfo,
            hasAccess,
            hasRole,
            usePrototype,
        };
    }, [hasAccess, hasRole, userInfo, usePrototype]);

    return <AccessControlContext.Provider value={contextValue}>{children}</AccessControlContext.Provider>;
};

export default AccessControl;
