import React from 'react';
import { ReactModal, Table } from 'oemiq-common';
import useViewImportJobHistoryModal from './useViewImportJobHistoryModal';
import SmallSpinner from 'components/SmallSpinner';

const ViewImportJobHistoryModal = ({ isOpen, toggle, oemId, oemName, handleOpenChangeReport }) => {
    const { importJobHistory, tableColumnDetails, noDataMessage, isLoading } = useViewImportJobHistoryModal(
        oemId,
        isOpen,
        handleOpenChangeReport
    );

    return (
        <ReactModal
            id="view-import-job-history-modal"
            className="modal-xl"
            headerComponent={
                <div style={{ fontSize: '1rem', lineHeight: 'normal' }}>
                    Import Job History for: <b>{oemName}</b>
                </div>
            }
            isOpen={isOpen}
            toggle={() => toggle()}
            bodyComponent={
                isLoading ? (
                    <SmallSpinner />
                ) : (
                    <div>
                        <Table
                            tableId="view-import-job-history-table"
                            isTableRowsClickable={false}
                            columnDetails={tableColumnDetails}
                            data={importJobHistory}
                            sortClickCallback={null}
                            rowClickCallback={null}
                            noDataMessage={noDataMessage}
                        />
                    </div>
                )
            }
            footerComponent={<i>Showing 5 most recent</i>}
        />
    );
};

export default ViewImportJobHistoryModal;
