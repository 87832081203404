import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LargeCheckbox } from 'oemiq-common';
import { partTypes } from 'helpers/PartTypes';

const FlagTableItem = ({ flag, dispositions, handleFlagRowClick, handleViewProcedureClick }) => {
    let disposition = '';
    if (flag.tagDispositionId !== null && dispositions) {
        const found = dispositions.find(d => d.value === flag.tagDispositionId);
        if (found) disposition = found.text;
    }

    return (
        <tr className="clickable" onClick={() => handleFlagRowClick(flag.procedureId)}>
            <td headers="checkbox" onClick={e => e.stopPropagation()}>
                <LargeCheckbox
                    id={`bulk-select-${flag.oneTimeUseFlagId}`}
                    label=""
                    className="large-checkbox-check mb-0"
                    checked={false}
                    onChange={() => console.log('When this checkbox is needed, need to implemented the logic')}
                    readOnly={false}
                />
            </td>
            <td headers="previewIcon">
                <button
                    id={`view-procedure-${flag.oneTimeUseFlagId}`}
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={e => handleViewProcedureClick(e, flag.procedureId)}>
                    <FontAwesomeIcon icon="eye" />
                </button>
            </td>
            <td headers="oneTimeUseFlagId">{flag.oneTimeUseFlagId}</td>
            <td headers="title">{flag.title}</td>
            <td headers="text">{flag.text}</td>
            <td headers="quantity">{flag.quantity}</td>
            <td headers="quantityCondition">{flag.quantityCondition}</td>
            <td headers="partNumber">{flag.partNumber}</td>
            <td headers="price">{flag.price}</td>
            <td headers="partTypeId">
                {flag.partTypeId !== null && partTypes && partTypes.find(p => p.value === flag.partTypeId).text}
            </td>
            <td headers="disposition">{disposition}</td>
            <td headers="procedureId">{flag.procedureId}</td>
            <td headers="procedureTitle">{flag.procedure.procedureTitle}</td>
            <td headers="groups">{flag.groups.join(', ')}</td>
            <td headers="type">{flag.type}</td>
        </tr>
    );
};

export default FlagTableItem;
