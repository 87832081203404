import React from 'react';
import { Table } from 'oemiq-common';
import useVehicleModelList from './useVehicleModelList';

const VehicleModelList = () => {
    const { models, tableColumnDetails, handleRowClick } = useVehicleModelList();

    return (
        <Table
            tableId="publisher-models-table"
            isTableRowsClickable={true}
            columnDetails={tableColumnDetails}
            data={models}
            sortClickCallback={null}
            rowClickCallback={handleRowClick}
        />
    );
};

export default VehicleModelList;
