import { useParams } from 'react-router-dom';
import { OemId } from 'helpers/OemId';
import VehicleList from './VehicleList';

const PublishVehicles = () => {
    const { oemId, modelId } = useParams();
    return <VehicleList oemId={oemId} modelId={modelId} showBooks={showPublisherWithBooks(parseInt(oemId))} />;
};

const showPublisherWithBooks = oemId =>
    [OemId.Chevrolet, OemId.Cadillac, OemId.Buick, OemId.GMC].includes(oemId) === false;

export default PublishVehicles;
