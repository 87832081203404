import { useContext, useCallback, useState, useMemo } from 'react';

import { LoadingContext } from 'components/Layout';
import { requestProceduresCountByBookIdApi, requestSearchProcedures } from 'api/RepairProcedureApi';

const searchOptions = [
    { key: 'type', text: 'Type', value: 'OemiqSectionName' },
    { key: 'title', text: 'Procedure Title', value: 'ProcedureTitle' },
    { key: 'body', text: 'Procedure Body', value: 'HTML' },
];

const useTagsAndFlagsSearch = (flags, tags) => {
    const [searchResults, setSearchResults] = useState(null);

    const { incrementLoading, decrementLoading } = useContext(LoadingContext);

    const handleSearch = useCallback(
        async (searchValue, searchOptionsSelections, bookId) => {
            if (searchValue === '') {
                setSearchResults(null);
                return;
            }
            const procedureCount = await requestProceduresCountByBookIdApi(bookId);
            const bookSearchQuery = {
                bookId: parseInt(bookId),
                searchText: searchValue,
                skip: 0,
                top: procedureCount,
                searchFields: searchOptionsSelections,
            };
            incrementLoading();
            setSearchResults(await requestSearchProcedures(bookSearchQuery));
            decrementLoading();
        },
        [incrementLoading, decrementLoading]
    );

    const search = useCallback(
        elements => {
            if (searchResults) {
                const searchProcIds = searchResults.map(sr => sr.procedureId);
                const searchedElements = elements.filter(e => searchProcIds.includes(e.procedureId));

                return searchedElements;
            } else {
                return elements;
            }
        },
        [searchResults]
    );

    const searchedFlags = useMemo(() => search(flags), [search, flags]);
    const searchedTags = useMemo(() => search(tags), [search, tags]);

    return { searchedFlags, searchedTags, handleSearch, searchOptions };
};

export default useTagsAndFlagsSearch;
