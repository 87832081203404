import { useState, useEffect, useContext, useRef, useCallback } from 'react';
import {
    requestHyundaiBulkSetShouldVehicleBeRun,
    requestHyundaiVehicleScrapeManagerOdata,
    requestHyundaiVehicleWithCountByStatus,
} from 'api/RepairProcedureApi';
import { LoadingContext } from 'components/Layout';
import { ToastContext } from 'components/ToastProvider';
import { HyundaiVehicle, IngestionButton, VehicleCountByStatus } from '../types';

const useHyundaiVehicles = notifications => {
    const [vehicles, setVehicles] = useState<HyundaiVehicle[]>([]);
    const [vehiclesCountByStatus, setVehiclesCountByStatus] = useState<VehicleCountByStatus>();
    const [loading, setLoading] = useState<boolean>(false);
    const [filters, setFilters] = useState<string>('');
    const [hasMore, setHasMore] = useState<boolean>(true);

    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const { showToast } = useContext<{ showToast }>(ToastContext);

    const page = useRef(0);
    const pageSize = 100;

    const loadVehicles = useCallback(async () => {
        if (!hasMore) return;
        try {
            setLoading(true);
            incrementLoading();

            const vehicles = await getVehicles(page.current, pageSize, filters);
            page.current++;

            if (vehicles.data.length < pageSize) setHasMore(false);

            if (vehicles.data.length > 0) {
                setVehicles(prevVehicles => {
                    const newVehicles = vehicles.data.filter(
                        veh => !prevVehicles.some(prevVehicle => prevVehicle.id === veh.id)
                    );
                    return [...prevVehicles, ...newVehicles];
                });
            }
        } catch (e) {
            notifications.pushExceptionDanger(e);
        } finally {
            setLoading(false);
            decrementLoading();
        }
    }, [incrementLoading, filters, setVehicles, notifications, decrementLoading, hasMore]);

    const loadVehicleCount = useCallback(async () => {
        const response = await getVehiclesCountByStatus(0, 0, filters);
        setVehiclesCountByStatus({
            new: response.data.newVehiclesCount,
            off: response.data.offVehiclesCount,
            on: response.data.onVehiclesCount,
        });
    }, [filters]);

    useEffect(() => {
        const getInitialVehicles = async () => {
            try {
                setLoading(true);
                incrementLoading();

                page.current = 0;
                setHasMore(true);

                const response = await getVehiclesCountByStatus(page.current, pageSize, filters);
                const vehicles = response.data.vehicles;
                setVehiclesCountByStatus({
                    new: response.data.newVehiclesCount,
                    off: response.data.offVehiclesCount,
                    on: response.data.onVehiclesCount,
                });
                if (vehicles.length < pageSize) {
                    setHasMore(false);
                }

                if (response.error) {
                    showToast(vehicles.error);
                }

                setVehicles(vehicles);
                page.current++;
            } catch (e) {
                notifications.pushExceptionDanger(e);
            } finally {
                setLoading(false);
                decrementLoading();
            }
        };
        getInitialVehicles();
    }, [decrementLoading, filters, incrementLoading, notifications, setVehicles, showToast]);

    const turnHyundaiVehiclesOn = useCallback(
        async (id: number) => {
            try {
                const ids = [];
                ids.push(id);
                await requestHyundaiBulkSetShouldVehicleBeRun(ids, true, true);
                setVehicles(vehicles =>
                    vehicles.map(v =>
                        ids.includes(v.id)
                            ? {
                                  ...v,
                                  shouldBeRun: true,
                                  hasBeenReviewed: true,
                              }
                            : v
                    )
                );
                loadVehicleCount();
            } catch (e) {
                notifications.pushExceptionDanger(e);
            }
        },
        [loadVehicleCount, notifications]
    );

    const turnHyundaiVehiclesOff = useCallback(
        async (id: number) => {
            try {
                const ids = [];
                ids.push(id);
                await requestHyundaiBulkSetShouldVehicleBeRun(ids, false, true);
                setVehicles(vehicles =>
                    vehicles.map(v =>
                        ids.includes(v.id)
                            ? {
                                  ...v,
                                  shouldBeRun: false,
                                  hasBeenReviewed: true,
                              }
                            : v
                    )
                );
                loadVehicleCount();
            } catch (e) {
                notifications.pushExceptionDanger(e);
            }
        },
        [loadVehicleCount, notifications]
    );

    const bulkTurnHyundaiVehiclesOn = useCallback(
        async (ids: number[]) => {
            try {
                await requestHyundaiBulkSetShouldVehicleBeRun(ids, true, true);
                setVehicles(vehicles =>
                    vehicles.map(v =>
                        ids.includes(v.id)
                            ? {
                                  ...v,
                                  shouldBeRun: true,
                                  hasBeenReviewed: true,
                              }
                            : v
                    )
                );
                loadVehicleCount();
            } catch (e) {
                notifications.pushExceptionDanger(e);
            }
        },
        [loadVehicleCount, notifications]
    );

    const bulkTurnHyundaiVehiclesOff = useCallback(
        async (ids: number[]) => {
            try {
                await requestHyundaiBulkSetShouldVehicleBeRun(ids, false, true);
                setVehicles(vehicles =>
                    vehicles.map(v =>
                        ids.includes(v.id)
                            ? {
                                  ...v,
                                  shouldBeRun: false,
                                  hasBeenReviewed: true,
                              }
                            : v
                    )
                );
                loadVehicleCount();
            } catch (e) {
                notifications.pushExceptionDanger(e);
            }
        },
        [loadVehicleCount, notifications]
    );

    const onSearch = (filter: string, radioValue: IngestionButton) => {
        const yearFilter = /^\d+$/.test(filter) ? `year eq ${filter}` : '';
        const modelNameFilter = `contains(modelName,'${filter}')`;
        const notesFilter = `contains(notes,'${filter}')`;

        const processFilter =
            radioValue === IngestionButton.NewButton
                ? 'and (hasBeenReviewed eq false)'
                : radioValue === IngestionButton.OnButton
                ? 'and (hasBeenReviewed eq true and shouldBeRun eq true)'
                : radioValue === IngestionButton.OffButton
                ? 'and (hasBeenReviewed eq true and shouldBeRun eq false)'
                : '';

        const parsedFilters = `isDeleted eq false and (${
            yearFilter && `${yearFilter} or`
        } ${modelNameFilter} or ${notesFilter}) ${processFilter}`;

        setFilters(parsedFilters);
    };

    return {
        vehicles,
        isLoading: loading,
        loadMoreCallback: loadVehicles,
        onSearch,
        vehiclesCountByStatus,
        turnHyundaiVehiclesOn,
        turnHyundaiVehiclesOff,
        bulkTurnHyundaiVehiclesOn,
        bulkTurnHyundaiVehiclesOff,
        hasMore,
    };
};

const getVehicles = async (page: number, pageSize: number, filter: string) => {
    try {
        const vehicles = await requestHyundaiVehicleScrapeManagerOdata({
            top: pageSize,
            skip: pageSize * page,
            orderBy: 'modelName, modelCode, Year desc',
            filter: filter ? filter : 'isDeleted eq false',
        });
        return { data: vehicles.value, error: '' };
    } catch (e) {
        return { data: [], error: e };
    }
};

const getVehiclesCountByStatus = async (page: number, pageSize: number, filter: string) => {
    try {
        const response = await requestHyundaiVehicleWithCountByStatus({
            top: pageSize,
            skip: pageSize * page,
            orderBy: 'modelName, modelCode, Year desc',
            filter: filter ? filter : 'isDeleted eq false',
            count: true,
        });
        return { data: response, error: '' };
    } catch (e) {
        return { data: [], error: e };
    }
};

export default useHyundaiVehicles;
