import React from 'react';
import { Input, PrimaryButton } from 'oemiq-common';
import usePlanReport from './usePlanReport';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import PlanTable from './PlanTable/PlanTable';

const PlansReport = () => {
    const {
        inputValue,
        handleInputChange,
        addToPlans,
        handleKeyPress,
        showErrorMessage,
        repairPlanIds,
        handleGenerateReportClick,
        removeFromPlans,
    } = usePlanReport();

    return (
        <div className="container-fluid mt-4">
            <div className="row">
                <div className="col">
                    <Input
                        id="plan-selection-input"
                        placeholder="Enter planId"
                        value={inputValue}
                        onChange={e => handleInputChange(e.currentTarget.value)}
                        onBlur={() => addToPlans(inputValue)}
                        onKeyPress={e => handleKeyPress(e)}
                    />
                    {showErrorMessage && <div className="text-danger mb-2">PlanID has already been added</div>}
                </div>
            </div>
            <div className="row mt-3">
                <div className="col">
                    <h6>Selected Plan IDs</h6>
                    {repairPlanIds.map(p => (
                        <div key={p} className="badge rounded-pill bg-primary me-2">
                            <span className="me-2">{p}</span>

                            <FontAwesomeIcon
                                id={`remove-${p}-planId`}
                                className="clickable"
                                icon={faTimes}
                                onClick={() => removeFromPlans(p)}
                            />
                        </div>
                    ))}
                </div>
                <div className="col d-flex justify-content-end">
                    <PrimaryButton id="generate-report" type="button" onClick={() => handleGenerateReportClick()}>
                        Generate Report
                    </PrimaryButton>
                </div>
            </div>

            <hr />

            <div className="mt-4">
                <PlanTable addToPlans={addToPlans} handleKeyPress={handleKeyPress} />
            </div>
        </div>
    );
};

export default PlansReport;
