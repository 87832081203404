export const publishTemplate = e => {
    return `You will publish ${e.item.year.yearValue} ${e.item.model.modelName} - ${
        e.item.trim.trimName !== null ? e.item.trim.trimName : 'Base'
    }`;
};

export const unpublishTemplate = e => {
    return `You will unpublish ${e.item.year.yearValue} ${e.item.model.modelName} - ${
        e.item.trim.trimName !== null ? e.item.trim.trimName : 'Base'
    }`;
};
