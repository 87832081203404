import UserModal from '../UserModal';
import { ReactModal } from 'oemiq-common';
import useCompanyUsers from './useCompanyUsers';
import MultiUserModal from '../MultiUserModal';
import { useCallback, useMemo, useRef } from 'react';
import Confirmation from 'components/Shared/Confirmation/Confirmation';
import BulkSendConfirmationModalBody from './BulkSendConfirmationModalBody';
import './CompanyUsers.scss';
import { messages } from '../../../Shared/Utils/Constants';
import CustomTooltip from '../../../Shared/Utils/CustomTooltip';
import MoveUserModal from '../MoveUserModal/MoveUserModal';
import ManageCustomersTable from '../ManageUsers/ManageCustomersTable';
import UsersActivationEmailFooter from '../ManageUsers/UsersActivationEmailFooter';
import GlobalSearchBar from 'components/Shared/GlobalSearchBar/GlobalSearchBar';

const CompanyUsers = () => {
    const {
        editUser,
        locationId,
        organizationId,
        showModal,
        isOpenUserDisable,
        isOpenSendForgotPasswordEmail,
        searchTerm,
        displayUsers,
        tableColumnDetails,
        userAccess,
        handleToggle,
        onSaveOrEdit,
        setShowUserDisableModal,
        handleDisableEnable,
        setShowUserForgotPasswordEmailModal,
        setSearchTerm,
        handleSendForgotPassword,
        sortClickCallback,
        isOpenDeleteUser,
        setIsOpenDeleteUser,
        handleDeletePendingUser,
        selectedUsers,
        setSelectedUsers,
        handleBulkSendActivationEmail,
        isOrgActive,
        isLocationActive,
        showMoveUserModal,
        handleShowMoveUserModal,
        editedMoveUser,
        hasMoveUserAccess,
        filterChangeCallback,
        selectedFilters,
    } = useCompanyUsers();

    const confirmRef = useRef();

    const openBulkSendConfirmationModal = useCallback(() => {
        confirmRef.current.open(handleBulkSendActivationEmail, {
            body: <BulkSendConfirmationModalBody selectedUsers={selectedUsers} />,
            title: 'Are you Sure?',
        });
    }, [handleBulkSendActivationEmail, selectedUsers]);

    const shouldShowFooter = useMemo(() => selectedUsers.length > 0, [selectedUsers]);

    return (
        <div className="manage-customers-layout-container">
            {/* Customer support doesn't have access to this, so hide it. This avoids 403 on API calls they can't do in this modal */}
            {(showModal && editUser && (
                <UserModal
                    onSaveOrEdit={onSaveOrEdit}
                    user={editUser}
                    companyId={locationId}
                    orgId={organizationId}
                    isOpen={showModal}
                    toggle={handleToggle}
                />
            )) || (
                <MultiUserModal
                    onSaveOrEdit={onSaveOrEdit}
                    orgId={organizationId}
                    isOpen={showModal}
                    toggle={handleToggle}
                    companyId={parseInt(locationId)}
                />
            )}
            {hasMoveUserAccess && showMoveUserModal && (
                <MoveUserModal
                    showMoveUserModal={showMoveUserModal}
                    handleShowMoveUserModal={handleShowMoveUserModal}
                    userDetails={editedMoveUser}
                    onSaveOrEdit={onSaveOrEdit}
                />
            )}
            <ReactModal
                id="confirm-user-disable-enable"
                isOpen={isOpenUserDisable}
                headerComponent={editUser ? <div>{'Are you sure?'}</div> : <></>}
                bodyComponent={
                    editUser ? (
                        editUser.isDeactivated ? (
                            <div>{'Activate ' + editUser.userName}</div>
                        ) : (
                            <div>{'Deactivate ' + editUser.userName}</div>
                        )
                    ) : (
                        <></>
                    )
                }
                footerComponent={
                    <div>
                        <button
                            type="button"
                            className="btn btn-sm btn-danger h-100"
                            onClick={() => setShowUserDisableModal(false)}>
                            Cancel
                        </button>
                        <button
                            id="confirm-enable-disable"
                            type="button"
                            className="btn btn-sm btn-primary ms-3 h-100"
                            onClick={handleDisableEnable}>
                            Confirm
                        </button>
                    </div>
                }
            />
            <ReactModal
                id="confirm-pending-user-delete"
                isOpen={isOpenDeleteUser}
                headerComponent={editUser ? <div>{'Are you sure?'}</div> : <></>}
                bodyComponent={
                    editUser && (
                        <div>{`You are about to delete the User ${editUser.firstName} ${editUser.lastName}. Please confirm.`}</div>
                    )
                }
                footerComponent={
                    <div>
                        <button
                            type="button"
                            className="btn btn-sm btn-danger h-100"
                            onClick={() => setIsOpenDeleteUser(false)}>
                            Cancel
                        </button>
                        <button
                            id="confirm-enable-disable"
                            type="button"
                            className="btn btn-sm btn-primary ms-3 h-100"
                            onClick={handleDeletePendingUser}>
                            Confirm
                        </button>
                    </div>
                }
            />
            <ReactModal
                id="send-forgot-password"
                isOpen={isOpenSendForgotPasswordEmail}
                headerComponent={editUser ? <div>{'Are you sure?'}</div> : <></>}
                bodyComponent={
                    editUser ? (
                        <div>{'Send ' + editUser.userName + ' a forgot email password to ' + editUser.email + '?'}</div>
                    ) : (
                        <></>
                    )
                }
                footerComponent={
                    <div>
                        <button
                            type="button"
                            className="btn btn-sm btn-danger h-100"
                            onClick={() => setShowUserForgotPasswordEmailModal(false)}>
                            Cancel
                        </button>
                        <button
                            id="send-forgot-password-confirm"
                            type="button"
                            className="btn btn-sm btn-primary ms-3 h-100"
                            onClick={handleSendForgotPassword}>
                            Confirm
                        </button>
                    </div>
                }
            />
            <div className="position-sticky w-100 p-2 manage-customers-nav">
                <div className="nav-bar mt-3">
                    <div className="buttons-container">
                        {/* If user is customer support, hide create new user button */}
                        {!userAccess.hasCustomerSupportRole && (
                            <CustomTooltip
                                title={
                                    !isOrgActive
                                        ? messages.organizationDisabledActionNotAllowed
                                        : !isLocationActive
                                        ? messages.companyDisabledActionNotAllowed
                                        : ''
                                }>
                                <span>
                                    <button
                                        id="company-new-user"
                                        type="button"
                                        className={`btn btn-sm btn-primary h-100 ${
                                            !isOrgActive || !isLocationActive ? 'cursor-not-allowed' : ''
                                        }`}
                                        onClick={() => handleToggle()}
                                        disabled={!isOrgActive || !isLocationActive}>
                                        Create New User
                                    </button>
                                </span>
                            </CustomTooltip>
                        )}
                    </div>

                    <div className="col-3 ml-auto">
                        <GlobalSearchBar inPageSearchCallback={setSearchTerm} />
                    </div>
                </div>
            </div>
            <div className="table-container">
                <ManageCustomersTable
                    tableId="company-users-table"
                    tableClassName="mb-5"
                    isTableRowsClickable={false}
                    columnDetails={tableColumnDetails}
                    data={displayUsers}
                    idKey={'userId'}
                    sortClickCallback={sortClickCallback}
                    rowClickCallback={null}
                    selected={selectedUsers}
                    setSelected={setSelectedUsers}
                    ellipsisColumnIndex={2}
                    enableSelectingRows={true}
                    filterChangeCallback={filterChangeCallback}
                    selectedFilters={selectedFilters}
                    options={{
                        checkboxes: true,
                        disableCheckboxFn: e => {
                            return e.emailConfirmed || e.isDeactivated;
                        },
                    }}
                />
                <UsersActivationEmailFooter show={shouldShowFooter} onSend={openBulkSendConfirmationModal} />
            </div>
            <Confirmation ref={confirmRef} />
        </div>
    );
};

export default CompanyUsers;
