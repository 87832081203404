import { useState, useEffect, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { requestCreateOneTimeUseTag, requestGetTagById } from 'api/RepairProcedureApi';
import { colors, /*validatePath, getValidIds,*/ getCssPath } from 'helpers/TaggerHelper';
import { ToastContext } from 'components/ToastProvider';
import { LoadingContext } from 'components/Layout';

const useAddNewTag = (setIsAddingNewTag, setTags) => {
    const [tagName, setTagName] = useState('');
    const [quantity, setQuantity] = useState('');
    const [quantityCondition, setQuantityCondition] = useState('none');
    const [partNumber, setPartNumber] = useState('');
    const [price, setPrice] = useState('');
    const [partType, setPartType] = useState(3);
    const [tagText, setTagText] = useState('');
    const [note, setNote] = useState('');
    const [selectedImages, setSelectedImages] = useState([]);
    const [highlightStartPath, setHighlightStartPath] = useState('');
    const [highlightEndPath, setHighlightEndPath] = useState('');
    const [isSelectingImg, setIsSelectingImage] = useState(false);
    const { procedureId } = useParams();
    const [isTitleBlank, setIsTitleBlank] = useState(false);
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const { showToast } = useContext(ToastContext);

    const handleDocumentClick = useCallback(
        event => {
            if (isSelectingImg) {
                const currentTarget = event.target;
                if (currentTarget.tagName === 'IMG') {
                    let currentImages = [...selectedImages];
                    currentImages.push(currentTarget.attributes.src.value);
                    setSelectedImages(currentImages);

                    setIsSelectingImage(false);
                }
                if (currentTarget.tagName === 'DIV' && currentTarget.classList.contains('object-wrapper')) {
                    let currentImages = [...selectedImages];
                    let imageObjects = currentTarget.querySelectorAll('object');
                    let src = imageObjects[0].data;
                    currentImages.push(src);
                    setSelectedImages(currentImages);

                    setIsSelectingImage(false);
                }
            }
        },
        [isSelectingImg, selectedImages]
    );

    useEffect(() => {
        setIsTitleBlank(tagName === '');
    }, [tagName]);

    useEffect(() => {
        let objects = document.querySelectorAll('object.svg-image');
        for (let i = 0; i < objects.length; i++) {
            if (objects[i].parentNode.classList.contains('object-wrapper')) continue;
            const wrapper = document.createElement('div');
            objects[i].parentNode.insertBefore(wrapper, objects[i]);
            objects[i].classList.add('pe-none');
            wrapper.appendChild(objects[i]);
            wrapper.classList.add('object-wrapper');
        }
        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [isSelectingImg, handleDocumentClick]);

    const handleRemoveImageClick = img => {
        let newSelectedImages = selectedImages.filter(i => i !== img);
        setSelectedImages(newSelectedImages);
    };

    const handleSelectTagRegion = () => {
        let selection = document.getSelection();
        if (!selection) return;

        const range = selection.getRangeAt(0);

        let start = getCssPath(range.startContainer.parentNode);
        let end = start.includes('li') || start.includes('td') ? start : getCssPath(range.endContainer.parentNode);

        const actualRange = document.createRange();
        actualRange.setStartBefore(document.querySelector(start), 0);
        actualRange.setEndAfter(document.querySelector(end), 0);

        // let validIds = getValidIds(procedureId);
        // let validPath = validatePath(start, validIds) && validatePath(end, validIds);
        const clientRect = actualRange.getBoundingClientRect();
        const highlight = document.getElementById('highlight');
        const spacing = 3;
        highlight.style.pointerEvents = 'none';
        highlight.style.left = `${clientRect.x + window.scrollX - spacing}px`;
        highlight.style.top = `${clientRect.y + window.scrollY - spacing}px`;
        // prettier-ignore
        highlight.style.width = `${clientRect.width + (2 * spacing)}px`;
        // prettier-ignore
        highlight.style.height = `${clientRect.height + (2 * spacing)}px`;
        setHighlightStartPath(start);
        setHighlightEndPath(end);
    };

    const clearHighlight = () => {
        const highlight = document.getElementById('highlight');
        highlight.style.left = '0';
        highlight.style.top = '0';
        highlight.style.width = '0';
        highlight.style.height = '0';
    };

    const handleClearTagRegion = () => {
        clearHighlight();
        setHighlightStartPath('');
        setHighlightEndPath('');
    };

    const handleSaveClick = async () => {
        let tagColor = colors[Math.floor(Math.random() * colors.length)];
        let createOneTimeUseTagCommand = [
            {
                procedureId: parseInt(procedureId),
                title: tagName,
                quantity: parseFloat(quantity),
                quantityCondition: quantityCondition,
                partNumber: partNumber,
                price: parseFloat(price),
                text: tagText,
                colorHex: tagColor,
                note: note,
                workFlowStatusid: 1,
                oneTimeUsePartTypeid: parseInt(partType),
                startPath: highlightStartPath,
                endPath: highlightEndPath,
                tagImages: selectedImages,
            },
        ];

        try {
            incrementLoading();
            let newTagIds = await requestCreateOneTimeUseTag(createOneTimeUseTagCommand);
            if (newTagIds.length) {
                const id = newTagIds[0];
                const newTag = await requestGetTagById(id);
                setTags(prev => [...prev, newTag]);
            }

            handleClearTagRegion();
            setIsAddingNewTag(false);
        } catch (error) {
            showToast(error);
        } finally {
            decrementLoading();
        }
    };

    return {
        tagName,
        setTagName,
        quantity,
        setQuantity,
        quantityCondition,
        setQuantityCondition,
        partNumber,
        setPartNumber,
        price,
        setPrice,
        partType,
        setPartType,
        isSelectingImg,
        setIsSelectingImage,
        selectedImages,
        handleRemoveImageClick,
        tagText,
        setTagText,
        handleSelectTagRegion,
        handleClearTagRegion,
        note,
        setNote,
        handleSaveClick,
        isTitleBlank,
    };
};

export default useAddNewTag;
