import { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { OlsMapperListContext } from 'contexts/MapperListContext';

const sortFunc = sortColumn => {
    return sortColumn.direction === 'A'
        ? (a, b) => (a[sortColumn.key] < b[sortColumn.key] ? -1 : 1)
        : (a, b) => (a[sortColumn.key] < b[sortColumn.key] ? 1 : -1);
};

const PAGE_AMOUNT = 150;

const useOldMapperTable = (tab, procedures) => {
    const [bulkSelections, setBulkSelections] = useState([]);
    const [groupTypeModalBulkType, setGroupTypeModalBulkType] = useState(null);
    const [sortColumn, setSortColumn] = useState(null);
    const [visibleIndex, setVisibleIndex] = useState(PAGE_AMOUNT);
    const [isLoading, setIsLoading] = useState(false);
    const sortedProcedures = useMemo(() => {
        if (!sortColumn) return [...procedures].slice(0, visibleIndex);

        return [...procedures].sort(sortFunc(sortColumn)).slice(0, visibleIndex);
    }, [procedures, sortColumn, visibleIndex]);

    const resetVisibleIndex = useCallback(() => {
        setVisibleIndex(PAGE_AMOUNT);
    }, []);

    const { appliedFilters, searchResults } = useContext(OlsMapperListContext);

    const resetBulkSelection = useCallback(() => {
        setBulkSelections([]);
    }, []);

    useEffect(() => {
        resetBulkSelection();
        resetVisibleIndex();
    }, [tab, searchResults, appliedFilters, resetBulkSelection, resetVisibleIndex]);

    const handleBulkSelection = useCallback(async procedureId => {
        setBulkSelections(currBulk => {
            if (currBulk.includes(procedureId)) return currBulk.filter(b => b !== procedureId);
            else return [...currBulk, procedureId];
        });
    }, []);

    const handleSelectAll = useCallback(
        procedures => {
            if (bulkSelections.length === procedures.length) setBulkSelections([]);
            else setBulkSelections(procedures.map(p => p.procedureId));
        },
        [bulkSelections.length]
    );

    const handleSortColumnClick = useCallback(
        key => {
            if (sortColumn === null || sortColumn.key !== key) setSortColumn({ key: key, direction: 'A' });
            else if (sortColumn.key === key && sortColumn.direction === 'A')
                setSortColumn({ key: key, direction: 'D' });
            else setSortColumn(null);
        },
        [sortColumn]
    );

    const loadMore = () => {
        if (!isLoading && visibleIndex < procedures.length) {
            setIsLoading(true);
            setVisibleIndex(visibleIndex + PAGE_AMOUNT);
            setIsLoading(false);
        }
    };

    return {
        bulkSelections,
        handleBulkSelection,
        resetBulkSelection,
        handleSelectAll,
        sortColumn,
        handleSortColumnClick,
        sortedProcedures,
        groupTypeModalBulkType,
        setGroupTypeModalBulkType,
        isLoading,
        loadMore,
    };
};

export default useOldMapperTable;
