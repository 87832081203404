import { ItemReturnEvent } from 'components/Shared/Table/types';
import { useCallback, useMemo } from 'react';
import { RefreshPublisherTableActionTypes } from './types';

const useTableActions = (
    publishBooks: (bookIds: number[]) => void,
    reloadStatsForBookId: (bookIds: number[]) => void
) => {
    const publishBook = useCallback(
        async ({ id }: ItemReturnEvent) => {
            publishBooks([parseInt(id)]);
        },
        [publishBooks]
    );
    const refreshBookStats = useCallback(
        ({ id }: ItemReturnEvent) => {
            reloadStatsForBookId([parseInt(id)]);
        },
        [reloadStatsForBookId]
    );

    return useMemo(
        () => ({
            [RefreshPublisherTableActionTypes.PublishBook]: publishBook,
            [RefreshPublisherTableActionTypes.RefreshBookStats]: refreshBookStats,
        }),
        [publishBook, refreshBookStats]
    );
};

export default useTableActions;
