import { useState, useContext, useEffect } from 'react';
import { LoadingContext } from 'components/Layout';
import { ToastContext } from 'components/ToastProvider';
import { requestYears, requestModelsForOem, requestRegions } from 'api/vehicleInfo';
import { requestSavePositionStatement } from 'api/RepairProcedureApi';

const useAddPositionStatementModal = (toggle, oemId, onSave) => {
    const [title, setTitle] = useState('');
    const [isOem, setIsOem] = useState(true);
    const [saveEnabled, setIsSaveEnabled] = useState(false);

    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const { showToast } = useContext(ToastContext);

    const [regionOptions, setRegionOptions] = useState([]);
    const [regions, setRegions] = useState([]);
    const [years, setYears] = useState([]);
    const [models, setModels] = useState([]);
    const [yearOptions, setYearOptions] = useState({});
    const [modelOptions, setModelOptions] = useState({});
    const [fileData, setFileData] = useState(null);

    useEffect(() => {
        const getVehicleData = async () => {
            try {
                incrementLoading();
                const yearData = await requestYears();
                const modelData = await requestModelsForOem(oemId);
                let regionData = await requestRegions();
                regionData = regionData.filter(rd => rd.regionId !== 40); //Filter out position statement group as this is auto mapped in the command handler

                setYearOptions(
                    yearData.map(y => {
                        return { label: y.yearValue, value: y.yearId };
                    })
                );
                setModelOptions(
                    modelData.map(m => {
                        return { label: m.modelName, value: m.modelId };
                    })
                );
                setRegionOptions(
                    regionData.map(r => {
                        return { label: r.regionFriendlyName, value: r.regionId };
                    })
                );
            } catch (error) {
                showToast(error);
            } finally {
                decrementLoading();
            }
        };
        getVehicleData();
    }, [showToast, incrementLoading, decrementLoading, oemId]);

    //Handle Enable save
    useEffect(() => {
        if (!oemId || !fileData || !regions || !title) {
            setIsSaveEnabled(false);
        } else if (!isOem && (!years || years.length < 1) && (!models || models.length < 1)) setIsSaveEnabled(false);
        else setIsSaveEnabled(true);
    }, [oemId, isOem, fileData, title, years, models, regions]);

    const handleToggle = () => {
        clear();
        toggle();
    };

    const handleSave = async () => {
        const form = new FormData();
        form.append('File', fileData, fileData.name);
        form.append('Title', `${title}`);
        form.append('OemId', `${oemId}`);
        if (!isOem) {
            for (let i = 0; i < years.length; i++) form.append('YearIds', `${years[i].value}`);
            for (let i = 0; i < models.length; i++) form.append('ModelIds', `${models[i].value}`);
        }
        for (let i = 0; i < regions.length; i++) form.append('Regions', `${regions[i].value}`);

        try {
            incrementLoading();
            await requestSavePositionStatement(oemId, form);
            clear();
            onSave();
        } catch (error) {
            showToast(error);
        } finally {
            handleToggle();
            decrementLoading();
        }
    };

    const clear = () => {
        setFileData(null);
        setTitle('');
        setRegions([]);
        setYears([]);
        setModels([]);
        setIsOem(false);
    };

    const handleIsOemToggle = () => {
        setYears([]);
        setModels([]);
        setIsOem(!isOem);
    };

    const handleYearChange = e => {
        setYears(e);
    };

    const handleModelChange = e => {
        setModels(e);
    };

    const handleRegionChange = e => {
        setRegions(e);
    };

    return {
        handleToggle,
        title,
        setTitle,
        isOem,
        handleIsOemToggle,
        setIsOem,
        yearOptions,
        handleYearChange,
        years,
        modelOptions,
        handleModelChange,
        models,
        regionOptions,
        handleRegionChange,
        regions,
        handleSave,
        saveEnabled,
        setFileData,
    };
};

export default useAddPositionStatementModal;
