import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { LargeCheckbox } from 'oemiq-common';
import { FormatDateToGMT } from 'helpers/DateHelper';
import { TABLE_COLUMN_ENUM } from 'helpers/BulkEditTableHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTrash,
    faExternalLinkAlt,
    faExclamationTriangle,
    faCamera,
    faTrashArrowUp,
    faRotateBack,
} from '@fortawesome/free-solid-svg-icons';
import InputCell from './InputCell';
import DropdownCell from './DropdownCell';
import { TaggingWorkFlowStatusOptions } from 'helpers/FlagTagHelper';
import { FLAG_TAG_MODE, getFlagTagMode } from '../../useBulkEdit';
import './style.scss';

const BulkEditTableItem = ({
    item,
    rowLinkIndex,
    handleOpenProcedureModal,
    partTypes,
    updateItem,
    quantityConditions,
    groups,
    types,
    checked,
    onCheckboxClick,
    flagModeOn,
    onNeedsAttentionClick,
    onCheckboxShiftButtonKeyDown,
    onCheckboxShiftButtonKeyUp,
    onDisposeItem,
    onRestoreItem,
    isLifecycleChange,
}) => {
    const statusOptions = TaggingWorkFlowStatusOptions;
    const [title, setTitle] = useState(item.title);
    const [procId, setProcId] = useState(item.procedureId);
    const [link, setLink] = useState(item.oneTimeUseTagLink);
    const [text, setText] = useState(item.text);
    const [quantity, setQuantity] = useState(item.quantity);
    const [partNumber, setPartNumber] = useState(item.partNumber);
    const [price, setPrice] = useState(item.price);
    const [willBeDisposed, setWillBeDisposed] = useState(item.isDeleted);
    const flagTagMode = useMemo(
        () => getFlagTagMode(flagModeOn, item, isLifecycleChange(item)),
        [flagModeOn, isLifecycleChange, item]
    );

    useEffect(() => {
        setTitle(item.title);
        setProcId(item.procedureId);
        setText(item.text);
        setLink(item.oneTimeUseTagLink);
        setQuantity(item.quantity);
        setPartNumber(item.partNumber);
        setPrice(item.price);
        setWillBeDisposed(item.isDeleted);
    }, [item]);

    const handleSubmit = useCallback(() => {
        // set empty string to null
        const quantityState = quantity === '' ? null : quantity;
        setQuantity(quantityState);
        if (
            title !== item.title ||
            procId !== item.procedureId ||
            text !== item.text ||
            quantity !== item.quantity ||
            partNumber !== item.partNumber ||
            price !== item.price
        ) {
            updateItem({ ...item, title, procId, text, quantity: quantityState, partNumber, price });
        }
    }, [item, partNumber, price, quantity, text, title, procId, updateItem]);

    const handleClickEdit = useCallback(
        fieldId => {
            const editedFields = new Set(item.editedFields);
            editedFields.add(fieldId);
            updateItem({ ...item, editedFields });
        },
        [item, updateItem]
    );

    const handleCheckboxKeyDown = useCallback(
        e => {
            if (e.key === 'Shift') {
                onCheckboxShiftButtonKeyDown();
            }
        },
        [onCheckboxShiftButtonKeyDown]
    );

    const handleCheckboxKeyUp = useCallback(
        e => {
            if (e.key === 'Shift') {
                onCheckboxShiftButtonKeyUp();
            }
        },
        [onCheckboxShiftButtonKeyUp]
    );

    const isLiveUpdate = item?.procedureDetail?.isLatest && item?.procedureDetail?.isPublished;

    // the item is about to be disposed:
    // render all its properties as text fields and hide the checkbox
    if (willBeDisposed || !flagTagMode.canBeEdited) {
        let qc = '';
        if (item.quantityCondition && quantityConditions) {
            const res = quantityConditions.find(qc => qc.value === item.quantityCondition);
            if (res) {
                qc = res.text;
            }
        }
        let pt = '';
        if (item.oneTimeUsePartTypeId && partTypes) {
            const res = partTypes.find(pt => pt.value === item.oneTimeUsePartTypeId);
            if (res) {
                pt = res.text;
            }
        }
        return (
            <tr className={flagTagMode.background}>
                <td>
                    <div className="d-flex flex-column">
                        <div className="d-flex align-items-center my-2">
                            <LargeCheckbox
                                id={`bulk-select-${item.id}`}
                                checked={checked}
                                onChange={() => onCheckboxClick(item)}
                                onKeyDown={handleCheckboxKeyDown}
                                onKeyUp={handleCheckboxKeyUp}
                            />
                            <div className="d-flex">
                                {flagTagMode.canBeRestored && ( // Restore
                                    <button
                                        title="restore"
                                        type="button"
                                        id={`btn-restore-${item.id}`}
                                        onClick={() => onRestoreItem(item, true)}
                                        className="btn btn-success btn-sm me-2 ms-2">
                                        <FontAwesomeIcon className="clickable" icon={faTrashArrowUp} />
                                    </button>
                                )}
                                {flagTagMode === FLAG_TAG_MODE.WillBeActive && ( // Undo restore
                                    <button
                                        title="undo restore"
                                        type="button"
                                        id={`btn-restore-${item.id}`}
                                        onClick={() => onRestoreItem(item, false)}
                                        className="btn btn-primary btn-sm me-2 ms-2">
                                        <FontAwesomeIcon className="clickable" icon={faRotateBack} />
                                    </button>
                                )}
                                {flagTagMode === FLAG_TAG_MODE.WillBeDisposed && ( // Undo Dispose
                                    <button
                                        title="undo dispose"
                                        type="button"
                                        id={`btn-undo-dispose-${item.id}`}
                                        onClick={() => onDisposeItem(item, false)}
                                        className="btn btn-primary btn-sm me-2 ms-2">
                                        <FontAwesomeIcon className="clickable" icon={faRotateBack} />
                                    </button>
                                )}
                            </div>
                        </div>
                        {isLiveUpdate && (
                            <div className="d-flex align-items-center my-2">
                                <span className="badge rounded-pill text-bg-success">Live Update</span>
                            </div>
                        )}
                    </div>
                </td>
                <td>
                    <span>{item.procedure.booksForProcedure.map(bp => bp.book.bookName).join(', ')}</span>
                </td>
                {!flagModeOn && (
                    <td className="column-image">
                        <BulkTaggerImageCell item={item} />
                    </td>
                )}
                <td className="column-status">
                    <BulkTaggerStatusCell
                        item={item}
                        flagModeOn={flagModeOn}
                        statusOptions={statusOptions}
                        onNeedsAttentionClick={onNeedsAttentionClick}
                    />
                </td>
                <td>{procId}</td>
                <td className="column-procedure-title">
                    <BulkTaggerProcedureTitleCell item={item} handleOpenProcedureModal={handleOpenProcedureModal} />
                </td>
                {flagModeOn && <td>{item.flagLocation}</td>}
                <td>{title}</td>
                {!flagModeOn && <td className="column-link">{link}</td>}
                <td>{text}</td>
                {flagModeOn && (
                    <>
                        <td>{item.oneTimeUseFlagTerm && item.oneTimeUseFlagTerm.id}</td>
                        <td>{item.oneTimeUseFlagTerm && item.oneTimeUseFlagTerm.term}</td>
                    </>
                )}
                <td>{quantity}</td>
                <td>{qc}</td>
                <td>{partNumber}</td>
                <td>{price}</td>
                <td>{pt}</td>
                <td>
                    {item.procedure.stageArea.groups
                        .map(g => g.groupId)
                        .map(id => (groups.has(id) ? groups.get(id).regionFriendlyName : id))
                        .join(', ')}
                </td>
                <td>{convertTypeToName(item.procedure.stageArea.type.typeId, types)}</td>
                <td>{FormatDateToGMT(item.updateDate)}</td>
                <td>{FormatDateToGMT(item.createDate)}</td>
            </tr>
        );
    }

    // the item is allowed to be edited
    return (
        <tr id={`bulk-edit-tag-table-row-${rowLinkIndex}`} className={flagTagMode.background}>
            <td>
                <div className="d-flex flex-column">
                    <div className="d-flex align-items-center my-2">
                        <LargeCheckbox
                            id={`bulk-select-${item.id}`}
                            checked={checked}
                            onChange={() => onCheckboxClick(item)}
                            onKeyDown={handleCheckboxKeyDown}
                            onKeyUp={handleCheckboxKeyUp}
                        />
                        {flagTagMode.canBeDisposed && ( // Dispose
                            <button
                                title={flagModeOn ? 'dispose' : 'inactivate'}
                                type="button"
                                id={`btn-dispose-${item.id}`}
                                onClick={() => onDisposeItem(item, true)}
                                className="btn btn-danger btn-sm me-2 ms-2">
                                <FontAwesomeIcon className="clickable" icon={faTrash} />
                            </button>
                        )}
                    </div>
                    {isLiveUpdate && (
                        <div className="d-flex align-items-center">
                            <span className="badge rounded-pill text-bg-success">Live Update</span>
                        </div>
                    )}
                </div>
            </td>
            <td>
                <span>{item.procedure.booksForProcedure.map(bp => bp.book.bookName).join(', ')}</span>
            </td>
            {!flagModeOn && (
                <td className="column-image">
                    <BulkTaggerImageCell item={item} />
                </td>
            )}
            {!flagModeOn ? (
                <DropdownCell
                    value={item.workFlowStatusId}
                    options={statusOptions}
                    selection
                    onChange={({ value }) => updateItem({ ...item, workFlowStatusId: value })}
                    edited={item.editedFields && item.editedFields.has(TABLE_COLUMN_ENUM.STATUS)}
                    setEdited={() => handleClickEdit(TABLE_COLUMN_ENUM.STATUS)}
                    disabled={willBeDisposed}
                />
            ) : (
                <td className="column-status">
                    <BulkTaggerStatusCell
                        item={item}
                        flagModeOn={flagModeOn}
                        statusOptions={statusOptions}
                        onNeedsAttentionClick={onNeedsAttentionClick}
                    />
                </td>
            )}
            <td>{procId}</td>
            <td className="column-procedure-title">
                <BulkTaggerProcedureTitleCell item={item} handleOpenProcedureModal={handleOpenProcedureModal} />
            </td>
            {flagModeOn && <td className="column-flag-location">{item.flagLocation}</td>}
            <InputCell
                value={title}
                multiline
                onChange={setTitle}
                onSubmit={handleSubmit}
                edited={item.editedFields && item.editedFields.has(TABLE_COLUMN_ENUM.TITLE)}
                setEdited={() => handleClickEdit(TABLE_COLUMN_ENUM.TITLE)}
                disabled={willBeDisposed}
            />
            {!flagModeOn && <td className="column-link">{link}</td>}
            <InputCell
                value={text}
                multiline
                onChange={setText}
                onSubmit={handleSubmit}
                edited={item.editedFields && item.editedFields.has(TABLE_COLUMN_ENUM.TEXT)}
                setEdited={() => handleClickEdit(TABLE_COLUMN_ENUM.TEXT)}
                disabled={willBeDisposed}
            />
            {flagModeOn && (
                <>
                    <td>{item.oneTimeUseFlagTerm && item.oneTimeUseFlagTerm.id}</td>
                    <td>{item.oneTimeUseFlagTerm && item.oneTimeUseFlagTerm.term}</td>
                </>
            )}

            <InputCell
                type="number"
                min="0"
                value={quantity}
                onChange={setQuantity}
                onSubmit={handleSubmit}
                edited={item.editedFields && item.editedFields.has(TABLE_COLUMN_ENUM.QUANTITY)}
                setEdited={() => handleClickEdit(TABLE_COLUMN_ENUM.QUANTITY)}
                disabled={willBeDisposed}
            />
            <DropdownCell
                value={item.quantityCondition ? item.quantityCondition : ''}
                options={quantityConditions}
                onChange={e => updateItem({ ...item, quantityCondition: e.value })}
                edited={item.editedFields && item.editedFields.has(TABLE_COLUMN_ENUM.QUANTITY_CONDITION)}
                setEdited={() => handleClickEdit(TABLE_COLUMN_ENUM.QUANTITY_CONDITION)}
                disabled={willBeDisposed}
                useValuePropDirectly={true}
            />
            <InputCell
                value={partNumber}
                onChange={setPartNumber}
                onSubmit={handleSubmit}
                edited={item.editedFields && item.editedFields.has(TABLE_COLUMN_ENUM.PART_NUMBER)}
                setEdited={() => handleClickEdit(TABLE_COLUMN_ENUM.PART_NUMBER)}
                disabled={willBeDisposed}
            />
            <InputCell
                type="number"
                min="0"
                step="0.25"
                value={price}
                onChange={setPrice}
                onSubmit={handleSubmit}
                edited={item.editedFields && item.editedFields.has(TABLE_COLUMN_ENUM.PRICE)}
                setEdited={() => handleClickEdit(TABLE_COLUMN_ENUM.PRICE)}
                disabled={willBeDisposed}
            />
            <DropdownCell
                value={item.oneTimeUsePartTypeId}
                options={partTypes}
                onChange={e => updateItem({ ...item, oneTimeUsePartTypeId: e.value })}
                edited={item.editedFields && item.editedFields.has(TABLE_COLUMN_ENUM.PART_TYPE_ID)}
                setEdited={() => handleClickEdit(TABLE_COLUMN_ENUM.PART_TYPE_ID)}
                disabled={willBeDisposed}
            />
            <td>
                {item.procedure.stageArea.groups
                    .map(g => g.groupId)
                    .map(id => (groups.has(id) ? groups.get(id).regionFriendlyName : id))
                    .join(', ')}
            </td>
            <td>{convertTypeToName(item.procedure.stageArea.type.typeId, types)}</td>
            <td>{FormatDateToGMT(item.updateDate)}</td>
            <td>{FormatDateToGMT(item.createDate)}</td>
        </tr>
    );
};

export default React.memo(BulkEditTableItem);

const convertTypeToName = (id, types) => {
    const foundType = types.find(t => t.oemIqSectionId === id);
    return foundType ? foundType.oemIqSectionName : id;
};

const BulkTaggerStatusCell = ({ item, flagModeOn, statusOptions, onNeedsAttentionClick }) => {
    if (flagModeOn)
        return (
            <div className="d-flex justify-content-center">
                <FontAwesomeIcon
                    onClick={() => onNeedsAttentionClick(item)}
                    className={`${item.needsAttention === true ? '' : 'opacity-15'} clickable fa-2x`}
                    icon={faExclamationTriangle}
                />
            </div>
        );
    if (item.workFlowStatusId && statusOptions) {
        const statusOptionItem = statusOptions.find(o => o.value === item.workFlowStatusId);
        if (statusOptionItem) return <div>{statusOptionItem.text}</div>;
    }
};

const BulkTaggerImageCell = ({ item }) => {
    const imagesCount = item['oneTimeUseTagImages@odata.count'];
    if (imagesCount === 0) return <div></div>;
    else
        return (
            <div>
                <FontAwesomeIcon
                    style={{
                        transform: 'scale(1.75)',
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}
                    icon={faCamera}
                />
            </div>
        );
};

const BulkTaggerProcedureTitleCell = ({ item, handleOpenProcedureModal }) => {
    const [isItemClicked, setIsItemClicked] = useState(false);

    const handleProcedureTitleClick = procedureId => {
        setIsItemClicked(true);
        handleOpenProcedureModal(procedureId);
    };

    const handleViewInTaggerClick = (bookId, procedureId) => {
        setIsItemClicked(true);
        // https://{url}}/tagger/{oem}}/bulkedit -> https://{url}}/tagger/{oem}}/${bookId}/tag/${procedureId}
        const url = window.location.href.replace(/bulkedit/i, `${bookId}/tag/${procedureId}`);
        window.open(url);
    };

    return (
        <div className={`${isItemClicked && 'active'}`}>
            <span
                className="clickable text-primary"
                onClick={() => handleProcedureTitleClick(item.procedureId, item.id)}>
                {item.procedure.procedureTitle}
            </span>
            <button
                id={`view-in-tagger-${item.procedure.booksForProcedure[0].bookId}-${item.procedureId}`}
                type="button"
                className={`btn btn-sm text-primary${isItemClicked && ' active-x'}`}
                onClick={() =>
                    handleViewInTaggerClick(item.procedure.booksForProcedure[0].bookId, item.procedureId, item.id)
                }>
                <FontAwesomeIcon className="clickable new-tab-link-icon" icon={faExternalLinkAlt} />
            </button>
        </div>
    );
};
