import { useState, useEffect, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { OlsMapperListContext } from 'contexts/MapperListContext';

const useFilterSearch = (setNoGroupFilter, setNoTypeFilter, setRefreshedFilter, setOnlyHotSheetFilter) => {
    const [unappliedFilters, setUnappliedFilters] = useState([]);
    const [displayFilters, setDisplayFilters] = useState([]);
    const [validationError, setValidationError] = useState(false);
    const [filterWithoutGroup, setFilterWithoutGroup] = useState(false);
    const [filterWithoutType, setFilterWithoutType] = useState(false);
    const [filterRefreshedPending, setFilterRefreshedPending] = useState(false);
    const [filterOnlyHotSheet, setFilterOnlyHotSheet] = useState(false);
    const { appliedFilters, setAppliedFilters } = useContext(OlsMapperListContext);

    const addFilter = (filter, changingField) => {
        const index = unappliedFilters.findIndex(f => f.id === filter.id);
        const newArray = [...unappliedFilters];

        if (index > -1) {
            if (changingField && changingField === 'term') newArray[index].term = filter.term;
            else newArray[index] = filter;
        } else {
            newArray.push(filter);
        }

        setUnappliedFilters(newArray);
    };

    useEffect(() => {
        const filterHash = {};

        appliedFilters.forEach(filter => {
            filterHash[filter.id] = filter;
        });

        unappliedFilters.forEach(filter => {
            filterHash[filter.id] = filter;
        });

        const newDisplayFilters = [];
        Object.keys(filterHash).forEach(function (id) {
            newDisplayFilters.push(filterHash[id]);
        });

        if (unappliedFilters.length < 1 && appliedFilters.length < 1) {
            const filter = { id: uuidv4(), selectedColumn: -1, selectedOperator: -1, term: '' };
            setUnappliedFilters(arr => [...arr, filter]);
        }

        setDisplayFilters(newDisplayFilters);
    }, [appliedFilters, unappliedFilters]);

    const applyUnappliedFilters = () => {
        const valid = displayFilters.every(filter => {
            return (
                filter.selectedColumn !== -1 && filter.selectedOperator !== -1 && filter.term && filter.term.length > 0
            );
        });

        if (!valid) {
            setValidationError(true);
        } else {
            setValidationError(false);
            setUnappliedFilters([]);
            setAppliedFilters(displayFilters);
        }
    };

    const handleApplyFilter = () => {
        const noColumnFilters =
            displayFilters.length === 1 &&
            displayFilters.every(filter => {
                return filter.selectedColumn === -1 && filter.selectedOperator === -1 && filter.term === '';
            });

        if (!noColumnFilters) applyUnappliedFilters();

        setNoGroupFilter(filterWithoutGroup);
        setNoTypeFilter(filterWithoutType);
        setRefreshedFilter(filterRefreshedPending);
        setOnlyHotSheetFilter(filterOnlyHotSheet);
    };

    const removeFilter = filterId => {
        setValidationError(false);
        const newFilters = appliedFilters.filter(f => f.id !== filterId);

        if (newFilters.length !== appliedFilters.length) setAppliedFilters(newFilters);

        const newUnappliedFilters = unappliedFilters.filter(f => f.id !== filterId);

        if (newUnappliedFilters.length !== unappliedFilters.length) setUnappliedFilters(newUnappliedFilters);
    };

    return {
        addFilter,
        removeFilter,
        unappliedFilters,
        setUnappliedFilters,
        handleApplyFilter,
        validationError,
        displayFilters,
        filterWithoutGroup,
        setFilterWithoutGroup,
        filterWithoutType,
        setFilterWithoutType,
        filterRefreshedPending,
        setFilterRefreshedPending,
        filterOnlyHotSheet,
        setFilterOnlyHotSheet,
    };
};

export default useFilterSearch;
