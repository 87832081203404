import React, { useCallback, useEffect } from 'react';
import useTaggerTool from './useTaggerTool';
import TagsAndFlagsDisplay from './TagsAndFlagsDisplay/TagsAndFlagsDisplay';
import OneTimeUseTagHighlight from './Highlighting/OneTimeUseTagHighlight';
import OneTimeUseFlagHighlight from './Highlighting/OneTimeUseFlagHighlight';
import AddNewTag from './AddNewTag/AddNewTag';
import { clearHighlight } from 'helpers/TaggerHelper';
import mapToOptions from 'helpers/MapToOptions';
import { useNavigate } from 'react-router-dom';
import { MESSAGE_EVENT_TYPE, useProcedureHtmlLinkClickHandler } from 'helpers/useProcedureHtmlLinkClickHandler';

export const TaggerToolContext = React.createContext();

function clickedDiv(message) {
    window.parent.postMessage({ type: MESSAGE_EVENT_TYPE, message }, window.location.origin);
}

const TaggerTool = () => {
    const {
        procedure,
        tags,
        setTags,
        flags,
        setFlags,
        highlightStylesTags,
        highlightStylesFlags,
        scrollToContentStart,
        isAddingNewTag,
        setIsAddingNewTag,
        quantityConditions,
        setQuantityConditions,
        partTypes,
        toggleFlag,
        toggleTag,
        hideAll,
        toggleAll,
        isScrollToContentEnabled,
        handleEnableScrollToContentChanged,
    } = useTaggerTool();

    const navigate = useNavigate();

    useEffect(() => {
        clearHighlight();
    }, [procedure]);

    useEffect(() => {
        const originalClickedDiv = window.clickedDiv;
        window.clickedDiv = clickedDiv;
        return () => {
            window.clickedDiv = originalClickedDiv;
        };
    }, []);

    const loadProcedure = useCallback(
        async procedureId => {
            const url = new URL(window.location.href);
            const pathSegments = url.pathname.split('/');

            pathSegments[pathSegments.length - 1] = procedureId;

            const newPath = pathSegments.join('/');
            navigate(newPath, { replace: false });
        },
        [navigate]
    );

    const { handleProcedureClick, handleMouseMove, handleMouseClick, selectorComponent } =
        useProcedureHtmlLinkClickHandler(
            procedure,
            loadProcedure,
            document.querySelector('#procedure-html'),
            procedure?.oemId
        );

    useEffect(() => {
        if (!handleProcedureClick) return;

        const listener = event => {
            if (event.data?.type === MESSAGE_EVENT_TYPE && event.isTrusted && event.origin === window.location.origin) {
                handleProcedureClick({ data: event.data.message });
            }
        };

        window.addEventListener('message', listener);
        return () => {
            window.removeEventListener('message', listener);
        };
    }, [handleProcedureClick]);

    if (!procedure) return null;

    return (
        <TaggerToolContext.Provider
            value={{
                setTags,
                setFlags,
                quantityConditions: quantityConditions.map(mapToOptions),
                setQuantityConditions,
                partTypes,
                scrollToContentStart,
            }}>
            <div className="container-fluid mt-4" onClick={handleMouseClick}>
                <div className="row">
                    <div className="col">
                        <div
                            onMouseMove={handleMouseMove}
                            onClick={handleMouseClick}
                            id={'procedure-html'}
                            dangerouslySetInnerHTML={{ __html: procedure.procedureHtml.html }}></div>
                        {selectorComponent}
                    </div>
                    <div className="col">
                        <div
                            className="pe-2 pb-2"
                            style={{ position: 'sticky', top: '96px', overflowY: 'scroll', height: '90vh' }}>
                            <h5>{procedure.procedureTitle}</h5>
                            <div className="d-flex justify-content-end">
                                <span className="mt-2">{`Auto Scroll ${
                                    isScrollToContentEnabled ? 'Enabled' : 'Disabled'
                                }: `}</span>
                                <div
                                    className="switch switch-sm"
                                    title={`Scroll To Content ${isScrollToContentEnabled ? 'Enabled' : 'Disabled'}`}>
                                    <input
                                        type="checkbox"
                                        className="switch"
                                        id="toggle-scroll-to-content"
                                        checked={isScrollToContentEnabled}
                                        onChange={handleEnableScrollToContentChanged}
                                    />
                                    <label
                                        className="text-darkblue mt-2 ms-2"
                                        htmlFor={'toggle-scroll-to-content'}></label>
                                </div>
                                <button
                                    type="button"
                                    className={'btn btn-sm me-2 btn-primary'}
                                    onClick={() => toggleAll()}>
                                    {`${hideAll ? 'Show' : 'Hide'} all`}
                                </button>
                                <button
                                    type="button"
                                    className={`btn btn-sm ${!isAddingNewTag ? 'btn-success' : 'btn-primary'}`}
                                    onClick={() => setIsAddingNewTag(!isAddingNewTag)}>
                                    {isAddingNewTag ? 'Back' : 'Add Tag'}
                                </button>
                            </div>

                            {isAddingNewTag && (
                                <AddNewTag
                                    quantityConditions={quantityConditions.map(mapToOptions)}
                                    partTypes={partTypes}
                                    setIsAddingNewTag={setIsAddingNewTag}
                                    setTags={setTags}
                                />
                            )}

                            <TagsAndFlagsDisplay
                                tags={tags}
                                flags={flags}
                                highlightStyles={{
                                    highlightStylesTags,
                                    highlightStylesFlags,
                                }}
                                toggleFlag={toggleFlag}
                                toggleTag={toggleTag}
                                isScrollToContentEnabled={isScrollToContentEnabled}
                            />
                        </div>
                    </div>
                </div>
                <div id="highlight" style={{ background: 'yellow', position: 'absolute', zIndex: -1 }}></div>
                {tags.map(t => (
                    <OneTimeUseTagHighlight
                        key={t.stagedOneTimeUseTagId}
                        highlightStyles={highlightStylesTags}
                        tag={t}
                    />
                ))}
                {flags.map(f => (
                    <OneTimeUseFlagHighlight key={f.oneTimeUseFlagId} highlightStyles={highlightStylesFlags} flag={f} />
                ))}
            </div>
        </TaggerToolContext.Provider>
    );
};

export default TaggerTool;
