import React from 'react';
import usePlanTable from './usePlanTable';
import { Input } from 'oemiq-common';

const PlanTable = ({ addToPlans, handleKeyPress }) => {
    const { companyUsers, displayPlans, searchValue, setSearchValue, handleSearch } = usePlanTable();

    return (
        <div>
            <Input
                id="search-plans"
                placeholder="Search by plan ID or RO"
                value={searchValue}
                onChange={e => setSearchValue(e.currentTarget.value)}
                onBlur={() => handleSearch(searchValue)}
                onKeyPress={e => handleKeyPress(e)}
            />
            <table className="table table-striped mt-3">
                <thead>
                    <tr>
                        <th scope="col">Plan ID</th>
                        <th scope="col">RO</th>
                        <th scope="col">User</th>
                        <th scope="col">Vehicle Info</th>
                        <th scope="col">VIN</th>
                    </tr>
                </thead>
                <tbody>
                    {companyUsers.length > 0 &&
                        displayPlans.map(p => {
                            let user = companyUsers.find(cu => cu.userId === p.userId);
                            let userName = user ? user.userName : '';
                            return (
                                <tr key={p.planId} className="clickable" onClick={() => addToPlans(p.planId)}>
                                    <td scope="col">{p.planId}</td>
                                    <td scope="col">{p.repairOrder}</td>
                                    <td scope="col">{userName}</td>
                                    <td scope="col">{`${p.yearId} ${p.oemValue} ${p.modelValue} ${p.trimValue}`}</td>
                                    <td scope="col">{p.vin}</td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        </div>
    );
};

export default PlanTable;
