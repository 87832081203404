import React from 'react';
import { LightButton, PrimaryButton, SuccessButton, DangerButton, WarningButton, DarkButton } from 'oemiq-common';

const GroupTypeBodyType = ({ selectedTypeIdWithStatusId, handleTypeStatusSelection, typeSelectionOptions }) => {
    return (
        <div>
            <h6>Type</h6>
            {typeSelectionOptions.map(ts => {
                if (selectedTypeIdWithStatusId && ts.value === selectedTypeIdWithStatusId.typeId) {
                    if (selectedTypeIdWithStatusId.statusId === 1) {
                        return (
                            <PrimaryButton
                                key={ts.value}
                                id={`type-${ts.value}`}
                                className="mb-1 me-3"
                                onClick={() => handleTypeStatusSelection(ts.value)}>
                                {ts.text}
                            </PrimaryButton>
                        );
                    } else if (selectedTypeIdWithStatusId.statusId === 2) {
                        return (
                            <SuccessButton
                                key={ts.value}
                                id={`type-${ts.value}`}
                                className="mb-1 me-3"
                                onClick={() => handleTypeStatusSelection(ts.value)}>
                                {ts.text}
                            </SuccessButton>
                        );
                    } else if (selectedTypeIdWithStatusId.statusId === 3) {
                        return (
                            <DangerButton
                                key={ts.value}
                                id={`type-${ts.value}`}
                                className="mb-1 me-3"
                                onClick={() => handleTypeStatusSelection(ts.value)}>
                                {ts.text}
                            </DangerButton>
                        );
                    } else if (selectedTypeIdWithStatusId.statusId === 100) {
                        return (
                            <DarkButton
                                key={ts.value}
                                id={`type-${ts.value}`}
                                className="mb-1 me-3"
                                onClick={() => handleTypeStatusSelection(ts.value)}>
                                {ts.text}
                            </DarkButton>
                        );
                    } else {
                        return (
                            <WarningButton
                                key={ts.value}
                                id={`type-${ts.value}`}
                                className="mb-1 me-3"
                                onClick={() => handleTypeStatusSelection(ts.value)}>
                                {ts.text}
                            </WarningButton>
                        );
                    }
                }
                return (
                    <LightButton
                        key={ts.value}
                        id={`type-${ts.value}`}
                        className="mb-1 me-3"
                        onClick={() => handleTypeStatusSelection(ts.value)}>
                        {ts.text}
                    </LightButton>
                );
            })}
        </div>
    );
};

export default GroupTypeBodyType;
