import CompanyModal from './CompanyModal/CompanyModal';
import useOrganizationCompanies from './useOrganizationCompanies';
import OrganizationModal from '../Organizations/OrganizationModal/OrganizationModal';
import useOrganizations from '../Organizations/useOrganizations';
import { OEC_STAGING_ORG } from '../../ManageCustomers/ManageCustomersConstants';
import { messages } from '../../../Shared/Utils/Constants';
import CustomTooltip from '../../../Shared/Utils/CustomTooltip';
import ManageCustomersTable from '../ManageUsers/ManageCustomersTable';
import CompanyMoveModal from './CompanyModal/CompanyMoveModal';
import GlobalSearchBar from 'components/Shared/GlobalSearchBar/GlobalSearchBar';
import ConfirmationModal from 'components/Shared/Utils/ConfirmationModal';

const OrganizationCompanies = () => {
    // take in a portion of the organization page state management to power the staging area functionality
    const {
        onSaveOrEdit: onOrgSaveOrEdit,
        editOrgId,
        setEditOrgId,
        handleCreateOrEditOrgClick: handleOrgEditClick,
    } = useOrganizations(true);

    const {
        showModal,
        organizationId,
        editCompanyId,
        displayCompanies,
        tableColumnDetails,
        userHasCustomerSupportRole,
        onSaveOrEdit,
        handleCompModalToggle,
        setSearchTerm,
        handleOrgUsersClick,
        setShowModal,
        handleRowClick,
        sortClickCallback,
        showOrgModal,
        handleOrgModalToggle,
        isOrgActive,
        showMoveLocationModal,
        editedMoveLocation,
        handleShowMoveLocationModal,
        searchParamText,
        handleUpdateLocationStatus,
        updatedLocationStatus,
        setUpdatedLocationStatus,
        filterChangeCallback,
        selectedFilters,
        globalSearchRef,
    } = useOrganizationCompanies(handleOrgEditClick, setEditOrgId);

    const inStagingOrg = parseInt(organizationId) === OEC_STAGING_ORG.organizationId;

    return (
        <div className="manage-customers-layout-container">
            {showModal && (
                <CompanyModal
                    onSaveOrEdit={onSaveOrEdit}
                    orgId={organizationId}
                    isOpen={showModal}
                    companyId={editCompanyId}
                    toggle={handleCompModalToggle}
                    targetOrgId={editOrgId}
                />
            )}
            {showOrgModal && (
                <OrganizationModal
                    onSaveOrEdit={onOrgSaveOrEdit}
                    stagedCompId={editCompanyId ? editCompanyId : null}
                    isOpen={showOrgModal}
                    toggle={handleOrgModalToggle}
                />
            )}
            {showMoveLocationModal && (
                <CompanyMoveModal
                    showMoveUserModal={showMoveLocationModal}
                    handleShowMoveLocationModal={handleShowMoveLocationModal}
                    companyDetails={editedMoveLocation}
                    onSaveOrEdit={onSaveOrEdit}
                />
            )}
            {updatedLocationStatus?.showConfirmationModal && (
                <ConfirmationModal
                    show={updatedLocationStatus?.showConfirmationModal}
                    body={
                        <>
                            Are you sure you want to{' '}
                            <strong>{updatedLocationStatus.isActive ? 'deactivate' : 'reactivate'}</strong>{' '}
                            {updatedLocationStatus.companyName}?
                        </>
                    }
                    onConfirmCallback={handleUpdateLocationStatus}
                    onCancelCallback={() => setUpdatedLocationStatus(null)}
                    confirmButtonText={updatedLocationStatus.isActive ? 'Deactivate' : 'Reactivate'}
                />
            )}
            <div className="position-sticky w-100 p-2 manage-customers-nav">
                <div className="nav-bar mt-3">
                    {searchParamText ? (
                        <h6>
                            <strong>Locations</strong> search results for '<strong>{searchParamText}</strong>'
                        </h6>
                    ) : (
                        <div className="buttons-container">
                            {!inStagingOrg && (
                                <button
                                    type="button"
                                    id="org-users"
                                    className="btn btn-sm btn-primary h-100"
                                    onClick={handleOrgUsersClick}>
                                    View Organization Users
                                </button>
                            )}
                            {!userHasCustomerSupportRole && !inStagingOrg && (
                                <CustomTooltip
                                    title={!isOrgActive ? messages.organizationDisabledActionNotAllowed : ''}>
                                    <span>
                                        <button
                                            type="button"
                                            id="org-create-company"
                                            className={`btn btn-sm btn-primary h-100 ms-3 ${
                                                !isOrgActive ? 'cursor-not-allowed' : ''
                                            }`}
                                            onClick={() => setShowModal(!showModal)}
                                            disabled={!isOrgActive}>
                                            Create New Location
                                        </button>
                                    </span>
                                </CustomTooltip>
                            )}
                        </div>
                    )}

                    <div className="col-3 ms-auto">
                        <GlobalSearchBar inPageSearchCallback={setSearchTerm} globalSearchRef={globalSearchRef} />
                    </div>
                </div>
            </div>
            <div className="table-container">
                <ManageCustomersTable
                    tableId="org-companies-table"
                    isTableRowsClickable={true}
                    columnDetails={tableColumnDetails.filter(p => p.show)}
                    data={displayCompanies}
                    sortClickCallback={sortClickCallback}
                    rowClickCallback={handleRowClick}
                    ellipsisColumnIndex={1}
                    filterChangeCallback={filterChangeCallback}
                    selectedFilters={selectedFilters}
                />
            </div>
        </div>
    );
};

export default OrganizationCompanies;
