import React from 'react';
import { Input } from 'oemiq-common';
import useSearch from './useSearch';
import ReactSelect from 'react-select';
import mapToOptions from 'helpers/MapToOptions';

const Search = ({ handleSearch, searchOptions, bookId }) => {
    const {
        localSearchValue,
        setLocalSearchValue,
        searchValueSelections,
        handleSearchKeyPress,
        handleOptionsSelectChange,
    } = useSearch(searchOptions);

    return (
        <div className="mb-2 d-flex align-items-center justify-content-between">
            <div className="w-75">
                <Input
                    id="searchfilter-search"
                    type="text"
                    value={localSearchValue}
                    formGroupClassName={'mb-0'}
                    onChange={e => setLocalSearchValue(e.currentTarget.value)}
                    onBlur={() => handleSearch(localSearchValue, searchValueSelections, bookId)}
                    onKeyPress={e => handleSearchKeyPress(e)}
                    placeholder="Search"
                />
            </div>
            <div className="ms-2 flex-fill">
                <ReactSelect
                    isMulti
                    placeholder="Search on"
                    value={searchOptions.filter(o => searchValueSelections.includes(o.value)).map(mapToOptions)}
                    options={searchOptions.map(mapToOptions)}
                    onChange={handleOptionsSelectChange}
                />
            </div>
        </div>
    );
};

export default Search;
