import { useContext } from 'react';
import { LoadingContext } from 'components/Layout';
import { ToastContext } from 'components/ToastProvider';
import { requestDeletePositionStatement } from 'api/RepairProcedureApi';

const useConfirmDeletePositionStatement = (procedureId, toggle, deletePositionStatement) => {
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const { showToast } = useContext(ToastContext);

    const handleDelete = async () => {
        try {
            incrementLoading();
            await requestDeletePositionStatement(procedureId);
            deletePositionStatement();
            toggle();
        } catch (error) {
            showToast(error);
        } finally {
            decrementLoading();
        }
    };

    return { handleDelete };
};

export default useConfirmDeletePositionStatement;
