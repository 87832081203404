import { requestAssignedUserCleanup } from 'api/RepairPlanApi';
import { requestOrganizations, requestOrganizationCompanies, requestMoveuser } from 'api/SecurityApi';
import { LoadingContext } from 'components/Layout';
import { NotificationsContext } from 'components/Shared/Notifications/Notifications';
import { ToastContext } from 'components/ToastProvider';
import { useContext, useEffect, useState } from 'react';
import { ICompany, IOrg, MoveUserHook, ToastContextType, UserDetails } from './types';
import { CompanyDetails } from '../OrganizationCompanies/CompanyModal/types';

const useMoveUser = (
    handleShowMoveUserModal: () => void,
    userDetails: UserDetails,
    onSaveOrEdit: () => void
): MoveUserHook => {
    // higher order states
    const { notifications } = useContext(NotificationsContext);
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const { showToast } = useContext(ToastContext) as ToastContextType;
    // local states
    const [saveEnabled, setSaveEnabled] = useState(false);
    const [disableLocationSelection, setDisableLocationSelection] = useState(false);
    const [selectedOrg, setSelectedOrg] = useState(userDetails.company.organizationId);
    const [orgs, setOrgs] = useState<IOrg[]>([]);
    const [selectedPrimaryLocation, setSelectedPrimaryLocation] = useState(userDetails.company.companyId);
    const [orgLocations, setOrgLocations] = useState<ICompany[]>([]);
    const selectedCompanies = orgLocations.filter(l => userDetails.companies.some(c => c.companyId === l.companyId));
    const [selectedLocations, setSelectedLocations] = useState<{ label: string; value: number }[]>([]);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [multiSelectOptions, setMultiSelectOptions] = useState<{ label: string; value: number }[]>(
        selectedCompanies.map(l => {
            return { label: l.companyName, value: l.companyId };
        })
    );

    useEffect(() => {
        let isMounted = true;
        const getOrgs = async () => {
            try {
                incrementLoading();
                const orgs = await requestOrganizations();
                isMounted && setOrgs(orgs.filter(o => o.isActive));
            } catch (error) {
                showToast(error);
            } finally {
                decrementLoading();
            }
        };
        getOrgs();
        return () => {
            isMounted = false;
        };
    }, [decrementLoading, incrementLoading, showToast]);

    useEffect(() => {
        let isMounted = true;
        const getOrgs = async () => {
            try {
                incrementLoading();
                const orgCompanies: CompanyDetails[] = await requestOrganizationCompanies(selectedOrg);
                if (isMounted) {
                    setOrgLocations(orgCompanies.filter(orgCompany => !orgCompany.isDeactivated));
                    setMultiSelectOptions(
                        orgCompanies.map(l => {
                            return { label: l.companyName, value: l.companyId };
                        })
                    );
                    const selectedCompanies = orgCompanies.filter(l =>
                        userDetails.companies.some(c => c.companyId === l.companyId)
                    );
                    setSelectedLocations(
                        selectedCompanies.map(l => {
                            return { label: l.companyName, value: l.companyId };
                        })
                    );
                }
            } catch (error) {
                showToast(error);
            } finally {
                decrementLoading();
            }
        };
        getOrgs();
        return () => {
            isMounted = false;
        };
    }, [decrementLoading, incrementLoading, showToast, selectedOrg, userDetails]);

    useEffect(() => {
        if (userDetails && userDetails.userId) {
            const isSame = userDetails.company.organizationId === selectedOrg;

            const required =
                selectedOrg !== null && Number(selectedPrimaryLocation) !== 0 && selectedLocations.length > 0;

            setSaveEnabled(!isSame && required);

            if (selectedOrg === userDetails.company.organizationId) {
                setDisableLocationSelection(true);
                setSelectedPrimaryLocation(userDetails.company.companyId);
                const selectedCompanies = orgLocations.filter(l =>
                    userDetails.companies.some(c => c.companyId === l.companyId)
                );
                setSelectedLocations(
                    selectedCompanies.map(l => {
                        return { label: l.companyName, value: l.companyId };
                    })
                );
            } else {
                setDisableLocationSelection(false);
            }
        }
    }, [userDetails, orgLocations, selectedOrg, selectedPrimaryLocation, selectedLocations.length]);

    const handleSetPrimaryLocation = (e: React.ChangeEvent<HTMLSelectElement>) => {
        // Auto add the primary location to the locations list
        const primaryId = parseInt(e.currentTarget.value);
        const primaryCompany = orgLocations.find(l => l.companyId === primaryId);
        setSelectedPrimaryLocation(primaryId);
        if (primaryId == 0) return;
        if (!selectedLocations.find(l => l.value === primaryId))
            setSelectedLocations([...selectedLocations, { label: primaryCompany.companyName, value: primaryId }]);
    };
    const handleLocationsChange = (e: { label: string; value: number }[]) => {
        // Don't allow the removal of the primary company from the locations
        if (e.length < selectedLocations.length) {
            const diff = selectedLocations.filter(l => !e.includes(l));
            if (diff[0].value === selectedPrimaryLocation) return;
        }
        setSelectedLocations(e);
    };
    const handleSave = async () => {
        if (!saveEnabled) return;
        setShowConfirmModal(false);
        let securityApiSuccess = false;
        try {
            incrementLoading();
            const moveUserResponse = await requestMoveuser(
                userDetails.userId,
                selectedOrg,
                selectedPrimaryLocation,
                selectedLocations.map(l => l.value)
            );
            if (moveUserResponse.status === 204) {
                securityApiSuccess = true;
                const plansResponse = await requestAssignedUserCleanup(userDetails.userId);
                if (plansResponse.status === 204) {
                    notifications.pushSuccess(`User with email: ${userDetails.email} moved`);
                    handleShowMoveUserModal();
                    await onSaveOrEdit();
                }
            }
        } catch (error) {
            if (securityApiSuccess) {
                await requestMoveuser(
                    userDetails.userId,
                    userDetails.company.organizationId,
                    userDetails.company.companyId,
                    userDetails.companies.map(l => l.companyId)
                );
            }
            notifications.pushExceptionDanger(error, { pinned: false, body: error.message.split(';') });
        } finally {
            decrementLoading();
        }
    };

    return {
        orgs,
        selectedOrg,
        setSelectedOrg,
        selectedPrimaryLocation,
        setSelectedPrimaryLocation,
        orgLocations,
        selectedLocations,
        setSelectedLocations,
        multiSelectOptions,
        handleSetPrimaryLocation,
        handleLocationsChange,
        saveEnabled,
        disableLocationSelection,
        handleSave,
        showConfirmModal,
        setShowConfirmModal,
    };
};

export default useMoveUser;
