import React, { useEffect, useState } from 'react';
import { Toast as OCToast, ToastErrorHeader } from 'oemiq-common';
import { ToastType } from './ToastType';
import { uuid } from 'helpers/UuidHelper';

const Toast = ({ isOpen, header, message, toggle, type }) => {
    const [headerFnc, setHeaderFnc] = useState(() => () => <ToastErrorHeader title={header} />);

    useEffect(() => {
        switch (type) {
            case ToastType.ERROR:
                setHeaderFnc(() => () => <ToastErrorHeader title={header} />);
                break;

            default:
                // headerComponent={<div>{header}</div>}
                setHeaderFnc(() => () => <div>{header}</div>);
                break;
        }
    }, [type, header]);

    return (
        <OCToast
            id={uuid()}
            isOpen={isOpen}
            toggle={toggle}
            headerComponent={headerFnc()}
            headerClassName={`toast-bg-${type}`}
            bodyComponent={<div>{message}</div>}
            bodyClassName={`toast-bg-${type}`}
        />
    );
};

export default Toast;
