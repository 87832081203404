import React, { useEffect } from 'react';
import { Checkbox } from 'oemiq-common';
import { operatorsList } from '../operators';

const Boolean = ({ filter, setFilterValue }) => {
    useEffect(() => {
        typeof filter.value !== 'boolean' && setFilterValue({ value: false, valueList: ['false'] });
    }, [setFilterValue, filter.value]);

    return (
        <Checkbox
            className="mb-2"
            type="text"
            placeholder="Filter value"
            disabled={!filter.id || !filter.operator}
            checked={filter.value}
            onChange={e => setFilterValue({ value: e.target.checked, valueList: [e.target.checked.toString()] })}
        />
    );
};

export default {
    component: Boolean,
    operators: [operatorsList.eq],
    defaultValue: false,
    allowFalse: true,
    allowInstances: 1,
};
