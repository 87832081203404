import { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import {
    requestOrganizations,
    requestCreateOrganization,
    requestEditOrganization,
    requestOrganization,
    requestOrganizationUsers,
    requestCompany,
} from 'api/SecurityApi';
import { LoadingContext } from 'components/Layout';
import { ToastContext } from 'components/ToastProvider';
import { addOrganizationToNetwork, removeOrganizationFromNetwork } from 'api/NetworkApi';
import { NotificationsContext } from 'components/Shared/Notifications/Notifications';
import { useFuzzySearch } from 'hooks/useFuzzySearch';
import {
    OEC_STAGING_ORG,
    FORCE_CLOSE_EVENT,
    GetCloseEventOverride,
    CUST_TOOLS,
} from 'components/locations/ManageCustomers/ManageCustomersConstants';
import { useNavigate } from 'react-router-dom';

const ORG_NAME_EXISTS_REDIRECT_ID = `validate-identical-org-link`;
const ORG_NAME_SIMILAR_REDIRECT_ID = `validate-similar-org-link`;
// todo: product copy / redesign
const STAGED_COMP_DATA_WARN =
    'Information Loaded From Staged Company. Please double check values before saving! This note will be removed upon save.';

const useOrganizationModal = (orgId, toggle, onSaveOrEdit, stagedCompId) => {
    // high order states
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const { showToast } = useContext(ToastContext);
    const { notifications } = useContext(NotificationsContext);
    // local states
    const [org, setOrg] = useState({});
    const [orgUsers, setOrgUsers] = useState([]);
    const [orgName, setOrgName] = useState('');
    const [contactPersonId, setContactPersonId] = useState(-1);
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [zip, setZip] = useState('');
    const [state, setState] = useState('');
    const [countryId, setCountryId] = useState(-1);
    const [notes, setNotes] = useState('');
    const [inEditState, setInEditState] = useState(false);
    const [saveEnabled, setSaveEnabled] = useState(false);
    const [selectedNetwork, setSelectedNetwork] = useState(-1);
    const [orgNames, setOrgNames] = useState([]);
    const staticOrgs = useMemo(() => [OEC_STAGING_ORG.organizationId /* add as needed */], []);
    const { setSearchTerm, searchComplete, searchResults } = useFuzzySearch(orgNames);
    const orgNameRedirectIds = useMemo(() => [ORG_NAME_EXISTS_REDIRECT_ID, ORG_NAME_SIMILAR_REDIRECT_ID], []);
    const [nameMatchesExisting, setNameMatchesExisting] = useState(false);
    const [nameIsSimilar, setNameIsSimilar] = useState(false);
    const [segmentId, setSegmentId] = useState(-1);
    const navigate = useNavigate();

    useEffect(() => {
        const getOrgInfo = async () => {
            try {
                incrementLoading();
                const org = await requestOrganization(orgId);
                setOrg(org);
                setOrgName(org.name);
                setContactPersonId(org.contactPersonId || -1);
                setAddress(org.address || '');
                setCity(org.city || '');
                setState(org.state || '');
                setZip(org.zip || '');
                setCountryId(org.countryId || -1);
                setSegmentId(org.segmentId || -1);
                setNotes(org.notes || '');
            } catch (error) {
                showToast(error);
            } finally {
                decrementLoading();
            }
        };

        const getOrgUsers = async () => {
            try {
                incrementLoading();
                const users = await requestOrganizationUsers(orgId);
                setOrgUsers(users);
            } catch (error) {
                showToast(error);
            } finally {
                decrementLoading();
            }
        };

        const getOrgs = async () => {
            try {
                incrementLoading();
                const res = await requestOrganizations();
                const modalContextOrg = parseInt(orgId);
                const orgNames = res.reduce((acc, org) => {
                    const organizationId = parseInt(org.organizationId);
                    if (organizationId !== modalContextOrg)
                        acc.push({ key: parseInt(org.organizationId), value: org.name });
                    return acc;
                }, []);
                setOrgNames(orgNames);
            } catch (error) {
                showToast(error);
            } finally {
                decrementLoading();
            }
        };

        // see useCompanyModal for source logic
        const getDbCompValues = async () => {
            try {
                incrementLoading();
                const respComp = await requestCompany(stagedCompId);
                setAddress(respComp.address || '');
                setCity(respComp.city || '');
                setState(respComp.state || '');
                setZip(respComp.zip || '');
                setCountryId(respComp.countryId || -1);
                setNotes(STAGED_COMP_DATA_WARN); // todo: product copy / redesign
            } catch (error) {
                showToast(error);
            } finally {
                decrementLoading();
            }
        };

        getOrgs();

        // if in the staging org context, we can pre-populate some values even on a new org
        if (stagedCompId) getDbCompValues();

        if (orgId) {
            getOrgInfo();
            getOrgUsers();
        }
    }, [orgId, stagedCompId, showToast, incrementLoading, decrementLoading]);

    useEffect(() => {
        const unchanged = orgId
            ? /* no difference */
              org.name === orgName &&
              (org.contactPersonId === contactPersonId || (!org.contactPersonId && contactPersonId === -1)) &&
              (org.address === address || (!org.address && !address)) &&
              (org.city === city || (!org.city && !city)) &&
              (org.zip === zip || (!org.zip && !zip)) &&
              (org.state === state || (!org.state && !state)) &&
              (org.countryId === countryId || (!org.countryId && countryId === -1)) &&
              (org.segmentId === segmentId || (!org.segmentId && segmentId === -1)) &&
              (org.notes === notes || (!org.notes && !notes))
            : /* no input */
              orgName === '' &&
              contactPersonId === -1 &&
              address === '' &&
              city === '' &&
              zip === '' &&
              state === '' &&
              countryId === -1 &&
              segmentId === -1 &&
              notes === '';

        const hasRequired =
            !unchanged &&
            orgName?.length > 0 &&
            address?.length > 0 &&
            city?.length > 0 &&
            zip?.length > 0 &&
            state?.length > 0 &&
            countryId > -1;

        const unique =
            !unchanged &&
            searchComplete &&
            (!searchResults || searchResults.filter(sr => sr.isExactMatch).length === 0);

        setInEditState(!unchanged);
        setSaveEnabled(hasRequired && unique);
    }, [
        orgName,
        contactPersonId,
        address,
        city,
        zip,
        state,
        notes,
        orgId,
        org,
        countryId,
        searchComplete,
        searchResults,
        setInEditState,
        setSaveEnabled,
        segmentId,
    ]);

    const handleSave = async () => {
        if (!saveEnabled) return;

        let newOrgId = null;
        const segmentData = segmentId > -1 ? segmentId : null;
        try {
            incrementLoading();
            if (orgId) {
                await requestEditOrganization(
                    orgId,
                    orgName,
                    contactPersonId > -1 ? contactPersonId : null,
                    address,
                    city,
                    state,
                    zip,
                    countryId,
                    notes,
                    segmentData
                );
                await onSaveOrEdit();
            } else {
                const res = await requestCreateOrganization(
                    orgName,
                    contactPersonId > -1 ? contactPersonId : null,
                    address,
                    city,
                    state,
                    zip,
                    countryId,
                    notes === STAGED_COMP_DATA_WARN ? '' : notes,
                    segmentData
                );

                newOrgId = res.organizationId;
                navigate(`${CUST_TOOLS.orgTool.Path(newOrgId)}`);
            }
            setInEditState(false);

            notifications.pushSuccess(`${orgName} ${orgId ? `updated` : `created`} successfully`);
            handleToggle(FORCE_CLOSE_EVENT, newOrgId);
        } catch (error) {
            // changed from toast to notification since we are using Modal
            notifications.pushExceptionDanger(error);
        } finally {
            decrementLoading();
        }
    };

    const handleToggle = (e, redirectOrg = null) => {
        let force = GetCloseEventOverride(e, orgNameRedirectIds);

        if (!inEditState || force) {
            setOrg({});
            setOrgName('');
            setSearchTerm('');
            setContactPersonId(-1);
            setAddress('');
            setCity('');
            setState('');
            setZip('');
            setNotes('');
            setCountryId(-1);
            setSegmentId(-1);
            setSaveEnabled(false);
            setOrgUsers([]);
            toggle(redirectOrg, stagedCompId);
        }
    };

    const onDeleteClick = async networkToRemove => {
        await removeOrganizationFromNetwork(networkToRemove.networkId, orgId);
        setOrg(prevState => {
            let newState = JSON.parse(JSON.stringify(prevState));
            newState.networks = newState.networks.filter(n => n.networkId !== networkToRemove.networkId);
            return newState;
        });
    };

    const onAddNetwork = async () => {
        let network = await addOrganizationToNetwork(selectedNetwork, orgId);
        setOrg(prevState => {
            let newState = JSON.parse(JSON.stringify(prevState));
            newState.networks = [...newState.networks, network];
            return newState;
        });
        setSelectedNetwork(-1);
    };

    useEffect(() => {
        setNameMatchesExisting(
            searchComplete && !!searchResults && searchResults.length > 0 && searchResults[0].isExactMatch
        );
        setNameIsSimilar(searchComplete && !!searchResults && searchResults.length > 0);
    }, [searchComplete, searchResults, setNameMatchesExisting, setNameIsSimilar]);

    const clickableOrgRedirect = (tagId, onClickFunk, message, orgId) => {
        return (
            <>
                {message}
                <a id={tagId} href="" onClick={e => onClickFunk(e, orgId)}>
                    <u>here →</u>
                </a>
            </>
        );
    };

    const wrapFeedback = (message, failsValidation = false) => (
        <h6 id="org-name-feedback" className={`${failsValidation ? 'danger-text' : 'warning-text'}`}>
            {message}
        </h6>
    );

    const orgNameFeedbackMessage = useCallback(
        (onClickFunc = () => {}) => {
            if (
                !searchComplete ||
                !searchResults ||
                searchResults.length === 0 ||
                (!nameIsSimilar && !nameMatchesExisting)
            )
                return <></>;

            const clickable = !staticOrgs.includes(searchResults[0].key);

            let message = `"${orgName}" ${
                nameMatchesExisting ? `already exists!` : `is similar to "${searchResults[0].value}".`
            } `;

            if (clickable) {
                message = clickableOrgRedirect(
                    nameMatchesExisting ? ORG_NAME_EXISTS_REDIRECT_ID : ORG_NAME_SIMILAR_REDIRECT_ID,
                    onClickFunc,
                    (message += ` To ${stagedCompId ? 'add to' : 'edit'} existing organization, click `),
                    searchResults[0].key
                );
            }

            return wrapFeedback(message, nameMatchesExisting);
        },
        [searchComplete, orgName, stagedCompId, searchResults, staticOrgs, nameIsSimilar, nameMatchesExisting]
    );

    return {
        org,
        orgName,
        handleToggle,
        setOrgName,
        contactPersonId,
        setContactPersonId,
        orgUsers,
        address,
        setAddress,
        city,
        setCity,
        zip,
        setZip,
        state,
        setState,
        countryId,
        setCountryId,
        notes,
        setNotes,
        inEditState,
        onDeleteClick,
        onAddNetwork,
        selectedNetwork,
        setSelectedNetwork,
        saveEnabled,
        handleSave,
        setSearchTerm,
        orgNameFeedbackMessage,
        segmentId,
        setSegmentId,
    };
};

export default useOrganizationModal;
