import React, { useState, useEffect, useContext } from 'react';
import LinksTagsCheckboxItem from 'components/Shared/Components/LinkTagsCheckboxItem';
import { requestLinkTagNameMatchesInBook, requestLinkTagTextMatchesInBook } from 'api/RepairProcedureApi';
import { ToastContext } from 'components/ToastProvider';

const LinkTagMatches = ({ tag, linkedTags, viewModalOnClick, bookId }) => {
    const [tagNameMatches, setTagNameMatches] = useState([]);
    const [textMatches, setTextMatches] = useState([]);
    const { showToast } = useContext(ToastContext);

    useEffect(() => {
        const getTagMatches = async () => {
            if (tag) {
                try {
                    let nameMatches = await requestLinkTagNameMatchesInBook(encodeURIComponent(tag.title), bookId);
                    let textMatches = await requestLinkTagTextMatchesInBook(encodeURIComponent(tag.text), bookId);

                    nameMatches = nameMatches.filter(n => n.stagedOneTimeUseTagId !== tag.stagedOneTimeUseTagId);
                    textMatches = textMatches.filter(n => n.stagedOneTimeUseTagId !== tag.stagedOneTimeUseTagId);

                    setTagNameMatches(nameMatches);
                    setTextMatches(textMatches);
                } catch (error) {
                    showToast(error);
                }
            }
        };
        getTagMatches();
    }, [tag, bookId, showToast]);

    useEffect(() => {
        if (linkedTags.length > 0) {
            let linkedTagIds = linkedTags.map(l => l.stagedOneTimeUseTagId);

            let newNameMatches = [...tagNameMatches];
            let newTextMatches = [...textMatches];
            newNameMatches = newNameMatches.filter(t => !linkedTagIds.includes(t.stagedOneTimeUseTagId));
            newTextMatches = newTextMatches.filter(t => !linkedTagIds.includes(t.stagedOneTimeUseTagId));

            if (newNameMatches.length !== tagNameMatches.length) setTagNameMatches(newNameMatches);
            if (newTextMatches.length !== textMatches.length) setTextMatches(newTextMatches);
        }
    }, [linkedTags, tagNameMatches, textMatches]);

    return (
        <div>
            <div className="mt-3">
                <h6 className="font-weight-bold text-primary border p-2 m-0">
                    Matching Tag Name ({tagNameMatches.length}):
                </h6>
                {tagNameMatches.map((m, index) => {
                    return (
                        <LinksTagsCheckboxItem
                            key={index}
                            text="Tag Name"
                            value={m.title}
                            tag={m}
                            modelId={'viewprocedure-modal'}
                            viewModalOnClick={e => viewModalOnClick(e, m.procedureId)}
                            parentTag={tag}
                        />
                    );
                })}
            </div>

            <div className="mt-3">
                <h6 className="font-weight-bold text-primary border p-2 m-0">
                    Matching Tag Text ({textMatches.length}):
                </h6>
                {textMatches.map((m, index) => {
                    return (
                        <LinksTagsCheckboxItem
                            key={index}
                            text="Tag Name"
                            value={m.title}
                            tag={m}
                            modelId={'viewprocedure-modal'}
                            viewModalOnClick={e => viewModalOnClick(e, m.procedureId)}
                            parentTag={tag}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default LinkTagMatches;
