import Breadcrumb from 'components/Navigation/items/Breadcrumb';
import { useParams, useLocation } from 'react-router-dom';
import { CUST_TOOLS, CUST_DICT } from 'components/locations/ManageCustomers/ManageCustomersConstants';

const titleCase = str => {
    try {
        return str
            .trim() //Required to prevent spaces from breaking logic below
            .split(' ')
            .map(word => (word ? word[0].toUpperCase() + word.slice(1) : word))
            .join(' ');
    } catch (e) {
        return str;
    }
};

// top of page navigation for customer management
const ManageCustomersNavigation = () => {
    const { customerTool, organizationId, orgTool, locationId, locTool } = useParams();
    const { state, pathname } = useLocation();

    const orgSelected = organizationId > 0;
    const displayOrg = orgSelected
        ? `${titleCase(state?.org?.name ?? CUST_TOOLS.orgTool.terminus + organizationId)}`
        : CUST_TOOLS.orgTool.name;

    const compSelected = orgSelected && locationId > 0;
    const displayComp = compSelected
        ? `${titleCase(state?.company?.companyName ?? CUST_TOOLS.compTool.terminus + locationId)}`
        : CUST_TOOLS.compTool.name;

    const isLocationsSearchResultsRoute = pathname?.includes(
        `${CUST_TOOLS.orgTool.route}/${CUST_TOOLS.compTool.route}`
    );
    const isUsersSearchResultsRoute = pathname?.includes(
        `${CUST_TOOLS.orgTool.route}/${CUST_TOOLS.compUserTool.route}`
    );

    return (
        <ol className="breadcrumb">
            <Breadcrumb
                isShown={true}
                isCurrent={!customerTool}
                link={`/${CUST_TOOLS.baseTool.route}`}
                text={`${CUST_TOOLS.baseTool.name}`}
            />
            {customerTool && (
                <Breadcrumb
                    isShown={true}
                    isCurrent={!orgSelected && !isLocationsSearchResultsRoute && !isUsersSearchResultsRoute}
                    link={`${CUST_TOOLS.orgTool.route}`}
                    text={`${CUST_DICT[customerTool]}`}
                />
            )}
            {orgSelected ? (
                <>
                    <Breadcrumb
                        isShown={true}
                        isCurrent={!orgTool}
                        link={`${CUST_TOOLS.orgTool.Path(organizationId)}`}
                        text={`${displayOrg}`}
                    />
                    <Breadcrumb
                        isCurrent={!locationId}
                        isShown={true}
                        link={`${CUST_TOOLS.orgTool.Path(organizationId)}`}
                        text={
                            compSelected
                                ? `${displayComp}`
                                : orgTool
                                ? `${titleCase(orgTool)}`
                                : `${CUST_TOOLS.compTool.name}`
                        }
                    />
                </>
            ) : isLocationsSearchResultsRoute ? (
                <Breadcrumb isShown={true} isCurrent={true} text={`${CUST_TOOLS.compTool.name}`} />
            ) : null}
            {compSelected ? (
                <Breadcrumb
                    isShown={true}
                    isCurrent={true}
                    link={CUST_TOOLS.compUserTool.Path(organizationId, locationId)}
                    text={`${titleCase(locTool)}`}
                />
            ) : isUsersSearchResultsRoute ? (
                <Breadcrumb isShown={true} isCurrent={true} text={`${CUST_TOOLS.compUserTool.name}`} />
            ) : null}
        </ol>
    );
};

export default ManageCustomersNavigation;
