import React, { FC, useState, useEffect } from 'react';
import { CellProps, TextAreaCellOptions } from './types';
import calculateClass from 'components/Shared/Table/Cells/Helpers/calculateClass';
import { TextArea } from 'oemiq-common';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VehiclePublishExceptionTrackerFieldEnum } from 'helpers/VehicleProcessHelper';

const PublishExceptionTicket: (options?: TextAreaCellOptions) => FC<CellProps> = options => {
    const PublishExceptionTicketComponent = ({ value = '', header, item, index }: CellProps) => {
        const [editing, setEditing] = useState(false);
        const [text, setText] = useState<string | number>(value);
        const handleClick = () => {
            setEditing(true);
        };
        const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
            setEditing(false);
            options.textArea.onBlur(
                item ? item : {},
                VehiclePublishExceptionTrackerFieldEnum.PUBLISH_EXCEPTION_TICKET.Id,
                e.target.value
            );
        };

        const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
            setText(event.target.value);
        };
        useEffect(() => {
            setText(value ? value : '');
        }, [value]);
        const displayText = (inputValue: string | number) => {
            const urlPattern = /^(https):\/\/[^ "]+$/;
            if (typeof inputValue === 'string' && urlPattern.test(inputValue)) {
                const lastPart = inputValue.split('/').pop() || inputValue;
                return lastPart;
            }
            return inputValue;
        };
        const handleViewTicketClick = (hyperLink: string | number) => {
            window.open(String(hyperLink));
        };
        return (
            <td
                headers={header.id}
                className={calculateClass(options, item)}
                id={`${header.id}${index}`}
                title={header.title}
                key={`${item.vehicleId}${header}`}>
                {item?.bookId ? (
                    <></>
                ) : editing || isEmpty(value) ? (
                    <TextArea
                        className={'vehicle-publish-textarea custom-textarea-scroll'}
                        id={'form-control-input-ticket' + item.vehicleId}
                        rows={3}
                        placeholder="Paste the ADO full link here"
                        value={text}
                        onChange={handleChange}
                        textAreaProps={{
                            onBlur: handleBlur,
                            maxLength: 100,
                            autoFocus: editing,
                            disabled: options.textArea.disabled,
                        }}
                    />
                ) : (
                    <>
                        <label
                            id={'form-control-clickable-label' + item.vehicleId}
                            className="clickable text-primary"
                            onClick={handleClick}>
                            {displayText(text)}
                        </label>
                        <button
                            id={`publish-exception-ticket-${item.vehicleId}`}
                            type="button"
                            className={'btn btn-sm text-primary'}
                            onClick={() => handleViewTicketClick(text)}>
                            <FontAwesomeIcon className="clickable new-tab-link-icon" icon={'external-link'} />
                        </button>
                    </>
                )}
            </td>
        );
    };

    return PublishExceptionTicketComponent;
};
export default PublishExceptionTicket;
