import React from 'react';
import { FormatDateToHumanReadable } from 'helpers/DateHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MappingTrainingBookList = ({ books, handleDeleteBook }) => {
    return (
        <table className="table table-striped mt-3">
            <thead>
                <tr>
                    <th>Book Name</th>
                    <th>Create Date</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {books.map(b => (
                    <tr key={b.bookId}>
                        <td>{b.bookName}</td>
                        <td>{FormatDateToHumanReadable(b.createDate)}</td>
                        <td>
                            <button
                                id={`delete-book-${b.bookId}`}
                                type="button"
                                className="btn btn-danger btn-sm"
                                onClick={() => handleDeleteBook(b.bookId)}>
                                <FontAwesomeIcon icon="trash" />
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default MappingTrainingBookList;
