import { useState, useEffect, useContext } from 'react';
import { requestBooksForOemApi } from 'api/RepairProcedureApi';
import useGetOemInfo from 'components/Oem/useGetOemInfo';
import { LoadingContext } from 'components/Layout';
import { ToastContext } from 'components/ToastProvider';

const useCreateMappingTrainingModal = handleToggle => {
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const { showToast } = useContext(ToastContext);
    const [trainingManualName, setTrainingManualName] = useState('');
    const { oemInfo } = useGetOemInfo({ hasBooks: true });
    const [displayOems, setDisplayOems] = useState([]);
    const [oem, setOem] = useState(-1);
    const [books, setBooks] = useState([]);
    const [book, setBook] = useState(-1);

    useEffect(() => {
        setDisplayOems(oemInfo.filter(o => o.isActive));
    }, [oemInfo]);

    useEffect(() => {
        const getBooksForOem = async () => {
            if (oem !== -1) {
                try {
                    incrementLoading();
                    setBooks(await requestBooksForOemApi(oem));
                } catch (error) {
                    showToast(error);
                } finally {
                    decrementLoading();
                }
            }
        };
        getBooksForOem();
    }, [oem, showToast, incrementLoading, decrementLoading]);

    const handleModalClose = () => {
        setTrainingManualName('');
        setOem(-1);
        setBook(-1);
        handleToggle();
    };

    return {
        trainingManualName,
        setTrainingManualName,
        oem,
        displayOems,
        setOem,
        book,
        books,
        setBook,
        handleModalClose,
    };
};

export default useCreateMappingTrainingModal;
