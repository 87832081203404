import React from 'react';

const GroupButtonDisplay = ({ children }) => {
    return (
        <div>
            <button
                type="button"
                className="btn btn-outline-primary mb-1"
                style={{ fontSize: '12px', borderRadius: '24px' }}>
                {children}
            </button>
        </div>
    );
};

export default GroupButtonDisplay;
