import React from 'react';
import { PrimaryButton, LightButton } from 'oemiq-common';

const GroupTypeFooter = ({ handleModalToggle, handleMapProcedures }) => {
    return (
        <div className="d-flex justify-content-between w-100">
            <LightButton id="close-group-type-modal" onClick={handleModalToggle}>
                Cancel
            </LightButton>
            <PrimaryButton id="map-procedures" onClick={() => handleMapProcedures()}>
                Map Procedures
            </PrimaryButton>
        </div>
    );
};

export default GroupTypeFooter;
