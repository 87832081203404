import { requestCountries } from 'api/SecurityApi';
import { ToastContext } from 'components/ToastProvider';
import { useContext, useEffect, useState } from 'react';
import { Country } from '../types/CountryTypes';

const useCountries = () => {
    const [countries, setCountries] = useState<Country[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const { showToast } = useContext<{ showToast }>(ToastContext);

    useEffect(() => {
        let isUnmounted = false;

        (async () => {
            try {
                setLoading(true);
                const values = await requestCountries();

                const options: Country[] = values.map(({ countryId, countryName }) => ({
                    key: countryId,
                    value: countryId,
                    text: countryName,
                }));
                if (!isUnmounted) {
                    setCountries(options);
                }
            } catch (error) {
                showToast(error);
            } finally {
                if (!isUnmounted) {
                    setLoading(false);
                }
            }
        })();

        return () => {
            isUnmounted = true;
        };
    }, [showToast]);

    return { loading, countries };
};

export default useCountries;
