import React, { useContext } from 'react';
import useFlagTagItem from './useFlagTagItem';
import TagRowDetails from './TagRowDetails/TagRowDetails';
import FlagRowDetails from './FlagRowDetails/FlagRowDetails';
import OneTimeUseTagUpdate from './OneTimeUseTagUpdate/OneTimeUseTagUpdate';
import OneTimeUseFlagConvert from './OneTimeUseFlagConvert/OneTimeUseFlagConvert';
import useOneTimeUseTagUpdate from './OneTimeUseTagUpdate/useOneTimeUseTagUpdate';
import { TaggerToolContext } from 'components/locations/TaggingProcess/TaggerTool/TaggerTool';
import useOneTimeUseFlagConvert from './OneTimeUseFlagConvert/useOneTimeUseFlagConvert';

const FlagTagItem = ({
    flagTagItem,
    toggleTag,
    toggleFlag,
    handleFlagDispositionClick,
    isScrollToContentEnabled,
    setFlags,
    setTags,
}) => {
    const { quantityConditions } = useContext(TaggerToolContext);
    const {
        isExpanded,
        setIsExpanded,
        handleRowClick,
        handleUpdateTagClick,
        rowStyle,
        restoreFlagDisposition,
        isFlag,
    } = useFlagTagItem(flagTagItem, isScrollToContentEnabled);
    const oneTimeUseTagUpdate = useOneTimeUseTagUpdate(flagTagItem.data, setIsExpanded, setTags, quantityConditions);
    const oneTimeUseFlagConvert = useOneTimeUseFlagConvert(
        flagTagItem.data,
        setIsExpanded,
        setFlags,
        setTags,
        quantityConditions
    );
    return (
        <>
            <div
                role={`tagrow`}
                className="py-3 px-2 mt-2 border rounded d-flex align-items-center clickable justify-content-between"
                style={rowStyle}
                onClick={() => handleRowClick()}>
                {isFlag ? (
                    <FlagRowDetails
                        title={flagTagItem.data.title}
                        isExpanded={isExpanded}
                        isHidden={flagTagItem.data.isHidden}
                        toggleFlag={toggleFlag}
                        oneTimeUseFlagId={flagTagItem.data.oneTimeUseFlagId}
                        flagDisposition={flagTagItem.data.flagDisposition}
                        handleFlagDispositionClick={handleFlagDispositionClick}
                        restoreFlagDisposition={restoreFlagDisposition}
                        flag={flagTagItem.data}
                    />
                ) : (
                    <TagRowDetails
                        isExpanded={isExpanded}
                        toggleTag={toggleTag}
                        tagId={flagTagItem.data.stagedOneTimeUseTagId}
                        handleUpdateTagClick={handleUpdateTagClick}
                        data={flagTagItem.data}
                        handleSplitTagClick={oneTimeUseTagUpdate.handleSplitTagClick}
                    />
                )}
            </div>
            {!isFlag && isExpanded && <OneTimeUseTagUpdate useOneTimeUseTagUpdate={oneTimeUseTagUpdate} />}
            {isFlag && isExpanded && <OneTimeUseFlagConvert useOneTimeUseFlagConvert={oneTimeUseFlagConvert} />}
        </>
    );
};

export default FlagTagItem;
