import React, { useContext } from 'react';
import { LightButton, PrimaryButton, DangerButton, DarkButton, SuccessButton } from 'oemiq-common';
import { MappingDefinitionsContext } from 'contexts/MappingDefinitionsContext';

const GroupTypeBodyGroups = ({ handleRegionClick, selectedGroupIdsWithStatusId }) => {
    const { groups } = useContext(MappingDefinitionsContext);
    return (
        <div>
            <h6>Groups</h6>
            {groups
                .sort((a, b) => {
                    if (a.regionFriendlyName > b.regionFriendlyName) return 1;
                    else return -1;
                })
                .map(g => {
                    let selectedGroup = selectedGroupIdsWithStatusId.filter(s => s.groupId === g.regionId);

                    if (selectedGroup.length > 0) {
                        if (selectedGroup[0].statusId === 1) {
                            return (
                                <PrimaryButton
                                    key={g.regionId}
                                    id={`group-${g.regionId}`}
                                    className="mb-1 me-3"
                                    onClick={() => handleRegionClick(g.regionId)}>
                                    {g.regionFriendlyName}
                                </PrimaryButton>
                            );
                        } else if (selectedGroup[0].statusId === 2) {
                            return (
                                <SuccessButton
                                    key={g.regionId}
                                    id={`group-${g.regionId}`}
                                    className="mb-1 me-3"
                                    onClick={() => handleRegionClick(g.regionId)}>
                                    {g.regionFriendlyName}
                                </SuccessButton>
                            );
                        } else if (selectedGroup[0].statusId === 3) {
                            return (
                                <DangerButton
                                    key={g.regionId}
                                    id={`group-${g.regionId}`}
                                    className="mb-1 me-3"
                                    onClick={() => handleRegionClick(g.regionId)}>
                                    {g.regionFriendlyName}
                                </DangerButton>
                            );
                        } else if (selectedGroup[0].statusId === 100) {
                            return (
                                <DarkButton
                                    key={g.regionId}
                                    id={`group-${g.regionId}`}
                                    className="mb-1 me-3"
                                    onClick={() => handleRegionClick(g.regionId)}>
                                    {g.regionFriendlyName}
                                </DarkButton>
                            );
                        }
                        return null; // should we return something here?
                    } else {
                        return (
                            <LightButton
                                key={g.regionId}
                                id={`group-${g.regionId}`}
                                className="mb-1 me-3"
                                onClick={() => handleRegionClick(g.regionId)}>
                                {g.regionFriendlyName}
                            </LightButton>
                        );
                    }
                })}
        </div>
    );
};

export default GroupTypeBodyGroups;
