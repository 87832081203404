import './theme-colors.scss';

const ThemeColors = () => {
    return (
        <div className="page-wrap d-flex flex-row align-items-center">
            <div className="container">
                <div className="columns">
                    <div className="column">
                        <div className="box-blue-100">
                            <span>blue-100</span>
                        </div>
                        <div className="box-blue-200">
                            <span>blue-200</span>
                        </div>
                        <div className="box-blue-300">
                            <span>blue-300</span>
                        </div>
                        <div className="box-blue-400">
                            <span>blue-400</span>
                        </div>
                        <div className="box-blue-500">
                            <span>blue-500</span>
                        </div>
                        <div className="box-blue-600">
                            <span>blue-600</span>
                        </div>
                        <div className="box-blue-700">
                            <span>blue-700</span>
                        </div>
                        <div className="box-blue-800">
                            <span>blue-800</span>
                        </div>
                        <div className="box-blue-900">
                            <span>blue-900</span>
                        </div>
                    </div>
                    <div className="column">
                        <div className="box-indigo-100">
                            <span>indigo-100</span>
                        </div>
                        <div className="box-indigo-200">
                            <span>indigo-200</span>
                        </div>
                        <div className="box-indigo-300">
                            <span>indigo-300</span>
                        </div>
                        <div className="box-indigo-400">
                            <span>indigo-400</span>
                        </div>
                        <div className="box-indigo-500">
                            <span>indigo-500</span>
                        </div>
                        <div className="box-indigo-600">
                            <span>indigo-600</span>
                        </div>
                        <div className="box-indigo-700">
                            <span>indigo-700</span>
                        </div>
                        <div className="box-indigo-800">
                            <span>indigo-800</span>
                        </div>
                        <div className="box-indigo-900">
                            <span>indigo-900</span>
                        </div>
                    </div>
                    <div className="column">
                        <div className="box-purple-100">
                            <span>purple-100</span>
                        </div>
                        <div className="box-purple-200">
                            <span>purple-200</span>
                        </div>
                        <div className="box-purple-300">
                            <span>purple-300</span>
                        </div>
                        <div className="box-purple-400">
                            <span>purple-400</span>
                        </div>
                        <div className="box-purple-500">
                            <span>purple-500</span>
                        </div>
                        <div className="box-purple-600">
                            <span>purple-600</span>
                        </div>
                        <div className="box-purple-700">
                            <span>purple-700</span>
                        </div>
                        <div className="box-purple-800">
                            <span>purple-800</span>
                        </div>
                        <div className="box-purple-900">
                            <span>purple-900</span>
                        </div>
                    </div>
                    <div className="column">
                        <div className="box-pink-100">
                            <span>pink-100</span>
                        </div>
                        <div className="box-pink-200">
                            <span>pink-200</span>
                        </div>
                        <div className="box-pink-300">
                            <span>pink-300</span>
                        </div>
                        <div className="box-pink-400">
                            <span>pink-400</span>
                        </div>
                        <div className="box-pink-500">
                            <span>pink-500</span>
                        </div>
                        <div className="box-pink-600">
                            <span>pink-600</span>
                        </div>
                        <div className="box-pink-700">
                            <span>pink-700</span>
                        </div>
                        <div className="box-pink-800">
                            <span>pink-800</span>
                        </div>
                        <div className="box-pink-900">
                            <span>pink-900</span>
                        </div>
                    </div>
                    <div className="column">
                        <div className="box-red-100">
                            <span>red-100</span>
                        </div>
                        <div className="box-red-200">
                            <span>red-200</span>
                        </div>
                        <div className="box-red-300">
                            <span>red-300</span>
                        </div>
                        <div className="box-red-400">
                            <span>red-400</span>
                        </div>
                        <div className="box-red-500">
                            <span>red-500</span>
                        </div>
                        <div className="box-red-600">
                            <span>red-600</span>
                        </div>
                        <div className="box-red-700">
                            <span>red-700</span>
                        </div>
                        <div className="box-red-800">
                            <span>red-800</span>
                        </div>
                        <div className="box-red-900">
                            <span>red-900</span>
                        </div>
                    </div>
                    <div className="column">
                        <div className="box-orange-100">
                            <span>orange-100</span>
                        </div>
                        <div className="box-orange-200">
                            <span>orange-200</span>
                        </div>
                        <div className="box-orange-300">
                            <span>orange-300</span>
                        </div>
                        <div className="box-orange-400">
                            <span>orange-400</span>
                        </div>
                        <div className="box-orange-500">
                            <span>orange-500</span>
                        </div>
                        <div className="box-orange-600">
                            <span>orange-600</span>
                        </div>
                        <div className="box-orange-700">
                            <span>orange-700</span>
                        </div>
                        <div className="box-orange-800">
                            <span>orange-800</span>
                        </div>
                        <div className="box-orange-900">
                            <span>orange-900</span>
                        </div>
                    </div>
                    <div className="column">
                        <div className="box-yellow-100">
                            <span>yellow-100</span>
                        </div>
                        <div className="box-yellow-200">
                            <span>yellow-200</span>
                        </div>
                        <div className="box-yellow-300">
                            <span>yellow-300</span>
                        </div>
                        <div className="box-yellow-400">
                            <span>yellow-400</span>
                        </div>
                        <div className="box-yellow-500">
                            <span>yellow-500</span>
                        </div>
                        <div className="box-yellow-600">
                            <span>yellow-600</span>
                        </div>
                        <div className="box-yellow-700">
                            <span>yellow-700</span>
                        </div>
                        <div className="box-yellow-800">
                            <span>yellow-800</span>
                        </div>
                        <div className="box-yellow-900">
                            <span>yellow-900</span>
                        </div>
                    </div>
                    <div className="column">
                        <div className="box-green-100">
                            <span>green-100</span>
                        </div>
                        <div className="box-green-200">
                            <span>green-200</span>
                        </div>
                        <div className="box-green-300">
                            <span>green-300</span>
                        </div>
                        <div className="box-green-400">
                            <span>green-400</span>
                        </div>
                        <div className="box-green-500">
                            <span>green-500</span>
                        </div>
                        <div className="box-green-600">
                            <span>green-600</span>
                        </div>
                        <div className="box-green-700">
                            <span>green-700</span>
                        </div>
                        <div className="box-green-800">
                            <span>green-800</span>
                        </div>
                        <div className="box-green-900">
                            <span>green-900</span>
                        </div>
                    </div>
                    <div className="column">
                        <div className="box-teal-100">
                            <span>teal-100</span>
                        </div>
                        <div className="box-teal-200">
                            <span>teal-200</span>
                        </div>
                        <div className="box-teal-300">
                            <span>teal-300</span>
                        </div>
                        <div className="box-teal-400">
                            <span>teal-400</span>
                        </div>
                        <div className="box-teal-500">
                            <span>teal-500</span>
                        </div>
                        <div className="box-teal-600">
                            <span>teal-600</span>
                        </div>
                        <div className="box-teal-700">
                            <span>teal-700</span>
                        </div>
                        <div className="box-teal-800">
                            <span>teal-800</span>
                        </div>
                        <div className="box-teal-900">
                            <span>teal-900</span>
                        </div>
                    </div>
                    <div className="column">
                        <div className="box-cyan-100">
                            <span>cyan-100</span>
                        </div>
                        <div className="box-cyan-200">
                            <span>cyan-200</span>
                        </div>
                        <div className="box-cyan-300">
                            <span>cyan-300</span>
                        </div>
                        <div className="box-cyan-400">
                            <span>cyan-400</span>
                        </div>
                        <div className="box-cyan-500">
                            <span>cyan-500</span>
                        </div>
                        <div className="box-cyan-600">
                            <span>cyan-600</span>
                        </div>
                        <div className="box-cyan-700">
                            <span>cyan-700</span>
                        </div>
                        <div className="box-cyan-800">
                            <span>cyan-800</span>
                        </div>
                        <div className="box-cyan-900">
                            <span>cyan-900</span>
                        </div>
                    </div>
                    <div className="column">
                        <div className="box-gray-500">
                            <span>gray-500</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ThemeColors;
