import { ProcedureVehicle } from '../Mapper/ESProcedure';

interface VehicleKey {
    oemId: number;
    modelId: number;
}

export interface VehicleYearRange {
    oemId: number;
    modelId: number;
    startYear: number;
    endYear: number;
}

export function groupModelsByYears(procedureVehicles: ProcedureVehicle[]): VehicleYearRange[] {
    const groups = new Map<string, number[]>();
    procedureVehicles.forEach(pv => {
        const vehicleKey: VehicleKey = {
            oemId: pv.oemId,
            modelId: pv.modelId,
        };
        const key = JSON.stringify(vehicleKey);
        if (groups.has(key)) {
            groups.get(key).push(pv.yearId);
        } else {
            groups.set(key, [pv.yearId]);
        }
    });

    const vehiclesWithRanges: VehicleYearRange[] = [];

    for (const [key, years] of groups) {
        const { oemId, modelId }: VehicleKey = JSON.parse(key);
        const sortedYears = [...new Set(years)].sort((a, b) => a - b);
        let startYear = sortedYears[0];
        let previousYear = startYear;

        sortedYears.forEach(year => {
            if (year - previousYear > 1) {
                vehiclesWithRanges.push({ oemId, modelId, startYear, endYear: previousYear });
                startYear = year;
            }
            previousYear = year;
        });
        vehiclesWithRanges.push({ oemId, modelId, startYear, endYear: previousYear });
    }

    return vehiclesWithRanges.sort((a, b) => a.oemId - b.oemId || a.modelId - b.modelId || a.startYear - b.startYear);
}
