import { useState, useEffect, useContext, useCallback } from 'react';
import { LoadingContext } from 'components/Layout';

const useMappingWorkflowStatus = () => {
    const [mappingWorkFlowStatuses, setMappingWorkflowStatus] = useState([]);
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);

    useEffect(() => {
        const getMappingWorkflowStatus = async () => {
            incrementLoading();
            const workFlowStatuses = [
                { mappingWorkFlowStatusId: 1, mappingWorkFlowStatusName: 'In Review' },
                { mappingWorkFlowStatusId: 2, mappingWorkFlowStatusName: 'Complete' },
                { mappingWorkFlowStatusId: 3, mappingWorkFlowStatusName: 'Needs Help' },
                { mappingWorkFlowStatusId: 4, mappingWorkFlowStatusName: 'Auto Mapped' },
            ];
            setMappingWorkflowStatus(workFlowStatuses);
            decrementLoading();
        };
        getMappingWorkflowStatus();
    }, [incrementLoading, decrementLoading]);

    const getMappingWorkFlowStatusByName = useCallback(
        mappingWorkFlowStatusName => {
            return mappingWorkFlowStatuses.find(m => m.mappingWorkFlowStatusName === mappingWorkFlowStatusName);
        },
        [mappingWorkFlowStatuses]
    );

    const getMappingWorkFlowStatusById = useCallback(
        mappingWorkFlowStatusId => {
            return mappingWorkFlowStatuses.find(m => m.mappingWorkFlowStatusId === mappingWorkFlowStatusId);
        },
        [mappingWorkFlowStatuses]
    );

    return { getMappingWorkFlowStatusByName, getMappingWorkFlowStatusById };
};

export default useMappingWorkflowStatus;
