import { useCallback, useEffect, useState } from 'react';
import { ISelectOption } from '../types';

const useCheckboxDropdown = (
    handleSelectionChange: (options: (string | number)[]) => void,
    options: ISelectOption[],
    defaultSelected: string[]
) => {
    const [allOptionState, setAllOptionState] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState<(string | number)[]>([]);
    const [visibleOptions, setVisibleOptions] = useState([]);
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        setVisibleOptions(options);
    }, [options]);

    useEffect(() => {
        if (defaultSelected.length) {
            setSelectedOptions(defaultSelected);
            if (defaultSelected.length == options.length) {
                setAllOptionState(true);
            }
        }
    }, [defaultSelected, options.length]);

    useEffect(() => {
        setIsActive(selectedOptions.length && selectedOptions.length != options.length);
    }, [options.length, selectedOptions.length]);

    const handleCheckboxChange = useCallback(
        (value: string | number) => {
            let updatedSelection: (string | number)[];
            if (selectedOptions.includes(value)) {
                updatedSelection = selectedOptions.filter(item => item !== value);
            } else {
                updatedSelection = [...selectedOptions, value];
            }
            if (updatedSelection.length != options.length && allOptionState) {
                setAllOptionState(false);
            } else if (updatedSelection.length == options.length && !allOptionState) {
                setAllOptionState(true);
            }
            setSelectedOptions(updatedSelection);
            handleSelectionChange(updatedSelection);
        },
        [allOptionState, handleSelectionChange, options.length, selectedOptions]
    );

    const handleSearchTermChange = useCallback(
        (value: string) => {
            setVisibleOptions(options.filter(o => (o.value as string).includes(value)));
        },
        [options]
    );

    const handleAllOptionClick = useCallback(() => {
        let updatedSelection: (string | number)[];
        if (!allOptionState) {
            updatedSelection = options.map(o => o.value);
            setSelectedOptions(updatedSelection);
            handleSelectionChange(updatedSelection);
            setAllOptionState(true);
        } else {
            updatedSelection = [];
            setSelectedOptions(updatedSelection);
            handleSelectionChange(updatedSelection);
            setAllOptionState(false);
        }
    }, [allOptionState, handleSelectionChange, options]);

    const handleMouseOverTrigger = useCallback(() => {
        setIsActive(true);
    }, []);

    const handleMouseLeavingTrigger = useCallback(() => {
        setIsActive(selectedOptions.length && selectedOptions.length != options.length);
    }, [options.length, selectedOptions.length]);

    return {
        allOptionState,
        selectedOptions,
        handleCheckboxChange,
        handleAllOptionClick,
        handleSearchTermChange,
        visibleOptions,
        isActive,
        handleMouseOverTrigger,
        handleMouseLeavingTrigger,
    };
};

export default useCheckboxDropdown;
