import React from 'react';
import { useState } from 'react';

export const TaggerContext = React.createContext();

const TaggerContextProvider = ({ children }) => {
    const [displayProcedureIds, setDisplayProcedureIds] = useState([]);
    const [displayTagIds, setDisplayTagIds] = useState([]);

    return (
        <TaggerContext.Provider
            value={{
                displayProcedureIds,
                setDisplayProcedureIds,
                displayTagIds,
                setDisplayTagIds,
            }}>
            {children}
        </TaggerContext.Provider>
    );
};

export default TaggerContextProvider;
