import { useState, useCallback, useRef, useEffect, useContext } from 'react';
import { NotificationsContext } from 'components/Shared/Notifications/Notifications';
const MINIMUM_SPEED_IN_KILOBITS_PER_SECOND = 16;
const TEST_IMG_URL = 'https://stroemiqrrpsimgs.blob.core.windows.net/oemiq/turtle.png'; //a small image
const DOWNLOAD_SIZE_KILOBITS = 14736;

const useUserSpeedCheck = () => {
    const { notifications } = useContext(NotificationsContext);
    const [showSpeedWarning, setShowSpeedWarning] = useState(false);
    const [sampledSpeedKbps, setSampledSpeedKbps] = useState(0); //will always update between runs
    const speedCheckInterval = useRef(null);
    const speedWarningId = useRef(null);

    const calcSpeed = useCallback(() => {
        try {
            let startTime, endTime;
            let downloadImgSrc = new Image();

            downloadImgSrc.onload = function () {
                endTime = new Date().getTime();
                displaySpeed();
            };

            startTime = new Date().getTime();
            let cacheBuster = '?nn=' + startTime;
            downloadImgSrc.src = TEST_IMG_URL + cacheBuster;

            const displaySpeed = () => {
                let timeDuration = (endTime - startTime) / 1000;
                let loadedBits = DOWNLOAD_SIZE_KILOBITS * 8;

                let bps = (loadedBits / timeDuration).toFixed(2);
                let speedInKbps = (bps / 1024).toFixed(2);

                //update the warning state
                setShowSpeedWarning(speedInKbps < MINIMUM_SPEED_IN_KILOBITS_PER_SECOND);
                setSampledSpeedKbps(speedInKbps);
            };
        } catch (error) {
            null;
        }
    }, []);

    const speedCheck = useCallback(() => {
        if (!document.hidden) {
            if (!window.navigator.onLine) setShowSpeedWarning(true);
            else calcSpeed();
        }
    }, [setShowSpeedWarning, calcSpeed]);

    useEffect(() => {
        if (showSpeedWarning && speedWarningId.current === null) {
            speedWarningId.current = notifications.getNextId();
            notifications.pushExceptionDanger(
                new Error('Slow or no internet connection detected, please check your internet settings.')
            );
        } else if (!showSpeedWarning) {
            if (speedWarningId.current !== null) notifications.hideNotification(speedWarningId.current);
            speedWarningId.current = null;
        }
    }, [showSpeedWarning, sampledSpeedKbps, notifications]);

    useEffect(() => {
        if (!speedCheckInterval.current) {
            speedCheckInterval.current = setInterval(speedCheck, 30000);
        }
    }, [speedCheck, speedCheckInterval]);

    return {};
};

export default useUserSpeedCheck;
