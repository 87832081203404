import { requestOrganizationUsers } from 'api/SecurityApi';
import { AccessControlContext } from 'components/Shared/AccessControl/AccessControl';
import useNotifications from 'components/Shared/Notifications/useNotifications';
import { useCallback, useContext, useEffect, useState } from 'react';

const useGetUserName = () => {
    const { userInfo } = useContext(AccessControlContext);
    const [organizationUsers, setOrganizationUsers] = useState([]);
    const { notifications } = useNotifications();
    const getOrganizationUsers = useCallback(async () => {
        try {
            const data = await requestOrganizationUsers(userInfo.organization.organizationId);
            setOrganizationUsers(data);
        } catch (error) {
            notifications.pushExceptionDanger(error);
        }
    }, [userInfo, notifications]);

    useEffect(() => {
        getOrganizationUsers();
    }, [getOrganizationUsers]);

    const getUserNameById = useCallback(
        userId => {
            if (userId === 0) return '-';
            const name = organizationUsers && organizationUsers.find(user => user.userId === userId)?.userName;
            return name ? name : `ID:${userId}`;
        },
        [organizationUsers]
    );

    return {
        getUserNameById,
    };
};

export default useGetUserName;
