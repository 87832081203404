import React from 'react';
import { useEffect } from 'react';
import authService from './AuthenticationService';
import { LoadingIndicator } from 'oemiq-common';
import { AuthenticationResultStatus } from './AuthenticationService';
import { ApplicationPaths, QueryParameterNames } from './AuthenticationConstants';

const getReturnUrl = () => {
    const params = new URLSearchParams(window.location.search);
    const fromQuery = decodeURIComponent(params.get(QueryParameterNames.ReturnUrl));
    if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
        // This is an extra check to prevent open redirects.
        throw new Error('Invalid return url. The return url needs to have the same origin as the current page.');
    }
    return fromQuery || `${window.location.origin}/`;
};

const navigateToUrl = (returnUrl: string) => {
    // It's important that we do a replace here so that we remove the callback uri with the
    // fragment containing the tokens from the browser history.
    window.location.replace(returnUrl);
};

export const Login = () => {
    useEffect(() => {
        const login = async () => {
            const state = { returnUrl: getReturnUrl() };
            const result = await authService.signIn(state);
            switch (result.status) {
                case AuthenticationResultStatus.Success:
                    navigateToUrl(state.returnUrl);
                    break;
                case AuthenticationResultStatus.Fail:
                    navigateToUrl(
                        `${window.location.origin}/${ApplicationPaths.LoginFailed}?${QueryParameterNames.Message}=${result.message}`
                    );
                    break;
                case AuthenticationResultStatus.Redirect:
                    break;
            }
        };

        login();
    }, []);

    return <LoadingIndicator />;
};

export const LoginCallback = () => {
    useEffect(() => {
        const processLoginCallback = async () => {
            const url = window.location.href;
            const result = await authService.completeSignIn(url);

            switch (result.status) {
                case AuthenticationResultStatus.Success:
                    navigateToUrl(result?.state?.returnUrl ?? getReturnUrl());
                    break;
                case AuthenticationResultStatus.Fail:
                    navigateToUrl(
                        `${window.location.origin}/${ApplicationPaths.LoginFailed}?${QueryParameterNames.Message}=${result.message}`
                    );
                    break;
            }
        };

        processLoginCallback();
    }, []);

    return <LoadingIndicator />;
};

export const LoginFailed = () => {
    const params = new URLSearchParams(window.location.search);
    const error = params.get(QueryParameterNames.Message);

    return <div>{error}</div>;
};
