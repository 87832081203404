import React from 'react';
import { Input } from 'oemiq-common';
import ReactSelect from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAddNewTag from './useAddNewTag';
import { FormatCurrency } from 'helpers/CurrencyHelper';
import ImagePreview from '../TagsAndFlagsDisplay/FlagTagItem/ImagePreview/ImagePreview';

const AddNewTag = ({ quantityConditions, partTypes, setIsAddingNewTag, setTags }) => {
    const {
        tagName,
        setTagName,
        quantity,
        setQuantity,
        quantityCondition,
        setQuantityCondition,
        partNumber,
        setPartNumber,
        price,
        setPrice,
        partType,
        setPartType,
        isSelectingImg,
        setIsSelectingImage,
        selectedImages,
        handleRemoveImageClick,
        tagText,
        setTagText,
        handleSelectTagRegion,
        handleClearTagRegion,
        note,
        setNote,
        handleSaveClick,
        isTitleBlank,
    } = useAddNewTag(setIsAddingNewTag, setTags);

    return (
        <div className="mt-3">
            <Input
                type="text"
                placeholder="Tag Name"
                className="form-control form-control-sm"
                formGroupClassName="mb-2"
                value={tagName}
                onChange={e => setTagName(e.currentTarget.value)}
            />
            <Input
                type="text"
                placeholder="Quantity"
                className="form-control form-control-sm mt-2"
                formGroupClassName="mb-2"
                value={quantity}
                onChange={e => setQuantity(isNaN(e.currentTarget.value) ? '' : e.currentTarget.value)}
            />
            <ReactSelect
                defaultValue={quantityConditions.find(c => c.value === quantityCondition)}
                options={quantityConditions}
                onChange={e => setQuantityCondition(e.value)}
            />
            <Input
                type="text"
                placeholder="Part Number"
                className="form-control form-control-sm mt-2"
                formGroupClassName="mb-2"
                value={partNumber || ''}
                onChange={e => setPartNumber(e.currentTarget.value)}
            />
            <Input
                type="text"
                placeholder="Part Price"
                className="form-control form-control-sm mt-2"
                formGroupClassName="mb-2"
                value={price}
                step="0.01"
                onChange={e => setPrice(e.currentTarget.value)}
                onBlur={() => setPrice(FormatCurrency(price, 2))}
            />
            <select
                id="partType"
                className="form-select form-control-sm mt-2"
                value={partType}
                onChange={e => setPartType(e.currentTarget.value)}>
                <option value="">Select Part Type</option>
                {partTypes.map((p, index) => (
                    <option key={index} value={p.value}>
                        {p.text}
                    </option>
                ))}
            </select>
            <div className="mt-2">
                <div>Activate desired command button and click image to add/remove images</div>
                <button
                    type="button"
                    className={`btn btn-sm ${isSelectingImg ? 'btn-primary' : 'btn-secondary'} mt-2`}
                    onClick={() => setIsSelectingImage(!isSelectingImg)}>
                    Select Images: {isSelectingImg ? 'On' : 'Off'}
                </button>
                <div className="mt-2">
                    {selectedImages.map((img, index) => (
                        <div key={index} style={{ position: 'relative' }}>
                            <span style={{ position: 'absolute', top: -5, left: 10 }}>
                                <FontAwesomeIcon
                                    className="clickable"
                                    icon="times-circle"
                                    onClick={() => handleRemoveImageClick(img)}
                                />
                            </span>
                            <ImagePreview alt={`added-${index}`} img={img} width={150} />
                        </div>
                    ))}
                </div>
            </div>
            <textarea
                rows={4}
                className="form-control form=control-sm mt-2"
                placeholder="Tag Text"
                value={tagText || ''}
                onChange={e => setTagText(e.currentTarget.value)}></textarea>
            <div className="mt-2">
                <div>
                    Highlight the region in the document on the left, and then press &lsquo;Save Tag Region&rsquo;
                </div>
                <button
                    type="button"
                    className={'btn btn-sm btn-primary mt-2 me-2'}
                    onClick={() => handleSelectTagRegion()}>
                    Save Tag Region
                </button>
                <button
                    type="button"
                    className={'btn btn-sm btn-secondary mt-2'}
                    onClick={() => handleClearTagRegion()}>
                    Clear Region
                </button>
            </div>
            <textarea
                rows={2}
                className="form-control form-control-sm mt-2"
                id="note"
                placeholder="Note"
                value={note || ''}
                onChange={e => setNote(e.currentTarget.value)}></textarea>

            <div className="mt-2 d-flex justify-content-end">
                <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    onClick={() => handleSaveClick()}
                    disabled={isTitleBlank}>
                    Save
                </button>
            </div>
        </div>
    );
};

export default AddNewTag;
