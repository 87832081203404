import { DataSource } from './DataSource';
import { ProcedurePropertyMetadata } from './ProcedureProperty';
import { ProcedureType } from './ProcedureType';

export interface FilterProperty {
    id: string;
    property: string;
    label: string;
    type;
    ruleable: boolean;
    allowInstances: number;
    dataSources: DataSource[];
    procedureTypes: ProcedureType[];
    isAppOnly?: boolean;
}

export type OemMetadataProperties = ProcedurePropertyMetadata<unknown, unknown>[] & {
    [key: string]: ProcedurePropertyMetadata<unknown, unknown>;
};

export interface OemMetadata {
    oemId: number;
    oemName: string;
    properties: OemMetadataProperties;
    filters: FilterProperty[];
    ruleKey: string;
    ruleable: ProcedurePropertyMetadata<unknown, unknown>[];
}

export interface OemMetadataView {
    key: {
        dataSource: DataSource;
        procedureType: ProcedureType;
        columnSettingsPostfix: string;
    };
    metadata: OemMetadata;
    properties: string[];
    filters: string[];
    ruleable: string[];
}

export const buildOemMetadata = ({
    oemId,
    oemName,
    properties,
    ruleKey,
    filters = null,
}: {
    oemId: number;
    oemName: string;
    properties: ProcedurePropertyMetadata<unknown, unknown>[];
    ruleKey: string;
    filters?: FilterProperty[];
}): OemMetadata => {
    const keyedProperties = [...properties] as OemMetadataProperties;

    for (const el of properties) {
        keyedProperties[el.id] = el;
    }
    return {
        oemId,
        oemName,
        properties: keyedProperties,
        ruleKey,
        ruleable: properties.filter(p => p.ruleable),
        filters: filters ?? [],
    };
};

export const buildFilteredOemMetadata = (
    metadata: OemMetadata,
    procedureType: ProcedureType | null,
    dataSource: DataSource
): OemMetadataView => {
    const dataKey = procedureType === null ? '' : `:${procedureType}`;
    const filterProperties = (p: { dataSources: DataSource[]; procedureTypes: ProcedureType[] }): boolean =>
        p.dataSources.includes(dataSource) && (procedureType == null || p.procedureTypes.includes(procedureType));
    return {
        key: { dataSource, procedureType, columnSettingsPostfix: dataKey },
        metadata,
        properties: metadata.properties.filter(filterProperties).map(p => p.id),
        filters: metadata.filters.filter(filterProperties).map(p => p.id),
        ruleable: metadata.ruleable.filter(filterProperties).map(p => p.id),
    };
};
