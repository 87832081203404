export const VM_TABLE_COLUMN_ID = {
    TRIM_FILTERING: 1,
    MODEL_ASSIGNMENT: 2,
    OEM_MODEL_NAME: 3,
    DISPLAY_TAG_STATE: 4,
    LOCALE_NAME: 5,
    IS_REVIEWED: 6,
};

export const VM_TABLE_COLUMN_NAME_BY_ID = {
    1: 'isTrimLevelFilteringEnabled',
    2: 'visualModel',
    3: 'oemModel',
    4: 'isDisplayingTagsEnabled',
    5: 'locales',
    6: 'isReviewed',
};

export const VM_OPERATOR_TYPE = {
    EQUAL: {
        id: 1,
        text: 'Equal',
    },
    NOT_EQUAL: {
        id: 2,
        text: 'Not equal',
    },
    CONTAINS: {
        id: 3,
        text: 'Contains',
    },
    NOT_CONTAIN: {
        id: 4,
        text: 'Does not contain',
    },
    CONTAINS_EXCLUSIVELY: {
        id: 5,
        text: 'Contains Exclusively',
    },
};

export const VM_TABLE_FILTER_COLUMNS = [
    {
        key: VM_TABLE_COLUMN_ID.TRIM_FILTERING,
        value: VM_TABLE_COLUMN_ID.TRIM_FILTERING,
        text: 'Trim Filtering',
    },
    {
        key: VM_TABLE_COLUMN_ID.MODEL_ASSIGNMENT,
        value: VM_TABLE_COLUMN_ID.MODEL_ASSIGNMENT,
        text: '3D Model Assigned',
    },
    {
        key: VM_TABLE_COLUMN_ID.OEM_MODEL_NAME,
        value: VM_TABLE_COLUMN_ID.OEM_MODEL_NAME,
        text: 'OEM Model Name assigned',
    },
    {
        key: VM_TABLE_COLUMN_ID.DISPLAY_TAG_STATE,
        value: VM_TABLE_COLUMN_ID.DISPLAY_TAG_STATE,
        text: 'Display Tags',
    },
    {
        key: VM_TABLE_COLUMN_ID.LOCALE_NAME,
        value: VM_TABLE_COLUMN_ID.LOCALE_NAME,
        text: 'Locale(s)',
    },
    {
        key: VM_TABLE_COLUMN_ID.IS_REVIEWED,
        value: VM_TABLE_COLUMN_ID.IS_REVIEWED,
        text: 'Is Reviewed',
    },
];

export const VM_INPUT_FILTER_VALUE = {
    TRUE: 'true',
    FALSE: 'false',
    NOT_COMPLETED: 'flagsortagsincomplete',
    EMPTY: 'empty',
};

export const VM_FILTER_OPTIONS = [
    {
        key: 1,
        value: VM_INPUT_FILTER_VALUE.TRUE,
        text: 'Yes',
    },
    {
        key: 2,
        value: VM_INPUT_FILTER_VALUE.FALSE,
        text: 'No',
    },
];

export const VM_FILTER_OPTION_WITH_NOTCOMPLETED = VM_FILTER_OPTIONS.concat([
    {
        key: 3,
        value: VM_INPUT_FILTER_VALUE.NOT_COMPLETED,
        text: 'Flags/tags not complete',
    },
]);

export const VM_ACTIONS = {
    [VM_TABLE_COLUMN_ID.TRIM_FILTERING]: {
        operators: [
            {
                key: VM_OPERATOR_TYPE.EQUAL.id,
                value: VM_OPERATOR_TYPE.EQUAL.id,
                text: VM_OPERATOR_TYPE.EQUAL.text,
            },
            {
                key: VM_OPERATOR_TYPE.NOT_EQUAL.id,
                value: VM_OPERATOR_TYPE.NOT_EQUAL.id,
                text: VM_OPERATOR_TYPE.NOT_EQUAL.text,
            },
        ],
        inputType: {
            id: 2,
            props: {
                defaultOption: { value: '', text: 'Select a Trim Filtering' },
                options: VM_FILTER_OPTIONS,
            },
        },
    },
    [VM_TABLE_COLUMN_ID.MODEL_ASSIGNMENT]: {
        operators: [
            {
                key: VM_OPERATOR_TYPE.EQUAL.id,
                value: VM_OPERATOR_TYPE.EQUAL.id,
                text: VM_OPERATOR_TYPE.EQUAL.text,
            },
            {
                key: VM_OPERATOR_TYPE.NOT_EQUAL.id,
                value: VM_OPERATOR_TYPE.NOT_EQUAL.id,
                text: VM_OPERATOR_TYPE.NOT_EQUAL.text,
            },
        ],
        inputType: {
            id: 2,
            props: {
                defaultOption: { value: '', text: 'Select 3D Model Assigned' },
                options: VM_FILTER_OPTIONS,
            },
        },
    },
    [VM_TABLE_COLUMN_ID.OEM_MODEL_NAME]: {
        operators: [
            {
                key: VM_OPERATOR_TYPE.EQUAL.id,
                value: VM_OPERATOR_TYPE.EQUAL.id,
                text: VM_OPERATOR_TYPE.EQUAL.text,
            },
            {
                key: VM_OPERATOR_TYPE.NOT_EQUAL.id,
                value: VM_OPERATOR_TYPE.NOT_EQUAL.id,
                text: VM_OPERATOR_TYPE.NOT_EQUAL.text,
            },
        ],
        inputType: {
            id: 2,
            props: {
                defaultOption: { value: '', text: 'Select OEM Model Name' },
                options: VM_FILTER_OPTIONS,
            },
        },
    },
    [VM_TABLE_COLUMN_ID.DISPLAY_TAG_STATE]: {
        operators: [
            {
                key: VM_OPERATOR_TYPE.EQUAL.id,
                value: VM_OPERATOR_TYPE.EQUAL.id,
                text: VM_OPERATOR_TYPE.EQUAL.text,
            },
            {
                key: VM_OPERATOR_TYPE.NOT_EQUAL.id,
                value: VM_OPERATOR_TYPE.NOT_EQUAL.id,
                text: VM_OPERATOR_TYPE.NOT_EQUAL.text,
            },
        ],
        inputType: {
            id: 2,
            props: {
                defaultOption: { value: '', text: 'Select Display Tagging State' },
                options: VM_FILTER_OPTION_WITH_NOTCOMPLETED,
            },
        },
    },
    [VM_TABLE_COLUMN_ID.LOCALE_NAME]: {
        operators: [
            {
                key: VM_OPERATOR_TYPE.CONTAINS.id,
                value: VM_OPERATOR_TYPE.CONTAINS.id,
                text: VM_OPERATOR_TYPE.CONTAINS.text,
            },
            {
                key: VM_OPERATOR_TYPE.NOT_CONTAIN.id,
                value: VM_OPERATOR_TYPE.NOT_CONTAIN.id,
                text: VM_OPERATOR_TYPE.NOT_CONTAIN.text,
            },
            {
                key: VM_OPERATOR_TYPE.CONTAINS_EXCLUSIVELY.id,
                value: VM_OPERATOR_TYPE.CONTAINS_EXCLUSIVELY.id,
                text: VM_OPERATOR_TYPE.CONTAINS_EXCLUSIVELY.text,
            },
        ],
        inputType: {
            id: 2,
            props: {
                defaultOption: { value: '', text: 'Select Locale(s)' },
                options: [],
            },
        },
    },
    [VM_TABLE_COLUMN_ID.IS_REVIEWED]: {
        operators: [
            {
                key: VM_OPERATOR_TYPE.EQUAL.id,
                value: VM_OPERATOR_TYPE.EQUAL.id,
                text: VM_OPERATOR_TYPE.EQUAL.text,
            },
            {
                key: VM_OPERATOR_TYPE.NOT_EQUAL.id,
                value: VM_OPERATOR_TYPE.NOT_EQUAL.id,
                text: VM_OPERATOR_TYPE.NOT_EQUAL.text,
            },
        ],
        inputType: {
            id: 2,
            props: {
                defaultOption: { value: '', text: 'Select Is Reviewed' },
                options: VM_FILTER_OPTIONS,
            },
        },
    },
};
