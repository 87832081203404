import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import useNotifications from './useNotifications';

const TIMEOUT_TIME = 8000;

const sortNotifications = (c, p) => {
    if (c.pinned && p.pinned) return 0;
    if (!c.pinned && p.pinned) return -1;
    if (c.pinned && !p.pinned) return 1;
};

export const NotificationsContext = React.createContext();

const Notifications = ({ children }) => {
    const { notifications, notificationInterface, hideNotification } = useNotifications();

    useEffect(() => {
        const timeoutsContainer = {};
        notifications
            .filter(notification => notification.show && !notification.pinned)
            .forEach(notification => {
                !timeoutsContainer[notification.id] &&
                    (timeoutsContainer[notification.id] = setTimeout(
                        () => hideNotification(notification.id),
                        TIMEOUT_TIME
                    ));
            });
        return Object.entries(timeoutsContainer).forEach(timeoutId => clearTimeout(timeoutId));
    }, [notifications, hideNotification]);

    return (
        <>
            <NotificationsContext.Provider value={notificationInterface}>{children}</NotificationsContext.Provider>
            <div className="position-fixed bottom-0 right-0 p-3" style={{ zIndex: 10000, right: 0, bottom: 0 }}>
                {notifications
                    .filter(notification => notification.show)
                    .sort(sortNotifications)
                    .map((notification, key, visibleNotifications) => (
                        <div
                            key={key}
                            className={`toast show border ${notification.type} ${notification.pinned ? 'pinned' : ''}`}
                            role="alert"
                            aria-live="assertive"
                            aria-atomic="true"
                            style={{ opacity: 1 - (visibleNotifications.length - (key + 1)) / 10 }}>
                            <div className="toast-header">
                                {notification.title && <strong className="me-auto">{notification.title}</strong>}
                                <div className="pin">
                                    <FontAwesomeIcon icon="thumbtack" />
                                </div>
                                <button
                                    type="button"
                                    className="ms-2 mb-1 close"
                                    data-dismiss="toast"
                                    aria-label="Close"
                                    onClick={() => hideNotification(notification.id)}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            {notification.body && (
                                <div className="toast-body">
                                    {typeof notification.body === 'string' || notification.body.length < 2 ? (
                                        notification.body
                                    ) : (
                                        <ul>
                                            {notification.body.map((m, i) => (
                                                <li key={i}>{m}</li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            )}
                        </div>
                    ))}
            </div>
        </>
    );
};

export default Notifications;
