export const VehiclePublishExceptionTrackerFieldEnum = Object.freeze({
    PUBLISH_EXCEPTION_CATEGORY: {
        Id: 1,
        Key: 'publishExceptionCategoryId',
    },
    PUBLISH_EXCEPTION_NOTE: {
        Id: 2,
        Key: 'note',
    },
    PUBLISH_EXCEPTION_TICKET: {
        Id: 3,
        Key: 'ticket',
    },
});
