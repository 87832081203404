import { DependencyList, Dispatch, SetStateAction, useState } from 'react';

const areHookInputsEqual = (nextDeps: DependencyList, prevDeps: DependencyList): boolean => {
    if (nextDeps.length !== prevDeps.length) {
        return false;
    }

    for (let i = 0; i < prevDeps.length && i < nextDeps.length; i++) {
        if (!Object.is(nextDeps[i], prevDeps[i])) {
            return false;
        }
    }
    return true;
};

export const useStateFromProp = <S, T extends DependencyList = DependencyList>(
    factory: (current: S) => S,
    deps: T
): [S, Dispatch<SetStateAction<S>>] => {
    const [reminder, setReminder] = useState<T>(deps);
    const [value, setValue] = useState<S>(() => factory(null));

    if (!areHookInputsEqual(deps, reminder)) {
        setValue(curr => factory(curr));
        setReminder(deps);
    }

    return [value, setValue];
};
