import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const useFlagSorting = flags => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [sortFlagColumn, setSortFlagColumn] = useState(
        searchParams.get('fsort') ? JSON.parse(searchParams.get('fsort')) : null
    );

    const sortedFlags = useMemo(() => {
        const sortedFlags = [...flags];
        if (sortFlagColumn !== null) {
            if (sortFlagColumn.key === 'partTypeId') {
                sortedFlags.sort((a, b) => {
                    if (a.partTypeId === b.partTypeId) return 0;
                    if (a.partTypeId === null) return 1;
                    if (b.partTypeId === null) return -1;
                    if (sortFlagColumn.direction === 'A') return a.partTypeId < b.partTypeId ? -1 : 1;
                    else return a.partTypeId < b.partTypeId ? 1 : -1;
                });
            } else if (sortFlagColumn.key === 'procedureTitle') {
                if (sortFlagColumn.direction === 'A')
                    sortedFlags.sort((a, b) =>
                        a.procedure['procedureTitle'] < b.procedure['procedureTitle'] ? -1 : 1
                    );
                else
                    sortedFlags.sort((a, b) =>
                        a.procedure['procedureTitle'] < b.procedure['procedureTitle'] ? 1 : -1
                    );
            } else if (sortFlagColumn.key === 'procedureId') {
                if (sortFlagColumn.direction === 'A')
                    sortedFlags.sort((a, b) => (a.procedure['procedureId'] < b.procedure['procedureId'] ? -1 : 1));
                else sortedFlags.sort((a, b) => (a.procedure['procedureId'] < b.procedure['procedureId'] ? 1 : -1));
            } else {
                sortedFlags.sort((a, b) => {
                    if (a[sortFlagColumn.key] === b[sortFlagColumn.key]) return 0;
                    else if (a[sortFlagColumn.key] === null) return 1;
                    else if (b[sortFlagColumn.key] === null) return -1;
                    else if (sortFlagColumn.direction === 'A')
                        return a[sortFlagColumn.key] < b[sortFlagColumn.key] ? -1 : 1;
                    else return a[sortFlagColumn.key] < b[sortFlagColumn.key] ? 1 : -1;
                });
            }
        }

        return sortedFlags;
    }, [flags, sortFlagColumn]);

    const handleFlagSortColumnClick = useCallback(
        key => {
            let sorting = null;
            if (sortFlagColumn?.key !== key) sorting = { key: key, direction: 'A' };
            else if (sortFlagColumn.key === key && sortFlagColumn.direction === 'A')
                sorting = { key: key, direction: 'D' };
            setSortFlagColumn(sorting);
            setSearchParams(searchParams => {
                searchParams.set('fsort', JSON.stringify(sorting));
                return searchParams;
            });
        },
        [setSearchParams, sortFlagColumn]
    );

    return { sortedFlags, sortFlagColumn, handleFlagSortColumnClick };
};

export default useFlagSorting;
