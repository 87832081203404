import React, { useContext } from 'react';
import { MappingDefinitionsContext } from 'contexts/MappingDefinitionsContext';
import ReactSelect from 'react-select';
import { PrimaryButton } from 'oemiq-common';
import { CreateRuleProps, GroupOption } from './types';
import './CreateRule.scss';

const reactSelectStyles = {
    menu: baseStyles => ({
        ...baseStyles,
        zIndex: 300,
    }),
};

const CreateRule = ({
    selectedGroups = [],
    selectedType = '',
    setSelectedGroups,
    setSelectedType,
    onCreateRule,
    isCreateDisabled,
}: CreateRuleProps) => {
    const { groups, types } = useContext(MappingDefinitionsContext);

    return (
        <fieldset className="add-rule-form row list-group-item-secondary me-3 p-2">
            <div className="col-6">
                <div className="row">
                    <label className="col-form-label col-3 d-flex align-items-center justify-content-center">
                        <span>RULE RESULT</span>
                    </label>
                    <div className="col-9">
                        <ReactSelect
                            options={groups}
                            styles={reactSelectStyles}
                            placeholder="Select group..."
                            isMulti={true}
                            isClearable={true}
                            value={selectedGroups}
                            onChange={e => setSelectedGroups(e as GroupOption[])}></ReactSelect>
                    </div>
                </div>
            </div>
            <div className="col-4">
                <ReactSelect
                    options={types}
                    getOptionLabel={(p: { text: string }) => p.text}
                    placeholder="Select type..."
                    isClearable={true}
                    value={selectedType}
                    onChange={setSelectedType}
                    styles={reactSelectStyles}></ReactSelect>
            </div>
            <div className="col-2 pe-0">
                <PrimaryButton
                    type="button"
                    className="create-rule-button col-12"
                    disabled={isCreateDisabled}
                    onClick={onCreateRule}>
                    Create A Rule
                </PrimaryButton>
            </div>
        </fieldset>
    );
};

export default CreateRule;
