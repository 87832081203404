import { useState, useEffect, useContext } from 'react';
import { requestOems } from 'api/vehicleInfo';
import { LoadingContext } from 'components/Layout';
import { ToastContext } from 'components/ToastProvider';

const useOems = () => {
    const [oems, setOems] = useState([]);
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);

    const { showToast } = useContext(ToastContext);

    useEffect(() => {
        const getOems = async () => {
            try {
                incrementLoading();
                setOems(await requestOems());
            } catch (err) {
                showToast(err);
            } finally {
                decrementLoading();
            }
        };
        getOems();
    }, [showToast, incrementLoading, decrementLoading]);

    return { oems };
};

export default useOems;
