import { useState, useCallback, useMemo } from 'react';

const useLayout = () => {
    const [loadingCount, setLoadingCount] = useState(0);

    const incrementLoading = useCallback(() => {
        setLoadingCount(currentCount => currentCount + 1);
    }, []);

    const decrementLoading = useCallback(() => {
        setLoadingCount(currentCount => (currentCount > 0 ? currentCount - 1 : 0));
    }, []);

    const isLoading = useMemo(() => loadingCount > 0, [loadingCount]);

    return {
        incrementLoading,
        decrementLoading,
        isLoading,
    };
};

export default useLayout;
