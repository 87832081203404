import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { requestProceduresInfoByOemProceduresIds, requestProceduresInfoByProceduresIds } from 'api/RepairProcedureApi';
import { OemId } from 'helpers/OemId';
import { OemsMap } from './OemsMap';
import { autoUpdate, flip, shift, useFloating } from '@floating-ui/react-dom';
import { requestVehicleByOem } from 'api/vehicleInfo';

export const MESSAGE_EVENT_TYPE = 'openProcedure';

const isInt = str => !isNaN(str) && !isNaN(parseInt(str));

const keyVehicle = vehicle => {
    return `${vehicle.oemId}:${vehicle.yearId}:${vehicle.modelId}:${vehicle.trimId}`;
};

type VehicleInfo = {
    oemId: number;
    oemName: string;
    yearId: number;
    yearValue: string;
    modelId: number;
    modelName: string;
    trimId: number;
    trimName: string;
};
type ProcedureVehicle = {
    oemId: number;
    yearId: number;
    modelId: number;
    trimId: number;
};
// type Procedure

export const useProcedureHtmlLinkClickHandler = (
    procedure: { vehicles: ProcedureVehicle[] },
    loadProcedure: (procedureId: number) => Promise<void>,
    boundary: HTMLElement,
    oemId: OemId
) => {
    const [vehiclesByOem, setVehiclesByOem] = useState<{ [key: number]: VehicleInfo[] }>({});
    const procedureVehicles = useMemo<ProcedureVehicle[]>(() => {
        return [...new Map(procedure?.vehicles?.map(v => [keyVehicle(v), v])).values()];
    }, [procedure?.vehicles]);

    useEffect(() => {
        if (!procedureVehicles) return;

        (async () => {
            for (const oemId of new Set(procedureVehicles.map(v => v.oemId))) {
                if (!vehiclesByOem[oemId]) {
                    const vehicles = await requestVehicleByOem(oemId);
                    setVehiclesByOem(vbo => ({
                        ...vbo,
                        [oemId]: vehicles.map(v => ({
                            oemId: v.oem.oemId,
                            oemName: v.oem.oemName,
                            yearId: v.year.yearId,
                            yearValue: v.year.yearValue,
                            modelId: v.model.modelId,
                            modelName: v.model.modelName,
                            trimId: v.trim?.trimId,
                            trimName: v.trim?.trimName,
                        })),
                    }));
                }
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [procedure, procedureVehicles]);

    const [currentMousePosition, setCurrentMousePosition] = useState({
        clientX: 0,
        clientY: 0,
    });

    const [isOpenSubProcedureSelector, setIsOpenSubProcedureSelector] = useState(false);
    const [vehicleSubProcedures, setVehicleSubProcedures] = useState(new Map());
    const [virtualEl, setVirtualEl] = useState(null);

    const [oemProceduresIds, setOemProceduresIds] = useState([]);
    const [rpProceduresIds, setRpProceduresIds] = useState([]);

    const closeSubProcedureSelector = useCallback(
        procedureId => {
            setIsOpenSubProcedureSelector(false);
            setVehicleSubProcedures(new Map());
            setOemProceduresIds([]);
            setRpProceduresIds([]);
            if (procedureId) {
                loadProcedure(procedureId);
            }
        },
        [loadProcedure]
    );

    // effect for oemProcedures
    useEffect(() => {
        if (!isOpenSubProcedureSelector || !oemProceduresIds?.length || !procedureVehicles?.length) return;

        let isLoading = true;
        (async () => {
            const oemProcedureIds = oemProceduresIds.map(o => "'" + o + "'"); // as oemProcedureId is string then we need to convert it into string values

            const vehicleProcedures = new Map(procedureVehicles.map(v => [v, null]));
            setVehicleSubProcedures(vehicleProcedures);
            const batchSize = 200;
            for (const vehicle of procedureVehicles) {
                if (!isLoading) return;
                let procedures = [];
                for (let i = 0; i < oemProcedureIds.length; i += batchSize) {
                    if (!isLoading) return;
                    const resp = await requestProceduresInfoByOemProceduresIds(
                        oemProcedureIds.slice(i, i + batchSize),
                        vehicle.oemId,
                        vehicle.yearId,
                        vehicle.modelId,
                        vehicle.trimId
                    );
                    if (resp.value && resp.value.length > 0) {
                        procedures = procedures.concat(resp.value);
                    }
                }
                vehicleProcedures.set(vehicle, procedures);
                setVehicleSubProcedures(new Map(vehicleProcedures));
            }

            // if all vehicles has same procedure then just set that procedure
            const vpList = [...vehicleProcedures.values()];
            const reduceValue = vpList.reduce(
                (acc, cur) => (acc && cur.length === 1 && cur[0].procedureId === acc ? acc : false),
                vpList[0].length === 1 && vpList[0][0].procedureId
            );
            if (reduceValue) {
                closeSubProcedureSelector(reduceValue);
            }
        })();

        return () => {
            isLoading = false;
        };
    }, [closeSubProcedureSelector, isOpenSubProcedureSelector, oemProceduresIds, procedureVehicles]);

    // effect for rpProceduresIds
    useEffect(() => {
        if (!isOpenSubProcedureSelector || !rpProceduresIds?.length || !procedureVehicles?.length) return;

        let isLoading = true;
        (async () => {
            const vehicleProcedures = new Map(procedureVehicles.map(v => [v, null]));
            setVehicleSubProcedures(vehicleProcedures);
            const batchSize = 200;
            for (const vehicle of procedureVehicles) {
                if (!isLoading) return;
                let procedures = [];
                for (let i = 0; i < rpProceduresIds.length; i += batchSize) {
                    if (!isLoading) return;
                    const resp = await requestProceduresInfoByProceduresIds(
                        rpProceduresIds.slice(i, i + batchSize),
                        vehicle.oemId,
                        vehicle.yearId,
                        vehicle.modelId,
                        vehicle.trimId
                    );
                    if (resp.value && resp.value.length > 0) {
                        procedures = procedures.concat(resp.value);
                    }
                }
                vehicleProcedures.set(vehicle, procedures);
                setVehicleSubProcedures(new Map(vehicleProcedures));
            }

            // if all vehicles has same procedure then just set that procedure
            const vpList = [...vehicleProcedures.values()];
            const reduceValue = vpList.reduce(
                (acc, cur) => (acc && cur.length === 1 && cur[0].procedureId === acc ? acc : false),
                vpList[0].length === 1 && vpList[0][0].procedureId
            );
            if (reduceValue) {
                closeSubProcedureSelector(reduceValue);
            }
        })();

        return () => {
            isLoading = false;
        };
    }, [closeSubProcedureSelector, isOpenSubProcedureSelector, procedureVehicles, rpProceduresIds]);

    const handleProcedureClick = useCallback(
        async event => {
            if (!event?.data) {
                return;
            }

            const { clientX, clientY } = currentMousePosition;
            setVirtualEl({
                getBoundingClientRect() {
                    return {
                        width: 0,
                        height: 0,
                        x: clientX,
                        y: clientY,
                        top: clientY,
                        left: clientX,
                        right: clientX,
                        bottom: clientY,
                    };
                },
            });
            setOemProceduresIds([]);
            setRpProceduresIds([]);

            if (Array.isArray(event.data)) {
                if (OemsMap.getMajorVehiclesOemId(oemId) === OemId.GMC) {
                    setOemProceduresIds(event.data); // looks by OEM ProcedureId (not GmProcedureId which is internal ID given by our system)
                    setIsOpenSubProcedureSelector(true);
                } else if (event.data.length == 1 && isInt(event.data[0])) {
                    await loadProcedure(parseInt(event.data[0]));
                } else {
                    setRpProceduresIds(event.data);
                    setIsOpenSubProcedureSelector(true);
                }
            } else if (isInt(event.data)) {
                await loadProcedure(parseInt(event.data));
            }
        },
        [currentMousePosition, loadProcedure, oemId]
    );

    const handleMouseMove = useCallback(event => {
        setCurrentMousePosition({ clientX: event.clientX, clientY: event.clientY });
    }, []);

    const handleMouseClick = useCallback(() => {
        closeSubProcedureSelector(null);
    }, [closeSubProcedureSelector]);

    const { refs, floatingStyles } = useFloating({
        open: isOpenSubProcedureSelector,
        middleware: [shift({ boundary, padding: 10 }), flip({ boundary })],
        elements: { reference: virtualEl },
        whileElementsMounted: autoUpdate,
    });

    const formatVehicle = useCallback(
        vehicle => {
            const vehicleInfo = vehiclesByOem[vehicle.oemId]?.find(
                v =>
                    v.yearId === vehicle.yearId &&
                    v.modelId === vehicle.modelId &&
                    (vehicle.trimId === null || v.trimId === vehicle.trimId)
            );
            if (vehicleInfo) {
                if (vehicle.trimId != null) {
                    return `${vehicleInfo.oemName} ${vehicleInfo.yearValue} ${vehicleInfo.modelName} ${vehicleInfo.trimName}`;
                } else {
                    return `${vehicleInfo.oemName} ${vehicleInfo.yearValue} ${vehicleInfo.modelName}`;
                }
            } else {
                return `${vehicle.oemId} ${vehicle.yearId} ${vehicle.modelId} ${vehicle.trimId}`;
            }
        },
        [vehiclesByOem]
    );

    const selectorComponent = useMemo(() => {
        return (
            isOpenSubProcedureSelector && (
                <div
                    ref={refs.setFloating}
                    style={{ ...floatingStyles, maxHeight: '500px', overflow: 'auto' }}
                    className="border p-1 bg-white">
                    {[...vehicleSubProcedures].map(p => {
                        const [vehicle, procedures] = p;
                        return (
                            <div key={keyVehicle(vehicle)}>
                                {procedures?.length > 0 ? (
                                    procedures.map(p => (
                                        <div
                                            key={p.procedureId}
                                            className="link-primary"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => closeSubProcedureSelector(p.procedureId)}>
                                            {formatVehicle(vehicle)} {p.procedureId} {p.procedureTitle}
                                        </div>
                                    ))
                                ) : (
                                    <div>
                                        {formatVehicle(vehicle)}{' '}
                                        {procedures === null ? 'Loading...' : 'No procedure found for vehicle'}
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
            )
        );
    }, [
        closeSubProcedureSelector,
        floatingStyles,
        formatVehicle,
        isOpenSubProcedureSelector,
        refs.setFloating,
        vehicleSubProcedures,
    ]);

    return {
        handleProcedureClick,
        closeSubProcedureSelector,
        handleMouseMove,
        handleMouseClick,
        isOpenSubProcedureSelector,
        refs,
        floatingStyles,
        selectorComponent,
    };
};
