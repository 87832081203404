import React, { useRef, useCallback } from 'react';
import OrganizationModal from './OrganizationModal/OrganizationModal';
import useOrganizations from './useOrganizations';
import MergeOrganizationModal from './OrganizationModal/MergeOrganizationModal';
import Confirmation from 'components/Shared/Confirmation/Confirmation';
import ConfirmationModal from 'components/Shared/Utils/ConfirmationModal';
import ManageCustomersTable from '../ManageUsers/ManageCustomersTable';
import GlobalSearchBar from 'components/Shared/GlobalSearchBar/GlobalSearchBar';

const Organizations = () => {
    const {
        tableColumnDetails,
        showModal,
        editOrgId,
        displayOrgs,
        userHasCustomerSupportRole,
        onSaveOrEdit,
        handleToggle,
        setSearchTerm,
        setShowModal,
        handleRowClick,
        sortClickCallback,
        segments,
        handleMergeOrganizationToggle,
        showMergeOrganizationModal,
        confirmMergeOrganization,
        organizations,
        selectedOrgData,
        handleUpdateOrgActiveStatus,
        setSelectedOrgData,
        searchParamText,
        filterChangeCallback,
        selectedFilters,
        globalSearchRef,
    } = useOrganizations();

    const confirmRef = useRef();

    const openMergeOrgConfirmationModal = useCallback(
        (originalOrgId, proposedOrgId) => {
            handleMergeOrganizationToggle();
            confirmRef.current.open(() => confirmMergeOrganization(originalOrgId, proposedOrgId), {
                body: (
                    <span>
                        Are you sure you want to merge{' '}
                        <b>{displayOrgs.find(org => org.organizationId === originalOrgId).name}</b> into{' '}
                        <b>{organizations.find(org => org.organizationId === proposedOrgId).name}</b>
                    </span>
                ),
                title: 'Are you Sure?',
            });
        },
        [confirmMergeOrganization, displayOrgs, handleMergeOrganizationToggle, organizations]
    );

    return (
        <div className="manage-customers-layout-container">
            {showModal && (
                <OrganizationModal
                    onSaveOrEdit={onSaveOrEdit}
                    orgId={editOrgId}
                    isOpen={showModal}
                    toggle={handleToggle}
                    segments={segments}
                />
            )}
            {showMergeOrganizationModal && (
                <MergeOrganizationModal
                    orgId={editOrgId}
                    openMergeOrgConfirmationModal={openMergeOrgConfirmationModal}
                    isOpen={showMergeOrganizationModal}
                    organizations={organizations}
                    toggle={handleMergeOrganizationToggle}
                />
            )}
            {selectedOrgData?.showConfirmationModal && (
                <ConfirmationModal
                    show={selectedOrgData?.showConfirmationModal}
                    body={
                        <>
                            Are you sure you want to{' '}
                            <strong>{selectedOrgData.isActive ? 'deactivate' : 'reactivate'}</strong>{' '}
                            {selectedOrgData.orgName}?
                        </>
                    }
                    onConfirmCallback={handleUpdateOrgActiveStatus}
                    onCancelCallback={() => setSelectedOrgData(null)}
                    confirmButtonText={selectedOrgData.isActive ? 'Deactivate' : 'Reactivate'}
                />
            )}
            <div className="position-sticky w-100 p-2 manage-customers-nav">
                <div className="nav-bar mt-3">
                    {/* If user is customer support, hide create new org button */}
                    {!searchParamText && !userHasCustomerSupportRole ? (
                        <div className="buttons-container">
                            <button
                                type="button"
                                id="create-new-org"
                                className="btn btn-sm btn-primary h-100"
                                onClick={() => setShowModal(!showModal)}>
                                Create New Organization
                            </button>
                        </div>
                    ) : (
                        <h6>
                            <strong>Organizations</strong> search results for '<strong>{searchParamText}</strong>'
                        </h6>
                    )}

                    <div className="col-3 ms-auto">
                        <GlobalSearchBar inPageSearchCallback={setSearchTerm} globalSearchRef={globalSearchRef} />
                    </div>
                </div>
            </div>

            <div data-testid="manage-org" className="table-container">
                <ManageCustomersTable
                    tableId="org-table"
                    isTableRowsClickable={true}
                    columnDetails={tableColumnDetails}
                    data={displayOrgs}
                    sortClickCallback={sortClickCallback}
                    rowClickCallback={handleRowClick}
                    ellipsisColumnIndex={1}
                    filterChangeCallback={filterChangeCallback}
                    selectedFilters={selectedFilters}
                />
            </div>
            <Confirmation ref={confirmRef} />
        </div>
    );
};

export default Organizations;
