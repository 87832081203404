import React from 'react';
import { PrimaryButton } from 'oemiq-common';
import GroupButtonDisplay from './GroupButtonDisplay';

const GroupColumn = ({ groups, procedure, setGroupTypeModalProcedureIds }) => {
    let groupCount = 0;
    let groupDisplayLimit = 2;
    return (
        <div className="d-flex align-items-center">
            {procedure.stageArea.groups !== null && procedure.stageArea.groups.length > 0 ? (
                <div
                    id={`select-${procedure.procedureId}-groups`}
                    className="clickable"
                    onClick={() => setGroupTypeModalProcedureIds([procedure.procedureId])}>
                    {procedure.stageArea.groups.map(gr => {
                        if (groups.length === 0) return null;
                        let group = groups.find(g => g.regionId === gr.groupId);

                        if (group === undefined) return null;

                        groupCount += 1;
                        if (groupCount > groupDisplayLimit) return null;

                        return (
                            <GroupButtonDisplay key={`${procedure.procedureId}-${group.regionId}`}>
                                {group.regionFriendlyName}
                            </GroupButtonDisplay>
                        );
                    })}
                </div>
            ) : (
                <PrimaryButton
                    id={`select-${procedure.procedureId}-groups`}
                    type="button"
                    disabled={false}
                    onClick={() => setGroupTypeModalProcedureIds([procedure.procedureId])}>
                    Select
                </PrimaryButton>
            )}
            {procedure.stageArea.groups.length > groupDisplayLimit && (
                <span>+{procedure.stageArea.groups.length - groupDisplayLimit}</span>
            )}
        </div>
    );
};

export default GroupColumn;
