import React from 'react';
import ThemeColors from './ThemeColors/ThemeColors';

const StyleGuide = () => {
    return (
        <div className="container mt-5">
            <div className="row">
                <div className="col">
                    <h4>Style Guide</h4>
                </div>
            </div>
            <div className="mt-3">
                <React.Fragment>
                    Colours
                    <ThemeColors />
                </React.Fragment>
            </div>
        </div>
    );
};

export default StyleGuide;
