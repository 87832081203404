import React, { FC } from 'react';
import { ActionListCellOptions } from './types';
import { CellProps } from 'components/Shared/Table/types';
import calculateClass from 'components/Shared/Table/Cells/Helpers/calculateClass';
import Form from 'react-bootstrap/Form';

import './CustomSwitch.scss';

const ActionList: (options?: ActionListCellOptions) => FC<CellProps> = options => {
    const ActionListComponent = ({ idKey, item, header, index, cellEventCallback }: CellProps) => {
        return (
            <td headers={header.id} className={calculateClass(options, item)}>
                <div className="d-flex flex-row align-items-center">
                    <button
                        key={'undoButton'}
                        title={options.undoButton.title}
                        id={`${options.undoButton.id}${index}`}
                        className={`btn btn-sm me-1 ${options.undoButton.class}`}
                        onClick={() =>
                            cellEventCallback({
                                id: item[idKey] as string,
                                actionId: options.undoButton.id,
                                index,
                                item,
                            })
                        }
                        disabled={options.undoButton.disabled && options.undoButton.disabled(item)}>
                        {options.undoButton.text && <span>{options.undoButton.text}</span>}
                    </button>
                    <button
                        key={'removeButton'}
                        title={options.removeButton.title}
                        id={`${options.removeButton.id}${index}`}
                        className={`btn btn-sm me-3 ${options.removeButton.class}`}
                        onClick={() =>
                            cellEventCallback({
                                id: item[idKey] as string,
                                actionId: options.removeButton.id,
                                index,
                                item,
                            })
                        }
                        disabled={options.removeButton.disabled && options.removeButton.disabled(item)}>
                        {options.removeButton.text && <span>{options.removeButton.text}</span>}
                    </button>
                    <Form.Check
                        key={'silder'}
                        id={`${options.slider.id}${index}`}
                        type="switch"
                        title={
                            options.slider.checked && options.slider.checked(item)
                                ? options.slider.checkedTitle
                                : options.slider.uncheckedTitle
                        }
                        className={`ms-1 ${options.slider.class}`}
                        style={{ transform: 'scale(1.8)', textAlign: 'center' }}
                        checked={options.slider.checked && options.slider.checked(item)}
                        onChange={() =>
                            cellEventCallback({
                                id: item[idKey] as string,
                                actionId: options.slider.id,
                                index,
                                item,
                            })
                        }
                        disabled={options.slider.disabled && options.slider.disabled(item)}
                    />
                </div>
            </td>
        );
    };
    return ActionListComponent;
};

export default ActionList;
