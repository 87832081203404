import { useMemo, useCallback } from 'react';
import { OemDataModel } from './OemRulesMetadata';

const useTableActions = (
    oemId: string | number,
    dataModel: OemDataModel,
    hasAccess,
    toggleActiveMappingRule: (rule) => Promise<void>,
    removeMappingRule: (rule) => Promise<void>,
    navigateToEngine: (oemId: string | number, rules) => void,
    queueUndoMappingRule: (oemId: string | number, ruleId) => Promise<void>
) => {
    const handleActivation = useCallback(
        e => {
            hasAccess('rule.toggleActive') && toggleActiveMappingRule(e.item);
        },
        [hasAccess, toggleActiveMappingRule]
    );

    const handleNavigateToEngine = useCallback(
        e => {
            navigateToEngine(oemId, e.item);
        },
        [oemId, navigateToEngine]
    );

    const handleUndoRule = useCallback(
        e => {
            queueUndoMappingRule(oemId, e.item[dataModel.keyField]);
        },
        [oemId, dataModel, queueUndoMappingRule]
    );

    const handleRemoveRule = useCallback(
        e => {
            hasAccess('rule.remove') && removeMappingRule(e.item);
        },
        [hasAccess, removeMappingRule]
    );

    return useMemo(
        () => ({
            handleActivation,
            handleNavigateToEngine,
            handleUndoRule,
            handleRemoveRule,
        }),
        [handleActivation, handleNavigateToEngine, handleUndoRule, handleRemoveRule]
    );
};

export default useTableActions;
