import { orderBy } from 'lodash';

export interface VehicleMakeModel {
    oemId: number;
    oemName: string;
    modelId: number;
    modelName: string;
}

export interface SelectOption {
    label: string;
    value: number;
}

export function getMakesForModelSelection(allVehicles: VehicleMakeModel[], selectedModel: SelectOption | null) {
    const matchingMakeOptions = allVehicles
        .filter(x => (!selectedModel ? true : x.modelId === selectedModel.value))
        .map(x => ({
            label: x.oemName,
            value: x.oemId,
        }));

    const uniqueMakeOptions = [...new Map(matchingMakeOptions.map(x => [x.value, x])).values()];

    return orderBy(uniqueMakeOptions, ['label'], ['asc']);
}

export function getModelsForMakeSelection(allVehicles: VehicleMakeModel[], selectedMake: SelectOption | null) {
    const matchingModelOptions = allVehicles
        .filter(x => (!selectedMake ? true : x.oemId === selectedMake.value))
        .map(x => ({
            label: x.modelName,
            value: x.modelId,
        }));

    const uniqueModelOptions = [...new Map(matchingModelOptions.map(x => [x.value, x])).values()];

    return orderBy(uniqueModelOptions, ['label'], ['asc']);
}
