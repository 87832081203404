import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactSelect from 'react-select';
import { FormatCurrency } from 'helpers/CurrencyHelper';
import ImagePreview from '../ImagePreview/ImagePreview';
import { partTypes } from 'helpers/PartTypes';

const OneTimeUseTagUpdate = ({ useOneTimeUseTagUpdate }) => {
    const {
        tagName,
        setTagName,
        quantity,
        setQuantity,
        quantityCondition,
        setQuantityCondition,
        partNumber,
        setPartNumber,
        price,
        setPrice,
        partType,
        setPartType,
        isSelectingImg,
        setIsSelectingImage,
        selectedImages,
        tagText,
        setTagText,
        note,
        setNote,
        handleRemoveImageClick,
        handleSelectTagRegion,
        handleRevertRegion,
        handleUpdateTag,
        isChanged,
        isTitleBlank,
        calcConditions,
        isActive,
    } = useOneTimeUseTagUpdate;

    return (
        <div className="tag-details px-2">
            <hr />
            <input
                type="text"
                placeholder="Tag Name"
                className="form-control form-control-sm"
                value={tagName}
                disabled={!isActive}
                onChange={e => setTagName(e.currentTarget.value)}
            />
            <input
                type="text"
                placeholder="Quantity"
                className="form-control form-control-sm mt-2"
                value={quantity}
                min="0"
                onChange={e => setQuantity(isNaN(e.currentTarget.value) ? '' : e.currentTarget.value)}
                disabled={!isActive}
            />
            <ReactSelect
                defaultValue={calcConditions.find(c => c.value === quantityCondition)}
                options={calcConditions}
                onChange={e => setQuantityCondition(e.value)}
                isDisabled={!isActive}
            />
            <input
                type="text"
                placeholder="Part Number"
                className="form-control form-control-sm mt-2"
                value={partNumber || ''}
                onChange={e => setPartNumber(e.currentTarget.value)}
                disabled={!isActive}
            />
            <input
                type="number"
                placeholder="Part Price"
                className="form-control form-control-sm mt-2"
                value={price}
                min="0"
                step="0.01"
                onChange={e => setPrice(e.currentTarget.value)}
                onBlur={() => setPrice(FormatCurrency(price, 2))}
                disabled={!isActive}
            />
            <select
                id="partType"
                className="form-select form-control-sm mt-2"
                value={partType}
                onChange={e => setPartType(e.currentTarget.value)}
                disabled={!isActive}>
                <option value="" disabled="disabled">
                    Select Part Type
                </option>
                {partTypes.map((p, index) => (
                    <option key={index} value={p.value}>
                        {p.text}
                    </option>
                ))}
            </select>
            <div className="mt-2">
                <div>Activate desired command button and click image to add/remove images</div>
                <button
                    type="button"
                    className={`btn btn-sm ${isSelectingImg ? 'btn-primary' : 'btn-secondary'} mt-2`}
                    onClick={() => setIsSelectingImage(!isSelectingImg)}
                    disabled={!isActive}>
                    Select Images: {isSelectingImg ? 'On' : 'Off'}
                </button>
                <div className="mt-2">
                    {selectedImages.map((img, index) => (
                        <div key={index} style={{ position: 'relative' }}>
                            <span style={{ position: 'absolute', top: -5, left: 10 }}>
                                <FontAwesomeIcon
                                    className="clickable"
                                    icon="times-circle"
                                    onClick={() => handleRemoveImageClick(img)}
                                />
                            </span>
                            <ImagePreview alt="" img={img} width={150} />
                        </div>
                    ))}
                </div>
            </div>
            <textarea
                rows="4"
                type="text"
                className="form-control form=control-sm mt-2"
                placeholder="Tag Text"
                value={tagText || ''}
                onChange={e => setTagText(e.currentTarget.value)}
                disabled={!isActive}
            />
            <div className="mt-2">
                <div>
                    Highlight the region in the document on the left, and then press &lsquo;Update Tag
                    Region/Text&rsquo;
                </div>
                <button
                    type="button"
                    className={'btn btn-sm btn-primary mt-2 me-2'}
                    onClick={() => handleSelectTagRegion()}
                    disabled={!isActive}>
                    Update Tag Region/Text
                </button>
                <button
                    type="button"
                    className={'btn btn-sm btn-secondary mt-2'}
                    onClick={() => handleRevertRegion()}
                    disabled={!isActive}>
                    Revert
                </button>
            </div>
            <textarea
                rows="2"
                type="text"
                className="form-control form-control-sm mt-2"
                id="note"
                placeholder="Note"
                value={note || ''}
                onChange={e => setNote(e.currentTarget.value)}
                disabled={!isActive}
            />
            <div className="mt-2 d-flex justify-content-end">
                <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    onClick={() => handleUpdateTag()}
                    disabled={!isActive || !isChanged || isTitleBlank}>
                    Save
                </button>
            </div>
        </div>
    );
};

export default OneTimeUseTagUpdate;
