import { requestImportJobDownloadType } from 'api/RepairProcedureApi';
import { ToastContext } from 'components/ToastProvider';
import { useContext, useEffect, useState } from 'react';
import { ImportJobDownloadType } from 'types/importJobDownloadType';

const useImportJobDownloadType = () => {
    const [importJobDownloadTypes, setImportJobDownloadTypes] = useState<ImportJobDownloadType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const { showToast } = useContext<{ showToast }>(ToastContext);

    useEffect(() => {
        let isUnmounted = false;
        (async () => {
            try {
                setLoading(true);
                const data = await requestImportJobDownloadType();
                if (!isUnmounted) {
                    setImportJobDownloadTypes(data);
                }
            } catch (error) {
                showToast(error);
            } finally {
                if (!isUnmounted) {
                    setLoading(false);
                }
            }
        })();

        return () => {
            isUnmounted = true;
        };
    }, [showToast]);

    return { loading, importJobDownloadTypes };
};

export default useImportJobDownloadType;
