import { useState, useEffect, useContext, useCallback } from 'react';
import { LoadingContext } from 'components/Layout';
import { ToastContext } from 'components/ToastProvider';
import { requestRemovedProceduresByBookApi } from 'api/RepairProcedureApi';
import { appInsights } from '../api/config/AppInsights';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

const useRemovedProcedures = bookId => {
    const { showToast } = useContext(ToastContext);
    const [removedProcedures, setRemovedProcedures] = useState([]);
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);

    const getRemovedProcedures = useCallback(async () => {
        try {
            incrementLoading();
            setRemovedProcedures(await requestRemovedProceduresByBookApi(bookId));
        } catch (error) {
            console.log(error);
            showToast(error);
            appInsights.trackException({ error: error, severityLevel: SeverityLevel.Error });
        } finally {
            decrementLoading();
        }
    }, [bookId, showToast, incrementLoading, decrementLoading]);

    useEffect(() => {
        getRemovedProcedures();
    }, [getRemovedProcedures]);

    return {
        removedProcedures,
        getRemovedProcedures,
    };
};

export default useRemovedProcedures;
