import React, { Key, useCallback, useMemo } from 'react';
import CheckAllButton from './CheckAllButton';
import { TableProps, Options } from './types';
import TableRow from './TableRow';
import useCheckboxes from './useCheckboxes';
import './Table.scss';

const defaultSettings: Options = {
    stickyHeader: true,
    checkboxes: false,
    disableCheckboxFn: () => false,
};

const Table = ({
    idKey,
    headers,
    data,
    selected,
    setSelected,
    cellEventCallbacks,
    options,
    className,
    noDataMessage,
    tableHtmlId,
    tableHeaderId,
}: TableProps) => {
    const settings = useMemo(() => ({ ...defaultSettings, ...(options || {}) }), [options]);
    const cellEventCallback = useCallback(
        e => {
            cellEventCallbacks[e.actionId](e);
        },
        [cellEventCallbacks]
    );
    const { checkAllState, toggleCheckAll, toggleCheck } = useCheckboxes(idKey, data, selected, setSelected, settings);
    const isNoDataAvailable = !data || data.length === 0;
    return (
        <div className={`${className || 'p-3'}`}>
            <table id={tableHtmlId} className={`table ${settings.stickyHeader ? 'sticky-header' : ''}`}>
                <thead id={tableHeaderId} data-testid={tableHeaderId}>
                    <tr>
                        {settings.checkboxes && (
                            <th id="checkboxes">
                                <CheckAllButton checkAllState={checkAllState} toggleCheckAll={toggleCheckAll} />
                            </th>
                        )}
                        {headers.map((h, i) => (
                            <th
                                className={h.thClass}
                                key={i}
                                id={h.id}
                                scope="col"
                                title={h.title}
                                style={{ zIndex: '2' }}>
                                {h.label}
                            </th>
                        ))}
                    </tr>
                </thead>
                {!isNoDataAvailable ? (
                    <tbody>
                        {data.map((d, i) => {
                            let tableRowHtmlId = null;
                            if (tableHtmlId && idKey) tableRowHtmlId = `${tableHtmlId}-${d[idKey]}`;
                            return (
                                <TableRow
                                    key={d[idKey] as Key}
                                    idKey={idKey}
                                    rowData={d}
                                    headers={headers}
                                    index={i}
                                    checked={selected && selected.some(s => s.id === d[idKey])}
                                    checkboxDisabled={settings.disableCheckboxFn(d)}
                                    toggleCheck={toggleCheck}
                                    settings={settings}
                                    cellEventCallback={cellEventCallback}
                                    tableRowHtmlId={tableRowHtmlId}
                                />
                            );
                        })}
                    </tbody>
                ) : (
                    <tfoot className="no-rows-found">
                        <tr>
                            <td colSpan={headers.length}>
                                <h4>{noDataMessage ? noDataMessage : 'No Record Found'}</h4>
                            </td>
                        </tr>
                    </tfoot>
                )}
            </table>
        </div>
    );
};

export default React.memo(Table);
