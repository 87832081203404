import React from 'react';
import InfiniteScroll from './InfiniteScroll';

const InfiniteScrollTableRow = ({ columnsCount, isLoading, loadMoreCallback }) => {
    return (
        <tr>
            <td colSpan={columnsCount}>
                <InfiniteScroll isLoading={isLoading} loadMoreCallback={loadMoreCallback} />
            </td>
        </tr>
    );
};

export default InfiniteScrollTableRow;
