import React, { useCallback } from 'react';
import { Input } from 'oemiq-common';
import TextareaAutoHeight from './TextareaAutoHeight';

const InputCell = ({ value, onChange, onSubmit, type, edited, setEdited, disabled, multiline, ...props }) => {
    const handleKeyPress = useCallback(e => {
        if (e.key === 'Enter' || e.key === 'Tab') {
            e.target.blur();
        }
    }, []);

    const handleSubmit = useCallback(() => {
        onSubmit();
    }, [onSubmit]);

    return edited ? (
        <td>
            {!multiline ? (
                <Input
                    type={type || 'text'}
                    value={value === 0 ? value : value || ''}
                    onChange={e => onChange(e.currentTarget.value)}
                    onBlur={handleSubmit}
                    onKeyPress={handleKeyPress}
                    disabled={disabled}
                    inputProps={props}
                />
            ) : (
                <TextareaAutoHeight
                    value={value === 0 ? value : value || ''}
                    onChange={e => onChange(e.currentTarget.value)}
                    onBlur={handleSubmit}
                    onKeyPress={handleKeyPress}
                    disabled={disabled}
                    {...props}
                />
            )}
        </td>
    ) : (
        <td className="clickable" onClick={setEdited}>
            {value}
        </td>
    );
};

InputCell.defaultProps = {
    multiline: false,
};

export default React.memo(InputCell);
