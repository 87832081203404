import { useState, useEffect, useContext } from 'react';
import { getRepairPlansByCompanyId } from 'api/RepairPlanApi';
import { requestCompanyUsers } from 'api/SecurityApi';
import { ToastContext } from 'components/ToastProvider';
import { AccessControlContext } from 'components/Shared/AccessControl/AccessControl';

const usePlanTable = () => {
    const [plans, setPlans] = useState([]);
    const [companyUsers, setCompanyUsers] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [displayPlans, setDisplayPlans] = useState([]);
    const { userInfo } = useContext(AccessControlContext);
    const { showToast } = useContext(ToastContext);

    useEffect(() => {
        const getPlansByCompanyId = async () => {
            if (userInfo) {
                userInfo.companies.map(async c => {
                    try {
                        let plans = await getRepairPlansByCompanyId(c.companyId);
                        let companyUsers = await requestCompanyUsers(c.companyId);
                        setPlans(currState => {
                            return [...currState, ...plans];
                        });

                        setCompanyUsers(currState => {
                            return [...currState, ...companyUsers];
                        });
                    } catch (error) {
                        showToast(error);
                    }
                });
            }
        };
        getPlansByCompanyId();
    }, [userInfo, showToast]);

    useEffect(() => {
        if (searchResults.length > 0) setDisplayPlans(searchResults);
        else setDisplayPlans(plans);
    }, [plans, searchResults]);

    const handleSearch = searchTerm => {
        if (searchTerm === '') setSearchResults([]);
        else {
            let results = plans.filter(
                p =>
                    (p.repairOrder && p.repairOrder.toLowerCase().includes(searchTerm.toLowerCase())) ||
                    p.planId.toString().includes(searchTerm)
            );
            setSearchResults(results);
        }
    };

    return {
        companyUsers,
        displayPlans,
        searchValue,
        setSearchValue,
        handleSearch,
        searchResults,
    };
};

export default usePlanTable;
