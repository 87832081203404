import { useState, useContext, useEffect } from 'react';
import { LoadingContext } from 'components/Layout';
import { ToastContext } from 'components/ToastProvider';
import { requestBooksForOemApi, requestDeleteBookByBookId, createTrainingMappingBookApi } from 'api/RepairProcedureApi';

const useMappingTrainingTool = () => {
    const [books, setBooks] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const { showToast } = useContext(ToastContext);

    useEffect(() => {
        const getBooksForOem = async () => {
            try {
                incrementLoading();
                setBooks(await requestBooksForOemApi(100));
            } catch (error) {
                showToast(error);
            } finally {
                decrementLoading();
            }
        };
        getBooksForOem();
    }, [showToast, incrementLoading, decrementLoading]);

    const handleDeleteBook = async bookId => {
        try {
            await requestDeleteBookByBookId(bookId);
            setBooks(currState => {
                return currState.filter(b => b.bookId !== bookId);
            });
        } catch (error) {
            showToast(error);
        }
    };

    const createTrainingMappingBook = async (bookId, bookTitle) => {
        try {
            incrementLoading();
            await createTrainingMappingBookApi(bookId, bookTitle);
            setIsModalOpen(false);
            setBooks(await requestBooksForOemApi(100));
        } catch (error) {
            showToast(error);
        } finally {
            decrementLoading();
        }
    };

    return {
        books,
        handleDeleteBook,
        isModalOpen,
        setIsModalOpen,
        createTrainingMappingBook,
    };
};

export default useMappingTrainingTool;
