import React from 'react';
import { PrimaryButton } from 'oemiq-common';

const ActionButtons = ({ setShowColumnSettings }) => {
    return (
        <div>
            <PrimaryButton
                id="column-settings"
                disabled={false}
                type="button"
                onClick={() => setShowColumnSettings(currShowValue => !currShowValue)}>
                Column Settings
            </PrimaryButton>
        </div>
    );
};

export default ActionButtons;
