import { useState, useEffect, useContext, useMemo } from 'react';
import { requestOems } from 'api/vehicleInfo';
import { ToastContext } from 'components/ToastProvider';

const useOemInfo = oemId => {
    const [oems, setOems] = useState([]);
    const { showToast } = useContext(ToastContext);

    useEffect(() => {
        const requestOemInfo = async () => {
            try {
                const oems = await requestOems();
                setOems(oems);
            } catch (error) {
                showToast();
            }
        };
        requestOemInfo();
    }, [showToast]);

    const oemName = useMemo(() => {
        const oem = oems.find(oem => oem.oemId === parseInt(oemId));
        const oemName = oem ? oem.oemName : '';
        return oemName;
    }, [oemId, oems]);

    return {
        oems,
        oemName,
    };
};

export default useOemInfo;
