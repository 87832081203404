import React from 'react';
import type { CellProps, ItemReturnEvent } from 'components/Shared/Table/types';
import { ImportHistoryActionsTypes, ImportHistoryRowDataType } from '../types';

const IdCell: React.FC<CellProps> = ({ item, index, idKey, cellEventCallback }) => {
    const data = item as ImportHistoryRowDataType;

    const handleIdClick = () => {
        const payload: ItemReturnEvent = {
            id: item[idKey] as string,
            item: item,
            index: index,
            actionId: ImportHistoryActionsTypes.ViewDetails,
        };

        cellEventCallback(payload);
    };

    return (
        <td className="d-flex justify-content-center">
            <button className="btn btn-link" onClick={handleIdClick}>
                <span>
                    <u>{data.importJobId}</u>
                </span>
            </button>
        </td>
    );
};

export default IdCell;
