import { OemId } from 'helpers/OemId';
import { useMemo } from 'react';
import { match } from 'ts-pattern';
import IngestionProcessList from './CustomCells/IngestionProcess';
import IngestionNotes from './CustomCells/IngestionNotes';
import {
    requestHyundaiSetNotes,
    requestGenesisSetNotes,
    requestFordSetNotes,
    requestGMSetNotes,
    requestMitsubishiSetNotes,
} from '../../../../api/RepairProcedureApi';

const useTableConfiguration = (oemId: OemId) => {
    const conf = useMemo(() => {
        const table = match(oemId)
            .with(OemId.Ford, () => FordTableConfiguration)
            .with(OemId.GMC, () => GMTableConfiguration)
            .with(OemId.Hyundai, () => HyundaiTableConfiguration)
            .with(OemId.Genesis, () => GenesisTableConfiguration)
            .with(OemId.Mitsubishi, () => MitsubishiTableConfiguration)
            .run();
        return table;
    }, [oemId]);

    return conf;
};

const FordTableConfiguration = [
    {
        label: 'Year',
        id: 'yearName',
        thClass: 'fordYearNameHeader',
    },
    {
        label: 'Model Name',
        id: 'modelName',
        thClass: 'fordModelNameHeader',
    },
    {
        label: 'Model Id',
        id: 'modelId',
        thClass: 'fordModelIdHeader',
    },
    {
        label: 'Ingestion Process',
        id: 'Ingestion Process',
        thClass: 'ingestion-process-header',
        component: IngestionProcessList({
            newButton: {
                id: 'handleNewButton',
                title: "The vehicle hasn't been reviewed",
            },
            onButton: {
                id: 'handleOnButton',
                title: 'The vehicle has been reviewed and will be scrapped and imported during the next refresh',
            },
            offButton: {
                id: 'handleOffButton',
                title: 'The vehicle has been reviewed and will NOT be scrapped and imported during the next refresh',
            },
        }),
    },
    {
        label: 'Notes',
        id: 'Ingestion Notes',
        component: IngestionNotes({
            textArea: {
                id: 'ingestionNotesTextArea',
                onBlur: requestFordSetNotes,
            },
        }),
    },
];

const GMTableConfiguration = [
    {
        label: 'Name',
        id: 'pubName',
        thClass: 'gMPubNameHeader',
    },
    {
        label: 'Pubsyskey',
        id: 'pubsyskey',
        thClass: 'gMPubsyskeyHeader',
    },
    {
        label: 'List of Vehicles',
        id: 'vehicleList',
        thClass: 'gMVehicleListHeader',
    },
    {
        label: 'Ingestion Process',
        id: 'Ingestion Process',
        thClass: 'gmIngestionProcessHeader',
        component: IngestionProcessList({
            newButton: {
                id: 'handleNewButton',
                title: "The vehicle hasn't been reviewed yet",
            },
            onButton: {
                id: 'handleOnButton',
                title: 'The vehicle has been reviewed and will be scrapped and imported during the next refresh',
            },
            offButton: {
                id: 'handleOffButton',
                title: 'The vehicle has been reviewed and will NOT be scrapped and imported during the next refresh',
            },
        }),
    },
    {
        label: 'Notes',
        id: 'Ingestion Notes',
        component: IngestionNotes({
            textArea: {
                id: 'ingestionNotesTextArea',
                onBlur: requestGMSetNotes,
            },
        }),
    },
];

const HyundaiTableConfiguration = [
    {
        label: 'Year',
        id: 'year',
        thClass: 'hyundaiYearHeader',
    },
    {
        label: 'Model Name',
        id: 'modelName',
        thClass: 'hyundaiModelNameHeader',
    },
    {
        label: 'Model Code',
        id: 'modelCode',
        thClass: 'hyundaiModelCodeHeader',
    },
    {
        label: 'Ingestion Process',
        id: 'Ingestion Process',
        thClass: 'ingestion-process-header',
        component: IngestionProcessList({
            newButton: {
                id: 'handleNewButton',
                title: "The vehicle hasn't been reviewed",
            },
            onButton: {
                id: 'handleOnButton',
                title: 'The vehicle has been reviewed and will be scrapped and imported during the next refresh',
            },
            offButton: {
                id: 'handleOffButton',
                title: 'The vehicle has been reviewed and will NOT be scrapped and imported during the next refresh',
            },
        }),
    },
    {
        label: 'Notes',
        id: 'Ingestion Notes',
        component: IngestionNotes({
            textArea: {
                id: 'ingestionNotesTextArea',
                onBlur: requestHyundaiSetNotes,
            },
        }),
    },
];

const GenesisTableConfiguration = [
    {
        label: 'Year',
        id: 'year',
        thClass: 'genesisYearHeader',
    },
    {
        label: 'Model Name',
        id: 'modelName',
        thClass: 'genesisModelNameHeader',
    },
    {
        label: 'Model Code',
        id: 'modelCode',
        thClass: 'genesisModelCodeHeader',
    },
    {
        label: 'Ingestion Process',
        id: 'Ingestion Process',
        thClass: 'ingestion-process-header',
        component: IngestionProcessList({
            newButton: {
                id: 'handleNewButton',
                title: "The vehicle hasn't been reviewed",
            },
            onButton: {
                id: 'handleOnButton',
                title: 'The vehicle has been reviewed and will be scrapped and imported during the next refresh',
            },
            offButton: {
                id: 'handleOffButton',
                title: 'The vehicle has been reviewed and will NOT be scrapped and imported during the next refresh',
            },
        }),
    },
    {
        label: 'Notes',
        id: 'Ingestion Notes',
        component: IngestionNotes({
            textArea: {
                id: 'ingestionNotesTextArea',
                onBlur: requestGenesisSetNotes,
            },
        }),
    },
];

const MitsubishiTableConfiguration = [
    {
        label: 'Year',
        id: 'year',
        thClass: 'mitsubishiYearHeader',
    },
    {
        label: 'Model Name',
        id: 'modelName',
        thClass: 'mitsubishiModelNameHeader',
    },
    {
        label: 'Model Code',
        id: 'modelCode',
        thClass: 'mitsubishiModelCodeHeader',
    },
    {
        label: 'Ingestion Process',
        id: 'Ingestion Process',
        thClass: 'ingestion-process-header',
        component: IngestionProcessList({
            newButton: {
                id: 'handleNewButton',
                title: "The vehicle hasn't been reviewed",
            },
            onButton: {
                id: 'handleOnButton',
                title: 'The vehicle has been reviewed and will be scrapped and imported during the next refresh',
            },
            offButton: {
                id: 'handleOffButton',
                title: 'The vehicle has been reviewed and will NOT be scrapped and imported during the next refresh',
            },
        }),
    },
    {
        label: 'Notes',
        id: 'Ingestion Notes',
        component: IngestionNotes({
            textArea: {
                id: 'ingestionNotesTextArea',
                onBlur: requestMitsubishiSetNotes,
            },
        }),
    },
];

export default useTableConfiguration;
