import { useState, useCallback, useRef, useEffect } from 'react';
// behavioral constants
const SIMILARITY_THRESHOLD = 2;
const MAX_LENGTH_RATIO = 2;
//  transformation functions:
const trimString = (str: string): string => str.trim();
const removeWhiteSpace = (str: string): string => str.replace(/\s/g, '');
const removeNonAlphanumeric = (str: string): string => str.replace(/[^a-zA-Z0-9]/g, '');
const toLowerCase = (str: string): string => str.toLowerCase();
const SEARCH_TRANSFORMATIONS: ((str: string) => string)[] = [
    trimString,
    removeWhiteSpace,
    removeNonAlphanumeric,
    toLowerCase,
];
// defaults for search behavior - can be overridden by the caller
const DEFAULT_MAX_RESULTS = 100;
const DEFAULT_DEBOUNCE_DELAY_MS = 200;

const getCrossProduct = (term: string, transformations: ((str: string) => string)[]): string[] => {
    const results = new Set<string>();

    for (const func of transformations) {
        results.add(func(term));
    }

    for (let i = 0; i < transformations.length; i++) {
        let combinedValue = term;
        for (let j = i; j < transformations.length; j++) {
            combinedValue = transformations[j](combinedValue);
            results.add(combinedValue);
        }
    }

    return [...results];
};

const calcTermDistance = (a: string, b: string): number => {
    const stringA: string = a.trim();
    const stringB: string = b.trim();

    const compareWords = (a: string, b: string): number => {
        if (a.length === 0) return b.length;
        if (b.length === 0) return a.length;

        const matrix: number[][] = Array(b.length + 1)
            .fill(null)
            .map(() => Array(a.length + 1).fill(0));

        for (let i = 0; i <= b.length; i++) {
            matrix[i][0] = i;
        }
        for (let j = 0; j <= a.length; j++) {
            matrix[0][j] = j;
        }

        for (let i = 1; i <= b.length; i++) {
            for (let j = 1; j <= a.length; j++) {
                const cost = a.charAt(j - 1) === b.charAt(i - 1) ? 0 : 1;
                matrix[i][j] = Math.min(matrix[i - 1][j] + 1, matrix[i][j - 1] + 1, matrix[i - 1][j - 1] + cost);
            }
        }

        return matrix[b.length][a.length];
    };

    const compareSentences = (a: string, b: string): number => {
        const wordsA: string[] = a.split(' ');
        const wordsB: string[] = b.split(' ');

        let totalDistance = 0;
        wordsA.forEach(wordA => {
            const distances = wordsB.map(wordB => compareWords(wordA, wordB));
            totalDistance += Math.min(...distances);
        });

        wordsB.forEach(wordB => {
            const distances = wordsA.map(wordA => compareWords(wordA, wordB));
            totalDistance += Math.min(...distances);
        });

        return totalDistance / (wordsA.length + wordsB.length);
    };

    if (!stringA.includes(' ') && !stringB.includes(' ')) {
        return compareWords(stringA, stringB);
    } else {
        return compareSentences(stringA, stringB);
    }
};

export type KeyValuePair = {
    key: number; // the index of the item in the list, or any unique identifier
    value: string; // some vale to search for
};

type RawSearchResult = {
    key: number;
    value: string;
    possiblyExact: boolean;
};

export type SearchResult = {
    key: number;
    value: string;
    isExactMatch: boolean;
};

class TrieNode {
    children: { [key: string]: TrieNode };
    isEndOfWord: boolean;

    constructor() {
        this.children = {};
        this.isEndOfWord = false;
    }
}

class Trie {
    root: TrieNode;

    constructor() {
        this.root = new TrieNode();
    }

    insert(word: string): void {
        let current = this.root;
        for (let i = 0; i < word.length; i++) {
            const ch = word[i];
            let node = current.children[ch];
            if (!node) {
                node = new TrieNode();
                current.children[ch] = node;
            }
            current = node;
        }
        current.isEndOfWord = true;
    }

    search(word: string): boolean {
        let current = this.root;
        for (let i = 0; i < word.length; i++) {
            const ch = word[i];
            const node = current.children[ch];
            if (!node) {
                return false;
            }
            current = node;
        }
        return current.isEndOfWord;
    }

    startsWith(prefix: string): boolean {
        let current = this.root;
        for (let i = 0; i < prefix.length; i++) {
            const ch = prefix[i];
            const node = current.children[ch];
            if (!node) {
                return false;
            }
            current = node;
        }
        return true;
    }
}

export const useFuzzySearch = (
    sourceValues: KeyValuePair[],
    debounceMs: number = DEFAULT_DEBOUNCE_DELAY_MS,
    maxResults: number = DEFAULT_MAX_RESULTS
) => {
    const [searchTrie, setSearchTrie] = useState(null);
    const [searchList, setSearchMatrix] = useState<Array<KeyValuePair>>(sourceValues ? [...sourceValues] : []);
    const [searchTerm, setSearchTerm] = useState('');
    const searchRef = useRef(null);
    const [resultBuffer, setResultBuffer] = useState(0);
    // define the type of this state as a set of SearchResult objects
    const [rawSearchResults, setRawSearchResults] = useState<Array<RawSearchResult>>();
    const [searchResults, setSearchResults] = useState<Array<SearchResult>>();
    const [searchComplete, setSearchComplete] = useState(false);

    const search = useCallback(
        (term: string): Array<RawSearchResult> => {
            if (!searchTrie || !term) return;

            const results = new Array<RawSearchResult>();
            const matchExists = searchTrie.search(term);
            let matchFound = false;
            const prefixMatch = searchTrie.startsWith(term);
            const comparableItems = [...searchList.filter(item => item.value.length / term.length <= MAX_LENGTH_RATIO)];
            comparableItems.sort(
                (a, b) => Math.abs(a.value.length - term.length) - Math.abs(b.value.length - term.length)
            );

            for (let i = 0; i < comparableItems.length; i++) {
                const item = comparableItems[i];
                if (matchExists && item.value === term) {
                    results.push({
                        key: item.key,
                        value: item.value,
                        possiblyExact: true,
                    });
                    matchFound = true;
                } else if (
                    /* prefix match applies to this item */
                    (item.value.length > term.length && prefixMatch && item.value.startsWith(term)) ||
                    /* item is similar, e.g. a typo */
                    calcTermDistance(term, item.value) <= SIMILARITY_THRESHOLD
                ) {
                    results.push({ key: item.key, value: item.value, possiblyExact: false });
                }

                // missed match edge case
                if (results.length >= resultBuffer) {
                    if (matchExists && !matchFound) {
                        const match = searchList.find(item => item.value === term);
                        if (match) {
                            results.push({
                                key: match.key,
                                value: match.value,
                                possiblyExact: true,
                            });
                        }
                    }

                    break;
                }
            }

            return results;
        },
        [searchTrie, searchList, resultBuffer]
    );

    // update the result buffer when the max results changes
    useEffect(() => {
        setResultBuffer(maxResults);
    }, [maxResults, setResultBuffer]);

    // build the search trie and matrix
    useEffect(() => {
        if (!sourceValues || sourceValues.length === 0 || maxResults === 0) return;

        const trie = new Trie();
        const searchMatrix = [...sourceValues];

        sourceValues.forEach(item => {
            trie.insert(item.value);

            for (const searchFormat of new Set(getCrossProduct(item.value, SEARCH_TRANSFORMATIONS))) {
                if (item.value !== searchFormat) {
                    trie.insert(searchFormat);
                    searchMatrix.push({ key: item.key, value: searchFormat });
                }
            }
        });

        setSearchTrie(trie);
        setSearchMatrix(searchMatrix);
    }, [sourceValues, maxResults, setSearchTrie]);

    // convert the raw search artifacts into results that relate to input values
    useEffect(() => {
        setSearchResults([]);
        if (
            !sourceValues ||
            sourceValues.length === 0 ||
            !searchRef.current ||
            !rawSearchResults ||
            rawSearchResults.length === 0
        ) {
            setSearchComplete(true);
            return;
        } else setSearchComplete(false);

        const actionableResults = rawSearchResults
            .filter((result, index, self) => {
                return index === self.findIndex(t => t.key === result.key);
            })
            .map(raw => {
                const sourceVal = sourceValues.find(item => item && item.key === raw.key)?.value;
                if (sourceVal)
                    return {
                        key: raw.key,
                        value: sourceVal,
                        isExactMatch: !raw.possiblyExact ? false : sourceVal === raw.value,
                    };
            })
            .filter(refined => refined && !!refined?.key && !!refined?.value && refined?.isExactMatch !== undefined);

        actionableResults.sort((a, b) => {
            if (a.isExactMatch && !b.isExactMatch) {
                return -1;
            } else if (!a.isExactMatch && b.isExactMatch) {
                return 1;
            } else {
                const distanceA = calcTermDistance(searchRef.current, a.value);
                const distanceB = calcTermDistance(searchRef.current, b.value);
                return distanceA - distanceB;
            }
        });

        setSearchResults(actionableResults);
        setSearchComplete(true);
    }, [rawSearchResults, sourceValues, setSearchResults]);

    // debounce the search term to prevent excessive computation
    useEffect(() => {
        const handler = setTimeout(() => {
            if (searchTerm !== searchRef.current) {
                setRawSearchResults(search(searchTerm));
            }

            searchRef.current = searchTerm;
        }, debounceMs);

        return () => {
            clearTimeout(handler);
        };
    }, [searchTerm, search, debounceMs]);

    return {
        setSearchTerm,
        searchComplete,
        searchResults,
    };
};

export default useFuzzySearch;
