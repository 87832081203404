import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FlagRowDetails = ({
    title,
    isExpanded,
    isHidden,
    toggleFlag,
    oneTimeUseFlagId,
    flagDisposition,
    handleFlagDispositionClick,
    restoreFlagDisposition,
    flag,
}) => {
    return (
        <>
            <div className="d-flex align-items-center">
                {isExpanded ? <FontAwesomeIcon icon="chevron-up" /> : <FontAwesomeIcon icon="chevron-down" />}
                <h6 className="mb-0 ms-2 me-2">{title}</h6>
            </div>
            <div className="d-flex align-items-center">
                <button
                    role="toggleFlag"
                    type="button"
                    className="btn btn-sm me-2 btn-light"
                    onClick={e => toggleFlag(e, oneTimeUseFlagId)}>
                    {isHidden ? <FontAwesomeIcon icon="eye-slash" /> : <FontAwesomeIcon icon="eye" />}
                </button>
                {flagDisposition === null ? (
                    <button
                        role="disposeFlag"
                        type="button"
                        className="btn btn-sm btn-danger"
                        onClick={e => handleFlagDispositionClick(e, flag)}>
                        <FontAwesomeIcon icon="trash" />
                    </button>
                ) : (
                    <button
                        role="restoreFlag"
                        type="button"
                        className="btn btn-sm btn-success"
                        onClick={e => restoreFlagDisposition(e)}>
                        <FontAwesomeIcon icon="trash-restore" />
                    </button>
                )}
            </div>
        </>
    );
};

export default FlagRowDetails;
