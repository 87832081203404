import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SortableTableHeader = ({ title, propertyKey, sortColumn, handleSortColumnClick }) => {
    return (
        <th className="clickable" onClick={() => handleSortColumnClick(propertyKey)}>
            <div className="d-flex align-items-center">
                {sortColumn !== null && sortColumn.key === propertyKey && (
                    <FontAwesomeIcon icon={sortColumn.direction === 'A' ? 'sort-up' : 'sort-down'} />
                )}
                <span className="ms-2">{title}</span>
            </div>
        </th>
    );
};

export default SortableTableHeader;
