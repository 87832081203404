import { requestBooksForOemApi } from 'api/RepairProcedureApi';
import { useEffect, useState } from 'react';
import { RpBookType } from 'types/RpBookType';

const useRpBooks = (oemId: number | string, onError?: (value: unknown) => void) => {
    const [rpBooks, setRpBooks] = useState<RpBookType[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        let isUnmounted = false;

        (async () => {
            try {
                setIsLoading(true);
                const books = await requestBooksForOemApi(oemId);

                if (!isUnmounted) {
                    setRpBooks(books);
                }
            } catch (error) {
                onError && onError(error);
            } finally {
                if (!isUnmounted) {
                    setIsLoading(false);
                }
            }
        })();

        return () => {
            isUnmounted = true;
        };
    }, [oemId, onError]);

    return { rpBooks, isLoading };
};

export default useRpBooks;
