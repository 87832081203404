import { PROPERTY_TYPE } from './PropertyType';
import { buildProcedureProperty } from './ProcedureProperty';
import { MappingRuleId, MappingStatus, VehicleFilter, ProcedureHTML } from 'components/Shared/TableFilters/Types';
import { Group } from 'api/RepairProcedures/types';
import { FilterProperty } from './BuildOemMetadata';
import { ProcedureType } from './ProcedureType';
import ProcedureTypeFilter from 'components/Shared/TableFilters/Types/ProcedureTypeFilter';
import { DataSource } from './DataSource';
import { FilterPropertyEnum } from './types/FilterPropertiesEnum';

export enum CommonPropertiesId {
    ProcedureId = 'procedureId',
    FirstBookId = 'firstBookId',
    FirstBookName = 'firstBookName',
    Vehicles = 'vehicles',
    ProcedureTitle = 'procedureTitle',
    StageAreaGroups = 'stageArea.groups',
    GroupsView = 'groupsView',
    TypeId = 'TypeId',
    TypeName = 'TypeName',
    SqlUpdateDate = 'SqlUpdateDate',
    UpdateDate = 'UpdateDate',
    Timestamp = '_timestamp',
}

export const getCommonProperties = () => [
    buildProcedureProperty({
        name: CommonPropertiesId.ProcedureId,
        displayName: 'Rp Procedure Id',
        type: PROPERTY_TYPE.historyView,
    }),
    buildProcedureProperty({
        id: CommonPropertiesId.FirstBookId,
        name: 'booksForProcedure.0.book.bookId',
        displayName: 'Book Id',
        type: PROPERTY_TYPE.bookId,
        sortable: false,
        procedureTypes: [ProcedureType.Bulletin, ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        id: CommonPropertiesId.FirstBookName,
        name: 'booksForProcedure.0.book.bookName',
        displayName: 'Book name',
        type: PROPERTY_TYPE.bookName,
        sortable: false,
        procedureTypes: [ProcedureType.Bulletin, ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: CommonPropertiesId.Vehicles,
        displayName: 'Vehicles',
        type: PROPERTY_TYPE.vehicleView,
        sortable: false,
        searchable: false,
    }),
    buildProcedureProperty({
        name: CommonPropertiesId.ProcedureTitle,
        displayName: 'Procedure Title',
        type: PROPERTY_TYPE.procedureView,
    }),
    buildProcedureProperty<Group[]>({
        name: CommonPropertiesId.StageAreaGroups,
        displayName: 'Group Ids',
        multiple: true,
        type: PROPERTY_TYPE.groupsIds,
        sortable: false,
    }),
    buildProcedureProperty<Group[]>({
        id: CommonPropertiesId.GroupsView,
        name: 'stageArea.groups',
        displayName: 'Groups',
        type: PROPERTY_TYPE.groupView,
        sortable: false,
    }),
    buildProcedureProperty({
        id: CommonPropertiesId.TypeId,
        name: 'stageArea.type.typeId',
        displayName: 'Type Id',
        type: PROPERTY_TYPE.typeId,
        sortable: false,
    }),
    buildProcedureProperty({
        id: CommonPropertiesId.TypeName,
        name: 'stageArea.type.typeId',
        displayName: 'Type Name',
        type: PROPERTY_TYPE.typeName,
        sortable: false,
    }),
];

export const getLastCommonProperties = () => {
    const commonProperties = [
        buildProcedureProperty({
            id: CommonPropertiesId.SqlUpdateDate,
            name: 'sqlUpdateDate',
            displayName: 'SQL Update Date',
            type: PROPERTY_TYPE.gmtDate,
            sortable: false,
            searchable: false,
        }),

        buildProcedureProperty({
            id: CommonPropertiesId.UpdateDate,
            name: 'updateDate',
            displayName: 'ES Index Update Date',
            type: PROPERTY_TYPE.gmtDate,
            sortable: true,
            dataSources: [DataSource.ES],
        }),
        buildProcedureProperty({
            id: CommonPropertiesId.Timestamp,
            name: '_timestamp',
            displayName: 'Upsert Timestamp',
            type: PROPERTY_TYPE.gmtDate,
            sortable: true,
            dataSources: [DataSource.ES],
        }),
    ];

    return commonProperties;
};

export const ProcedureTypeFilterProperty: FilterProperty = {
    id: 'ProcedureType',
    label: 'Procedure Type',
    allowInstances: 1,
    property: 'ProcedureType',
    ruleable: false,
    type: ProcedureTypeFilter,
    dataSources: [DataSource.ES, DataSource.SQL],
    procedureTypes: [ProcedureType.Procedure, ProcedureType.Bulletin, ProcedureType.PositionStatement],
};

export const getCommonFilters = (): FilterProperty[] => {
    const commonFilters = [
        {
            id: FilterPropertyEnum.MappingRuleId,
            label: 'Mapping Rule Id',
            allowInstances: 10,
            property: FilterPropertyEnum.MappingRuleId,
            ruleable: false,
            type: MappingRuleId,
            dataSources: [DataSource.ES, DataSource.SQL],
            procedureTypes: [ProcedureType.Procedure, ProcedureType.Bulletin],
        },
        {
            id: 'MappingStatus',
            label: 'Procedure Mapping Status',
            allowInstances: 10,
            property: 'MappingStatus',
            ruleable: false,
            type: MappingStatus,
            dataSources: [DataSource.ES, DataSource.SQL],
            procedureTypes: [ProcedureType.Procedure, ProcedureType.Bulletin, ProcedureType.PositionStatement],
        },
        {
            id: FilterPropertyEnum.Vehicle,
            label: 'Vehicle',
            allowInstances: 10,
            property: FilterPropertyEnum.Vehicle,
            ruleable: false,
            type: VehicleFilter,
            dataSources: [DataSource.ES, DataSource.SQL],
            procedureTypes: [ProcedureType.Procedure, ProcedureType.Bulletin, ProcedureType.PositionStatement],
        },
        {
            id: 'ProcedureHTML',
            label: 'Procedure HTML',
            allowInstances: 10,
            property: 'ProcedureHTML',
            ruleable: false,
            type: ProcedureHTML,
            dataSources: [DataSource.ES],
            procedureTypes: [ProcedureType.Procedure, ProcedureType.Bulletin, ProcedureType.PositionStatement],
        },
        ProcedureTypeFilterProperty,
    ];

    return commonFilters;
};
