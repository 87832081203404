interface Group {
    label: string;
    value: string;
}

interface Type {
    oemIqSectionName: string;
    value: string;
}

interface OneTimeUseFlagTerm {
    term: string;
    id: string;
}

export interface RuleCondition {
    field: { label: string; value: string };
    operator: { label: string; value: string };
    values: InputValue[];
}

export interface InputValue {
    label: string;
    value: string;
    isDisabled?: boolean;
}

export class FilterViewModel {
    private groups: Group[];
    private types: Type[];
    private oneTimeUseFlagTerms: OneTimeUseFlagTerm[];

    constructor(groups: Group[], types: Type[], oneTimeUseFlagTerms: OneTimeUseFlagTerm[]) {
        this.groups = groups;
        this.types = types;
        this.oneTimeUseFlagTerms = oneTimeUseFlagTerms;
    }

    private static filterTypeLabels = {
        Group: 'Groups',
        Type: 'Type',
        ProcedureTitle: 'Procedure Title',
        Text: 'Text',
        OneTimeUseFlagTerm: '1xId Flag Term',
    };

    getFilterTypes(ruleConditions: RuleCondition[]): InputValue[] {
        return Object.entries(FilterViewModel.filterTypeLabels).map(([value, label]) => ({
            label,
            value,
            isDisabled:
                value !== 'ProcedureTitle' && value !== 'Text'
                    ? ruleConditions.some(
                          rc =>
                              rc.field.value != 'ProcedureTitle' && rc.field.value != 'Text' && rc.field.value === value
                      )
                    : false,
        }));
    }

    getOperatorTypes(filter: InputValue | null): InputValue[] {
        const operatorMap = {
            ProcedureTitle: [
                { value: 'Contains', label: 'contains' },
                { value: 'NotContains', label: 'not contains' },
            ],
            Text: [
                { value: 'Contains', label: 'contains' },
                { value: 'NotContains', label: 'not contains' },
            ],
            Group: [{ value: 'Equals', label: 'equals' }],
            Type: [{ value: 'Equals', label: 'equals' }],
            OneTimeUseFlagTerm: [
                { value: 'Equals', label: 'equals' },
                { value: 'NotEquals', label: 'not equals' },
            ],
        };

        return operatorMap[filter?.value ?? ''] || [];
    }

    getValueTypes(filter: InputValue | null): InputValue[] {
        const valueMap: Record<string, () => InputValue[]> = {
            Group: () =>
                this.groups.map(group => ({
                    ...group,
                })),
            Type: () =>
                this.types.map(type => ({
                    label: type.oemIqSectionName,
                    value: type.value,
                })),
            OneTimeUseFlagTerm: () =>
                this.oneTimeUseFlagTerms.map(term => ({
                    label: `${term.term} (ID: ${term.id})`,
                    value: term.id,
                })),
        };

        return (valueMap[filter?.value ?? ''] || (() => []))();
    }
}
