import React from 'react';
import { ReactModal, LargeCheckbox } from 'oemiq-common';
import useAddPositionStatementModal from './useAddPositionStatementModal';
import ReactSelect from 'react-select';
import PositionStatementFileDropBox from '../PositionStatementFileDropBox/PositionStatementFileDropBox';

const AddPositionStatementModal = ({ isOpen, toggle, oemId, onSave }) => {
    const {
        handleToggle,
        title,
        setTitle,
        isOem,
        handleIsOemToggle,
        yearOptions,
        handleYearChange,
        years,
        modelOptions,
        handleModelChange,
        models,
        regionOptions,
        handleRegionChange,
        regions,
        handleSave,
        saveEnabled,
        setFileData,
    } = useAddPositionStatementModal(toggle, oemId, onSave);

    return (
        <ReactModal
            id="position-statement-modal"
            headerComponent={<div>Upload New Position Statement</div>}
            isOpen={isOpen}
            toggle={handleToggle}
            bodyComponent={
                <div>
                    <PositionStatementFileDropBox fileDataCallback={setFileData} />
                    <input
                        id="ps-title"
                        type="text"
                        className="form form-control mt-3"
                        placeholder="Position Statement Title (Required)"
                        defaultValue={title}
                        onChange={e => setTitle(e.target.value)}
                    />
                    <div className="mt-3">
                        <LargeCheckbox
                            id="ps-isoem"
                            label="Apply to Entire Oem"
                            checked={isOem}
                            readOnly={true}
                            onChange={handleIsOemToggle}
                        />
                    </div>
                    {!isOem && (
                        <>
                            <div className="mt-3">
                                {yearOptions && (
                                    <ReactSelect
                                        className="mt-2"
                                        isMulti={true}
                                        placeholder="Select Available Years"
                                        options={yearOptions}
                                        value={years}
                                        onChange={e => handleYearChange(e)}
                                    />
                                )}
                            </div>
                            <div className="mt-3">
                                {modelOptions && (
                                    <ReactSelect
                                        className="mt-2"
                                        isMulti={true}
                                        placeholder="Select Available Models"
                                        options={modelOptions}
                                        value={models}
                                        onChange={e => handleModelChange(e)}
                                    />
                                )}
                            </div>
                        </>
                    )}
                    <div className="mt-3">
                        {regionOptions && (
                            <ReactSelect
                                className="mt-2"
                                isMulti={true}
                                placeholder="Select Available Groups"
                                options={regionOptions}
                                value={regions}
                                onChange={e => handleRegionChange(e)}
                            />
                        )}
                    </div>
                </div>
            }
            footerComponent={
                <div>
                    <button type="button" className="btn btn-sm btn-danger h-100" onClick={handleToggle}>
                        Cancel
                    </button>
                    <button
                        id="ps-create"
                        type="button"
                        className="btn btn-sm btn-primary ms-3 h-100"
                        onClick={handleSave}
                        disabled={!saveEnabled}>
                        Save
                    </button>
                </div>
            }
        />
    );
};

export default AddPositionStatementModal;
