import { createContext } from 'react';
import { ImportJobDownloadType } from 'types/importJobDownloadType';

export interface ImportManagerDashboardContextType {
    importJobDownloadTypes: ImportJobDownloadType[];
}

export const initialImportManagerDashboardContextValue: ImportManagerDashboardContextType = {
    importJobDownloadTypes: [],
};

const ImportManagerDashboardContext = createContext<ImportManagerDashboardContextType>(
    initialImportManagerDashboardContextValue
);
export default ImportManagerDashboardContext;
