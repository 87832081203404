import { useContext, useEffect, useState } from 'react';
import { ToastContext } from 'components/ToastProvider';
import { getOneTimeUseFlagDispositionTypes } from 'api/RepairProcedureApi';

const useOneTimeUseFlagDispositions = () => {
    const [oneTimeUseFlagDispositions, setOneTimeUseFlagDispositions] = useState([]);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    const { showToast } = useContext(ToastContext);

    useEffect(() => {
        let isUnmounted = false;
        (async () => {
            try {
                setLoading(true);
                const values = await getOneTimeUseFlagDispositionTypes();
                // drowdown friendly values (move the 'Other' option to the bottom of the list)
                const options = values
                    .map(({ oneTimeUseFlagDispositionId, oneTimeUseFlagDispoistionName }) => ({
                        key: oneTimeUseFlagDispositionId,
                        value: oneTimeUseFlagDispositionId,
                        text: oneTimeUseFlagDispoistionName,
                    }))
                    .map(option =>
                        option.text.toLowerCase() === 'other'
                            ? { ...option, key: Math.max(...values.map(v => v.oneTimeUseFlagDispositionId)) + 1 }
                            : option
                    )
                    .sort((a, b) => a.key - b.key);
                if (!isUnmounted) {
                    setOneTimeUseFlagDispositions(values);
                    setOptions(options);
                }
            } catch (error) {
                showToast(error);
            } finally {
                if (!isUnmounted) {
                    setLoading(false);
                }
            }
        })();

        return () => (isUnmounted = true);
    }, [showToast]);

    return { oneTimeUseFlagDispositions, options, loading };
};

export default useOneTimeUseFlagDispositions;
