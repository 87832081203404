import { useCallback, useState } from 'react';
import Breadcrumb from 'components/Navigation/items/Breadcrumb';
import useOemInfo from 'components/Navigation/items/useOemInfo';
import ViewImportJobHistoryModal from 'components/Modals/ViewImportJobHistoryModal/ViewImportJobHistoryModal';
import ImportJobChangeReportModal from 'components/Modals/ViewImportJobHistoryModal/ImportJobChangeReportModal/ImportJobChangeReportModal';

const OemNameBreadcrumb = ({ oemId, isCurrent, link, withImportJob }) => {
    const [isImportJobModalOpen, setIsImportJobModalOpen] = useState(false);
    const [isImportJobChangeReportModalOpen, setIsImportJobChangeReportModalOpen] = useState(false);
    const [changeReportImportJobId, setChangeReportImportJobId] = useState(null);
    const { oemName } = useOemInfo(oemId);

    const iconOnClick = useCallback(
        e => {
            e.stopPropagation();
            withImportJob && setIsImportJobModalOpen(true);
        },
        [withImportJob]
    );

    const handleOpenChangeReport = (openModal, importJobId) => {
        setIsImportJobChangeReportModalOpen(openModal);
        setChangeReportImportJobId(importJobId);
    };

    return (
        <>
            <Breadcrumb
                isShown={oemId}
                isCurrent={isCurrent}
                link={link}
                text={oemName}
                icon={withImportJob && 'clock'}
                iconOnClick={iconOnClick}
            />
            {withImportJob && (
                <ViewImportJobHistoryModal
                    isOpen={isImportJobModalOpen}
                    toggle={() => setIsImportJobModalOpen(!isImportJobModalOpen)}
                    oemName={oemName}
                    oemId={oemId}
                    handleOpenChangeReport={handleOpenChangeReport}
                />
            )}
            {withImportJob && (
                <ImportJobChangeReportModal
                    isOpen={isImportJobChangeReportModalOpen}
                    toggle={() => handleOpenChangeReport(false, null)}
                    toggleHistory={() => setIsImportJobModalOpen(!isImportJobModalOpen)}
                    importJobId={changeReportImportJobId}
                />
            )}
        </>
    );
};

export default OemNameBreadcrumb;
