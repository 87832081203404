import useOemService from 'hooks/OemModels/useOemService';

export interface Column {
    fieldName: string;
    displayName: string;
}
export interface OemDataModel {
    keyField: string;
    columns: Column[];
}
export interface OemRulesMetadata {
    oemId: number;
    oemName: string;
    dataModel: OemDataModel;
}

const cache = new Map<number | string, OemDataModel>();

export const useOemDataModel = (oemId: number | string): OemDataModel => {
    const { oemMetadataView } = useOemService(oemId);

    if (cache.has(oemId)) return cache.get(oemId);

    if (!oemMetadataView) throw `No metamodel defined for OEM Id = ${oemId}`;

    const dataModel: OemDataModel = {
        keyField: oemMetadataView.metadata.ruleKey,
        columns: oemMetadataView.ruleable.map(p => {
            const { rulePropertyName: fieldName, displayName: displayName } = oemMetadataView.metadata.properties[p];
            return { fieldName, displayName };
        }),
    };

    cache.set(oemId, dataModel);

    return dataModel;
};
