import { buildFilteredOemMetadata, OemMetadataView } from './MetaModel/BuildOemMetadata';
import { OemEngineService } from './MetaModel/types';
import { OemId } from 'helpers/OemId';
import { NISSAN_PROCEDURE_METADATA, NissanMappingEngineService } from './MetaModel/NissanProcedureMetadata';
import { TOYOTA_PROCEDURE_METADATA, ToyotaMappingEngineService } from './MetaModel/ToyotaProcedureMetadata';
import { FORD_PROCEDURE_METADATA, FordMappingEngineService } from './MetaModel/FordProcedureMetadata';
import { GM_PROCEDURE_METADATA, GMMappingEngineService } from './MetaModel/GMProcedureMetadata';
import { OEMIQ_FORD_PROCEDURE_METADATA, OemIqFordMappingEngineService } from './MetaModel/OemIqFordProcedureMetadata';
import { STELLANTIS_PROCEDURE_METADATA, StellantisMappingEngineService } from './MetaModel/StellantisProcedureMatadata';
import { VW_PROCEDURE_METADATA, VWMappingEngineService } from './MetaModel/VWProcedureMetadata';
import { SUBARU_PROCEDURE_METADATA, SubaruMappingEngineService } from './MetaModel/SubaruProcedureMatadata';
import { HYUNDAI_PROCEDURE_METADATA, HyundaiMappingEngineService } from './MetaModel/HyundaiProcedureMatadata';
import {
    FordProceduresApiService,
    OemIqFordProceduresApiService,
    ToyotaProceduresApiService,
    NissanProceduresApiService,
    GMProceduresApiService,
    HondaProceduresApiService,
    StellantisProceduresApiService,
    VWProceduresApiService,
    SubaruProceduresApiService,
    HyundaiProceduresApiService,
    OemProceduresApiService,
} from 'api/RepairProcedures/OemProceduresApiService';
import { match } from 'ts-pattern';
import { useMemo } from 'react';
import { ProcedureType } from './MetaModel/ProcedureType';
import { HONDA_PROCEDURE_METADATA, HondaMappingEngineService } from './MetaModel/HondaProcedureMetadata';
import { DataSource } from './MetaModel/DataSource';

const oemToConf = (oemId: OemId, procedureType: ProcedureType | null, dataSource: DataSource) => {
    return match(oemId)
        .with(OemId.Ford, () => ({
            oemMetadataView: buildFilteredOemMetadata(FORD_PROCEDURE_METADATA, procedureType, dataSource),
            oemEngineService: FordMappingEngineService,
            oemService: FordProceduresApiService,
        }))
        .with(OemId.OEMiQ, () => ({
            oemMetadataView: buildFilteredOemMetadata(OEMIQ_FORD_PROCEDURE_METADATA, procedureType, dataSource),
            oemEngineService: OemIqFordMappingEngineService,
            oemService: OemIqFordProceduresApiService,
        }))
        .with(OemId.Toyota, () => ({
            oemMetadataView: buildFilteredOemMetadata(TOYOTA_PROCEDURE_METADATA, procedureType, dataSource),
            oemEngineService: ToyotaMappingEngineService,
            oemService: ToyotaProceduresApiService,
        }))
        .with(OemId.Nissan, () => ({
            oemMetadataView: buildFilteredOemMetadata(NISSAN_PROCEDURE_METADATA, procedureType, dataSource),
            oemEngineService: NissanMappingEngineService,
            oemService: NissanProceduresApiService,
        }))
        .with(OemId.GMC, () => ({
            oemMetadataView: buildFilteredOemMetadata(GM_PROCEDURE_METADATA, procedureType, dataSource),
            oemEngineService: GMMappingEngineService,
            oemService: GMProceduresApiService,
        }))
        .with(OemId.Honda, () => ({
            oemMetadataView: buildFilteredOemMetadata(HONDA_PROCEDURE_METADATA, procedureType, dataSource),
            oemEngineService: HondaMappingEngineService,
            oemService: HondaProceduresApiService,
        }))
        .with(OemId.Chrysler, () => ({
            oemMetadataView: buildFilteredOemMetadata(STELLANTIS_PROCEDURE_METADATA, procedureType, dataSource),
            oemEngineService: StellantisMappingEngineService,
            oemService: StellantisProceduresApiService,
        }))
        .with(OemId.Volkswagen, () => ({
            oemMetadataView: buildFilteredOemMetadata(VW_PROCEDURE_METADATA, procedureType, dataSource),
            oemEngineService: VWMappingEngineService,
            oemService: VWProceduresApiService,
        }))
        .with(OemId.Subaru, () => ({
            oemMetadataView: buildFilteredOemMetadata(SUBARU_PROCEDURE_METADATA, procedureType, dataSource),
            oemEngineService: SubaruMappingEngineService,
            oemService: SubaruProceduresApiService,
        }))
        .with(OemId.Hyundai, () => ({
            oemMetadataView: buildFilteredOemMetadata(HYUNDAI_PROCEDURE_METADATA, procedureType, dataSource),
            oemEngineService: HyundaiMappingEngineService,
            oemService: HyundaiProceduresApiService,
        }))
        .otherwise(() => ({ oemMetadataView: null, oemEngineService: null, oemService: null }));
};

const useOemService = (
    oemId: number | string,
    procedureType = null,
    dataSource = DataSource.SQL
): {
    oemMetadataView: OemMetadataView;
    oemEngineService: OemEngineService<unknown>;
    oemService: OemProceduresApiService<unknown>;
} => {
    const oemIdAsNumber = typeof oemId === 'number' ? oemId : parseInt(oemId, 10);
    return useMemo(
        () => oemToConf(oemIdAsNumber as OemId, procedureType, dataSource),
        [dataSource, procedureType, oemIdAsNumber]
    );
};

export default useOemService;
