import React from 'react';
import Search from './Search/Search';
import Filter from './Filter/Filter';

const SearchFilter = ({
    handleSearch,
    searchOptions,
    bookId,
    filterColumnOptions,
    appliedFilters,
    filterByNoGroups,
    filterByNoType,
    filterRefreshed,
    handleUpdateApliedFilters,
    handleUpdateFilterByNoGroups,
    handleUpdateFilterByNoType,
    handleUpdateFilterRefreshed,
    isInitialUrlFiltersCheck,
}) => {
    return (
        <div>
            <Search handleSearch={handleSearch} searchOptions={searchOptions} bookId={bookId} />
            <Filter
                filterColumnOptions={filterColumnOptions}
                appliedFilters={appliedFilters}
                filterByNoGroups={filterByNoGroups}
                filterByNoType={filterByNoType}
                filterRefreshed={filterRefreshed}
                handleUpdateApliedFilters={handleUpdateApliedFilters}
                handleUpdateFilterByNoType={handleUpdateFilterByNoType}
                handleUpdateFilterByNoGroups={handleUpdateFilterByNoGroups}
                handleUpdateFilterRefreshed={handleUpdateFilterRefreshed}
                isInitialUrlFiltersCheck={isInitialUrlFiltersCheck}
            />
        </div>
    );
};

export default SearchFilter;
