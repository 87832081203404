import { useState, useEffect, useContext } from 'react';
import ReactGA from 'react-ga4';
import apiConfig from 'api/config/ApiConfig';
import { AccessControlContext } from 'components/Shared/AccessControl/AccessControl';

const GoogleAnalytics = () => {
    const [didUserInfoSet, setUserInfo] = useState(false);
    const { userInfo } = useContext(AccessControlContext);

    useEffect(() => {
        (async () => {
            if (didUserInfoSet || !userInfo || !userInfo.userId) return;

            const config = await apiConfig.get();
            if (!config?.gaMeasurementId) return;

            ReactGA.initialize(config.gaMeasurementId);
            ReactGA.set({
                dimension1: userInfo.userId,
                dimension2: userInfo.company?.companyId,
                dimension3: userInfo.company?.companyName,
                dimension4: userInfo.organization?.organizationId,
                dimension5: userInfo.organization?.name,
            });

            ReactGA.send({
                hitType: 'pageview',
                page: window.location.pathname + window.location.search,
            });

            setUserInfo(true);
        })();
    }, [userInfo, didUserInfoSet, setUserInfo]);

    return null;
};

export default GoogleAnalytics;
