import React, { useState, useEffect, useContext } from 'react';
import { requestImportJobHistoryByOemId } from 'api/RepairProcedureApi';
import { requestOrganizationUsers } from 'api/SecurityApi';
import { ToastContext } from 'components/ToastProvider';
import { FormatDateToHumanReadable } from 'helpers/DateHelper';
import { AccessControlContext } from 'components/Shared/AccessControl/AccessControl';

const useViewImportJobHistoryModal = (oemId, isOpen, handleOpenChangeReport) => {
    const [importJobHistory, setImportJobHistory] = useState([]);
    const [orgUsers, setOrgUsers] = useState([]);
    const { userInfo } = useContext(AccessControlContext);
    const [noDataMessage, setNoDataMessage] = useState('Loading Import Job History');
    const [isLoading, setIsLoading] = useState(false);
    const { showToast } = useContext(ToastContext);

    useEffect(() => {
        (async () => {
            if (!userInfo || !userInfo.organization) return;

            setOrgUsers(await requestOrganizationUsers(userInfo.organization.organizationId));
        })();
    }, [userInfo]);

    useEffect(() => {
        (async () => {
            if (orgUsers.length === 0 || !isOpen) return;

            setIsLoading(true);

            try {
                const history = await requestImportJobHistoryByOemId(oemId);
                history.forEach(h => {
                    const userInfo = orgUsers.find(u => u.userId === h.userId);
                    h.success = h.success === null ? 'In Progress' : h.success ? 'Yes' : 'No';
                    h.userName = h.userId ? (userInfo ? userInfo.userName : 'User Not Found') : 'Automated Process';
                    h.startDate = FormatDateToHumanReadable(h.createdAt);
                    h.totalUpdated = h.procedureSummary.updated;
                    h.totalNew = h.procedureSummary.new;
                    h.changeReport = (
                        <div
                            className="clickable text-primary"
                            onClick={() => handleOpenChangeReport(true, h.importJobId)}>
                            Open Report
                        </div>
                    );
                });
                if (history.length === 0) setNoDataMessage('No data found to display');
                setImportJobHistory(history);
            } catch (error) {
                showToast();
            } finally {
                setIsLoading(false);
            }
        })();
    }, [oemId, isOpen, orgUsers, handleOpenChangeReport, showToast]);

    const tableColumnDetails = [
        { title: 'Started By', columnProperty: 'userName', sortable: false },
        { title: 'Number Of Books', columnProperty: 'numberOfBooks', sortable: false },
        { title: 'Total New', columnProperty: 'totalNew', sortable: false },
        { title: 'Total Updated', columnProperty: 'totalUpdated', sortable: false },
        { title: 'Success', columnProperty: 'success', sortable: false },
        { title: 'Start Date', columnProperty: 'startDate', sortable: false },
        { title: 'Change Report', columnProperty: 'changeReport', sortable: false },
    ];

    return { importJobHistory, tableColumnDetails, isLoading, noDataMessage };
};

export default useViewImportJobHistoryModal;
