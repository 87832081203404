import { useCallback, useState } from 'react';
import { ToastType } from './ToastType';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { appInsights } from '../../api/config/AppInsights';
import { isAllowedToLogErrors } from 'helpers/EnvironmentHelper';

const useToast = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [header, setHeader] = useState('Error');
    const [type, setType] = useState(ToastType.ERROR);
    const [message, setMessage] = useState('An error was encountered with the requested page.');

    const showToastMessage = useCallback((header, message, type = ToastType.ERROR) => {
        if (header) setHeader(header);
        if (message) setMessage(message);
        setType(type);
        setIsOpen(true);
    }, []);

    const showToast = useCallback(error => {
        if (isAllowedToLogErrors()) {
            /**
             * Basically this means nothing if the errors are automatically logged to the console by default
             * but for some other errors, this MIGHT help to display them
             * Consequence: an error might be displayed twice
             */
            console.error(error);
        }
        setIsOpen(true);
        appInsights.trackException({ error: error, severityLevel: SeverityLevel.Error });
    }, []);

    const setToastType = useCallback(type => setType(type), []);

    const hideToast = useCallback(() => setIsOpen(false), []);

    return {
        isOpen,
        header,
        message,
        showToast,
        showToastMessage,
        hideToast,
        type,
        setToastType,
    };
};

export default useToast;
