export type ODataParams = {
    select?: string;
    expand?: string;
    filter?: string;
    orderby?: string;
    top?: number;
    skip?: number;
    count?: boolean;
};

export const constructUrlFromODataParams = (baseUrl: string, odataParams?: ODataParams) => {
    if (!odataParams) return baseUrl;

    const stringRecordParams = Object.entries(odataParams).reduce((acc, [key, value]) => {
        if (value) acc[`$${key}`] = value.toString();
        return acc;
    }, {});

    const searchParamsObj = new URLSearchParams(stringRecordParams);

    return `${baseUrl}?${searchParamsObj.toString()}`;
};
