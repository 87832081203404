import { useState, useContext } from 'react';
import { TaggerToolContext } from 'components/locations/TaggingProcess/TaggerTool/TaggerTool';
import { requestUpdateOneTimeUseTag, requestUpdateFlagDisposition } from 'api/RepairProcedureApi';
import { ToastContext } from 'components/ToastProvider';
import { FLAG_TAG_ITEM_TYPES } from '../useTagsAndFlagsDisplay';
import { LoadingContext } from 'components/Layout';

const useFlagTagItem = (flagTagItem, isScrollToContentEnabled) => {
    const { setTags, setFlags, scrollToContentStart } = useContext(TaggerToolContext);
    const [isExpanded, setIsExpanded] = useState(false);
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const { showToast } = useContext(ToastContext);

    const isFlag = flagTagItem.type === FLAG_TAG_ITEM_TYPES.FLAG;

    let rowStyle = { backgroundColor: flagTagItem.data.colorHex }; //Used to show unique color for flag or tags and handling grey/opacity for dispositioned flags
    if (isFlag) {
        if (flagTagItem.data.flagDisposition) {
            rowStyle = { backgroundColor: '#cccccc', opacity: '50%' };
        }
    } else if (!flagTagItem.data.isActive) {
        rowStyle = { backgroundColor: flagTagItem.data.colorHex, opacity: '50%' };
    }

    const handleRowClick = () => {
        if (isScrollToContentEnabled) {
            if (isFlag)
                scrollToContentStart(
                    flagTagItem.data.oneTimeUseFlagElement && flagTagItem.data.oneTimeUseFlagElement.contentStart
                );
            else
                scrollToContentStart(
                    flagTagItem.data.oneTimeUseTagElements &&
                        flagTagItem.data.oneTimeUseTagElements.length > 0 &&
                        flagTagItem.data.oneTimeUseTagElements[0].contentStart
                );
        }
        setIsExpanded(currState => !currState);
    };

    // Note: this is only to update active + workflowstatus
    const handleUpdateTagClick = async (e, updateType) => {
        e.stopPropagation();
        if (isFlag) return;

        let tag = flagTagItem.data;

        //Determine what active state tag should be in when updating
        let isActive = tag.isActive;
        if (updateType === 'setInactive') isActive = false;
        else if (updateType === 'setActive') isActive = true;

        //Determine what workFlowStatus tag should be in when updating
        let updatingWorkflowStatusId = tag.workFlowStatus.taggingWorkFlowStatusId;
        if (updateType === 'setComplete') updatingWorkflowStatusId = 2;

        const updateCommand = {
            stagedOneTimeUseTagId: tag.stagedOneTimeUseTagId,
            title: tag.title,
            quantity: parseFloat(tag.quantity),
            quantityCondition: tag.quantityCondition,
            partNumber: tag.partNumber,
            price: parseFloat(tag.price),
            text: tag.text,
            colorHex: tag.colorHex,
            note: tag.note,
            linksChecked: tag.linksChecked,
            workFlowStatusid: updatingWorkflowStatusId,
            isChangingImages: false,
            startPath: null,
            endPath: null,
            isActive: isActive,
        };

        try {
            incrementLoading();
            await requestUpdateOneTimeUseTag([updateCommand]);
            // as we only updated isActive and/or workflowstatus, there is no need to update orphaned links
            setTags(currState => {
                let newTags = [...currState];
                let index = newTags.findIndex(t => t.stagedOneTimeUseTagId === tag.stagedOneTimeUseTagId);
                newTags[index].isActive = isActive;
                newTags[index].workFlowStatus.taggingWorkFlowStatusId = updatingWorkflowStatusId;
                return newTags;
            });
        } catch (error) {
            showToast(error);
        } finally {
            decrementLoading();
        }
    };

    const restoreFlagDisposition = async e => {
        e.stopPropagation();
        if (flagTagItem.type === 'tag') return;

        try {
            incrementLoading();
            await requestUpdateFlagDisposition([
                {
                    oneTimeUseFlagId: flagTagItem.data.oneTimeUseFlagId,
                    oneTimeUseFlagDispositionId: null,
                },
            ]);
            setFlags(currState => {
                let newState = [...currState];
                const index = newState.findIndex(ns => ns.oneTimeUseFlagId === flagTagItem.data.oneTimeUseFlagId);
                newState[index].flagDisposition = null;
                newState[index].title = newState[index].title.includes('Image')
                    ? 'Image Flagged - Required investigation'
                    : 'Flagged - Required investigation';
                return newState;
            });
        } catch (error) {
            showToast(error);
        } finally {
            decrementLoading();
        }
    };

    return {
        isExpanded,
        setIsExpanded,
        handleRowClick,
        handleUpdateTagClick,
        rowStyle,
        restoreFlagDisposition,
        isFlag,
    };
};

export default useFlagTagItem;
