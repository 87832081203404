import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { PrimaryButton } from 'oemiq-common';
import { partTypes, getPartTypeLabel } from 'helpers/PartTypes';
import useModal from 'hooks/useModal';
import FlaggerToolCreateModal from './FlaggerToolCreateModal';

import useFlagTerms from './useFlagTerms';
import FlaggerTable from './FlaggerToolTable';

import { AccessControlContext } from 'components/Shared/AccessControl/AccessControl';

const FlaggerTool = () => {
    const { isModalOpen, openModal, closeModal } = useModal();
    const { oemId } = useParams();
    const {
        flagTerms,
        navigateToTagger,
        createFlagTerm,
        runFlagTerm,
        undoFlagTerm,
        deleteFlagTerm,
        activateFlagTerm,
        getNumberOfFlags,
    } = useFlagTerms(closeModal);
    const { hasAccess } = useContext(AccessControlContext);

    return (
        <div className="p-3">
            <div className="flagger-header d-flex justify-content-between">
                <h2 className="mb-4">Flagger</h2>
                <PrimaryButton
                    id="create-new-flag-term"
                    className="me-3 align-self-baseline"
                    type="button"
                    disabled={!hasAccess('flagTerm.create')}
                    onClick={openModal}>
                    Create New Flag Term
                </PrimaryButton>
            </div>
            <FlaggerTable
                oemId={oemId}
                flagTerms={flagTerms}
                navigateToTagger={navigateToTagger}
                getPartTypeLabel={getPartTypeLabel}
                runFlagTerm={runFlagTerm}
                undoFlagTerm={undoFlagTerm}
                deleteFlagTerm={deleteFlagTerm}
                activateFlagTerm={activateFlagTerm}
                getNumberOfFlags={getNumberOfFlags}
            />
            {isModalOpen && (
                <FlaggerToolCreateModal
                    isOpen={isModalOpen}
                    toggleCallback={closeModal}
                    typeOptions={partTypes}
                    submitCallback={createFlagTerm}
                />
            )}
        </div>
    );
};

export default FlaggerTool;
