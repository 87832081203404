import React, { useCallback } from 'react';
import useOldMapperTable from './useOldMapperTable';
import OldMapperTableItem from './OldMapperTableItem/OldMapperTableItem';
import GroupTypeModal from './GroupTypeModal/GroupTypeModal';
import BulkMoveModal from './BulkMoveModal/BulkMoveModal';
import { PrimaryButton, LargeCheckbox } from 'oemiq-common';
import './OldMapperTable.css';
import DynamicTableHeading from './DynamicTableHeading/DynamicTableHeading';
import useModal from 'hooks/useModal';
import InfiniteScroll from 'components/InfiniteScroll';

const OldMapperTable = ({
    procedures,
    tab,
    setNewGroupListToProcedureByProcedureId,
    dynamicProcedureKeys,
    updateColumnWidth,
    mappingProgress,
    showIsDeletedFlags,
}) => {
    const {
        bulkSelections,
        handleBulkSelection,
        resetBulkSelection,
        handleSelectAll,
        sortColumn,
        handleSortColumnClick,
        sortedProcedures,
        groupTypeModalBulkType,
        setGroupTypeModalBulkType,
        isLoading,
        loadMore,
    } = useOldMapperTable(tab, procedures);

    const {
        isModalOpen: isBulkMoveModalOpen,
        openModal: openBulkMoveModal,
        closeModal: closeBulkMoveModal,
    } = useModal();

    const {
        isModalOpen: isGroupTypeModalOpen,
        openModal: openGroupTypeModal,
        closeModal: closeGroupTypeModal,
    } = useModal();

    const handleOpenBulkAssignModal = useCallback(() => {
        setGroupTypeModalBulkType('Assign');
        openGroupTypeModal();
    }, [openGroupTypeModal, setGroupTypeModalBulkType]);
    const handleOpenBulkRemoveModal = useCallback(() => {
        setGroupTypeModalBulkType('Remove');
        openGroupTypeModal();
    }, [openGroupTypeModal, setGroupTypeModalBulkType]);

    const handleEditGroupType = useCallback(
        ids => {
            handleBulkSelection(ids[0]);
            openGroupTypeModal();
        },
        [handleBulkSelection, openGroupTypeModal]
    );

    return (
        <>
            {!showIsDeletedFlags && (
                <div className="mt-3 mb-3">
                    <span className="pe-2 pb-1">
                        <PrimaryButton
                            id="bulk-assign-procedures"
                            type="button"
                            disabled={bulkSelections.length <= 1}
                            onClick={handleOpenBulkAssignModal}>
                            Bulk Assign
                        </PrimaryButton>
                    </span>
                    <span className="pe-2 pb-1">
                        <PrimaryButton
                            id="bulk-unassign-procedures"
                            type="button"
                            disabled={bulkSelections.length <= 1}
                            onClick={handleOpenBulkRemoveModal}>
                            Bulk Remove
                        </PrimaryButton>
                    </span>
                    <span className="pe-2 pb-1">
                        <PrimaryButton
                            id="bulk-move-procedures"
                            type="button"
                            disabled={bulkSelections.length === 0}
                            onClick={openBulkMoveModal}>
                            Bulk Move
                        </PrimaryButton>
                    </span>
                </div>
            )}
            {dynamicProcedureKeys.length > 0 && (
                <table className="table table-stripped table-responsive">
                    <thead className="thead-light">
                        <tr>
                            <th>
                                <LargeCheckbox
                                    id={'bulk-select-all'}
                                    label=""
                                    className="large-checkbox-check mb-0"
                                    checked={procedures.length === bulkSelections.length}
                                    onChange={() => handleSelectAll(procedures)}
                                    readOnly={false}
                                />
                            </th>
                            <th id="selected-count" style={{ width: '8%' }}>
                                {bulkSelections.length} Selected
                            </th>
                            <DynamicTableHeading // We are using this component for ID so that we can sort by this column
                                key={'ID'}
                                columnDetails={{ text: 'ID', isHidden: false, width: 'initial' }}
                                updateColumnWidth={updateColumnWidth}
                                handleSortColumnClick={handleSortColumnClick}
                                sortColumn={sortColumn}
                            />
                            <th
                                title={`Progress: ${mappingProgress.group}/${mappingProgress.total} (${mappingProgress.groupPercentage}%)`}>
                                Group ({mappingProgress.groupPercentage}%)
                            </th>
                            <th
                                title={`Progress: ${mappingProgress.type}/${mappingProgress.total} (${mappingProgress.typePercentage}%)`}>
                                Type ({mappingProgress.typePercentage}%)
                            </th>
                            {showIsDeletedFlags && (
                                <>
                                    <th>stageAreaIsDeleted</th>
                                    <th>productionAreaIsDeleted</th>
                                </>
                            )}
                            {dynamicProcedureKeys.map(d => (
                                <DynamicTableHeading
                                    key={d.text}
                                    columnDetails={d}
                                    updateColumnWidth={updateColumnWidth}
                                    handleSortColumnClick={handleSortColumnClick}
                                    sortColumn={sortColumn}
                                />
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {sortedProcedures.map(p => (
                            <OldMapperTableItem
                                key={p.procedureId}
                                procedure={p}
                                isSelected={bulkSelections.includes(p.procedureId)}
                                handleBulkSelection={handleBulkSelection}
                                setGroupTypeModalProcedureIds={handleEditGroupType}
                                setStatusModalProcedureIds={openBulkMoveModal}
                                dynamicProcedureKeys={dynamicProcedureKeys}
                                setNewGroupListToProcedureByProcedureId={setNewGroupListToProcedureByProcedureId}
                                showIsDeletedFlags={showIsDeletedFlags}
                            />
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>
                                <InfiniteScroll isLoading={isLoading} loadMoreCallback={loadMore} />
                            </td>
                        </tr>
                    </tfoot>
                </table>
            )}
            {isGroupTypeModalOpen && (
                <GroupTypeModal
                    isOpen={isGroupTypeModalOpen}
                    groupTypeModalProcedureIds={bulkSelections}
                    resetProcedureIds={closeGroupTypeModal}
                    setNewGroupListToProcedureByProcedureId={setNewGroupListToProcedureByProcedureId}
                    procedures={procedures}
                    resetBulkSelection={resetBulkSelection}
                    groupTypeModalBulkType={groupTypeModalBulkType}
                    setGroupTypeModalBulkType={setGroupTypeModalBulkType}
                />
            )}
            {isBulkMoveModalOpen && (
                <BulkMoveModal
                    isOpen={isBulkMoveModalOpen}
                    statusModalProcedureIds={bulkSelections}
                    selectedProceduresCount={bulkSelections.length}
                    resetProcedureIds={closeBulkMoveModal}
                    resetBulkSelection={resetBulkSelection}
                    procedures={procedures}
                />
            )}
        </>
    );
};

export default OldMapperTable;
