import { Buttons } from 'components/Shared/Table/Cells';

const tableConfiguration = [
    {
        label: 'Name',
        id: 'name',
    },
    {
        label: 'Type',
        id: 'networkType.name',
    },
    {
        label: 'Level',
        id: 'networkLevel.name',
    },
    {
        label: 'Note',
        id: 'note',
    },
    {
        label: 'Actions',
        component: Buttons({
            buttons: [
                {
                    id: 'editNetwork',
                    class: 'btn-primary',
                    text: '',
                    icon: 'edit',
                    title: 'Edit Network',
                    show: () => true,
                    disabled: () => false,
                },
                {
                    id: 'deleteNetwork',
                    class: 'btn-danger',
                    text: '',
                    icon: 'trash',
                    title: 'Delete Network',
                    show: () => true,
                    disabled: () => false,
                },
            ],
        }),
    },
];

export default tableConfiguration;
