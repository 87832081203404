import { fetchWithAuthHeader } from './AuthUtils';
import { OEC_STAGING_ORG } from '../components/locations/ManageCustomers/ManageCustomersConstants';

export const requestOrganizations = async () => {
    const url = 'api/Security/Organization';
    const response = await fetchWithAuthHeader(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
    });

    if (!response.ok) throw new Error('Could not get organizations');

    return await response.json();
};

export const requestOrganization = async orgId => {
    const url = `api/Security/Organization/${orgId}`;
    const response = await fetchWithAuthHeader(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
    });

    if (!response.ok) throw new Error('Could not get organization');

    return await response.json();
};

export const requestCreateOrganization = async (
    name,
    contactPersonId,
    address,
    city,
    state,
    zip,
    countryId,
    notes,
    segmentId
) => {
    const url = 'api/Security/Organization';
    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            name,
            contactPersonId,
            address,
            city,
            state,
            zip,
            countryId,
            notes,
            segmentId,
        }),
    });

    if (!response.ok) throw new Error('Could not create organization');

    return await response.json();
};

export const requestEditOrganization = async (
    orgId,
    name,
    contactPersonId,
    address,
    city,
    state,
    zip,
    countryId,
    notes,
    segmentId
) => {
    const url = `api/Security/Organization/${orgId}`;
    const response = await fetchWithAuthHeader(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            name,
            contactPersonId,
            address,
            city,
            state,
            zip,
            countryId,
            notes,
            segmentId,
        }),
    });
    //response check
    if (!response.ok) throw new Error('Could not update organization');

    return await response.json();
};

export const requestOrganizationUsers = async (orgId, queryActiveUsers = false) => {
    const url = `api/Security/Organization/${orgId}/users?queryActiveUsers=${queryActiveUsers}`;
    const response = await fetchWithAuthHeader(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
    });

    return await response.json();
};

export const requestOrganizationCompanies = async orgId => {
    const url = `api/Security/Organization/${orgId}/companies`;
    const response = await fetchWithAuthHeader(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
    });

    if (!response.ok) throw new Error('Could not get organization companies');

    return await response.json();
};

export const requestCreateOrganizationCompany = async (
    orgId,
    companyName,
    address,
    city,
    state,
    zip,
    countryId,
    businessPhone,
    website,
    billingType,
    price,
    oecId,
    salesAgreementId,
    pricingId,
    isInAppSurveyEnabled,
    features
) => {
    const url = `api/Security/Organization/${orgId}/companies`;
    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            name: companyName,
            address,
            city,
            state,
            zip,
            countryId,
            businessPhone,
            website,
            billingType,
            price,
            oecId,
            salesAgreementId,
            pricingId,
            isInAppSurveyEnabled,
            features,
        }),
    });

    if (!response.ok) throw new Error('Could not create organization company');

    return await response.json();
};

export const requestStagedCompanyTransfer = async (compId, destOrgId, editCompany = null) => {
    if (!compId || !destOrgId) throw new Error('Invalid companyId or destinationOrganizationId');

    const companyId = parseInt(compId);
    const destinationOrganizationId = parseInt(destOrgId);

    if (isNaN(companyId) || isNaN(destinationOrganizationId))
        throw new Error('Invalid companyId or destinationOrganizationId');

    const requestBody = editCompany
        ? JSON.stringify({
              destinationOrganizationId,
              companyId,
              companyUpdate: editCompany,
          })
        : JSON.stringify({ destinationOrganizationId, companyId });

    const url = `api/Security/Organization/${OEC_STAGING_ORG.organizationId}/companies/transfer`;
    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: requestBody,
    });

    if (!response.ok) {
        const responseData = response.status >= 400 ? await response.json() : null;

        if (responseData) {
            if (typeof responseData === 'string') throw new Error(responseData);
            if (typeof responseData === 'object')
                throw new Error(Object.values(responseData.errors).reduce((p, c) => `${p};${c}`));
        } else throw new Error('Could not transfer company');
    }

    return await response.json(); // the updated company info for dest org.
};

export const requestCompanyUsers = async (companyId, queryActiveUsers = false) => {
    const url = `api/Security/Company/${companyId}/users?queryActiveUsers=${queryActiveUsers}`;
    const response = await fetchWithAuthHeader(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
    });

    if (!response.ok) throw new Error('Could not get company users');

    return await response.json();
};

export const deletePendingUser = async userId => {
    const url = `api/Security/User/${userId}/deletePendingUser`;
    const response = await fetchWithAuthHeader(url, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
        },
    });

    if (!response.ok) throw new Error('Could not delete the user');
    return response;
};

export const requestCompany = async companyId => {
    const url = `api/Security/Company/${companyId}`;
    const response = await fetchWithAuthHeader(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
    });

    if (!response.ok) throw new Error('Could not get company');

    return await response.json();
};

const editCompanyRequest = (
    companyName, // string
    billingType, // number
    address, // string
    city, // string
    state, // string
    zip, // string
    countryId, // number
    businessPhone, // string
    website, // string
    price, // number
    salesAgreementId, // string
    oecId, // string
    pricingId, // string
    isInAppSurveyEnabled, // boolean
    features // [ {"id": 0, "name": "string", "friendlyName": "string"} ]
) =>
    JSON.stringify({
        companyName,
        billingType,
        address,
        city,
        state,
        zip,
        countryId,
        businessPhone,
        website,
        price,
        salesAgreementId,
        oecId,
        pricingId,
        isInAppSurveyEnabled,
        features,
    });

export const requestEditCompany = async (
    companyId,
    companyName,
    address,
    city,
    state,
    zip,
    countryId,
    businessPhone,
    website,
    billingType,
    price,
    oecId,
    salesAgreementId,
    pricingId,
    isInAppSurveyEnabled,
    features
) => {
    const url = `api/Security/Company/${companyId}`;
    const response = await fetchWithAuthHeader(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: editCompanyRequest(
            companyName,
            billingType,
            address,
            city,
            state,
            zip,
            countryId,
            businessPhone,
            website,
            price,
            salesAgreementId,
            oecId,
            pricingId,
            isInAppSurveyEnabled,
            features
        ),
    });

    if (!response.ok) {
        const responseData = response.status >= 400 ? await response.json() : null;

        if (responseData) {
            if (typeof responseData === 'string') throw new Error(responseData);
            if (typeof responseData === 'object')
                throw new Error(Object.values(responseData.errors).reduce((p, c) => `${p};${c}`));
        } else throw new Error('Could not edit company');
    }

    return response.ok;
};

export const requestEditUser = async (userId, firstName, lastName, email, phoneNumber, primaryCompanyId, companies) => {
    const url = `api/Security/User/${userId}`;
    const phone = phoneNumber && phoneNumber.length > 0 ? phoneNumber : null;

    const response = await fetchWithAuthHeader(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            firstName,
            lastName,
            email,
            phoneNumber: phone,
            primaryCompanyId,
            companies,
        }),
    });

    if (!response.ok) throw new Error('Could not edit user');

    return await response.json();
};

export const requestDisableUser = async userId => {
    const url = `api/Security/User/${userId}/deactivate`;

    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) throw new Error('Could not disable user');

    return;
};

export const requestEnableUser = async userId => {
    const url = `api/Security/User/${userId}/reactivate?api-version=1.0`;

    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) throw new Error('Could not enable user');

    return;
};

export const requestSendForgotPasswordEmail = async email => {
    const url = 'api/Security/UserAccount/reset-password';

    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            email: email,
        }),
    });

    if (!response.ok) throw new Error('Could not send forgot password email');

    return;
};

export const requestSendBulkActivationEmail = async emails => {
    const url = 'api/Security/UserAccount/send-activation-email/bulk';
    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            emails: emails,
        }),
    });

    if (!response.ok) throw new Error('Failed to send Activation Email(s)');

    return;
};

export const requestCreateCompanyUser = async (
    companyId,
    firstName,
    lastName,
    email,
    phoneNumber,
    roles,
    companies
) => {
    const url = `api/Security/Company/${companyId}/users`;
    const phone = phoneNumber && phoneNumber.length > 0 ? phoneNumber : null;

    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            firstName,
            lastName,
            email,
            phoneNumber: phone,
            roles: [roles],
            companies,
        }),
    });

    const data = response.status === 400 ? await response.json() : null;

    if (response.status === 400 && typeof data === 'string') throw new Error(data);
    if (response.status === 400 && typeof data === 'object')
        throw new Error(Object.values(data.errors).reduce((p, c) => `${p};${c}`));
    if (!response.ok) throw new Error('Could not create company user');

    return response.ok;
};

export const requestRoles = async () => {
    const url = 'api/Security/Roles';
    const response = await fetchWithAuthHeader(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });

    return await response.json();
};

export const requestEditRole = async (userId, roles) => {
    const url = `api/Security/User/${userId}/roles`;
    const response = await fetchWithAuthHeader(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            roleNames: roles,
        }),
    });

    if (!response.ok) throw new Error('Could not edit role');

    return await response.json();
};

export const requestGetBillingTypes = async () => {
    const url = 'api/Security/Company/BillingTypes';
    const response = await fetchWithAuthHeader(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) throw new Error('Failed to get billing types');

    return await response.json();
};

export const requestGetFeatures = async () => {
    const url = 'api/Security/Features';
    const response = await fetchWithAuthHeader(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
    });

    if (!response.ok) throw new Error('Failed to get features');
    return await response.json();
};

export const requestCountries = async () => {
    const url = 'api/Security/Country';
    const response = await fetchWithAuthHeader(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
    });
    if (!response.ok) throw new Error('Failed to get countries');
    return await response.json();
};

export const requestOrganizationSegments = async () => {
    const url = 'api/Security/Segment';
    const response = await fetchWithAuthHeader(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
    });

    if (!response.ok) throw new Error('Could not get organization segments');

    return await response.json();
};

export const mergeOrganizations = async (originalOrgId, proposedOrgId) => {
    const url = `api/Security/merge/${originalOrgId}/${proposedOrgId}`;
    const response = await fetchWithAuthHeader(url, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
        },
    });
    if (!response.ok) throw new Error('Could not merge Organizations!');
};

export const requestMoveuser = async (userId, orgId, companyId, companies) => {
    const url = `api/Security/User/Move`;
    const response = await fetchWithAuthHeader(url, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            userId,
            orgId,
            companyId,
            companies,
        }),
    });
    if (!response.ok) throw new Error('Could not move user');
    return response;
};

export const requestMoveLocation = async (orgId, companyId) => {
    const url = `api/Security/Company/Move`;
    const response = await fetchWithAuthHeader(url, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            orgId,
            companyId,
        }),
    });
    if (!response.ok) throw new Error('Location movement unsuccessful.');
    return response;
};
