import React, { FC, useCallback } from 'react';
import { CellProps } from 'components/Shared/Table/types';
import './Publish.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BookPublishState } from 'types/RefreshPublisherBookStatisticsType';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { RefreshPublisherTableActionTypes } from '../types';

export interface PublishCell {
    actionId: RefreshPublisherTableActionTypes;
}

const getStyles = (bookPublishState: BookPublishState): { publishEnabled: boolean; class: string; icon?: IconProp } => {
    switch (bookPublishState) {
        case BookPublishState.ReadyToPublish:
            return { publishEnabled: true, class: 'btn-success', icon: 'paper-plane' };
        case BookPublishState.UnableToPublish:
            return {
                publishEnabled: false,
                class: 'btn-danger',
                icon: 'xmark',
            };
        case BookPublishState.NothingToPublish:
            return {
                publishEnabled: false,
                class: 'btn-secondary',
                icon: 'check',
            };
        case BookPublishState.Publising:
            return {
                publishEnabled: false,
                class: 'btn-primary',
                icon: 'wrench',
            };
        case BookPublishState.FailedToPublish:
            return {
                publishEnabled: false,
                class: 'btn-warning',
                icon: 'xmark',
            };
        default:
            return {
                publishEnabled: false,
                class: 'btn-light border border-secondary',
            };
    }
};

const usePublishCell = ({ actionId }: PublishCell): FC<CellProps> => {
    const Cell = useCallback(
        ({ idKey, header, item, index, cellEventCallback }: CellProps) => {
            const styles = getStyles(item.bookPublishState as BookPublishState);
            const isPublishing = item.bookPublishState === BookPublishState.Publising;
            const id = `publish-book-${item.bookId}-button`;

            return (
                <td headers={header.id} id={`${header.id}${index}`} title={header.title}>
                    <div className="btn-group" role="group" aria-label="Basic example">
                        <div className={`status-check btn ${styles.class}`}>
                            {!!styles.icon && <FontAwesomeIcon icon={styles.icon} />}
                        </div>
                        <button
                            id={id}
                            data-testid={id}
                            type="button"
                            className="btn btn-primary publish-button"
                            disabled={!styles.publishEnabled}
                            onClick={() =>
                                cellEventCallback({
                                    id: item[idKey] as string,
                                    actionId: actionId,
                                    index,
                                    item,
                                })
                            }>
                            Publish
                        </button>
                    </div>
                    {isPublishing && (
                        <div className="publishing-overlay d-flex justify-content-center align-items-center">
                            <div className="spinner-border publishing-spinner" role="status" />
                        </div>
                    )}
                </td>
            );
        },
        [actionId]
    );
    return Cell;
};

export default usePublishCell;
