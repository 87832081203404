import { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LoadingContext } from 'components/Layout';
import { ToastContext } from 'components/ToastProvider';
import {
    requestCompany,
    requestCompanyUsers,
    requestOrganization,
    requestDisableUser,
    requestEnableUser,
    requestSendForgotPasswordEmail,
    deletePendingUser,
    requestSendBulkActivationEmail,
} from 'api/SecurityApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEdit,
    faStopCircle,
    faPlayCircle,
    faEnvelope,
    faTrash,
    faArrowsTurnRight,
    faUnlockKeyhole,
} from '@fortawesome/free-solid-svg-icons';
import { AccessControlContext } from 'components/Shared/AccessControl/AccessControl';
import { roles } from 'components/Shared/AccessControl/privilegesMap';
import { NotificationsContext } from 'components/Shared/Notifications/Notifications';
import withButton from '../../../Shared/Utils/withButton';
import { messages } from 'components/Shared/Utils/Constants';
import FloatingMenu from 'components/Shared/FloatingMenu/FloatingMenu';

const tableColumnDetailsConfig = [
    { title: 'Id', columnProperty: 'userId', sortable: true },
    { title: 'First Name', columnProperty: 'firstName', sortable: true },
    { title: 'Last Name', columnProperty: 'lastName', sortable: true },
    { title: 'Username', columnProperty: 'userName', sortable: true },
    { title: 'Phone', columnProperty: 'phoneNumber', sortable: true },
    { title: 'Locations', columnProperty: 'locations', textProperty: 'companyName', sortable: true },
    { title: 'Roles', columnProperty: 'role', sortable: true },
    {
        title: 'Status',
        columnProperty: 'status',
        textProperty: 'statusText',
        filterable: true,
        sortable: true,
    },
];

const useCompanyUsers = () => {
    // higher order states
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const { organizationId, locationId } = params;
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const { notifications } = useContext(NotificationsContext);
    const { showToast } = useContext(ToastContext);
    const { hasRole } = useContext(AccessControlContext);
    // local states
    const userAccess = useMemo(() => {
        return {
            hasCustomerSupportRole: hasRole(roles.customerSupport),
            hasAdminRole: hasRole(roles.admin),
            hasCustomerSuccesstRole: hasRole(roles.customerSuccess),
            hasSiteAdminRole: hasRole(roles.siteAdmin),
        };
    }, [hasRole]);
    const [companyUsers, setCompanyUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [displayUsers, setDisplayUsers] = useState([]);
    const [editUser, setEditUser] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [isOpenUserDisable, setShowUserDisableModal] = useState(false);
    const [isOpenDeleteUser, setIsOpenDeleteUser] = useState(false);
    const [isOpenSendForgotPasswordEmail, setShowUserForgotPasswordEmailModal] = useState(false);
    const [org, setOrg] = useState(null);
    const [company, setCompany] = useState(null);
    const [sortedPropertyKey, setSortedPropertyKey] = useState(null);
    const [sortedPropertyDirection, setSortedPropertyDirection] = useState(null);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [tableColumnDetails, setTableColumnDetails] = useState(tableColumnDetailsConfig);

    useEffect(() => {
        setTableColumnDetails(configs => {
            configs.forEach(config => {
                if (config.filterable) {
                    if (!config.filterConfig) {
                        config.filterConfig = {};
                    }
                    config.filterConfig.filterOptions = Array.from(
                        new Set(companyUsers.map(user => user[config.textProperty] ?? user[config.columnProperty]))
                    ).map(x => ({
                        label: x,
                        value: x,
                    }));
                }
            });
            return configs;
        });
    }, [companyUsers]);

    const [selectedFilters, setSelectedFilters] = useState([]);

    const isOrgActive = org?.isActive;
    const isLocationActive = !company?.isDeactivated;
    const [showMoveUserModal, setShowMoveUserModal] = useState(false);
    const [editedMoveUser, setEditedMoveUser] = useState(null);
    const hasMoveUserAccess = useMemo(() => {
        return {
            hasAdminRole: hasRole(roles.admin),
            hasCustomerSuccesstRole: hasRole(roles.customerSuccess),
            hasSiteAdminRole: hasRole(roles.siteAdmin),
        };
    }, [hasRole]);

    //Updating navbar breadcrumb
    useEffect(() => {
        if (!org || !company || (location?.state?.org && location?.state?.company)) return;
        navigate(null, { replace: true, state: { org, company } });
    }, [navigate, location, org, company]);

    useEffect(() => {
        const getCompUsers = async () => {
            try {
                incrementLoading();
                const users = await requestCompanyUsers(locationId);
                setCompanyUsers(users);
                setDisplayUsers(users);
            } catch (error) {
                showToast(error);
            } finally {
                decrementLoading();
            }
        };
        const getOrg = async () => {
            if (location && location.state && location.state.org) {
                setOrg(location.state.org);
            } else {
                try {
                    incrementLoading();
                    const orgJson = await requestOrganization(organizationId);
                    setOrg(orgJson);
                } catch (error) {
                    showToast(error);
                } finally {
                    decrementLoading();
                }
            }
        };
        const getCompany = async () => {
            if (location && location.state && location.state.company) {
                setCompany(location.state.company);
            } else {
                try {
                    incrementLoading();
                    const companyJson = await requestCompany(locationId);
                    setCompany(companyJson);
                } catch (error) {
                    showToast(error);
                } finally {
                    decrementLoading();
                }
            }
        };

        getOrg();
        getCompany();
        getCompUsers();
    }, [locationId, showToast, incrementLoading, decrementLoading, location, organizationId]);

    const handleDeletePendingUser = useCallback(async () => {
        try {
            incrementLoading();
            const response = await deletePendingUser(editUser.userId);
            if (response.status === 204) {
                notifications.pushSuccess(
                    `User ${editUser.firstName} ${editUser.lastName} has been successfully deleted.`
                );
                const users = await requestCompanyUsers(locationId);
                setCompanyUsers(users);
                const updatedSelectedUsers = selectedUsers.filter(user => {
                    return users.find(companyUser => companyUser.userId === user.id);
                });
                setSelectedUsers(updatedSelectedUsers);
                setEditUser(null);
                setIsOpenDeleteUser(false);
            }
        } catch (error) {
            showToast(error);
        } finally {
            decrementLoading();
        }
    }, [incrementLoading, editUser, notifications, locationId, selectedUsers, showToast, decrementLoading]);

    const handleShowDeletePendingUserModal = useCallback((e, user) => {
        setEditUser(user);
        setIsOpenDeleteUser(true);
        e.stopPropagation();
    }, []);

    //Search Users
    useEffect(() => {
        let newSortedAndSearchedCompanyUsers = companyUsers.slice();

        //Add data at base level of object to access in generic table component
        newSortedAndSearchedCompanyUsers.forEach(u => {
            u.actions = [];
            const deactivatedActionsTitle = !isOrgActive
                ? messages.organizationDisabledActionNotAllowed
                : !isLocationActive
                ? messages.companyDisabledActionNotAllowed
                : '';
            //If customer support role, don't show action buttons for functionality they should not have
            if (userAccess.hasCustomerSupportRole) {
                const EmailButtonAlt = withButton(() => (
                    <FontAwesomeIcon
                        id={`user-${u.userId}-resend`}
                        className="text-primary clickable m-1 customer-action-button"
                        icon={faEnvelope}
                        onClick={e => handleForgotEmailClick(e, u)}
                    />
                ));
                u.actions = [<EmailButtonAlt key={`email-${u.userId}`} isActive={isOrgActive && isLocationActive} />];
            } else {
                const EditButton = withButton(() => (
                    <FontAwesomeIcon
                        id={`user-${u.userId}-edit`}
                        className="text-primary clickable customer-action-button"
                        icon={faEdit}
                    />
                ));
                const ActOrDeactButton = withButton(() =>
                    u.isDeactivated ? (
                        <FontAwesomeIcon
                            id={`user-${u.userId}-activate`}
                            className="text-primary clickable customer-action-button"
                            icon={faPlayCircle}
                        />
                    ) : (
                        <FontAwesomeIcon
                            id={`user-${u.userId}-deactivate`}
                            className="text-primary clickable customer-action-button"
                            icon={faStopCircle}
                        />
                    )
                );
                const EmailButton = withButton(() => (
                    <FontAwesomeIcon
                        id={`user-${u.userId}-resend`}
                        className="text-primary clickable customer-action-button"
                        icon={faUnlockKeyhole}
                    />
                ));
                const DeleteButton = withButton(() => (
                    <FontAwesomeIcon
                        id={`user-${u.userId}-delete`}
                        className="text-primary clickable customer-action-button"
                        icon={faTrash}
                    />
                ));
                const MoveUserButton = withButton(() => (
                    <FontAwesomeIcon
                        id={`user-${u.userId}-move`}
                        className={`text-primary clickable customer-action-button`}
                        icon={faArrowsTurnRight}
                    />
                ));
                u.actions = [
                    <EditButton
                        key={`edit-${u.userId}`}
                        isActive={isOrgActive && isLocationActive}
                        label="Edit Information"
                        title={deactivatedActionsTitle}
                        onClick={e => handleEditClick(e, u)}
                    />,
                    <ActOrDeactButton
                        key={`activate-${u.userId}`}
                        isActive={isOrgActive && isLocationActive}
                        label={u.isDeactivated ? 'Activate' : 'Deactivate'}
                        title={deactivatedActionsTitle}
                        onClick={u.isDeactivated ? e => handleEnableClick(e, u) : e => handleDisableClick(e, u)}
                    />,
                    <EmailButton
                        key={`email-${u.userId}`}
                        isActive={isOrgActive && isLocationActive}
                        label="Send Password Reset"
                        title={deactivatedActionsTitle}
                        onClick={e => handleForgotEmailClick(e, u)}
                    />,
                ];

                if (userAccess.hasAdminRole || userAccess.hasCustomerSuccesstRole || userAccess.hasSiteAdminRole) {
                    u.actions.push(
                        <DeleteButton
                            isActive={isOrgActive && isLocationActive && !u.emailConfirmed && !u.isDeactivated}
                            title={
                                u.emailConfirmed || u.isDeactivated
                                    ? 'Only User with Pending status can be deleted'
                                    : ''
                            }
                            label="Delete"
                            onClick={e => handleShowDeletePendingUserModal(e, u)}
                        />
                    );
                    u.actions.push(
                        <MoveUserButton
                            isActive={isOrgActive && isLocationActive}
                            title={deactivatedActionsTitle}
                            label="Move User"
                            onClick={() => handleShowMoveUserModal(u)}
                        />
                    );
                }
            }

            const secondaryLocations = u.companies?.filter(p => p.companyId != u.company?.companyId);
            u.companyName = u.company ? u.company.companyName : '';
            u.locations = secondaryLocations?.length ? (
                <div className="locations-cell">
                    <span role="heading" data-testid="primary-location">
                        {u.company?.companyName}
                    </span>
                    <FloatingMenu
                        buttonId="secondary-locations-button"
                        labelId="locations-counter"
                        label={`(+${secondaryLocations.length})`}
                        items={secondaryLocations.map(comp => (
                            <li key={comp.companyId}>{comp.companyName}</li>
                        ))}
                    />
                </div>
            ) : (
                <span>{u.company?.companyName}</span>
            );

            u.role = u.roles && u.roles.join(', ');
            u.status =
                !u.isDeactivated && u.emailConfirmed ? (
                    <span className="text-success">Active</span>
                ) : u.isDeactivated ? (
                    <span className="text-danger">Deactivated</span>
                ) : (
                    <span className="text-warning">Pending</span>
                );
            u.statusText =
                !u.isDeactivated && u.emailConfirmed ? 'Active' : u.isDeactivated ? 'Deactivated' : 'Pending';
        });

        //If there is a column requested to be sorted by, sort by that property and the sort direction
        if (sortedPropertyKey) {
            newSortedAndSearchedCompanyUsers.sort((a, b) => {
                const aValue =
                    sortedPropertyKey === 'status'
                        ? a['statusText']
                        : sortedPropertyKey === 'locations'
                        ? a['companyName']
                        : sortedPropertyKey === 'userId'
                        ? a[sortedPropertyKey]
                        : sortedPropertyKey === 'phoneNumber'
                        ? a[sortedPropertyKey]
                            ? a[sortedPropertyKey].replace(/[^\d]/g, '')
                            : 0
                        : a[sortedPropertyKey]?.toLowerCase();

                const bValue =
                    sortedPropertyKey === 'status'
                        ? b['statusText']
                        : sortedPropertyKey === 'locations'
                        ? b['companyName']
                        : sortedPropertyKey === 'userId'
                        ? b[sortedPropertyKey]
                        : sortedPropertyKey === 'phoneNumber'
                        ? b[sortedPropertyKey]
                            ? b[sortedPropertyKey].replace(/[^\d]/g, '')
                            : 0
                        : b[sortedPropertyKey]?.toLowerCase();
                if (sortedPropertyDirection === 'A') {
                    return aValue < bValue ? -1 : 1;
                } else {
                    return aValue < bValue ? 1 : -1;
                }
            });
        }

        if (searchTerm) {
            newSortedAndSearchedCompanyUsers = newSortedAndSearchedCompanyUsers.filter(
                u =>
                    u.userId.toString().indexOf(searchTerm) > -1 ||
                    (u.firstName && u.firstName.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) ||
                    (u.lastName && u.lastName.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) ||
                    (u.email && u.email.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) ||
                    (u.phoneNumber && u.phoneNumber.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1)
            );
        }

        if (selectedFilters.length) {
            for (let filter of selectedFilters) {
                newSortedAndSearchedCompanyUsers =
                    filter.optionsSelected &&
                    newSortedAndSearchedCompanyUsers.filter(u =>
                        filter.optionsSelected.some(x => x.toLowerCase() == u[filter.columnName].toLowerCase())
                    );
            }
        }

        setDisplayUsers(newSortedAndSearchedCompanyUsers);
    }, [
        companyUsers,
        searchTerm,
        sortedPropertyKey,
        sortedPropertyDirection,
        userAccess,
        handleShowDeletePendingUserModal,
        isOrgActive,
        handleShowMoveUserModal,
        selectedFilters,
        isLocationActive,
    ]);

    const onSaveOrEdit = async () => {
        try {
            incrementLoading();
            const users = await requestCompanyUsers(locationId);
            setCompanyUsers(users);
        } catch (error) {
            showToast(error);
        } finally {
            decrementLoading();
        }
    };

    const handleToggle = () => {
        if (showModal) setEditUser(null);
        setShowModal(!showModal);
    };

    const handleEditClick = (e, user) => {
        setEditUser(user);
        setShowModal(true);
        e.stopPropagation();
    };

    const handleDisableEnable = async () => {
        try {
            incrementLoading();
            if (editUser.isDeactivated) await requestEnableUser(editUser.userId);
            else await requestDisableUser(editUser.userId);
            const users = await requestCompanyUsers(locationId);
            setCompanyUsers(users);
            const updatedSelectedUsers = selectedUsers.filter(user => {
                let updatedUser = users.find(companyUser => companyUser.userId === user.id);
                return !updatedUser.isDeactivated;
            });
            setSelectedUsers(updatedSelectedUsers);
            setShowUserDisableModal(false);
        } catch (error) {
            showToast(error);
        } finally {
            decrementLoading();
        }
    };

    const handleDisableClick = async (e, user) => {
        setEditUser(user);
        setShowUserDisableModal(true);
        e.stopPropagation();
    };

    const handleEnableClick = async (e, user) => {
        setEditUser(user);
        setShowUserDisableModal(true);
        e.stopPropagation();
    };

    const handleForgotEmailClick = async (e, user) => {
        setEditUser(user);
        setShowUserForgotPasswordEmailModal(true);
        e.stopPropagation();
    };

    useEffect(() => {
        if (!isOpenUserDisable && !isOpenSendForgotPasswordEmail && !showModal) {
            setEditUser(null);
        }
    }, [isOpenUserDisable, isOpenSendForgotPasswordEmail, showModal]);

    const handleSendForgotPassword = async () => {
        try {
            incrementLoading();
            await requestSendForgotPasswordEmail(editUser.email);
            decrementLoading();
            setShowUserForgotPasswordEmailModal(false);
            setEditUser(null);
        } catch (error) {
            showToast(error);
        } finally {
            decrementLoading();
        }
    };

    const handleBulkSendActivationEmail = useCallback(async () => {
        try {
            const emails = selectedUsers.map(s => s.item.email);
            await requestSendBulkActivationEmail(emails);
            setSelectedUsers([]);
            notifications.pushSuccess('Activation Email(s) successfully sent to the selected users');
        } catch (error) {
            notifications.pushExceptionDanger(new Error(error));
        }
    }, [notifications, selectedUsers]);

    const sortClickCallback = (retSortedProperty, retSortedPropertyDirection) => {
        setSortedPropertyKey(retSortedProperty);
        setSortedPropertyDirection(retSortedPropertyDirection);
    };

    const handleShowMoveUserModal = useCallback(user => {
        setShowMoveUserModal(prevShowMoveUserModal => !prevShowMoveUserModal);
        setEditedMoveUser(user);
    }, []);

    const filterChangeCallback = filters => {
        setSelectedFilters(prevFilters => {
            let updatedFilters = prevFilters.filter(filter => filter.columnName != filters.columnName);
            if (filters.optionsSelected.length) {
                updatedFilters = [...updatedFilters, filters];
            }
            return updatedFilters;
        });
    };

    return {
        editUser,
        locationId,
        organizationId,
        showModal,
        isOpenUserDisable,
        isOpenSendForgotPasswordEmail,
        org,
        company,
        searchTerm,
        displayUsers,
        tableColumnDetails,
        userAccess,
        handleToggle,
        onSaveOrEdit,
        setShowUserDisableModal,
        handleDisableEnable,
        setShowUserForgotPasswordEmailModal,
        setSearchTerm,
        handleSendForgotPassword,
        handleBulkSendActivationEmail,
        sortClickCallback,
        isOpenDeleteUser,
        setIsOpenDeleteUser,
        handleDeletePendingUser,
        selectedUsers,
        setSelectedUsers,
        isOrgActive,
        isLocationActive,
        showMoveUserModal,
        handleShowMoveUserModal,
        editedMoveUser,
        hasMoveUserAccess,
        filterChangeCallback,
        selectedFilters,
    };
};

export default useCompanyUsers;
