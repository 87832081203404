import React, { useContext, useEffect, useMemo, useState } from 'react';
import { buildSignalRConnection } from 'hooks/signalR/buildSignalRConnection';
import { LoadingContext } from 'components/Layout';

const contexts = new Map();
const getSignalRHubContext = hub => {
    if (contexts.has(hub)) {
        return contexts.get(hub);
    }

    const context = React.createContext();
    contexts.set(hub, context);
    return context;
};

export const useSignalRHub = hub => {
    return useContext(getSignalRHubContext(hub));
};

const SignalRHub = ({ hub, callbacks, children }) => {
    const SignalRHubContext = getSignalRHubContext(hub);
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const [isConnected, setIsConnected] = useState(false);
    const { connection, start, stop } = useMemo(() => buildSignalRConnection(hub), [hub]);

    if (callbacks) {
        for (const [key, value] of Object.entries(callbacks)) {
            connection.on(key, value);
        }
    }

    useEffect(() => {
        const core = async () => {
            try {
                incrementLoading();
                await start();
                setIsConnected(true);
            } finally {
                decrementLoading();
            }
        };
        core();

        return () => {
            setIsConnected(false);
            stop();
        };
    }, [decrementLoading, incrementLoading, start, stop]);

    return isConnected && <SignalRHubContext.Provider value={connection}>{children}</SignalRHubContext.Provider>;
};

export const withSignalRHub = (WrappedComponent, hub, callbacks) => {
    return props => {
        return (
            <SignalRHub hub={hub} callbacks={callbacks}>
                <WrappedComponent {...props} />
            </SignalRHub>
        );
    };
};

export default SignalRHub;
