import { fetchWithAuthHeader } from 'api/AuthUtils';
import { useEffect, useState } from 'react';

export function FeatureFlagsManager() {
    const [flags, setFlags] = useState();

    useEffect(() => {
        const url = `api/RepairProcedure/Features/GetAllFeatureFlags?api-version=5.0`;
        const fetchFlags = async () => {
            const response = await fetchWithAuthHeader(url, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });
            if (!response.ok) throw new Error(`Failed to get feature flags`);

            const data = await response.json();
            setFlags(data);
        };

        fetchFlags();
    }, []);

    const handleChangeIsActiveSwitch = flag => {
        const url = `api/RepairProcedure/Features/SetFeatureFlag?api-version=5.0`;
        const setFeatureFlagState = async featureFlag => {
            await fetchWithAuthHeader(url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    featureFlagsId: featureFlag.featureFlagsId,
                    isActive: featureFlag.isActive,
                }),
            });
        };

        setFlags(currFlags => {
            const idx = currFlags.findIndex(f => f.featureFlagsId === flag.featureFlagsId);
            currFlags[idx].isActive = !currFlags[idx].isActive;
            setFeatureFlagState(currFlags[idx]);
            return [...currFlags];
        });
    };

    return (
        <div>
            <div className="mx-5">
                <h1>Feature Flags Manager</h1>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Flag Name</th>
                            <th>Product</th>
                            <th>Label</th>
                            <th>Is Active</th>
                        </tr>
                    </thead>
                    <tbody>
                        {flags &&
                            flags.map(flag => (
                                <tr key={flag.featureFlagsId} style={{ height: '50px' }}>
                                    <td>{flag.flagName}</td>
                                    <td>{flag.product}</td>
                                    <td>{flag.label}</td>
                                    <td>
                                        <div className="form-check form-switch">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                role="switch"
                                                id={`IsActiveSwitch-${flag.featureFlagsId}`}
                                                checked={flag.isActive}
                                                style={{ transform: 'scale(1.5)' }}
                                                onChange={() => handleChangeIsActiveSwitch(flag)}
                                            />
                                            <label
                                                className="form-check-label mx-2"
                                                htmlFor={`IsActiveSwitch-${flag.featureFlagsId}`}>
                                                {flag.isActive ? 'true' : 'false'}
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
