import { OemId } from 'helpers/OemId';

const RepairProceduresUrls = [
    {
        oemId: OemId.Ford,
        idKey: 'fordMappingRuleId',
        getMappingRules: 'api/RepairProcedure/odata/FordMappingRule',
        createMappingRule: 'api/RepairProcedure/odata/FordMappingRule/CreateFordMappingRule',
        setActiveMappingRule: ({ key }) => `api/RepairProcedure/odata/FordMappingRule(${key})/SetActive`,
        removeMappingRule: ({ key }) => `api/RepairProcedure/odata/FordMappingRule(${key})`,
    },
    {
        oemId: OemId.Chrysler,
        idKey: 'stellantisMappingRuleId',
        getMappingRules: 'api/RepairProcedure/odata/StellantisMappingRule',
        createMappingRule: 'api/RepairProcedure/odata/StellantisMappingRule/CreateStellantisMappingRule',
        setActiveMappingRule: ({ key }) => `api/RepairProcedure/odata/StellantisMappingRule(${key})/SetActive`,
        removeMappingRule: ({ key }) => `api/RepairProcedure/odata/StellantisMappingRule(${key})`,
    },
    {
        oemId: OemId.Toyota,
        idKey: 'toyotaMappingRuleId',
        getMappingRules: 'api/RepairProcedure/odata/ToyotaMappingRule',
        createMappingRule: 'api/RepairProcedure/odata/ToyotaMappingRule/CreateToyotaMappingRule',
        setActiveMappingRule: ({ key }) => `api/RepairProcedure/odata/ToyotaMappingRule(${key})/SetActive`,
        removeMappingRule: ({ key }) => `api/RepairProcedure/odata/ToyotaMappingRule(${key})`,
    },
    {
        oemId: OemId.Nissan,
        idKey: 'nissanMappingRuleId',
        getMappingRules: 'api/RepairProcedure/odata/NissanMappingRule',
        createMappingRule: 'api/RepairProcedure/odata/NissanMappingRule/CreateNissanMappingRule',
        setActiveMappingRule: ({ key }) => `api/RepairProcedure/odata/NissanMappingRule(${key})/SetActive`,
        removeMappingRule: ({ key }) => `api/RepairProcedure/odata/NissanMappingRule(${key})`,
    },
    {
        oemId: OemId.GMC,
        idKey: 'gmMappingRuleId',
        getMappingRules: 'api/RepairProcedure/odata/GMMappingRule',
        createMappingRule: 'api/RepairProcedure/odata/GMMappingRule/CreateGmMappingRule',
        setActiveMappingRule: ({ key }) => `api/RepairProcedure/odata/GMMappingRule(${key})/SetActive`,
        removeMappingRule: ({ key }) => `api/RepairProcedure/odata/GMMappingRule(${key})`,
    },
    {
        oemId: OemId.Honda,
        idKey: 'hondaMappingRuleId',
        getMappingRules: 'api/RepairProcedure/odata/HondaMappingRule',
        createMappingRule: 'api/RepairProcedure/odata/HondaMappingRule/CreateHondaMappingRule',
        setActiveMappingRule: ({ key }) => `api/RepairProcedure/odata/HondaMappingRule(${key})/SetActive`,
        removeMappingRule: ({ key }) => `api/RepairProcedure/odata/HondaMappingRule(${key})`,
    },
    {
        oemId: OemId.Volkswagen,
        idKey: 'vwMappingRuleId',
        getMappingRules: 'api/RepairProcedure/odata/VWMappingRule',
        createMappingRule: 'api/RepairProcedure/odata/VWMappingRule/CreateVWMappingRule',
        setActiveMappingRule: ({ key }) => `api/RepairProcedure/odata/VWMappingRule(${key})/SetActive`,
        removeMappingRule: ({ key }) => `api/RepairProcedure/odata/VWMappingRule(${key})`,
    },
    {
        oemId: OemId.Subaru,
        idKey: 'subaruMappingRuleId',
        getMappingRules: 'api/RepairProcedure/odata/SubaruMappingRule',
        createMappingRule: 'api/RepairProcedure/odata/SubaruMappingRule/CreateSubaruMappingRule',
        setActiveMappingRule: ({ key }) => `api/RepairProcedure/odata/SubaruMappingRule(${key})/SetActive`,
        removeMappingRule: ({ key }) => `api/RepairProcedure/odata/SubaruMappingRule(${key})`,
    },
    {
        oemId: OemId.Hyundai,
        idKey: 'hyundaiMappingRuleId',
        getMappingRules: 'api/RepairProcedure/odata/HyundaiMappingRule',
        createMappingRule: 'api/RepairProcedure/odata/HyundaiMappingRule/CreateHyundaiMappingRule',
        setActiveMappingRule: ({ key }) => `api/RepairProcedure/odata/HyundaiMappingRule(${key})/SetActive`,
        removeMappingRule: ({ key }) => `api/RepairProcedure/odata/HyundaiMappingRule(${key})`,
    },
    {
        oemId: OemId.OEMiQ,
        idKey: 'fordMappingRuleId',
        getMappingRules: 'api/RepairProcedure/odata/OemIqFordMappingRule',
        createMappingRule: 'api/RepairProcedure/odata/OemIqFordMappingRule/CreateOemIqFordMappingRule',
        setActiveMappingRule: ({ key }) => `api/RepairProcedure/odata/OemIqFordMappingRule(${key})/SetActive`,
        removeMappingRule: ({ key }) => `api/RepairProcedure/odata/OemIqFordMappingRule(${key})`,
    },
];

function getRepairProceduresUrls(oemId) {
    const oemUrls = RepairProceduresUrls.find(x => x.oemId == oemId);
    if (!oemUrls) throw `Not found URLs defined for oemId = ${oemId}`;
    return oemUrls;
}

export function getMappingRulesUrl(oemId) {
    const oemUrls = getRepairProceduresUrls(oemId);
    return oemUrls.getMappingRules;
}

export function getCreateMappingRuleUrl(oemId) {
    const oemUrls = getRepairProceduresUrls(oemId);
    return oemUrls.createMappingRule;
}

export function setActiveMappingRuleUrl(oemId) {
    const oemUrls = getRepairProceduresUrls(oemId);
    return oemUrls.setActiveMappingRule;
}

export function removeMappingRuleUrl(oemId) {
    const oemUrls = getRepairProceduresUrls(oemId);
    return oemUrls.removeMappingRule;
}

export function getMappingRuleIdKey(oemId) {
    const oemUrls = getRepairProceduresUrls(oemId);
    return oemUrls.idKey;
}
