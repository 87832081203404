import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LargeCheckbox } from 'oemiq-common';

const TagTableItem = ({ tag, handleTagRowClick, handleViewProcedureClick, onCheckboxClick, checked }) => {
    return (
        <tr
            id={`tag-table-row-${tag.stagedOneTimeUseTagId}`}
            className="clickable"
            onClick={() => handleTagRowClick(tag.procedureId)}>
            <td headers="checkbox" onClick={e => e.stopPropagation()}>
                <LargeCheckbox
                    id={`bulk-select-${tag.stagedOneTimeUseTagId}`}
                    label=""
                    className="large-checkbox-check mb-0"
                    checked={checked}
                    onChange={() => onCheckboxClick([tag])}
                    readOnly={false}
                />
            </td>
            <td headers="previewIcon">
                <button
                    id={`view-procedure-${tag.stagedOneTimeUseTagId}`}
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={e => handleViewProcedureClick(e, tag.procedureId)}>
                    <FontAwesomeIcon icon="eye" />
                </button>
            </td>
            <td headers="stagedOneTimeUseTagId">{tag.stagedOneTimeUseTagId}</td>
            <td headers="oneTimeUseTagId">{tag.oneTimeUseTagId}</td>
            <td headers="title">{tag.title}</td>
            <td headers="text">{tag.text}</td>
            <td headers="quantity">{tag.quantity}</td>
            <td headers="quantityCondition">{tag.quantityCondition}</td>
            <td headers="partNumber">{tag.partNumber}</td>
            <td headers="price">{tag.price}</td>
            <td headers="oneTimeUsePartType">
                {tag.oneTimeUsePartType ? tag.oneTimeUsePartType.oneTimeUsePartTypeName : ''}
            </td>
            <td headers="isActive">{tag.isActive === true ? 'Yes' : 'No'}</td>
            <td headers="procedureId">{tag.procedureId}</td>
            <td headers="procedureTitle">{tag.procedure.procedureTitle}</td>
            <td headers="groups">{tag.groups.join(', ')}</td>
            <td headers="type">{tag.type}</td>
        </tr>
    );
};

export default TagTableItem;
