import { OemId } from 'helpers/OemId';
import { useEffect, useState } from 'react';

interface BatchOptions {
    publishBatchSize: number;
    loadStatsBatchSize: number;
}

const useRefreshPublisherParallelOptions = (oemId: number) => {
    const [options, setOptions] = useState<BatchOptions>({ loadStatsBatchSize: 1, publishBatchSize: 1 });

    useEffect(() => {
        let options: BatchOptions;
        switch (oemId) {
            case OemId.Audi:
            case OemId.Volkswagen:
                options = { loadStatsBatchSize: 10, publishBatchSize: 1 };
                break;
            default:
                options = { loadStatsBatchSize: 5, publishBatchSize: 1 };
                break;
        }
        setOptions(options);
    }, [oemId]);

    return {
        options,
    };
};

export default useRefreshPublisherParallelOptions;
