import React from 'react';
import { PrimaryButton, DangerButton, LightButton, SuccessButton } from 'oemiq-common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateBack } from '@fortawesome/free-solid-svg-icons';

const Footer = ({
    onClose,
    onSave,
    onDelete,
    onRestore,
    onUndo,
    anythingToUndo,
    anythingToDispose,
    anythingToEdit,
    anythingToRestore,
}) => {
    return (
        <div style={{ width: '100%' }}>
            <div style={{ float: 'left' }}>
                {anythingToDispose && (
                    <DangerButton onClick={onDelete}>
                        Dispose <FontAwesomeIcon icon="trash" />
                    </DangerButton>
                )}
                {anythingToRestore && (
                    <SuccessButton onClick={onRestore} className="ms-2">
                        Restore <FontAwesomeIcon icon="trash-arrow-up" />
                    </SuccessButton>
                )}
                {anythingToUndo && (
                    <PrimaryButton onClick={onUndo} className="ms-2">
                        Undo dispose/restore <FontAwesomeIcon icon={faRotateBack} />
                    </PrimaryButton>
                )}
            </div>
            <div style={{ float: 'right' }}>
                <LightButton onClick={onClose}>
                    Cancel <FontAwesomeIcon icon="xmark" />
                </LightButton>
                {anythingToEdit && (
                    <PrimaryButton onClick={onSave} className="ms-2">
                        Save <FontAwesomeIcon icon="check" />
                    </PrimaryButton>
                )}
            </div>
        </div>
    );
};

export default Footer;
