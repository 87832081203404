import { useNavigate } from 'react-router-dom';

const LegacyRoutes = () => {
    const navigate = useNavigate();
    const newUrl = window.location.href
        .replace('/mapper-old/', '/mapping-process/mapper-old/')
        .replace('/tagger/', '/tagging-process/tagger/');

    return (
        <div className="page-wrap d-flex flex-row align-items-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12 text-center">
                        <span className="display-3 d-block">Legacy link</span>
                        <div className="mb-4 lead">Please update your link to currently supported version:</div>
                        <div className="h4 mb-5">
                            <a href={newUrl}>{newUrl}</a>
                        </div>
                        <button className="btn btn-primary" onClick={() => navigate('/')}>
                            Back to Home
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LegacyRoutes;
