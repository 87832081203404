import React from 'react';
import { PrimaryButton } from 'oemiq-common';

const TypeDropdownButton = ({ procedure, mappingDefinitions, setGroupTypeModalProcedureIds }) => {
    const procedureId = procedure.procedureId;
    const currentTypeId = procedure.stageArea.type.typeId;
    return (
        <div>
            {currentTypeId === null ? (
                <PrimaryButton
                    id={`select-${procedureId}-type`}
                    type="button"
                    onClick={() => setGroupTypeModalProcedureIds([procedureId])}>
                    Select
                </PrimaryButton>
            ) : (
                <div
                    id={`select-${procedureId}-type`}
                    className="clickable"
                    onClick={() => setGroupTypeModalProcedureIds([procedureId])}>
                    {mappingDefinitions.types.length > 0 &&
                        mappingDefinitions.types.find(type => type.oemIqSectionId === currentTypeId).oemIqSectionName}
                </div>
            )}
        </div>
    );
};

export default TypeDropdownButton;
