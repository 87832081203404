import React from 'react';
import { FileDropBox, ModelView, PrimaryButton } from 'oemiq-common';
import useTestThreeDModel from './useTestThreeDModel';

const colors = [
    { name: 'default', value: '#51504E' },
    { name: 'red', value: '#FF0000' },
    { name: 'green', value: '#00FF00' },
    { name: 'blue', value: '#0000FF' },
];

const TestThreeDModel = () => {
    const {
        modelData,
        vehicleTypes,
        handleSetModelData,
        SetSelectedVehicleIndex,
        hiddenRegions,
        toggleAllRegions,
        selectedVehicleIndex,
        generateMaterialsToggleTiles,
        generateRegionToggleTiles,
        regionsShouldBeOnTheModel,
        regionsNotExpectedOnModel,
        regionsShouldNotBeOnModel,
        allRegionNames,
        toggleHiddenRegion,
        setRegionsOnModel,
        materials,
        setMaterials,
        selectedExteriorColor,
        setSelectedExteriorColor,
    } = useTestThreeDModel();

    if (modelData && vehicleTypes.length > 0) {
        return (
            <div className="d-flex">
                <div className="d-flex flex-column">
                    <div className="d-flex m-2">
                        <PrimaryButton type="button" onClick={() => handleSetModelData(null)}>
                            Change model
                        </PrimaryButton>
                    </div>
                    <div className="m-2">
                        <select
                            className="form-select"
                            onChange={e => SetSelectedVehicleIndex(parseInt(e.target.value))}>
                            {vehicleTypes.map((vehicleType, index) => (
                                <option key={vehicleType.vehicleTypeId} value={index}>
                                    {vehicleType.vehicleTypeName}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="m-2 p-2 shadow-sm bg-white d-flex justify-content-between align-items-center">
                        <div className="me-2">All regions</div>
                        <div className="switch switch-sm">
                            <input
                                type="checkbox"
                                className="switch"
                                id={'toggle-all'}
                                checked={hiddenRegions.length === 0}
                                onChange={toggleAllRegions}
                            />
                            <label className="text-darkblue mt-2 ms-2" htmlFor={'toggle-all'}></label>
                        </div>
                    </div>
                    {materials && (
                        <div>
                            <h2 className="text-primary ms-2">
                                {vehicleTypes[selectedVehicleIndex].vehicleTypeName} materials expected on model
                            </h2>

                            <select className="form-select" onChange={e => setSelectedExteriorColor(e.target.value)}>
                                {colors.map(color => (
                                    <option key={color.name} value={color.value}>
                                        {color.name}
                                    </option>
                                ))}
                            </select>

                            {generateMaterialsToggleTiles(materials, 'text-success', 'text-danger')}
                        </div>
                    )}
                    <h2 className="text-primary ms-2">
                        {vehicleTypes[selectedVehicleIndex].vehicleTypeName} regions expected on model
                    </h2>
                    {generateRegionToggleTiles(regionsShouldBeOnTheModel, 'text-danger')}
                    <h2 className="text-primary ms-2">
                        {vehicleTypes[selectedVehicleIndex].vehicleTypeName} regions not expected on model
                    </h2>
                    {generateRegionToggleTiles(regionsNotExpectedOnModel, 'text-success')}
                    {regionsShouldNotBeOnModel && regionsShouldNotBeOnModel.length > 0 && (
                        <h2 className="text-danger ms-2">
                            Not {vehicleTypes[selectedVehicleIndex].vehicleTypeName} regions on model
                        </h2>
                    )}
                    {generateRegionToggleTiles(regionsShouldNotBeOnModel, 'text-danger')}
                </div>
                <div className="flex-grow-1 align-self-start sticky-below-header">
                    <ModelView
                        pause3D={false}
                        regionNames={allRegionNames}
                        hiddenRegions={hiddenRegions}
                        regionClick={toggleHiddenRegion}
                        modelData={modelData}
                        exteriorColor={selectedExteriorColor}
                        materialsCheckedCallback={setMaterials}
                        includedRegionsCallback={setRegionsOnModel}
                    />
                </div>
            </div>
        );
    } else {
        return (
            <div className="d-flex justify-content-center">
                <FileDropBox className="m-4 w-50" fileDataCallback={handleSetModelData}>
                    <h2 className="text-primary">Click to browse for or drop a GLB file</h2>
                    <h2 className="text-primary">Drop GLB file</h2>
                </FileDropBox>
            </div>
        );
    }
};

export default TestThreeDModel;
