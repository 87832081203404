import React from 'react';
import useLinksTable from './useLinksTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LinksTable = ({ tags, handleViewProcedureClick, handleRowClick }) => {
    const { handleLinksRowClick } = useLinksTable(handleRowClick);

    return (
        <table className="table table-striped table-hover table-responsive">
            <thead>
                <tr>
                    <th>Staged Tag ID</th>
                    <th>Tag ID</th>
                    <th>Tag Type</th>
                    <th>Tag Name</th>
                    <th>Tag Text</th>
                    <th>Images</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {tags.map(t => (
                    <tr
                        key={t.stagedOneTimeUseTagId}
                        className="clickable"
                        onClick={() => handleLinksRowClick(t.stagedOneTimeUseTagId)}>
                        <td>{t.stagedOneTimeUseTagId}</td>
                        <td>{t.oneTimeUseTagId}</td>
                        <td>{t.oneTimeUsePartType ? t.oneTimeUsePartType.oneTimeUsePartTypeName : ''}</td>
                        <td>{t.title}</td>
                        <td>{t.text}</td>
                        <td>{t.oneTimeUseTagImages.length}</td>
                        <td>
                            <button
                                id={`view-procedure-${t.procedureId}`}
                                type="button"
                                className="btn btn-primary btn-sm"
                                onClick={e => handleViewProcedureClick(e, t.procedureId)}>
                                <FontAwesomeIcon icon="eye" />
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default LinksTable;
