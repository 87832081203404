import React from 'react';
import FlagTableItem from './FlagTableItem/FlagTableItem';
import { LargeCheckbox } from 'oemiq-common';
import SortableTableHeader from '../SortableTableHeader/SortableTableHeader';
import useFlagSorting from './useFlagSorting';
import useDisplayRowsLimited from '../useDisplayRowsLimited';
import { useNavigate } from 'react-router-dom';

const FlagTable = ({
    flags,
    flagsProcedureIds,
    setDisplayProcedureIds,
    dispositions,
    searchParamsObject,
    handleViewProcedureClick,
}) => {
    const navigate = useNavigate();

    const handleFlagRowClick = procedureId => {
        setDisplayProcedureIds(flagsProcedureIds);
        navigate(`${location.pathname}/tag/${procedureId}`, { state: searchParamsObject });
    };

    const {
        sortedFlags,
        sortFlagColumn: sortColumn,
        handleFlagSortColumnClick: handleSortColumnClick,
    } = useFlagSorting(flags);

    const { flagsLimited } = useDisplayRowsLimited(sortedFlags);

    return (
        <table className="table table-striped table-hover table-responsive">
            <thead>
                <tr>
                    <th>
                        <LargeCheckbox
                            id={'bulk-select-all'}
                            label=""
                            className="large-checkbox-check mb-0"
                            checked={false}
                            onChange={() => console.log('When this checkbox is needed, need to implemented the logic')}
                            readOnly={false}
                        />
                    </th>
                    <th>Preview Icon</th>
                    <SortableTableHeader
                        title={'Flag ID'}
                        propertyKey={'oneTimeUseFlagId'}
                        sortColumn={sortColumn}
                        handleSortColumnClick={handleSortColumnClick}
                    />
                    <SortableTableHeader
                        title={'Flag Name'}
                        propertyKey={'title'}
                        sortColumn={sortColumn}
                        handleSortColumnClick={handleSortColumnClick}
                    />
                    <SortableTableHeader
                        title={'Flagged Text'}
                        propertyKey={'text'}
                        sortColumn={sortColumn}
                        handleSortColumnClick={handleSortColumnClick}
                    />
                    <SortableTableHeader
                        title={'Quantity'}
                        propertyKey={'quantity'}
                        sortColumn={sortColumn}
                        handleSortColumnClick={handleSortColumnClick}
                    />
                    <SortableTableHeader
                        title={'Quantity Condition'}
                        propertyKey={'quantityCondition'}
                        sortColumn={sortColumn}
                        handleSortColumnClick={handleSortColumnClick}
                    />
                    <SortableTableHeader
                        title={'Part Number'}
                        propertyKey={'partNumber'}
                        sortColumn={sortColumn}
                        handleSortColumnClick={handleSortColumnClick}
                    />
                    <SortableTableHeader
                        title={'MSRP'}
                        propertyKey={'price'}
                        sortColumn={sortColumn}
                        handleSortColumnClick={handleSortColumnClick}
                    />
                    <SortableTableHeader
                        title={'1xID Type'}
                        propertyKey={'partTypeId'}
                        sortColumn={sortColumn}
                        handleSortColumnClick={handleSortColumnClick}
                    />
                    <SortableTableHeader
                        title={'Disposition'}
                        propertyKey={'tagDispositionId'}
                        sortColumn={sortColumn}
                        handleSortColumnClick={handleSortColumnClick}
                    />
                    <SortableTableHeader
                        title={'Procedure ID'}
                        propertyKey={'procedureId'}
                        sortColumn={sortColumn}
                        handleSortColumnClick={handleSortColumnClick}
                    />
                    <SortableTableHeader
                        title={'Procedure Title'}
                        propertyKey={'procedureTitle'}
                        sortColumn={sortColumn}
                        handleSortColumnClick={handleSortColumnClick}
                    />
                    <SortableTableHeader
                        title={'Groups'}
                        propertyKey={'groups'}
                        sortColumn={sortColumn}
                        handleSortColumnClick={handleSortColumnClick}
                    />
                    <SortableTableHeader
                        title={'Type'}
                        propertyKey={'type'}
                        sortColumn={sortColumn}
                        handleSortColumnClick={handleSortColumnClick}
                    />
                </tr>
            </thead>
            <tbody>
                {flagsLimited.map(flag => {
                    return (
                        <FlagTableItem
                            key={flag.oneTimeUseFlagId}
                            flag={flag}
                            dispositions={dispositions}
                            handleFlagRowClick={handleFlagRowClick}
                            handleViewProcedureClick={handleViewProcedureClick}
                        />
                    );
                })}
            </tbody>
        </table>
    );
};

export default FlagTable;
