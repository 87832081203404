import React, { useState } from 'react';
import Icon from '../Elements/Icon';

const LinkTagsCheckboxItem = ({ text, value, tag, modelId, viewModalOnClick, parentTag }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div className="align-items-center d-flex form-check m-0">
            <div className="border m-0 p-2 w-100 clickable" onClick={() => setIsOpen(!isOpen)}>
                <span className="align-middle">
                    <b>{text}:</b> {value}
                    <br />
                    <div>{tag.procedure ? tag.procedure.procedureTitle : ''}</div>
                </span>
                <span className="float-right">
                    <button
                        type="button"
                        className="btn btn-primary btn-sm me-1"
                        data-toggle="modal"
                        data-target={`#${modelId}`}
                        onClick={viewModalOnClick}>
                        <Icon icon="eye" />
                    </button>
                </span>
                {isOpen && (
                    <div>
                        <hr />
                        <div>
                            {parentTag && (
                                <span>
                                    {parentTag.title === tag.title ? (
                                        <Icon icon={'check'} className="text-success me-2" />
                                    ) : (
                                        <Icon icon={'times'} className="text-danger me-2" />
                                    )}
                                </span>
                            )}
                            <b>Tag Name: </b> {tag.title}
                        </div>
                        <div>
                            {parentTag && (
                                <span>
                                    {parentTag.quantity === tag.quantity ? (
                                        <Icon icon={'check'} className="text-success me-2" />
                                    ) : (
                                        <Icon icon={'times'} className="text-danger me-2" />
                                    )}
                                </span>
                            )}
                            <b>Quantity: </b> {tag.quantity}
                        </div>
                        <div>
                            {parentTag && (
                                <span>
                                    {parentTag.partType === tag.partType ? (
                                        <Icon icon={'check'} className="text-success me-2" />
                                    ) : (
                                        <Icon icon={'times'} className="text-danger me-2" />
                                    )}
                                </span>
                            )}
                            <b>Part Type: </b> {tag.partType}
                        </div>
                        <div>
                            {parentTag && (
                                <span>
                                    {parentTag.text === tag.text ? (
                                        <Icon icon={'check'} className="text-success me-2" />
                                    ) : (
                                        <Icon icon={'times'} className="text-danger me-2" />
                                    )}
                                </span>
                            )}
                            <b>Tag Text: </b> {tag.text}
                        </div>
                        <div>
                            <b>Tag Images: </b>{' '}
                        </div>
                        <div>
                            {parentTag && (
                                <span>
                                    {parentTag.note === tag.note ? (
                                        <Icon icon={'check'} className="text-success me-2" />
                                    ) : (
                                        <Icon icon={'times'} className="text-danger me-2" />
                                    )}
                                </span>
                            )}
                            <b>Tag Notes: </b> {tag.note}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default LinkTagsCheckboxItem;
