import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { TaggerContext } from 'contexts/TaggerContextProvider';

const useNavigationButtons = () => {
    const { procedureId, bookId, oemId } = useParams();
    const [nextProcedureId, setNextProcedureId] = useState(null);
    const [previousProcedureId, setPreviousProcedureId] = useState(null);
    const { displayProcedureIds } = useContext(TaggerContext);

    useEffect(() => {
        if (displayProcedureIds.length > 0) {
            const currentIndex = displayProcedureIds.findIndex(id => id === parseInt(procedureId));
            if (currentIndex !== -1) {
                const nextId =
                    currentIndex === displayProcedureIds.length - 1 ? null : displayProcedureIds[currentIndex + 1];
                const previousId = currentIndex === 0 ? null : displayProcedureIds[currentIndex - 1];

                setNextProcedureId(nextId);
                setPreviousProcedureId(previousId);
            } else {
                // something's wrong (refreshing, bookmark, etc.)
                setNextProcedureId(null);
                setPreviousProcedureId(null);
            }
        }
    }, [displayProcedureIds, procedureId, setNextProcedureId, setPreviousProcedureId]);

    return { nextProcedureId, previousProcedureId, bookId, oemId };
};

export default useNavigationButtons;
