import React, { useContext, useMemo } from 'react';
import { PrimaryButton, TabbedPane } from 'oemiq-common';
import OldMapperTable from './OldMapperTable/OldMapperTable';
import useOldMapperList from './useOldMapperList';
import useFilterProceduresByMappingFlowAndSearchAndFilters from 'components/locations/MappingProcess/OldMapper/useFilterProceduresByMappingFlowAndSearchAndFilters';
import MapperSearch from './Search/MapperSearch';
import ActionButtons from './Actions/ActionButtons';
import ColumnSettings from './Actions/ColumnSettings/ColumnSettings';
import FilterSearch from './Filter/FilterSearch';
import { MappingDefinitionsContext } from 'contexts/MappingDefinitionsContext';
import { OlsMapperListContext } from 'contexts/MapperListContext';
import BulkTaskHistoryModal from './BulkTaskHistoryModal';

const OldMapperTool = () => {
    const {
        mergedProcedureDetailsList,
        mergedRemovedProcedureDetailsList,
        updateProcedureOemiqType,
        setNewGroupListToProcedureByProcedureId,
        dynamicProcedureKeys,
        searchProcedures,
        searchResults,
        handleSaveColumnSettings,
        showColumnSettings,
        setShowColumnSettings,
        updateColumnWidth,
        appliedFilters,
        setAppliedFilters,
        hideDynamicKeyList,
        noGroupFilter,
        setNoGroupFilter,
        noTypeFilter,
        setNoTypeFilter,
        refreshedFilter,
        setRefreshedFilter,
        onlyHotSheetFilter,
        setOnlyHotSheetFilter,
        mappingProgress,
        updateProcedures,
        refreshProcedures,
        updateOemIqTypeForProcedureIds,
        isBulkActionHistoryModalOpen,
        handleBulkTaskHistoryToggle,
        handleBulkTaskHistoryButtonClick,
    } = useOldMapperList();

    const mappingDefinitions = useContext(MappingDefinitionsContext);

    const { inReviewProcedures, completeProcedures, needsHelpProcedures, allActiveProcedures, removedProcedures } =
        useFilterProceduresByMappingFlowAndSearchAndFilters(
            mergedProcedureDetailsList,
            mergedRemovedProcedureDetailsList,
            searchResults,
            appliedFilters,
            mappingDefinitions.groups,
            noGroupFilter,
            noTypeFilter,
            refreshedFilter,
            onlyHotSheetFilter,
            mappingDefinitions
        );
    const contextValue = useMemo(() => {
        return {
            updateProcedures,
            appliedFilters,
            setAppliedFilters,
            searchResults,
            updateProcedureOemiqType,
            hideDynamicKeyList,
            refreshProcedures,
            updateOemIqTypeForProcedureIds,
            toggleBulkTaskHistoryModal: handleBulkTaskHistoryToggle,
        };
    }, [
        appliedFilters,
        hideDynamicKeyList,
        refreshProcedures,
        searchResults,
        setAppliedFilters,
        updateOemIqTypeForProcedureIds,
        updateProcedureOemiqType,
        updateProcedures,
        handleBulkTaskHistoryToggle,
    ]);

    const createTabPane = (procedures, tabKey, tabHeader, showIsDeletedFlags = false) => {
        return (
            <>
                <div className="align-items-center d-flex justify-content-center clickable" data-testid="tab-label">
                    <strong>{tabHeader}</strong>
                </div>
                {procedures.length === 0 ? (
                    <h3 className="pt-4 text-center" id="no-results">
                        No Results
                    </h3>
                ) : (
                    <div className="col mt-3">
                        <OldMapperTable
                            procedures={procedures}
                            tab={tabKey}
                            setNewGroupListToProcedureByProcedureId={setNewGroupListToProcedureByProcedureId}
                            dynamicProcedureKeys={dynamicProcedureKeys}
                            updateColumnWidth={updateColumnWidth}
                            mappingProgress={mappingProgress}
                            showIsDeletedFlags={showIsDeletedFlags}
                        />
                    </div>
                )}
            </>
        );
    };

    return (
        <OlsMapperListContext.Provider value={contextValue}>
            <div className="container-fluid mt-3">
                <div className="row mb-3">
                    <div className="col-6">
                        <MapperSearch searchProcedures={searchProcedures} />
                        <FilterSearch
                            columns={dynamicProcedureKeys}
                            setNoGroupFilter={setNoGroupFilter}
                            setNoTypeFilter={setNoTypeFilter}
                            setRefreshedFilter={setRefreshedFilter}
                            setOnlyHotSheetFilter={setOnlyHotSheetFilter}
                        />
                    </div>
                    <div className="col-6 d-flex justify-content-end">
                        <div className="pe-2">
                            <PrimaryButton
                                id="bulk-task-history-button"
                                type="button"
                                onClick={handleBulkTaskHistoryButtonClick}>
                                Bulk Task History
                            </PrimaryButton>
                        </div>
                        <div className="pe-2">
                            <PrimaryButton id="refresh-procedures" type="button" onClick={refreshProcedures}>
                                Refresh Procedures
                            </PrimaryButton>
                        </div>
                        <ActionButtons setShowColumnSettings={setShowColumnSettings} />
                    </div>
                </div>
                <div className="col-12">
                    <TabbedPane>
                        {createTabPane(allActiveProcedures, 'all', `All Active (${allActiveProcedures.length})`)}
                        {createTabPane(inReviewProcedures, 'review', `In Review (${inReviewProcedures.length})`)}
                        {createTabPane(completeProcedures, 'complete', `Complete (${completeProcedures.length})`)}
                        {createTabPane(needsHelpProcedures, 'help', `Need Help (${needsHelpProcedures.length})`)}
                        {createTabPane(removedProcedures, 'removed', `Removed (${removedProcedures.length})`, true)}
                    </TabbedPane>
                </div>
                <ColumnSettings
                    showColumnSettings={showColumnSettings}
                    setShowColumnSettings={setShowColumnSettings}
                    dynamicProcedureKeys={dynamicProcedureKeys}
                    onSaveColumnSettings={handleSaveColumnSettings}
                    hideDynamicKeyList={hideDynamicKeyList}
                />
            </div>
            <BulkTaskHistoryModal isOpen={isBulkActionHistoryModalOpen} onToggle={handleBulkTaskHistoryToggle} />
        </OlsMapperListContext.Provider>
    );
};

export default OldMapperTool;
