import { buildProcedureProperty } from './ProcedureProperty';
import { getCommonFilters, getCommonProperties, getLastCommonProperties } from './BaseProcedureMetadata';
import { requestCreateNewMappingRule } from 'api/RepairProcedures/RepairProcedureMappingRuleApi';
import { buildOemMetadata, OemMetadata } from './BuildOemMetadata';
import { isEmpty, isNil } from 'lodash';
import { PROPERTY_TYPE } from './PropertyType';
import { OemEngineService, OemMappingRuleBase } from './types';
import { ProcedureType } from './ProcedureType';

const OEM_METADATA_PROPERTY = 'latestHondaProcedure';

const getRulableProperties = () => [
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.serviceCategoryTypeName',
        displayName: 'Service Category Type Name',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'serviceCategoryTypeName',
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.serviceCategoryName',
        displayName: 'Service Category Name',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'serviceCategoryName',
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.serviceSystemName',
        displayName: 'Service System Name',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'serviceSystemName',
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.componentDTCName',
        displayName: 'Component DTC Name',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'componentDTCName',
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.displayName',
        displayName: 'Display Name',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'displayName',
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.sitqCode',
        displayName: 'SITQ Code',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'sitqCode',
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.sitqDescription',
        displayName: 'SITQ Description',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'sitqDescription',
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.publicationTitle',
        displayName: 'Publication Title',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'publicationTitle',
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.procedureTypeCode',
        displayName: 'Procedure Type Code',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'procedureTypeCode',
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.procedureType',
        displayName: 'Procedure Type',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'procedureType',
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.dataGroupName',
        displayName: 'Data Group Name',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'dataGroupName',
        procedureTypes: [ProcedureType.Procedure],
    }),
];

export const getHondaProcedureMetadata = () =>
    buildOemMetadata({
        oemId: 17,
        oemName: 'Honda',
        properties: [
            ...getCommonProperties(),
            ...getRulableProperties(),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.hondaProcedureId',
                displayName: 'Honda Procedure Id',
                type: PROPERTY_TYPE.number,
                procedureTypes: [ProcedureType.Procedure],
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.oemProcedureId',
                displayName: 'Oem Procedure Id',
                type: PROPERTY_TYPE.string,
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.version',
                displayName: 'Version',
                type: PROPERTY_TYPE.string,
                procedureTypes: [ProcedureType.Procedure],
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.htmlFileName',
                displayName: 'HTML File Name',
                type: PROPERTY_TYPE.string,
                procedureTypes: [ProcedureType.Procedure],
            }),
            ...getLastCommonProperties(),
        ],
        ruleKey: 'hondaMappingRuleId',
        filters: [...getCommonFilters()],
    });

type HondaMappingRule = OemMappingRuleBase & {
    hondaMappingRuleId: number;
};

class _HondaMappingEngineService implements OemEngineService<HondaMappingRule> {
    metadata: OemMetadata;

    constructor() {
        this.metadata = HONDA_PROCEDURE_METADATA;
    }

    isRuleValid = (rule: HondaMappingRule): boolean => {
        return (
            (!isNil(rule.typeId) || !isEmpty(rule.groupIds)) &&
            this.metadata.ruleable.some(p => rule[p.rulePropertyName])
        );
    };

    createRule = async (rule: HondaMappingRule): Promise<HondaMappingRule> => {
        const newRule = { ...rule };
        const hondaMappingRuleId = await requestCreateNewMappingRule(this.metadata.oemId, newRule);
        newRule.hondaMappingRuleId = hondaMappingRuleId;

        return newRule;
    };
}

export const HONDA_PROCEDURE_METADATA = getHondaProcedureMetadata();
export const HondaMappingEngineService = new _HondaMappingEngineService();
