import authService from 'auth/AuthenticationService';

export const fetchWithAuthHeader = async (input: RequestInfo | URL, init?: RequestInit) => {
    const accessToken = await authService.getAccessToken();

    const { headers, ...rest } = init ?? {};

    init = {
        headers: { Authorization: `Bearer ${accessToken}`, ...headers },
        ...rest,
    };

    return await fetch(input, init);
};
