import { MappingWorkflowStatus } from 'types/procedureTypes';
import { escapeSpecialChars, ManuallyMapped, operatorsList } from './operators';
import { mappingRulesOperatorsList, proceduresOperatorsList } from './Types/GroupList';
import { MappingStatusListOption } from './Types/MappingStatus';
import { match } from 'ts-pattern';
import { MappingDefinitionType } from 'contexts/types';
import { Text } from './Types';
import ProcedureTypeFilter from './Types/ProcedureTypeFilter';
import { ProcedureType } from 'hooks/OemModels/MetaModel/ProcedureType';
import { VehicleFilter, YearOperator } from './Types/VehicleFilter';

const bookNameField = 'booksForProcedure.book.bookName';
const bookIdField = 'booksForProcedure.book.bookId';

const mappedByBuildSQLFilter = (propertyName, value) => {
    const filters = [];
    const { rulesIds, manuallyMapped } = value;
    const values = rulesIds.map(v => v.value);
    if (manuallyMapped != null) {
        let filter = `(stageArea/type/mappingRuleId eq null or stageArea/groups/any(g : g/mappingRuleId eq null))`;
        if (manuallyMapped === ManuallyMapped.EXCLUDE) {
            filter = 'not ' + filter;
        }
        filters.push(filter);
    }

    if (values.length > 0) {
        const inList = values.join(',');
        filters.push(
            `(stageArea/type/mappingRuleId in (${inList}) or stageArea/groups/any(g : g/mappingRuleId in (${inList})))`
        );
    }
    return `(${filters.join(' and ')})`;
};

function createODataFilterFunction(operator) {
    const getODataField = field => field.replaceAll('.', '/');
    const getODataValue = (value, propertyType) => {
        const oDataValue =
            propertyType === Text || (isNaN(value) && (typeof value === 'string' || value instanceof String))
                ? encodeURIComponent(`'${value.replaceAll("'", "''")}'`)
                : value;
        return oDataValue;
    };
    switch (operator) {
        case 'contains':
            return (field, value, propertyType) =>
                `contains(${getODataField(field)}, ${getODataValue(value, propertyType)})`;
        case 'not contains':
            return (field, value, propertyType) =>
                `not contains(${getODataField(field)}, ${getODataValue(value, propertyType)})`;
        default:
            return (field, value, propertyType) =>
                `${getODataField(field)} ${operator} ${getODataValue(value, propertyType)}`;
    }
}

const mappedByBuildESFilter = (propertyName, value) => {
    const filters = [];
    const { rulesIds, manuallyMapped } = value;
    const values = rulesIds.map(v => v.value);

    if (manuallyMapped != null) {
        if (manuallyMapped === ManuallyMapped.EXCLUDE) {
            filters.push(`(!stageArea.type.mappingRuleId:null) && (stageArea.groups.mappingRuleId:*)`);
        } else {
            filters.push(`(stageArea.type.mappingRuleId:null) || (!stageArea.groups.mappingRuleId:*)`);
        }
    }

    if (values.length > 0) {
        const inList = values.join(' OR ');
        filters.push(`(stageArea.type.mappingRuleId:(${inList}) || stageArea.groups.mappingRuleId:(${inList}))`);
    }

    return filters.join(' && ');
};

const getESMappingStatusFilter = (value: MappingStatusListOption, equal: boolean) => {
    const equalFilter = (filter: string, equal: boolean) => (equal ? filter : `!(${filter})`);

    return match(value)
        .with(MappingStatusListOption.InReview, () =>
            equalFilter(`mappingStatusId:${MappingWorkflowStatus.InReview}`, equal)
        )
        .with(MappingStatusListOption.Complete, () =>
            equalFilter(`mappingStatusId:${MappingWorkflowStatus.Completed}`, equal)
        )
        .with(MappingStatusListOption.NeedHelp, () =>
            equalFilter(`mappingStatusId:${MappingWorkflowStatus.NeedHelp}`, equal)
        )
        .run();
};

const getSQLMappingStatusFilter = (value: MappingStatusListOption, equal: boolean) => {
    const hotSheetGroupId = 80;
    const isActiveFilter = 'isDeleted eq false';
    const hasGroupOrTypeFilter = '(stageArea/type/typeId ne null or stageArea/groups/any())';
    const hasGroupAndTypeFilter = 'stageArea/type/typeId ne null and stageArea/groups/any()';
    const hasAnyGroupBesideHotSheet = `stageArea/groups/$count ge 1 and stageArea/groups/any(group: group/groupId ne ${hotSheetGroupId})`;
    const hasAnyGroupOrTypeInStatusFilter = (value: MappingWorkflowStatus) => {
        if (value === MappingWorkflowStatus.InReview) {
            return `(stageArea/groups/any(group: group/mappingStatusId eq ${value}) or stageArea/type/mappingStatusId eq ${value} or stageArea/type/mappingStatusId eq null)`;
        }

        return `(stageArea/groups/any(group: group/mappingStatusId eq ${value}) or stageArea/type/mappingStatusId eq ${value})`;
    };
    const hasEveryGroupAndTypeInStatusFilter = (value: MappingWorkflowStatus) =>
        `stageArea/groups/all(group: group/mappingStatusId eq ${value}) and stageArea/type/mappingStatusId eq ${value}`;

    const equalFilter = (filter: string, equal: boolean) => (equal ? filter : `not (${filter})`);

    return match(value)
        .with(MappingStatusListOption.InReview, () =>
            equalFilter(
                `${isActiveFilter} and ${hasGroupAndTypeFilter} and ${hasAnyGroupBesideHotSheet} and not ${hasAnyGroupOrTypeInStatusFilter(
                    MappingWorkflowStatus.NeedHelp
                )} and ${hasAnyGroupOrTypeInStatusFilter(MappingWorkflowStatus.InReview)}`,
                equal
            )
        )
        .with(MappingStatusListOption.Complete, () =>
            equalFilter(
                `${isActiveFilter} and ${hasGroupAndTypeFilter} and ${hasAnyGroupBesideHotSheet} and ${hasEveryGroupAndTypeInStatusFilter(
                    MappingWorkflowStatus.Completed
                )}`,
                equal
            )
        )
        .with(MappingStatusListOption.NeedHelp, () =>
            equalFilter(
                `${isActiveFilter} and ${hasGroupOrTypeFilter} and ${hasAnyGroupOrTypeInStatusFilter(
                    MappingWorkflowStatus.NeedHelp
                )}`,
                equal
            )
        )
        .run();
};

export const filterToQueryTranslations = {
    [operatorsList.contains.value]: {
        buildESFilter: (field, value) => `${field}:*${escapeSpecialChars(value)}*`,
        buildFilter: createODataFilterFunction('contains'),
    },
    [operatorsList.containsBookName.value]: {
        buildESFilter: (field, value) => `${bookNameField}:*${escapeSpecialChars(value)}*`,
        buildFilter: (propertyName, value) =>
            `BooksForProcedure/any(bp: contains(bp/Book/BookName, '${encodeURIComponent(value)}'))`,
    },
    [operatorsList.notContains.value]: {
        buildESFilter: (field, value) => `!${field}:*${escapeSpecialChars(value)}*`,
        buildFilter: createODataFilterFunction('not contains'),
    },
    [operatorsList.notContainsBookName.value]: {
        buildESFilter: (field, value) => `!${bookNameField}:*${escapeSpecialChars(value)}*`,
        buildFilter: (propertyName, value) =>
            `not BooksForProcedure/any(bp: contains(bp/Book/BookName, '${encodeURIComponent(value)}'))`,
    },
    [operatorsList.eq.value]: {
        buildESFilter: (field, value) => `${field}:${escapeSpecialChars(value)}`,
        buildFilter: createODataFilterFunction('eq'),
    },
    [operatorsList.eqBookId.value]: {
        buildESFilter: (field, value) => `${bookIdField}:${value}`,
        buildFilter: (propertyName, value) => `BooksForProcedure/any(bp: bp/BookId eq ${value})`,
    },
    [operatorsList.eqBookName.value]: {
        buildESFilter: (field, value) => `${bookNameField}:${escapeSpecialChars(value)}`,
        buildFilter: (propertyName, value) =>
            `BooksForProcedure/any(bp: bp/Book/BookName eq '${encodeURIComponent(value)}')`,
    },
    [operatorsList.ne.value]: {
        buildESFilter: (field, value) => `!${field}:${escapeSpecialChars(value)}`,
        buildFilter: createODataFilterFunction('ne'),
    },
    [operatorsList.neBookName.value]: {
        buildESFilter: (field, value) => `!${bookNameField}:${escapeSpecialChars(value)}`,
        buildFilter: (propertyName, value) =>
            `BooksForProcedure/any(bp: bp/Book/BookName ne '${encodeURIComponent(value)}')`,
    },
    [operatorsList.lt.value]: {
        buildESFilter: (field, value) => `${field}:<${value}`,
        buildFilter: createODataFilterFunction('lt'),
    },
    [operatorsList.le.value]: {
        buildESFilter: (field, value) => `${field}:<=${value}`,
        buildFilter: createODataFilterFunction('le'),
    },
    [operatorsList.gt.value]: {
        buildESFilter: (field, value) => `${field}:>${value}`,
        buildFilter: createODataFilterFunction('gt'),
    },
    [operatorsList.ge.value]: {
        buildESFilter: (field, value) => `${field}:>=${value}`,
        buildFilter: createODataFilterFunction('ge'),
    },
    [operatorsList.mappedBy.value]: {
        buildESFilter: mappedByBuildESFilter,
        buildFilter: mappedByBuildSQLFilter,
    },
    eqdate: {
        buildESFilter: (propertyName, value) => `${propertyName}:${value}`,
        buildFilter: (propertyName, value) => `${propertyName} eq ${value}`,
    },
    nedate: {
        buildESFilter: (propertyName, value) => `!${propertyName}:${value}`,
        buildFilter: (propertyName, value) => `${propertyName} ne ${value}`,
    },
    ltdate: {
        buildESFilter: (propertyName, value) => `${propertyName}:<${value}`,
        buildFilter: (propertyName, value) => `${propertyName} lt ${value}`,
    },
    ledate: {
        buildESFilter: (propertyName, value) => `${propertyName}:<=${value}`,
        buildFilter: (propertyName, value) => `${propertyName} le ${value}`,
    },
    gtdate: {
        buildESFilter: (propertyName, value) => `${propertyName}:>${value}`,
        buildFilter: (propertyName, value) => `${propertyName} gt ${value}`,
    },
    gedate: {
        buildESFilter: (propertyName, value) => `${propertyName}:>=${value}`,
        buildFilter: (propertyName, value) => `${propertyName} ge ${value}`,
    },
    [proceduresOperatorsList.containsAny.value]: {
        buildESFilter: (propertyName, value) => `${propertyName}.groupId:(${value.map(g => g.regionId).join(' OR ')})`,
        buildFilter: (propertyName, value) =>
            `${propertyName.replaceAll('.', '/')}/any(g: g/groupId in (${value.map(g => g.regionId).join(', ')}))`,
    },
    [proceduresOperatorsList.notContainsAny.value]: {
        buildESFilter: (propertyName, value) => `!${propertyName}.groupId:(${value.map(g => g.regionId).join(' OR ')})`,
        buildFilter: (propertyName, value) =>
            `${propertyName.replaceAll('.', '/')}/all(g: g/groupId in (${value
                .map(g => g.regionId)
                .join(', ')}) eq false)`,
    },
    [proceduresOperatorsList.containsAll.value]: {
        buildESFilter: (propertyName, value) => `${propertyName}.groupId:(${value.map(g => g.regionId).join(' AND ')})`,
        buildFilter: (propertyName, value) =>
            `(${value
                .map(g => `${propertyName.replaceAll('.', '/')}/any(g: g/groupId eq ${g.regionId})`)
                .join(' and ')})`,
    },
    [proceduresOperatorsList.eqwo.value]: {
        buildESFilter: (propertyName, value) =>
            `${propertyName}.groupId:(${value.map(g => g.regionId).join(' AND ')}) && ${
                propertyName.split('.')[0]
            }.groupsCount:${value.length}`,
        buildFilter: (propertyName, value) =>
            `(${value
                .map(g => `${propertyName.replaceAll('.', '/')}/any(g: g/groupId eq ${g.regionId})`)
                .join(' and ')} and ${propertyName.replaceAll('.', '/')}/$count eq ${value.length})`,
    },
    [proceduresOperatorsList.nameContains.value]: {
        buildESFilter: (propertyName, value) => `${propertyName}.groupId:(${value.map(g => g.regionId).join(' OR ')})`,
        buildFilter: (propertyName, value) =>
            `${propertyName.replaceAll('.', '/')}/any(g: g/groupId in (${value.map(g => g.regionId).join(', ')}))`,
    },
    mappingStatusEq: {
        buildESFilter: (propertyName: string, value: { value: MappingStatusListOption; label: string }[]) =>
            getESMappingStatusFilter(value[0].value, true),
        buildFilter: (propertyName: string, value: { value: MappingStatusListOption; label: string }[]) =>
            getSQLMappingStatusFilter(value[0].value, true),
    },
    mappingStatusNe: {
        buildESFilter: (propertyName: string, value: { value: MappingStatusListOption; label: string }[]) =>
            getESMappingStatusFilter(value[0].value, false),
        buildFilter: (propertyName: string, value: { value: MappingStatusListOption; label: string }[]) =>
            getSQLMappingStatusFilter(value[0].value, false),
    },
    search: {
        buildFilter: () => null,
        buildESFilter: (propertyName: string, value: string) => `html:${value}`,
    },
    'typeList.contains': {
        buildESFilter: (propertyName: string, values: MappingDefinitionType[]) =>
            `${propertyName}:(${values.map(t => t.oemIqSectionId).join(' OR ')})`,
        buildFilter: (propertyName: string, values: MappingDefinitionType[]) =>
            `${propertyName.replaceAll('.', '/')} in (${values.map(t => t.oemIqSectionId).join(', ')})`,
    },
    'typeList.notContains': {
        buildESFilter: (propertyName: string, values: MappingDefinitionType[]) =>
            `!${propertyName}:(${values.map(t => t.oemIqSectionId).join(' OR ')})`,
        buildFilter: (propertyName: string, values: MappingDefinitionType[]) =>
            `${propertyName.replaceAll('.', '/')} in (${values.map(t => t.oemIqSectionId).join(', ')}) eq false`,
    },
    'typeList.eq': {
        buildESFilter: (propertyName: string, values: MappingDefinitionType[]) =>
            `${propertyName}:(${values[0].oemIqSectionId})`,
        buildFilter: (propertyName: string, value: MappingDefinitionType[]) =>
            `${propertyName.replaceAll('.', '/')} eq ${value[0].oemIqSectionId}`,
    },
    'typeList.ne': {
        buildESFilter: (propertyName: string, values: MappingDefinitionType[]) =>
            `!${propertyName}:(${values[0].oemIqSectionId})`,
        buildFilter: (propertyName: string, value: MappingDefinitionType[]) =>
            `${propertyName.replaceAll('.', '/')} ne ${value[0].oemIqSectionId}`,
    },
    'vehicleList.match': {
        label: 'Match',
        buildFilter: (propertyName: string, value: VehicleFilter) => {
            const filters = [];
            if (value.make?.value) filters.push(`v/oemId eq ${value.make.value}`);
            if (value.model?.value) filters.push(`v/modelId eq ${value.model.value}`);
            if (value.yearOperator?.value === YearOperator.eq) filters.push(`v/yearId eq ${value.yearNumber}`);
            if (value.yearOperator?.value === YearOperator.neq) filters.push(`v/yearId ne ${value.yearNumber}`);
            if (value.yearOperator?.value === YearOperator.range) {
                if (value.fromYear && !value.toYear) filters.push(`v/yearId ge ${value.fromYear}`);
                else if (!value.fromYear && value.toYear) filters.push(`v/yearId le ${value.toYear}`);
                else if (value.fromYear && value.toYear)
                    filters.push(`v/yearId ge ${value.fromYear} and v/yearId le ${value.toYear}`);
            }
            return `vehicles/any(v: ${filters.join(' and ')})`;
        },
        buildESFilter: (propertyName: string, value: VehicleFilter) => {
            const filters = [];
            if (value.make?.value) filters.push(`vehicles.oemId:${value.make.value}`);
            if (value.model?.value) filters.push(`vehicles.modelId:${value.model.value}`);
            if (value.yearOperator?.value === YearOperator.eq && value.yearNumber)
                filters.push(`vehicles.yearId:${value.yearNumber}`);
            if (value.yearOperator?.value === YearOperator.neq && value.yearNumber)
                filters.push(`!vehicles.yearId:${value.yearNumber}`);
            if (value.yearOperator?.value === YearOperator.range) {
                if (value.fromYear && !value.toYear) filters.push(`vehicles.yearId:>=${value.fromYear}`);
                else if (!value.fromYear && value.toYear) filters.push(`vehicles.yearId:<=${value.toYear}`);
                else if (value.fromYear && value.toYear)
                    filters.push(`vehicles.yearId:>=${value.fromYear} && vehicles.yearId:<=${value.toYear}`);
            }
            return filters.join(' && ');
        },
    },
    [mappingRulesOperatorsList.containsAny.value]: {
        buildFilter: (propertyName, value) =>
            `(${value
                .map(v => v.regionId)
                .map(g => `contains(${propertyName.replaceAll('.', '/')}, ',${g},')`)
                .join(' or ')})`,
    },
    [mappingRulesOperatorsList.notContainsAny.value]: {
        buildFilter: (propertyName, value) =>
            `(${value
                .map(v => v.regionId)
                .map(g => `not(contains(${propertyName.replaceAll('.', '/')}, ',${g},'))`)
                .join(' and ')})`,
    },
    [mappingRulesOperatorsList.containsAll.value]: {
        buildFilter: (propertyName, value) =>
            `(${value
                .map(v => v.regionId)
                .map(g => `contains(${propertyName.replaceAll('.', '/')}, ',${g},')`)
                .join(' and ')})`,
    },
    [mappingRulesOperatorsList.eqwo.value]: {
        buildFilter: (propertyName, value) =>
            `(${value
                .map(v => v.regionId)
                .map(g => `contains(${propertyName.replaceAll('.', '/')}, ',${g},')`)
                .join(' and ')} and length(${propertyName.replaceAll('.', '/')}) eq ${Math.max(
                2 * value.length + 1,
                0
            )})`,
    },
    [mappingRulesOperatorsList.nameContains.value]: {
        buildFilter: (propertyName, value) =>
            `(${value
                .map(v => v.regionId)
                .map(g => `contains(${propertyName.replaceAll('.', '/')}, ',${g},')`)
                .join(' or ')})`,
    },
    [ProcedureTypeFilter.operators[0].value]: {
        buildESFilter: (propertyName, value) => `procedureTypeId:${ProcedureTypeToDisplayName[value]}`,
        buildFilter: (propertyName, value) => `procedureTypeId eq ${ProcedureTypeToDisplayName[value]}`,
    },
};

const ProcedureTypeToDisplayName: Record<ProcedureType, number> = {
    [ProcedureType.Procedure]: 1,
    [ProcedureType.PositionStatement]: 2,
    [ProcedureType.Bulletin]: 3,
};
