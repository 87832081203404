import React, { useMemo } from 'react';
import ReactSelect from 'react-select';
import Creatable from 'react-select/creatable';
import mapToOptions from 'helpers/MapToOptions';

const getValue = (options, value) => {
    return options.find(o => o.value === value) || { value, label: value };
};

const DropdownCell = ({ value, options, edited, setEdited, useValuePropDirectly = false, ...props }) => {
    const formattedOptions = useMemo(() => options.map(mapToOptions), [options]);

    let dropdown_text = '';
    //useValuePropDirectly used primarily for quantity condition. This skips looking into options for the value which is always present because quantity condition is freeform
    if (useValuePropDirectly) dropdown_text = value;
    else if (value && options) {
        const res = options.find(o => o.value === value);
        if (res) {
            dropdown_text = res.text;
        }
    }
    return edited ? (
        <td style={{ minWidth: '120px' }}>
            {props.allowAdditions ? (
                <Creatable
                    className="table-cell-select"
                    options={formattedOptions}
                    value={getValue(formattedOptions, value)}
                    {...props}
                />
            ) : (
                <ReactSelect
                    className="table-cell-select"
                    options={formattedOptions}
                    value={getValue(formattedOptions, value)}
                    {...props}
                />
            )}
        </td>
    ) : (
        <td className="clickable" onClick={setEdited}>
            {dropdown_text}
        </td>
    );
};

export default React.memo(DropdownCell);
