import React from 'react';
import { PublishStatus } from './types';
import ProgressBar from 'components/Shared/ProgressBar/ProgressBar';

interface RefreshPublisherFooterPropsType {
    show?: boolean;
    onPublish: () => void;
    status?: PublishStatus;
}

const RefreshPublisherFooter: React.FC<RefreshPublisherFooterPropsType> = ({ show, status, onPublish }) => {
    if (!show) return null;

    return (
        <footer className="d-flex justify-content-end fixed-bottom">
            {status && (
                <div className="bulk-publish-status-overlay d-flex justify-content-center align-items-center">
                    <ProgressBar percentage={status.percentage} />
                    <div className="spinner-border me-2 refresh-publisher-spinner" role="status" />
                    <div>{`Completed publishing ${status.current} books, loading statistics...`}</div>
                </div>
            )}
            <button
                type="button"
                className="btn btn-success m-1"
                onClick={onPublish}
                data-testid={'refresh-publisher-bulk-publish-btn'}>
                Bulk publish
            </button>
        </footer>
    );
};

export default RefreshPublisherFooter;
