import { useCallback, useState } from 'react';

const useBulkSelect = (totalDataLength: number, keyId: string, resetWhenAll: boolean) => {
    const [selected, setSelected] = useState([]);
    const [isAll, setIsAll] = useState<boolean>(false);

    const resetSelected = useCallback(() => {
        setSelected([]);
        setIsAll(false);
    }, []);

    const handleSelectOne = useCallback(
        async id => {
            setSelected(currBulk => {
                if (currBulk.includes(id)) {
                    setIsAll(false);
                    return currBulk.filter(b => b !== id);
                } else if (selected.length === totalDataLength - 1) {
                    setIsAll(true);
                    resetWhenAll && setSelected([]);
                    return [...currBulk, id];
                } else {
                    return [...currBulk, id];
                }
            });
        },
        [resetWhenAll, selected.length, totalDataLength]
    );

    const handleSelectAll = useCallback(
        data => {
            if (resetWhenAll) {
                setSelected([]);
                setIsAll(!isAll);
            } else {
                if (selected.length === data.length) {
                    setSelected([]);
                    setIsAll(false);
                } else {
                    setSelected(data.map(p => p[keyId]));
                    setIsAll(true);
                }
            }
        },
        [isAll, keyId, resetWhenAll, selected.length]
    );

    return { selected, isAll, resetSelected, handleSelectAll, handleSelectOne };
};

export default useBulkSelect;
