import { useState, useEffect, useContext } from 'react';
import { downloadPlansInCSVFormat } from 'api/RepairPlanApi';
import { ToastContext } from 'components/ToastProvider';

const usePlanReport = () => {
    const [inputValue, setInputValue] = useState('');
    const [repairPlanIds, setRepairPlanIds] = useState([]);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const { showToast } = useContext(ToastContext);

    useEffect(() => {
        setShowErrorMessage(false);
    }, [inputValue, repairPlanIds]);

    const handleInputChange = value => {
        let intValue = parseInt(value);

        if (value === '') setInputValue('');
        else if (intValue) setInputValue(intValue);
    };

    const addToPlans = planId => {
        if (planId === '') return;
        if (repairPlanIds.includes(planId)) {
            setShowErrorMessage(true);
            return;
        }

        setRepairPlanIds(currState => [...currState, planId]);
        setInputValue('');
    };

    const removeFromPlans = planId => {
        setRepairPlanIds(currState => {
            return currState.filter(c => c !== planId);
        });
    };

    const handleKeyPress = e => {
        if (e.key === 'Enter') e.currentTarget.blur();
    };

    const handleGenerateReportClick = async () => {
        try {
            await downloadPlansInCSVFormat(repairPlanIds);
        } catch (error) {
            showToast(error);
        }
    };

    return {
        inputValue,
        handleInputChange,
        addToPlans,
        handleKeyPress,
        showErrorMessage,
        repairPlanIds,
        handleGenerateReportClick,
        removeFromPlans,
    };
};

export default usePlanReport;
