export const createMap = (array: { value: string | number }[], labelKey: string) => {
    return array.length > 0 ? array.reduce((map, item) => ({ ...map, [item.value]: item[labelKey] }), {}) : null;
};

export const createLabeledArray = (array: unknown[], map: object[], idProp: string, labelProp: string) => {
    const labeledArray = [];

    Array.isArray(array) &&
        array.forEach(e => {
            const labelObject = map.find(m => m[idProp] === e);
            labelObject && labeledArray.push(labelObject[labelProp]);
        });

    return labeledArray;
};
