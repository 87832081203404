import { movedLocationCleanup } from 'api/RepairPlanApi';
import { requestOrganizations, requestMoveLocation, requestCompanyUsers } from 'api/SecurityApi';
import { LoadingContext } from 'components/Layout';
import { NotificationsContext } from 'components/Shared/Notifications/Notifications';
import { ToastContext } from 'components/ToastProvider';
import { useContext, useEffect, useState, useMemo } from 'react';
import { IOrg, MoveUserHook, ToastContextType, CompanyDetails, UserData } from './types';
import { IOrganization } from '../../types';

const useMoveUser = (
    handleShowMoveLocationModal: () => void,
    companyDetails: CompanyDetails,
    onSaveOrEdit: () => void
): MoveUserHook => {
    // higher order states
    const { notifications } = useContext(NotificationsContext);
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const { showToast } = useContext(ToastContext) as ToastContextType;
    // local states
    const [saveEnabled, setSaveEnabled] = useState(false);
    const [selectedOrg, setSelectedOrg] = useState(0);
    const [orgs, setOrgs] = useState<IOrg[]>([]);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const orgName = useMemo(() => {
        return orgs.find((org: IOrganization) => org.organizationId === selectedOrg)?.name ?? '';
    }, [selectedOrg, orgs]);
    const [currentParentOrgName, setCurrentParentOrgName] = useState('');

    useEffect(() => {
        let isMounted = true;
        const getOrgs = async () => {
            try {
                incrementLoading();
                const orgs = await requestOrganizations();
                if (isMounted) {
                    setCurrentParentOrgName(
                        orgs.find((org: IOrganization) => org.organizationId === companyDetails.organizationId)?.name
                    );
                    setOrgs(orgs.filter(o => o.isActive && o.organizationId != companyDetails.organizationId));
                }
            } catch (error) {
                showToast(error);
            } finally {
                decrementLoading();
            }
        };
        getOrgs();
        return () => {
            isMounted = false;
        };
    }, [companyDetails.organizationId, decrementLoading, incrementLoading, showToast]);

    useEffect(() => {
        if (companyDetails && companyDetails.companyId) {
            setSaveEnabled(selectedOrg !== 0);
        }
    }, [companyDetails, selectedOrg]);

    const handleSave = async () => {
        if (!saveEnabled) return;
        setShowConfirmModal(false);
        try {
            incrementLoading();
            const { companyId } = companyDetails;
            await requestMoveLocation(selectedOrg, companyId);
            const companyUsers = await requestCompanyUsers(companyId);
            await movedLocationCleanup(
                selectedOrg,
                companyId,
                companyUsers.map((u: UserData) => {
                    return u.userId;
                })
            );
            handleShowMoveLocationModal();
            notifications.pushSuccess('Location moved successfully!');
        } catch (error) {
            notifications.pushExceptionDanger(error);
        } finally {
            onSaveOrEdit();
            decrementLoading();
        }
    };

    return {
        orgs,
        selectedOrg,
        setSelectedOrg,
        saveEnabled,
        handleSave,
        showConfirmModal,
        setShowConfirmModal,
        orgName,
        currentParentOrgName,
    };
};

export default useMoveUser;
