import { useCallback, useMemo } from 'react';
import { isActive, isInReview, isCompleted, isNeedHelp } from './ProcedureService';

const useFilterProceduresByMappingFlowAndSearchAndFilters = (
    procedures,
    removedProcedures,
    searchResults,
    appliedFilters,
    groups,
    noGroupFilter,
    noTypeFilter,
    refreshedFilter,
    onlyHotSheetFilter,
    mappingDefinitions
) => {
    const filterBasedOnSearchResults = useCallback(
        procs => {
            if (searchResults !== null) {
                let searchProcIds = searchResults.map(sr => sr.procedureId);
                procs = procs.filter(p => searchProcIds.includes(p.procedureId));
            }
            return procs;
        },
        [searchResults]
    );

    const filterBasedOnColumFilters = useCallback(
        procs => {
            if (appliedFilters.length > 0) {
                appliedFilters.map(filter => {
                    const filterColumn = filter.selectedColumn;
                    const isOperatorInclude = filter.selectedOperator === 'include';
                    const filterTerm = filter.term.toLowerCase();
                    procs = procs.filter(procedure => {
                        const isIncludes = isProcedureIncludesTerm(
                            procedure,
                            filterColumn,
                            filterTerm,
                            mappingDefinitions
                        );
                        return isOperatorInclude ? isIncludes : !isIncludes;
                    });
                });
            }
            return procs;
        },
        [appliedFilters, mappingDefinitions]
    );

    const filterBasedOnFilterCheckboxes = useCallback(
        procs => {
            if (noGroupFilter) procs = procs.filter(p => p.stageArea.groups.length === 0);
            if (noTypeFilter) procs = procs.filter(p => p.stageArea.type.typeId === null);
            if (refreshedFilter)
                /* Latest, Unpublished, Significant */
                procs = procs.filter(p =>
                    p.procedureDetails.some(
                        d =>
                            d.isLatest === true &&
                            d.isPublished === false &&
                            (!d.versionSignificance /* Unevaluated */ ||
                                d.versionSignificance.toLowerCase() === 'MajorRevision'.toLowerCase())
                    )
                );
            const hotSheetGroupId = 80;
            if (onlyHotSheetFilter) {
                procs = procs.filter(p => p.stageArea.groups.every(group => group.groupId === hotSheetGroupId));
            }
            return procs;
        },
        [noGroupFilter, noTypeFilter, refreshedFilter, onlyHotSheetFilter]
    );

    const baseFilteredProcedures = useMemo(() => {
        let procs = procedures;
        procs = filterBasedOnSearchResults(procs);
        procs = filterBasedOnColumFilters(procs);
        procs = filterBasedOnFilterCheckboxes(procs);
        return procs;
    }, [filterBasedOnColumFilters, filterBasedOnFilterCheckboxes, filterBasedOnSearchResults, procedures]);

    const allActiveProcedures = useMemo(
        () => baseFilteredProcedures.filter(p => isActive(p)),
        [baseFilteredProcedures]
    );

    const inReviewProcedures = useMemo(
        () => baseFilteredProcedures.filter(p => isInReview(p)),
        [baseFilteredProcedures]
    );

    const completeProcedures = useMemo(
        () => baseFilteredProcedures.filter(p => isCompleted(p)),
        [baseFilteredProcedures]
    );

    const needsHelpProcedures = useMemo(
        () => baseFilteredProcedures.filter(p => isNeedHelp(p)),
        [baseFilteredProcedures]
    );

    const filteredRemovedProcedures = useMemo(() => {
        let procs = removedProcedures;
        procs = filterBasedOnSearchResults(procs);
        procs = filterBasedOnColumFilters(procs);
        procs = filterBasedOnFilterCheckboxes(procs);
        return procs;
    }, [filterBasedOnColumFilters, filterBasedOnFilterCheckboxes, filterBasedOnSearchResults, removedProcedures]);

    return {
        inReviewProcedures,
        completeProcedures,
        needsHelpProcedures,
        allActiveProcedures,
        removedProcedures: filteredRemovedProcedures,
    };
};

const isProcedureIncludesTerm = (procedure, filterColumn, filterTerm, mappingDefinitions) => {
    switch (filterColumn) {
        case 'ColumnGroups': {
            const matchingGroupIds = mappingDefinitions.groups
                .filter(groupDef => groupDef.regionFriendlyName.toLowerCase().includes(filterTerm))
                .map(groupDef => groupDef.regionId);
            return procedure.stageArea.groups.some(group => matchingGroupIds.includes(group.groupId));
        }
        case 'ColumnType': {
            const matchingTypeIds = mappingDefinitions.types
                .filter(typeDef => typeDef.oemIqSectionName.toLowerCase().includes(filterTerm))
                .map(typeDef => typeDef.oemIqSectionId);
            return matchingTypeIds.includes(procedure.stageArea.type.typeId);
        }
        default: {
            const procedureColumnValue = String(procedure[filterColumn]).toLowerCase();
            return procedureColumnValue.includes(filterTerm);
        }
    }
};

export default useFilterProceduresByMappingFlowAndSearchAndFilters;
