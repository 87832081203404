import React from 'react';

const OneTimeUseFlagHighlight = ({ highlightStyles, flag }) => {
    if (!highlightStyles) return null;
    let style = highlightStyles.filter(s => s.id === flag.oneTimeUseFlagId);
    if (style.length === 0) return null;
    if (flag.flagDisposition === null) return <div id={flag.oneTimeUseFlagId} style={style[0].style}></div>;
    else return null;
};

export default OneTimeUseFlagHighlight;
