import { useEffect, useState, useContext, useCallback, useReducer, useRef } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { LoadingContext } from 'components/Layout';
import { ToastContext } from 'components/ToastProvider';
import {
    getUntaggedFlagsByOemAsync_pagination,
    getTagsByOemAsync_pagination,
    requestCreateOneTimeUseTag,
    requestUpdateOneTimeUseTag,
    requestUpdateFlagDisposition,
    updateFlagNeedsAttention,
} from 'api/RepairProcedureApi';
import { TaggingWorkFlowStatusEnum } from 'helpers/FlagTagHelper';
import { useParams } from 'react-router-dom';
import { PAGE_SIZE, SEARCH_ACTION_TYPE_ENUM, ACTIONS } from 'helpers/BulkEditTableHelper';
import { getRandomTagColor } from 'helpers/TagColorHelper';
import { WARNING_MODAL_TYPE } from './ViewWarningModal';
import { NotificationsContext } from 'components/Shared/Notifications/Notifications';
import { requestModelsForOem } from 'api/vehicleInfo';
import { MappingDefinitionsContext } from 'contexts/MappingDefinitionsContext';
import { match, P } from 'ts-pattern';

export const LIFECYCLE_FILTER = {
    All: 'All',
    ActiveOnly: 'ActiveOnly',
    DisposedOnly: 'DisposedOnly',
};

export const CLEAR_FILTER_AND_SORT = {
    AllFilters: 'AllFilters',
    AllSorts: 'AllSorts',
    Both: 'Both',
};

export const FLAG_TAG_MODE = {
    Active: {
        name: 'Active',
        canBeEdited: true,
        canBeDisposed: true,
        canBeRestored: false,
        canBeUndo: false,
    },
    WillBeDisposed: {
        name: 'WillBeDisposed',
        canBeEdited: false,
        canBeDisposed: false,
        canBeRestored: false,
        canBeUndo: true,
        background: 'bg-secondary',
    },
    Disposed: {
        name: 'Disposed',
        canBeEdited: false,
        canBeDisposed: false,
        canBeRestored: true,
        canBeUndo: false,
        background: 'bg-red-200',
    },
    WillBeActive: {
        name: 'WillBeActive',
        canBeEdited: false,
        canBeDisposed: false,
        canBeRestored: false,
        canBeUndo: true,
        background: 'bg-success bg-opacity-50',
    },
};

export const getFlagTagMode = (isFlag, item, isLifecycleChange) => {
    return match([isFlag, item, isLifecycleChange])
        .with([true, { tagDispositionId: null }, false], () => FLAG_TAG_MODE.Active)
        .with([true, { tagDispositionId: null }, true], () => FLAG_TAG_MODE.WillBeDisposed)
        .with([true, { tagDispositionId: P.any }, false], () => FLAG_TAG_MODE.Disposed)
        .with([true, { tagDispositionId: P.any }, true], () => FLAG_TAG_MODE.WillBeActive)
        .with([false, { isActive: true }, false], () => FLAG_TAG_MODE.Active)
        .with([false, { isActive: true }, true], () => FLAG_TAG_MODE.WillBeDisposed)
        .with([false, { isActive: false }, false], () => FLAG_TAG_MODE.Disposed)
        .with([false, { isActive: false }, true], () => FLAG_TAG_MODE.WillBeActive)
        .exhaustive();
};

const DEFAULT_SEARCH_ACTIONS = {
    searchValue: '',
    filterActions: [],
    checkboxes: {
        NoGroupCheckbox: false,
        NoTypeCheckbox: false,
        RefreshedPendingCheckbox: false,
    },
};

const searchActionReducer = (state, action) => {
    switch (action.type) {
        case SEARCH_ACTION_TYPE_ENUM.ADD_FILTER:
            return { ...state, filterActions: [...state.filterActions, action.payload] };
        case SEARCH_ACTION_TYPE_ENUM.DELETE_FILTER:
            return {
                ...state,
                filterActions: state.filterActions.filter(a => a.id !== action.payload.id),
            };
        case SEARCH_ACTION_TYPE_ENUM.UPDATE_FILTER:
            return {
                ...state,
                filterActions: state.filterActions.map(a => {
                    if (a.id === action.payload.id) return action.payload;
                    return a;
                }),
            };
        case SEARCH_ACTION_TYPE_ENUM.TOGGLE_CHECKBOX:
            return {
                ...state,
                checkboxes: {
                    ...state.checkboxes,
                    [action.payload.checkboxName]: !state.checkboxes[action.payload.checkboxName],
                },
            };
        case SEARCH_ACTION_TYPE_ENUM.RESET:
            return DEFAULT_SEARCH_ACTIONS;
        case SEARCH_ACTION_TYPE_ENUM.SET_LIFECYCLE_FILTER:
            return {
                ...state,
                lifecycleFilter: action.payload,
            };
        default:
            throw new Error('Filter action type not found');
    }
};

const getFilterQueryFromSearchActions = (action, flagModeOn, lifecycleFilter) => {
    let query = '';
    if (action) {
        query += match(lifecycleFilter)
            .with(LIFECYCLE_FILTER.All, () => '')
            .with(LIFECYCLE_FILTER.ActiveOnly, () =>
                flagModeOn ? ' and tagDispositionId eq null  ' : 'and isActive eq true'
            )
            .with(LIFECYCLE_FILTER.DisposedOnly, () =>
                flagModeOn ? ' and tagDispositionId ne null  ' : 'and isActive ne true'
            )
            .exhaustive();

        if (action.checkboxes.NoGroupCheckbox) {
            query += ' and not procedure/stageArea/groups/any()';
        }
        if (action.checkboxes.NoTypeCheckbox) {
            query += ' and procedure/stageArea/type/typeId eq null';
        }
        if (action.checkboxes.RefreshedPendingCheckbox) {
            query +=
                " and procedure/procedureDetails/any(p:p/isLatest eq true and p/isPublished eq false and (p/versionSignificance eq null or p/versionSignificance eq OemIq.RepairProcedures.Data.Models.Rp.ProcedureVersionSignificance'MajorRevision'))";
        }

        // Extract and generate filter terms
        const filterTerms = action.filterActions
            .filter(fa => fa.action && fa.action.generateFilterTerm)
            .map(fa => {
                const term = fa.action.generateFilterTerm(fa.action.operatorId, fa.action.value, flagModeOn);
                return term;
            })
            .join(' ');

        query += ` ${filterTerms}`;
    }

    return query.trimEnd();
};

const modes = {
    FlagMode: 'flags',
    TagMode: 'tags',
};

const modeParamName = 'mode';

const useBulkEdit = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const modeParam = searchParams.get(modeParamName);

    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const { showToast } = useContext(ToastContext);
    const { notifications } = useContext(NotificationsContext);
    const { oemId } = useParams();
    const [modalProcedureId, setModalProcedureId] = useState(null);
    const [items, setItems] = useState([]);
    const [editedItems, setEditedItems] = useState(new Map());
    const [displayItems, setDisplayItems] = useState([]);
    const [deletedItemIds, setDeletedItemIds] = useState(new Map());
    const [flagModeOn, setFlagModeOn] = useState(modeParam === null || modeParam === modes.FlagMode);
    const [restoredItemIds, setRestoredItemIds] = useState(new Set());
    const [lifecycleFilter, setLifecycleFilter] = useState(LIFECYCLE_FILTER.ActiveOnly);
    const [isBulkEditModalOpen, setIsBulkEditModalOpen] = useState(false);
    const [modelsOptions, setModelsOptions] = useState([]);

    const locationStateHandled = useRef(false);

    const location = useLocation();

    const filterModelIds = useRef([]);

    // Selection
    const [selectedItems, setSelectedItems] = useState(new Map());
    const bulkCheckboxSelectionRef = useRef({
        isShiftKeyDown: false, // true if the user holding shift while checking
        isAdding: false, // true if the last action was to check a checkbox
    });

    // Master checkbox state
    const [selectAllChecked, setSelectAllChecked] = useState(false);

    const [sortedColumns, setSortedColumns] = useState(new Map());
    const [selectedSortColumn, setSelectedSortColumn] = useState(null);

    const [loading, setLoading] = useState(false); // indicate if it is fetching items from the api
    const [hasMore, setHasMore] = useState(true); // indicate if there are more items to fetch
    const [total, setTotal] = useState(0); // total possible values

    const { groups: regions, types } = useContext(MappingDefinitionsContext);

    const [groups, setGroups] = useState(new Map());

    // Search and filters
    const [searchAction, searchActionDispatch] = useReducer(searchActionReducer, DEFAULT_SEARCH_ACTIONS);
    const [filter, setFilter] = useState(getFilterQueryFromSearchActions(searchAction, flagModeOn, lifecycleFilter));

    // Warning Modal
    const [warningModalType, setWarningModalType] = useState(WARNING_MODAL_TYPE.APPLYING);
    const [warningModalCallback, setWarningModalCallback] = useState(null);
    const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);

    // Disposition
    const [dispositionModalOpen, setDispositionModalOpen] = useState(false);
    const [dispositionSelection, setDispositionSelection] = useState('');
    const [disposingFlags, setDisposingFlags] = useState([]);

    const [resetFilterDetail, setResetFilterDetail] = useState(null);

    const isLifecycleChange = useCallback(
        item => deletedItemIds.has(item.id) || restoredItemIds.has(item.id),
        [deletedItemIds, restoredItemIds]
    );

    // if none or all are checked, make sure checkbox is in right state
    useEffect(() => {
        let count = 0;
        const topItems = items.slice(0, 500);
        if (items.length >= 500) {
            topItems.forEach(item => {
                if (selectedItems.has(item.id)) count += 1;
            });
        } else {
            count = selectedItems.size;
        }

        if (count === 0) {
            setSelectAllChecked(false);
        } else if (count === topItems.length) {
            setSelectAllChecked(true);
        }
    }, [items, selectedItems]);

    // convert group array to map
    useEffect(() => {
        setGroups(new Map(regions.map(r => [r.regionId, r])));
    }, [regions]);

    // merge edited items and new items
    useEffect(() => {
        setDisplayItems(
            items.map(i => {
                const key = i.id;
                let ret = i;
                if (deletedItemIds.has(key)) {
                    ret = { ...i };
                    ret.isDeleted = true;
                } else if (editedItems.has(key)) {
                    ret = editedItems.get(key);
                }
                return ret;
            })
        );
    }, [items, editedItems, deletedItemIds]);

    const getModels = useCallback(async () => {
        try {
            const responseModels = (await requestModelsForOem(oemId)).sort((a, b) =>
                a.modelName.toUpperCase() < b.modelName.toUpperCase() ? -1 : 1
            );
            setModelsOptions(responseModels.map(model => ({ value: model.modelId, label: model.modelName })));
        } catch (error) {
            notifications.pushExceptionDanger(error);
        }
    }, [oemId, notifications]);
    useEffect(() => {
        getModels();
    }, [getModels]);

    const updateItem = useCallback(
        item => {
            if (getFlagTagMode(flagModeOn, item, isLifecycleChange(item)).canBeEdited)
                setEditedItems(prev => {
                    const newState = new Map(prev);
                    newState.set(item.id, item);
                    return newState;
                });
        },
        [flagModeOn, isLifecycleChange]
    );

    const selectItem = useCallback(
        item => {
            const key = item.id;
            if (!selectedItems.has(key)) {
                setSelectedItems(prevState => {
                    return new Map(prevState).set(key, item);
                });
            }
        },
        [selectedItems]
    );

    const deselectItem = useCallback(
        item => {
            const key = item.id;
            if (selectedItems.has(key)) {
                setSelectedItems(prevState => {
                    const newState = new Map(prevState);
                    newState.delete(key);
                    return newState;
                });
            }
        },
        [selectedItems]
    );

    const onNeedsAttentionClick = useCallback(
        async item => {
            if (!getFlagTagMode(flagModeOn, item, isLifecycleChange(item)).canBeEdited) return;

            const flagsArray = [{ oneTimeUseFlagId: item.oneTimeUseFlagId, needsAttention: !item.needsAttention }];
            await updateFlagNeedsAttention(flagsArray);

            setDisplayItems(currState => {
                let newState = JSON.parse(JSON.stringify(currState));
                let itemIndex = newState.findIndex(f => f.id === item.id);
                newState[itemIndex].needsAttention = !newState[itemIndex].needsAttention;
                return newState;
            });
        },
        [flagModeOn, isLifecycleChange]
    );

    const handleModalCloseButtonClick = useCallback(() => {
        setIsBulkEditModalOpen(false);
    }, []);

    const handleModalSaveButtonClick = useCallback(
        data => {
            const newSelectedItems = new Map(selectedItems);
            const newEditedItems = new Map(editedItems);

            // manually update all the selected flags'/tags' fields with the new values from the data
            [...selectedItems].forEach(v => {
                const [id, item] = v;
                if (!getFlagTagMode(flagModeOn, item, isLifecycleChange(item)).canBeEdited) return;

                newSelectedItems.delete(id);
                if (data && data.editedFields && data.editedFields.size > 0) {
                    if (newEditedItems.has(id)) {
                        const item = newEditedItems.get(id);
                        const updatedItem = {
                            ...item,
                            ...data,
                            editedFields: new Set([...item.editedFields, ...data.editedFields]),
                        };
                        newEditedItems.set(updatedItem.id, updatedItem);
                    } else {
                        const item = selectedItems.get(id);
                        const updatedItem = { ...item, ...data, editedFields: new Set([...data.editedFields]) };
                        newEditedItems.set(updatedItem.id, updatedItem);
                    }
                }
            });
            setEditedItems(newEditedItems);
            setIsBulkEditModalOpen(false);
            setSelectedItems(newSelectedItems);
        },
        [editedItems, flagModeOn, isLifecycleChange, selectedItems]
    );

    const handleModalDeleteButtonClick = useCallback(() => {
        // manually add the selected items to the deleted item list
        // and remove the selected items from the edited item list if found
        const newEditedItems = new Map(editedItems);
        const newDeletedItemIds = new Map(deletedItemIds);
        const newSelectedItems = new Map(selectedItems);
        if (!flagModeOn) {
            [...selectedItems].forEach(v => {
                const [id, item] = v;
                if (!getFlagTagMode(flagModeOn, item, isLifecycleChange(item)).canBeDisposed) return;

                newSelectedItems.delete(id);
                newEditedItems.delete(id);
                newDeletedItemIds.set(id, { id: id, dispositionTypeId: null });
            });
            setEditedItems(newEditedItems);
            setDeletedItemIds(newDeletedItemIds);
            setSelectedItems(newSelectedItems);
        } else {
            const flagsToDispose = [...selectedItems]
                .filter(v => getFlagTagMode(flagModeOn, v[1], isLifecycleChange(v[1])).canBeDisposed)
                .map(v => v[0]);
            setDisposingFlags(flagsToDispose);
            setDispositionModalOpen(true);
        }
        setIsBulkEditModalOpen(false);
    }, [editedItems, deletedItemIds, selectedItems, flagModeOn, isLifecycleChange]);

    const handleModalRestoreButtonClick = useCallback(() => {
        const newDeletedItemIds = new Map(deletedItemIds);
        const newRestoredItemIds = new Set(restoredItemIds);
        const newSelectedItems = new Map(selectedItems);
        [...selectedItems].forEach(v => {
            const [id, item] = v;
            if (!getFlagTagMode(flagModeOn, item, isLifecycleChange(item)).canBeRestored) return;

            newDeletedItemIds.delete(id);
            newSelectedItems.delete(id);
            newRestoredItemIds.add(id);
        });

        setDeletedItemIds(newDeletedItemIds);
        setRestoredItemIds(newRestoredItemIds);
        setSelectedItems(newSelectedItems);
        setIsBulkEditModalOpen(false);
    }, [deletedItemIds, restoredItemIds, selectedItems, flagModeOn, isLifecycleChange]);

    const handleModalUndoButtonClick = useCallback(() => {
        const newDeletedItemIds = new Map(deletedItemIds);
        const newRestoredItemIds = new Set(restoredItemIds);
        const newSelectedItems = new Map(selectedItems);
        [...selectedItems].forEach(v => {
            const [id, item] = v;
            if (!getFlagTagMode(flagModeOn, item, isLifecycleChange(item)).canBeUndo) return;

            newDeletedItemIds.delete(id);
            newSelectedItems.delete(id);
            newRestoredItemIds.delete(id);
        });

        setDeletedItemIds(newDeletedItemIds);
        setRestoredItemIds(newRestoredItemIds);
        setSelectedItems(newSelectedItems);
        setIsBulkEditModalOpen(false);
    }, [deletedItemIds, restoredItemIds, selectedItems, flagModeOn, isLifecycleChange]);

    const getFlagsOrTags = useCallback(
        async skip => {
            try {
                setLoading(true);

                // Add any sort options
                let sortOptions = [];
                sortedColumns.forEach(value => {
                    if (value.column.showInFlagMode === null || value.column.showInFlagMode === flagModeOn) {
                        sortOptions.push(
                            `${value.column.getSortOrderKey(flagModeOn)} ${value.direction === 'A' ? 'asc' : 'desc'}`
                        );
                    }
                });

                const params = {
                    skip: skip > -1 ? skip : items.length,
                    top: PAGE_SIZE,
                    modelIds: filterModelIds.current,
                    filter,
                    orderBy: sortOptions,
                };

                if (location.state && location.state.filters && !locationStateHandled.current) {
                    const { columnId, operator, inputValue } = location.state.filters[0];

                    const actionByColumn = { ...ACTIONS[columnId] };
                    const newSearchAction = {
                        ...DEFAULT_SEARCH_ACTIONS,
                        filterActions: [
                            {
                                id: 1,
                                action: {
                                    operatorId: operator,
                                    value: inputValue,
                                    generateFilterTerm: actionByColumn.generateFilterTerm,
                                },
                            },
                        ],
                    };
                    setFilter(getFilterQueryFromSearchActions(newSearchAction, flagModeOn, lifecycleFilter));
                    locationStateHandled.current = true;
                    return;
                }
                const { total, value } = flagModeOn
                    ? await getUntaggedFlagsByOemAsync_pagination(oemId, params)
                    : await getTagsByOemAsync_pagination(oemId, params);
                const nItems = value.length + items.length;

                // prettier-ignore
                const sanitizedValue = value.map(i => ({
                    ...i,
                    startPath: flagModeOn ?
                        (i.oneTimeUseFlagElement === null ? null : i.oneTimeUseFlagElement.contentStart) :
                        null,
                    endPath: flagModeOn ?
                        (i.oneTimeUseFlagElement === null ? null : i.oneTimeUseFlagElement.contentEnd) :
                        null,
                    id: flagModeOn ? i.oneTimeUseFlagId : i.stagedOneTimeUseTagId,
                    isFlag: flagModeOn,
                    oneTimeUsePartTypeId: flagModeOn
                        ? i.partTypeId
                        : i.oneTimeUsePartType
                            ? i.oneTimeUsePartType.oneTimeUsePartTypeId
                            : null,
                }));

                skip > -1 ? setItems(sanitizedValue) : setItems(prev => [...prev, ...sanitizedValue]);
                setHasMore(nItems < total);
                setTotal(total);
            } catch (error) {
                showToast(error);
                setHasMore(false);
            } finally {
                setLoading(false);
            }
        },
        [filter, flagModeOn, sortedColumns, items.length, oemId, showToast, lifecycleFilter, location.state]
    );

    const fetchMoreAsync = useCallback(() => {
        if (hasMore) {
            getFlagsOrTags();
        }
    }, [hasMore, getFlagsOrTags]);

    const submitModelFilterCallback = useCallback(
        modelIds => {
            filterModelIds.current = modelIds;
            getFlagsOrTags(0);
        },
        [getFlagsOrTags]
    );

    // toggle whether to use flags or tags, and reset
    const applyChangingMode = useCallback(() => {
        if (!loading) {
            const newMode = !flagModeOn;
            setSelectedItems(new Map());
            setFilter(getFilterQueryFromSearchActions(searchAction, newMode, lifecycleFilter));

            setEditedItems(new Map());
            setDeletedItemIds(new Map());
            setRestoredItemIds(new Set());
            setItems([]);
            setDisplayItems([]);
            setHasMore(true);
            setFlagModeOn(newMode);
            setSearchParams(searchParams => {
                searchParams.set(modeParamName, newMode == true ? modes.FlagMode : modes.TagMode);
                return searchParams;
            });
        }
    }, [flagModeOn, lifecycleFilter, loading, searchAction, setSearchParams]);

    const applyLifecycleFilter = useCallback(
        lifecycleFilter => {
            setSelectedItems(new Map());
            setFilter(getFilterQueryFromSearchActions(searchAction, flagModeOn, lifecycleFilter));

            setEditedItems(new Map());
            setDeletedItemIds(new Map());
            setRestoredItemIds(new Set());
            setItems([]);
            setDisplayItems([]);
            setHasMore(true);
        },
        [flagModeOn, searchAction]
    );

    const applySavingChangeAsync = useCallback(async () => {
        try {
            incrementLoading();

            let filtered = [];
            if (flagModeOn) {
                //check if any being dispose
                if (deletedItemIds.size > 0) {
                    let flagDisposition = [...deletedItemIds.values()].map(({ id, dispositionTypeId }) => ({
                        oneTimeUseFlagId: id,
                        oneTimeUseFlagDispositionId: dispositionTypeId,
                    }));
                    //request dispose
                    await requestUpdateFlagDisposition(flagDisposition);
                }

                if (editedItems.size > 0) {
                    const newTags = [...editedItems.values()].map(t => ({
                        ...t,
                        workFlowStatusId: TaggingWorkFlowStatusEnum.IN_PROGRESS.Id,
                        colorHex: getRandomTagColor(),
                    }));
                    await requestCreateOneTimeUseTag(newTags);
                }

                if (restoredItemIds.size > 0) {
                    const flagDisposition = [...restoredItemIds].map(id => ({
                        oneTimeUseFlagId: id,
                        oneTimeUseFlagDispositionId: null,
                    }));
                    //request restore
                    await requestUpdateFlagDisposition(flagDisposition);
                }

                //filter out edited and deleted from items in view
                filtered = items
                    .filter(i => {
                        return match(lifecycleFilter)
                            .with(LIFECYCLE_FILTER.All, () => !editedItems.has(i.id))
                            .with(
                                LIFECYCLE_FILTER.ActiveOnly,
                                () => !editedItems.has(i.id) && !deletedItemIds.has(i.id)
                            )
                            .with(LIFECYCLE_FILTER.DisposedOnly, () => !restoredItemIds.has(i.id))
                            .exhaustive();
                    })
                    .map(i => {
                        if (deletedItemIds.has(i.id)) {
                            return { ...i, tagDispositionId: deletedItemIds.get(i.id).dispositionTypeId };
                        }
                        if (restoredItemIds.has(i.id)) {
                            return { ...i, tagDispositionId: null };
                        }

                        return i;
                    });
            } else {
                let updates = [...editedItems.values()];

                //check if any being dispose
                if (deletedItemIds.size > 0) {
                    //find deleted items by delete ids and merge into updates setting isActive to false for the deletes
                    let deleting = items
                        .filter(i => deletedItemIds.has(i.id))
                        .map(item => {
                            return { ...item, isActive: false };
                        });

                    //mereg updates amd deletes for the update tag operation
                    updates = [...updates, ...deleting];
                }

                if (restoredItemIds.size > 0) {
                    const restoring = items
                        .filter(i => restoredItemIds.has(i.id))
                        .map(item => {
                            return { ...item, isActive: true };
                        });

                    updates = [...updates, ...restoring];
                }

                const { updatedStagedTagIds, orphanedStagedTagIds } = await requestUpdateOneTimeUseTag(updates);

                //filter out deleted from items in view and reset the editable state for edited items
                filtered = items
                    .filter(i => {
                        return match(lifecycleFilter)
                            .with(LIFECYCLE_FILTER.All, () => true)
                            .with(LIFECYCLE_FILTER.ActiveOnly, () => !deletedItemIds.has(i.id))
                            .with(LIFECYCLE_FILTER.DisposedOnly, () => !restoredItemIds.has(i.id))
                            .exhaustive();
                    })
                    .map(i => {
                        const key = i.id;
                        let ret = { ...i };
                        if (deletedItemIds.has(key)) {
                            ret.isActive = false;
                        }
                        if (restoredItemIds.has(key)) {
                            ret.isActive = true;
                        }
                        if (editedItems.has(key) && updatedStagedTagIds.includes(key)) {
                            ret = { ...editedItems.get(key), editedFields: new Set() };
                            if (i.title != ret.title && i.oneTimeUseTagLink) {
                                ret.oneTimeUseTagLink = null;
                                ret.linksChecked = false;
                            }
                        }
                        if (orphanedStagedTagIds.includes(key)) {
                            ret.oneTimeUseTagLink = null;
                            ret.linksChecked = false;
                        }
                        return ret;
                    });
            }

            // update the table
            setItems(filtered);
            //clear editing & deleting items on success
            setSelectedItems(new Map());
            setEditedItems(new Map());
            setDeletedItemIds(new Map());
            setRestoredItemIds(new Set());
        } catch (error) {
            showToast(error);
        } finally {
            decrementLoading();
        }
    }, [
        incrementLoading,
        flagModeOn,
        deletedItemIds,
        editedItems,
        restoredItemIds,
        items,
        lifecycleFilter,
        showToast,
        decrementLoading,
    ]);

    const applyPerformingSearch = useCallback(() => {
        setFilter(getFilterQueryFromSearchActions(searchAction, flagModeOn, lifecycleFilter));
        setHasMore(true);

        setEditedItems(new Map());
        setSelectedItems(new Map());
        setDeletedItemIds(new Map());
        setRestoredItemIds(new Set());
        setItems([]);
        setDisplayItems([]);
    }, [flagModeOn, searchAction, lifecycleFilter]);

    const applyColumnSort = useCallback(col => {
        setSortedColumns(cols => {
            const sorted = new Map(cols);

            if (sorted.has(col.propertyName) && sorted.get(col.propertyName).direction === 'D')
                sorted.delete(col.propertyName);
            else
                sorted.set(col.propertyName, {
                    key: col.propertyName,
                    direction: !sorted.has(col.propertyName) ? 'A' : 'D',
                    column: col,
                });
            return sorted;
        });
        setHasMore(true);
        setEditedItems(new Map());
        setDeletedItemIds(new Map());
        setRestoredItemIds(new Set());
        setItems([]);
        setDisplayItems([]);
    }, []);

    // modal handlers
    const handleWarningModalCancelButtonClick = useCallback(() => setIsWarningModalOpen(false), []);

    const handleWarningModalConfirmButtonClick = useCallback(async () => {
        switch (warningModalType) {
            case WARNING_MODAL_TYPE.APPLYING:
                await applySavingChangeAsync();
                break;
            case WARNING_MODAL_TYPE.SWITCHING:
                applyChangingMode();
                break;
            case WARNING_MODAL_TYPE.SEARCHING:
                applyPerformingSearch();
                break;
            case WARNING_MODAL_TYPE.SORTING:
                applyColumnSort(selectedSortColumn);
                break;
            case WARNING_MODAL_TYPE.LIFECYCLE_FILTER:
                warningModalCallback?.call();
                break;
            case WARNING_MODAL_TYPE.APPLY_RESET_FILTER_SORT:
                if (resetFilterDetail) {
                    applyResetAllSortsAndFilters(resetFilterDetail.resetType, resetFilterDetail.resetSearchToolFilter);
                }
                break;
            default:
                break;
        }
        setIsWarningModalOpen(false);
    }, [
        applyChangingMode,
        applyPerformingSearch,
        applySavingChangeAsync,
        applyColumnSort,
        warningModalCallback,
        warningModalType,
        selectedSortColumn,
        applyResetAllSortsAndFilters,
        resetFilterDetail,
    ]);

    const handleApplyButtonClick = useCallback(async () => {
        if (editedItems.size > 0 || deletedItemIds.size > 0 || restoredItemIds.size > 0) {
            setWarningModalType(WARNING_MODAL_TYPE.APPLYING);
            setIsWarningModalOpen(true);
        } else {
            await applySavingChangeAsync();
        }
    }, [applySavingChangeAsync, deletedItemIds.size, editedItems.size, restoredItemIds.size]);

    const handleFlagModeSwitchClick = useCallback(() => {
        if (editedItems.size > 0 || deletedItemIds.size > 0 || restoredItemIds.size > 0) {
            setWarningModalType(WARNING_MODAL_TYPE.SWITCHING);
            setIsWarningModalOpen(true);
        } else {
            applyChangingMode();
        }
    }, [applyChangingMode, deletedItemIds.size, editedItems.size, restoredItemIds.size]);

    const handleSetLifecycleFilter = useCallback(
        lifecycleFilter => {
            if (editedItems.size > 0 || deletedItemIds.size > 0 || restoredItemIds.size > 0) {
                setWarningModalCallback(() => () => {
                    setLifecycleFilter(lifecycleFilter);
                    applyLifecycleFilter(lifecycleFilter);
                });
                setWarningModalType(WARNING_MODAL_TYPE.LIFECYCLE_FILTER);
                setIsWarningModalOpen(true);
            } else {
                setLifecycleFilter(lifecycleFilter);
                applyLifecycleFilter(lifecycleFilter);
            }
        },
        [applyLifecycleFilter, deletedItemIds.size, editedItems.size, restoredItemIds.size]
    );

    const handleMasterCheckboxClick = useCallback(() => {
        const topItems = items.slice(0, 500);
        topItems.forEach(item => {
            if (!selectAllChecked) {
                selectItem(item);
            } else {
                deselectItem(item);
            }
        });
        setSelectAllChecked(!selectAllChecked);
    }, [selectAllChecked, items, selectItem, deselectItem]);

    const handleSearchButtonClick = useCallback(() => {
        if (editedItems.size > 0 || deletedItemIds.size > 0 || restoredItemIds.size > 0) {
            setWarningModalType(WARNING_MODAL_TYPE.SEARCHING);
            setIsWarningModalOpen(true);
        } else {
            applyPerformingSearch();
        }
    }, [applyPerformingSearch, deletedItemIds.size, editedItems.size, restoredItemIds.size]);

    const handleSortColumnClick = useCallback(
        col => {
            /**
             * Only allow users to sort the table only if the data is already loaded.
             * Otherwise, there is a bug in the UI where the headers show the data is sorted
             * but the actual data is not.
             */
            if (!loading) {
                // disabled for regionName and bookName as they can have multiple items
                if (
                    col.propertyName !== 'bookName' &&
                    col.propertyName !== 'regionName'
                    //&& (!flagModeOn || (flagModeOn && col.propertyName !== TABLE_COLUMNS.find(c => c.filterOption.key === TABLE_COLUMN_ENUM.STATUS).propertyName)) // uncomment if we want status to not sortable on flag mode
                ) {
                    // empty the selected item list
                    setSelectedItems(new Map());
                    // store this so the column sort can use it
                    setSelectedSortColumn(col);
                    if (editedItems.size > 0 || deletedItemIds.size > 0 || restoredItemIds.size > 0) {
                        // warn before sorting if edits exist
                        setWarningModalType(WARNING_MODAL_TYPE.SORTING);
                        setIsWarningModalOpen(true);
                    } else {
                        applyColumnSort(col);
                    }
                }
            }
        },
        [applyColumnSort, deletedItemIds.size, editedItems.size, loading, restoredItemIds.size]
    );

    const handleResetSortAndFilter = useCallback(
        ({ resetType, resetSearchToolFilter }) => {
            if (editedItems.size > 0 || deletedItemIds.size > 0 || restoredItemIds.size > 0) {
                setWarningModalType(WARNING_MODAL_TYPE.APPLY_RESET_FILTER_SORT);
                setIsWarningModalOpen(true);
                setResetFilterDetail({ resetType, resetSearchToolFilter });
            } else {
                applyResetAllSortsAndFilters(resetType, resetSearchToolFilter);
            }
        },
        [editedItems, deletedItemIds, restoredItemIds, applyResetAllSortsAndFilters]
    );

    const applyResetAllSortsAndFilters = useCallback(
        (resetType, resetSearchToolFilter) => {
            switch (resetType) {
                case CLEAR_FILTER_AND_SORT.AllFilters:
                    removeAllFilters();
                    resetSearchToolFilter();
                    break;
                case CLEAR_FILTER_AND_SORT.AllSorts:
                    removeAllSorts();
                    break;
                case CLEAR_FILTER_AND_SORT.Both:
                    removeAllSortsAndFilters();
                    resetSearchToolFilter();
                    break;
                default:
                    break;
            }
        },
        [removeAllFilters, removeAllSorts, removeAllSortsAndFilters]
    );

    const removeAllSorts = useCallback(() => {
        setSortedColumns(new Map());
        resetBulkEditTable();
    }, [resetBulkEditTable]);

    const removeAllFilters = useCallback(() => {
        searchActionDispatch({ type: SEARCH_ACTION_TYPE_ENUM.RESET });
        setFilter(getFilterQueryFromSearchActions(DEFAULT_SEARCH_ACTIONS, flagModeOn, lifecycleFilter));
        resetBulkEditTable();
    }, [lifecycleFilter, flagModeOn, resetBulkEditTable]);

    const removeAllSortsAndFilters = useCallback(() => {
        setSortedColumns(new Map());
        searchActionDispatch({ type: SEARCH_ACTION_TYPE_ENUM.RESET });
        setFilter(getFilterQueryFromSearchActions(DEFAULT_SEARCH_ACTIONS, flagModeOn, lifecycleFilter));
        resetBulkEditTable();
    }, [flagModeOn, lifecycleFilter, resetBulkEditTable]);

    const resetBulkEditTable = useCallback(() => {
        setHasMore(true);
        setEditedItems(new Map());
        setSelectedItems(new Map());
        setDeletedItemIds(new Map());
        setRestoredItemIds(new Set());
        setItems([]);
        setDisplayItems([]);
    }, []);

    const handleBulkEditButtonClick = useCallback(() => {
        setIsBulkEditModalOpen(true);
    }, []);

    /* Handle bulk checkbox selection with shift key */
    // ---------------------------------------------------------------------------
    const handleItemCheckboxClick = useCallback(
        item => {
            setSelectedItems(prev => {
                const newSelectedItems = new Map(prev);
                const key = item.id;
                if (newSelectedItems.has(key)) {
                    newSelectedItems.delete(key);
                    if (bulkCheckboxSelectionRef.current) {
                        bulkCheckboxSelectionRef.current.isAdding = false;
                    }
                } else {
                    if (
                        bulkCheckboxSelectionRef.current &&
                        bulkCheckboxSelectionRef.current.isAdding &&
                        bulkCheckboxSelectionRef.current.isShiftKeyDown &&
                        newSelectedItems.size > 0
                    ) {
                        // start of bulk checkbox selection
                        const lastItemIndex = items.findIndex(
                            i => i.id === [...newSelectedItems.keys()][newSelectedItems.size - 1]
                        );
                        const currentItemIndex = items.findIndex(i => i.id === key);
                        if (lastItemIndex === -1 || currentItemIndex === -1) {
                            throw new Error('Cannot find selected item index');
                        }

                        const from = lastItemIndex > currentItemIndex ? currentItemIndex : lastItemIndex;
                        const to = currentItemIndex + lastItemIndex - from;
                        items.slice(from, to + 1).forEach(i => {
                            const key = i.id;
                            if (!deletedItemIds.has(key)) {
                                newSelectedItems.set(key, i);
                            }
                        });
                        // end of bulk checkbox selection
                        bulkCheckboxSelectionRef.current.isAdding = false;
                    } else {
                        newSelectedItems.set(key, item);
                        bulkCheckboxSelectionRef.current.isAdding = true;
                    }
                }
                return newSelectedItems;
            });
        },
        [items, deletedItemIds]
    );

    const handleShiftButtonKeyDown = useCallback(() => {
        if (bulkCheckboxSelectionRef.current && bulkCheckboxSelectionRef.current.isShiftKeyDown === false) {
            bulkCheckboxSelectionRef.current.isShiftKeyDown = true;
        }
    }, []);

    const handleShiftButtonKeyUp = useCallback(() => {
        if (bulkCheckboxSelectionRef.current && bulkCheckboxSelectionRef.current.isShiftKeyDown === true) {
            bulkCheckboxSelectionRef.current.isShiftKeyDown = false;
        }
    }, []);
    // ---------------------------------------------------------------------------

    /* Handle disposition modal actions */
    // ---------------------------------------------------------------------------
    // #region HANDLE_DISPOSITION_MODAL_ACTIONS

    //used to add dispose flag or inactivate tag inlne table row button click
    const handleOnDisposeItem = useCallback(
        (item, isDisposing) => {
            const key = item.id;
            if (!isDisposing) {
                if (!getFlagTagMode(flagModeOn, item, isLifecycleChange(item)).canBeUndo) return;

                // to reactivate an item, just remove it from the deleted item list
                setDeletedItemIds(prev => {
                    const cpy = new Map(prev);
                    if (cpy.has(key)) {
                        cpy.delete(key);
                    }
                    return cpy;
                });
            } else {
                if (!getFlagTagMode(flagModeOn, item, isLifecycleChange(item)).canBeDisposed) return;

                if (!flagModeOn) {
                    //toggle the deleted item id in collection
                    setDeletedItemIds(prev => {
                        const newDisposingIems = new Map(prev);
                        newDisposingIems.set(key, { id: key, dispositionTypeId: null });
                        return newDisposingIems;
                    });

                    //make sure the disposed is no longer included as selected or edited item
                    setEditedItems(prev => {
                        const newEditedItems = new Map(prev);
                        if (newEditedItems.has(key)) {
                            newEditedItems.delete(key);
                        }
                        return newEditedItems;
                    });

                    setSelectedItems(prev => {
                        const newSelectedItems = new Map(prev);
                        if (newSelectedItems.has(key)) {
                            newSelectedItems.delete(key);
                        }
                        return newSelectedItems;
                    });
                } else {
                    setDisposingFlags([key]);
                    setDispositionModalOpen(true);
                }
            }
        },
        [flagModeOn, isLifecycleChange]
    );

    const handleRestoreItem = useCallback(
        (item, isRestore) => {
            const id = item.id;
            if (!isRestore) {
                if (!getFlagTagMode(flagModeOn, item, isLifecycleChange(item)).canBeUndo) return;

                setRestoredItemIds(prev => {
                    const newRestoredItemIds = new Set(prev);
                    newRestoredItemIds.delete(id);
                    return newRestoredItemIds;
                });
            } else {
                if (!getFlagTagMode(flagModeOn, item, isLifecycleChange(item)).canBeRestored) return;

                setRestoredItemIds(prev => {
                    const newRestoredItemIds = new Set(prev);
                    newRestoredItemIds.add(id);
                    return newRestoredItemIds;
                });

                setSelectedItems(prev => {
                    const newSelectedItems = new Map(prev);
                    newSelectedItems.delete(id);
                    return newSelectedItems;
                });
            }
        },
        [flagModeOn, isLifecycleChange]
    );

    const handleDispositionModalToggle = useCallback(() => {
        setDispositionSelection('');
        setDisposingFlags([]);
        setDispositionModalOpen(prev => !prev);
    }, []);

    const handleDispositionModalValueChange = useCallback(value => setDispositionSelection(value), []);

    const handleDispositionSaveButtonClick = useCallback(
        dispositionTypeId => {
            const newEditedItems = new Map(editedItems);
            const newDeletedItemIds = new Map(deletedItemIds);
            const newSelectedItems = new Map(selectedItems);
            disposingFlags.forEach(id => {
                newEditedItems.delete(id);
                newSelectedItems.delete(id);
                newDeletedItemIds.set(id, { id: id, dispositionTypeId: dispositionTypeId });
            });
            setDeletedItemIds(newDeletedItemIds);
            setEditedItems(newEditedItems);
            setSelectedItems(newSelectedItems);
            setDispositionSelection('');
            setDisposingFlags([]);
            setDispositionModalOpen(false);
        },
        [deletedItemIds, editedItems, selectedItems, disposingFlags]
    );

    // #endregion
    // ---------------------------------------------------------------------------

    return {
        loading,
        hasMore,
        updateItem,
        deletedItemIds,
        restoredItemIds,
        handleApplyButtonClick,
        fetchMoreAsync,
        modalProcedureId,
        setModalProcedureId,
        selectedItems,
        handleItemCheckboxClick,
        isBulkEditModalOpen,
        handleModalCloseButtonClick,
        handleModalSaveButtonClick,
        handleModalDeleteButtonClick,
        handleModalRestoreButtonClick,
        handleModalUndoButtonClick,
        handleOnDisposeItem,
        handleRestoreItem,
        items: displayItems,
        editedItems,
        flagModeOn,
        groups,
        types,
        handleSearchButtonClick,
        handleSearchActionChange: searchActionDispatch,
        noGroupChecked: searchAction.checkboxes.NoGroupCheckbox,
        noTypeChecked: searchAction.checkboxes.NoTypeCheckbox,
        refreshedPendingChecked: searchAction.checkboxes.RefreshedPendingCheckbox,
        lifecycleFilter,
        handleSetLifecycleFilter,
        selectAllChecked,
        handleMasterCheckboxClick,
        isWarningModalOpen,
        warningModalType,
        handleWarningModalCancelButtonClick,
        handleWarningModalConfirmButtonClick,
        handleFlagModeSwitchClick,
        handleBulkEditButtonClick,
        sortedColumns,
        handleSortColumnClick,
        total,
        onNeedsAttentionClick,
        handleShiftButtonKeyDown,
        handleShiftButtonKeyUp,
        dispositionModalOpen,
        dispositionSelection,
        handleDispositionModalToggle,
        handleDispositionModalValueChange,
        handleDispositionSaveButtonClick,
        submitModelFilterCallback,
        modelsOptions,
        filterModelIds,
        isLifecycleChange,
        handleResetSortAndFilter,
    };
};

export default useBulkEdit;
