import React, { FC } from 'react';
import Icon from 'components/Shared/Elements/Icon';
import { ButtonCellOptions, CellProps } from '../types';
import calculateClass from './Helpers/calculateClass';

const Buttons: (options?: ButtonCellOptions) => FC<CellProps> = options => {
    const ButtonsComponent = ({ idKey, item, header, index, cellEventCallback }: CellProps) => {
        return (
            <td headers={header.id} className={calculateClass(options, item)}>
                {options.buttons.map(
                    (b, i) =>
                        (!b.show || b.show(item)) && (
                            <button
                                key={i}
                                title={b.title}
                                id={`${b.id}${index}`}
                                className={`btn btn-sm me-2 ${b.class}`}
                                onClick={() =>
                                    cellEventCallback({
                                        id: item[idKey] as string,
                                        actionId: b.id,
                                        index,
                                        item,
                                    })
                                }
                                disabled={b.disabled && b.disabled(item)}>
                                {b.component
                                    ? b.component(item)
                                    : (b.text && <span className="me-2">{b.text}</span>) ||
                                      (b.icon && <Icon icon={b.icon} />)}
                            </button>
                        )
                )}
            </td>
        );
    };
    return ButtonsComponent;
};

export default Buttons;
