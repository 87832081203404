import { BookPublishState, RefreshPublisherBookStatisticsType } from 'types/RefreshPublisherBookStatisticsType';
import { PublishStatus, RefreshPublisherDataRowType, RefreshPublisherPagination } from './types';
import { ItemReturnEvent } from 'components/Shared/Table/types';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { NotificationsContext } from 'components/Shared/Notifications/Notifications';
import useRpBooks from 'hooks/useRpBooks';
import { requestPublishBooksAndRemovePendingProcedures, requestRefreshPublisherBook } from 'api/RepairProcedureApi';
import { escapeRegExp } from 'lodash';
import useRefreshPublisherParallelOptions from './useRefreshPublisherParallelOptions';

interface RpBookStatsType extends RefreshPublisherBookStatisticsType {
    loadedAt: string;
}

const useRefreshPublisher = (oemId: number) => {
    const { notifications } = useContext(NotificationsContext);
    const { rpBooks, isLoading: bookLoading } = useRpBooks(oemId, notifications.pushExceptionDanger);
    const { options: parallelOptions } = useRefreshPublisherParallelOptions(oemId);

    const [rpBookStats, setRpBookStats] = useState<Map<number, RpBookStatsType>>(new Map());
    const [statsLoading, setStatsLoading] = useState(true);
    const [pagination, setPagination] = useState<RefreshPublisherPagination>({
        currentPageNumber: 1,
        itemsPerPage: 0,
    });
    const [loadBookStatsPercentage, setLoadBookStatsPercentage] = useState(0);
    const [selected, setSelected] = useState([]); // I gave up on figuring out the type of this one
    const [searchValue, setSearchValue] = useState('');
    const [isPublishing, setIsPublishing] = useState(false);
    const [bulkPublishStatus, setBulkPublishStatus] = useState<PublishStatus | null>(null);
    const [failedToLoadStatsBookId, setFailedToLoadStatsBookIds] = useState<Set<number>>(new Set());

    const reloadStatsForBooks = useCallback((bookIds: number[]) => {
        setFailedToLoadStatsBookIds(prev => {
            const cpy = [...prev.keys()].filter(id => !bookIds.includes(id));
            return new Set(cpy);
        });
        setRpBookStats(prev => {
            const cpy = new Map(prev);
            bookIds.forEach(bookId => {
                cpy.delete(bookId);
            });
            return cpy;
        });
    }, []);

    const publishRpBooks = useCallback(
        async (bookIds: number[]) => {
            try {
                setIsPublishing(true);
                setRpBookStats(prev => {
                    const cpy = new Map(prev);
                    bookIds.forEach(id => {
                        if (cpy.has(id)) {
                            cpy.set(id, { ...cpy.get(id), bookPublishState: BookPublishState.Publising });
                        }
                    });
                    return cpy;
                });

                const succeeded: number[] = [];
                const failed: number[] = [];

                for (let i = 0; i < bookIds.length; i += parallelOptions.publishBatchSize) {
                    const ids = bookIds.slice(i, i + parallelOptions.publishBatchSize);
                    try {
                        await requestPublishBooksAndRemovePendingProcedures(ids);
                        succeeded.push(...ids);
                        setBulkPublishStatus({
                            current: succeeded.length,
                            percentage: Math.min(((i + ids.length) / bookIds.length) * 100, 100),
                        });
                    } catch (error) {
                        failed.push(...ids);
                    }
                }
                if (failed.length === 0) {
                    notifications.pushSuccess('Selected books were succesfully published');
                } else {
                    setRpBookStats(prev => {
                        const cpy = new Map(prev);
                        failed.forEach(id => {
                            if (cpy.has(id)) {
                                const stats = cpy.get(id);
                                cpy.set(id, { ...stats, bookPublishState: BookPublishState.FailedToPublish });
                            }
                        });
                        return cpy;
                    });
                    notifications.pushExceptionDanger(
                        new Error(`Failed to publish ${failed.length} book${failed.length === 1 ? '' : 's'}`)
                    );
                }

                reloadStatsForBooks(succeeded);
            } catch (error) {
                notifications.pushExceptionDanger(error);
            } finally {
                setBulkPublishStatus(null);
                setIsPublishing(false);
            }
        },
        [notifications, reloadStatsForBooks, parallelOptions]
    );

    const handleBulkPublishButtonClick = useCallback(async () => {
        if (selected && selected.length) {
            await publishRpBooks(selected.map(({ id }: ItemReturnEvent) => parseInt(id)));
        }
        setSelected([]);
    }, [selected, publishRpBooks]);

    const booksSearched = useMemo(() => {
        return searchValue
            ? rpBooks.filter(b =>
                  b.generationFriendlyName
                      ? new RegExp(escapeRegExp(searchValue), 'i').test(b.generationFriendlyName)
                      : new RegExp(escapeRegExp(searchValue), 'i').test(b.bookName)
              )
            : rpBooks;
    }, [rpBooks, searchValue]);

    const totalPage = pagination.itemsPerPage ? Math.ceil(booksSearched.length / pagination.itemsPerPage) : 1;

    const handleCurrentPageNumberChange = (value: number) => {
        const currentPage = value < 1 ? 1 : value > totalPage ? totalPage : value;
        setPagination(prev => ({ ...prev, currentPageNumber: currentPage }));
        setSelected([]);
    };

    const handleItemsPerPageChange = (value: number) => {
        setPagination({ currentPageNumber: 1, itemsPerPage: value });
        setSelected([]);
    };

    const handleSearchChange = (value: string) => {
        setPagination(prev => ({ ...prev, currentPageNumber: 1 }));
        setSearchValue(value);
        setSelected([]);
    };

    const bookIdsToShow = useMemo(() => {
        const itemsPerPage = pagination.itemsPerPage || rpBooks.length;
        return booksSearched
            .map(b => b.bookId)
            .slice((pagination.currentPageNumber - 1) * itemsPerPage, pagination.currentPageNumber * itemsPerPage);
    }, [booksSearched, pagination, rpBooks.length]);

    useEffect(() => {
        let isUnmounted = false;

        (async () => {
            const toFetch = [...bookIdsToShow.filter(id => !failedToLoadStatsBookId.has(id) && !rpBookStats.has(id))];
            try {
                if (toFetch.length && bookIdsToShow.length && !isUnmounted) {
                    setStatsLoading(true);
                    const ids = toFetch.slice(0, parallelOptions.loadStatsBatchSize);
                    const results = await Promise.allSettled(
                        ids.map<Promise<RefreshPublisherBookStatisticsType>>(id =>
                            requestRefreshPublisherBook(oemId, id)
                        )
                    );

                    const failedToLoadIds: number[] = [];
                    const booksWithStats: Array<[number, RefreshPublisherBookStatisticsType]> = [];
                    for (let i = 0; i < ids.length; ++i) {
                        const id = ids[i];
                        const result = results[i];

                        if (result.status === 'rejected') {
                            failedToLoadIds.push(id);
                            notifications.pushExceptionDanger(
                                new Error(`Failed to load stats for book ${id}. Please reload it manually after.`)
                            );
                        } else {
                            booksWithStats.push([id, result.value]);
                        }
                    }

                    if (!isUnmounted) {
                        if (failedToLoadIds.length) {
                            setFailedToLoadStatsBookIds(prev => {
                                const cpy = [...prev.keys(), ...failedToLoadIds];
                                return new Set(cpy);
                            });
                        }
                        if (booksWithStats.length) {
                            setRpBookStats(prev => {
                                const cpy = new Map(prev);
                                booksWithStats.forEach(([id, stats]) => {
                                    cpy.set(id, { ...stats, loadedAt: new Date().toLocaleTimeString() });
                                });
                                return cpy;
                            });
                        }

                        const remaining = toFetch.length - booksWithStats.length;
                        const total = bookIdsToShow.length - failedToLoadStatsBookId.size;
                        setLoadBookStatsPercentage((1 - remaining / total) * 100.0);
                    }
                }
            } catch (error) {
                notifications.pushExceptionDanger(error);
            } finally {
                if (!isUnmounted) {
                    const done = toFetch.length <= parallelOptions.loadStatsBatchSize;
                    if (done) {
                        setLoadBookStatsPercentage(100);
                        setStatsLoading(false);
                    }
                }
            }
        })();

        return () => {
            isUnmounted = true;
        };
    }, [oemId, bookIdsToShow, rpBookStats, failedToLoadStatsBookId, notifications, parallelOptions]);

    const dataToShow: RefreshPublisherDataRowType[] =
        bookLoading || statsLoading || isPublishing
            ? []
            : bookIdsToShow
                  .map(id => {
                      const book = rpBooks.find(b => b.bookId === id);
                      const stats = rpBookStats.get(id);

                      if (!book && !stats) {
                          // shouldn't get here
                          return undefined;
                      } else if (!stats) {
                          return { ...book };
                      } else {
                          return {
                              ...book,
                              removedProcedures: stats.removalPending,
                              mappingStatus: `${stats.completedMappedProcedures}/${stats.totalProcedures}`,
                              totalProcedures: stats.totalProcedures,
                              totalMapped: stats.completedMappedProcedures,
                              totalLatestToPublish: stats.refreshedProcedures,
                              totalRemovedPending: stats.removalPending,
                              totalStageAreaChanges: stats.stageAreaChanges,
                              totalHtmlLocationNull: stats.htmlLocationNull,
                              totalOnlyHotSheetMapped: stats.onlyHotSheetMapped,
                              unresolvedFlags: stats.unresolvedFlags,
                              unresolvedTags: stats.unresolvedTags,
                              bookPublishState: stats.bookPublishState, // to control the publish button
                              lastPublishDate: stats.lastPublishDate,
                              loadedAt: stats.loadedAt,
                          };
                      }
                  })
                  .filter(b => !!b);

    return {
        bookLoading: bookLoading,
        statsLoading: statsLoading,
        isPublishing: isPublishing,
        searchValue: searchValue,
        handleSearchChange,
        pagination,
        handleCurrentPageNumberChange,
        totalPage,
        handleItemsPerPageChange,
        loadBookStatsPercentage,
        bulkPublishStatus,
        selected,
        setSelected,
        publishRpBooks,
        reloadStatsForBooks,
        dataToShow,
        handleBulkPublishButtonClick,
        totalBooks: rpBooks.length,
    };
};

export default useRefreshPublisher;
