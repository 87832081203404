import React, { useMemo } from 'react';
import { TableContext } from './Contextes';
import { isReactElement } from './utils';
import { NewDataTableProps } from './types';
import { NewDataTableBody, NewDataTableRow } from './NewDataTableBody';
import { NewDataTableHeader } from './NewDataTableHeader';
import { NewDataTableFooter } from './NewDataTableFooter';

export const NewDataTable = <T,>({
    children,
    data,
    getKey,
    orderedColumns,
    columnSettings,
    tableClassName,
}: NewDataTableProps<T>) => {
    const { row, header, footer } = useMemo(() => {
        let row = null;
        let header = null;
        let footer = null;

        React.Children.forEach(children, child => {
            if (isReactElement(child)) {
                if (child.type === NewDataTableRow) {
                    row = child;
                }

                if (child.type === NewDataTableHeader) {
                    header = child;
                }

                if (child.type === NewDataTableFooter) {
                    footer = child;
                }
            }
        });

        return { row, header, footer };
    }, [children]);

    const contextValue = useMemo(() => ({ orderedColumns, columnSettings }), [columnSettings, orderedColumns]);

    return (
        <table className={tableClassName}>
            <TableContext.Provider value={contextValue}>
                {header}
                <NewDataTableBody data={data} getKey={getKey}>
                    {row}
                </NewDataTableBody>
                {footer}
            </TableContext.Provider>
        </table>
    );
};
