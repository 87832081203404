import { useState, useEffect } from 'react';

const useSearch = searchOptions => {
    const [localSearchValue, setLocalSearchValue] = useState('');
    const [searchValueSelections, setSearchValueSelections] = useState([]);

    useEffect(() => {
        setSearchValueSelections(searchOptions.map(opt => opt.value));
    }, [searchOptions]);

    const handleSearchKeyPress = event => {
        if (event.key === 'Enter') event.currentTarget.blur();
    };

    const handleOptionsSelectChange = e => {
        setSearchValueSelections(e.map(v => v.value));
    };

    return {
        localSearchValue,
        setLocalSearchValue,
        searchValueSelections,
        handleSearchKeyPress,
        handleOptionsSelectChange,
    };
};

export default useSearch;
