import React from 'react';
import { PrimaryButton } from 'oemiq-common';
import SmallSpinner from 'components/SmallSpinner';

const BulkEditFooter = ({
    selectedItems,
    handleBulkEditButtonClick,
    editedItems,
    deletedItemIds,
    restoredItemIds,
    handleApplyButtonClick,
    items,
    total,
    loading,
}) => {
    return (
        <div
            id="tagger-bulk-edit-footer"
            className="py-2 border-top"
            style={{ backgroundColor: 'white', position: 'sticky', bottom: '0' }}>
            <div className="d-flex justify-content-between align-items-center px-3">
                <div className="selected-items-count">
                    {loading ? (
                        <SmallSpinner text="Loading" />
                    ) : (
                        `${items.length} of ${total} (${selectedItems.size} selected)`
                    )}
                </div>
                <div className="d-flex">
                    <div className="pe-2">
                        <PrimaryButton
                            disabled={selectedItems.size === 0}
                            id="oem-tagger-bulk-edit-button"
                            type="button"
                            onClick={handleBulkEditButtonClick}>
                            Bulk Edit
                        </PrimaryButton>
                    </div>
                    <div>
                        <PrimaryButton
                            id="oem-tagger-apply-button"
                            disabled={editedItems.size === 0 && deletedItemIds.size === 0 && restoredItemIds.size === 0}
                            type="button"
                            onClick={handleApplyButtonClick}>
                            Apply
                        </PrimaryButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BulkEditFooter;
