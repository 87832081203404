import React, { FC } from 'react';
import { CellProps, TextCellOptions } from './types';
import calculateClass from 'components/Shared/Table/Cells/Helpers/calculateClass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const PublishExceptionText: (options?: TextCellOptions) => FC<CellProps> = options => {
    const PublishExceptionTextComponent: FC<CellProps> = ({ value, header, item, index }) => {
        const icon: IconProp = 'check';
        return (
            <td
                headers={header.id}
                className={calculateClass(options, item) + ' book-label'}
                id={`${header.id}${index}`}
                title={header.title}>
                {options?.transform ? options.transform(item) : value}
                {item.published && <FontAwesomeIcon className={'vehicle-published'} icon={icon} />}
            </td>
        );
    };

    return PublishExceptionTextComponent;
};

export default PublishExceptionText;
