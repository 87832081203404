import { useParams } from 'react-router-dom';
import Breadcrumb from './items/Breadcrumb';
import kebab2Camel from './utils/kebab2Camel';
import OemNameBreadcrumb from 'components/Navigation/items/OemNameBreadcrumb';
import ModelNameBreadcrumb from './items/ModelNameBreadcrumb';

const VehicleProcessNavigation = () => {
    const { vehicleTool, oemId, modelId } = useParams();

    return (
        <ol className="breadcrumb">
            <Breadcrumb isShown={true} isCurrent={!vehicleTool} link={'/vehicle-process'} text={'Vehicle Process'} />
            {vehicleTool && (
                <Breadcrumb
                    isShown={true}
                    isCurrent={!oemId}
                    link={`/vehicle-process/${vehicleTool}`}
                    text={Tools[kebab2Camel(vehicleTool)]}
                />
            )}
            {vehicleTool && oemId && (
                <OemNameBreadcrumb
                    oemId={oemId}
                    isCurrent={!modelId}
                    link={`/vehicle-process/${vehicleTool}/${oemId}`}
                    withImportJob={false}
                />
            )}
            {vehicleTool && oemId && modelId && (
                <ModelNameBreadcrumb
                    oemId={oemId}
                    modelId={modelId}
                    isCurrent={true}
                    baseUrl={`/vehicle-process/${vehicleTool}`}
                />
            )}
        </ol>
    );
};

const Tools = {
    publisher: 'Publisher',
    vehicleManagement: 'Vehicle Management',
    test3dModel: 'Test 3D Model',
};

export default VehicleProcessNavigation;
