import React, { useCallback, useState } from 'react';
import ReactSelect from 'react-select';

export enum MappingStatusListOption {
    InReview,
    Complete,
    NeedHelp,
}

export const mappingStatusList = [
    { value: MappingStatusListOption.InReview, label: 'In Review' },
    { value: MappingStatusListOption.Complete, label: 'Complete' },
    { value: MappingStatusListOption.NeedHelp, label: 'Need Help' },
];

type MappingStatusListProps = {
    filter;
    setFilterValue;
};

export const MappingStatusList = ({ filter, setFilterValue }: MappingStatusListProps) => {
    const [selectValue, setSelectValue] = useState<{ value: number; label: string }>(
        (filter.value && filter.value[0]) || {}
    );

    const handleSelect = useCallback(
        v => {
            setSelectValue(v);
            setFilterValue({
                value: [v],
                valueList: [v.label],
            });
        },
        [setFilterValue]
    );

    return (
        <ReactSelect
            className="mb-2 basic-single"
            classNamePrefix="select"
            placeholder="Choose value"
            options={mappingStatusList}
            isDisabled={!filter.id || !filter.operator}
            value={selectValue}
            onChange={handleSelect}
        />
    );
};

const operatorsList = {
    eq: {
        value: 'mappingStatusEq',
        label: '==',
    },
    ne: {
        value: 'mappingStatusNe',
        label: '!=',
    },
};

export default {
    component: MappingStatusList,
    operators: [operatorsList.eq, operatorsList.ne],
    defaultValue: '',
    allowFalse: false,
    allowInstances: 1,
};
