import FlaggerTableRow from './FlaggerToolTableRow';

const FlaggerTable = ({
    oemId,
    flagTerms,
    navigateToTagger,
    getPartTypeLabel,
    runFlagTerm,
    undoFlagTerm,
    deleteFlagTerm,
    activateFlagTerm,
    getNumberOfFlags,
}) => {
    return (
        <table className="table">
            <thead>
                <tr>
                    <th scope="col">FlagTermId</th>
                    <th scope="col">FlagTerm</th>
                    <th scope="col">Number of Flags</th>
                    <th scope="col">Part Type</th>
                    <th scope="col">Run</th>
                </tr>
            </thead>
            <tbody>
                {flagTerms.map(term => (
                    <FlaggerTableRow
                        key={term.id}
                        oemId={oemId}
                        term={term}
                        navigateToTagger={navigateToTagger}
                        getPartTypeLabel={getPartTypeLabel}
                        runFlagTerm={runFlagTerm}
                        undoFlagTerm={undoFlagTerm}
                        deleteFlagTerm={deleteFlagTerm}
                        activateFlagTerm={activateFlagTerm}
                        getNumberOfFlags={getNumberOfFlags}></FlaggerTableRow>
                ))}
            </tbody>
        </table>
    );
};

export default FlaggerTable;
