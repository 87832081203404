import { LocalDate, Number } from 'components/Shared/Table/Cells';
import { useMemo } from 'react';
import MappingStatus from './CustomCells/MappingStatus';
import usePublishCell from './CustomCells/usePublish';
import RefreshCell from './CustomCells/RefreshCell';
import { RefreshPublisherTableActionTypes } from './types';

const useTableConfiguration = () => {
    const Publish = usePublishCell({
        actionId: RefreshPublisherTableActionTypes.PublishBook,
    });
    const MappingStatusComponent = MappingStatus();
    const conf = useMemo(
        () => [
            {
                label: 'Book',
                id: 'bookName',
            },
            {
                label: 'Mapping Status',
                id: 'mappingStatus',
                thClass: 'text-center',
                component: MappingStatusComponent,
            },
            {
                label: 'Unresolved Flags',
                id: 'unresolvedFlags',
                thClass: 'text-center',
                component: Number({
                    highlightFn: i => (i.unresolvedFlags as number) > 0,
                }),
            },
            {
                label: 'Unresolved Tags',
                id: 'unresolvedTags',
                title: 'Sum of total `in review` and `in progress` tags',
                thClass: 'text-center',
                component: Number({
                    highlightFn: i => (i.unresolvedTags as number) > 0,
                }),
            },
            {
                label: 'Refreshed Procedures',
                id: 'totalLatestToPublish',
                thClass: 'text-center',
                component: Number({
                    highlightFn: i => (i.totalLatestToPublish as number) > 0,
                }),
            },
            {
                label: 'Removal Pending',
                id: 'totalRemovedPending',
                thClass: 'text-center',
                component: Number({
                    highlightFn: i => (i.totalRemovedPending as number) > 0,
                }),
            },
            {
                label: 'RI Changed Procedures',
                id: 'totalStageAreaChanges',
                thClass: 'text-center',
                component: Number({
                    highlightFn: i => (i.totalStageAreaChanges as number) > 0,
                }),
            },
            {
                label: 'Html Location Null',
                id: 'totalHtmlLocationNull',
                thClass: 'text-center',
                component: Number({
                    highlightFn: i => (i.totalHtmlLocationNull as number) > 0,
                }),
            },
            {
                label: 'Only HotSheet Mapped',
                id: 'totalOnlyHotSheetMapped',
                thClass: 'text-center',
                component: Number({
                    highlightFn: i => (i.totalOnlyHotSheetMapped as number) > 0,
                }),
            },
            {
                label: 'Last Publish Date',
                id: 'lastPublishDate',
                title: 'Last publication date of changes to procedures in this book \n(publication of shared procedures may affect other books publish dates)',
                component: LocalDate(),
            },
            {
                label: 'Action',
                id: 'actions',
                component: Publish,
            },
            {
                label: 'Stats Loaded At',
                id: 'reload',
                component: RefreshCell,
            },
        ],
        [MappingStatusComponent, Publish]
    );
    return conf;
};

export default useTableConfiguration;
