import { fetchWithAuthHeader } from './AuthUtils';

const repairProcedureApi = 'api/RepairProcedure/';
export const requestFlagTerms = async oemId => {
    const url = repairProcedureApi + `odata/OneTimeUseFlagTerms?api-version=4.0&$filter=oemId eq ${oemId}`;
    let response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error('Could not retrieve flag terms');
    return (await response.json()).value;
};

export const requestCreateFlagTerm = async (oemId, params) => {
    const url = repairProcedureApi + `Flagger/${oemId}/FlagTerms`;
    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
    });
    if (!response.ok) throw new Error('Failed to create flag term');

    return await response.json();
};

export const requestRunFlagTerm = async (oemId, id) => {
    const url = repairProcedureApi + `Flagger/${oemId}/FlagTerms/${id}/Run`;
    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });
    if (!response.ok) throw new Error('Failed to run flag term');
};

export const requestUndoFlagTerm = async (oemId, id) => {
    const url = repairProcedureApi + `Flagger/${oemId}/FlagTerms/${id}/Undo`;
    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });
    if (!response.ok) throw new Error('Failed to undo flag term run');
};

export const requestSetActiveFlagTerm = async (oemId, params) => {
    const url = repairProcedureApi + `odata/OneTimeUseFlagTerms/${params.termId}/SetActive`;
    const response = await fetchWithAuthHeader(url, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ isActive: params.isActive }),
    });
    if (!response.ok) throw new Error(`Failed to update active status for termId:${params.termId}`);
};

export const requestDeleteFlagTerm = async (oemId, id) => {
    const url = repairProcedureApi + `Flagger/${oemId}/FlagTerms/${id}`;
    const response = await fetchWithAuthHeader(url, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });
    if (!response.ok) throw new Error('Failed to delete flag term');

    return id;
};

export const requestNumberOfFlags = async id => {
    const url =
        repairProcedureApi + `odata/OneTimeUseFlags/$count?$filter=OneTimeUseFlagTermId eq ${id} &api-version=4.0`;
    let response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error('Could not retrieve flag terms count');
    return await response.text();
};

export const setFlagDispositionRuleActiveStatus = async params => {
    const url = repairProcedureApi + `odata/FlagDispositionRules/SetActive`;
    const response = await fetchWithAuthHeader(url, {
        method: 'PATCH',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: params.ruleId, isActive: params.isActive }),
    });

    if (!response.ok) throw new Error(`Failed to update active status for flag disposition rule:${params.ruleId}`);
};

export const createRule = async params => {
    const url = repairProcedureApi + `odata/FlagDispositionRules/CreateRule`;
    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(params),
    });

    if (!response.ok) throw new Error(`Failed to create disposition rule`);

    return await response.json();
};
