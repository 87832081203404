// * Todo: Merge this helper to TaggerHelper
// Ref: rp.TaggingWorkFlowStatus table
export const TaggingWorkFlowStatusEnum = Object.freeze({
    IN_REVIEW: {
        Id: 1,
        Name: 'In Review',
        Updatable: true,
    },
    COMPLETE: {
        Id: 2,
        Name: 'Complete',
        Updatable: true,
    },
    NEED_HELP: {
        Id: 3,
        Name: 'Needs Help',
        Updatable: true,
    },
    IN_PROGRESS: {
        Id: 4,
        Name: 'In Progress',
        Updatable: false,
    },
});

export const TaggingWorkFlowStatusOptions = [];
for (const prop in TaggingWorkFlowStatusEnum) {
    const { Id, Name, Updatable } = TaggingWorkFlowStatusEnum[prop];
    TaggingWorkFlowStatusOptions.push({
        key: Id,
        value: Id,
        text: Name,
        disabled: !Updatable,
    });
}

export const filterUntaggedFlags = (flags, tags) => {
    const taggedFlagIds = new Set(tags.map(t => t.oneTimeUseFlagId));
    return flags.filter(f => !taggedFlagIds.has(f.oneTimeUseFlagId));
};

export const filterInReviewTags = tags =>
    tags.filter(({ workFlowStatusId }) => workFlowStatusId === TaggingWorkFlowStatusEnum.IN_REVIEW.Id);

export const filterCompleteTags = tags =>
    tags.filter(({ workFlowStatusId }) => workFlowStatusId === TaggingWorkFlowStatusEnum.COMPLETE.Id);

export const filterInProgressTags = tags =>
    tags.filter(({ workFlowStatusId }) => workFlowStatusId === TaggingWorkFlowStatusEnum.IN_PROGRESS.Id);

export const filterNeedHelpTags = tags =>
    tags.filter(({ workFlowStatusId }) => workFlowStatusId === TaggingWorkFlowStatusEnum.NEED_HELP.Id);

export const filterUncheckedLinkTags = tags => tags.filter(({ linksChecked }) => linksChecked === false);

export const filterCheckedLinkTags = tags => tags.filter(({ linksChecked }) => linksChecked === true);
