import { fetchWithAuthHeader } from 'api/AuthUtils';

export const requestImportJobDelta = async importJobId => {
    const url = `api/RepairProcedure/odata/ImportJob/ProcedureDetails`;
    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            ImportJobId: importJobId,
        }),
    });

    if (!response.ok) throw new Error(`Cannot get import job delta for ${importJobId}`);

    const data = await response.json();
    return data.importJob;
};
