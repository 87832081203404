import React, { useState } from 'react';
import { FileDropBox } from 'oemiq-common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PositionStatementFileDropBox = ({ fileDataCallback }) => {
    const acceptedFileTypes = '.pdf';
    const [fileData, setFileData] = useState(null);
    const [fileError, setFileError] = useState('');

    const getFileExtension = fileName => {
        return fileName.substr(fileName.lastIndexOf('.') + 1);
    };

    const validateFile = file => {
        let fileExt = getFileExtension(file.name);
        if (!acceptedFileTypes.includes(fileExt)) return `File extension "${fileExt}" not accepted`;
        else if (file.size > 1024 * 1024 * 1024) return 'File must be less then 1GB';
        else return null;
    };

    const handleFileChange = file => {
        let fileError = validateFile(file);
        setFileError(fileError);
        if (fileError) {
            fileDataCallback(null);
            setFileData(null);
            return;
        }

        setFileData(file);
        fileDataCallback(file);
    };

    return (
        <div>
            <FileDropBox
                className="my-3 text-center"
                fileDataCallback={handleFileChange}
                accept={acceptedFileTypes}
                returnType="file">
                <h2 className="text-primary">
                    Drag &amp; drop a PDF file
                    <br /> -- or --
                    <br /> Click to browse
                </h2>
                <h2 className="text-primary">Drop PDF file</h2>
            </FileDropBox>
            {fileData != null && <div className="text-success">{fileData.name}</div>}
            {fileError && (
                <h6 id="file-error-message" className="my-2 text-danger">
                    <FontAwesomeIcon icon="times-circle" className="me-2" />
                    {fileError}
                </h6>
            )}
        </div>
    );
};

export default PositionStatementFileDropBox;
