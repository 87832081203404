/**
 * Parses a ISO8601 duration string and returns a human-readable string.
 * @param {*} dateStr A string in ISO8601 duration format that matches this format: PnYnMnDTnHnMnS
 * @returns A human-readable string that represents the duration.
 */
export const ParseIso8601Duration = (isoDuration, keepMilliseconds = false) => {
    if (!isoDuration) return '';

    // Updated regular expression to match the new pattern
    // https://stackoverflow.com/questions/32044846/regex-for-iso-8601-durations
    const regex =
        /^P(?!$)(\d+(?:\.\d+)?Y)?(\d+(?:\.\d+)?M)?(\d+(?:\.\d+)?W)?(\d+(?:\.\d+)?D)?(T(?=\d)(\d+(?:\.\d+)?H)?(\d+(?:\.\d+)?M)?(\d+(?:\.\d+)?S)?)?$/;

    const matches = isoDuration.match(regex);
    if (!matches) return '';

    const years = matches[1] || '';
    const months = matches[2] || '';
    const weeks = matches[3] || '';
    const days = matches[4] || '';
    const hours = matches[6] || '';
    const minutes = matches[7] || '';
    let seconds = matches[8] || '';

    if (seconds) {
        if (keepMilliseconds) {
            seconds = `${parseFloat(seconds).toFixed(3)}S`; // Keeps three decimals
        } else {
            seconds = seconds.includes('.') ? `${seconds.split('.')[0]}S` : seconds; // Remove decimals
        }
    }

    const parts = [years, months, weeks, days, hours, minutes, seconds].filter(part => part);

    return parts.join(' ').trim();
};
