import React from 'react';
import { PrimaryButton } from 'oemiq-common';
import CreateMappingTrainingModal from './CreateMappingTrainingModal/CreateMappingTrainingModal';
import MappingTrainingBookList from './MappingTrainingBookList/MappingTrainingBookList';
import useMappingTrainingTool from './useMappingTrainingTool';

const MappingTrainingTool = () => {
    const { books, handleDeleteBook, isModalOpen, setIsModalOpen, createTrainingMappingBook } =
        useMappingTrainingTool();

    return (
        <div className="container-fluid mt-3">
            <div className="d-flex justify-content-end">
                <PrimaryButton type="button" id="create-mapping-training" onClick={() => setIsModalOpen(true)}>
                    Create Mapping Training
                </PrimaryButton>
            </div>
            <MappingTrainingBookList books={books} handleDeleteBook={handleDeleteBook} />
            <CreateMappingTrainingModal
                isOpen={isModalOpen}
                handleToggle={() => setIsModalOpen(!isModalOpen)}
                createTrainingMappingBook={createTrainingMappingBook}
            />
        </div>
    );
};

export default MappingTrainingTool;
