import { Route, Routes } from 'react-router-dom';
import Oems from 'components/Oem/Oems';
import ToolSelection from 'components/locations/Home/ToolSelection';
import AuthorizedElement from 'routes/AuthorizedElement';
import VehicleModelList from './VehicleModelList/VehicleModelList';
import PublishVehicles from './Publisher/PublishVehicles';
import VehicleManagementTool from './VehicleManagement/VehicleManagementTool';
import TestThreeDModel from './TestThreeDModel/TestThreeDModel';
import fetchCounts from 'helpers/FetchCountsHelper';
import VehicleProcessNavigation from 'components/Navigation/VehicleProcessNavigation';

const VehicleProcessRoutes = () => {
    return (
        <Routes>
            <Route
                path={'/'}
                element={
                    <ToolSelection
                        tools={[
                            {
                                path: '/vehicle-process/publisher',
                                text: 'Publish Vehicle',
                                icon: 'rocket',
                                tileDescription: 'Tool for publishing vehicles',
                                access: 'tool.vehicleProcess.publisher',
                            },
                            {
                                path: '/vehicle-process/vehicle-management',
                                text: 'Vehicle Management',
                                icon: 'car',
                                tileDescription: 'Tool for managing vehicles in our system',
                                access: 'tool.vehicleProcess.vehicleManagement',
                            },
                            {
                                path: '/vehicle-process/test-3d-model',
                                text: 'Test 3D Model',
                                icon: 'car-side',
                                tileDescription:
                                    'Tool for testing validity of GLB models against what our system expects',
                                access: 'tool.vehicleProcess.treeDModel',
                            },
                        ]}
                    />
                }
            />
            <Route
                path="/publisher"
                element={
                    <AuthorizedElement resource={'tool.vehicleProcess.publisher'}>
                        <Oems mode={{ hasProcedureVehicles: true }} />
                    </AuthorizedElement>
                }
            />
            <Route
                path={'/publisher/:oemId'}
                element={
                    <AuthorizedElement resource={'tool.vehicleProcess.publisher'}>
                        <VehicleModelList />
                    </AuthorizedElement>
                }
            />
            <Route
                path={'/publisher/:oemId/:modelId'}
                element={
                    <AuthorizedElement resource={'tool.vehicleProcess.publisher'}>
                        <PublishVehicles />
                    </AuthorizedElement>
                }
            />
            <Route
                path={'/vehicle-management'}
                element={
                    <AuthorizedElement resource={'tool.vehicleProcess.vehicleManagement'}>
                        <Oems mode={{ hasVehicles: true }} fetchCounts={fetchCounts} context="vehicleManagement" />
                    </AuthorizedElement>
                }
            />
            <Route
                path={'/vehicle-management/:oemId'}
                element={
                    <AuthorizedElement resource={'tool.vehicleProcess.vehicleManagement'}>
                        <VehicleModelList />
                    </AuthorizedElement>
                }
            />
            <Route
                path={'/vehicle-management/:oemId/:modelId'}
                element={
                    <AuthorizedElement resource={'tool.vehicleProcess.vehicleManagement'}>
                        <VehicleManagementTool />
                    </AuthorizedElement>
                }
            />
            <Route
                path={'/test-3d-model'}
                element={
                    <AuthorizedElement resource={'tool.vehicleProcess.treeDModel'}>
                        <TestThreeDModel />
                    </AuthorizedElement>
                }
            />
        </Routes>
    );
};

export const VehicleProcessRoute = {
    path: 'vehicle-process/*',
    element: <VehicleProcessRoutes />,
    handle: {
        resource: 'tool.vehicleProcess',
        crumb: () => (
            <Routes>
                <Route path="vehicle-process/" element={<VehicleProcessNavigation />}>
                    <Route path=":vehicleTool" element={<VehicleProcessNavigation />} />
                    <Route path=":vehicleTool/:oemId" element={<VehicleProcessNavigation />} />
                    <Route path=":vehicleTool/:oemId/:modelId" element={<VehicleProcessNavigation />} />
                </Route>
            </Routes>
        ),
    },
};

export default VehicleProcessRoutes;
