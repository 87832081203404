import SmallSpinner from 'components/SmallSpinner';
import React, { useCallback, useRef } from 'react';

interface InfiniteScrollingFooterProps {
    loading: boolean;
    hasMoreData: boolean;
    fetchMore: () => Promise<void>;
}

const InfiniteScrollingFooter: React.FC<InfiniteScrollingFooterProps> = ({ loading, hasMoreData, fetchMore }) => {
    const observerRef = useRef(null);
    const tableFooterRef = useCallback(
        node => {
            if (loading) return;
            if (observerRef.current) {
                observerRef.current.disconnect();
            }
            observerRef.current = new IntersectionObserver(async entries => {
                if (entries[0].isIntersecting && hasMoreData) {
                    await fetchMore();
                }
            });
            if (node) {
                if (hasMoreData) {
                    observerRef.current.observe(node);
                } else {
                    observerRef.current.disconnect();
                }
            }
        },
        [fetchMore, hasMoreData, loading]
    );

    return !loading && hasMoreData ? (
        <div ref={tableFooterRef} className="d-flex flex-row justify-content-center">
            <SmallSpinner id="loading-spinner" />
        </div>
    ) : null;
};

export default InfiniteScrollingFooter;
