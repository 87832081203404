import { MappingWorkflowStatus, Procedure } from 'types/procedureTypes';

const hasGroupAndType = (proc: Procedure) => proc.stageArea.type.typeId !== null && proc.stageArea.groups.length > 0;

const hasAnyGroupOrTypeInStatus = (proc: Procedure, workflowStatus: MappingWorkflowStatus) =>
    proc.stageArea.groups.some(group => group.mappingStatusId === workflowStatus) ||
    proc.stageArea.type.mappingStatusId === workflowStatus;

export const isActive = (proc: Procedure) => !proc.isDeleted;

export const isInReview = (proc: Procedure) =>
    !proc.isDeleted &&
    hasGroupAndType(proc) &&
    !hasAnyGroupOrTypeInStatus(proc, MappingWorkflowStatus.NeedHelp) &&
    hasAnyGroupOrTypeInStatus(proc, MappingWorkflowStatus.InReview);

export const isCompleted = (proc: Procedure) =>
    !proc.isDeleted &&
    hasGroupAndType(proc) &&
    proc.stageArea.groups.every(group => group.mappingStatusId === MappingWorkflowStatus.Completed) &&
    proc.stageArea.type.mappingStatusId === MappingWorkflowStatus.Completed;

export const isNeedHelp = (proc: Procedure) =>
    !proc.isDeleted && hasGroupAndType(proc) && hasAnyGroupOrTypeInStatus(proc, MappingWorkflowStatus.NeedHelp);

export const isRemoved = (p: Procedure) => p.isDeleted;
