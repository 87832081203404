import SearchBar from 'components/Shared/SearchBar/SearchBar';
import React, { useEffect, useState } from 'react';
import { RefreshPublisherPagination } from './types';

const itemsPerPageOptions = ['All', '5', '500', '1000'] as const;

interface RefreshPublisherHeaderPropsType {
    searchValue: string;
    onSearchValueChange: (value: string) => void;
    pagination: RefreshPublisherPagination;
    onPageNumberChange: (value: number) => void;
    totalPage: number;
    totalItems: number;
    onItemsPerPageChange: (value: number) => void;
    disabled: boolean;
}

const RefreshPublisherHeader: React.FC<RefreshPublisherHeaderPropsType> = ({
    searchValue: searchTerm,
    onSearchValueChange,
    pagination,
    onPageNumberChange,
    totalPage,
    totalItems,
    onItemsPerPageChange,
    disabled,
}) => {
    const [searchValue, setSearchValue] = useState<string>(searchTerm);
    const [pageNumber, setPageNumber] = useState<number>(pagination.currentPageNumber);
    const [selectedItemPerPageOption, setSelectedItemPerPageOption] = useState<string>(itemsPerPageOptions[0]);

    useEffect(() => {
        setSearchValue(searchTerm);
    }, [searchTerm]);

    useEffect(() => {
        setPageNumber(pagination.currentPageNumber);
    }, [pagination]);

    const handleSearchBlur = (e?: React.FocusEvent<HTMLInputElement, Element>) => {
        const value = e?.currentTarget.value.trim() ?? '';
        setSearchValue(value);
        onSearchValueChange(value);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => e.key === 'Enter' && e.currentTarget.blur();

    const handlePageNumberChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        setPageNumber(parseInt(e.currentTarget.value));

    const handlePageNumberBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        const value = e.currentTarget.value.trim();
        const parsed = parseInt(value);
        isNaN(parsed) ? onPageNumberChange(1) : onPageNumberChange(parsed);
    };

    const handleItemsPerPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.currentTarget.value;
        setSelectedItemPerPageOption(value);
        value === itemsPerPageOptions.at(0) ? onItemsPerPageChange(totalItems) : onItemsPerPageChange(parseInt(value));
    };

    return (
        <div className="d-flex flex-row align-items-end my-2">
            <SearchBar
                className="w-50 px-2"
                value={searchValue}
                onKeyDown={handleKeyDown}
                onBlur={handleSearchBlur}
                onChange={setSearchValue}
                disabled={disabled}
            />
            <div className="d-flex flex-grow-1 justify-content-end">
                {totalPage > 1 && (
                    <div className="me-3 align-items-center">
                        <label htmlFor="input-page-number" className="h6">
                            Go to:
                        </label>
                        <div>
                            <input
                                className="form-control w-75 d-inline"
                                type="number"
                                id="input-page-number"
                                data-testid="input-page-number"
                                value={pageNumber}
                                onChange={handlePageNumberChange}
                                onBlur={handlePageNumberBlur}
                                disabled={disabled}
                                onKeyDown={handleKeyDown}
                            />
                            <div className="h5 ms-2 d-inline">{`/ ${totalPage}`}</div>
                        </div>
                    </div>
                )}

                <div className="align-items-center">
                    <label className="h6" htmlFor="select-items-per-page">
                        Items per page:{' '}
                    </label>
                    <select
                        className="form-select d-inline"
                        id="select-items-per-page"
                        data-testid="select-items-per-page"
                        onChange={handleItemsPerPageChange}
                        value={selectedItemPerPageOption}
                        disabled={disabled}>
                        {itemsPerPageOptions.map(option => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );
};

export default RefreshPublisherHeader;
