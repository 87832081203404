import { buildProcedureProperty } from './ProcedureProperty';
import { getCommonFilters, getCommonProperties, getLastCommonProperties } from './BaseProcedureMetadata';
import { requestCreateNewMappingRule } from 'api/RepairProcedures/RepairProcedureMappingRuleApi';
import { buildOemMetadata, OemMetadata } from './BuildOemMetadata';
import { isEmpty, isNil } from 'lodash';
import { PROPERTY_TYPE } from './PropertyType';
import { OemEngineService, OemMappingRuleBase } from './types';
import { ProcedureType } from './ProcedureType';

const OEM_METADATA_PROPERTY = 'latestHyundaiProcedure';

const getRulableProperties = () => [
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.metaLevel1',
        displayName: 'MetaLevel 1',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'metaLevel1',
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.metaLevel2',
        displayName: 'MetaLevel 2',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'metaLevel2',
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.metaLevel3',
        displayName: 'MetaLevel 3',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'metaLevel3',
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.metaLevel4',
        displayName: 'MetaLevel 4',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'metaLevel4',
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.metaLevel5',
        displayName: 'MetaLevel 5',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'metaLevel5',
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.metaLevel6',
        displayName: 'MetaLevel 6',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'metaLevel6',
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.nodeDescription',
        displayName: 'Node Description',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'nodeDescription',
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.typeKeywords',
        displayName: 'Type Keywords',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'typeKeywords',
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.isReadyForMapping',
        displayName: 'Ready for Mapping',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'isReadyForMapping',
        procedureTypes: [ProcedureType.Procedure],
    }),
];

const getNonRulableProperties = () => [
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.oemProcedureId',
        displayName: 'OemProcedureId',
        type: PROPERTY_TYPE.string,
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.version',
        displayName: 'Version',
        type: PROPERTY_TYPE.string,
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.htmlFileName',
        displayName: 'Html File Name',
        type: PROPERTY_TYPE.string,
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.procedureTitle',
        displayName: 'Procedure Title',
        type: PROPERTY_TYPE.string,
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.group',
        displayName: 'Group',
        type: PROPERTY_TYPE.string,
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.family',
        displayName: 'Family',
        type: PROPERTY_TYPE.string,
        procedureTypes: [ProcedureType.Procedure],
    }),
    //buildProcedureProperty({
    //    name: OEM_METADATA_PROPERTY + '.siteInfoList',
    //    displayName: 'Site Info List',
    //    type: PROPERTY_TYPE.string,
    //procedureTypes: [ProcedureType.Procedure],}),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.modelYear',
        displayName: 'Model Year',
        type: PROPERTY_TYPE.number,
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.modelSerialNumber',
        displayName: 'Model Serial Number',
        type: PROPERTY_TYPE.string,
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.modelCode',
        displayName: 'Model Code',
        type: PROPERTY_TYPE.string,
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.modelName',
        displayName: 'Model Name',
        type: PROPERTY_TYPE.string,
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.cat1',
        displayName: 'Cat1',
        type: PROPERTY_TYPE.string,
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.contentNo',
        displayName: 'Content No',
        type: PROPERTY_TYPE.string,
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.contentSsn',
        displayName: 'Content Ssn',
        type: PROPERTY_TYPE.string,
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.FfirstNodeDescription',
        displayName: 'First Node Description',
        type: PROPERTY_TYPE.string,
        procedureTypes: [ProcedureType.Procedure],
    }),
    // buildProcedureProperty(
    //     {
    //         name: OEM_METADATA_PROPERTY + '.nodeDescription',
    //         displayName: 'Node Description',
    //         type: PROPERTY_TYPE.string,
    //     },
    //     dataSource
    // ),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.procedureSections',
        displayName: 'Procedure Sections',
        type: PROPERTY_TYPE.string,
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.procedureTitleSections',
        displayName: 'Procedure Title Sections',
        type: PROPERTY_TYPE.string,
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.serviceCode',
        displayName: 'Service Code',
        type: PROPERTY_TYPE.string,
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.qualifiedServiceCode',
        displayName: 'Qualified Service Code',
        type: PROPERTY_TYPE.string,
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.componentId',
        displayName: 'Component Id',
        type: PROPERTY_TYPE.string,
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.col3',
        displayName: 'Col3',
        type: PROPERTY_TYPE.string,
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.query',
        displayName: 'Query',
        type: PROPERTY_TYPE.string,
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.qualifier',
        displayName: 'Qualifier',
        type: PROPERTY_TYPE.string,
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.languageCode',
        displayName: 'Language Code',
        type: PROPERTY_TYPE.string,
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.manufacturerCode',
        displayName: 'Manufacturer Code',
        type: PROPERTY_TYPE.string,
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.modelId',
        displayName: 'Model Id',
        type: PROPERTY_TYPE.string,
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.subSystem',
        displayName: 'Sub System',
        type: PROPERTY_TYPE.string,
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.subSystemId',
        displayName: 'Sub System Id',
        type: PROPERTY_TYPE.string,
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.pcode',
        displayName: 'Pcode',
        type: PROPERTY_TYPE.string,
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.col13',
        displayName: 'Col13',
        type: PROPERTY_TYPE.string,
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.col14',
        displayName: 'Col14',
        type: PROPERTY_TYPE.string,
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.col17',
        displayName: 'Col17',
        type: PROPERTY_TYPE.string,
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.dataSource',
        displayName: 'Data Source',
        type: PROPERTY_TYPE.string,
        procedureTypes: [ProcedureType.Procedure],
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.revisionNumber',
        displayName: 'Revision Number',
        type: PROPERTY_TYPE.string,
        procedureTypes: [ProcedureType.Procedure],
    }),
];

export const getHyundaiProcedureMetadata = () =>
    buildOemMetadata({
        oemId: 19,
        oemName: 'Hyundai',
        properties: [
            ...getCommonProperties(),
            ...getRulableProperties(),
            ...getNonRulableProperties(),
            ...getLastCommonProperties(),
        ],
        ruleKey: 'hyundaiMappingRuleId',
        filters: [...getCommonFilters()],
    });

type HyundaiMappingRule = OemMappingRuleBase & {
    hyundaiMappingRuleId: number;
};

class _HyundaiMappingEngineService implements OemEngineService<HyundaiMappingRule> {
    metadata: OemMetadata;

    constructor() {
        this.metadata = HYUNDAI_PROCEDURE_METADATA;
    }

    isRuleValid = (rule: HyundaiMappingRule): boolean => {
        return (
            (!isNil(rule.typeId) || !isEmpty(rule.groupIds)) &&
            this.metadata.ruleable.some(p => rule[p.rulePropertyName])
        );
    };

    createRule = async (rule: HyundaiMappingRule): Promise<HyundaiMappingRule> => {
        const newRule = { ...rule };
        const hyundaiMappingRuleId = await requestCreateNewMappingRule(this.metadata.oemId, newRule);
        newRule.hyundaiMappingRuleId = hyundaiMappingRuleId;

        return newRule;
    };
}

export const HYUNDAI_PROCEDURE_METADATA = getHyundaiProcedureMetadata();
export const HyundaiMappingEngineService = new _HyundaiMappingEngineService();
