import { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react';
import useOems from 'hooks/useOems';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { requestImportJobForOem, requestForceImportJobComplete } from 'api/RepairProcedureApi';
import { ImportHistoryActionsTypes, ImportHistoryRowDataType } from './types';
import { Number, Text } from 'components/Shared/Table/Cells';
import { ItemReturnEvent } from 'components/Shared/Table/types';
import IdCell from './CustomCells/IdCell';
import ForceCompleteCell from './CustomCells/ForceCompleteCell';
import { ToastContext } from 'components/ToastProvider';
import { AccessControlContext } from 'components/Shared/AccessControl/AccessControl';

const BATCH_SIZE = 20;

const transformDateToMatchStringInDatabase = (str: string | null): string | null =>
    str ? str.slice(0, -1).replace(/T/gi, ' ') : str;

const baseHeaders = [
    {
        label: 'Id',
        title: 'Import Job Id',
        thClass: 'text-center',
        component: IdCell,
    },
    {
        label: 'Success',
        id: 'success',
        component: Text({ transform: i => (i.success === true ? 'True' : i.success === false ? 'False' : '-') }),
    },
    {
        label: 'Errors',
        id: 'errors',
        thClass: 'text-center',
        component: Number({ highlightFn: i => (i.errors as number) > 0 }),
    },
    {
        label: 'Create Date',
        id: 'createDate',
        component: Text({ transform: i => transformDateToMatchStringInDatabase(i.createDate as string) }),
    },
    {
        label: 'Update Date',
        id: 'updateDate',
        component: Text({ transform: i => transformDateToMatchStringInDatabase(i.updateDate as string) }),
    },
    {
        label: '# of Books',
        id: 'numberOfBooks',
        thClass: 'text-center',
        title: 'Number of books',
        component: Number(),
    },
    {
        label: 'Books Published',
        id: 'booksPublished',
        component: Text({
            transform: i => {
                const publishedCount = (i.numOfPublished as number) || 0;
                const totalCount = (i.numToPublish as number) || 0;
                if (totalCount <= 0) {
                    return '-';
                } else {
                    const percentage = (publishedCount / totalCount) * 100;
                    return `(${publishedCount}/${totalCount}) ${percentage.toFixed(2)}%`;
                }
            },
        }),
    },
    {
        label: 'Download Type',
        id: 'importJobDownloadTypeName',
    },
    {
        label: 'Import Y/N',
        id: 'shouldImport',
        component: Text({
            transform: i => (i.shouldImport === true ? 'True' : i.shouldImport === false ? 'False' : '-'),
        }),
    },
];

const getImportJobData = async (
    oemId: number,
    top: number,
    skip: number,
    searchValue: string | null
): Promise<{ total: number; data: ImportHistoryRowDataType[] }> => {
    const odata = await requestImportJobForOem(oemId, top, skip, searchValue);
    const total = odata['@odata.count'];
    const data: ImportHistoryRowDataType[] = odata.value.map(
        (importJob): ImportHistoryRowDataType => ({
            importJobId: importJob.importJobId,
            userId: importJob.userId,
            numberOfBooks: importJob.numberOfBooks,
            success: importJob.success ?? null,
            shouldImport: importJob.shouldImport ?? null,
            importJobDownloadTypeId: importJob.importJobDownloadTypeId,
            importJobDownloadTypeName:
                importJob.importJobDownloadTypeId !== null
                    ? importJob.importJobDownloadType.importJobDownloadTypeName
                    : '-',
            createDate: importJob.createDate ?? null,
            updateDate: importJob.updateDate ?? null,
            errors: importJob.errors,
            numOfPublished: importJob.numOfPublished,
            numToPublish: importJob.numToPublish,
        })
    );

    return { total, data };
};

const useImportHistory = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [searchValue, setSearchValue] = useState('');
    const [total, setTotal] = useState(0);
    const [data, setData] = useState<ImportHistoryRowDataType[]>([]);
    const [loading, setLoading] = useState(true);

    const confirmationRef = useRef(null);

    const { showToast } = useContext<{ showToast }>(ToastContext);
    const { hasAccess } = useContext(AccessControlContext);

    const { oems } = useOems();
    const { oemId } = useParams();

    const headers = useMemo(() => {
        const newHeaders = [...baseHeaders];

        if (hasAccess('importManager.forceComplete')) {
            newHeaders.push({
                label: 'Actions',
                id: 'historyActions',
                component: ForceCompleteCell,
            });
        }

        return newHeaders;
    }, [hasAccess]);

    useEffect(() => {
        let isUnmounted = false;
        (async () => {
            try {
                setLoading(true);
                const result = await getImportJobData(parseInt(oemId), BATCH_SIZE, 0, null);

                if (!isUnmounted) {
                    setTotal(result.total);
                    setData(result.data);
                }
            } catch (error) {
                showToast(error);
            } finally {
                setLoading(false);
            }
        })();

        return () => {
            isUnmounted = true;
        };
    }, [oemId, showToast]);

    const fetchMore = async () => {
        try {
            setLoading(true);
            const result = await getImportJobData(parseInt(oemId), BATCH_SIZE, data.length, searchValue);
            setData(prev => [...prev, ...result.data]);
            setTotal(result.total);
        } catch (error) {
            setTotal(0);
            showToast(error);
        } finally {
            setLoading(false);
        }
    };

    const handleSearchValueChange = useCallback((value: string) => {
        setSearchValue(value);
    }, []);

    const handleSearchKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (e.key === 'Enter') {
            e.currentTarget.blur();
        }
    }, []);

    const handleSearchBlur = useCallback(
        async (e?: React.FocusEvent<HTMLInputElement, Element>): Promise<void> => {
            const value = e?.currentTarget.value.trim() || '';

            setSearchValue(value);
            setLoading(true);
            setData([]);
            setTotal(0);

            try {
                const result = await getImportJobData(parseInt(oemId), BATCH_SIZE, 0, value);
                setData(result.data);
                setTotal(result.total);
            } catch (error) {
                showToast(error);
            } finally {
                setLoading(false);
            }
        },
        [oemId, showToast]
    );

    const handleIdButtonClick = useCallback(
        (payload: ItemReturnEvent) => {
            const item = payload.item as ImportHistoryRowDataType;
            const destination = `${location.pathname}/${item.importJobId}`;
            navigate(destination);
        },
        [navigate, location]
    );

    const handleForceCompleteButtonClick = useCallback(
        (payload: ItemReturnEvent) => {
            if (confirmationRef.current) {
                const item = payload.item as ImportHistoryRowDataType;
                const importJob = item.importJobId;
                confirmationRef.current.open(
                    async () => {
                        try {
                            await requestForceImportJobComplete(importJob);
                            const result = await getImportJobData(parseInt(oemId), BATCH_SIZE, 0, null);
                            setTotal(result.total);
                            setData(result.data);
                        } catch (error) {
                            showToast(error);
                        }
                    },
                    {
                        title: 'Confirmation',
                        body: `Are you sure you want to force complete Import Job ${importJob}?`,
                    }
                );
            }
        },
        [oemId, showToast]
    );

    const handlers = useMemo(
        () =>
            ({
                [ImportHistoryActionsTypes.ViewDetails]: handleIdButtonClick,
                [ImportHistoryActionsTypes.ForceComplete]: handleForceCompleteButtonClick,
            } as const),
        [handleIdButtonClick, handleForceCompleteButtonClick]
    );

    const oem = oems.find(oem => oem.oemId === parseInt(oemId));

    return {
        oem,
        headers,
        searchValue,
        handleSearchValueChange,
        handleSearchKeyDown,
        handleSearchBlur,
        loading,
        data,
        total,
        fetchMore,
        handlers,
        confirmationRef,
    };
};

export default useImportHistory;
