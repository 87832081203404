import { useState, useEffect, useContext, useCallback } from 'react';
import { requestQuantityConditions } from 'api/RepairProcedureApi';
import { ToastContext } from 'components/ToastProvider';

const useQuantityConditions = () => {
    const [quantityConditions, setQuantityConditions] = useState([]);
    const { showToast } = useContext(ToastContext);

    useEffect(() => {
        async function fetchData() {
            try {
                const values = await requestQuantityConditions();
                let mapped = values.map(s => ({
                    key: s.quantityConditionId,
                    value: s.quantityConditionText,
                    text: s.quantityConditionText,
                }));
                setQuantityConditions(mapped);
            } catch (error) {
                showToast(error);
            }
        }
        fetchData();
    }, [showToast]);

    const addNewQuantityCondition = useCallback(value => {
        setQuantityConditions(prev => [
            ...prev,
            {
                key: prev.length + 1,
                value: value,
                text: value,
            },
        ]);
    }, []);

    return { quantityConditions, setQuantityConditions, addNewQuantityCondition };
};

export default useQuantityConditions;
