import React from 'react';
import { PrimaryButton, LightButton } from 'oemiq-common';

const BulkMoveFooter = ({ handleModalToggle, handleBulkChangeStatusProcedures }) => {
    return (
        <div className="d-flex justify-content-between w-100">
            <LightButton id="close-group-type-modal" onClick={handleModalToggle}>
                Cancel
            </LightButton>
            <PrimaryButton id="bulk-move-procedures" onClick={() => handleBulkChangeStatusProcedures()}>
                Change Status
            </PrimaryButton>
        </div>
    );
};

export default BulkMoveFooter;
