import React, { useCallback, useEffect, useState } from 'react';
import { ReactModal, Input, Select, InputSubmit } from 'oemiq-common';

const termInputDefault = {
    value: '',
    isTouched: false,
    isValid: false,
};

const partTypeInputDefault = {
    value: -1,
    isTouched: false,
    isValid: false,
};

const isRegex = text => {
    try {
        new RegExp(text);
        return true;
    } catch {
        return false;
    }
};

const FlaggerToolCreateModal = ({ isOpen, toggleCallback, typeOptions, submitCallback }) => {
    const [termInput, setTermInput] = useState(termInputDefault);
    const [partTypeInput, setPartTypeInput] = useState(partTypeInputDefault);
    const [isFormValid, setIsFormValid] = useState(false);

    const isHighlightError = field => !field.isValid && field.isTouched;

    const termInputChange = value => {
        const termInputLength = value.length > 2;
        const termInputRegex = isRegex(value);
        setTermInput(v => ({ ...v, value, isValid: termInputLength && termInputRegex }));
    };

    const partTypeInputChange = value => {
        const partTypeInputExist = value > -1;
        setPartTypeInput(v => ({ ...v, value: parseInt(value, 10), isValid: partTypeInputExist }));
    };

    const refreshValidity = useCallback(() => {
        setIsFormValid([termInput.isValid, partTypeInput.isValid].every(i => i));
    }, [termInput, partTypeInput]);

    useEffect(() => refreshValidity(), [refreshValidity]);

    return (
        <ReactModal
            isOpen={isOpen}
            toggle={toggleCallback}
            headerComponent={<>Create new flag term</>}
            bodyComponent={
                <form
                    id="create-flag-term-form"
                    onSubmit={e => (
                        e.preventDefault(),
                        submitCallback({ term: termInput.value, oneTimeUsePartType: partTypeInput.value })
                    )}>
                    <Input
                        id="create-flag-term-field-term"
                        name="create-flag-term-field-term"
                        type="text"
                        className={isHighlightError(termInput) ? 'is-invalid' : ''}
                        label="Flag term"
                        placeholder="Text or regex"
                        value={termInput.value}
                        onChange={e => termInputChange(e.currentTarget.value)}
                        onBlur={() => setTermInput(v => ({ ...v, isTouched: true }))}
                        errorMessage={
                            !termInput.isValid && termInput.isTouched
                                ? 'Please enter at least 3 characters, text should be a valid regex'
                                : undefined
                        }
                    />
                    <Select
                        label="1xUse Part Type"
                        id="create-flag-term-field-1xUse"
                        name="create-flag-term-field-1xUse"
                        className={`form-select ${
                            !partTypeInput.isValid && partTypeInput.isTouched ? 'is-invalid' : ''
                        }`}
                        value={partTypeInput.value}
                        onChange={e => partTypeInputChange(e.currentTarget.value)}
                        selectProps={{
                            onBlur: () => setPartTypeInput(v => ({ ...v, isTouched: true })),
                        }}
                        options={typeOptions}
                        defaultOption={{ text: '-- Select One Time Use Part Type --', value: -1 }}
                    />
                </form>
            }
            footerComponent={
                <div className="d-flex justify-content-end">
                    <InputSubmit
                        id={'create-flag-term-form-submit'}
                        form={'create-flag-term-form'}
                        value={'Create new flag term'}
                        disabled={!isFormValid}
                    />
                </div>
            }
        />
    );
};

export default FlaggerToolCreateModal;
