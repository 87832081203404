import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from 'oemiq-common';

const Unauthorized = () => {
    const navigate = useNavigate();

    return (
        <div className="d-flex align-items-center justify-content-center mt-5">
            <div className="text-center">
                <div className="mb-4 lead">{"Sorry. You're not authorized to do that."}</div>
                <PrimaryButton
                    id="error-back-home"
                    type="button"
                    className="btn-sm"
                    onClick={() => navigate('/')}
                    disabled={false}>
                    Back to Home
                </PrimaryButton>
            </div>
        </div>
    );
};

export default Unauthorized;
