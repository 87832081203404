import React, { useEffect, useRef, useCallback } from 'react';
import { Input, PrimaryButton } from 'oemiq-common';
import { useNavigate, useLocation } from 'react-router-dom';
import ProgressBar from 'components/Shared/ProgressBar/ProgressBar';

const BookFilterTools = ({
    bookSearchValue,
    setBookSearchValue,
    displayTaggerDetails,
    isNotCompletedFilter,
    setIsNotCompletedFilter,
    isRefreshedPendingFilter,
    setRefreshedPendingFilter,
    statsLoadingPercentage,
    isBooksStatsLoading,
    isOnlyHotSheetFilter,
    setOnlyHotSheetFilter,
    procedureTools,
}) => {
    const navigate = useNavigate();
    const location = useLocation();

    const timeoutRef = useRef(null);

    // same idea as debounce
    // only update the search term after the user stops input 500ms
    useEffect(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            setBookSearchValue(bookSearchValue);
        }, 500);

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [bookSearchValue, setBookSearchValue]);

    const handleClearClick = useCallback(() => {
        setBookSearchValue('');
    }, [setBookSearchValue]);

    const handleAllProceduresClick = useCallback(() => {
        navigate('procedures');
    }, [navigate]);

    const handleAllTsbClick = useCallback(() => {
        navigate('bulletins');
    }, [navigate]);

    return (
        <>
            <div className="py-3 px-2 d-flex align-items-center">
                <div className="w-50 me-3">
                    <Input
                        id="searchfilter-search"
                        type="text"
                        value={bookSearchValue}
                        formGroupClassName={'mb-0'}
                        onChange={e => setBookSearchValue(e.currentTarget.value)}
                        placeholder="Search"
                    />
                </div>
                <div className="w-25">
                    <PrimaryButton
                        id="book-filter-clear"
                        disabled={false}
                        type="button"
                        onClick={() => handleClearClick()}>
                        Clear Filters
                    </PrimaryButton>
                    {procedureTools && (
                        <PrimaryButton
                            id="book-all-procedures"
                            disabled={false}
                            type="button"
                            className="ms-2"
                            onClick={() => handleAllProceduresClick()}>
                            All Procedures
                        </PrimaryButton>
                    )}
                    {procedureTools && (
                        <PrimaryButton
                            id="book-all-tsbs"
                            disabled={false}
                            type="button"
                            className="ms-2"
                            onClick={() => handleAllTsbClick()}>
                            All TSBs
                        </PrimaryButton>
                    )}
                    {displayTaggerDetails && (
                        <PrimaryButton
                            id="oem-bulk-edit-button"
                            type="button"
                            className="ms-2"
                            onClick={() => navigate(`${location.pathname}/bulkedit`)}>
                            Bulk Edit
                        </PrimaryButton>
                    )}
                </div>
            </div>
            <div className="py-3 px-2 d-flex align-items-center">
                {!displayTaggerDetails && (
                    <div className="content-switches mb-2">
                        <div className="switch switch-sm" title="Hide 100% completed">
                            <input
                                type="checkbox"
                                className="switch"
                                id={'toggle-one-hundred-completed-mapper'}
                                checked={isNotCompletedFilter}
                                onChange={e => setIsNotCompletedFilter(e.target.checked)}
                            />
                            <label
                                className="text-darkblue mt-2 ms-2"
                                htmlFor={'toggle-one-hundred-completed-mapper'}></label>
                        </div>
                        <span style={{ verticalAlign: 'middle' }}>Hide 100% completed</span>
                    </div>
                )}
                <div className="content-switches mb-2">
                    <div className="switch switch-sm" title="Refreshed pending">
                        <input
                            type="checkbox"
                            className="switch"
                            disabled={isBooksStatsLoading}
                            id={'toggle-refreshed-pending-mapper'}
                            checked={isRefreshedPendingFilter}
                            onChange={e => setRefreshedPendingFilter(e.target.checked)}
                        />
                        <label className="text-darkblue mt-2 ms-2" htmlFor={'toggle-refreshed-pending-mapper'}></label>
                    </div>
                    <span style={{ verticalAlign: 'middle' }}>Refreshed pending</span>
                </div>
                <div className="content-switches mb-2">
                    <div className="switch switch-sm" title="Filter HotSheet group only">
                        <input
                            type="checkbox"
                            className="switch"
                            disabled={isBooksStatsLoading}
                            id={'toggle-hotsheet-only-mapper'}
                            checked={isOnlyHotSheetFilter}
                            onChange={e => setOnlyHotSheetFilter(e.target.checked)}
                        />
                        <label className="text-darkblue mt-2 ms-2" htmlFor={'toggle-hotsheet-only-mapper'}></label>
                    </div>
                    <span style={{ verticalAlign: 'middle' }}>Filter HotSheet group only</span>
                </div>
            </div>
            <ProgressBar percentage={statsLoadingPercentage} />
        </>
    );
};

export default BookFilterTools;
