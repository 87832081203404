export enum BookPublishState {
    None = 'None',
    UnableToPublish = 'NotReadyToPublish',
    NothingToPublish = 'NothingToPublish',
    ReadyToPublish = 'ReadyToPublish',
    Publising = '_Publishing',
    FailedToPublish = '_FailedToPublish',
}

export interface RefreshPublisherBookStatisticsType {
    bookId: number;
    bookName: string;
    lastPublishDate: string;
    bookPublishState: BookPublishState;
    unresolvedFlags: number;
    unresolvedTags: number;
    totalProcedures: number;
    completedMappedProcedures: number;
    refreshedProcedures: number;
    removalPending: number;
    stageAreaChanges: number;
    htmlLocationNull: number;
    onlyHotSheetMapped: number;
}
