import { fetchWithAuthHeader } from 'api/AuthUtils';
import { ODataParams, constructUrlFromODataParams } from 'api/OData/OData';
import { OperationTypes } from 'components/Shared/ModalOperations/ModalOperations';
import { OemId } from 'helpers/OemId';

export const requestMappingOperations = async (oemId: OemId, operationTypes: OperationTypes[]) => {
    const params: ODataParams = {
        filter: `parentId eq null and oemId eq ${oemId} and type in (${operationTypes
            .map(t => `'${OperationTypes[t]}'`)
            .join(',')})`,
        orderby: 'id desc',
        count: false,
    };
    const url = constructUrlFromODataParams('api/RepairProcedure/odata/Operation', params);
    const response = await fetchWithAuthHeader(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error(`Failed to get OEM mapping operations, oemId: ${oemId}`);
    }

    const json = await response.json();
    const value = json.value;
    value.totalCount = json['@odata.count'];

    return value;
};

export const requestAreAllOperationsFinishedSinceTime = async (
    oemId: OemId,
    time: number,
    operationTypes: OperationTypes[]
) => {
    const currentDate = new Date();
    currentDate.setMilliseconds(currentDate.getMilliseconds() - time);
    const formattedDate = currentDate.toISOString();

    const params: ODataParams = {
        filter: `parentId eq null and oemId eq ${oemId} and type in (${operationTypes
            .map(t => `'${OperationTypes[t]}'`)
            .join(',')}) and updateDate ge ${formattedDate}`,
        count: true,
        top: 0,
    };
    const url = constructUrlFromODataParams('api/RepairProcedure/odata/Operation', params);

    const response = await fetchWithAuthHeader(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error(`Failed to get check OEM finished operations, oemId: ${oemId}`);
    }

    const json = await response.json();
    return json['@odata.count'] === 0;
};
