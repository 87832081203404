import Breadcrumb from 'components/Navigation/items/Breadcrumb';
import { useParams } from 'react-router-dom';
import OemNameBreadcrumb from 'components/Navigation/items/OemNameBreadcrumb';
import kebab2Camel from './utils/kebab2Camel';
import BookNameBreadcrumb from './items/BookNameBreadcrumb';

const Tools = {
    styleGuide: 'Style Guide',
    reportPlans: 'Report Plans',
    graph: 'Graph',
};

const OtherNavigation = () => {
    const { otherTool, oemId, bookId } = useParams();

    return (
        <ol className="breadcrumb">
            <Breadcrumb isShown={true} isCurrent={!otherTool} link={'/other'} text={'Other'} />
            {otherTool && (
                <Breadcrumb
                    isShown={true}
                    isCurrent={!oemId}
                    link={`/other/${otherTool}`}
                    text={Tools[kebab2Camel(otherTool)]}
                />
            )}
            {otherTool && oemId && (
                <OemNameBreadcrumb oemId={oemId} isCurrent={!bookId} link={`/other/${otherTool}/${oemId}`} />
            )}
            {otherTool && oemId && bookId && (
                <BookNameBreadcrumb oemId={oemId} bookId={bookId} baseUrl={`/other/${otherTool}`} isCurrent={true} />
            )}
        </ol>
    );
};

export default OtherNavigation;
