import React from 'react';
import { TableRowProps } from './types';
import TableCell from './TableCell';
import './Table.scss';

const TableRow = ({
    idKey,
    rowData,
    headers,
    index,
    checked,
    checkboxDisabled,
    toggleCheck,
    settings,
    cellEventCallback,
    tableRowHtmlId,
}: TableRowProps) => {
    return (
        <tr id={tableRowHtmlId}>
            {settings.checkboxes && (
                <td headers="checkboxes" className="ps-3">
                    <input
                        type="checkbox"
                        className="scale2x"
                        checked={checked}
                        disabled={checkboxDisabled}
                        onChange={e =>
                            toggleCheck({
                                checked: e.target.checked,
                                id: rowData[idKey] as string,
                                index,
                                item: rowData,
                            })
                        }
                    />
                </td>
            )}
            {headers.map((h, i) => (
                <TableCell
                    key={i}
                    idKey={idKey}
                    header={h}
                    rowData={rowData}
                    index={index}
                    cellEventCallback={cellEventCallback}
                />
            ))}
        </tr>
    );
};

export default React.memo(TableRow);
