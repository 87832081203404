import { fetchWithAuthHeader } from './AuthUtils';

export default class ApiService {
    private static async createPlainRequest(url: string, methodType: string, signal?: AbortSignal) {
        const res = await fetchWithAuthHeader(url, {
            method: methodType,
            headers: {
                Accept: 'application/json',
            },
            signal,
        });

        if (!res.ok) throw new Error(res.status.toString());

        return res.status === 200 ? await res.json() : '';
    }

    private static async createPayloadRequest(url: string, data: string, methodType: string, signal?: AbortSignal) {
        const res = await fetchWithAuthHeader(url, {
            method: methodType,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: data,
            signal,
        });

        if (!res.ok) throw new Error(res.status.toString());

        return res.status === 200 ? await res.json() : '';
    }

    static async get(url: string, signal?: AbortSignal) {
        return this.createPlainRequest(url, 'GET', signal);
    }

    static async post<T>(url: string, data: T, signal?: AbortSignal) {
        return this.createPayloadRequest(url, JSON.stringify(data), 'POST', signal);
    }

    static async put<T>(url: string, data: T, signal?: AbortSignal) {
        return this.createPayloadRequest(url, JSON.stringify(data), 'PUT', signal);
    }

    static async patch<T>(url: string, data: T, signal?: AbortSignal) {
        return this.createPayloadRequest(url, JSON.stringify(data), 'PATCH', signal);
    }

    static async delelte(url: string, signal?: AbortSignal) {
        return this.createPlainRequest(url, 'DELETE', signal);
    }
}
