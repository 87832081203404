import React, { useCallback, useEffect, useState } from 'react';
import { PrimaryButton, LightButton } from 'oemiq-common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactSelect from 'react-select';

const PLACEHOLDER_STYLE_CORRECTION = {
    borderRadius: '2px',
    fontSize: '85%',
    fontWeight: 400,
    padding: '3px 6px',
    lineHeight: '1.5',
};

const FilterCategory = ({
    options,
    filteredValues,
    submitCallback,
    title,
    allText,
    buttonText,
    maxFilterMessage,
    maxFilters = 0,
}) => {
    const [isDropdownShow, setIsDropdownShow] = useState(false);
    const [value, setValue] = useState([]);
    const [dirty, setDirty] = useState(false);

    const formatValue = () => value.map(item => item.value);

    const isNotMaxFilters = () => !maxFilters || value.length < maxFilters;
    const isRemoving = v => v.length < value.length;
    const setFilterValue = v => (isNotMaxFilters() || isRemoving(v)) && setValue(v);

    const getLabel = useCallback(id => options.find(option => option.value === id).label, [options]);

    useEffect(() => {
        const filteredValue = filteredValues.map(v => ({
            value: v,
            label: getLabel(v),
        }));
        setValue(filteredValue);
    }, [filteredValues, options, isDropdownShow, getLabel]);

    return (
        <div className="filter-category d-flex justify-content-end">
            <div className="text-right align-self-center me-3">
                {filteredValues.length < 1 && (
                    <span className="badge bg-primary me-1">{allText ? allText : 'All'}</span>
                )}
                {filteredValues.slice(0, 3).map(v => (
                    <span key={v} className="badge bg-secondary me-1">
                        {getLabel(v)}
                    </span>
                ))}
                {filteredValues.length > 3 && (
                    <span className="badge bg-primary me-1">+{filteredValues.length - 3}</span>
                )}
            </div>
            <div className={`dropdown me-3`}>
                <PrimaryButton
                    id="filter-category-toggle"
                    className={`dropdown-toggle ${isDropdownShow ? 'show' : ''}`}
                    type="button"
                    onClick={() => setIsDropdownShow(show => !show)}>
                    {buttonText ? buttonText : 'Filter'} <FontAwesomeIcon className="ms-1" icon="fa-filter" />
                </PrimaryButton>
                <div
                    className={`dropdown-menu dropdown-menu-end bg-light pt-2 pb-3 pe-3 ps-3 ${
                        isDropdownShow ? 'show' : ''
                    }`}
                    data-bs-popper
                    aria-labelledby="dropdownMenuButton"
                    style={{ minWidth: '800px', borderRadius: '1.3rem' }}>
                    <div className="mb-2">
                        <h6>{title ? title : 'Apply filter'}</h6>
                        {!isNotMaxFilters() && (
                            <div className="alert alert-info" style={{ padding: '3px 5px' }} role="alert">
                                {maxFilterMessage}
                            </div>
                        )}
                        <ReactSelect
                            isMulti
                            className="basic-multi-select"
                            placeholder={
                                <span className="badge text-bg-primary" style={PLACEHOLDER_STYLE_CORRECTION}>
                                    {allText ? allText : 'All'}
                                </span>
                            }
                            tabIndex={-1}
                            options={options}
                            value={value}
                            onChange={value => (setFilterValue(value), setDirty(true))}
                        />
                    </div>
                    <div className="text-right">
                        <LightButton
                            id="filter-category-cancel"
                            type="button"
                            className="me-2"
                            onClick={() => (setIsDropdownShow(false), setDirty(false))}>
                            Cancel
                        </LightButton>
                        <PrimaryButton
                            id="filter-category-submit"
                            type="button"
                            disabled={!dirty}
                            onClick={() => (submitCallback(formatValue()), setDirty(false), setIsDropdownShow(false))}>
                            Filter
                        </PrimaryButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FilterCategory;
