import { BasicCellOptions, CellEventCallback, CellValue, DataItem, Header } from 'components/Shared/Table/types';
import { ReactNode } from 'react';

export interface FordVehicle {
    fordScrapeManagerVehicleId: number;
    yearName: number;
    yearId: string;
    modelName: string;
    modelId: number;
    shouldVehicleBeRun: boolean;
    hasBeenReviewed: boolean;
    hasWorkshopManual: boolean;
    isDeleted: boolean;
    createDate: string;
    updateDate: string;
    notes: string;
    [key: string]: string | boolean | number;
}

export interface GMVehicle {
    importPublicationId: number;
    pubsyskey: number;
    pubName: string;
    shouldBeRun: boolean;
    hasBeenReviewed: boolean;
    createDate: string;
    updateDate: string;
    vehicleList: string;
    notes: string;
    [key: string]: string | boolean | number;
}

export interface HyundaiVehicle {
    id: number;
    year: number;
    modelValue: string;
    modelName: string;
    modelCode: string;
    shouldBeRun: boolean;
    hasBeenReviewed: boolean;
    notes: string;
    [key: string]: string | boolean | number;
}

export interface GenesisVehicle {
    id: number;
    year: number;
    modelValue: string;
    modelName: string;
    modelCode: string;
    shouldBeRun: boolean;
    hasBeenReviewed: boolean;
    notes: string;
    [key: string]: string | boolean | number;
}

export interface MitsubishiVehicle {
    id: number;
    year: number;
    modelValue: string;
    modelName: string;
    modelCode: string;
    shouldBeRun: boolean;
    hasBeenReviewed: boolean;
    notes: string;
    [key: string]: string | boolean | number;
}

export interface VehicleCountByStatus {
    new: number;
    on: number;
    off: number;
}

export enum IngestionButton {
    NewButton,
    OnButton,
    OffButton,
    None,
}

export interface IngestionProcess extends BasicCellOptions {
    newButton: {
        id: string;
        idKey?: string;
        title?: string;
        class?: string;
    };
    onButton: {
        id: string;
        idKey?: string;
        title?: string;
        class?: string;
    };
    offButton: {
        id: string;
        idKey?: string;
        title?: string;
        class?: string;
    };
}

export interface TextAreaCellOptions extends BasicCellOptions {
    textArea: {
        id: string;
        idKey?: string;
        text?: string;
        title?: string;
        class?: string;
        icon?: string;
        component?: (item: DataItem) => ReactNode;
        onBlur?: (id: number, note: string) => void;
    };
}

export interface CellProps {
    idKey?: string;
    value?: CellValue;
    header?: Header;
    item?: DataItem;
    index?: number;
    cellEventCallback?: CellEventCallback;
}
