import { useContext, useMemo } from 'react';
import { NotificationsContext } from 'components/Shared/Notifications/Notifications';

type ApplyMappingRuleCommand = {
    operationId: number;
    oemId?: number;
    rpBookIds: number[];
    ruleList: number[];
    userId: number;
};

type ApplyUndoMappingRuleCommand = {
    operationId: number;
    oemId: number;
    ruleId: number;
    userId: number;
};

type PushNotificationFunc = (body: string | React.FC, option?: unknown) => void;

type NotificationInterface = {
    pushSuccess: PushNotificationFunc;
    pushInfo: PushNotificationFunc;
    pushWarning: PushNotificationFunc;
    pushDanger: PushNotificationFunc;
    pushExceptionDanger: PushNotificationFunc;
};

type NotificationsContextInterface = {
    notifications: NotificationInterface;
};

const s = (n: number): string => (n > 1 || n === 0 ? 's' : '');
const getAmount = (idList: unknown[]): string => (idList.length ? idList.length.toString() : 'all');

const useSignalRCallbacks = (refreshRuleStatistics, setIsRunnedFlag) => {
    const { notifications } = useContext<NotificationsContextInterface>(NotificationsContext);

    return useMemo(() => {
        return {
            onApplyMappingRuleStarted: (command: ApplyMappingRuleCommand) => {
                setIsRunnedFlag(command.ruleList);
                notifications.pushSuccess(
                    `User ${command.userId} runs ${getAmount(command.ruleList)} rule${s(
                        command.ruleList.length
                    )} for ${getAmount(command.rpBookIds)} bookId${s(command.rpBookIds.length)}`
                );
            },
            onApplyMappingRuleFinished: (command: ApplyMappingRuleCommand, success: boolean, error?: string) => {
                if (success) {
                    for (const ruleId of command.ruleList) {
                        refreshRuleStatistics(ruleId);
                    }
                    notifications.pushSuccess(`Operation ${command.operationId} finished with success`);
                } else {
                    notifications.pushDanger(`Ooperation ${command.operationId} failed with error ${error}`);
                }
            },
            onUndoMappingRuleStarted: (command: ApplyUndoMappingRuleCommand) => {
                notifications.pushSuccess(`User ${command.userId} undos rule id: ${command.ruleId}`);
            },
            onUndoMappingRuleFinished: (command: ApplyUndoMappingRuleCommand, success: boolean, error?: string) => {
                if (success) {
                    refreshRuleStatistics(command.ruleId);
                    notifications.pushSuccess(`Operation ${command.operationId} finished with success`);
                } else {
                    notifications.pushDanger(`Ooperation ${command.operationId} failed with error ${error}`);
                }
            },
        };
    }, [notifications, refreshRuleStatistics, setIsRunnedFlag]);
};

export default useSignalRCallbacks;
