import { FC, ReactNode } from 'react';

export interface CellProps {
    idKey?: string;
    value?: CellValue;
    header?: Header;
    item?: DataItem;
    index?: number;
    cellEventCallback?: CellEventCallback;
}

export interface Header {
    label: string;
    id?: string;
    title?: string;
    thClass?: string;
    component?: FC<CellProps>;
}

export interface Options {
    stickyHeader: boolean;
    checkboxes: boolean;
    disableCheckboxFn?: (rd: DataItem) => boolean;
}

export interface TableProps {
    headers: Header[];
    data: DataItem[];
    idKey?: string;
    selected?: ItemReturnEvent[];
    setSelected?: CheckRowCallback;
    cellEventCallbacks?: { [cellEventId: string]: CellEventCallback };
    options?: Options;
    className?: string;
    addDefaultPadding?: boolean;
    noDataMessage?: string;
    tableHtmlId?: string;
    tableHeaderId?: string;
}

export interface TableRowProps {
    idKey: string;
    rowData: DataItem;
    headers: Header[];
    index: number;
    checked: boolean;
    checkboxDisabled: boolean;
    toggleCheck: ToggleCheck;
    settings: Options;
    cellEventCallback: CellEventCallback;
    tableRowHtmlId?: string;
}

export interface TableCellProps {
    idKey: string;
    header: Header;
    rowData: DataItem;
    index: number;
    cellEventCallback: CellEventCallback;
}

export interface CheckAllButtonProps {
    checkAllState: CheckAllStates;
    toggleCheckAll: (e: CheckAllStates) => void;
}

export interface FAProps {
    icon: [string, string];
    className: string;
    title: string;
}

export interface BasicCellOptions {
    class?: string | ((item: DataItem) => string);
}

export interface NumberCellOptions extends BasicCellOptions {
    highlightFn?: (rd: DataItem) => boolean;
}

export interface ButtonCellOptions extends BasicCellOptions {
    buttons: {
        id: string;
        idKey?: string;
        text?: string;
        title?: string;
        class?: string;
        icon?: string;
        component?: (item: DataItem) => ReactNode;
        show?: (item: DataItem) => boolean;
        disabled?: (item: DataItem) => boolean;
    }[];
}

export interface SliderCellOptions extends BasicCellOptions {
    sliders: {
        id: string;
        class?: string;
        checkedTitle?: string;
        uncheckedTitle?: string;
        checked?: (item: DataItem) => boolean;
        disabled?: (item: DataItem) => boolean;
    }[];
}

export interface LabelWithPills {
    labelText: string;
    pillTexts: string[];
}

export interface LabelsCellOptions extends BasicCellOptions {
    labelClass?: string;
    pillsClass?: string;
    labels?: (i: DataItem) => LabelWithPills[];
}

export enum CheckAllStates {
    unchecked,
    checked,
    undetermined,
}

export type CellValue = string | number;

export type DataItemProp = CellValue | unknown;

export type DataItem = Record<string, DataItemProp>;

export type CheckRowCallback = (f: (current: DataItem[]) => DataItem[]) => void;

export type CellEventCallback = (e: ItemReturnEvent) => void;

export interface TextCellOptions extends BasicCellOptions {
    transform?: (i: DataItem) => CellValue | null;
}

export type LocalDateCellOptions = BasicCellOptions;

export type ItemReturnEvent = { checked?: boolean; item: DataItem; index: number; id: string; actionId?: string };

export type ToggleCheck = (e: ItemReturnEvent) => void;

export interface ActionFloatingMenuCellProps extends CellProps {
    floatingMenuId: string;
    floatingMenuClass?: string;
    labelId: string;
    options: ButtonCellOptions;
}
