import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBat, faJackOLantern, faSpiderWeb } from '@fortawesome/pro-light-svg-icons';
import './HalloweenOver.scss';

export function HalloweenOver() {
    return (
        <span className="halloween-overlay">
            <FontAwesomeIcon
                icon={faJackOLantern}
                style={{ left: '55px', top: '29px', fontSize: '2rem', color: 'white' }}
            />
            <FontAwesomeIcon
                icon={faSpiderWeb}
                style={{ left: '85px', top: '-20px', fontSize: '2.5rem', color: '#f0f0f060' }}
            />
            <FontAwesomeIcon
                icon={faBat}
                style={{ left: '228px', top: '-1px', fontSize: '1.5rem', color: '#f0f0f0a0' }}
            />
        </span>
    );
}
