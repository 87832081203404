import React from 'react';
import useDynamicTableHeading from './useDynamicTableHeading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DynamicTableHeading = ({ columnDetails, updateColumnWidth, handleSortColumnClick, sortColumn }) => {
    const { localWidth, hideDynamicKeyList } = useDynamicTableHeading(columnDetails, updateColumnWidth);

    if (!columnDetails.isHidden && !hideDynamicKeyList.includes(columnDetails.text)) {
        return (
            <th
                id={`dynamic-th-${columnDetails.text}`}
                className="position-relative overflow-hidden clickable"
                key={columnDetails.text}
                style={{ maxWidth: localWidth, minWidth: localWidth, textOverflow: 'ellipsis' }}
                onClick={() => handleSortColumnClick(columnDetails.text)}>
                <div className="d-flex align-items-center">
                    {sortColumn !== null && sortColumn.key === columnDetails.text && (
                        <FontAwesomeIcon icon={sortColumn.direction === 'A' ? 'sort-up' : 'sort-down'} />
                    )}
                    <span className="ms-2">{columnDetails.text}</span>
                </div>
                <span className="resize-handle" onClick={e => e.stopPropagation()}></span>
            </th>
        );
    } else {
        return null;
    }
};

export default DynamicTableHeading;
