import React, { useState, useEffect, useContext } from 'react';
import { requestGetVehicleTypesWithRegions } from 'api/vehicleInfo';
import { uniq, without, concat, difference } from 'lodash';
import { ToastContext } from 'components/ToastProvider';

const useTest3DModel = () => {
    const [modelData, setModelData] = useState(null);
    const [vehicleTypes, setVehicleTypes] = useState([]);
    const [hiddenRegions, setHiddenRegions] = useState([]);
    const [selectedVehicleIndex, SetSelectedVehicleIndex] = useState(0);
    const [regionsOnModel, setRegionsOnModel] = useState([]);
    const [allRegionNames, setAllRegionNames] = useState([]);
    const [regionsShouldBeOnTheModel, setRegionsShouldBeOnTheModel] = useState([]);
    const [regionsNotExpectedOnModel, setRegionsNotExpectedOnModel] = useState([]);
    const [regionsShouldNotBeOnModel, setRegionsShouldNotBeOnModel] = useState([]);
    const { showToast } = useContext(ToastContext);
    const [materials, setMaterials] = useState(null);
    const [selectedExteriorColor, setSelectedExteriorColor] = useState(null);

    useEffect(() => {
        const getVehicleData = async () => {
            try {
                let vehicleTypes = await requestGetVehicleTypesWithRegions();
                let allRegionNames = [];

                vehicleTypes.forEach(vehicleType => {
                    allRegionNames = allRegionNames.concat(
                        vehicleType.regions.map(region => region.regionName.toLocaleLowerCase())
                    );
                });

                vehicleTypes.forEach(vehicleType => {
                    vehicleType.regions.forEach(region => {
                        region.regionName = region.regionName.toLocaleLowerCase();
                    });
                });
                allRegionNames = uniq(allRegionNames);

                setVehicleTypes(vehicleTypes);
                setAllRegionNames(allRegionNames);
            } catch (error) {
                showToast(error);
            }
        };
        getVehicleData();
    }, [showToast]);

    useEffect(() => {
        if (vehicleTypes.length) {
            let availableRegions = vehicleTypes[selectedVehicleIndex].regions.filter(r => r.availableInModel);
            let newRegionsShouldBeOnTheModel = [];
            availableRegions.map(ar =>
                newRegionsShouldBeOnTheModel.push({
                    regionName: ar.regionName,
                    isPresent: regionsOnModel.includes(ar.regionName),
                })
            );
            newRegionsShouldBeOnTheModel = newRegionsShouldBeOnTheModel.sort((a, b) =>
                a.isPresent < b.isPresent ? -1 : 1
            );

            let notAvailableRegions = vehicleTypes[selectedVehicleIndex].regions.filter(r => !r.availableInModel);
            let newRegionsNotExpectedOnModel = [];
            notAvailableRegions.map(ar =>
                newRegionsNotExpectedOnModel.push({
                    regionName: ar.regionName,
                    isPresent: regionsOnModel.includes(ar.regionName),
                })
            );
            newRegionsNotExpectedOnModel = newRegionsNotExpectedOnModel.sort((a, b) =>
                a.isPresent < b.isPresent ? 1 : -1
            );

            let regionsForSelectedVehicleType = vehicleTypes[selectedVehicleIndex].regions.map(
                region => region.regionName
            );
            let newRegionsShouldNotBeOnModel = difference(regionsOnModel, regionsForSelectedVehicleType);
            newRegionsShouldNotBeOnModel = newRegionsShouldNotBeOnModel.map(ar => {
                return { regionName: ar };
            });

            setRegionsShouldBeOnTheModel(newRegionsShouldBeOnTheModel);
            setRegionsNotExpectedOnModel(newRegionsNotExpectedOnModel);
            setRegionsShouldNotBeOnModel(newRegionsShouldNotBeOnModel);
        } else {
            setRegionsShouldBeOnTheModel([]);
            setRegionsNotExpectedOnModel([]);
            setRegionsShouldNotBeOnModel([]);
        }
    }, [selectedVehicleIndex, vehicleTypes, regionsOnModel]);

    const handleSetModelData = modelData => {
        setSelectedExteriorColor(null);
        setModelData(modelData);
    };

    const toggleHiddenRegion = regionName => {
        setHiddenRegions(
            hiddenRegions.includes(regionName) ? without(hiddenRegions, regionName) : concat(hiddenRegions, regionName)
        );
    };

    const toggleAllRegions = () => {
        setHiddenRegions(hiddenRegions.length ? [] : allRegionNames);
    };

    const generateMaterialsToggleTiles = (materials, presentClass, notPresentClass) => {
        return (
            <div className="m-2">
                {materials.map(m => {
                    return (
                        <div key={m.materialName}>
                            <div className="mb-2 p-2 shadow-sm bg-white d-flex justify-content-between align-items-center">
                                <div>{m.materialName}</div>
                                {m.isPresent ? (
                                    <div className={presentClass}>Present</div>
                                ) : (
                                    <div className={notPresentClass}>Not present</div>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    const generateRegionToggleTiles = (regionNames, notPresentClass) => {
        return (
            <div className="m-2">
                {regionNames.map(rn => (
                    <div
                        key={rn.regionName}
                        className="mb-2 p-2 shadow-sm bg-white d-flex justify-content-between align-items-center">
                        <div className="mr-2">{rn.regionName}</div>
                        {regionsOnModel.includes(rn.regionName) ? (
                            <div className="switch switch-sm">
                                <input
                                    type="checkbox"
                                    className="switch"
                                    id={`toggle-${rn.regionName}`}
                                    checked={!hiddenRegions.includes(rn.regionName)}
                                    onChange={() => toggleHiddenRegion(rn.regionName)}
                                />
                                <label className="text-darkblue mt-2 ml-2" htmlFor={`toggle-${rn.regionName}`}></label>
                            </div>
                        ) : (
                            <div className={notPresentClass}>Not present</div>
                        )}
                    </div>
                ))}
            </div>
        );
    };

    return {
        modelData,
        vehicleTypes,
        handleSetModelData,
        SetSelectedVehicleIndex,
        hiddenRegions,
        toggleAllRegions,
        selectedVehicleIndex,
        generateMaterialsToggleTiles,
        generateRegionToggleTiles,
        regionsShouldBeOnTheModel,
        regionsNotExpectedOnModel,
        regionsShouldNotBeOnModel,
        allRegionNames,
        toggleHiddenRegion,
        setRegionsOnModel,
        materials,
        setMaterials,
        selectedExteriorColor,
        setSelectedExteriorColor,
    };
};

export default useTest3DModel;
