import React from 'react';
import { Tooltip } from 'react-tooltip';
import { LargeCheckbox } from 'oemiq-common';

const ProcedureFilterCheckboxes = ({ filterCheckboxes, setFilterCheckboxes }) => {
    const { filterNoGroups, filterNoType, filterRefreshedPending, filterOnlyHotSheet, filterRemovedRemovalPending } =
        filterCheckboxes;

    const removedRemovalPendingTooltipText = text => {
        return text.split('\n').map((item, key) => {
            return (
                <span key={key}>
                    {item}
                    <br />
                </span>
            );
        });
    };

    return (
        <>
            <div className="d-flex align-items-center my-2">
                <div className="d-flex align-items-center me-4">
                    <div style={{ height: '21px' }}>
                        <LargeCheckbox
                            id="filter-no-groups"
                            label="Filter no group"
                            className="large-checkbox-check mb-0"
                            checked={filterNoGroups}
                            onChange={e =>
                                setFilterCheckboxes({
                                    ...filterCheckboxes,
                                    filterNoGroups: e.target.checked,
                                })
                            }
                            readOnly={false}
                        />
                    </div>
                </div>
                <div className="d-flex align-items-center me-4">
                    <div style={{ height: '21px' }}>
                        <LargeCheckbox
                            id="filter-no-type"
                            label="Filter no type"
                            className="large-checkbox-check mb-0"
                            checked={filterNoType}
                            onChange={e =>
                                setFilterCheckboxes({
                                    ...filterCheckboxes,
                                    filterNoType: e.target.checked,
                                })
                            }
                            readOnly={false}
                        />
                    </div>
                </div>
                <div className="d-flex align-items-center me-4">
                    <div style={{ height: '21px' }}>
                        <LargeCheckbox
                            id="filter-refreshed-pending"
                            label="Filter refreshed pending"
                            className="large-checkbox-check mb-0"
                            checked={filterRefreshedPending}
                            onChange={e =>
                                setFilterCheckboxes({
                                    ...filterCheckboxes,
                                    filterRefreshedPending: e.target.checked,
                                })
                            }
                            readOnly={false}
                        />
                    </div>
                </div>
                <div className="d-flex align-items-center me-4">
                    <div style={{ height: '21px' }}>
                        <LargeCheckbox
                            id="filter-only-hotsheet"
                            label="Filter HotSheet group only"
                            className="large-checkbox-check mb-0"
                            checked={filterOnlyHotSheet}
                            onChange={e =>
                                setFilterCheckboxes({
                                    ...filterCheckboxes,
                                    filterOnlyHotSheet: e.target.checked,
                                })
                            }
                            readOnly={false}
                        />
                    </div>
                </div>
                <div>
                    <div style={{ height: '21px' }} data-tooltip-id="removedRemovalPendingTooltip">
                        <LargeCheckbox
                            id="filter-removed-removal-pending"
                            label="Include Removed / Removal Pending"
                            className="large-checkbox-check mb-0"
                            checked={filterRemovedRemovalPending}
                            onChange={e =>
                                setFilterCheckboxes({
                                    ...filterCheckboxes,
                                    filterRemovedRemovalPending: e.target.checked,
                                })
                            }
                            readOnly={false}
                        />
                        <Tooltip id="removedRemovalPendingTooltip" place="top" effect="solid">
                            {removedRemovalPendingTooltipText(
                                'Gray background color for Removed\nLight red background color for Removal Pending'
                            )}
                        </Tooltip>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProcedureFilterCheckboxes;
