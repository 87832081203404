import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Breadcrumb from './items/Breadcrumb';
import useProcedureTitle from './items/useProcedureTitle';
import kebab2Camel from './utils/kebab2Camel';
import OemNameBreadcrumb from 'components/Navigation/items/OemNameBreadcrumb';
import BookNameBreadcrumb from './items/BookNameBreadcrumb';

const Tools = {
    tagger: 'Tagger',
    flagger: 'Flagger',
    flagDispositionRules: 'Flag Disposition Rules',
};

const TaggingProcessNavigation = () => {
    const { taggingTool, oemId, bookId, procedureId, tagId } = useParams();
    const { procedureTitle } = useProcedureTitle(procedureId);

    const isBulkEdit = useLocation().pathname.includes('/bulkedit');

    return (
        <ol className="breadcrumb">
            <Breadcrumb isShown={true} isCurrent={!taggingTool} link={'/tagging-process'} text={'Tagging Process'} />
            {taggingTool && (
                <Breadcrumb
                    isShown={true}
                    isCurrent={!oemId}
                    link={`/tagging-process/${taggingTool}`}
                    text={Tools[kebab2Camel(taggingTool)]}
                />
            )}
            {taggingTool && oemId && (
                <OemNameBreadcrumb
                    oemId={oemId}
                    isCurrent={!isBulkEdit && !bookId}
                    link={`/tagging-process/${taggingTool}/${oemId}`}
                    withImportJob={taggingTool === 'tagger'}
                />
            )}
            {taggingTool && oemId && bookId && (
                <BookNameBreadcrumb
                    oemId={oemId}
                    bookId={bookId}
                    baseUrl={`/tagging-process/${taggingTool}`}
                    isCurrent={procedureId || tagId ? false : true}
                />
            )}
            {taggingTool == 'tagger' && oemId && bookId && procedureId && (
                <Breadcrumb isShown={true} isCurrent={true} text={procedureTitle} />
            )}
            {taggingTool == 'tagger' && oemId && bookId && tagId && (
                <Breadcrumb isShown={true} isCurrent={true} text={`tagid:${tagId}`} />
            )}
        </ol>
    );
};

export default TaggingProcessNavigation;
