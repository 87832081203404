import { useState, useEffect, useContext } from 'react';
import { LoadingContext } from 'components/Layout';
import { ToastContext } from 'components/ToastProvider';
import { requestOemHasBooks, requestOemHasProcedureVehicles } from 'api/RepairProcedureApi';
import { requestOemHasVehicles } from 'api/vehicleInfo';
import useOems from 'hooks/useOems';
import { OemInfo } from './types';

const DAY_IN_MS = 86400000;

const useGetOemInfo = mode => {
    const [oemInfo, setOemInfo] = useState<OemInfo[]>([]);
    const [storageKey, setStorageKey] = useState(null);
    const [activeMode, setActiveMode] = useState({ hasBooks: true }); //default to active by if hasbooks

    const { oems } = useOems();
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const { showToast } = useContext<{ showToast }>(ToastContext);

    const checkOemIsActive = async (oem, mode) => {
        if (mode.hasVehicles) {
            const hasVehicles = await requestOemHasVehicles(oem.oemId);
            oem.isActive = hasVehicles;
        } else if (mode.isInIngestionManager) {
            oem.isActive = true;
        } else if (mode.hasProcedureVehicles) {
            const hasProcedureVehicles = await requestOemHasProcedureVehicles(oem.oemId);
            oem.isActive = hasProcedureVehicles;
        } else {
            //default by has books
            const hasBooks = await requestOemHasBooks(oem.oemId);
            oem.isActive = hasBooks;
        }
    };

    const checkStorageForOemsList = key => {
        const storageOems = JSON.parse(sessionStorage.getItem(key));
        const dayNotPassed = storageOems ? Date.now() - DAY_IN_MS - storageOems.timestamp < 0 : false;
        if (dayNotPassed) {
            setOemInfo(storageOems.oemsInfo);
            return true;
        }
        return false;
    };

    useEffect(() => {
        if (mode == null || mode.hasBooks || mode.procedureTools) {
            setStorageKey('oemBooksInfo');
        } else if (mode.hasVehicles || mode.isInIngestionManager) {
            setStorageKey('oemVehiclesInfo');
            setActiveMode(mode);
        } else if (mode.hasProcedureVehicles) {
            setStorageKey('oemProcedureVehiclesInfo');
            setActiveMode(mode);
        }
    }, [mode, activeMode, setActiveMode, storageKey, setStorageKey]);

    useEffect(() => {
        const getOems = async () => {
            if (storageKey != null && oems.length > 0 && !checkStorageForOemsList(storageKey)) {
                try {
                    incrementLoading();
                    const newOems = [...oems];
                    const promises = [];
                    newOems.forEach(oem => {
                        promises.push(checkOemIsActive(oem, activeMode));
                    });
                    await Promise.all(promises);
                    sessionStorage.setItem(
                        storageKey,
                        JSON.stringify({
                            timestamp: Date.now(),
                            oemsInfo: newOems,
                        })
                    );
                    setOemInfo(newOems);
                } catch (error) {
                    showToast(error);
                } finally {
                    decrementLoading();
                }
            }
        };
        getOems();
    }, [oems, mode, activeMode, storageKey, setStorageKey, showToast, incrementLoading, decrementLoading]);

    return { oemInfo };
};

export default useGetOemInfo;
