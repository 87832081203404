import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { customStyles } from './Constants';

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: customStyles.tooltip.backgroundColor,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: customStyles.tooltip.backgroundColor,
    },
}));

export default CustomTooltip;
