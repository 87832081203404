import React, { useRef, useContext, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SORT_ORDER } from 'enums/SortOrderEnum';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ColumnContext } from 'components/Shared/NewDataTable/Contextes';

export const OemProceduresHeaderCell = ({
    getDisplayName,
    isSortable,
    onSorting,
}: {
    getDisplayName: (id: string) => string;
    isSortable: (id: string) => boolean;
    onSorting: (id: string, order: SORT_ORDER) => unknown;
}) => {
    const id = useContext(ColumnContext);
    const sortable = useMemo(() => isSortable(id), [id, isSortable]);
    const displayName = useMemo(() => getDisplayName(id), [getDisplayName, id]);
    const sortOrderRef = useRef(0);
    const sortOrders: { order: SORT_ORDER; icon?: IconProp }[] = [
        { order: SORT_ORDER.none, icon: null },
        { order: SORT_ORDER.asc, icon: 'sort-up' },
        { order: SORT_ORDER.desc, icon: 'sort-down' },
    ];

    return (
        <div
            className={`d-flex align-items-center ${sortable ? 'clickable' : ''}`}
            onClick={() => {
                if (sortable) {
                    sortOrderRef.current = (sortOrderRef.current + 1) % sortOrders.length;
                    onSorting(id, sortOrders[sortOrderRef.current].order);
                }
            }}>
            {sortOrders[sortOrderRef.current].icon && <FontAwesomeIcon icon={sortOrders[sortOrderRef.current].icon} />}
            {displayName}
        </div>
    );
};
