// AppInsights.jsx
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import apiConfig from './ApiConfig';

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: 'not yet loaded',
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory },
        },
    },
});

let _settings;

let filteringFunction = envelope => {
    if (envelope.baseType === 'PageviewData') {
        //admin portal doesn't need to know what its users are doing, does it?
        return false;
    }
    //console.log(envelope); //Turn this on to see the porperties of each packet, and filter as desired.
    return true;
};

//initialize
(async function init() {
    _settings = await apiConfig.get();
    appInsights.config.instrumentationKey = _settings.instrumentationKey;
    appInsights.loadAppInsights();
    appInsights.addTelemetryInitializer(filteringFunction);
})();

export { reactPlugin, appInsights };
