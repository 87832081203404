import { useCallback } from 'react';

const useTableActions = onDeleteClick => {
    const deleteNetwork = useCallback(
        e => {
            onDeleteClick(e.item);
        },
        [onDeleteClick]
    );
    return { deleteNetwork };
};

export default useTableActions;
