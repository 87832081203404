import { useState, useEffect, useCallback, useRef, useContext, useMemo } from 'react';
import useOemService from 'hooks/OemModels/useOemService';
import { NotificationsContext } from 'components/Shared/Notifications/Notifications';
import { ESProcedure } from 'components/locations/MappingProcess/Mapper/ESProcedure';
import { FilterCheckboxes, FilterClause } from 'components/locations/MappingProcess/Mapper/MapperTool';
import { SortOrderClause } from 'enums/SortOrderEnum';
import { DataSource } from 'hooks/OemModels/MetaModel/DataSource';

const autoReloadAfterChanges = false;

const useProcedures = (
    dataFilters: FilterClause[],
    filterCheckboxes: FilterCheckboxes,
    orderByClause: SortOrderClause[] | null,
    oemId: number | string,
    includeDeletedProcedures: boolean,
    dataSource: DataSource
) => {
    const [data, setData] = useState<ESProcedure[]>([]);
    const [proceduresStaleData, setProceduresStaleData] = useState({});
    const [hasMoreData, setHasMoreData] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [totalCount, setTotalCount] = useState({ error: false, value: 0, loadingCount: false, selectableCount: 0 });
    const { oemService } = useOemService(oemId);
    const page = useRef(0);
    const pageSize = 100;
    const { notifications } = useContext(NotificationsContext);

    useEffect(() => {
        setData([]);
        setProceduresStaleData({});
        setHasMoreData(true);
        page.current = 0;
    }, [dataSource, dataFilters, filterCheckboxes, orderByClause, includeDeletedProcedures]);

    const updateProceduresCount = useCallback(async () => {
        setTotalCount(c => {
            return {
                ...c,
                error: false,
                loadingCount: true,
            };
        });

        try {
            if (dataSource !== DataSource.ES) {
                const countPromise = oemService.countSQLProcedures(dataFilters, filterCheckboxes);
                const selectableCountPromise = includeDeletedProcedures
                    ? oemService.countSQLProcedures(dataFilters, {
                          ...filterCheckboxes,
                          filterRemovedRemovalPending: false,
                      })
                    : countPromise;
                const [count, selectableCount] = await Promise.all([countPromise, selectableCountPromise]);
                setTotalCount(c => {
                    return {
                        ...c,
                        error: false,
                        value: count,
                        selectableCount,
                        loadingCount: false,
                    };
                });
            }
        } catch (e) {
            setTotalCount(c => {
                return {
                    ...c,
                    error: true,
                    value: undefined,
                    selectableCount: undefined,
                    loadingCount: false,
                };
            });
            throw e;
        }
    }, [dataFilters, dataSource, filterCheckboxes, includeDeletedProcedures, oemService]);

    useEffect(() => {
        updateProceduresCount();
    }, [updateProceduresCount]);

    const handleReloadButton = useCallback(() => {
        page.current = 0;
        setHasMoreData(true);
        updateProceduresCount();
        setData([]);
        setProceduresStaleData({});
    }, [updateProceduresCount]);

    const updateProceduresStaleData = useCallback(
        async (newProceduresIds: number[]) => {
            const newStaleData = {};
            for (let i = 0; i < newProceduresIds.length; i += pageSize) {
                const resp = await oemService.getProceduresStaleData(newProceduresIds.slice(i, i + pageSize));
                for (const procedureStaleData of resp.value) {
                    newStaleData[procedureStaleData.procedureId] = procedureStaleData.updateDate;
                }
            }

            setProceduresStaleData(proceduresStaleData => ({ ...proceduresStaleData, ...newStaleData }));
        },
        [oemService]
    );

    const reloadProcedures = useCallback(async () => {
        setLoading(true);
        try {
            updateProceduresCount();
            let data = [];
            for (let i = 0; i < page.current; i++) {
                if (dataSource === DataSource.ES) {
                    const calculateCount = i === 0;

                    const { totalResults, procedures } = await oemService.getESProcedures(
                        dataFilters,
                        filterCheckboxes,
                        orderByClause,
                        pageSize,
                        pageSize * i,
                        calculateCount
                    );
                    data = [...data, ...procedures];

                    if (calculateCount) {
                        const selectableCount = includeDeletedProcedures
                            ? await oemService.countESProcedures(dataFilters, {
                                  ...filterCheckboxes,
                                  filterRemovedRemovalPending: false,
                              })
                            : totalResults;

                        setTotalCount(c => {
                            return {
                                ...c,
                                error: false,
                                value: totalResults,
                                selectableCount: selectableCount,
                                loadingCount: false,
                            };
                        });
                    }
                } else if (dataSource === DataSource.SQL) {
                    const oDataResponse = await oemService.getSQLProcedures(
                        dataFilters,
                        filterCheckboxes,
                        orderByClause,
                        pageSize,
                        pageSize * i
                    );
                    data = [...data, ...oDataResponse.value];
                }
            }

            setHasMoreData(data.length === pageSize * page.current);
            setData(data);
            await updateProceduresStaleData(data.map(p => p.procedureId));
        } catch (error) {
            setData([]);
            setProceduresStaleData({});
            setHasMoreData(false);
            notifications.pushExceptionDanger(error);
        } finally {
            setLoading(false);
        }
    }, [
        dataFilters,
        dataSource,
        filterCheckboxes,
        includeDeletedProcedures,
        notifications,
        oemService,
        orderByClause,
        updateProceduresCount,
        updateProceduresStaleData,
    ]);

    const getProcedureIds = useCallback(
        async (top: number, skip: number): Promise<number[]> => {
            const procedures = (await oemService.getProcedureIds(
                dataFilters,
                filterCheckboxes,
                orderByClause,
                top,
                skip
            )) as ESProcedure[];
            return procedures.map(p => p.procedureId);
        },
        [dataFilters, filterCheckboxes, oemService, orderByClause]
    );

    // needs to be updated when bulk mapper will be worked on
    const updateProcedures = procedures => {
        if (autoReloadAfterChanges) {
            return reloadProcedures();
        }

        setData(procedure => {
            const newProcedures = [...procedure];
            procedures.forEach(up => {
                const index = newProcedures.findIndex(np => np.procedureId === up.procedureId);
                newProcedures[index] = up;
            });

            return newProcedures;
        });

        return updateProceduresStaleData(procedures.map(p => p.procedureId));
    };

    const updateOemIqTypeForProcedureIds = (newOemIqType, procedureIds: number[], statusId: number) => {
        if (autoReloadAfterChanges) {
            return reloadProcedures();
        }

        const mappedType = { mappingRuleId: null, mappingStatusId: statusId, typeId: newOemIqType?.value };

        setData(currProcedures => {
            return currProcedures.map(p => {
                if (procedureIds.includes(p.procedureId)) {
                    const newProcedure = { ...p };
                    newProcedure.stageArea.type = mappedType;
                    return newProcedure;
                }
                return p;
            });
        });

        return updateProceduresStaleData(procedureIds);
    };

    const setNewGroupListToProcedureByProcedureId = useCallback(
        (newGroupList, procedureId) => {
            if (autoReloadAfterChanges) {
                return reloadProcedures();
            }

            const mappedGroups = newGroupList.map(g => {
                return {
                    groupId: g.regionId,
                    mappingRuleId: null,
                    mappingStatusId: g.mappingWorkFlowStatus.mappingWorkFlowStatusId,
                };
            });

            setData(currProcedures => {
                return currProcedures.map(p => {
                    if (p.procedureId === procedureId) {
                        const newProcedure = { ...p };
                        newProcedure.stageArea.groups = mappedGroups;
                        return newProcedure;
                    }
                    return p;
                });
            });

            return updateProceduresStaleData([procedureId]);
        },
        [reloadProcedures, updateProceduresStaleData]
    );

    const loadMoreCallback = useCallback(async () => {
        setLoading(true);
        try {
            if (dataSource === DataSource.ES) {
                const calculateCount = page.current === 0;

                const { totalResults, procedures } = await oemService.getESProcedures(
                    dataFilters,
                    filterCheckboxes,
                    orderByClause,
                    pageSize,
                    pageSize * page.current,
                    calculateCount
                );

                const esProcedures = procedures as unknown as ESProcedure[];

                setData(d => [...d, ...esProcedures]);

                if (calculateCount) {
                    const selectableCount = includeDeletedProcedures
                        ? await oemService.countESProcedures(dataFilters, {
                              ...filterCheckboxes,
                              filterRemovedRemovalPending: false,
                          })
                        : totalResults;

                    setTotalCount(c => {
                        return {
                            ...c,
                            error: false,
                            value: totalResults,
                            selectableCount: selectableCount,
                            loadingCount: false,
                        };
                    });
                }

                setHasMoreData(procedures.length === pageSize);
                await updateProceduresStaleData(esProcedures.map(p => p.procedureId));
            } else if (dataSource === DataSource.SQL) {
                const oDataResponse = await oemService.getSQLProcedures(
                    dataFilters,
                    filterCheckboxes,
                    orderByClause,
                    pageSize,
                    pageSize * page.current
                );
                const data = oDataResponse.value as unknown as ESProcedure[];
                setData(d => [...d, ...data]);
                setHasMoreData(data.length === pageSize);
                await updateProceduresStaleData(data.map(p => p.procedureId));
            }

            page.current++;
        } catch (error) {
            setData([]);
            setProceduresStaleData({});
            setHasMoreData(false);
            notifications.pushExceptionDanger(error);
        } finally {
            setLoading(false);
        }
    }, [
        dataSource,
        dataFilters,
        filterCheckboxes,
        includeDeletedProcedures,
        notifications,
        oemService,
        orderByClause,
        updateProceduresStaleData,
    ]);

    const infusedData = useMemo(
        () =>
            data.map(p => {
                const sqlUpdateDate = proceduresStaleData[p.procedureId];
                const updateDate = p.updateDate.slice(-1) === 'Z' ? p.updateDate : p.updateDate + 'Z';
                return {
                    ...p,
                    updateDate,
                    sqlUpdateDate,
                    isStale: sqlUpdateDate && sqlUpdateDate !== updateDate,
                };
            }),
        [data, proceduresStaleData]
    );

    return {
        data: infusedData,
        hasMoreData,
        loading,
        totalCount,
        loadMoreCallback,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        refreshProcedures: () => {}, // this is old code from mapper-old
        handleReloadButton,
        updateProcedures,
        updateOemIqTypeForProcedureIds,
        setNewGroupListToProcedureByProcedureId,
        getProcedureIds,
    };
};

export default useProcedures;
