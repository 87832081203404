import { ItemReturnEvent } from 'components/Shared/Table/types';
import { useCallback, useMemo } from 'react';

export const useFordTableActions = (
    turnFordVehiclesOn: (fordScrapeManagerVehicleId: number) => Promise<void>,
    turnFordVehiclesOff: (fordScrapeManagerVehicleId: number) => Promise<void>
) => {
    const handleOnButton = useCallback(
        async (e: ItemReturnEvent) => {
            await turnFordVehiclesOn(e.item.fordScrapeManagerVehicleId as number);
        },
        [turnFordVehiclesOn]
    );

    const handleOffButton = useCallback(
        async (e: ItemReturnEvent) => {
            await turnFordVehiclesOff(e.item.fordScrapeManagerVehicleId as number);
        },
        [turnFordVehiclesOff]
    );

    return useMemo(
        () => ({
            handleOnButton,
            handleOffButton,
        }),
        [handleOnButton, handleOffButton]
    );
};

export const useGMTableActions = (
    turnGMVehiclesOn: (importPublicationId: number) => Promise<void>,
    turnGMVehiclesOff: (importPublicationId: number) => Promise<void>
) => {
    const handleOnButton = useCallback(
        async (e: ItemReturnEvent) => {
            await turnGMVehiclesOn(e.item.importPublicationId as number);
        },
        [turnGMVehiclesOn]
    );

    const handleOffButton = useCallback(
        async (e: ItemReturnEvent) => {
            await turnGMVehiclesOff(e.item.importPublicationId as number);
        },
        [turnGMVehiclesOff]
    );

    return useMemo(
        () => ({
            handleOnButton,
            handleOffButton,
        }),
        [handleOnButton, handleOffButton]
    );
};

export const useHyundaiTableActions = (
    turnHyundaiVehiclesOn: (id: number) => Promise<void>,
    turnHyundaiVehiclesOff: (id: number) => Promise<void>
) => {
    const handleOnButton = useCallback(
        async (e: ItemReturnEvent) => {
            await turnHyundaiVehiclesOn(e.item.id as number);
        },
        [turnHyundaiVehiclesOn]
    );

    const handleOffButton = useCallback(
        async (e: ItemReturnEvent) => {
            await turnHyundaiVehiclesOff(e.item.id as number);
        },
        [turnHyundaiVehiclesOff]
    );

    return useMemo(
        () => ({
            handleOnButton,
            handleOffButton,
        }),
        [handleOnButton, handleOffButton]
    );
};

export const useGenesisTableActions = (
    turnGenesisVehiclesOn: (id: number) => Promise<void>,
    turnGenesisVehiclesOff: (id: number) => Promise<void>
) => {
    const handleOnButton = useCallback(
        async (e: ItemReturnEvent) => {
            await turnGenesisVehiclesOn(e.item.id as number);
        },
        [turnGenesisVehiclesOn]
    );

    const handleOffButton = useCallback(
        async (e: ItemReturnEvent) => {
            await turnGenesisVehiclesOff(e.item.id as number);
        },
        [turnGenesisVehiclesOff]
    );

    return useMemo(
        () => ({
            handleOnButton,
            handleOffButton,
        }),
        [handleOnButton, handleOffButton]
    );
};

export const useMitsubishiTableActions = (
    turnMitsubishiVehiclesOn: (id: number) => Promise<void>,
    turnMitsubishiVehiclesOff: (id: number) => Promise<void>
) => {
    const handleOnButton = useCallback(
        async (e: ItemReturnEvent) => {
            await turnMitsubishiVehiclesOn(e.item.id as number);
        },
        [turnMitsubishiVehiclesOn]
    );

    const handleOffButton = useCallback(
        async (e: ItemReturnEvent) => {
            await turnMitsubishiVehiclesOff(e.item.id as number);
        },
        [turnMitsubishiVehiclesOff]
    );

    return useMemo(
        () => ({
            handleOnButton,
            handleOffButton,
        }),
        [handleOnButton, handleOffButton]
    );
};
