import React, { useContext, useState } from 'react';
import Table from 'components/Shared/Table/Table';
import useTableConfiguration from '../tableConfiguration';
import { NotificationsContext } from 'components/Shared/Notifications/Notifications';
import { OemId } from 'helpers/OemId';
import useFordVehicles from './useFordVehicles';
import { useFordTableActions } from '../tableActions';
import useModal from 'hooks/useModal';
import BulkEditModal from '../BulkEditModal';
import InfiniteScroll from 'components/InfiniteScroll';
import IngestionManagerFooter from '../IngestionManagerFooter';
import IngestionManagerSearch from '../IngestionManagerSearch';

const tableOptions = {
    checkboxes: true,
    stickyHeader: true,
};

const FordIngestionManager = () => {
    const oemId = OemId.Ford;
    const { notifications } = useContext(NotificationsContext);

    const {
        vehicles,
        isLoading,
        loadMoreCallback,
        onSearch,
        vehiclesCountByStatus,
        turnFordVehiclesOn,
        turnFordVehiclesOff,
        bulkTurnFordVehiclesOn,
        bulkTurnFordVehiclesOff,
    } = useFordVehicles(notifications);

    const headers = useTableConfiguration(oemId);
    const actions = useFordTableActions(turnFordVehiclesOn, turnFordVehiclesOff);

    const [selected, setSelected] = useState([]);

    const applyChanges = (applyOn: boolean) => {
        const ids = selected.map(s => s.id);
        applyOn ? bulkTurnFordVehiclesOn(ids) : bulkTurnFordVehiclesOff(ids);
    };

    const {
        isModalOpen: isBulkEditModalOpen,
        openModal: openBulkEditModal,
        closeModal: closeBulkEditModal,
    } = useModal();

    return (
        <>
            <IngestionManagerSearch onSearch={onSearch} countByStatus={vehiclesCountByStatus} />
            <Table
                className="mb-5 ingestion-process-table"
                headers={headers}
                data={vehicles}
                idKey="fordScrapeManagerVehicleId"
                cellEventCallbacks={actions}
                selected={selected}
                setSelected={setSelected}
                options={tableOptions}
            />
            {vehicles.length > 0 && <InfiniteScroll isLoading={isLoading} loadMoreCallback={loadMoreCallback} />}
            <IngestionManagerFooter selectedLength={selected.length} openBulkEditModal={openBulkEditModal} />
            <div>
                {isBulkEditModalOpen && (
                    <BulkEditModal
                        isBulkEditModalOpen={isBulkEditModalOpen}
                        closeBulkEditModal={closeBulkEditModal}
                        applyChanges={applyChanges}
                    />
                )}
            </div>
        </>
    );
};

export default FordIngestionManager;
