import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { requestUpdateOneTimeUseTag } from 'api/RepairProcedureApi';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { ToastContext } from 'components/ToastProvider';

const LinkTagsControls = ({ tag, tagIds }) => {
    const [nextTagId, setNextTagId] = useState(null);
    const [prevTagId, setPrevTagId] = useState(null);
    let navigate = useNavigate();
    const { oemId, bookId } = useParams();
    const { showToast } = useContext(ToastContext);

    useEffect(() => {
        if (tagIds.length > 0 && tag) {
            const currentIndex = tagIds.findIndex(id => id === parseInt(tag.stagedOneTimeUseTagId));
            if (currentIndex !== -1) {
                const nextTagId = currentIndex === tagIds.length - 1 ? null : tagIds[currentIndex + 1];
                const previousTagId = currentIndex === 0 ? null : tagIds[currentIndex - 1];

                setNextTagId(nextTagId);
                setPrevTagId(previousTagId);
            } else {
                // something's wrong (refreshing, bookmark, etc.)
                setNextTagId(null);
                setPrevTagId(null);
            }
        }
    }, [tagIds, tag]);

    const handleZeroLinkClick = async () => {
        let updateTagCommand = [
            {
                stagedOneTimeUseTagId: tag.stagedOneTimeUseTagId,
                title: tag.title,
                quantity: tag.quantity,
                text: tag.text,
                colorHex: tag.colorHex,
                note: tag.note,
                linksChecked: true,
                workFlowStatusid: null,
                oneTimeUsePartTypeid: tag.oneTimeUsePartType.oneTimeUsePartTypeId,
                startPath: null,
                endPath: null,
                tagImages: tag.oneTimeUseTagImages.map(i => i.imageUrl),
            },
        ];

        try {
            await requestUpdateOneTimeUseTag(updateTagCommand);
        } catch (error) {
            showToast(error);
        }
    };

    return (
        <div className="row">
            <div className="col">
                <div className="d-flex align-items-center justify-content-between">
                    <button
                        type="button"
                        className="btn btn-sm btn-link font-weight-bold"
                        disabled={prevTagId === null}
                        onClick={() => navigate(`/tagging-process/tagger/${oemId}/${bookId}/link/${prevTagId}`)}>
                        <FontAwesomeIcon icon="chevron-left" /> Previous Tag
                    </button>
                    <button type="button" className="btn btn-sm btn-primary" onClick={() => handleZeroLinkClick()}>
                        0 Tag Links
                    </button>
                    <button
                        type="button"
                        className="btn btn-sm btn-link font-weight-bold"
                        disabled={nextTagId === null}
                        onClick={() => navigate(`/tagging-process/tagger/${oemId}/${bookId}/link/${nextTagId}`)}>
                        Next Tag <FontAwesomeIcon icon="chevron-right" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default LinkTagsControls;
