import { DataItem } from 'components/Shared/Table/types';
import { RefreshPublisherBookStatisticsType } from 'types/RefreshPublisherBookStatisticsType';
import { RpBookType } from 'types/RpBookType';

export interface PublishStatus {
    percentage: number;
    current: number;
}

export enum RefreshPublisherTableActionTypes {
    PublishBook = '1',
    RefreshBookStats = '2',
}

export interface RefreshPublisherDataRowType extends DataItem, RpBookType {
    removedProcedures?: RefreshPublisherBookStatisticsType['removalPending'];
    mappingStatus?: string;
    totalProcedures?: RefreshPublisherBookStatisticsType['totalProcedures'];
    totalMapped?: RefreshPublisherBookStatisticsType['completedMappedProcedures'];
    totalLatestToPublish?: RefreshPublisherBookStatisticsType['refreshedProcedures'];
    totalRemovedPending?: RefreshPublisherBookStatisticsType['removalPending'];
    totalStageAreaChanges?: RefreshPublisherBookStatisticsType['stageAreaChanges'];
    totalHtmlLocationNull?: RefreshPublisherBookStatisticsType['htmlLocationNull'];
    totalOnlyHotSheetMapped?: RefreshPublisherBookStatisticsType['onlyHotSheetMapped'];
    unresolvedFlags?: RefreshPublisherBookStatisticsType['unresolvedFlags'];
    unresolvedTags?: RefreshPublisherBookStatisticsType['unresolvedTags'];
    bookPublishState?: RefreshPublisherBookStatisticsType['bookPublishState']; // to control the publish button
    lastPublishDate?: RefreshPublisherBookStatisticsType['lastPublishDate'];
    loadedAt?: string;
}

export interface RefreshPublisherPagination {
    currentPageNumber: number;
    itemsPerPage: number;
}
