import { useCallback, useContext } from 'react';
import ReactSelect from 'react-select';
import { Input } from 'oemiq-common';
import { MappingDefinitionsContext } from 'contexts/MappingDefinitionsContext';

export const BasicEditValueComponent = ({ value, property, onChange, ...props }) => {
    const handleChange = useCallback(e => onChange(e.target.value), [onChange]);

    return <Input type={property.type.type} onChange={handleChange} value={value} {...props} />;
};

export const BoolEditValueComponent = ({ value, onChange, ...props }) => {
    return (
        <ReactSelect
            autosize={false}
            menuPortalTarget={document.body}
            styles={{
                menuPortal: base => ({ ...base, zIndex: 9999 }),
            }}
            value={value}
            isClearable={true}
            options={[
                { value: true, label: 'true' },
                { value: false, label: 'false' },
            ]}
            onChange={onChange}
            {...props}
        />
    );
};

export const GroupEditValueComponent = ({ value, onChange }) => {
    const mappingDefinitions = useContext(MappingDefinitionsContext);

    return (
        mappingDefinitions?.groups?.length && (
            <ReactSelect
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({ ...base, zIndex: 9999 }),
                }}
                placeholder="Select Available Groups"
                isMulti={true}
                options={mappingDefinitions.groups}
                value={value}
                onChange={onChange}
            />
        )
    );
};

export const GroupIdEditValueComponent = ({ value, onChange }) => {
    const mappingDefinitions = useContext(MappingDefinitionsContext);

    return (
        mappingDefinitions?.groups?.length && (
            <ReactSelect
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({ ...base, zIndex: 9999 }),
                }}
                placeholder="Select Available Groups"
                isMulti={true}
                options={mappingDefinitions.groups}
                value={value}
                getOptionLabel={v => v.value}
                onChange={onChange}
            />
        )
    );
};
