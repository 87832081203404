export enum SORT_ORDER {
    none = '',
    asc = 'asc',
    desc = 'desc',
}

export type SortOrderClause = {
    elementId: string;
    order: SORT_ORDER;
};
