import React from 'react';

const TagDetails = ({ tag, viewModalOnClick }) => {
    return (
        <div>
            <div>
                <span className="font-weight-bold text-primary">Tag Name: </span>
                {tag.title}
            </div>
            <div>
                <span className="font-weight-bold text-primary">Procedure Name: </span>
                {tag.procedure.procedureTitle}
            </div>
            <hr />
            <div className="mt-2">
                <b>Quantity: </b>
                {tag.quantity ? tag.quantity : 'N/A'}
            </div>
            <div className="mt-2">
                <b>Part Type: </b>
                {tag.partType ? tag.partType : 'N/A'}
            </div>
            <div className="mt-2">
                <b>Tag Text: </b>
                {tag.text ? tag.text : 'N/A'}
            </div>
            <div className="mt-2">
                <b>Tag Image: </b>
                <div>
                    {tag.oneTimeUseTagImages.map((image, index) => {
                        return <img key={index} src={image.imageUrl} style={{ width: 150 }} alt="tag" />;
                    })}
                </div>
            </div>
            <div className="mt-2">
                <b>Tag Note: </b>
                {tag.note ? tag.note : 'N/A'}
            </div>

            <div className="mt-3">
                <button
                    type="button"
                    className="btn btn-sm btn-primary me-3"
                    onClick={e => viewModalOnClick(e, tag.procedureId)}>
                    View Procedure
                </button>
            </div>
        </div>
    );
};

export default TagDetails;
