import React from 'react';
import useUserSpeedCheck from 'hooks/useUserSpeedCheck';

export const PerformanceCheckContext = React.createContext();

const PerformanceCheckProvider = ({ children }) => {
    // import user speed check and run it in the background
    const { sampledSpeedKbps, showSpeedWarning } = useUserSpeedCheck();

    return (
        <PerformanceCheckContext.Provider value={{ sampledSpeedKbps, showSpeedWarning }}>
            {children}
        </PerformanceCheckContext.Provider>
    );
};

export default PerformanceCheckProvider;
