import { useState, useEffect, useContext, useRef, useCallback } from 'react';
import {
    requestMitsubishiBulkSetShouldVehicleBeRun,
    requestMitsubishiVehicleScrapeManagerOdata,
    requestMitsubishiVehicleWithCountByStatus,
} from 'api/RepairProcedureApi';
import { LoadingContext } from 'components/Layout';
import { ToastContext } from 'components/ToastProvider';
import { MitsubishiVehicle, IngestionButton, VehicleCountByStatus } from '../types';

const useMitsubishiVehicles = notifications => {
    const [vehicles, setVehicles] = useState<MitsubishiVehicle[]>([]);
    const [vehiclesCountByStatus, setVehiclesCountByStatus] = useState<VehicleCountByStatus>();
    const [loading, setLoading] = useState<boolean>(false);
    const [filters, setFilters] = useState<string>('');
    const [hasMore, setHasMore] = useState<boolean>(true);

    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const { showToast } = useContext<{ showToast }>(ToastContext);

    const page = useRef(0);
    const pageSize = 100;

    const loadVehicles = useCallback(async () => {
        if (!hasMore) return;
        try {
            setLoading(true);
            incrementLoading();

            const vehicles = await getVehicles(page.current, pageSize, filters);
            page.current++;

            if (vehicles.data.length < pageSize) setHasMore(false);

            if (vehicles.data.length > 0) {
                setVehicles(prevVehicles => {
                    const newVehicles = vehicles.data.filter(
                        veh => !prevVehicles.some(prevVehicle => prevVehicle.id === veh.id)
                    );
                    return [...prevVehicles, ...newVehicles];
                });
            }
        } catch (e) {
            notifications.pushExceptionDanger(e);
        } finally {
            setLoading(false);
            decrementLoading();
        }
    }, [incrementLoading, filters, setVehicles, notifications, decrementLoading, hasMore]);

    const getInitialVehicles = useCallback(async () => {
        try {
            setLoading(true);
            incrementLoading();

            page.current = 0;
            setHasMore(true);

            const response = await getVehiclesCountByStatus(page.current, pageSize, filters);
            const vehicles = response.data.vehicles;
            setVehiclesCountByStatus({
                new: response.data.newVehiclesCount,
                off: response.data.offVehiclesCount,
                on: response.data.onVehiclesCount,
            });
            if (vehicles.length < pageSize) {
                setHasMore(false);
            }
            if (response.error) {
                showToast(vehicles.error);
            }

            setVehicles(vehicles);
            page.current++;
        } catch (e) {
            notifications.pushExceptionDanger(e);
        } finally {
            setLoading(false);
            decrementLoading();
        }
    }, [decrementLoading, filters, incrementLoading, notifications, showToast]);

    useEffect(() => {
        getInitialVehicles();
    }, [getInitialVehicles]);

    const loadVehicleCount = useCallback(async () => {
        const response = await getVehiclesCountByStatus(0, 0, filters);
        setVehiclesCountByStatus({
            new: response.data.newVehiclesCount,
            off: response.data.offVehiclesCount,
            on: response.data.onVehiclesCount,
        });
    }, [filters]);

    const turnMitsubishiVehiclesOn = useCallback(
        async (id: number) => {
            try {
                const ids = [];
                ids.push(id);
                await requestMitsubishiBulkSetShouldVehicleBeRun(ids, true, true);
                setVehicles(vehicles =>
                    vehicles.map(v =>
                        ids.includes(v.id)
                            ? {
                                  ...v,
                                  shouldVehicleBeRun: true,
                                  hasBeenReviewed: true,
                              }
                            : v
                    )
                );
                loadVehicleCount();
            } catch (e) {
                notifications.pushExceptionDanger(e);
            }
        },
        [loadVehicleCount, notifications]
    );

    const turnMitsubishiVehiclesOff = useCallback(
        async (id: number) => {
            try {
                const ids = [];
                ids.push(id);
                await requestMitsubishiBulkSetShouldVehicleBeRun(ids, false, true);
                setVehicles(vehicles =>
                    vehicles.map(v =>
                        ids.includes(v.id)
                            ? {
                                  ...v,
                                  shouldVehicleBeRun: false,
                                  hasBeenReviewed: true,
                              }
                            : v
                    )
                );
                loadVehicleCount();
            } catch (e) {
                notifications.pushExceptionDanger(e);
            }
        },
        [loadVehicleCount, notifications]
    );

    const bulkTurnMitsubishiVehiclesOn = useCallback(
        async (ids: number[]) => {
            try {
                await requestMitsubishiBulkSetShouldVehicleBeRun(ids, true, true);
                setVehicles(vehicles =>
                    vehicles.map(v =>
                        ids.includes(v.id)
                            ? {
                                  ...v,
                                  shouldVehicleBeRun: true,
                                  hasBeenReviewed: true,
                              }
                            : v
                    )
                );
                loadVehicleCount();
            } catch (e) {
                notifications.pushExceptionDanger(e);
            }
        },
        [loadVehicleCount, notifications]
    );

    const bulkTurnMitsubishiVehiclesOff = useCallback(
        async (ids: number[]) => {
            try {
                await requestMitsubishiBulkSetShouldVehicleBeRun(ids, false, true);
                setVehicles(vehicles =>
                    vehicles.map(v =>
                        ids.includes(v.id)
                            ? {
                                  ...v,
                                  shouldVehicleBeRun: false,
                                  hasBeenReviewed: true,
                              }
                            : v
                    )
                );
                loadVehicleCount();
            } catch (e) {
                notifications.pushExceptionDanger(e);
            }
        },
        [loadVehicleCount, notifications]
    );

    const onSearch = (filter: string, radioValue: IngestionButton) => {
        const yearFilter = /^\d+$/.test(filter) ? `year eq ${filter}` : '';
        const modelNameFilter = `contains(modelName,'${filter}')`;
        const notesFilter = `contains(notes,'${filter}')`;

        const processFilter =
            radioValue === IngestionButton.NewButton
                ? 'and (hasBeenReviewed eq false)'
                : radioValue === IngestionButton.OnButton
                ? 'and (hasBeenReviewed eq true and shouldVehicleBeRun eq true)'
                : radioValue === IngestionButton.OffButton
                ? 'and (hasBeenReviewed eq true and shouldVehicleBeRun eq false)'
                : '';

        const parsedFilters = `isDeleted eq false and (${
            yearFilter && `${yearFilter} or`
        } ${modelNameFilter} or ${notesFilter}) ${processFilter}`;

        setFilters(parsedFilters);
    };

    return {
        vehicles,
        isLoading: loading,
        loadMoreCallback: loadVehicles,
        onSearch,
        vehiclesCountByStatus,
        turnMitsubishiVehiclesOn,
        turnMitsubishiVehiclesOff,
        bulkTurnMitsubishiVehiclesOn,
        bulkTurnMitsubishiVehiclesOff,
        hasMore,
    };
};

const getVehicles = async (page: number, pageSize: number, filter: string) => {
    try {
        const vehicles = await requestMitsubishiVehicleScrapeManagerOdata({
            top: pageSize,
            skip: pageSize * page,
            orderBy: 'modelName, modelCode, Year desc',
            filter: filter ? filter : 'isDeleted eq false',
        });
        return { data: vehicles.value, error: '' };
    } catch (e) {
        return { data: [], error: e };
    }
};

const getVehiclesCountByStatus = async (page: number, pageSize: number, filter: string) => {
    try {
        const response = await requestMitsubishiVehicleWithCountByStatus({
            top: pageSize,
            skip: pageSize * page,
            orderBy: 'modelName, modelCode, Year desc',
            filter: filter ? filter : 'isDeleted eq false',
            count: true,
        });
        return { data: response, error: '' };
    } catch (e) {
        return { data: [], error: e };
    }
};

export default useMitsubishiVehicles;
