import { AccessControlContext } from 'components/Shared/AccessControl/AccessControl';
import NavigationTile from 'components/Shared/Components/NavigationTile';
import React, { useEffect, useState, useContext } from 'react';
import fetchCounts from 'helpers/FetchCountsHelper';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type Tool = {
    id: string;
    path: string;
    text: string;
    secondText: string;
    access: string;
    icon: IconProp;
    tileDescription: string;
};

type ToolSelectionProps = {
    tools: Tool[];
};

const ToolSelection = ({ tools }: ToolSelectionProps) => {
    const { hasAccess } = useContext(AccessControlContext);

    const [notificationCount, setNotificationCount] = useState({});

    useEffect(() => {
        const fetchCountsData = async toolName => {
            const count = await fetchCounts(toolName);
            return count.total;
        };

        const loadNotifications = async () => {
            fetchCountsData('toolSelection').then(v => setNotificationCount(nc => ({ ...nc, 'Ingestion Manager': v })));
            fetchCountsData('vehicleManagement').then(v =>
                setNotificationCount(nc => ({ ...nc, 'Vehicle Management': v }))
            );
        };
        loadNotifications();
    }, []);

    return (
        <div className="container mt-4">
            <div className="row">
                {tools.map((t, i) => (
                    <NavigationTile
                        id={t.id}
                        key={i}
                        path={t.path}
                        text={t.text}
                        secondText={t.secondText}
                        icon={t.icon}
                        tileDescription={t.tileDescription}
                        display={hasAccess(t.access)}
                        notificationCount={notificationCount[t.text] ?? 0}
                    />
                ))}
            </div>
        </div>
    );
};

export default ToolSelection;
