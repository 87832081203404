import { LoadingContext } from 'components/Layout';
import { NotificationsContext } from 'components/Shared/Notifications/Notifications';
import {
    requestCreateCompanyUser,
    requestEditRole,
    requestEditUser,
    requestOrganizationCompanies,
    requestRoles,
} from 'api/SecurityApi';
import { useState, useEffect, useContext } from 'react';

const useUserModal = (user, toggle, orgId, companyId, onSaveOrEdit) => {
    // higher order states
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const { notifications } = useContext(NotificationsContext);
    // local states
    const [saveEnabled, setSaveEnabled] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [primaryLocation, setPrimaryLocation] = useState(0);
    const [locations, setLocations] = useState([]);
    const [userRole, setUserRole] = useState('');
    const [inEditState, setInEditState] = useState(false);
    const [awaitingResponse, setAwaitingResponse] = useState(false);
    const [orgLocations, setOrgLocations] = useState([]);
    const [multiSelectOptions, setMultiSelectOptions] = useState([]);
    const [primaryLocationOptions, setPrimaryLocationOptions] = useState([]);
    const [roles, setRoles] = useState([]);

    useEffect(() => {
        const getRoles = async () => {
            try {
                incrementLoading();
                const rolesJson = await requestRoles();
                setRoles(rolesJson);
            } catch (error) {
                notifications.pushExceptionDanger(error);
            } finally {
                decrementLoading();
            }
        };
        const getLocations = async () => {
            try {
                incrementLoading();
                let orgCompanies = await requestOrganizationCompanies(orgId);
                let userCompaniesIds = new Set(user.companies.map(c => c.companyId));
                let dropdownOptions = orgCompanies.filter(
                    oc => !oc.isDeactivated || userCompaniesIds.has(oc.companyId)
                );
                let primaryDropdownOptions = orgCompanies.filter(
                    oc => !oc.isDeactivated || oc.companyId == user.company.companyId
                );
                setOrgLocations(orgCompanies);
                setPrimaryLocationOptions(primaryDropdownOptions);
                setMultiSelectOptions(
                    dropdownOptions.map(l => {
                        return { label: l.companyName, value: l.companyId };
                    })
                );
            } catch (error) {
                notifications.pushExceptionDanger(error);
            } finally {
                decrementLoading();
            }
        };
        getRoles();
        getLocations();
    }, [orgId, notifications, incrementLoading, decrementLoading, user]);

    useEffect(() => {
        if (!user || !user.userId) return;

        setFirstName(user.firstName || '');
        setLastName(user.lastName || '');
        setUserName(user.userName || '');
        setPhone(user.phoneNumber || '');
        setEmail(user.email || '');
        setUserRole(user.roles[0] || '');
        setPrimaryLocation((user.company && user.company.companyId) || 0);
        const selectedCompanies = orgLocations.filter(l => user.companies.some(c => c.companyId === l.companyId));
        setLocations(
            selectedCompanies.map(l => {
                return { label: l.companyName, value: l.companyId };
            })
        );
    }, [user, orgLocations]);

    useEffect(() => {
        if (user && user.userId) {
            const selectedLocations = locations.map(l => l.value);
            const userLocations = user.companies.map(l => l.companyId);
            const areLocationsSame =
                userLocations.every(cid => selectedLocations.includes(cid)) &&
                selectedLocations.every(sl => userLocations.includes(sl));

            const isSame =
                user.firstName === firstName &&
                user.lastName === lastName &&
                user.userName === userName &&
                user.email === email &&
                (user.phoneNumber === phone || (user.phoneNumber === null && phone === '')) &&
                user.company.companyId === primaryLocation &&
                areLocationsSame &&
                user.roles[0] === userRole;

            const required =
                firstName &&
                firstName.length > 0 &&
                lastName &&
                lastName.length > 0 &&
                email &&
                email.length > 0 &&
                email.indexOf('@') > -1 &&
                email.indexOf('.') > -1 &&
                parseInt(primaryLocation) !== 0 &&
                userRole.length > 0 &&
                locations.length > 0;
            setSaveEnabled(!isSame && required);
            setInEditState(!isSame);
        }
    }, [firstName, lastName, userName, email, phone, primaryLocation, locations, userRole, user]);

    useEffect(() => {
        // handle requirements for new user
        if (!user || !user.userId) {
            const required =
                firstName &&
                firstName.length > 0 &&
                lastName &&
                lastName.length > 0 &&
                email &&
                email.length > 0 &&
                email.indexOf('@') > -1 &&
                email.indexOf('.') > -1 &&
                parseInt(primaryLocation) !== 0 &&
                userRole.length > 0 &&
                locations.length > 0;

            const noInfoEntered =
                firstName === '' && lastName === '' && email === '' && parseInt(primaryLocation) === 0;

            setSaveEnabled(required);
            setInEditState(!noInfoEntered);
        }
    }, [firstName, lastName, email, primaryLocation, locations, userRole, user]);

    const handleSave = async () => {
        if (!saveEnabled) return;

        try {
            setAwaitingResponse(true);
            if (user && user.userId) {
                await requestEditUser(
                    user.userId,
                    firstName,
                    lastName,
                    email,
                    phone,
                    primaryLocation,
                    locations.map(l => l.value)
                );
                if (user.roles[0] !== userRole) await requestEditRole(user.userId, [userRole]);
                notifications.pushSuccess(`User with email:${email} edited`);
            } else if (companyId) {
                await requestCreateCompanyUser(
                    companyId,
                    firstName,
                    lastName,
                    email,
                    phone,
                    userRole,
                    locations.map(l => l.value)
                );
                notifications.pushSuccess(`User with email:${email} created`);
            } else {
                await requestCreateCompanyUser(
                    primaryLocation,
                    firstName,
                    lastName,
                    email,
                    phone,
                    userRole,
                    locations.map(l => l.value)
                );
                notifications.pushSuccess(`User with email:${email} created`);
            }
            setInEditState(false);
            toggle();
            await onSaveOrEdit();
        } catch (error) {
            notifications.pushExceptionDanger(error, { pinned: false, body: error.message.split(';') });
        } finally {
            setAwaitingResponse(false);
        }
    };

    const handleSetPrimaryLocation = e => {
        // Auto add the primary location to the locations list
        const primaryId = parseInt(e.currentTarget.value);
        const primaryCompany = orgLocations.find(l => l.companyId === primaryId);
        setPrimaryLocation(primaryId);
        if (!locations.find(l => l.value === primaryId))
            setLocations([...locations, { label: primaryCompany.companyName, value: primaryId }]);
    };

    const handleLocationsChange = e => {
        // Don't allow the removal of the primary company from the locations
        if (e.length < locations.length) {
            let diff = locations.filter(l => !e.includes(l));
            if (diff[0].value === primaryLocation) return;
        }

        setLocations(e);
    };

    return {
        inEditState,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        userName,
        setUserName,
        email,
        setEmail,
        phone,
        setPhone,
        orgLocations,
        handleSetPrimaryLocation,
        primaryLocation,
        roles,
        userRole,
        setUserRole,
        multiSelectOptions,
        locations,
        handleLocationsChange,
        handleSave,
        saveEnabled,
        awaitingResponse,
        primaryLocationOptions,
    };
};

export default useUserModal;
