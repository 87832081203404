import { match } from 'ts-pattern';

const cellOperationAction = operation => {
    const getAmount = idList => (idList.length ? idList.length : 'all');
    const pluralizeWord = (word, count) => word + (count > 1 || count === 0 ? 's' : '');

    return match(operation.type)
        .with('ApplyRule', () => {
            return (
                `Running ${getAmount(operation.data.rulesIds)} ${pluralizeWord(
                    'rule',
                    operation.data.rulesIds.length
                )}` +
                ` for ${getAmount(operation.data.rpBooksIds)} ${pluralizeWord(
                    'book',
                    operation.data.rpBooksIds.length
                )}`
            );
        })
        .with('UndoRule', () => `Undoing rule id:${operation.data.ruleId}`)
        .with('ApplyFlagDispositionRules', () => 'Running all rules')
        .otherwise(() => '');
};

export default cellOperationAction;
