import React from 'react';
import { ReactModal } from 'oemiq-common';
import ViewVehiclePublishHistoryContent from './ViewVehiclePublishHistoryContent/ViewVehiclePublishHistoryContent';
import useViewVehiclePublishHistory from './useViewVehiclePublishHistory';

const ViewVehiclePublishHistory = ({ isOpen, toggleModal, vehicle }) => {
    const { vehicleDetails } = useViewVehiclePublishHistory(vehicle);

    return (
        <ReactModal
            id="group-type-modal"
            className="modal-xl"
            headerComponent={<b>Publish History: {vehicleDetails}</b>}
            isOpen={isOpen}
            toggle={toggleModal}
            bodyComponent={<ViewVehiclePublishHistoryContent vehicleId={vehicle.vehicleId} />}
        />
    );
};

export default ViewVehiclePublishHistory;
