import React from 'react';
import { NewDataTableHeaderCellProps, NewDataTableRowCellProps } from './types';
import { NewDataTableRowCell } from './NewDataTableBody';
import { NewDataTableHeaderCell } from './NewDataTableHeader';

export const isReactElement = (child): child is React.ReactElement => {
    return child && 'type' in child;
};

export const isRowCell = (child): child is React.ReactElement<NewDataTableRowCellProps> => {
    return child && 'type' in child && child.type === NewDataTableRowCell;
};

export const isHeaderCell = (child): child is React.ReactElement<NewDataTableHeaderCellProps> => {
    return child && 'type' in child && child.type === NewDataTableHeaderCell;
};

export const isReactFragment = (child): child is React.ReactFragment & { props: { children: React.ReactNode } } => {
    return child && 'type' in child && child.type === React.Fragment;
};
