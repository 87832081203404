import { requestSerializedImportJobCommand } from 'api/RepairProcedureApi';
import { ReactModal } from 'oemiq-common';
import React, { useEffect, useState } from 'react';

type ISerializeImportJobBookCommandModalProps = {
    open: boolean;
    onSerializedImportJobBookCommandModalClose: () => void;
    title: string;
    importJobBookCommandId: string;
};

const ImportJobBookCommandModal = ({
    open,
    onSerializedImportJobBookCommandModalClose,
    title = '',
    importJobBookCommandId,
}: ISerializeImportJobBookCommandModalProps) => {
    const [ImportJobBookCommand, setImportJobBookCommand] = useState<object>({});
    useEffect(() => {
        (async () => {
            const response = await requestSerializedImportJobCommand(importJobBookCommandId);
            setImportJobBookCommand(response);
        })();
    }, [importJobBookCommandId]);

    return (
        <ReactModal
            id="disposition-flag-modal"
            isOpen={open}
            toggle={onSerializedImportJobBookCommandModalClose}
            headerComponent={<div>{title}</div>}
            bodyComponent={
                <div>
                    <span>
                        <pre>{JSON.stringify(ImportJobBookCommand, undefined, 2)}</pre>
                    </span>
                </div>
            }
        />
    );
};

export default ImportJobBookCommandModal;
