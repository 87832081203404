import {
    JsonHubProtocol,
    HttpTransportType,
    HubConnectionBuilder,
    LogLevel,
    IHttpConnectionOptions,
} from '@microsoft/signalr';
import authService from 'auth/AuthenticationService';

export const buildSignalRConnection = (hub: string) => {
    const urlRoot = window.location.origin.toString();
    const connectionHub = `${urlRoot}/api/RepairProcedure/SignalR/${hub}`;
    const protocol = new JsonHubProtocol();
    const transport = HttpTransportType.WebSockets;

    const options: IHttpConnectionOptions = {
        transport,
        logMessageContent: true,
        logger: LogLevel.Error,
        accessTokenFactory: () => authService.getAccessToken(),
    };

    const connection = new HubConnectionBuilder()
        .withUrl(connectionHub, options)
        .withHubProtocol(protocol)
        .withAutomaticReconnect()
        // .configureLogging(LogLevel.Trace) //Show all signalR activity.
        .build();

    let timeout: NodeJS.Timeout;
    const start = async () => {
        timeout = null;
        try {
            await connection.start();
        } catch (err) {
            console.log(err);
            timeout = setTimeout(start, 5000);
        }
    };

    const stop = () => {
        if (timeout) {
            clearTimeout(timeout);
        }
        if (connection) {
            connection.stop();
        }
    };

    connection.onclose(async (e?: Error) => {
        if (e) {
            await start();
        }
    });

    return { connection, start, stop };
};
