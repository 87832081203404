import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useNavigationButtons from './useNavigationButtons';
import { useLocation, useNavigate } from 'react-router-dom';

const NavigationButtons = () => {
    const { nextProcedureId, previousProcedureId, bookId, oemId } = useNavigationButtons();
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div className="d-flex align-items-center justify-content-between mt-3">
            <button
                type="button"
                disabled={!previousProcedureId}
                className="btn btn-sm btn-primary"
                onClick={() =>
                    navigate(`/tagging-process/tagger/${oemId}/${bookId}/tag/${previousProcedureId}`, {
                        state: location.state,
                    })
                }>
                <FontAwesomeIcon icon="chevron-left" className="me-2" />
                Previous
            </button>

            <button
                type="button"
                className="btn btn-sm btn-primary"
                onClick={() => {
                    navigate(`/tagging-process/tagger/${oemId}/${bookId}?${new URLSearchParams(location.state)}`);
                }}>
                Return to List
            </button>

            <button
                type="button"
                disabled={!nextProcedureId}
                className="btn btn-sm btn-primary"
                onClick={() =>
                    navigate(`/tagging-process/tagger/${oemId}/${bookId}/tag/${nextProcedureId}`, {
                        state: location.state,
                    })
                }>
                Next <FontAwesomeIcon icon="chevron-right" className="ms-2" />
            </button>
        </div>
    );
};

export default NavigationButtons;
