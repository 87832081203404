import React from 'react';
import { Button } from 'react-bootstrap';

interface IngestionManagerFooterProps {
    selectedLength: number;
    openBulkEditModal: () => void;
}

const IngestionManagerFooter = ({ selectedLength, openBulkEditModal }: IngestionManagerFooterProps) => (
    <div
        id="ingestion-manager-bulk-edit-footer"
        className="py-2 border-top"
        style={{ backgroundColor: 'white', position: 'sticky', bottom: '0', zIndex: 1 }}>
        <div className="d-flex justify-content-between align-items-center px-3">
            <div></div>
            <div className="d-flex">
                <div className="pe-2">
                    <Button
                        variant="primary"
                        className="btn btn-primary"
                        onClick={openBulkEditModal}
                        disabled={selectedLength === 0}>
                        Bulk Edit
                    </Button>
                </div>
            </div>
        </div>
    </div>
);

export default IngestionManagerFooter;
