import React from 'react';
import useGraphExplorer from './useGraphExplorer';

const GraphExplorer = () => {
    const { svgNode } = useGraphExplorer();

    return (
        <div>
            <svg ref={svgNode} width="100%" height="100vh" />
        </div>
    );
};

export default GraphExplorer;
