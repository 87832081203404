import React from 'react';
import { CellProps, ItemReturnEvent } from 'components/Shared/Table/types';
import { RefreshDashboardRowDataType, RefreshDashboardActionTypes } from '../types';

const convertToReadableDate = (dateStr: string): string => dateStr.replace(/T/gi, ' ').substring(0, 19) + ' GMT';

const ScheduleCell: React.FC<CellProps> = ({ index, item, cellEventCallback }) => {
    const { isLoading, oemRefreshSchedules } = item as RefreshDashboardRowDataType;

    const buttonHandlerFactory = (id: number): (() => void) => {
        return (): void => {
            const payload: ItemReturnEvent = {
                id: id.toString(),
                index: index,
                item: item,
                actionId: RefreshDashboardActionTypes.EditNextScheduledRun,
            };

            cellEventCallback(payload);
        };
    };

    if (isLoading || !oemRefreshSchedules) return <td></td>;

    const id = `import-manager-item-${item.oemId}-schedule`;

    return (
        <td className="px-0" style={{ verticalAlign: 'middle' }} id={id}>
            <div className="d-flex flex-column">
                {oemRefreshSchedules.map((schedule, i) => {
                    const scheduleId = `${id}-${schedule.id}`;
                    return (
                        <div
                            id={scheduleId}
                            data-testid={scheduleId}
                            key={schedule.id}
                            className={`py-4 d-flex flex-row align-items-center ${
                                i === 0 ? 'border-0' : 'border-top'
                            }`}>
                            <div
                                title={`${schedule.isActive ? 'Active' : 'Inactive'}`}
                                className={`mx-2 ${schedule.isActive ? 'text-success' : 'text-danger'}`}>
                                {convertToReadableDate(schedule.nextScheduledRun)}
                            </div>
                            <div className="mx-2">
                                <button
                                    type="button"
                                    className="btn btn-outline-primary btn-sm"
                                    onClick={buttonHandlerFactory(schedule.id)}>
                                    Edit
                                </button>
                            </div>
                        </div>
                    );
                })}
            </div>
        </td>
    );
};

export default ScheduleCell;
