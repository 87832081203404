import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Icon = ({ icon, className, size, onClickFunction }) => {
    return <FontAwesomeIcon icon={icon} className={className} size={size} onClick={onClickFunction} />;
};

export default Icon;

Icon.propTypes = {
    icon: PropTypes.string.isRequired,
    className: PropTypes.string,
    size: PropTypes.string,
    onClickFunction: PropTypes.func,
};
