import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import './VehicleFilter.scss';
import { OemId } from 'helpers/OemId';
import { OemsMap } from 'helpers/OemsMap';
import { requestModelOemVehicles } from 'api/vehicleInfo';
import { useParams } from 'react-router-dom';
import {
    getMakesForModelSelection,
    getModelsForMakeSelection,
    VehicleMakeModel,
} from './logic/getMakeAndModelForSelection';
import { validateVehicleFilterValue } from './logic/validateVehicleFilterValue';

export enum YearOperator {
    eq,
    neq,
    range,
}

const yearOperatorOptions = [
    { label: '==', value: YearOperator.eq },
    { label: '!=', value: YearOperator.neq },
    { label: 'range', value: YearOperator.range },
];

export type VehicleFilter = {
    make?: { label: string; value: number };
    model?: { label: string; value: number };
    yearOperator?: { label: string; value: YearOperator };
    yearNumber?: string;
    fromYear?: string;
    toYear?: string;
};

type VehicleFilterProps = {
    filter;
    setFilterValue;
};

export const VehicleFilterComponent = ({ filter, setFilterValue }: VehicleFilterProps) => {
    const { oemId } = useParams();
    const mainOemId = parseInt(oemId) as OemId;

    const [allVehicles, setAllVehicles] = useState<VehicleMakeModel[]>([]);

    useEffect(() => {
        const fetchVehicles = async () => {
            const oems = OemsMap.getVehicleOemsForMajorOemId(mainOemId);
            const result = await requestModelOemVehicles(oems);
            const allVehicles: VehicleMakeModel[] = result.map(x => ({
                oemId: x.oemId,
                oemName: x.oem.oemName,
                modelId: x.modelId,
                modelName: x.model.modelName,
            }));
            setAllVehicles(allVehicles);
        };

        fetchVehicles();
    }, [mainOemId]);

    const makeOptions = useMemo(
        () => getMakesForModelSelection(allVehicles, filter.value?.model),
        [allVehicles, filter.value?.model]
    );

    const modelOptions = useMemo(
        () => getModelsForMakeSelection(allVehicles, filter.value?.make),
        [allVehicles, filter.value?.make]
    );

    const handleMakeChange = (selectedMake: { label: string; value: number }) => {
        const newFilterValue = { ...filter.value, make: selectedMake };
        setFilterValue({
            value: newFilterValue,
            valueList: formatValueList(newFilterValue),
        });
    };

    const handleModelChange = (selectedModel: { label: string; value: number }) => {
        const newFilterValue = { ...filter.value, model: selectedModel };
        setFilterValue({
            value: newFilterValue,
            valueList: formatValueList(newFilterValue),
        });
    };

    const handleYearNumberChange = useCallback(
        (inputNumber: string) => {
            const newFilterValue = {
                ...filter.value,
                yearOperator: filter.value?.yearOperator || yearOperatorOptions[0],
                yearNumber: inputNumber,
            };
            setFilterValue({
                value: newFilterValue,
                valueList: formatValueList(newFilterValue),
            });
        },
        [filter.value, setFilterValue]
    );

    const handleYearOperatorChange = useCallback(
        (inputOperator: { label: string; value: YearOperator }) => {
            if (inputOperator.value === YearOperator.eq || inputOperator.value === YearOperator.neq) {
                const newFilterValue = { ...filter.value, yearOperator: inputOperator, fromYear: '', toYear: '' };
                setFilterValue({
                    value: newFilterValue,
                    valueList: formatValueList(newFilterValue),
                });
            } else if (inputOperator.value === YearOperator.range) {
                const newFilterValue = { ...filter.value, yearOperator: inputOperator, yearNumber: '' };
                setFilterValue({
                    value: newFilterValue,
                    valueList: formatValueList(newFilterValue),
                });
            }
        },
        [filter.value, setFilterValue]
    );

    const handleFromValueChange = useCallback(
        (fromValue: string) => {
            const newFilterValue = {
                ...filter.value,
                yearOperator: yearOperatorOptions.find(x => x.value === YearOperator.range),
                fromYear: fromValue,
                toYear: filter.value.toYear,
            };
            setFilterValue({
                value: newFilterValue,
                valueList: formatValueList(newFilterValue),
            });
        },
        [filter.value, setFilterValue]
    );

    const handleToValueChange = useCallback(
        (toValue: string) => {
            const newFilterValue = {
                ...filter.value,
                yearOperator: yearOperatorOptions.find(x => x.value === YearOperator.range),
                fromYear: filter.value.fromYear,
                toYear: toValue,
            };
            setFilterValue({
                value: newFilterValue,
                valueList: formatValueList(newFilterValue),
            });
        },
        [filter.value, setFilterValue]
    );

    return (
        <div className="vehicle-filter">
            <section>
                <label className="vehicle-filter-label">Make</label>
                <Select
                    className="filter-select"
                    placeholder="Select Make"
                    aria-label="Select Make"
                    value={filter.value?.make}
                    isDisabled={!filter.id || !filter.operator}
                    isClearable={true}
                    options={makeOptions}
                    onChange={handleMakeChange}
                />
            </section>

            <section>
                <label className="vehicle-filter-label">Model</label>
                <Select
                    className="filter-select"
                    placeholder="Select Model"
                    aria-label="Select Model"
                    value={filter.value?.model}
                    isDisabled={!filter.id || !filter.operator}
                    isClearable={true}
                    options={modelOptions}
                    onChange={handleModelChange}
                />
            </section>

            <section aria-label="select year">
                <label className="vehicle-filter-label">Year</label>
                <Select
                    className="year-operator-select"
                    options={yearOperatorOptions}
                    placeholder="Choose operator"
                    aria-label="Select Year Operator"
                    isDisabled={!filter.id || !filter.operator}
                    value={filter.value?.yearOperator || yearOperatorOptions[0]}
                    onChange={handleYearOperatorChange}
                />
                {filter.value?.yearOperator?.value === YearOperator.range ? (
                    <>
                        <label className="vehicle-filter-label" htmlFor="input-year-from">
                            From
                        </label>
                        <Form.Control
                            placeholder="From Year"
                            type="number"
                            step="1"
                            disabled={!filter.id || !filter.operator}
                            value={filter.value?.fromYear}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFromValueChange(e.target.value)}
                        />
                        <label className="vehicle-filter-label" htmlFor="input-year-to">
                            To
                        </label>
                        <Form.Control
                            placeholder="To Year"
                            type="number"
                            step="1"
                            disabled={!filter.id || !filter.operator}
                            value={filter.value?.toYear}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleToValueChange(e.target.value)}
                        />
                    </>
                ) : (
                    <Form.Control
                        placeholder="Year"
                        type="number"
                        step="1"
                        disabled={!filter.id || !filter.operator}
                        value={filter.value?.yearNumber}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleYearNumberChange(e.target.value)}
                    />
                )}
            </section>
        </div>
    );
};

const formatValueList = (v: VehicleFilter) => {
    const valueList = [];
    if (v.make?.label) valueList.push(v.make.label);
    if (v.model?.label) valueList.push(v.model.label);
    if (v.yearOperator?.value === YearOperator.eq && v.yearNumber) valueList.push(v.yearNumber);
    if (v.yearOperator?.value === YearOperator.neq && v.yearNumber) valueList.push(`!= ${v.yearNumber}`);
    if (v.yearOperator?.value === YearOperator.range && (v.fromYear || v.toYear)) {
        if (v.fromYear && v.toYear) valueList.push(`${v.fromYear}-${v.toYear}`);
        else if (v.fromYear) valueList.push(`from ${v.fromYear}`);
        else if (v.toYear) valueList.push(`to ${v.toYear}`);
    }
    return valueList;
};

const operatorsList = {
    match: {
        value: 'vehicleList.match',
        label: 'Match',
    },
};

export default {
    component: VehicleFilterComponent,
    validator: validateVehicleFilterValue,
    operators: [operatorsList.match],
    defaultValue: '',
    allowFalse: false,
    allowInstances: 1,
};
