import { useState, useContext, useEffect } from 'react';
import { ToastContext } from 'components/ToastProvider';
import Breadcrumb from './Breadcrumb';
import { requestBooksForOemApi } from 'api/RepairProcedureApi';

const BookNameBreadcrumb = ({ oemId, bookId, baseUrl, isCurrent }) => {
    const [bookLinks, setBookLinks] = useState([]);
    const [bookName, setBookName] = useState();
    const { showToast } = useContext(ToastContext);

    useEffect(() => {
        const intOemId = parseInt(oemId);
        if (!intOemId) return;
        requestBooksForOemApi(intOemId)
            .then(books => {
                let links = [];
                books.forEach(b =>
                    links.push({
                        id: b.bookId,
                        link: `${baseUrl}/${oemId}/${b.bookId}`,
                        text: b.bookName,
                        key: b.bookId,
                        value: b.bookName,
                    })
                );
                setBookLinks(links);
                if (links.length && bookId) {
                    const name = links.find(bookLink => bookLink.id === parseInt(bookId)).text;
                    setBookName(name);
                }
            })
            .catch(error => showToast(error));
    }, [oemId, baseUrl, bookId, showToast]);

    return isCurrent ? (
        <Breadcrumb isShown={true} isCurrent={true} text={bookName} links={bookLinks} />
    ) : (
        <Breadcrumb isShown={true} isCurrent={false} text={bookName} link={`${baseUrl}/${oemId}/${bookId}`} />
    );
};

export default BookNameBreadcrumb;
