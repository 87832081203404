import React, { useState } from 'react';
import ReactModal from 'components/Shared/ReactModal';
import { Button, Form } from 'react-bootstrap';
import './IngestionManager.scss';

interface BulkEditModalProps {
    isBulkEditModalOpen: boolean;
    closeBulkEditModal: () => void;
    applyChanges: (x: boolean) => void;
}

const BulkEditModal = ({ isBulkEditModalOpen, closeBulkEditModal, applyChanges }: BulkEditModalProps) => {
    const [ingestionTurnedOn, setIngestionTurnedOn] = useState<boolean>(true);

    const handleApply = () => {
        applyChanges(ingestionTurnedOn);
        closeBulkEditModal();
    };

    return (
        <ReactModal
            id="ingestion-bulk-edit-modal"
            container={undefined}
            isOpen={isBulkEditModalOpen}
            toggle={closeBulkEditModal}
            headerComponent={<BulkEditModalHeader />}
            bodyComponent={
                <BulkEditModalBody ingestionTurnedOn={ingestionTurnedOn} setIngestionTurnedOn={setIngestionTurnedOn} />
            }
            footerComponent={<BulkEditModalFooter handleApply={handleApply} closeBulkEditModal={closeBulkEditModal} />}
            className={'sticky-footer'}
        />
    );
};

const BulkEditModalHeader = () => (
    <div className="d-flex justify-content-between" style={{ position: 'sticky', top: '0px' }}>
        <span>Bulk Edit</span>
    </div>
);

interface BulkEditModalBodyProps {
    ingestionTurnedOn: boolean;
    setIngestionTurnedOn: (x: boolean) => void;
}

const BulkEditModalBody = ({ ingestionTurnedOn, setIngestionTurnedOn }: BulkEditModalBodyProps) => (
    <>
        <div className="d-flex justify-content-between">
            <div>Turn the ingestion process ON/OFF</div>
            <Form.Check
                id="bulk-edit-modal-slider-ingestion-process"
                type="switch"
                className={`default-slider row`}
                checked={ingestionTurnedOn}
                onChange={e => setIngestionTurnedOn(e.target.checked)}
            />
        </div>
    </>
);

interface BulkEditModalFooterProps {
    handleApply: () => void;
    closeBulkEditModal: () => void;
}

const BulkEditModalFooter = ({ handleApply, closeBulkEditModal }: BulkEditModalFooterProps) => (
    <>
        <div className="flex-fill justify-content-between d-flex">
            <Button
                id="bulk-edit-modal-close"
                className="me-3 btn btn-light"
                type="button"
                onClick={closeBulkEditModal}>
                Cancel
            </Button>
            <Button id="bulk-edit-modal-apply" className="btn btn-primary" type="button" onClick={handleApply}>
                Apply
            </Button>
        </div>
    </>
);

export default BulkEditModal;
