import React from 'react';
import { LightButton, PrimaryButton } from 'oemiq-common';

const BulkAssignVehicleModalFooter = ({ onCloseButtonClick, onApplyButtonClick, applyButtonDisabled }) => {
    return (
        <div>
            <LightButton
                id="bulk-assign-vehicle-modal-cancel-button"
                className="mx-3 btn-border-radius"
                buttonProps={{ style: { minWidth: '120px' } }}
                onClick={onCloseButtonClick}>
                Cancel
            </LightButton>
            <PrimaryButton
                id="bulk-assign-vehicle-modal-apply-button"
                buttonProps={{ style: { minWidth: '120px' } }}
                className="mx-3"
                onClick={onApplyButtonClick}
                disabled={applyButtonDisabled}>
                Apply
            </PrimaryButton>
        </div>
    );
};

export default React.memo(BulkAssignVehicleModalFooter);
