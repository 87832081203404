import SmallSpinner from 'components/SmallSpinner';
import React from 'react';
import { FormatDateToGMT } from 'helpers/DateHelper';
import { ParseIso8601Duration } from 'helpers/DurationHelper';

const statusValues = {
    2: {
        status_message: 'Failed Import - Vehicles',
        status_class: 'text-danger',
    },
    1: {
        status_message: 'Completed Succesfully',
        status_class: 'text-success',
    },
    0: {
        status_message: 'Failed Import - NVD',
        status_class: 'text-danger',
    },
    null: {
        status_message: 'Import Running',
        status_class: '',
    },
};

const ImportItem = ({ importRecord }) => {
    const {
        success,
        importDuration,
        filesImported,
        vehicleImportCount,
        chromeModelBodyTypeImportCount,
        chromeModelImportCount,
        modelImportCount,
        trimImportCount,
        vehicleStyleImportCount,
        colourImportCount,
    } = importRecord;

    return (
        <tr>
            <td>{importRecord.importHistoryId}</td>
            <td>{FormatDateToGMT(importRecord.importDate)}</td>
            <td className={`${statusValues[success]?.status_class}`}>
                {statusValues[success]?.status_message}
                {success === null && (
                    <div className="d-inline-block ms-2">
                        <SmallSpinner />
                    </div>
                )}
            </td>
            <td>{ParseIso8601Duration(importDuration)}</td>
            <td>{success !== null ? filesImported : ''}</td>
            <td>{success === 1 ? vehicleImportCount : ''}</td>
            <td>{success === 1 ? chromeModelBodyTypeImportCount : ''}</td>
            <td>{success === 1 ? chromeModelImportCount : ''}</td>
            <td>{success === 1 ? modelImportCount : ''}</td>
            <td>{success === 1 ? trimImportCount : ''}</td>
            <td>{success === 1 ? vehicleStyleImportCount : ''}</td>
            <td>{success === 1 ? colourImportCount : ''}</td>
        </tr>
    );
};

export default React.memo(ImportItem);
