import { requestActiveFlagsCount, requestNewVehicleCounts } from 'api/RepairProcedureApi';
import { requestNewNVDVehicleCounts } from 'api/vehicleInfo';

const fetchCounts = async context => {
    try {
        switch (context) {
            case 'tagger': {
                // Transform array response to an object
                const activeFlagsResponse = await requestActiveFlagsCount();
                const activeFlagsCounts = activeFlagsResponse.activeFlagsCounts.reduce((acc, { oemId, count }) => {
                    acc[oemId] = { activeFlagsCount: count };
                    return acc;
                }, {});
                return activeFlagsCounts; // Return transformed active flags counts for tagger
            }
            case 'ingestionManager':
                return await requestNewVehicleCounts(); // Return new vehicle counts
            case 'toolSelection':
                return await requestNewVehicleCounts(); // Return total count for tool selection
            case 'vehicleManagement':
                return await requestNewNVDVehicleCounts();
            default:
                throw new Error(`Unknown context: ${context}`);
        }
    } catch (error) {
        console.error('Error fetching counts:', error);
        return {};
    }
};

export default fetchCounts;
