import React, { useCallback, useEffect, useState } from 'react';
import SmallSpinner from './SmallSpinner';

export const InfiniteScroll = ({ isLoading, loadMoreCallback }) => {
    const [observableElement, setObservableElement] = useState(null);
    const handleIntersecting = useCallback(
        entities => {
            entities.some(observer => observer.isIntersecting) && loadMoreCallback();
        },
        [loadMoreCallback]
    );

    useEffect(() => {
        if (observableElement) {
            const observer = new IntersectionObserver(handleIntersecting, { threshold: 0.0 });
            observer.observe(observableElement);
            return () => observer.unobserve(observableElement);
        }
    }, [observableElement, handleIntersecting]);

    return (
        <div className={`infinite-scroll ${!isLoading ? 'invisible' : ''}`} ref={setObservableElement}>
            <SmallSpinner />
        </div>
    );
};

export default InfiniteScroll;
