import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useContext, useMemo, useCallback } from 'react';
import { AccessControlContext } from 'components/Shared/AccessControl/AccessControl';
import { roles } from 'components/Shared/AccessControl/privilegesMap';
import Assign3dModel from './Assign3dModel';
import AssignOemModelToVehicle from './AssignOemModelToVehicle';
import { LargeCheckbox } from 'oemiq-common';
import { Switch } from '@mui/material';
import { FormatDateToGMT } from 'helpers/DateHelper';

const YesNoTile = ({ showConfirmation, currentValue, disabled, text, onUpdate, onToggle, disabledNoText }) =>
    showConfirmation ? (
        <td>
            <div>
                <span>
                    {text}
                    <span className="font-weight-bold">{currentValue ? ' OFF' : ' ON'}</span>?
                </span>
                <button
                    onClick={() => onUpdate()}
                    disabled={disabled}
                    className="btn btn-sm btn-success ms-2 me-2"
                    type="button">
                    <FontAwesomeIcon icon={'check'} />
                </button>
                <button onClick={onToggle} className="btn btn-sm btn-danger" type="button">
                    <FontAwesomeIcon icon={'times'} />
                </button>
            </div>
        </td>
    ) : (
        <td
            onClick={onToggle}
            className={`${currentValue ? 'text-success font-weight-bold' : ''} ${!disabled ? 'clickable' : ''}`}>
            {currentValue ? 'Yes' : disabled && disabledNoText ? disabledNoText : 'No'}
        </td>
    );

const VehicleItem = ({
    vehicleRecord,
    updateTrimFiltering,
    isActiveAssign3dModel,
    isActiveAssignOemModel,
    onToggleAssign3dModel,
    onToggleAssignOemModel,
    selectedVehicleIds,
    onCheckboxChange,
    onCheckboxKeyDown,
    onCheckboxKeyUp,
    setVisualModalViewerModel,
    onUpdateVehicleDisplayingTags,
    updateVehicles,
    onUpdateIsReviewed,
}) => {
    const { hasRole } = useContext(AccessControlContext);
    //restrict update trim level filtering flag to admin roles
    const canUpdateTrimFiltering = useMemo(() => hasRole(roles.admin) || hasRole(roles.siteAdmin), [hasRole]);
    const [showTrimConfirmation, setShowTrimConfirmation] = useState(false);

    // currently there is no role restriction (i.e. who can access this tool can update this bit)
    const canUpdateDisplayingTags =
        !vehicleRecord.isDisplayingTagsEnabled &&
        vehicleRecord.taggerStatistics.unresolvedFlagCount +
            vehicleRecord.taggerStatistics.activeIncompletedTagCount ===
            0;
    const [showDisplayingTagsConfirmation, setShowDisplayingTagsConfirmation] = useState(false);

    const onUpdateTrimFiltering = async () => {
        setShowTrimConfirmation(false);
        let isTrimLevelFilteringEnabledForVehicle = vehicleRecord.isTrimLevelFilteringEnabled;
        if (isTrimLevelFilteringEnabledForVehicle === null) isTrimLevelFilteringEnabledForVehicle = false;
        await updateTrimFiltering(vehicleRecord.vehicleId, !isTrimLevelFilteringEnabledForVehicle);
    };

    const handleCheckboxChange = () => {
        onCheckboxChange(vehicleRecord);
    };

    const isCheckboxChecked = !!selectedVehicleIds.find(id => id == vehicleRecord.vehicleId);

    const handleShowTrimConfirmationToggle = useCallback(
        () => setShowTrimConfirmation(prevState => !prevState && canUpdateTrimFiltering),
        [canUpdateTrimFiltering]
    );
    const handleShowDisplayingTagsConfirmationToggle = useCallback(
        () => setShowDisplayingTagsConfirmation(prev => !prev && canUpdateDisplayingTags),
        [canUpdateDisplayingTags]
    );

    const handleDisplayingTagsUpdateClick = async () => {
        setShowDisplayingTagsConfirmation(false);
        await onUpdateVehicleDisplayingTags(vehicleRecord.vehicleId, !vehicleRecord.isDisplayingTagsEnabled);
    };

    const handleIsReviewedChange = async e => {
        await onUpdateIsReviewed(vehicleRecord.vehicleId, e.target.checked);
    };

    return (
        <tr>
            <td>
                <LargeCheckbox
                    id={`vehicle-${vehicleRecord.vehicleId}-checkbox`}
                    onChange={handleCheckboxChange}
                    checked={isCheckboxChecked}
                    onKeyDown={onCheckboxKeyDown}
                    onKeyUp={onCheckboxKeyUp}
                />
            </td>
            <td>{vehicleRecord.vehicleId}</td>
            <td>{`${[vehicleRecord.year.yearValue, vehicleRecord.model.modelName, vehicleRecord.trim.trimName].join(
                ' '
            )}`}</td>
            <td>{vehicleRecord.locales.join(', ')}</td>
            <YesNoTile
                text={'Turn trim level filtering'}
                currentValue={vehicleRecord.isTrimLevelFilteringEnabled}
                disabled={!canUpdateTrimFiltering}
                showConfirmation={showTrimConfirmation}
                onToggle={handleShowTrimConfirmationToggle}
                onUpdate={onUpdateTrimFiltering}
            />
            <YesNoTile
                text={'Turn displaying tags bit'}
                currentValue={vehicleRecord.isDisplayingTagsEnabled}
                disabled={!canUpdateDisplayingTags}
                showConfirmation={showDisplayingTagsConfirmation}
                onToggle={handleShowDisplayingTagsConfirmationToggle}
                onUpdate={handleDisplayingTagsUpdateClick}
                disabledNoText={'Flags/tags not complete'}
            />
            <td>
                <Assign3dModel
                    vehicle={vehicleRecord}
                    active={isActiveAssign3dModel}
                    onToggle={onToggleAssign3dModel}
                    setVisualModalViewerModel={setVisualModalViewerModel}
                    updateVehicles={updateVehicles}
                />
            </td>
            <td>
                <AssignOemModelToVehicle
                    vehicle={vehicleRecord}
                    active={isActiveAssignOemModel}
                    onToggle={onToggleAssignOemModel}
                    updateVehicles={updateVehicles}
                />
            </td>
            <td>{FormatDateToGMT(vehicleRecord.createDate)}</td>
            <td>
                <Switch checked={vehicleRecord.isReviewed} onChange={handleIsReviewedChange} name="isReviewed" />
            </td>
        </tr>
    );
};

export default React.memo(VehicleItem);
