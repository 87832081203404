import { useState } from 'react';
import { TableFilters } from '../types';
import { DataItem } from 'components/Shared/Table/types';

const useManageCustomersTable = (
    isTableRowsClickable: boolean,
    sortClickCallback: (retSortedProperty: string, retSortedPropertyDirection: string) => void,
    rowClickCallback: (dataItem: DataItem) => void,
    filterChangeCallback: (filters: TableFilters) => void
) => {
    const [sortedPropertyKey, setSortedPropertyKey] = useState(null);
    const [sortedPropertyDirection, setSortedPropertyDirection] = useState(null);

    const handleSortableTableHeaderClick = (newSortedPropertyKey: string) => {
        let retSortedProperty = newSortedPropertyKey;
        let retSortedPropertyDirection = null;
        if (sortedPropertyKey === null || sortedPropertyKey !== newSortedPropertyKey) {
            retSortedPropertyDirection = 'A';
            setSortedPropertyKey(newSortedPropertyKey);
            setSortedPropertyDirection('A');
        } else if (sortedPropertyKey === newSortedPropertyKey && sortedPropertyDirection === 'A') {
            retSortedPropertyDirection = 'D';
            setSortedPropertyDirection('D');
        } else {
            retSortedProperty = null;
            setSortedPropertyKey(null);
            setSortedPropertyDirection(null);
        }

        sortClickCallback(retSortedProperty, retSortedPropertyDirection);
    };

    const handleFiltersChange = (columnName: string, selectedOptions: (string | number)[]) => {
        filterChangeCallback({
            columnName,
            optionsSelected: selectedOptions,
        });
    };

    const handleRowClick = (d: DataItem) => {
        if (!isTableRowsClickable) return;
        rowClickCallback(d);
    };

    return {
        sortedPropertyKey,
        sortedPropertyDirection,
        handleSortableTableHeaderClick,
        handleRowClick,
        handleFiltersChange,
    };
};

export default useManageCustomersTable;
