import React from 'react';
import ReactModal from 'components/Shared/ReactModal';
import { Form, Row, Col } from 'react-bootstrap';

const RetryImportJobModal = ({ isOpen, onClose, toggles, onToggleChange, onRunButtonClick }) => {
    return (
        <ReactModal
            data-testid="retry-import-job-modal"
            isOpen={isOpen}
            toggle={onClose}
            headerComponent={<div className="h4">{'Retry Import Jobs'}</div>}
            bodyComponent={
                <div className="h5">
                    <Row className="justify-content-center">
                        <Col xs={3} className="px-3">
                            <Form.Label>Should Recrawl</Form.Label>
                        </Col>
                        <Col>
                            <Form.Switch
                                data-testid="retry-import-job-modal-slider-should-recrawl"
                                type="checkbox"
                                checked={toggles.shouldReCrawl}
                                onChange={() => onToggleChange('shouldReCrawl')}
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xs={3} className="px-3">
                            <Form.Label>Should Reimport</Form.Label>
                        </Col>
                        <Col>
                            <Form.Switch
                                data-testid="retry-import-job-modal-slider-should-reimport"
                                type="checkbox"
                                checked={toggles.shouldReImport}
                                onChange={() => onToggleChange('shouldReImport')}
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xs={3} className="px-3">
                            <Form.Label>Should Reimport All</Form.Label>
                        </Col>
                        <Col>
                            <Form.Switch
                                data-testid="retry-import-job-modal-slider-reimport-all"
                                type="checkbox"
                                checked={toggles.shouldReImportAll}
                                onChange={() => onToggleChange('shouldReImportAll')}
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xs={3} className="px-3">
                            <Form.Label>Force</Form.Label>
                        </Col>
                        <Col>
                            <Form.Switch
                                data-testid="retry-import-job-modal-slider-force"
                                type="checkbox"
                                checked={toggles.force}
                                onChange={() => onToggleChange('force')}
                            />
                        </Col>
                    </Row>
                </div>
            }
            footerComponent={
                <div>
                    <button
                        data-testid="retry-import-job-modal-run-btn"
                        type="button"
                        className="btn btn-md btn-primary"
                        disabled={!Object.values(toggles).some(toggle => toggle)}
                        onClick={() => onRunButtonClick()}>
                        Run
                    </button>
                </div>
            }
        />
    );
};

export default RetryImportJobModal;
