export class ApiConfig {
    _config;

    async get() {
        if (!this._config) {
            const response = await fetch('/_config');

            if (!response.ok) throw new Error('Failed to fetch config');

            const config = await response.json();

            if (config.environment !== 'dev') {
                const windowEnv = window.location.hostname.split('.')[0].replace('adminportal-site-', '');

                config.applicationUrl = config.applicationUrl.replace(config.environment, windowEnv);
                config.authority = config.authority.replace(config.environment, windowEnv);
            } else {
                config.applicationUrl =
                    window.location.protocol +
                    '//' +
                    window.location.hostname +
                    (window.location.port ? `:${window.location.port}` : '');
            }

            this._config = config;
        }

        return this._config;
    }

    static get instance() {
        return apiConfig;
    }
}

const apiConfig = new ApiConfig();
export default apiConfig;
