import React from 'react';
import { ReactModal } from 'oemiq-common';
import useConfirmDeletePositionStatement from './useConfirmDeletePositionStatement';

const ConfirmDeletePositionStatementModal = ({ isOpen, toggle, procedureId, deletePositionStatement }) => {
    const { handleDelete } = useConfirmDeletePositionStatement(procedureId, toggle, deletePositionStatement);

    return (
        <ReactModal
            id="position-statement-modal"
            headerComponent={<div>Upload New Position Statement</div>}
            isOpen={isOpen}
            toggle={toggle}
            bodyComponent={<div>Are you sure you want to delete this position statement?</div>}
            footerComponent={
                <div>
                    <button type="button" className="btn btn-sm btn-primary h-100" onClick={toggle}>
                        cancel
                    </button>
                    <button
                        id="ps-create"
                        type="button"
                        className="btn btn-sm  btn-danger ms-3 h-100"
                        onClick={handleDelete}>
                        Delete
                    </button>
                </div>
            }
        />
    );
};

export default ConfirmDeletePositionStatementModal;
